import React, { useRef, useState, useEffect } from "react";
import { Button, Form, Modal, Dropdown, Card, Row, Col, OverlayTrigger, Tooltip, Popover } from "react-bootstrap";
import { Link, NavLink, Redirect } from "react-router-dom";
import fedduplogo from "../images/fedduplogo.png";
import NavBar1 from "../NavBar/NavBar1";
import './Profile.css';
import safety from '../images/safety.svg';
import profileicon from '../images/profileicon.svg';
import feedsettings from '../images/feedsettings.svg';
import usersettings from '../images/usersettings.svg';
import deactivate from '../images/deactivate.png';
import { Tags } from "../UsersFeed/TrendingTags";
import { Moderators } from "../CompanyFeed/Moderators";
import axios from 'axios';
import { config } from '../Config/Config';
import { country } from "../utils/country";
import { decrypt, useClickOutside } from "../utils";

const list = ["adventurer", "adventurer-neutral", "croodles", "croodles-neutral", "avataaars", "bottts", "big-ears", "micah", "open-peeps", "miniavs", "identicon"]

export default function UserSettings() {

    const [clicked, setClicked] = useState(false);
    const [userName, setUserName] = useState(localStorage.getItem("name"));
    const [user, setUser] = useState();
    const [userCountry, setUserCountry] = useState('')
    const [isDrop, setIsDrop] = useState(false)
    const countryRef = useRef()
    const [popUp, setPopUp] = useState({
        open: false,
        type: '',
        onCancel: null,
        onConfirm: null
    })

    useClickOutside(() => setIsDrop(false), countryRef)

    useEffect(() => {
        getUserDetails()
    }, [])

    const ref = useRef(null);
    const onClicked = () => {
        setClicked(!clicked)
    }
    const onChangeUsername = (e) => {
        setUserName(e.target.value)
    }

    const getUserDetails = async () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/details', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setUser(response.data.data);
                    setUserCountry(response.data.data.country)
                    setClicked(false)
                } else {
                    console.log(response.data.message)
                }
            })
            .catch(function (error) {
                alert("Error")
                console.log(error);
            });
    }
    const updateUserDetails = async (data) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        axios.patch(config.userUrl + 'user/details', data, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    localStorage.setItem('icon', response.data.data?.profilePicture)
                    setUser(response.data.data);
                    setClicked(false)
                } else {
                    console.log(response.data.message)
                }
            })
            .catch(function (error) {
                alert("Error")
                console.log(error);
            });
    }


    const updateUserName = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "userName": userName
        }
        axios.post(config.userUrl + 'user/updateUserName', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    localStorage.setItem('name', response.data.user)
                    setUserName(response.data.user)
                    setClicked(false)

                } else {
                    console.log(response.data.message)
                    document.getElementById('status').innerText = msg
                }
            })
            .catch(function (error) {
                alert("Error")
                console.log("payload", payload)
                console.log(error);
            });
    }
    const deactivateAccount = (id) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "flag": id,
        }

        axios.post(config.userUrl + `user/activeAndDeActiveAccount `, payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    console.log("account deactivated successfully")
                    localStorage.clear();
                    window.location.href = '/'
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    const deleteAccount = (id) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "flag": id,
        }

        axios.post(config.userUrl + `user/deleteAccount `, payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    console.log("account deleted successfully")
                    localStorage.clear();
                    window.location.href = '/'
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    const showDeactivateNone = () => {
        document.getElementById('deactivate').style.display = 'none'
    }
    const showDeactivate = () => {
        setPopUp({
            open: true,
            type: 'deactivate',
            onCancel: () => setPopUp({
                open: false
            }),
            onConfirm: () => deactivateAccount(2)

        })
        // document.getElementById('deactivate').style.display = 'flex'
    }

    const showDeleteNone = () => {
        document.getElementById('delete').style.display = 'none'
    }
    const showDelete = () => {
        setPopUp({
            open: true,
            type: 'delete',
            onCancel: () => setPopUp({
                open: false
            }),
            onConfirm: () => deleteAccount(2)

        })
        // document.getElementById('delete').style.display = 'flex'
    }

    return (
        <>
            <div>
                <NavBar1 />
                <div id="background">
                    <div className="usersettings-l-r"><br /><br />
                        {(localStorage.getItem("role") == "admin") || (localStorage.getItem("role") == "subAdmin") ?
                            <div className="user-settings-heading"> <Link to="/admindashboard" style={{ color: "black", textDecoration: "none" }}><i class="fa fa-arrow-left cursor" aria-hidden="true"></i></Link> Admin Settings</div>
                            : <div className="user-settings-heading"> <Link to="/userfeed" style={{ color: "black", textDecoration: "none" }}><i class="fa fa-arrow-left cursor" aria-hidden="true"></i></Link> User Settings</div>
                        }
                        <div className="flex items-start w-full max-w-7xl gap-10 mt-3 pb-10">

                            <div className="w-full max-w-[200px] rounded-lg flex flex-col gap-4">
                                <NavLink to="/usersettings/account" activeClassName="active-user-text " className="link">
                                    <div className="bg-[#FF8B66] rounded-lg flex items-center py-2 px-4 text-white gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                        </svg>
                                        <span>Account</span>
                                    </div>
                                </NavLink>
                                {/* {(localStorage.getItem("role") == "admin") || (localStorage.getItem("role") == "subAdmin") ?
                                    <NavLink to="/usersettings/companyprofile" activeClassName="active-user-text " className="link"><div className="bg-[#FF8B66] rounded-lg flex items-center py-2 px-4 text-white gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                                        </svg>

                                        Company Profile</div></NavLink>
                                    : null} */}
                            </div>

                            <div className="flex-grow bg-white rounded-lg py-3 px-5">
                                <h1 className="text-xl font-medium">Account Setting</h1>

                                <div className="grid place-items-center mt-3">
                                    <div className="w-40 h-40 bg-white shadow-md relative rounded-lg overflow-hidden">
                                        <img src={user?.profilePicture} alt="" />
                                        <div className="w-7 h-7 absolute bottom-0 right-0 bg-white grid place-items-center cursor-pointer" onClick={() => updateUserDetails({ profilePicture: `https://api.dicebear.com/7.x/${list[Math.floor(Math.random() * list.length)]}/svg?seed=${(Math.random() + 1).toString(36).substring(7)}` })}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <Modal
                                    size='md'
                                    show={clicked}
                                    onHide={() => { setUserName(localStorage.getItem("name")); setClicked(false) }}
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                >
                                    <Modal.Body className='w-full'>
                                        <h1 className="text-3xl font-medium text-orange">Change Username</h1>
                                        <div className="py-4">
                                            <p className="text-lg font-medium">User Name</p>
                                            <input type="text" value={userName} onChange={onChangeUsername} className="py-2 px-3 w-full border rounded-lg mt-2" />
                                            <div className='btn-outer ml-auto mt-3 transform translate-y-5'>
                                                <div className='btn-1' style={{ width: "150px", height: "35px" }}>
                                                </div>
                                                <Button
                                                    variant="default" size="md"
                                                    type="submit"
                                                    className="btn-2 "
                                                    style={{ width: "150px", height: "35px", lineHeight: "14px", fontSize: "14px" }}
                                                    disabled={!userName}
                                                    onClick={updateUserName}
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>

                                <h1 className="py-2 border-b text-gray-500 font-medium my-3">Account Preferences</h1>
                                <div className="change-username">Change Username <span className="right"><Button variant="default" size="sm" className="ml-3 change-btn" onClick={onClicked}>Change</Button></span></div>
                                <div className="username"> <div>{userName} </div></div>
                                <div className="mt-3 change-username">Email </div>
                                <div className="username_">{localStorage.getItem("value")}</div>
                                <div className="mt-3 change-username">Change Password <span className="right"><Button variant="default" size="sm" className="ml-4 change-btn" ><Link to="/changepassword" className="change-color">Change</Link></Button></span></div>
                                <div className="username">**********</div>
                                <h1 className="py-2 border-b text-gray-500 font-medium my-3">System Preferences</h1>

                                <Row style={{ width: "100%" }}>
                                    <Col md={7}>
                                        <div className="change-username">Language</div>
                                        <div className="username_ mt-1">Choose your language preference from the list of languages we support currently. we are in the process of adding more.</div>
                                    </Col>
                                    <Col md={5}>
                                        <div className="relative group">
                                            <Button variant="default" size="md" className="english-btn" style={{ textAlign: "left" }}>English (US)   <i style={{ marginTop: "4px", float: "right" }} class="fa fa-angle-down" ></i></Button>
                                            <div className="hidden group-focus-within:flex flex-col gap-2 bg-white p-3 absolute top-14 -right-7 min-w-max z-20 shadow-md rounded-lg w-[12rem]">
                                                <h1 className="cursor-pointer">English (US)</h1>
                                                <h1 className="my-1 cursor-pointer">English (UK)</h1>
                                                <h1 className="cursor-pointer">English (India)</h1>
                                            </div>
                                        </div>
                                    </Col>
                                </Row><br />
                                <Row style={{ width: "100%" }}>
                                    <Col md={7}>
                                        <div className="change-username">Country</div>
                                        <div className="username_ mt-1">Choose the geographic location  which  you will be accessing feddup the most. this will allow us to notify you if we observe any suspicious activity in your account</div>
                                    </Col>
                                    <Col md={5}>
                                        <div className="relative group flex items-end -mr-8" ref={countryRef} >
                                            <input type="text" value={userCountry} onFocus={() => setIsDrop(true)} onChange={(e) => setUserCountry(e.target.value)} className="rounded-full py-1 px-4 !border-orange-600 outline-none ml-auto" style={{ border: '2px solid red' }} />

                                            {isDrop && <div className="flex flex-col gap-2 bg-white p-3 absolute top-10 right-0 min-w-max max-w-xs z-20 shadow-md rounded-lg w-[12rem]">
                                                {
                                                    country.filter(item => item.name?.toLowerCase().includes(userCountry?.toLowerCase()))?.length
                                                        ?
                                                        country.filter(item => item.name?.toLowerCase().includes(userCountry?.toLowerCase()))?.slice(0, 5)?.map(item => (
                                                            <h1 className="cursor-pointer pb-1 border-b last:border-b-0" onClick={() => { updateUserDetails({ country: item.name }); setUserCountry(item.name); setIsDrop(false) }}>{item.name}</h1>
                                                        ))
                                                        :
                                                        <p className="text-center text-gray-500">
                                                            Not Country Found
                                                        </p>
                                                }
                                            </div>}
                                        </div>
                                    </Col>
                                </Row>

                                {localStorage.getItem("role") == "individual" ?
                                    <div>
                                        <h1 className="py-2 border-b text-gray-500 font-medium my-3">Deactivate Account</h1>

                                        <Row style={{ width: "100%" }}>
                                            <Col md={7}>
                                                <div className="change-username">Deactivate Account</div>
                                                <div className="username_ mt-1">You will be able to reactivate by trying to sign in again after a period of 30 days</div>
                                            </Col>
                                            <Col md={5}>
                                                <Button variant="default" size="md" className="deactive-btn flex items-center gap-2 font-medium" onClick={showDeactivate} style={{ textAlign: "left" }}>
                                                    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8 9.5C8 7.9087 8.63214 6.38258 9.75736 5.25736C10.8826 4.13214 12.4087 3.5 14 3.5C15.5913 3.5 17.1174 4.13214 18.2426 5.25736C19.3679 6.38258 20 7.9087 20 9.5C20 11.0913 19.3679 12.6174 18.2426 13.7426C17.1174 14.8679 15.5913 15.5 14 15.5C12.4087 15.5 10.8826 14.8679 9.75736 13.7426C8.63214 12.6174 8 11.0913 8 9.5Z" fill="#E6570E" />
                                                        <path d="M3.5 20C3.5 18.3305 4.8545 17 6.5135 17H15.8855C14.6639 18.4765 13.997 20.3337 14 22.25C14 24.2045 14.6795 26 15.815 27.4145C15.2225 27.4715 14.615 27.5 14 27.5C11.2175 27.5 8.6255 26.921 6.7025 25.6955C4.7495 24.4505 3.5 22.535 3.5 20Z" fill="#E6570E" />
                                                        <path d="M24 22.25H19.75M28.25 22.25C28.25 23.0872 28.0851 23.9162 27.7647 24.6896C27.4444 25.4631 26.9748 26.1658 26.3828 26.7578C25.7908 27.3498 25.0881 27.8194 24.3146 28.1397C23.5412 28.4601 22.7122 28.625 21.875 28.625C21.0378 28.625 20.2088 28.4601 19.4354 28.1397C18.6619 27.8194 17.9592 27.3498 17.3672 26.7578C16.7752 26.1658 16.3056 25.4631 15.9853 24.6896C15.6649 23.9162 15.5 23.0872 15.5 22.25C15.5 20.5592 16.1716 18.9377 17.3672 17.7422C18.5627 16.5466 20.1842 15.875 21.875 15.875C23.5658 15.875 25.1873 16.5466 26.3828 17.7422C27.5783 18.9377 28.25 20.5592 28.25 22.25Z" stroke="#E6570E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>

                                                    <span>Deactivate Account</span>
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row style={{ width: "100%", marginTop: '1rem' }}>
                                            <Col md={7}>
                                                <div className="change-username">Delete Account</div>
                                                <div className="username_ mt-1">All your account info and posts created will be delete from feddup</div>
                                            </Col>
                                            <Col md={5}>
                                                <Button variant="default" size="md" className="deactive-btn flex items-center gap-4 font-medium" onClick={showDelete} >
                                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.5 9C7.5 7.4087 8.13214 5.88258 9.25736 4.75736C10.3826 3.63214 11.9087 3 13.5 3C15.0913 3 16.6174 3.63214 17.7426 4.75736C18.8679 5.88258 19.5 7.4087 19.5 9C19.5 10.5913 18.8679 12.1174 17.7426 13.2426C16.6174 14.3679 15.0913 15 13.5 15C11.9087 15 10.3826 14.3679 9.25736 13.2426C8.13214 12.1174 7.5 10.5913 7.5 9Z" fill="#E6570E" />
                                                        <path d="M3 19.5C3 17.8305 4.3545 16.5 6.0135 16.5H15.3855C14.1639 17.9765 13.497 19.8337 13.5 21.75C13.5 23.7045 14.1795 25.5 15.315 26.9145C14.7225 26.9715 14.115 27 13.5 27C10.7175 27 8.1255 26.421 6.2025 25.1955C4.2495 23.9505 3 22.035 3 19.5Z" fill="#E6570E" />
                                                        <path d="M28.5 21.75C28.5 23.5402 27.7888 25.2571 26.523 26.523C25.2571 27.7888 23.5402 28.5 21.75 28.5C19.9598 28.5 18.2429 27.7888 16.977 26.523C15.7112 25.2571 15 23.5402 15 21.75C15 19.9598 15.7112 18.2429 16.977 16.977C18.2429 15.7112 19.9598 15 21.75 15C23.5402 15 25.2571 15.7112 26.523 16.977C27.7888 18.2429 28.5 19.9598 28.5 21.75ZM24.531 20.031C24.6718 19.8902 24.7509 19.6992 24.7509 19.5C24.7509 19.3008 24.6718 19.1098 24.531 18.969C24.3902 18.8282 24.1992 18.7491 24 18.7491C23.8008 18.7491 23.6098 18.8282 23.469 18.969L21.75 20.6895L20.031 18.969C19.8902 18.8282 19.6992 18.7491 19.5 18.7491C19.3008 18.7491 19.1098 18.8282 18.969 18.969C18.8282 19.1098 18.7491 19.3008 18.7491 19.5C18.7491 19.6992 18.8282 19.8902 18.969 20.031L20.6895 21.75L18.969 23.469C18.8993 23.5387 18.844 23.6215 18.8062 23.7126C18.7685 23.8037 18.7491 23.9014 18.7491 24C18.7491 24.0986 18.7685 24.1963 18.8062 24.2874C18.844 24.3785 18.8993 24.4613 18.969 24.531C19.0387 24.6007 19.1215 24.656 19.2126 24.6938C19.3037 24.7315 19.4014 24.7509 19.5 24.7509C19.5986 24.7509 19.6963 24.7315 19.7874 24.6938C19.8785 24.656 19.9613 24.6007 20.031 24.531L21.75 22.8105L23.469 24.531C23.5387 24.6007 23.6215 24.656 23.7126 24.6938C23.8037 24.7315 23.9014 24.7509 24 24.7509C24.0986 24.7509 24.1963 24.7315 24.2874 24.6938C24.3785 24.656 24.4613 24.6007 24.531 24.531C24.6007 24.4613 24.656 24.3785 24.6938 24.2874C24.7315 24.1963 24.7509 24.0986 24.7509 24C24.7509 23.9014 24.7315 23.8037 24.6938 23.7126C24.656 23.6215 24.6007 23.5387 24.531 23.469L22.8105 21.75L24.531 20.031Z" fill="#E6570E" />
                                                    </svg>

                                                    <span>Delete Account</span>
                                                </Button>
                                            </Col>
                                        </Row>

                                        <div id="deactivate" className="modal-box">
                                            <form className="modal-content-log">
                                                <div className="md-container" style={{ height: 200, width: 300, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                                    <p className="mb-6 text-lg">Do you really want to Deactivate?</p>
                                                    <center>
                                                        <Button variant="default" onClick={() => deactivateAccount(2)} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                        <Button variant="default" onClick={showDeactivateNone} className="deletebutton">No</Button>
                                                    </center>
                                                </div>
                                            </form>
                                        </div>

                                        <div id="delete" className="modal-box">
                                            <form className="modal-content-log">
                                                <div className="md-container" style={{ height: 200, width: 300, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                                    <p className="mb-6 text-lg">Do you really want to Delete?</p>
                                                    <center>
                                                        <Button variant="default" onClick={() => deleteAccount(2)} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                        <Button variant="default" onClick={showDeleteNone} className="deletebutton">No</Button>
                                                    </center>
                                                </div>
                                            </form>
                                        </div>

                                    </div> : null}
                            </div>

                        </div>

                    </div>
                </div >
            </div >

            <Modal
                size='lg'
                show={popUp.open}
                onHide={popUp.onCancel}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body className='w-full'>

                    <div className='flex flex-col'>
                        <div className='flex flex-col items-center justify-center flex-grow pt-10 pb-16'>
                            <svg width="176" height="176" viewBox="0 0 176 176" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M88.1143 175.359C136.439 175.359 175.614 136.184 175.614 87.8594C175.614 39.5345 136.439 0.359375 88.1143 0.359375C39.7893 0.359375 0.614258 39.5345 0.614258 87.8594C0.614258 136.184 39.7893 175.359 88.1143 175.359Z" fill="#FFB858" />
                                <path d="M88.1143 105.932L88.1143 38.9961" stroke="#E6570E" stroke-width="15" stroke-linecap="round" />
                                <path d="M88.1143 136.724C93.6371 136.724 98.1143 132.246 98.1143 126.724C98.1143 121.201 93.6371 116.724 88.1143 116.724C82.5914 116.724 78.1143 121.201 78.1143 126.724C78.1143 132.246 82.5914 136.724 88.1143 136.724Z" fill="#E6570E" />
                            </svg>

                            <div className="mt-3 text-3xl font-medium text-orange">Are you sure to {popUp.type} the account?</div>
                        </div>

                        <div className='flex items-center justify-between w-full '>
                            <button className='py-1.5 px-4 rounded-lg text-white font-medium text-lg bg-orange-300' onClick={popUp.onCancel}>No, Cancel</button>
                            <button className='py-1.5 px-4 rounded-lg text-white font-medium text-lg bg-orange capitalize' onClick={popUp.onConfirm}>Yes, {popUp.type}</button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
}


import React, { Component } from "react";
import { Button, Form, Card, Row, Col } from "react-bootstrap";
import fedduplogo from "../images/fedduplogo.png";
import landingpage from '../images/landingpage.png';
import { Link } from 'react-router-dom';
import { decrypt } from "../utils";

export default class PageNotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem('junkStore')
        }
    }
    render() {
        return (
            <div className="min-h-screen flex flex-col" style={{ background: "#FF8B66" }}>
                {
                    (!this.state.token) ?
                        <Link to="/"><img alt="" src={fedduplogo} className="pt-4 ml-8 cursor-pointer w-20" /></Link> :
                        ((decrypt(this.state.token) && (localStorage.getItem('role') == "admin") || (localStorage.getItem('role') == "subAdmin")) ?
                            <Link to="/admindashboard"><img alt="" src={fedduplogo} className="w-20 pt-4 ml-8 cursor-pointer" /></Link> :
                            <Link to="/userfeed"><img alt="" src={fedduplogo} className="w-20 pt-4 ml-8 cursor-pointer" /></Link>)
                }
                <div className="flex justify-center items-center text-center w-full flex-grow gap-32 -mt-32">
                    <img src={landingpage} className=" max-w-md" />
                    <div md={5} className="flex flex-col gap-4 justify-start items-center">
                        <div className='pnf-text  text-orange-100'>Looks like you are fed up</div>
                        <div className='text-yellow-200 text-5xl font-bold'>& Lost</div>
                        {
                            (!this.state.token) ?
                                <Link to="/">
                                    <div className='btn-outer mt-8'>
                                        <div className='btn-1' style={{ width: "201px" }}>
                                        </div>
                                        <Button variant="default" style={{ width: "200px" }} className="btn-2">Go Back to Home</Button>
                                    </div>
                                </Link> :
                                ((decrypt(this.state.token) && (localStorage.getItem('role') == "admin") || (localStorage.getItem('role') == "subAdmin")) ?
                                    <Link to="/admindashboard">
                                        <div className='btn-outer mt-8'>
                                            <div className='btn-1' style={{ width: "181px" }}>
                                            </div>
                                            <Button variant="default" style={{ width: "180px" }} className="btn-2">Go Back to Home</Button>
                                        </div>
                                    </Link> :
                                    <Link to="/userfeed">
                                        <div className='btn-outer mt-8'>
                                            <div className='btn-1' style={{ width: "181px" }}>
                                            </div>
                                            <Button variant="default" style={{ width: "180px" }} className="btn-2">Go Back to Home</Button>
                                        </div>
                                    </Link>)}
                    </div>

                </div>
            </div>
        )
    }
}
import React from 'react';
import './CompanyFeed.css';
import { Row, Col, Modal, Form, FormControl, Card, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import upvoteb from '../images/upvote-b.svg';
import downvoteb from '../images/downvote-b.svg';
import upvote from '../images/upvote.svg';
import downvote from '../images/downvote.svg';
import comment from '../images/comments.svg';
import share from '../images/share.svg';
import lock from '../images/lock.png';
import profilepicture from '../images/profilepicture.png';

class PopupBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uImg: false,
            dImg: true,
            count: 999
        }

    }
    componentDidMount() {
        console.log("props", this.props)
    }
    changeUpvoteImage = () => {
        const { uImg, dImg, count } = this.state
        this.setState({ uImg: !uImg })
        if (uImg) {
            this.setState({ count: count - 1, dImg: false })
        } else {
            this.setState({ count: count + 1, dImg: true })
        }
    }
    render() {
        const { uImg, dImg, count } = this.state
        return (
            <div className='popup-box'>
                <div className='_box'>
                    <span className="close-icon-new" onClick={this.props.closePopup}> &times;</span>

                    <Card>
                        <div className="row">
                            <Col md={3}>
                                <Card className="ml-2 mt-2 mb-2 card-padding-sm">
                                    <center> {uImg ? <img src={upvote} onClick={this.changeUpvoteImage} style={{ width: "100px" }} /> : <img src={upvoteb} onClick={this.changeUpvoteImage} style={{ width: "80px" }} />}</center>
                                    <center className="mt-1 likes">{count}</center>
                                    <center> {dImg ? <img src={downvoteb} onClick={this.changeUpvoteImage} className="mt-2" style={{ width: "100px" }} /> : <img src={downvote} onClick={this.changeUpvoteImage} className="mt-2" style={{ width: "80px" }} />}</center>
                                </Card>
                            </Col>
                            <Col md={9}>
                                <div className="ml-2 mt-2 mb-2">
                                    <div className="card-padding">
                                        <h6 className="t-today">{this.props.data.heading}</h6>
                                        <div className="posted">Posted by {this.props.data.postedBy} &nbsp;&nbsp;{this.props.data.date}</div>
                                        <div className="title">{this.props.data.title}</div>
                                        <div className="mt-1 desc">{this.props.data.text}</div>
                                        {this.props.data.tags !== "" ?
                                            <div className="mt-2">&nbsp;&nbsp;&nbsp;&nbsp;<Button id="talkbubble" variant="default" className="tags-btn"><li>{this.props.data.tags[0]}</li></Button>
                                                &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;<Button id="talkbubble" variant="default" className="tags-btn"><li>{this.props.data.tags[1]}</li></Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Button id="talkbubble" variant="default" className="tags-btn"><li>{this.props.data.tags[2]}</li></Button></div>

                                            : null}<br />
                                        <img src={this.props.data.image} style={{ marginTop: "10px", width: "100%" }} /><br /><br />
                                        <div><img src={comment} style={{ width: "25px" }} /> <span className="comment">{this.props.data.comments} Comments</span><span className="ml-5"><img src={share} style={{ width: "25px" }} /> <span className="comment">{this.props.data.shares} Shares</span></span></div>

                                        <Card className="mt-3 c-padding">
                                            <Row style={{ width: "100%" }}>
                                                <Col md={2}>
                                                    <div className="c-box"><img src={lock} className="lock" /></div>
                                                </Col>
                                                <Col md={10}>
                                                    <div className='c-text'>You are not allowed to comment here.</div><br />
                                                </Col>
                                            </Row>
                                        </Card><br />
                                        <div style={{ fontFamily: "Poppins" }}>Sort by<span style={{ color: "#E6570E" }}> Top </span></div>
                                        <hr style={{ marginTop: "5px" }} />
                                        <div className="mt-3">
                                            <Row style={{ width: "100%" }}>
                                                <Col md={2}>
                                                    <img src={profilepicture} style={{ width: "50px" }} />
                                                </Col>
                                                <Col md={10}>
                                                    <div className='c-comments'>
                                                        <span style={{ display: "flex" }}><span style={{ fontSize: "18px" }}>John Doe </span>&nbsp;&nbsp;&nbsp;&nbsp;<li style={{ marginTop: "5px", color: "#AAAAAA", fontSize: "10px" }}>1 hour</li></span>
                                                        <div style={{ fontSize: "14px" }}>Suscipit egestas dolor massa magna nullam enim, dignissim. Sed est morbi auctor nibh morbi. Quisque tortor potenti fusce scelerisque adipiscing. Lectus mauris consectetur ut urna sit pellentesque blandit arcu in.</div>
                                                        <div style={{ display: "flex" }}><center> {uImg ? <img src={upvote} onClick={this.changeUpvoteImage} style={{ width: "60px" }} /> : <img src={upvoteb} onClick={this.changeUpvoteImage} style={{ width: "60px" }} />}</center>
                                                            <center className="mt-4 likes"> &nbsp;&nbsp;{count}&nbsp;&nbsp;</center>
                                                            <center> {dImg ? <img src={downvoteb} onClick={this.changeUpvoteImage} style={{ width: "60px" }} /> : <img src={downvote} onClick={this.changeUpvoteImage} style={{ width: "60px" }} />}</center> <span className="ml-5" style={{ marginTop: "20px" }}><img src={share} style={{ width: "25px" }} /> <span className="ml-3 comment"> Share</span></span></div>
                                                    </div><br />
                                                    <div className="row">
                                                        <Col md={2}>
                                                            <img src={profilepicture} style={{ width: "50px" }} />
                                                        </Col>
                                                        <Col md={10}>
                                                            <div className='c-comments'>
                                                                <span style={{ display: "flex" }}><span style={{ fontSize: "18px" }}>John Doe </span>&nbsp;&nbsp;&nbsp;&nbsp;<li style={{ marginTop: "5px", color: "#AAAAAA", fontSize: "10px" }}>1 hour</li></span>
                                                                <div style={{ fontSize: "14px" }}>Suscipit egestas dolor massa magna nullam enim, dignissim. Sed est morbi auctor nibh morbi. Quisque tortor potenti fusce scelerisque adipiscing. Lectus mauris consectetur ut urna sit pellentesque blandit arcu in.</div>
                                                                <div style={{ display: "flex" }}><center> {uImg ? <img src={upvote} onClick={this.changeUpvoteImage} style={{ width: "60px" }} /> : <img src={upvoteb} onClick={this.changeUpvoteImage} style={{ width: "60px" }} />}</center>
                                                                    <center className="mt-4 likes"> &nbsp;&nbsp;{count}&nbsp;&nbsp;</center>
                                                                    <center> {dImg ? <img src={downvoteb} onClick={this.changeUpvoteImage} style={{ width: "60px" }} /> : <img src={downvote} onClick={this.changeUpvoteImage} style={{ width: "60px" }} />}</center> <span className="ml-5" style={{ marginTop: "20px" }}><img src={share} style={{ width: "25px" }} /> <span className="ml-3 comment"> Share</span></span></div>
                                                            </div><br />
                                                        </Col>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </div>
                                        <br />
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </Card><br />
                </div>
            </div>
        );
    }
}

export default PopupBox;


import React from 'react';
import '../CompanyFeed/CompanyFeed.css';
import { Row, Col, Modal, Form, FormControl, Card, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import upvoteb from '../images/upvote-b.svg';
import downvoteb from '../images/downvote-b.svg';
import upvote from '../images/upvote.svg';
import downvote from '../images/downvote.svg';
import comment from '../images/comments.svg';
import share from '../images/share.svg';
import lock from '../images/lock.png';
import profilepicture from '../images/profilepicture.png';
import deletei from "../images/delete-i.svg";
import axios from 'axios';
import { config } from '../Config/Config';
import { decrypt } from '../utils';

class DeleteSuggestionPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deletePost: true
        }
    }
    componentDidMount() {
        console.log("props", this.props.data)
    }
    deleteSuggestionById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "suggestionId": this.props.data
        }
        console.log("payload", payload)
        var self = this;
        axios.post(config.userUrl + 'user/deleteSuggestionByCreater', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, payload);
                    self.props.getSuggestions(self.props.selectedValue)
                    self.props.closePopup();

                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    render() {
        const { deletePost } = this.state
        return (
            <Modal
                size="lg"
                show={deletePost}
                onHide={this.props.closePopup}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title >
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='flex flex-col'>
                        <div className='flex flex-col items-center justify-center flex-grow pb-16'>
                            <svg width="176" height="176" viewBox="0 0 176 176" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M88.1143 175.359C136.439 175.359 175.614 136.184 175.614 87.8594C175.614 39.5345 136.439 0.359375 88.1143 0.359375C39.7893 0.359375 0.614258 39.5345 0.614258 87.8594C0.614258 136.184 39.7893 175.359 88.1143 175.359Z" fill="#FFB858" />
                                <path d="M88.1143 105.932L88.1143 38.9961" stroke="#E6570E" stroke-width="15" stroke-linecap="round" />
                                <path d="M88.1143 136.724C93.6371 136.724 98.1143 132.246 98.1143 126.724C98.1143 121.201 93.6371 116.724 88.1143 116.724C82.5914 116.724 78.1143 121.201 78.1143 126.724C78.1143 132.246 82.5914 136.724 88.1143 136.724Z" fill="#E6570E" />
                            </svg>

                            <div className="mt-3 text-3xl font-medium text-orange">Are you sure to delete this suggestion post?</div>
                        </div>
                        <div className='flex items-center justify-between w-full '>
                            <button className='py-1.5 px-4 rounded-lg text-white font-medium text-lg bg-orange-300' onClick={this.props.closePopup}>No, Cancel</button>
                            <button className='py-1.5 px-4 rounded-lg text-white font-medium text-lg bg-orange' onClick={this.deleteSuggestionById}>Yes, Delete</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default DeleteSuggestionPopup;


import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { config } from '../Config/Config';
import { Button, Card, Col, Dropdown, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import AddImage from "./AddImage";
import feedbackImage from "../images/feedback-img.svg";
import imageicon from "../images/image-icon.svg";
import linkicon from "../images/link-icon.svg";
import i from "../images/i.svg";
import fillTag from "../images/fill-tag.svg";
import emptyTag from "../images/empty-tag.svg";
import downvoteb from '../images/downvote-b.svg';
import upvote from '../images/upvote.svg';
import upvoteb from '../images/upvote-b.svg';
import downvote from '../images/downvote.svg';
import dots from "../images/dots.svg";
import imgDel from "../images/delete.svg";
import ImgEdit from "../images/edit.svg";
import comment from '../images/comments.svg';
import share from '../images/share.svg';
import PopupComments from "../UsersFeed/PopupComments";
import ViewImage from '../UsersFeed/ViewImage';
import OpenShareFeedbackPopup from '../UsersFeed/OpenShareFeedbackPopup';
import EditFeedbackPopup from '../UsersFeed/EditFeedbackPopup';
import DeleteFeedbackPopup from '../UsersFeed/DeleteFeedbackPopup';
import AddImage2 from './AddImage2';
import moment from 'moment';
import Tag from '../utils/Tag';
import AccessPopUp from './AccessPopUp';
import ShareBox from '../UsersFeed/ShareBox';
import { decrypt, encrypt } from '../utils';

function ProductFeedbackSection(props) {
    const { companyId, productId, selectedVersion, initialTags, canReview, guidelines, sharedId, productType, setState } = props;
    const [addFeedback, setAddFeedback] = useState(false);
    const [feedbackTitle, setFeedbackTitle] = useState("");
    const [feedbackDescription, setFeedbackDescription] = useState("");
    const [image, setImage] = useState("");
    const [defaultTags, setDefaultTags] = useState(initialTags || [])
    const [selectedTags, setSelectedTags] = useState([])
    const [productFeedbacks, setProductFeedbacks] = useState([])
    const [uImg, setUImg] = useState(false);
    const [count, setCount] = useState(999);
    const [dImg, setDImg] = useState(true);
    const [selectedValue, setSelectedValue] = useState(0);
    const [edit, setEdit] = useState(false);
    const [tag, setTag] = useState([]);
    const [feedId, setFeedId] = useState("");
    const [deletePost, setDeletePost] = useState(false);
    const [popup, setPopup] = useState(false);
    const [popupData, setPopupData] = useState([]);
    const [openShareFeedback, setOpenShareFeedback] = useState(false);
    const [sortOption, setSortOption] = useState('');
    const [sortOption2, setSortOption2] = useState('');
    const [sortedData, setSortedData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [reviewEnabled, setReviewEnabled] = useState(false);
    const [viewImage, setViewImage] = useState(false);
    const [popupImage, setPopupImage] = useState("");
    const [isAccess, setIsAccess] = useState(true)
    const [accessPopUp, setAccessPopUp] = useState(false)


    useEffect(() => {
        setSortedData([]);
        getAllFeedbacksByProductId(0);
    }, [selectedVersion])

    const addFeedbackPopup = () => {
        if (localStorage.getItem('junkStore')) {
            if (isAccess) {
                setAddFeedback(true)
            }
            else {
                setAccessPopUp(true)
            }
        } else {
            setAddFeedback(false)
        }
    }
    const onChangeImage = (data) => {
        setImage(data)
        console.log("s", data)
    }
    const handleTags = (e, tag) => {
        const updated = defaultTags.map(data => (data.tag === tag) ?
            { ...data, isSelected: !data.isSelected } : data)
        setDefaultTags(updated)
        setSelectedTags(updated)
    }
    const postFeedback = () => {
        if (localStorage.getItem('junkStore')) {
            var headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                "applicationType": "web"
            }
            let tagsData = [];
            tagsData = defaultTags.filter(item => (item.isSelected === true) ? item.tag : null)
            var tokenId = encrypt({ "companyId": companyId, "productId": productId })
            var payload = {
                "tokenId": tokenId,
                "title": feedbackTitle,
                "feedback": feedbackDescription,
                "feedbackImage": image,
                "tag": tagsData.map(item => item.tag),
                "versionId": selectedVersion.id
            }
            console.log("payload", payload);
            if (feedbackTitle) {

                axios.post(config.userUrl + 'user/createProductFeedbackByUser', payload, { headers: headers })
                    .then(function (response) {
                        // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                        if (response.data.status === true) {
                            console.log("success");
                            setAddFeedback(false)
                            setFeedbackTitle("")
                            setImage("")
                            setFeedbackDescription("")
                            getDefaultTags()
                            getAllFeedbacksByProductId(0)
                        } else {
                            console.log("error", payload);
                        }
                    })
                    .catch(function (error) {
                        console.log("Error", error);
                    });
            }
        } else {
            console.log("No Suggestion token")
        }
    };

    const validateReviewAdded = () => {
        if (localStorage.getItem('junkStore')) {
            var headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                "applicationType": "web"
            }
            var tokenId = encrypt({ "companyId": companyId, "productId": productId })
            var payload = {
                "tokenId": tokenId,
                "versionId": selectedVersion.id,
                type: 'feedback'
            }
            console.log("payload", payload);
            if (selectedVersion.id) {

                axios.post(config.userUrl + 'user/validateReviewAdded', payload, { headers: headers })
                    .then(function (response) {
                        if (response.data.status === true) {
                            setIsAccess(response.data?.access)
                        } else {
                            console.log("error", payload);
                        }
                    })
                    .catch(function (error) {
                        console.log("Error", error);
                    });
            }
        } else {
            console.log("No Suggestion token")
        }
    };

    // useEffect(() => {
    //     if (companyId && productId && selectedVersion?.id) {
    //         validateReviewAdded()
    //     }
    // }, [companyId, productId, selectedVersion?.id])

    const getDefaultTags = () => {
        var headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getDefaultTag', { headers: headers })
            .then(function (response) {
                console.log(" response.data ", response.data)
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    for (let i = 0; i < response.data.getDefaultTag.length; i++) {
                        response.data.getDefaultTag[i].isSelected = false
                    }
                    setDefaultTags(response.data.getDefaultTag)
                } else {
                    console.log("error");
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    function changeUpvoteImage(id, val) {
        if (localStorage.getItem('junkStore')) {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                "applicationType": "web"
            }
            console.log("dataup", id.id)
            setUImg(!uImg)
            if (uImg) {
                setCount(count - 1)
                setDImg(false)

            } else {
                setCount(count + 1)
                // setDImg(false)
                setDImg(false)
            }
            var payload = {
                "feedbackId": id.id,
                "feedback": val,
                "userIdFeedbackBelongTo": id.feedbackByUserId
            }
            console.log("upvote", payload)
            if (val == 1) {
                console.log("upvote", payload)
                let idx = sortedData?.findIndex(item => item.id == id.id);
                let arr = [...sortedData];
                arr[idx].vote = 1;
                arr[idx].totallike = Number(arr[idx].totallike) + 1;
                setSortedData(arr);
                axios.post(config.userUrl + 'user/likeOrDislikeFeedback', payload, { headers: headers })
                    .then(function (response) {
                        if (response.data.status === true) {
                            // getAllFeedbacksByProductId(selectedValue);
                        }
                    })
                    .catch(function (error) {
                        arr[idx].vote = 0;
                        arr[idx].totallike = Number(arr[idx].totallike) - 1;
                        setSortedData(arr);
                        console.log(error, payload)
                    })
            }
        } else {
            console.log("No upvote")
        }
    }
    function changeDownvoteImage(id, val) {
        if (localStorage.getItem('junkStore')) {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                "applicationType": "web"
            }
            setDImg(!dImg)
            if (!dImg) {
                setUImg(false)
                setCount(count - 1)
            } else {
                // setDImg(!dImg)
                setUImg(false)
                setDImg(false)
            }
            var payload = {
                "feedbackId": id.id,
                "feedback": val,
                "userIdFeedbackBelongTo": id.feedbackByUserId
            }
            console.log("upvote", payload)
            if (val == 0) {
                let idx = sortedData?.findIndex(item => item.id == id.id);
                let arr = [...sortedData];
                arr[idx].vote = -1;
                arr[idx].totallike = Number(arr[idx].totallike) - 1;
                setSortedData(arr);
                axios.post(config.userUrl + 'user/likeOrDislikeFeedback', payload, { headers: headers })
                    .then(function (response) {
                        if (response.data.status === true) {
                            // getAllFeedbacksByProductId(selectedValue);
                        }
                    })
                    .catch(function (error) {
                        arr[idx].vote = 0;
                        arr[idx].totallike = Number(arr[idx].totallike) + 1;
                        setSortedData(arr);
                        console.log(error, payload)
                    })
            }
        } else {
            console.log('No Downvote')
        }
    }
    function editFeedback(id) {
        setEdit(!edit)
        setFeedbackTitle(id.title)
        setFeedbackDescription(id.feedback)
        setImage(id.feedbackImage)
        setTag(id.tag)
        setFeedId(id.id)
    }
    function deleteFeedback(id) {
        setDeletePost(!deletePost)
        setFeedId(id.id)
    }
    const togglePopup = (data) => {
        if (localStorage.getItem('junkStore')) {
            setPopup(!popup)
            setPopupData({ ...data, productTypeString: productType })
        } else {
            setPopup(false);
        }
    }
    const toggleShareFeedbackPopup = (data) => {
        if (localStorage.getItem('junkStore')) {
            setOpenShareFeedback(!openShareFeedback)
            setPopupData(data)
        } else {
            setOpenShareFeedback(false)
        }
    }
    const filter = () => {
        let feedbacksArray = [...productFeedbacks]
        let sortedFeedbacks = []
        if (sortOption2.length > 0) {
            if (sortOption2 === "Anonymous") {
                sortedFeedbacks = feedbacksArray.filter(feedback => feedback.userName === null)
            }
            else if (sortOption2 === "Registered") {
                sortedFeedbacks = feedbacksArray.filter(feedback => feedback.userName !== null)
            }
            else {
                sortedFeedbacks = [...productFeedbacks];
            }
            feedbacksArray = [...sortedFeedbacks];
        }
        if (sortOption.length > 0) {
            if (sortOption === "Latest to Oldest") {
                sortedFeedbacks = feedbacksArray.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            }
            else {
                sortedFeedbacks = feedbacksArray.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
            }
            feedbacksArray = [...sortedFeedbacks];
        }
        setSortedData(sortedFeedbacks);
    }
    const handleToggle = () => {
        if (sortOption || sortOption2)
            filter()
        setIsOpen(!isOpen)
    }
    const openImage = (data) => {
        setViewImage(true)
        setPopupImage(data.feedbackImage)
    }
    const getAllFeedbacksByProductId = async (eventKey) => {
        let headers;
        if (localStorage.getItem('junkStore')) {
            headers = {
                'Accept': 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                'Content-Type': 'application/json'
            }
        } else {
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        var payload = {
            "productId": productId,
            "type": parseInt(eventKey),
            "versionId": selectedVersion.id
        }
        if (selectedVersion?.id) {

            let result = axios.post(config.userUrl + "user/getFeedbackByProductId", payload, { headers: headers })
                .then(function (response) {
                    // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                    if (response.data.status === true) {
                        console.log("getFeedbacksProductById successfull", response.data);
                        setProductFeedbacks(response.data.feedback?.filter(item => item?.title)?.sort((a, b) => b.pin - a.pin));
                        setSortedData(response.data.feedback?.filter(item => item?.title)?.sort((a, b) => b.pin - a.pin))
                        setReviewEnabled(response.data.reviewEnabled.reviewEnabled);
                    }
                })
                .catch(function (error) {
                    console.log("Error", error);
                });
        }
    };

    useEffect(() => {
        if (productFeedbacks?.length && sharedId) {
            let idx = productFeedbacks.findIndex(item => item?.id == sharedId);
            if (idx > -1)
                togglePopup(productFeedbacks[idx])
        }
    }, [productFeedbacks.length, sharedId])

    const closeImage = () => {
        setViewImage(false)
    }
    function closeDeleteFeedback() {
        setDeletePost(false)
        setFeedId("")
    }
    function closeEditFeedback() {
        setEdit(false)
        setFeedbackTitle("")
        setFeedbackDescription("")
        setImage("")
        setTag("")
        setFeedId("")
        setDefaultTags(defaultTags?.map((item) => {
            item.isSelected = false;
            return item
        }))
    }
    return (
        <div>
            {accessPopUp && <AccessPopUp active={accessPopUp} closePopUp={() => {
                setAccessPopUp(false);
                setDefaultTags(defaultTags?.map((item) => {
                    item.isSelected = false;
                    return item
                }))
            }} type={"Feddback"} />}
            {popup && <PopupComments openImage={openImage} closePopup={togglePopup} getAllFeedbacks={getAllFeedbacksByProductId} data={popupData} selectedValue={selectedValue} />}
            {viewImage && <ViewImage close={closeImage} image={popupImage} />}
            {deletePost && <DeleteFeedbackPopup closePopup={closeDeleteFeedback} getAllFeedbacks={getAllFeedbacksByProductId} data={feedId} selectedValue={selectedValue} />}
            {edit && <EditFeedbackPopup initialTags={defaultTags} closePopup={closeEditFeedback} feedId={feedId} image={image} getAllFeedbacks={getAllFeedbacksByProductId} title={feedbackTitle} postFeedback={feedbackDescription} tag={tag} selectedValue={selectedValue} />}
            {openShareFeedback && <OpenShareFeedbackPopup closePopup={toggleShareFeedbackPopup} getAllFeedbacks={getAllFeedbacksByProductId} data={popupData} selectedValue={selectedValue} />}
            <div className="mt-2">
                {localStorage.getItem('junkStore') && canReview ?
                    <div className={`feedback-inp ${reviewEnabled ? "flex items-center" : "hidden"}`} style={{ cursor: "pointer" }} onClick={addFeedbackPopup}>
                        <img src={feedbackImage} style={{ width: "100px", margin: "0 30px 0 40px" }} />
                        <div className="feedback-details">
                            <p className="text-2xl">Bug? Discuss? Feddup?</p >
                            <div className="create-post">
                                <span className="ml-4">Create a feddback!</span>
                                <div style={{ padding: "0 2px" }}>
                                    <img src={imageicon} style={{ width: "20px", float: "right", margin: "0 1rem" }} />
                                    <img src={linkicon} style={{ width: "20px", float: "right" }} />
                                </div>
                            </div>
                        </div>
                    </div> : ""}
                <Modal
                    size="xl"
                    show={addFeedback}
                    onHide={() => setAddFeedback(false)}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title >
                            <h1 className='cursor-pointer absolute top-4 right-10 text-lg font-normal' style={{ color: 'rgba(255, 160, 32, 1)' }} onClick={guidelines}>View Post Guidelines</h1>
                            <div className='flex items-center gap-2 relative w-full'>
                                <span className='w-24'>
                                    <svg viewBox="0 0 139 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M70.7812 34.2148V73.2644H84.1343H89.6179H95.5238H114.824L123.016 83.3073L137.547 34.2148L70.7812 34.2148Z" fill="#E6570E" stroke="#E6570E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M91.3435 47.6166C91.4671 49.5947 90.7254 53.551 85.6564 58.0018C78.9802 57.1363 76.2603 54.1692 75.2712 52.4383C74.9003 51.8201 74.7767 51.4492 74.7767 51.3256C74.1586 49.1002 75.3949 46.6276 77.6203 46.0094C79.8457 45.3912 82.3183 46.6276 82.9365 48.8529C82.3183 46.6276 83.5546 44.1549 85.78 43.5367C88.0054 42.9186 90.4781 44.1549 91.0963 46.3803C91.2199 46.3803 91.3435 46.8748 91.3435 47.6166Z" fill="#FFB858" />
                                        <path d="M115.166 48.3779L121.842 55.0541" stroke="#FFB858" stroke-width="3.9997" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M121.842 48.3779L115.166 55.0541" stroke="#FFB858" stroke-width="3.9997" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M96.6179 51.6519C96.6179 54.5646 98.9791 56.9258 101.892 56.9258C104.805 56.9258 107.166 54.5646 107.166 51.6519C107.166 48.7391 104.805 46.3779 101.892 46.3779C98.9791 46.3779 96.6179 48.7391 96.6179 51.6519Z" fill="#FFB858" />
                                        <path d="M67.5469 1.21484V40.2644H54.1938H48.7102H42.8044L23.504 40.2644L15.3126 50.3073L0.78125 1.21484L67.5469 1.21484Z" fill="#FFB858" stroke="#FFB858" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M46.9846 14.6166C46.861 16.5947 47.6028 20.551 52.6717 25.0018C59.3479 24.1363 62.0678 21.1692 63.0569 19.4383C63.4278 18.8201 63.5514 18.4492 63.5514 18.3256C64.1696 16.1002 62.9332 13.6276 60.7078 13.0094C58.4825 12.3912 56.0098 13.6276 55.3916 15.8529C56.0098 13.6276 54.7735 11.1549 52.5481 10.5367C50.3227 9.91857 47.85 11.1549 47.2319 13.3803C47.1082 13.3803 46.9846 13.8748 46.9846 14.6166Z" fill="#E6570E" />
                                        <path d="M23.1624 15.3779L16.4862 22.0541" stroke="#E6570E" stroke-width="3.9997" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16.4862 15.3779L23.1624 22.0541" stroke="#E6570E" stroke-width="3.9997" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M41.7102 18.6519C41.7102 21.5646 39.349 23.9258 36.4363 23.9258C33.5236 23.9258 31.1624 21.5646 31.1624 18.6519C31.1624 15.7391 33.5236 13.3779 36.4363 13.3779C39.349 13.3779 41.7102 15.7391 41.7102 18.6519Z" fill="#E6570E" />
                                    </svg>
                                </span>
                                <h5 style={{ fontFamily: "poppins" }} className='text-orange font-semibold'>Create Feddback</h5>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id={`tooltip-top`}>
                                            <div className='tool-text'>
                                                Public community post to shed light on generic bugs and features not working as intended<br />
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <span className="i-icon"><img src={i} style={{ width: "20px" }} /></span>
                                </OverlayTrigger>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="-mt-8 mb-2 px-5">
                            <Form>
                                <Form.Control style={{ fontFamily: "poppins" }} type="text"
                                    name="feedbackTitle" value={feedbackTitle}
                                    placeholder="Feddback Title"
                                    onChange={(e) => setFeedbackTitle(e.target.value)} />
                                <textarea
                                    className="mt-3 form-control"
                                    id="exampleFormControlTextarea1"
                                    style={{ fontFamily: "poppins", resize: "none" }}
                                    rows="5"
                                    maxLength={180}
                                    value={feedbackDescription}
                                    name="feedbackDescription"
                                    onChange={(e) => setFeedbackDescription(e.target.value)}
                                    placeholder="What is making you feddup?"
                                />
                                <div style={{ fontFamily: "poppins", fontSize: "12px" }}>{feedbackDescription.length}/180 characters</div>

                                <div className='flex items-start w-full py-4 gap-8'>
                                    <div className="w-1/2"> <AddImage2 onChangeImage={onChangeImage} /></div>
                                    <div className='w-1/2 h-[13rem] overflow-y-auto scrollbar-hide rounded-lg border-2 border-gray-500 p-2'>
                                        <h1 className='text-orange font-medium'>Select Tags</h1>
                                        <div className='flex flex-wrap gap-y-2 py-2'>
                                            {
                                                defaultTags.map((data, i) => (
                                                    <span className='cursor-pointer' onClick={(e) => handleTags(e, data.tag)}>
                                                        <Tag text={data.tag} color={data.isSelected ? '' : '#999'} />
                                                    </span>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </Form>
                            <div className='w-full flex items-center justify-between'>
                                <div onClick={() => setAddFeedback(false)} className=" text-orange font-medium text-lg cursor-pointer">Cancel</div>

                                <OverlayTrigger style={(feedbackTitle.length != 0) && (feedbackDescription.length != 0) ? { display: "none" } : { cursor: "pointer" }}
                                    placement="left"
                                    overlay={
                                        <Tooltip id={`tooltip-top`} style={(feedbackTitle.length != 0) && (feedbackDescription.length != 0) ? { display: "none" } : { cursor: "pointer" }}>
                                            {(feedbackTitle.length != 0) && (feedbackDescription.length != 0) ?
                                                <div style={{ display: "none" }}>

                                                </div>
                                                : <div className='tool-text'>
                                                    Please add title and description
                                                </div>
                                            }
                                        </Tooltip>
                                    }
                                >
                                    {(feedbackTitle.length != 0) && (feedbackDescription.length != 0) ?
                                        <div className='btn-outer transform translate-x-6'>
                                            <div className='btn-1' style={{ width: "120px", height: "30px" }}>
                                            </div>
                                            <Button
                                                variant="default"
                                                className="btn-2" style={{ width: "120px", height: "30px", lineHeight: "12px" }}
                                                onClick={postFeedback}
                                            >Submit</Button>
                                        </div>
                                        :
                                        <div className='btn-outer transform translate-x-6'>
                                            <div className='btn-1' style={{ width: "120px", height: "30px" }}>
                                            </div>
                                            <Button
                                                disabled
                                                variant="default"
                                                className="btn-2" style={{ width: "120px", height: "30px", lineHeight: "12px", color: '#222' }}
                                                onClick={postFeedback}
                                            >Submit</Button>
                                        </div>
                                    }
                                </OverlayTrigger>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <div className="feddback-company">
                    <div className="mt-1 mb-32">
                        <div className="rating-title">
                            <p className="rating-text">User Feddback</p>
                            <div className='btn-outer'>
                                <div className='btn-1'>
                                </div>
                                {/* <Button variant="default" className="btn-2">Sort & Filter <b><i className="fa fa-angle-down"></i></b></Button> */}
                                <Dropdown className="btn-2 flex justify-end items-end" align="end" show={isOpen} onToggle={handleToggle}>
                                    <Dropdown.Toggle variant="default" className="text-white focus:outline-none focus:ring-0" >
                                        <span className="text-center text-lg">Sort & Filter <b><i className="fa fa-angle-down"></i></b></span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="border-orange-400 rounded-lg min-w-[15rem] z-20">
                                        <span className='text-black px-2 text-lg'>Sort</span>
                                        <div className="flex flex-col gap-1 text-balck font-medium px-2 mb-2">
                                            <div className='flex items-center gap-2'>
                                                <button
                                                    className={`rounded-sm w-3 h-3 focus:outline-none ${sortOption === "" ? 'bg-orange-600 text-white' : 'bg-gray-200'
                                                        }`}
                                                    onClick={() => setSortOption("")}
                                                >
                                                </button>
                                                <span>Default</span>
                                            </div>
                                            <div className='flex items-center gap-2'>
                                                <button
                                                    className={`rounded-sm w-3 h-3 focus:outline-none ${sortOption === "Latest to Oldest" ? 'bg-orange-600 text-white' : 'bg-gray-200'
                                                        }`}
                                                    onClick={() => setSortOption("Latest to Oldest")}
                                                >
                                                </button>
                                                <span>Latest to Oldest</span>
                                            </div>
                                            <div className='flex items-center gap-2'>
                                                <button
                                                    className={`rounded-sm w-3 h-3 focus:outline-none ${sortOption === "Oldest to Latest" ? 'bg-orange-600 text-white' : 'bg-gray-200'
                                                        }`}
                                                    onClick={() => setSortOption("Oldest to Latest")}
                                                >
                                                </button>
                                                <span>Oldest to Latest</span>
                                            </div>
                                        </div>
                                        <span className='text-black px-2 mt-4 text-lg'>Review Type</span>
                                        <div className="flex flex-col gap-1 text-black font-medium px-2 mb-4">
                                            <div className='flex items-center gap-2'>
                                                <button
                                                    className={`rounded-sm w-3 h-3 focus:outline-none ${sortOption2 === "Registered" ? 'bg-orange-600 text-white' : 'bg-gray-200'
                                                        }`}
                                                    onClick={() => setSortOption2("Registered")}
                                                >
                                                </button>
                                                <span>Registered Users</span>
                                            </div>
                                            <div className='flex items-center gap-2'>
                                                <button
                                                    className={`rounded-sm w-3 h-3 focus:outline-none ${sortOption2 === "Anonymous" ? 'bg-orange-600 text-white' : 'bg-gray-200'
                                                        }`}
                                                    onClick={() => setSortOption2("Anonymous")}
                                                >
                                                </button>
                                                <span>Anonymous Users</span>
                                            </div>
                                        </div>
                                        {/* <div className="flex justify-end mt-4 px-2">
                                        <span className="bg-orange-600 text-white rounded-lg text-sm p-1 cursor-pointer" onClick={filter}>Apply Filters</span>
                                    </div> */}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        {sortedData.length == 0 ? <Card className="mt-2 border-none no_pages"><center className="no-details-text"><img src={downvoteb} className="no-pages-img" /><br />No Feddbacks</center></Card> :
                            sortedData.map((data, i) => (
                                <div className="mt-2" key={i}>
                                    <Card className="border-dashed border-orange-400 rounded-[10px] border-t-2 border-r-2 border-l-0 border-b-0 mb-8">
                                        <div className="row py-2.5">
                                            <Col md={2}>
                                                <Card className="ml-2 h-full vote-bg rounded-[10px] py-4 border-none">
                                                    <center className={localStorage.getItem('junkStore') ? "cursor" : ""}> {data.vote == 1 ? <img src={upvote} onClick={(e, i) => changeUpvoteImage(data, 2)} style={{ width: "55px" }} /> : <img src={upvoteb} onClick={(e) => changeUpvoteImage(data, 1)} style={{ width: "45px" }} />}</center><br />
                                                    <center className="likes font-medium">{data.totallike == null ? 0 : data.totallike}</center><br />
                                                    <center className={localStorage.getItem('junkStore') ? "cursor" : ""}> {data.vote == -1 ? <img src={downvote} onClick={(e, i) => changeDownvoteImage(data, 2)} style={{ width: "55px" }} /> : <img src={downvoteb} onClick={(e) => changeDownvoteImage(data, 0)} style={{ width: "45px" }} />}</center>
                                                </Card>
                                            </Col>
                                            <Col md={10}>
                                                <div className="card-padding flex flex-col w-full h-[90%]">
                                                    {data.pin ? <div className='flex items-center gap-2 -mt-2'>
                                                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M3.94073 13.7296L9.08947 6.21469L6.38797 4.95496L3.94073 13.7296Z" fill="black" />
                                                            <path d="M6.20129 8.88249C8.18696 9.80842 10.4574 9.14206 11.2725 7.39412C12.0876 5.64619 11.1386 3.47859 9.15294 2.55265C7.16726 1.62672 4.89681 2.29308 4.08173 4.04102C3.26666 5.78895 4.21561 7.95655 6.20129 8.88249Z" fill="#FFB858" />
                                                        </svg>
                                                        <p className='text-orange-300 text-sm'>Pinned post</p>
                                                    </div> : <></>}
                                                    <div className='mb-1'><h6 className="t-today">{data.title}</h6>
                                                        {(data.userCreate) ?
                                                            <Dropdown className='z-40'>
                                                                <Dropdown.Toggle variant="default" style={{ float: "right" }} className="abc feed-card-a -mt-5 h-7">
                                                                    <img src={dots} style={{ width: "3px" }} />
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu className="border-orange-400 bg-white" align="end">
                                                                    <Dropdown.Item className="d-text" onClick={(e, i) => editFeedback(data)} ><span className='flex gap-2'><img src={ImgEdit} style={{ width: "20px" }} /> &nbsp;Edit</span></Dropdown.Item>
                                                                    <Dropdown.Item className="d-text" onClick={(e, i) => deleteFeedback(data)} style={{ color: "red" }}><span className='flex gap-2'><img src={imgDel} style={{ width: "20px" }} /> &nbsp;Delete</span></Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown> : null}
                                                    </div>
                                                    <div onClick={() => localStorage.getItem('junkStore') ? togglePopup(data) : setState(true)} className={`max-w-[50vw] 2xl:max-w-[40vw] flex-grow py-2 ${localStorage.getItem('junkStore') ? "cursor" : ""}`}>
                                                        <div className="posted">Posted by {data.userName == null ? "anonymous" : data.userName} &nbsp;&nbsp;{moment(data.updatedAt).fromNow()} {moment(data.updatedAt).diff(moment(data.createdAt), 'minutes') > 1 ? <span>(Edited)</span> : ''}</div>
                                                        <div className="text-gray-800 py-2 font-medium" dangerouslySetInnerHTML={{
                                                            __html: data.feedback
                                                        }} />
                                                        {
                                                            data?.tag?.length
                                                                ?
                                                                <div className='flex flex-wrap gap-x-3 gap-y-3 py-3'>
                                                                    {data?.tag?.map(tag => <Tag text={tag} />
                                                                    )}
                                                                </div>
                                                                : ''
                                                        }
                                                        {data.feedbackImage ? <div className={`${data.feedbackImage ? "my-2" : ""}`}><img src={data.feedbackImage} style={{ width: "200px", height: '150px', objectFit: 'contain', objectPosition: 'top' }} /></div> : ''}

                                                    </div>
                                                    <div className={`flex items-center mt-6 ${localStorage.getItem('junkStore') ? "cursor" : ""}`}>
                                                        <div onClick={() => localStorage.getItem('junkStore') ? togglePopup(data) : setState(true)} className={`flex items-center gap-x-2.5`}>
                                                            <img src={comment} style={{ width: "20px" }} className={localStorage.getItem('junkStore') ? "cursor" : ""} />
                                                            <span className="text-gray-400 font-medium" >{data.commentCount == null ? 0 : data.commentCount} Comments</span>
                                                        </div>
                                                        <ShareBox shareCount={data.shareCount} path={`/${data.companyName}/${data.productName}/${productType}`} type={"Feedback"} postId={data.id} callback={() => { }} />
                                                    </div>
                                                </div>
                                            </Col>
                                        </div>
                                    </Card>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductFeedbackSection
import React, { Component } from "react";
import { Button, Form, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import fedduplogo from "../images/fedduplogo.png";
import forgotImg from '../images/forgot-screen-img.png';
import './Login.css';
import axios from 'axios';
import { config } from '../Config/Config';

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            registerFlow: false,
            redirect: false
        }
    }
    forgotPassword = (e) => {
        e.preventDefault();
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var payload = {
            "email": this.state.email,
            "registerFlow": this.state.registerFlow
        }
        var self = this;
        axios.post(config.apiUrl + 'auth/sendOtp', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("Otp sent successfully!!")
                    self.setState({ redirect: true })
                } else {
                    document.getElementById('msg').innerText = msg

                }
            })
            .catch(function (error) {
                alert("Error")
                console.log(error);
            });
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    render() {
        const { redirect } = this.state
        if (redirect) {
            return <Redirect
                to={{
                    pathname: "/verifyemail",
                    state: { email: this.state.email, registerFlow: this.state.registerFlow }
                }} />
        }
        return (
            <div className="h-screen flex flex-col">
                <Link to="/login"><img alt="" src={fedduplogo} className="logo-login" /></Link>
                <div className="w-full flex-grow grid place-items-center pb-20">
                    <Card className="w-full max-w-md">
                     <center>
                    <p className="forgot-text">Forgot password</p>
                    <p className="forgot-text-sm">Please, enter your registered email. You'll receive <br />a link to reset password</p>
                    <br />
                    <img alt="" src={forgotImg} className="forgotImg" /></center><br />
                    <Form className="font_family">
                        <center>
                            <div style={{ width: "80%" }}>
                                <input type="email"
                                    className="form-control border"
                                    aria-describedby="text"
                                    value={this.state.email}
                                    name="email"
                                    placeholder="Email"
                                    onChange={this.onChange}
                                />
                                <span id="msg" className="error"></span><br />


                                    <div className='btn-outer mt-3'>
                                        <div className='btn-1' style={{ height: "40px", width: '150px' }}></div>
                                        <Button
                                            variant="default"
                                            type="submit"
                                            className="btn-2"
                                            style={{ position: "absolute", fontSize: "16px", height: "40px", width: '150px', lineHeight: "16px", left: 0, top: 0 }}
                                            onClick={this.forgotPassword}
                                        >
                                            Submit
                                        </Button>
                                    </div>

                            </div><br />
                            <Link to="/login"><span className="login-here"> Login here</span></Link>
                        </center>
                    </Form><br />
                </Card>
                </div>
            </div>
        );
    }
}

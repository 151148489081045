import React, { useEffect, useState } from 'react'
import NavBar from '../NavBar/NavBar'
import background from '../images/background-img.svg'
import arrowLeft from "../images/arrow-left-black.svg"
import camera from "../images/camera.svg";
import editIcon from "../images/edit-icon.svg"
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import useDebounce, { decrypt, uploadImage } from "../utils";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import Select from 'react-select'
import cancelWhite from "../images/cancel-x-white.svg"
import cancelBlack from "../images/cancel-x-black.svg"
import ReactSelect from '../utils/ReSelect';
import { useQuery } from 'react-query';


const getCompanyDetails = () => {
    let headers = {
        "Content-Type": 'application/json',
        "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
        "applicationType": "web"
    }
    return axios.get(config.userUrl + 'admin/getCompanyProfileDetailsByAdmin', { headers: headers })
}

function CretaeFeddup() {
    const { companyId } = useParams()
    const [productLogo, setProductLogo] = useState([])
    const [productSearchTags, setProductSearchTags] = useState([])
    const [productTags, setProductTags] = useState([])
    const [userType, setUserType] = useState("Free")
    const [form, setForm] = useState({ appType: "Desktop Application", pageType: "Public", subadmin: "", moderators: [] })
    const [errors, setErrors] = useState({})
    const [subAdmisn, setSubAdmins] = useState([])
    const [moderators, setModerators] = useState([])
    const [subAdminOptions, setSubAdminOptions] = useState([])
    const [selectedSubadmin, setSelectedSubadmin] = useState("");
    const [moderatorsOptions, setModeratorsOptions] = useState([])
    const [selectedModerators, setSelectedModerators] = useState([]);
    const [isExist, setIsExist] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [toast, setToast] = useState(false);

    const history = useHistory();

    useEffect(() => {
        getAllSubAdmins(companyId);
        getAllModerators(companyId);
    }, [])

    const { data: companyData } = useQuery("company-details", getCompanyDetails, { refetchOnWindowFocus: false, });

    const debounced = useDebounce(form?.name, 400)

    useEffect(() => {
        if (debounced) {
            validatePageName()
        }
    }, [debounced])

    useEffect(() => {
        if (toast) {
            setTimeout(() => setToast(false), 4000)
        }
    }, [toast])

    const getAllSubAdmins = (companyId) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "companyId": companyId
        }
        axios.post(config.userUrl + "admin/getAllSubAdmins", payload, { headers: headers })
            .then((response) => {
                console.log(response.data);
                setSubAdmins(response.data.subadmins);
                updateSubAdminOptions(response.data.subadmins)
            }).catch((err) => {
                console.log(err)
            });
    }
    const getAllModerators = (companyId) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "companyId": companyId
        }
        axios.post(config.userUrl + "admin/getAllModerators", payload, { headers: headers })
            .then((response) => {
                console.log(response.data);
                setModerators(response.data.moderators);
                updateModeratorOptions(response.data.moderators);
            }).catch((err) => {
                console.log(err)
            });
    }
    const validatePageName = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "name": form.name
        }
        axios.post(config.userUrl + "admin/validatePageName", payload, { headers: headers })
            .then((response) => {
                if (response.data.data) {
                    setIsExist(true);
                    setToast('Page Name already exist')
                }
            }).catch((err) => {
                console.log(err)
            });
    }
    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        })
        if (!!errors[field]) {
            setErrors({
                ...errors,
                [field]: null
            })
        }
    }
    let addImage = async (e) => {
        let img_url = await uploadImage(e.target.files[0]);
        console.log(img_url);
        // if (img_url) setCompanyIcon(img_url);
        setForm({
            ...form,
            ["productLogo"]: img_url
        })
    }
    let handleRemoveTag = (tagToRemove) => {
        let filteredTags = productSearchTags.filter((tag) => tag !== tagToRemove)
        setProductSearchTags(filteredTags);
    };
    let handleKeyDown = (event) => {
        if (event.key === "Enter") {
            const newTag = event.target.value.trim();
            console.log(newTag);
            setProductSearchTags([...productSearchTags, newTag])
            setForm({
                ...form,
                ["searchTags"]: [...productSearchTags, newTag]
            })
            event.preventDefault();
            event.target.value = "";
        }
    }
    let handleRemoveTag2 = (tagToRemove) => {
        let filteredTags = productTags.filter((tag) => tag !== tagToRemove)
        setProductTags(filteredTags);
    };
    let handleKeyDown2 = (event) => {
        if (event.key === "Enter") {
            const newTag = event.target.value.trim();
            setProductTags([...productTags, newTag])
            setForm({
                ...form,
                ["productTags"]: [...productTags, newTag]
            })
            event.preventDefault();
            event.target.value = "";
        }
    }
    let handleRemoveSubadmin = (pageToRemove) => {
        setSelectedSubadmin("");
        setForm({
            ...form,
            ["subadmin"]: ""
        })
    };
    let handleChange = (option) => {
        const newSubadmin = option.value;
        setSelectedSubadmin(option.label);
        setForm({
            ...form,
            ["subadmin"]: newSubadmin
        })
    }
    const updateSubAdminOptions = (subadmins) => {
        let currentOptions = subadmins.map((item) => {
            let obj = {
                value: item.uuid,
                label: item.userName
            }
            return obj;
        })
        setSubAdminOptions(currentOptions)
    }
    const updateModeratorOptions = (moderators) => {
        let currentOptions = moderators.map((item) => {
            let obj = {
                value: item.userName,
                label: item.userName
            }
            return obj;
        })
        setModeratorsOptions(currentOptions)
    }
    const addModerator = (option) => {
        const newModerator = option.value;
        let newOptions = moderatorsOptions.filter((item) => item.value !== newModerator)
        console.log(newModerator, newOptions);
        setModeratorsOptions(newOptions)
        setSelectedModerators([...selectedModerators, newModerator])
        setForm({
            ...form,
            ["moderators"]: [...selectedModerators, newModerator]
        })

    }
    const deleteModerator = (moderator) => {
        let newSelectedModeratorsArray = selectedModerators.filter((item) => item !== moderator)
        setSelectedModerators(newSelectedModeratorsArray);
        setModeratorsOptions([...moderatorsOptions, { value: moderator, label: moderator }])
        setForm({
            ...form,
            ["moderators"]: newSelectedModeratorsArray
        })
    }
    const handelSubmit = async (e) => {
        e.preventDefault();
        console.log(form);
        submitForm();
    }
    const submitForm = () => {
        try {
            addProduct();
        } catch (error) {
            console.log(error);
        }
    }
    const addProduct = () => {
        if (!isExist) {
            if (!form["website"]) return setToast("Website is required")

            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                "applicationType": "web"
            }
            let payload = {
                "productName": form["name"],
                "productIcon": form["productLogo"],
                "productWebsite": form["website"],
                "productType": form["appType"],
                "searchTags": form["searchTags"] || [],
                "pageType": form["pageType"],
                "productDescription": form["description"],
                "productFaq": form["faq"],
                "productHelp": form["support"],
                "tags": form["productTags"] || [],
                "subadmin": form["subadmin"],
                "moderators": form["moderators"]
            }
            axios.post(config.userUrl + "admin/addNewProductByAdmin", payload, { headers: headers })
                .then((response) => {
                    if (response.data.message == "created successfully") {
                        setIsSuccess(true)
                        setTimeout(() => {
                            window.location.replace('/admindashboard')
                        }, 5000);
                    }
                }).catch((err) => {
                    console.log(err)
                });
        }
    }
    return (
        <div>
            {
                isSuccess &&
                <div className='fixed inset-0 grid place-items-center' style={{ zIndex: 13000 }}>
                    <div className='fixed inset-0 bg-black bg-opacity-50' onClick={() => window.location.replace('/admindashboard')}></div>
                    <div className='w-full max-w-2xl min-h-[400px] z-10 bg-white rounded-lg flex flex-col items-center justify-center'>
                        <svg width="176" height="176" viewBox="0 0 176 176" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_725_2659)">
                                <path d="M88.1145 175.859C136.439 175.859 175.615 136.684 175.615 88.3594C175.615 40.0345 136.439 0.859375 88.1145 0.859375C39.7896 0.859375 0.614502 40.0345 0.614502 88.3594C0.614502 136.684 39.7896 175.859 88.1145 175.859Z" fill="#E6570E" />
                                <path d="M41.2393 93.0469L78.7393 130.547L134.989 46.1719" stroke="#FFB858" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_725_2659">
                                    <rect width="176" height="175" fill="white" transform="translate(0 0.859375)" />
                                </clipPath>
                            </defs>
                        </svg>
                        <h1 className='text-3xl font-medium text-orange my-4'>Added Successfully</h1>
                    </div>
                </div>
            }
            {toast && <div className='fixed top-2 left-1/2 transform -translate-x-1/2 bg-white rounded-lg py-2 px-5 max-w-md shadow-lg font-medium text-red-500 min-w-[300px] text-center' style={{ zIndex: 1000001 }}>
                {toast}
            </div>}
            <NavBar />
            <div className="bg-gray-100 relative h-auto pb-9" >
                <img src={background} style={{ marginTop: "3rem", width: "100%" }} />
                <img src={arrowLeft} alt='' className='absolute top-16 xl:left-32 lg:left-24 left-20 cursor-pointer z-40' onClick={() => history.goBack()} />
                {/* <img src={editIcon} alt='' className='absolute top-16 right-10' /> */}
                <Row className='relative min-w-full -top-28 flex items-start justify-center'>
                    <Col md={5} className='relative'>
                        <div className='absolute top-0 -left-40 w-36 h-36 flex flex-col items-center justify-center bg-white rounded-full group overflow-hidden cursor-pointer border-4 border-white shadow-md'>
                            {productLogo.length > 0 ? <>
                                <img src={URL.createObjectURL(productLogo[0])} alt="" className="w-full h-full object-cover" />
                                <div className='absolute inset-0 hidden group-hover:flex'>
                                    <label htmlFor="logo" className='w-full h-full bg-black bg-opacity-40 cursor-pointer flex flex-col items-center justify-center'>
                                        <h1 className='text-lg text-white px-3 text-center'>Upload new logo</h1>
                                        <input id='logo' type='file' className="hidden" onChange={(e) => {
                                            setProductLogo([e.target.files[0]]);
                                            addImage(e);
                                        }} />
                                    </label>
                                </div>
                            </> :
                                <>
                                    <img src={camera} alt="" className="mt-4" />
                                    <label htmlFor='logo' className="items-center px-4 text-center cursor-pointer ">
                                        <span className="mt-2 text-xs">Upload your company logo</span>
                                        <input id='logo' type='file' className="hidden" onChange={(e) => {
                                            setProductLogo([e.target.files[0]]);
                                            addImage(e);
                                        }} />
                                    </label>
                                </>}
                        </div>


                        <Form>
                            <Form.Group controlId='name'>
                                <Form.Control
                                    required
                                    placeholder='Enter feddup page name'
                                    className='border rounded text-black'
                                    value={form.name}
                                    onChange={(e) => { setIsExist(false); setField('name', e.target.value) }}
                                    isInvalid={!!errors.name}
                                />
                                <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
                                {isExist && <h1 type='invalid' className='text-white font-medium'>Page Name already exist</h1>}
                            </Form.Group>
                            <Form.Group controlId='appType'>
                                <div className="flex border px-2 text-lg text-center rounded-lg h-9 bg-white mt-2 cursor-pointer">
                                    <select className="font-normal focus:outline-none w-full px-2 cursor-pointer" id="type" onChange={(e) => setField("appType", e.target.value)}>
                                        <option value="Public">Desktop Application</option>
                                        <option value="Private">Web Application</option>
                                    </select>
                                </div>
                                <Form.Control.Feedback type='invalid'>{errors.appType}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId='description'>
                                <Form.Control
                                    className='border rounded w-full mt-2 resize-none bg-white px-2'
                                    as="textarea"
                                    rows={4}
                                    type="textarea"
                                    placeholder="Product Description"
                                    value={form.description}
                                    isInvalid={!!errors.description}
                                    onChange={(e) => setField("description", e.target.value)}
                                >
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>{errors.description}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId='website'>
                                <Form.Control
                                    placeholder='Enter Product Website'
                                    className='border rounded bg-white mt-2'
                                    value={form.website}
                                    onChange={(e) => setField("website", e.target.value)}
                                    isInvalid={!!errors.website}
                                />
                                <Form.Control.Feedback type='invalid'>{errors.website}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId='faq'>
                                <Form.Label className='mb-0 mt-2 font-medium text-lg'>FAQ Link</Form.Label>
                                <Form.Control
                                    placeholder='FAQ link'
                                    className='border rounded bg-white mt-2'
                                    value={form.faq}
                                    onChange={(e) => setField("faq", e.target.value)}
                                    isInvalid={!!errors.faq}
                                />
                                <Form.Control.Feedback type='invalid'>{errors.faq}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId='support'>
                                <Form.Label className='mb-0 mt-2 font-medium text-lg'>Support Link</Form.Label>
                                <Form.Control
                                    placeholder='support link'
                                    className='border rounded bg-white mt-2'
                                    value={form.support}
                                    onChange={(e) => setField("support", e.target.value)}
                                    isInvalid={!!errors.support}
                                />
                                <Form.Control.Feedback type='invalid'>{errors.support}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId='productTags'>
                                <div className="mt-2">
                                    <label className="font-medium text-lg mb-0">Product Search Tags</label>
                                    <p className="font-normal text-sm mb-2">Tags will help out for searching and finding your product better. Type the tag and hit enter <span className='float-right font-medium text-base'>{productSearchTags.length}/7</span></p>
                                    <div className="flex flex-wrap items-start p-2 rounded border border-black min-h-[120px] bg-white">
                                        {productSearchTags && productSearchTags.map((tag, i) => {
                                            return (
                                                <span key={i} className="mr-2 rounded-xl min-w-[80px] text-center px-2 text-black font-medium" style={{ backgroundColor: "#FFB858" }}>{tag}<span className="float-right cursor-pointer" onClick={() => handleRemoveTag(tag)}><img src={cancelBlack} alt='' /></span></span>
                                            )
                                        })
                                        }
                                        {productSearchTags.length < 7 ? <input type="text" className="focus:outline-none" onKeyDown={handleKeyDown} placeholder="Enter Tag" maxLength="20" /> : ''}
                                    </div>
                                </div>
                            </Form.Group>
                            <Form.Group controlId='searchTags'></Form.Group>
                            <div className="mt-2">
                                <label className="font-medium text-lg mb-0">Product Tags</label>
                                <p className="font-normal text-xs mb-2">These tags will help users and admin while share post,filtering and sorting, and to give an overall overview of the post<span className='float-right font-medium text-base'>{productTags.length}/15</span></p>
                                <div className="flex flex-wrap items-start p-2 rounded border border-black min-h-[120px] bg-white">
                                    {productTags && productTags.map((tag, i) => {
                                        return (
                                            <span key={i} className="mr-2 rounded-xl min-w-[80px] text-center text-white px-2" style={{ backgroundColor: "#E6570E" }}>{tag}<span className="float-right cursor-pointer" onClick={() => handleRemoveTag2(tag)}><img src={cancelWhite} alt='' /></span></span>
                                        )
                                    })
                                    }
                                    {productTags.length < 15 ? <input type="text" className="focus:outline-none" onKeyDown={handleKeyDown2} placeholder="Enter Tag" maxLength="20" /> : ''}
                                </div>
                            </div>
                            {companyData?.data?.companyDetail?.membershipType == "Premium" ?
                                <div className='flex flex-col mt-4'>
                                    <p className='text-xl font-semibold mb-4'>Change Page Type</p>
                                    <div className="flex border p-2 text-xl text-center rounded-xl bg-white">
                                        <select className="font-normal focus:outline-none w-full cursor-pointer px-2" id="type" onChange={(e) => setField("pageType", e.target.value)}>
                                            <option value="Public">Public</option>
                                            <option value="Private">Private</option>
                                        </select>
                                    </div>
                                    <p className='text-xs w-[50vw]'>Public pages: Listed page, Users can view contents with out following the page and post anonymously.</p>
                                    <p className='text-xs'>Private pages: These pages cannot be searched and posting anonymously is not allowed.
                                        Converting a page to private from public will remove all followers</p>
                                    <hr className="bg-orange-600  w-[40rem] rounded-sm h-[1px] my-8 -pl-10" />
                                    <div>
                                        <p className='text-xl font-medium mb-2'>User Access</p>
                                        <p className='text-md font-medium'>Select sub admins and moderators how can this feddup page</p>
                                        <p className='text-md font-medium'>Users must be added by admin in company access portal.</p>
                                        <p className='text-sm font-medium'>This step can be skipped</p>
                                        <p className='text-lg font-medium mt-4'>Select Subadmin</p>
                                        <div className="flex w-full p-2 rounded border border-black  bg-white">
                                            {selectedSubadmin ?
                                                <div className='flex w-full justify-between'>
                                                    <div className="mr-2 rounded-xl min-w-[80px] text-center px-2" >{selectedSubadmin}</div>
                                                    <div className="text-xl font-semibold cursor-pointer" onClick={() => handleRemoveSubadmin()}><img src={cancelBlack} alt='' /></div>
                                                </div>
                                                :
                                                <ReactSelect options={subAdminOptions} handleChange={handleChange} />
                                            }

                                            {/* <input type="text" className="focus:outline-none" onKeyDown={handleKeyDown} placeholder="Enter Tag" maxLength="20" /> */}
                                        </div>

                                        <p className='text-lg font-medium mt-4'>Select Moderators</p>
                                        <div className="flex flex-wrap items-start p-2 rounded border border-black min-h-[120px] bg-white">
                                            {selectedModerators && selectedModerators.map((moderator, i) => {
                                                return (
                                                    <span key={i} className="mr-2 rounded-xl min-w-[80px] text-center px-2 text-white" style={{ backgroundColor: "#FFB858" }}>{moderator}<span className="float-right cursor-pointer" onClick={() => deleteModerator(moderator)}><img src={cancelWhite} alt='' /></span></span>
                                                )
                                            })
                                            }
                                            {/* <input type="text" className="focus:outline-none" onKeyDown={handleKeyDown} placeholder="Enter Tag" maxLength="20" /> */}
                                            <ReactSelect options={moderatorsOptions} handleChange={addModerator} />
                                        </div>

                                    </div>
                                </div> : ""}
                            <div className='btn-outer float-right my-4'>
                                <div className='btn-1 ml-auto' style={{ width: "120px", height: "30px" }}>
                                </div>
                                <Button
                                    variant="default" size="md"
                                    type="submit"
                                    style={{ float: "right", width: "120px", height: "30px", lineHeight: "14px", fontSize: "18px" }}
                                    className="btn-2 mr-[1px]"
                                    onClick={handelSubmit}
                                >
                                    Create
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default CretaeFeddup



import React, { Component } from "react";
import { Button, Form, Container, Card, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import Header from "../Header/Header";
import landingpage from "../images/landingpage.png";
import lpbg from "../images/lp-bg-img.png";
import thanksforregistering from '../images/thanksforregistering.png';
import popup from "../images/l-popup.png";
import sharefeedback from "../images/l-feedback.png";
import feddup from "../images/l-feddup.png";
import upvote from "../images/l-upvote.png";
import rightsemicircle from "../images/right-semi-circle.png";
import leftsemicircle from "../images/left-semi-circle.png";
import followfeddup from "../images/l-followfeddup.png";
import communities from "../images/communities.png";
import activemembers from "../images/activemembers.png";
import feedback from "../images/feedbackscounting.png";
import './LandingPage.css';
import Footer from "./Footer";

export default function LandingPage() {

    return (
        <div>
            <Header />
            <div className="enable-center">
                <Row style={{ width: "100%" }}>
                    <Col md={1}></Col>
                    <Col md={5} className="flex flex-col justify-center">
                        <div><img src={lpbg} className="lpbg-img" /><img src={lpbg} className="lpbg-img_" /></div><br />
                        <div><img src={lpbg} className="lpbg-img-left" /><img src={lpbg} className="lpbg-img-left_" /></div>
                        <div className="fd-top-ab">
                            <div className='l-heading'>Hello feddup user! Love a Product? Hate a Product?</div>
                            <div className='l-text'>Use feddup to review, provide feedbacks and suggestions to all the tools and websites you use. Follow feddup product pages and stay upto date with the products you love and hate.</div>
                        </div>
                    </Col>
                    <Col md={1}></Col>
                    <Col md={5} className="flex flex-col justify-center">
                        <center> <img src={thanksforregistering} style={{ width: "66%" }} /></center>
                    </Col>
                </Row>
            </div>
            <img src={rightsemicircle} className="rightsemicircle" />
            <Row style={{ width: "100%", marginTop: "1rem" }}>
                <img src={leftsemicircle} className="leftsemicircle" />
                <Col md={5} className="ml-3 flex flex-col justify-center">
                    <center> <img src={landingpage} style={{ width: "66%" }} /></center>
                </Col>
                <Col md={1}></Col>
                <Col md={5} className="flex flex-col justify-center">
                    <div className="l-heading">No one heard you?</div>
                    <div className="l-text">Feddup pages allows you to follow products, check your posts status, view fellow users posts, follow up from companies, announcements from companies and much more.</div>

                </Col>
            </Row><br /><br />
            {/* <div id="upper-line"></div>
            <div id="lower-line"></div>
            <div id="lower-line1"></div>
            <div id="upper-line1"></div>
            <div id="lower-line2"></div> */}
            <div className="mt-4">
                <center className="l-growingup">How Does FeddUp Work?</center>
                <center className="l-text">5 simple steps… its that simple</center><br />
                <div>
                    <Row style={{ width: "100%" }}>
                        <Col md={6} className="flex flex-col justify-center">
                            <center > <img src={popup} className="pop-img" /></center>
                        </Col>
                        <Col md={5} className="flex flex-col justify-center">
                            <div className="feddupwork-textheading">Click on popup</div>
                            <div className="feddupwork-text">Something did not work? have a better idea? Click on the feddup icon on the tool/website to get started</div>

                        </Col>
                    </Row><br />
                    <Row style={{ width: "100%" }}>
                        <Col md={6} className="flex flex-col justify-center align-items-right">
                            <div className="feddupwork-textheading">Share your feedback or suggestion</div>
                            <div className="feddupwork-text-left">Use Text, screenshots or gifs to share your feedbacks, suggestions and reviews signed up or anonymously. Users who have signed up receive updates on the status of the post</div>

                        </Col>
                        <Col md={6} className="flex flex-col justify-center">
                            <center> <img src={sharefeedback} className="pop-img" /></center>
                        </Col>
                    </Row><br />
                    <Row style={{ width: "100%" }}>
                        <Col md={6} className="flex flex-col justify-center">
                            <center> <img src={feddup} className="pop-img" /></center>
                        </Col>
                        <Col md={5} className="flex flex-col justify-center">
                            <div className="feddupwork-textheading">Check FeddUp page</div>
                            <div className="feddupwork-text">Want to learn more about what’s broken or what is being fixed? Feddup pages provides all the information you need</div>

                        </Col>
                    </Row><br />
                    <Row style={{ width: "100%" }}>
                        <Col md={6} className="flex flex-col justify-center align-items-right">
                            <div className="feddupwork-textheading">Up vote your favourite feedbacks/suggestions </div>
                            <div className="feddupwork-text-left">Think a feature works? Maybe not? Like and dislike posts from users across the world</div>
                        </Col>
                        <Col md={6} className="flex flex-col justify-center">
                            <center> <img src={upvote} className="pop-img" /></center>
                        </Col>
                    </Row><br />
                    <Row style={{ width: "100%" }}>
                        <Col md={6} className="flex flex-col justify-center">
                            <center> <img src={followfeddup} className="pop-img" /></center>
                        </Col>
                        <Col md={5} className="flex flex-col justify-center">
                            <div className="feddupwork-textheading">Follow FeddUp page for
                                updates</div>
                            <div className="feddupwork-text">Be a part of the community and stay up to date with latest updates on all the products you use daily</div>

                        </Col>
                    </Row>
                </div>
            </div><br />

            <div style={{ background: "#F5F5F5", width: "100%", zIndex: "100" }}><br />
                <center className="l-growingup">FeddUp is Growing Up</center>
                <center className="l-text">Be a part of all the feddup users just like you sign up for free and the world know how feddup you are</center>
                <center className="l-lastupdated">Last Updated Jan {new Date().getFullYear()}</center><br /><br />

                    <center>
                        <Row className="grow-card">
                            <Col md={4}>
                                <Card className="l-card"><br />
                                    <img className="l-card-img" src={activemembers} />
                                    <div className="mt-4 l-card-count">123K+</div>
                                    <div className="mt-4 l-card-text">Daily active members</div><br />
                                </Card>
                            </Col>
                            <Col md={4}>
                                <Card className="l-card"><br />
                                    <img className="l-card-img" src={communities} />
                                    <div className="mt-4 l-card-count">45K+</div>
                                    <div className="mt-4 l-card-text">Communities</div><br />
                                </Card>
                            </Col>
                            <Col md={4}>
                                <Card className="l-card"><br />
                                    <img className="l-card-img" src={feedback} />
                                    <div className="mt-4 l-card-count">6M+</div>
                                    <div className="mt-4 l-card-text">Feedback & Counting</div><br />
                                </Card>
                            </Col>
                        </Row>
                    </center>
                <br /><br /><br />
            </div>
            <Footer />
        </div>
    );
}


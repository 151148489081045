import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import usePayment from '../utils/usePayment';
import PaymentSuccess from '../SignUp/PaymentSuccess';
import { config } from '../Config/Config';
import axios from 'axios';
import toast from 'react-hot-toast';
import { decrypt } from '../utils';

function UpgradePopUp({ onClose }) {
    const history = useHistory()
    const [pricing, setPricing] = useState([]);
    const [isCopied, setIsCopied] = useState(false);
    const { createOrder, success, setSuccess } = usePayment()
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getAllPlans();
    }, []);


    const getAllPlans = () => {
        const headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + (localStorage.getItem('junkStore') ? decrypt(localStorage.getItem('junkStore')) : ''),
            "applicationType": "web"
        };
        const payload = {
            planDuration: 'Yearly'
        };
        axios.post(config.userUrl + 'user/getAllPlan', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setLoading(false)
                    setPricing(response.data.planDetails);
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    useEffect(() => {
        if (isCopied) {
            setTimeout(() => setIsCopied(false), 2000)
        }
    }, [isCopied])

    useEffect(() => {
        if (success) {
            setTimeout(() => {
                closeSuccess()
            }, 3000)
        }
    }, [success])

    const closeSuccess = () => {
        setSuccess(false);
        window.location.replace('/admindashboard');
    }

    const handleSubscription = () => {
        let plan = pricing.find(el => el.planName == "Company");
        if (plan) {
            createOrder({ planId: plan.id })
        }
        else {
            toast.error("Plan not found", { id: 'err' })
        }
    }

    if (success) return <PaymentSuccess closeSuccess={closeSuccess} />

    return (
        <div className='fixed inset-0 bg-transparent grid place-items-center' style={{ zIndex: 12000 }}>
            <div className='bg-black bg-opacity-60 fixed inset-0' onClick={onClose}></div>
            <div className='bg-[#E6570E] w-full max-w-3xl z-10 rounded-lg min-h-[420px] p-4 relative opacTranslate flex flex-col justify-between text-white'>
                <h1 className='text-3xl font-bold max-w-[31rem] text-white'>Upgrade now & unlock more from feddup!</h1>

                {
                    loading ?
                        <div className='rounded-xl p-4 w-full max-w-md cursor-pointer flex items-center justify-center gap-4 mx-auto h-72'>
                            <div className='border-8 rounded-full border-b-transparent animate-spin w-20 h-20'></div>
                        </div>
                        :

                        <div onClick={handleSubscription} className='bg-[#649E36] rounded-xl p-4 w-full max-w-md cursor-pointer flex items-center justify-between gap-4 mx-auto my-10'>
                            <svg width="181" height="182" viewBox="0 0 181 182" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M73.1225 17.9677L96.1259 81.4434L163.222 73.9324L99.7465 96.9359L107.257 164.032L84.254 100.556L17.1578 108.067L80.6335 85.0639L73.1225 17.9677Z" fill="#FFB633" />
                                <path d="M54.3521 68.7402L88.2689 82.784L112.449 55.1632L98.4052 89.08L126.026 113.26L92.1091 99.2162L67.9292 126.837L81.9729 92.9202L54.3521 68.7402Z" fill="#FFB633" />
                                <path d="M81.195 92.5446C82.1908 94.5291 85.1397 97.9402 92.556 99.3899C98.5301 94.7875 99.4896 90.3816 99.4709 88.1485C99.4813 87.341 99.3918 86.9123 99.3221 86.7927C98.6656 84.29 96.0745 82.5938 93.5719 83.2504C91.0693 83.9069 89.3731 86.498 90.0296 89.0006C89.3731 86.498 86.7821 84.8017 84.2794 85.4583C81.7768 86.1149 80.0806 88.7059 80.7371 91.2085C80.6175 91.2782 80.7767 91.8266 81.195 92.5446Z" fill="white" />
                            </svg>
                            <h1 className='text-3xl font-medium'>Upgrade now!</h1>
                        </div>
                }

                <div className='flex items-center justify-around gap-4'>
                    <p className='text-2xl font-medium'>View pricing and benifits of premium</p>
                    <button className='py-2 px-10 rounded-lg bg-[#36919E] font-medium text-lg outline-none border-none' onClick={() => history.push('/pricing')}>View Benefits</button>
                </div>

            </div>
        </div>
    )
}

export default UpgradePopUp
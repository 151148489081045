export const Moderators = [
    {
        id: 1,
        image: "../square.png",
        name: "Sunil Sharma"
    },
    {
        id: 2,
        image: "../square.png",
        name: "Pratimesh Mahajani"
    },
    {
        id: 3,
        image: "../square.png",
        name: "Mukundh Bhushan"
    },
    {
        id: 4,
        image: "../square.png",
        name: "pname2"
    },
]
import React, { Component } from "react";
import { Button, Form, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import fedduplogo from "../images/fedduplogo.png";
import forgotImg from '../images/forgot-screen-img.png';
import './Login.css';

export default class SuccessResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            registerFlow: false,
        }
    }
    render() {
        return (
            <div>
                <a href="/"><img alt="" src={fedduplogo} className="logo-login" /></a>
                <section className="flex items-center justify-center min-h-[75dvh]">
                    <Card className="flex flex-col items-center justify-center gap-6 py-16 w-full max-w-md">
                        <svg width="176" height="176" viewBox="0 0 176 176" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_725_2659)">
                                <path d="M88.1145 175.859C136.439 175.859 175.615 136.684 175.615 88.3594C175.615 40.0345 136.439 0.859375 88.1145 0.859375C39.7896 0.859375 0.614502 40.0345 0.614502 88.3594C0.614502 136.684 39.7896 175.859 88.1145 175.859Z" fill="#E6570E" />
                                <path d="M41.2393 93.0469L78.7393 130.547L134.989 46.1719" stroke="#FFB858" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_725_2659">
                                    <rect width="176" height="175" fill="white" transform="translate(0 0.859375)" />
                                </clipPath>
                            </defs>
                        </svg>
                        <h1 className="text-3xl font-medium text-center">Password<br />reset successfully</h1>
                        <Link to="/login" style={{ width: '120px' }}>
                            <div className='btn-outer mt-3'>
                                <div className='btn-1' style={{ height: "36px", width: '120px' }}></div>
                                <Button
                                    variant="default"
                                    type="submit"
                                    className="btn-2"
                                    style={{ position: "absolute", fontSize: "18px", height: "36px", width: '120px', lineHeight: "16px", left: 0, top: 0 }}
                                >
                                    Login
                                </Button>
                            </div>
                        </Link>
                    </Card>
                </section>
            </div>
        );
    }
}

import { BrowserRouter as Router } from 'react-router-dom/cjs/react-router-dom.min';
import './App.css';
import Routes from './Routes';

import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'
import { Toaster } from 'react-hot-toast';

const queryClient = new QueryClient();

// vekow19403@ksyhtc.com // user
// ankit@sugoilabs.com // admin
// password for all Ankit@12345
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes />
      </Router>
      <div><Toaster style={{ zIndex: 99999 }} /></div>
      <div id="password" className="errormsg"></div>
      <div id="error" className="errormsg"></div>
      <div id="status" className="errormsg"></div>
      {/* <ReactQueryDevtools initialIsOpen={false} position='bottom-right'/> */}
    </QueryClientProvider >
  );
}

export default App;
// client id: 1fa837eb2bad95d
// client secret:44a61f048c75bf66cdcbcaee02b0c983d0b2dc91



const PaymentSuccess = ({ closeSuccess }) => {
    return (
        <div className='fixed inset-0 bg-transparent grid place-items-center' style={{ zIndex: 50000 }}>
            <div className='bg-black bg-opacity-60 fixed inset-0' onClick={() => closeSuccess()}></div>
            <div className='bg-[#649E36] w-full max-w-3xl z-10 rounded-lg min-h-[420px] p-4 relative opacTranslate grid place-items-center'>
                <svg className="absolute -top-28 -left-28 w-60 h-60" viewBox="0 0 241 241" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M97.4944 23.2785L128.166 107.913L217.627 97.8982L132.993 128.569L143.008 218.031L112.336 133.397L22.8748 143.411L107.509 112.74L97.4944 23.2785Z" fill="#FFB633" />
                    <path d="M72.4679 90.9753L117.69 109.7L149.93 72.8725L131.205 118.095L168.033 150.335L122.811 131.61L90.5706 168.438L109.296 123.215L72.4679 90.9753Z" fill="#FFB633" />
                    <path d="M108.257 122.714C109.585 125.36 113.517 129.908 123.405 131.841C131.371 125.705 132.65 119.83 132.625 116.853C132.639 115.776 132.52 115.204 132.427 115.045C131.551 111.708 128.097 109.446 124.76 110.322C121.423 111.197 119.162 114.652 120.037 117.989C119.162 114.652 115.707 112.39 112.37 113.266C109.033 114.141 106.772 117.596 107.647 120.933C107.487 121.026 107.7 121.757 108.257 122.714Z" fill="white" />
                </svg>

                <svg className="absolute -bottom-28 -right-28 w-60 h-60" viewBox="0 0 241 241" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M143.019 23.3087L133.011 112.771L217.647 143.437L128.185 133.428L97.5191 218.064L107.528 128.602L22.8915 97.9364L112.354 107.945L143.019 23.3087Z" fill="#FFB633" />
                    <path d="M90.5862 72.9059L122.829 109.731L168.05 91.0035L131.224 123.246L149.952 168.467L117.71 131.641L72.4886 150.369L109.314 118.127L90.5862 72.9059Z" fill="#FFB633" />
                    <path d="M108.604 117.218C108.622 120.179 110.132 125.998 118.139 132.113C127.999 130.141 131.749 125.442 133.047 122.762C133.536 121.803 133.682 121.238 133.67 121.053C134.364 117.674 132.269 114.116 128.89 113.422C125.51 112.728 121.952 114.823 121.258 118.202C121.952 114.823 119.857 111.264 116.478 110.571C113.099 109.877 109.54 111.971 108.847 115.351C108.662 115.363 108.529 116.113 108.604 117.218Z" fill="white" />
                </svg>
                <div className="flex flex-col items-center justify-center gap-3 p-5 text-white">
                    <h1 className='text-5xl font-bold text-center tracking-wide'>Hurray !</h1>
                    <p className="text-3xl text-center tracking-wide font-bold mb-3">You are now a Premium company!</p>
                    <p className="max-w-xl text-center tracking-wide text-lg">Connect better with your user base and improve your product much better and faster!</p>
                </div>
            </div>
        </div>
    )
}


export default PaymentSuccess
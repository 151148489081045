import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { config } from '../Config/Config';
import cancelWhiteIcon from "../images/cancel-x-white.svg";
import { Button, Col, Dropdown, Form, FormControl, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import { decrypt, loaderProps, suggestionsOptions } from '../utils';
import { CircleFadeLoader } from 'react-loaders-kit';
import fillTag from "../images/fill-tag.svg";
import emptyTag from "../images/empty-tag-gray.svg";
import pinOrange from "../images/pinned-orange.svg";
import pinGray from "../images/pinned-gray.svg"
import pinkDot from "../images/pink-dot.svg";
import AnnouncementComments from "./AnnouncementComments"
import ViewAnnouncementImage from '../UsersFeed/ViewAnnouncementImage';
import Tag from '../utils/Tag';
import CreateAnnouncement from './CreateAnnouncement';

function ProductAnnouncements({ productid, companyId, membershipType, selectedVersionId, item, productTags, userRole }) {
    const [section, setSection] = useState("Announcements");
    const [openStates, setOpenStates] = useState([]);
    const [selectedTags, setSelectedTags] = useState(Array(productTags?.length).fill(false));
    const [show, setShow] = useState(false)
    const [sortedData, setSortedData] = useState([])
    const [sortOption, setSortOption] = useState('')
    const [sortOption2, setSortOption2] = useState('')
    const [sortOption3, setSortOption3] = useState([])
    const [searchInput, setSearchInput] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const [announcements, setAnnouncements] = useState([])
    const [loading, setLoading] = useState(false)
    const [pinnedAnnouncementsCount, setPinnedAnnouncementsCount] = useState(0)
    const [showToast, setShowToast] = useState(false)
    const [announcementPop, setAnnouncementPop] = useState(false);
    const [viewSuggestionImage, setViewSuggestionImage] = useState(false);
    const [popupSuggestionImage, setPopupSuggestionImage] = useState("");
    const [announcementPopData, setAnnouncementPopData] = useState([]);
    const [announcementData, setAnnouncementData] = useState([]);
    const [selectedValue, setSelectedValue] = useState(0);
    const [viewAnnouncementImage, setViewAnnouncementImage] = useState(false);
    const [popupAnnouncementImage, setPopupAnnouncementImage] = useState("");
    const [announcePopup, setAnnouncePopup] = useState(false);

    useEffect(() => {
        setSortedData([]);
        getAllAnnouncements();
    }, [selectedVersionId])

    const getAllAnnouncements = () => {
        if (selectedVersionId === "") return;
        setLoading(true);
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "productId": productid,
            "versionId": selectedVersionId
        }
        axios.post(config.userUrl + "admin/getProductAnnouncements", payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    setAnnouncements(response.data.announcementDetails)
                    setSortedData(response.data.announcementDetails)
                    setOpenStates(Array(response.data.announcementDetails.length).fill(false))
                    setPinnedAnnouncementsCount(response.data.pinnedAnnouncementsCount)
                }
            })
            .then(() => {
                setSortOption("Latest to Oldest")
                setSortOption2("All")
                setSortOption3([])
                setLoading(false);
            })
            .catch(function (error) {
                console.log("Error", error);
                setLoading(false);
            });
    };
    const pinAnnouncement = (announcement) => {
        if (pinnedAnnouncementsCount === 5) {
            setShowToast(true);
            setTimeout(() => {
                setShowToast(false)
            }, 3000)
            return;
        }
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "id": announcement.id
        }
        axios.post(config.userUrl + "admin/pinAnnouncement", payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    announcement.pin = true;
                    setPinnedAnnouncementsCount(pinnedAnnouncementsCount + 1)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const unpinAnnouncement = (announcement) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "id": announcement.id,
        }
        axios.post(config.userUrl + "admin/unpinAnnouncement", payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    announcement.pin = false;
                    setPinnedAnnouncementsCount(pinnedAnnouncementsCount - 1)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        const currentData = announcements;
        if (searchValue !== '') {
            const filteredData = currentData.filter((item) => {
                return item.announcement.toLowerCase().includes(searchValue.toLowerCase())
            })
            setFilteredResults(filteredData)
        }
        else {
            setFilteredResults(currentData)
        }
    }
    const handleButtonClick = (rowIndex) => {
        setOpenStates(openStates.map((isOpen, index) => index === rowIndex ? !isOpen : isOpen));
    }
    const handleTagSelectButtonClick = (rowIndex) => {
        let newSelectedTagsArray = selectedTags.map((isOpen, index) => rowIndex === index ? !isOpen : isOpen);
        setSelectedTags(newSelectedTagsArray);
        let tagsSelected = [];
        productTags.forEach((tag, i) => {
            if (newSelectedTagsArray[i] === true)
                tagsSelected.push(tag);
        })
        setSortOption3(tagsSelected);
    }
    useEffect(() => {
        filter()
    }, [sortOption, sortOption2, sortOption3, announcementData])
    const filter = () => {
        let announcementsArray = [...announcements]
        let sortedAnnouncement = []
        if (sortOption2.length > 0) {
            if (sortOption2 === "Pinned Only") {
                sortedAnnouncement = announcementsArray.filter(announcement => announcement.pin === true)
            }
            else if (sortOption2 === "Except Pinned") {
                sortedAnnouncement = announcementsArray.filter(announcement => announcement.pin === false)
            }
            else {
                sortedAnnouncement = [...announcements];
            }
            announcementsArray = [...sortedAnnouncement];
        }
        if (sortOption.length > 0) {
            if (sortOption === "Latest to Oldest") {
                sortedAnnouncement = announcementsArray.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            }
            else {
                sortedAnnouncement = announcementsArray.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
            }
            announcementsArray = [...sortedAnnouncement];
        }
        if (sortOption3.length > 0) {
            sortedAnnouncement = announcementsArray.filter(announcement => {
                let currentTagArray = announcement.tag;
                const filteredArray = currentTagArray.filter(element => sortOption3.includes(element));
                if (filteredArray.length > 0)
                    return announcement;
            })
        }
        setSortedData(sortedAnnouncement);
    }
    const handleChange = (option, id) => {
        console.log("option", option);
        let newAnnouncements = announcements.filter(announcement => {
            if (announcement.id === id) {
                announcement.status = option.value;
            }
            return announcement;
        })
        setAnnouncements(newAnnouncements);
    }

    const toggleAnnouncementPopup = (data) => {
        setAnnouncementPop(!announcementPop)
        setAnnouncementPopData(data)
    }
    const getAnnouncements = (eventKey) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let product = decrypt(localStorage.getItem('junk'))
        var payload = {
            "productId": product,
            "type": parseInt(eventKey)
        }
        axios.post(config.userUrl + "admin/getAnnouncementByProductIdByAdmin", payload, { headers: headers })
            .then(function (response) {
                console.log("getAnnouncementByProductId");
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("getAnnouncementByProductId  successfull");
                    setAnnouncementData(response.data.announcement)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    const deleteAnnouncement = (id) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }

        var payload = {
            announcementId: id
        }

        axios.post(config.userUrl + "admin/deleteAnnouncementByCreaterAdmin", payload, { headers: headers })
            .then(function (response) {
                console.log("getAnnouncementByProductId");
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("getAnnouncementByProductId  successfull");
                    getAllAnnouncements()
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    const openAnnouncementImage = (data) => {
        setViewAnnouncementImage(true)
        setPopupAnnouncementImage(data.image)
    }

    const closeAnnouncementImage = () => {
        setViewAnnouncementImage(false)
    }
    return (
        <div>
            {announcePopup && <CreateAnnouncement initialData={announcePopup} tags={productTags?.map(tag => ({ isSelected: false, tag: tag }))} closePopup={() => setAnnouncePopup(false)} callback={() => getAllAnnouncements()} product={{ id: productid, companyId: companyId }} versionId={selectedVersionId} />}

            {announcementPop && <AnnouncementComments openAnnouncementImage={openAnnouncementImage} closePopup={toggleAnnouncementPopup} getAnnouncements={getAnnouncements} data={announcementPopData} selectedValue={selectedValue} />}
            {viewAnnouncementImage && <ViewAnnouncementImage close={closeAnnouncementImage} image={popupAnnouncementImage} />}

            <div>
                <div className='flex items-center justify-between gap-6 mt-8 ml-10 '>
                    <p className={`text-md font-semibold cursor-pointer ${section === "Announcements" ? "underline text-orange-400 underline-offset-8" : ""}`} onClick={() => setSection("Announcements")}><span className='text-black'>Announcements</span></p>
                </div>
                <Row className="ml-1 mt-6">
                    <Col md={3} className='flex flex-col bg-white mb-10 p-3 rounded-xl h-max sticky top-[80px]'>
                        <div className='flex justify-between items-center'>
                            <p className='text-2xl font-semibold  underline text-orange-400 underline-offset-8'><span className='text-black'>Sort&Filter</span></p>
                            <p className='bg-orange-600 rounded-xl text-white text-xs px-2 py-0.5 flex items-center gap-2 justify-between cursor-pointer' onClick={() => { setSortOption("Latest to Oldest"); setSortOption2("All"); setSortOption3([]); setSortedData(announcements) }}><span>Clear All</span><img src={cancelWhiteIcon} alt='' className='float-right w-5' /></p>
                        </div>
                        <br />
                        <div className="flex flex-col gap-2 text-balck font-medium" onChange={(e) => setSortOption(e.target.value)}>
                            <div className='flex items-center gap-2'>
                                <button
                                    className={`rounded-full w-4 h-4 ${sortOption === "Latest to Oldest" ? 'bg-black text-white' : 'bg-gray-200'
                                        }`}
                                    onClick={() => setSortOption("Latest to Oldest")}
                                >
                                </button>
                                <span>Latest to Oldest</span>
                            </div>
                            <div className='flex items-center gap-2'>
                                <button
                                    className={`rounded-full w-4 h-4 ${sortOption === "Oldest to Latest" ? 'bg-black text-white' : 'bg-gray-200'
                                        }`}
                                    onClick={() => setSortOption("Oldest to Latest")}
                                >
                                </button>
                                <span>Oldest to Latest</span>
                            </div>
                        </div>
                        <br />
                        <div>
                            <p className='text-xl font-medium mt-4 flex items-center justify-between'>Pinned </p>
                            <div className="flex flex-col gap-2 text-balck font-medium" onChange={(e) => setSortOption2(e.target.value)}>
                                <div className='flex items-center gap-2'>
                                    <button
                                        className={`rounded-full w-4 h-4 ${sortOption2 === "Pinned Only" ? 'bg-black text-white' : 'bg-gray-200'
                                            }`}
                                        onClick={() => setSortOption2("Pinned Only")}
                                    >
                                    </button>
                                    <span>Pinned Only</span>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <button
                                        className={`rounded-full w-4 h-4 ${sortOption2 === "Except Pinned" ? 'bg-black text-white' : 'bg-gray-200'
                                            }`}
                                        onClick={() => setSortOption2("Except Pinned")}
                                    >
                                    </button>
                                    <span>Except Pinned</span>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <button
                                        className={`rounded-full w-4 h-4 ${sortOption2 === "All" ? 'bg-black text-white' : 'bg-gray-200'
                                            }`}
                                        onClick={() => setSortOption2("All")}
                                    >
                                    </button>
                                    <span>All</span>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div>
                            <p className='text-xl font-medium mt-4 flex items-center justify-between'>Filter By Tags</p>
                            <div className='flex items-center flex-wrap gap-3 py-3'>
                                {productTags && productTags.map((data, i) => {
                                    return (
                                        <span className='cursor-pointer' onClick={() => handleTagSelectButtonClick(i)}>
                                            <Tag text={data} color={selectedTags[i] ? '#E6570E' : '#999'} />
                                        </span>
                                    )
                                })}
                            </div>
                        </div>
                    </Col>
                    <Col md={9} className='flex flex-col gap-4'>
                        <div className='flex justify-between'>
                            <div className='flex flex-col gap-4'>
                                <div className='flex items-center justify-between w-full'>
                                    <p className='text-2xl font-semibold text-black'>Announcements</p>
                                </div>
                                <div className={`${membershipType === "Free" ? "hidden" : ""}`}>
                                    <Form className=''>
                                        <FormControl
                                            type="text"
                                            placeholder="Search for user"
                                            value={searchInput}
                                            onChange={(e) => searchItems(e.target.value)}
                                            id="search"
                                            className="abc form-control ml-0 bg-white rounded-full"
                                            style={{ width: "480px", height: "45px", borderRadius: "25px", paddingLeft: "3rem" }}
                                            aria-label="Search keyword"
                                        />
                                    </Form>
                                </div>
                            </div>
                            <div className='flex flex-col items-end justify-end'>
                                {userRole != 'moderator' && <button className='py-1.5 px-5 rounded-lg text-white bg-orange transform -translate-y-3 outline-none border-none active:outline-none focus:outline-none' onClick={() => setAnnouncePopup(true)}>Create Announcement</button>}

                                <div className='flex rounded-xl items-center'>
                                    <img src={pinOrange} alt='' className='w-10' />
                                    <span className='text-lg font-medium'>Pinned {pinnedAnnouncementsCount}/5</span>
                                </div>
                            </div>
                        </div>
                        {loading ? <div className='mx-auto flex flex-col'><CircleFadeLoader {...loaderProps} /><span className='text-2xl font-semibold'>Loading...</span></div> : null}
                        {loading === false && searchInput.length > 0 ?
                            filteredResults.length === 0 ? <div className='mx-auto flex text-2xl font-semibold'>No Announcements found</div>
                                : filteredResults.map((announcement, i) => {
                                    return (
                                        <div key={i}>
                                            <Row className='mt-4 ml-2 items-center'>
                                                <Col md={2} className='text-black font-medium'>
                                                    <p>{announcement.userName ? announcement.userName : "Anonymous User"}</p>
                                                    <p className='text-sm'>{moment(announcement.updatedAt, "YYYY-MM-DD HH:mm:ss.SSSZ").format("DD/MM/YY")}</p>
                                                    <p className='font-normal capitalize text-sm'>{announcement?.role || ''}</p>
                                                </Col>
                                                <Col md={4}>
                                                    <p className='text-base font-semibold'>{announcement.title}</p>
                                                </Col>
                                                <Col md={1}>
                                                    <span className='font-medium'>{`${announcement.tag?.length > 1 ? announcement.tag?.length + " Tags" : announcement.tag?.length === 1 ? "1 Tag" : "0 Tag"}`}</span>
                                                </Col>
                                                <Col md={2}>
                                                    <p className='text-base font-medium'>{announcement?.announcementType || 'Generic'}</p>
                                                </Col>
                                                <Col md={1}>
                                                    <img className='cursor-pointer' src={announcement.pin ? pinOrange : pinGray} alt="" onClick={() => announcement.pin ? unpinAnnouncement(announcement) : pinAnnouncement(announcement)} />
                                                </Col>
                                                <Col md={1}>
                                                    <Button
                                                        size='sm'
                                                        variant="default"
                                                        type="submit"
                                                        className="bg-orange-400 text-white h-8 w-20 leading-tight rounded-lg"
                                                        onClick={() => toggleAnnouncementPopup(announcement)}
                                                    >Comment</Button>
                                                </Col>
                                                <Col md={1} className='flex items-center relative'>
                                                    {userRole != 'moderator' && <Dropdown align="end" show={openStates[i]} onToggle={() => handleButtonClick(i)}>
                                                        <Dropdown.Toggle variant="default" className="text-white focus:outline-none focus:ring-0" >
                                                            <svg className="w-6 h-6" aria-hidden="true" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z">
                                                                </path>
                                                            </svg>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu className="border-black rounded-lg min-w-max px-2 z-20">
                                                            <p className="hover:bg-orange-200 hover:font-medium rounded-md py-1 px-2 cursor-pointer w-20" onClick={() => setAnnouncePopup(announcement)}>Edit</p>
                                                            <p className="hover:bg-orange-200 hover:font-medium rounded-md py-1 px-2 cursor-pointer w-20" onClick={() => deleteAnnouncement(announcement?.id)}>Delete</p>
                                                        </Dropdown.Menu>
                                                    </Dropdown>}
                                                </Col>
                                            </Row>
                                            <hr className="bg-orange-600 w-full mx-auto rounded-sm h-[1px]" />
                                        </div>
                                    )
                                })
                            : loading === false && sortedData.length === 0 ? <div className='mx-auto flex text-2xl font-semibold'>Change your filter to load more announcements</div>
                                : sortedData && sortedData.map((announcement, i) => {
                                    return (
                                        <div key={i}>
                                            <Row className='mt-4 ml-2 items-center'>
                                                <Col md={2} className='text-black font-medium'>
                                                    <p>{announcement.userName ? announcement.userName : "Anonymous User"}</p>
                                                    <p className='text-sm'>{moment(announcement.updatedAt, "YYYY-MM-DD HH:mm:ss.SSSZ").format("DD/MM/YY")}</p>
                                                    <p className='font-normal capitalize text-sm'>{announcement?.role || ''}</p>
                                                </Col>
                                                <Col md={4}>
                                                    <p className='text-base font-semibold'>{announcement.title}</p>
                                                </Col>
                                                <Col md={1}>
                                                    <span className='font-medium'>{`${announcement.tag?.length > 1 ? announcement.tag?.length + " Tags" : announcement.tag?.length === 1 ? "1 Tag" : "0 Tag"}`}</span>
                                                </Col>
                                                <Col md={2}>
                                                    <p className='text-base font-medium'>{announcement.announcementType || 'Generic'}</p>
                                                </Col>
                                                <Col md={1}>
                                                    <img className='cursor-pointer' src={announcement.pin ? pinOrange : pinGray} alt="" onClick={() => announcement.pin ? unpinAnnouncement(announcement) : pinAnnouncement(announcement)} />
                                                </Col>
                                                <Col md={1}>
                                                    <Button
                                                        size='sm'
                                                        variant="default"
                                                        type="submit"
                                                        className="bg-orange-400 text-white h-8 w-20 leading-tight rounded-lg"
                                                        onClick={() => toggleAnnouncementPopup(announcement)}
                                                    >View</Button>
                                                </Col>
                                                <Col md={1} className='flex items-start relative'>
                                                    {userRole != 'moderator' && <Dropdown align="end" show={openStates[i]} onToggle={() => handleButtonClick(i)}>
                                                        <Dropdown.Toggle variant="default" className="text-white focus:outline-none focus:ring-0" >
                                                            <svg className="w-6 h-6" aria-hidden="true" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z">
                                                                </path>
                                                            </svg>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu className="border-black rounded-lg min-w-max px-2 z-20">
                                                            <p className="hover:bg-orange-200 hover:font-medium rounded-md py-1 px-2 cursor-pointer w-20" onClick={() => setAnnouncePopup(announcement)}>Edit</p>
                                                            <p className="hover:bg-orange-200 hover:font-medium rounded-md py-1 px-2 cursor-pointer w-20" onClick={() => deleteAnnouncement(announcement?.id)}>Delete</p>
                                                        </Dropdown.Menu>
                                                    </Dropdown>}
                                                </Col>
                                            </Row>
                                            <hr className="bg-orange-600 w-full mx-auto rounded-sm h-[1px]" />
                                        </div>
                                    )
                                })}
                    </Col>
                </Row>
            </div>
            <Modal
                show={showToast}
                onHide={() => setShowToast(false)}
                dialogClassName="modal-3w"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <p className='text-lg text'>OOPS!! You cannot pin more than 5 announcement</p>
                    </Modal.Title>
                </Modal.Header>
            </Modal>
        </div>
    )
}

export default ProductAnnouncements
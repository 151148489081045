import React, { useRef, useState, useEffect } from "react";
import { Button, Form, Modal, Dropdown, Card, Row, Col, OverlayTrigger, Tooltip, Popover } from "react-bootstrap";
import { Link, NavLink, Redirect } from "react-router-dom";
import fedduplogo from "../images/fedduplogo.png";
import NavBar from "../NavBar/NavBar";
import { Feedback } from '../UsersFeed/Feedback';
import { Suggestion } from '../UsersFeed/Suggestion';
import { Announcements } from '../UsersFeed/Announcements';
import './UsersFeed.css';
import { FaStar } from "react-icons/fa";
import square from '../images/square.png';
import upvoteb from '../images/upvote-b.svg';
import downvoteb from '../images/downvote-b.svg';
import upvote from '../images/upvote.svg';
import downvote from '../images/downvote.svg';
import commenticon from '../images/comments.svg';
import share from '../images/share.svg';
import post from '../images/post.png';
import trending from '../images/trending.svg';
import newimage from '../images/new.svg';
import top from '../images/top.svg';
import background from '../images/background-img.png';
import profilepicture from '../images/profilepicture.png';
import rating from '../images/FeddUp Rating.svg';
import rating1 from '../images/rating1.png';
import rating2 from '../images/rating2.png';
import rating3 from '../images/rating3.png';
import rating4 from '../images/rating4.png';
import rating5 from '../images/rating5.png';
import ratingImg from '../images/showrating.PNG';
import fillrating1 from '../images/fill-rating1.png';
import fillrating2 from '../images/fill-rating2.png';
import fillrating3 from '../images/fill-rating3.png';
import fillrating4 from '../images/fill-rating4.png';
import fillrating5 from '../images/fill-rating5.png';
import { Tags } from "../UsersFeed/TrendingTags";
import { Moderators } from "../CompanyFeed/Moderators";
import { BsArrowLeft } from "react-icons/bs";
import lock from '../images/lock.png';
import out from "../images/out.svg";
import cross from "../images/cross.svg";
import hide from "../images/hide.svg";
import pin from "../images/pin.svg";
import imgDel from "../images/delete.svg";
import dots from "../images/dots.svg";
import deletei from "../images/delete-i.svg";
import axios from 'axios';
import { config } from '../Config/Config';
import Tag from "../utils/Tag";
import { decrypt } from "../utils";

export default function ViewAllCommentsForProduct(props) {
    const [deletePost, setDeletePost] = useState(false);
    const [edit, setEdit] = useState(false);
    const [show, setShow] = useState(false);
    const [feedShow, setFeedShow] = useState(false);
    const [scroll, setScroll] = useState(0)
    const [item, setItem] = useState("");
    const [feedId, setFeedId] = useState("");
    const [suggestionId, setSuggestionId] = useState("");
    const [rate1, setRate1] = useState(true);
    const [uImg, setUImg] = useState(false);
    const [dImg, setDImg] = useState(true);
    const [cuImg, setCuImg] = useState(false);
    const [cdImg, setCdImg] = useState(true);
    const [commentCount, setCommentCount] = useState(0);
    const [count, setCount] = useState(999);
    const [follow, setFollow] = useState(true);
    const [feedbackData, setFeedbackData] = useState([]);
    const [suggestionData, setSuggestionData] = useState([]);
    const [ratingData, setRatingData] = useState([]);
    const [popSuggestion, setPopSuggestion] = useState(false)
    const [popFeedback, setPopFeedback] = useState(false)
    const [feedbackByUserId, setFeedbackByUserId] = useState("")
    const [suggestionByUserId, setSuggestionByUserId] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [productName, setProductName] = useState("")
    const [avgRating, setAvgRating] = useState("")
    const [comment, setComment] = useState("")
    const [noOfPeopleGivenRating, setNoOfPeopleGivenRating] = useState("")
    const [ratingCommentDetails, setRatingCommentDetails] = useState([])
    const [follower, setFollower] = useState("")
    const [allComments, setAllComments] = useState([])

    useEffect(() => {
        if (props.location.state.referrer == "Feedback") {
            getAllComments();
        } else if (props.location.state.referrer == "Suggestion") {
            getAllSuggestionComments();
        } else {
            getAllRatingComments();
        }
        console.log("props-1", props.location.state.referrer)
    }, [])
    const ref = useRef(null);
    const feedback = () => {
        console.log("item1", item)
        setItem("Feedback")
    }
    const suggestion = () => {
        console.log("item2", item)
        setItem("Suggestion")
    }
    const announcements = () => {
        console.log("item3", item)
        setItem("Announcements")
    }
    const ratingNew1 = () => {
        setRate1(!rate1)
    }
    function changeUpvoteImage(id, val) {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        console.log("dataup", id.id)
        setUImg(!uImg)
        if (uImg) {
            setCount(count - 1)
            setDImg(false)

        } else {
            setCount(count + 1)
            // setDImg(false)
            setDImg(false)
        }
        var payload = {
            "feedbackId": id.id,
            "feedback": val,
            "userIdFeedbackBelongTo": id.feedbackByUserId
        }
        console.log("upvote", payload)
        axios.post(config.userUrl + 'user/likeOrDislikeFeedback', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    getAllComments();
                    console.log("upvote", payload, response.data)
                }
            })
            .catch(function (error) {
                console.log(error, payload)
            })
    }
    function changeDownvoteImage(id, val) {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        console.log("data", id)
        setDImg(!dImg)
        if (!dImg) {
            setUImg(false)
            setCount(count - 1)
        } else {
            // setDImg(!dImg)
            setUImg(false)
            setDImg(false)
        }
        var payload = {
            "feedbackId": id.id,
            "feedback": val,
            "userIdFeedbackBelongTo": id.feedbackByUserId
        }
        console.log("upvote", payload)
        axios.post(config.userUrl + 'user/likeOrDislikeFeedback', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    console.log("upvote", payload, response.data)
                    getAllComments();
                }
            })
            .catch(function (error) {
                console.log(error, payload)
            })
    }
    function changeUpvoteSuggestionImage(id, val) {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        console.log("dataup", id.id)
        setUImg(!uImg)
        if (uImg) {
            setCount(count - 1)
            setDImg(false)

        } else {
            setCount(count + 1)
            // setDImg(false)
            setDImg(false)
        }
        var payload = {
            "suggestionId": id.id,
            "suggestion": val,
            "userIdSuggetsionBelongTo": id.suggestionByUserId
        }
        console.log("upvote", payload)
        axios.post(config.userUrl + 'user/likeOrDislikeSuggestion', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    getAllSuggestionComments();
                    console.log("upvote", payload, response.data)
                }
            })
            .catch(function (error) {
                console.log(error, payload)
            })
    }
    function changeDownvoteSuggestionImage(id, val) {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        console.log("data", id)
        setDImg(!dImg)
        if (!dImg) {
            setUImg(false)
            setCount(count - 1)
        } else {
            // setDImg(!dImg)
            setUImg(false)
            setDImg(false)
        }
        var payload = {
            "suggestionId": id.id,
            "suggestion": val,
            "userIdsuggestionBelongTo": id.suggestionByUserId
        }
        console.log("upvote", payload)
        axios.post(config.userUrl + 'user/likeOrDislikeSuggestion', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    console.log("upvote", payload, response.data)
                    getAllSuggestionComments();
                }
            })
            .catch(function (error) {
                console.log(error, payload)
            })
    }
    function hidePopover() {
        document.getElementById("test").style.display = "none";
    }
    function feedbackPopover() {
        setPopFeedback(!popFeedback)
    }

    const getAllComments = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "feedbackId": props.location.state.id
        }
        axios.post(config.userUrl + "user/getFeedbackByFeedbackId", payload, { headers: headers })
            .then(function (response) {
                console.log("getRatingComments", response);
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data.feedback[0].feedbackByUserId, response.data);
                    setFeedbackData(response.data.feedback)
                    setFeedbackByUserId(response.data.feedback[0].feedbackByUserId)
                    setAllComments(response.data.allComments)
                    setAvgRating(response.data.averageRating)
                    setNoOfPeopleGivenRating(response.data.ratingDetails)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const getAllSuggestionComments = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "suggestionId": props.location.state.id
        }
        axios.post(config.userUrl + "user/getSuggestionBySuggestionId", payload, { headers: headers })
            .then(function (response) {
                console.log("getRatingSuggestionComments", response);
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    setSuggestionData(response.data.suggestion)
                    setSuggestionByUserId(response.data.suggestion[0].suggestionByUserId)
                    setAllComments(response.data.allComments)
                    setAvgRating(response.data.averageRating)
                    setNoOfPeopleGivenRating(response.data.ratingDetails)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const getAllRatingComments = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "ratingCommentId": props.location.state.id
        }
        axios.post(config.userUrl + "user/getRatingCommentByRatingId", payload, { headers: headers })
            .then(function (response) {
                console.log("getRatingComments", response);
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    setRatingData(response.data.ratingComment)
                    setAvgRating(response.data.averageRating)
                    setNoOfPeopleGivenRating(response.data.ratingDetails)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const createComment = (e) => {
        e.preventDefault();
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "feedbackId": props.location.state.id,
            "userIdFeedbackBelongTo": feedbackByUserId,
            "comment": comment,
        }
        console.log("payload", payload)
        axios.post(config.userUrl + "user/feedbackCommentByUser", payload, { headers: headers })
            .then(function (response) {
                console.log("comment created successfully", response);
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    setComment("");
                    getAllComments();
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const createSuggestionComment = (e) => {
        e.preventDefault();
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "suggestionId": props.location.state.id,
            "userIdSuggestionBelongTo": suggestionByUserId,
            "comment": comment,
        }
        console.log("payload", payload)
        axios.post(config.userUrl + "user/suggestionCommentByUser", payload, { headers: headers })
            .then(function (response) {
                console.log("comment created successfully", response);
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    setComment("");
                    getAllSuggestionComments();
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const likeDislikeUpvoteComment = (id, val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        console.log("dataup", id.id)
        setCuImg(!cuImg)
        if (cuImg) {
            setCount(count - 1)
            setCdImg(false)

        } else {
            setCount(count + 1)
            setCdImg(false)
        }
        var payload = {
            "feedbackCommentId": id.id,
            "feedbackComment": val,
            "userIdFeedbackCommentBelongTo": id.commentByUserId
        }
        console.log("payload", payload)
        axios.post(config.userUrl + "user/likeOrDislikeFeedbackComment", payload, { headers: headers })
            .then(function (response) {
                console.log("comment created successfully", response);
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    getAllComments();
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const likeDislikeDownvoteComment = (id, val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        setCdImg(!cdImg)
        if (!cdImg) {
            setCuImg(false)
        } else {
            setCuImg(false)
            setCdImg(false)
        }
        var payload = {
            "feedbackCommentId": id.id,
            "feedbackComment": val,
            "userIdFeedbackCommentBelongTo": id.commentByUserId
        }
        console.log("payload", payload)
        axios.post(config.userUrl + "user/likeOrDislikeFeedbackComment", payload, { headers: headers })
            .then(function (response) {
                console.log("comment created successfully", response);
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    getAllComments();
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const likeDislikeUpvoteSuggestionComment = (id, val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        console.log("dataup", id.id)
        setCuImg(!cuImg)
        if (cuImg) {
            setCount(count - 1)
            setCdImg(false)

        } else {
            setCount(count + 1)
            setCdImg(false)
        }
        var payload = {
            "suggestionCommentId": id.id,
            "suggestionComment": val,
            "userIdSuggestionCommentBelongTo": id.commentByUserId
        }
        console.log("payload", payload)
        axios.post(config.userUrl + "user/likeOrDislikeSuggestionComment", payload, { headers: headers })
            .then(function (response) {
                console.log("comment created successfully", response);
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    getAllSuggestionComments();
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const likeDislikeDownvoteSuggestionComment = (id, val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        setCdImg(!cdImg)
        if (!cdImg) {
            setCuImg(false)
        } else {
            setCuImg(false)
            setCdImg(false)
        }
        var payload = {
            "suggestionCommentId": id.id,
            "suggestionComment": val,
            "userIdSuggestionCommentBelongTo": id.commentByUserId
        }
        console.log("payload", payload)
        axios.post(config.userUrl + "user/likeOrDislikeSuggestionComment", payload, { headers: headers })
            .then(function (response) {
                console.log("comment created successfully", response);
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    getAllSuggestionComments();
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    function shareFeedback(data) {
        if (navigator.share) {
            navigator.share({
                url: `https://www.feddup.com/${data.title}`,
            }).then(() => {
                console.log('Thanks for sharing!', data.title);
            }).catch(err => {
                console.log(
                    "Error while using Web share API:");
                console.log(err);
            });
        } else {
            console.log("Sorry! Your browser does not support Web Share API");
        }
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "feedbackIdd": data.id,
            "userIdFeedbackBelongTo": data.feedbackByUserId
        }
        console.log("payload", payload)
        axios.post(config.userUrl + 'user/shareFeedbackByUser', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    setTimeout(() => {
                        getAllComments();
                    }, 1000);
                    console.log("feedback shared successfully", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            })
    };
    function shareSuggestion(data) {
        if (navigator.share) {
            navigator.share({
                url: `https://www.feddup.com/${data.title}`,
            }).then(() => {
                console.log('Thanks for sharing!', data.title);
            }).catch(err => {
                console.log(
                    "Error while using Web share API:");
                console.log(err);
            });
        } else {
            console.log("Sorry! Your browser does not support Web Share API");
        }
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "suggestionId": data.id,
            "userIdSuggestionBelongTo": data.suggestionByUserId
        }
        console.log("payload", payload)
        axios.post(config.userUrl + 'user/shareSuggestionByUser', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    setTimeout(() => {
                        getAllSuggestionComments();
                    }, 1000);
                    console.log("feedback shared successfully", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            })
    };
    return (
        <div>
            <NavBar />
            <div id="background">
                <img src={background} style={{ marginTop: "3.8rem", width: "100%", height: "160px" }} />
                <div className="usersfeed-l-r">
                    <Row className="row-width-company">
                        <Col md={8}>
                            <Card>{props.location.state.referrer == "Feedback" ?
                                <div className="card-padding">
                                    {feedbackData.map((data, i) => (
                                        <Row style={{ width: "100%" }}>
                                            <Col md={3}>
                                                <img src={profilepicture} style={{ width: "140px", height: "142px" }} />
                                            </Col>
                                            <Col md={9}>
                                                <div className="standalone mt-3">{data.productName}</div>
                                                <div className="companyName">By {data.companyName}</div><br />
                                                <div className="row">
                                                    <Col md={8}>
                                                        {/* <span className="followers">{follower} <span className="followers-sm"> Follower</span></span><br />
                                                    {follow ? <Button variant="default" onClick={followP} className="follow-btn">Follow</Button> : <Button variant="default" onClick={followP} className="unfollow-btn">Unfollow</Button>} */}
                                                    </Col>
                                                    <Col md={4}>
                                                        <div style={{ marginTop: "1rem", float: "right", display: "flex" }}><span className="ml-5 mt-1 followers-sm">({noOfPeopleGivenRating}) </span><span className="followers"> &nbsp;{Math.round(avgRating * 10) / 10}</span></div>
                                                        <div style={{ float: "right", display: "flex" }}>
                                                            <div style={{ backgroundColor: "#E6570E", width: `${((Math.round(avgRating * 10) / 10) / 5) * 100}%` }}> <img src={ratingImg} style={{ width: "145px" }} /></div>
                                                            {/* <span><img src={rating1} style={avgRating >= 1 ? { backgroundColor: "#E6570E", width: "25px" } : { width: "25px" }} /><img src={rating2} style={avgRating >= 2 ? { backgroundColor: "#E6570E", width: "25px" } : { width: "25px" }} /><img src={rating3} style={avgRating >= 3 ? { backgroundColor: "#E6570E", width: "25px" } : { width: "25px" }} /><img src={rating4} style={avgRating >= 4 ? { backgroundColor: "#E6570E", width: "25px" } : { width: "25px" }} /><img src={rating5} style={avgRating == 5 ? { backgroundColor: "#E6570E", width: "25px" } : { width: "25px" }} /></span> */}
                                                            {/* {((Math.round(avgRating * 10) / 10) / 5) * 100} */}
                                                        </div>
                                                        <br />

                                                    </Col>
                                                </div>
                                                {/* <div><span className="followers">9.19K<span className="followers-sm"> Followers</span></span><div style={{ float: "right" }} ><span className="followers-sm">(999)</span><span className="followers"> 4.5</span></div></div>
                                            <div style={{ display: "flex" }}><span><Button variant="default" className="follow-btn">Follow</Button></span><div style={{ display: "flex", float: "right" }}><img src={rating1} className="rating-img" /><img src={rating2} className="rating-img" /><img src={rating3} className="rating-img" /><img src={rating4} className="rating-img" /><img src={rating5} className="rating-img" /></div></div> */}

                                            </Col>
                                        </Row>
                                    ))}

                                </div> : (props.location.state.referrer == "Suggestion") ?
                                    <div className="card-padding">
                                        {suggestionData.map((data, i) => (
                                            <Row style={{ width: "100%" }}>
                                                <Col md={3}>
                                                    <img src={profilepicture} style={{ width: "140px", height: "142px" }} />
                                                </Col>
                                                <Col md={9}>
                                                    <div className="standalone mt-3">{data.productName}</div>
                                                    <div className="companyName">By {data.companyName}</div><br />
                                                    <div className="row">
                                                        <Col md={8}>
                                                            {/* <span className="followers">{follower} <span className="followers-sm"> Follower</span></span><br />
                                                                               {follow ? <Button variant="default" onClick={followP} className="follow-btn">Follow</Button> : <Button variant="default" onClick={followP} className="unfollow-btn">Unfollow</Button>} */}
                                                        </Col>
                                                        <Col md={4}>
                                                            <div style={{ marginTop: "1rem", float: "right", display: "flex" }}><span className="ml-5 mt-1 followers-sm">({noOfPeopleGivenRating}) </span><span className="followers"> &nbsp;{Math.round(avgRating * 10) / 10}</span></div>
                                                            <div style={{ float: "right", display: "flex" }}>
                                                                <div style={{ backgroundColor: "#E6570E", width: `${((Math.round(avgRating * 10) / 10) / 5) * 100}%` }}> <img src={ratingImg} style={{ width: "145px" }} /></div>
                                                                {/* <span><img src={rating1} style={avgRating >= 1 ? { backgroundColor: "#E6570E", width: "25px" } : { width: "25px" }} /><img src={rating2} style={avgRating >= 2 ? { backgroundColor: "#E6570E", width: "25px" } : { width: "25px" }} /><img src={rating3} style={avgRating >= 3 ? { backgroundColor: "#E6570E", width: "25px" } : { width: "25px" }} /><img src={rating4} style={avgRating >= 4 ? { backgroundColor: "#E6570E", width: "25px" } : { width: "25px" }} /><img src={rating5} style={avgRating == 5 ? { backgroundColor: "#E6570E", width: "25px" } : { width: "25px" }} /></span> */}
                                                                {/* {((Math.round(avgRating * 10) / 10) / 5) * 100} */}
                                                            </div>
                                                            <br />

                                                        </Col>
                                                    </div>
                                                    {/* <div><span className="followers">9.19K<span className="followers-sm"> Followers</span></span><div style={{ float: "right" }} ><span className="followers-sm">(999)</span><span className="followers"> 4.5</span></div></div>
                                                                       <div style={{ display: "flex" }}><span><Button variant="default" className="follow-btn">Follow</Button></span><div style={{ display: "flex", float: "right" }}><img src={rating1} className="rating-img" /><img src={rating2} className="rating-img" /><img src={rating3} className="rating-img" /><img src={rating4} className="rating-img" /><img src={rating5} className="rating-img" /></div></div> */}

                                                </Col>
                                            </Row>
                                        ))}

                                    </div> :
                                    <div className="card-padding">
                                        {ratingData.map((data, i) => (
                                            <Row style={{ width: "100%" }}>
                                                <Col md={3}>
                                                    <img src={profilepicture} style={{ width: "140px", height: "142px" }} />
                                                </Col>
                                                <Col md={9}>
                                                    <div className="standalone mt-3">{data.productName}</div>
                                                    <div className="companyName">By {data.companyName}</div><br />
                                                    <div className="row">
                                                        <Col md={8}>

                                                        </Col>
                                                        <Col md={4}>
                                                            <div style={{ marginTop: "1rem", float: "right", display: "flex" }}><span className="ml-5 mt-1 followers-sm">({noOfPeopleGivenRating}) </span><span className="followers"> &nbsp;{Math.round(avgRating * 10) / 10}</span></div>

                                                            <div style={{ float: "right", display: "flex" }}>
                                                                <div style={{ backgroundColor: "#E6570E", width: `${((Math.round(avgRating * 10) / 10) / 5) * 100}%` }}> <img src={ratingImg} style={{ width: "145px" }} /></div>
                                                                {/* <span style={{ float: "right", display: "flex" }}><img src={rating1} style={avgRating >= 1 ? { backgroundColor: "#E6570E", width: "25px" } : { width: "25px" }} /><img src={rating2} style={avgRating >= 1.5 ? (avgRating >= 2 ? { backgroundColor: "#E6570E", width: "25px", marginLeft: "1px" } : { backgroundColor: "#E6570E", width: "25px", marginLeft: "1px" }) : { width: "25px", marginLeft: "1px" }} /><img src={rating3} style={avgRating >= 2.5 ? (avgRating >= 3 ? { backgroundColor: "#E6570E", width: "25px", marginLeft: "1px" } : { backgroundColor: "#E6570E", width: "25px", marginLeft: "1px" }) : { width: "25px", marginLeft: "1px" }} /><img src={rating4} style={avgRating >= 3.5 ? (avgRating >= 4 ? { backgroundColor: "#E6570E", width: "25px", marginLeft: "1px" } : { backgroundColor: "#E6570E", width: "25px", marginLeft: "1px" }) : { width: "25px", marginLeft: "1px" }} /><img src={rating5} style={avgRating >= 4.5 ? (avgRating == 5 ? { backgroundColor: "#E6570E", width: "25px", marginLeft: "1px" } : { backgroundColor: "#E6570E", width: "25px", marginLeft: "1px" }) : { width: "25px", marginLeft: "1px" }} /></span> */}
                                                                {/* {((Math.round(avgRating * 10) / 10) / 5) * 100} */}
                                                            </div>
                                                            <br />

                                                        </Col>
                                                    </div>
                                                </Col>
                                            </Row>
                                        ))}

                                    </div>
                            }
                            </Card>
                            <div>
                                <div className="row">
                                    <Col md={12}>
                                        <div className="row mt-2">
                                            <Col md={3}>
                                                <Card className="feed-card f-color">{props.location.state.referrer}</Card>
                                            </Col>
                                            {/* <Col md={3}>
                                                <NavLink to={"#"} activeClassName="feed-card-a" ><Card className="feed-card"><span className={props.location.state.referrer == "Suggestion" ? "f-color" : "f-color-g"}>Suggestion</span></Card></NavLink>
                                            </Col>
                                            <Col md={3}>
                                                <NavLink to={"#"} activeClassName="feed-card-a" > <Card className="feed-card" ><span className={props.location.state.referrer == "Announcements" ? "f-color" : "f-color-g"}>Announcements</span></Card></NavLink>
                                            </Col> */}
                                            <Col md={1}></Col>
                                            <Col md={2} className="mt-4">
                                                {/* <Dropdown >
                                                    <Dropdown.Toggle variant="default" className="feed-card d-down">
                                                        <img src={trending} style={{ width: "20px" }} />
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item className="d-text"><img src={trending} style={{ width: "20px" }} /> &nbsp;Trending</Dropdown.Item>
                                                        <Dropdown.Item className="d-text"><img src={newimage} style={{ width: "20px" }} /> &nbsp;Top</Dropdown.Item>
                                                        <Dropdown.Item className="d-text"><img src={top} style={{ width: "20px" }} /> &nbsp;New</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown> */}
                                            </Col>
                                        </div>
                                    </Col>
                                </div>
                            </div>

                            <div>
                                <div>
                                    {props.location.state.referrer == "Feedback" ?
                                        <div className="mt-2">
                                            {feedbackData.map((data, i) => (
                                                <div>
                                                    <Card>
                                                        <div className="row">
                                                            <Col md={2}>
                                                                <Card className="ml-2 mt-1 mb-2 card-padding-sm">
                                                                    <center> {data.vote == 1 ? <img src={upvote} onClick={(e, i) => changeUpvoteImage(data, 2)} style={{ width: "55px", marginTop: "-0.6rem" }} /> : <img src={upvoteb} onClick={(e) => changeUpvoteImage(data, 1)} style={{ width: "45px" }} />}</center>
                                                                    <center className="mt-3 likes mb-2">{data.totallike == null ? 0 : data.totallike}</center>
                                                                    <center> {data.vote == -1 ? <img src={downvote} onClick={(e, i) => changeDownvoteImage(data, 2)} className="mt-2" style={{ width: "55px", marginTop: "-0.6rem" }} /> : <img src={downvoteb} onClick={(e) => changeDownvoteImage(data, 0)} className="mt-2" style={{ width: "45px" }} />}</center>
                                                                </Card>
                                                            </Col>
                                                            <Col md={10}>
                                                                <div className="ml-2 mt-1 mb-2">
                                                                    <div className="card-padding">
                                                                        <h6 className="t-today">{data.productName}</h6>
                                                                        <div className="posted">Posted by {data.userName == null ? "anonymous" : data.userName}&nbsp;&nbsp;{data.updatedAt.split("T")[0].split("-").reverse().join("-")}</div>
                                                                        <h6 className="title">{data.title}</h6>
                                                                        <div className="desc">{data.feedback}</div>
                                                                        {
                                                                            data?.tag?.length
                                                                                ?
                                                                                <div className='flex flex-wrap gap-x-3 gap-y-3 py-3'>
                                                                                    {data?.tag?.map(tag => <Tag text={tag} />
                                                                                    )}
                                                                                </div>
                                                                                : ''
                                                                        }
                                                                        <div className="my-2"><img src={commenticon} style={{ width: "20px", cursor: "pointer" }} /> <span className="comment">{data.commentCount == null ? 0 : data.commentCount} Comments</span><span className="ml-5"><img src={share} style={{ width: "20px", cursor: "pointer" }} onClick={() => shareFeedback(data)} /> <span className="comment">{data.shareCount == null ? 0 : data.shareCount} Shares</span></span></div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </div>
                                                    </Card><br />
                                                    {data.userCreate ?
                                                        <Card>
                                                            <form className="ml-4 mr-4 mb-4 mt-4">
                                                                <div className="form-group mt-2">
                                                                    <label style={{ fontFamily: "Poppins" }}>Add comment:</label>
                                                                    <textarea type="text"
                                                                        rows={2}
                                                                        style={{ overflowY: "none", fontFamily: "Poppins", resize: "none" }}
                                                                        className="form-control form-bg"
                                                                        aria-describedby="text"
                                                                        value={comment}
                                                                        name="comment"
                                                                        placeholder="Write your comment.."
                                                                        onChange={(e) => setComment(e.target.value)}
                                                                    />
                                                                </div>
                                                                {comment !== "" ?
                                                                    <Button
                                                                        style={{ float: "right", width: "5rem" }}
                                                                        variant="default" size="md"
                                                                        className="submit-button"
                                                                        onClick={createComment}

                                                                    >
                                                                        Send
                                                                    </Button>
                                                                    :
                                                                    <Button
                                                                        style={{ float: "right", width: "5rem" }}
                                                                        variant="default" size="md"
                                                                        className="submit-button"
                                                                        disabled={true}

                                                                    >
                                                                        Send
                                                                    </Button>
                                                                }
                                                            </form>
                                                        </Card> :
                                                        <Card className=" c-padding">
                                                            <Row style={{ width: "100%" }}>
                                                                <Col md={2}>
                                                                    <div className="c-box"><img src={lock} className="lock" /></div>
                                                                </Col>
                                                                <Col md={10}>
                                                                    <div className='c-text'>You are not allowed to comment here.</div>
                                                                    <br />
                                                                </Col>
                                                            </Row>
                                                        </Card>}<br />

                                                </div>
                                            ))}
                                            <Card>
                                                {allComments.length == 0 ? <center className="no-details-text">No Comments to View </center> :
                                                    <div>
                                                        <div style={{ fontSize: "16px", fontFamily: "Poppins" }} className="ml-4 mt-2">    All Comments</div><br />
                                                        {allComments.map((data, i) => (
                                                            <div>
                                                                <Row style={{ width: "100%" }}>
                                                                    <Col md={2}>
                                                                        <img src={profilepicture} style={{ marginLeft: "30px", width: "50px" }} />
                                                                    </Col>
                                                                    <Col md={10}>
                                                                        <div className='c-comments'>
                                                                            <span style={{ display: "flex" }}><span style={{ fontSize: "14px" }}>{data.userName} </span>&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ marginTop: "5px", color: "#AAAAAA", fontSize: "10px" }}>{data.updatedAt.split("T")[0].split("-").reverse().join("-")}</span></span>
                                                                            <div style={{ fontSize: "14px" }}>{data.comment}</div>
                                                                            <div style={{ display: "flex", marginTop: "10px" }}>
                                                                                {/* <center>{uImg ? <img src={upvote} onClick={(e, i) => changeUpvoteImage()} style={{ width: "55px" }} /> : <img src={upvoteb} onClick={(e, i) => changeUpvoteImage()} style={{ width: "45px" }} />}</center>
                                                                            <center className="mt-2 likes"> &nbsp;&nbsp;{count}&nbsp;&nbsp;</center>
                                                                            <center>{dImg ? <img src={downvote} onClick={(e, i) => changeDownvoteImage()} style={{ width: "55px" }} /> : <img src={downvoteb} onClick={(e, i) => changeDownvoteImage()} style={{ width: "45px" }} />}</center> */}
                                                                                <center> {data.vote == 1 ? <img src={upvote} onClick={(e, i) => likeDislikeUpvoteComment(data, 2)} style={{ width: "55px" }} /> : <img src={upvoteb} onClick={(e) => likeDislikeUpvoteComment(data, 1)} style={{ width: "45px" }} />}</center>
                                                                                <center className="mt-2 likes"> &nbsp;{data.totallike == null ? 0 : data.totallike}&nbsp;</center>
                                                                                <center> {data.vote == -1 ? <img src={downvote} onClick={(e, i) => likeDislikeDownvoteComment(data, 2)} style={{ width: "55px" }} /> : <img src={downvoteb} onClick={(e) => likeDislikeDownvoteComment(data, 0)} style={{ width: "45px" }} />}</center>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row><br />
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                                {/* <div className='c-comments'>
                                                        <span style={{ display: "flex" }}><span style={{ fontSize: "18px" }}>John Doe </span>&nbsp;&nbsp;&nbsp;&nbsp;<li style={{ marginTop: "5px", color: "#AAAAAA", fontSize: "10px" }}>1 hour</li></span>
                                                        <div style={{ fontSize: "14px" }}>Suscipit egestas dolor massa magna nullam enim, dignissim. Sed est morbi auctor nibh morbi. Quisque tortor potenti fusce scelerisque adipiscing. Lectus mauris consectetur ut urna sit pellentesque blandit arcu in.</div>
                                                        <div style={{ display: "flex", marginTop: "10px" }}>
                                                            <center>{uImg ? <img src={upvote} onClick={(e, i) => this.changeUpvoteImage()} style={{ width: "55px" }} /> : <img src={upvoteb} onClick={(e, i) => this.changeUpvoteImage()} style={{ width: "45px" }} />}</center>
                                                            <center className="mt-2 likes"> &nbsp;&nbsp;{count}&nbsp;&nbsp;</center>
                                                            <center>{dImg ? <img src={downvote} onClick={(e, i) => this.changeDownvoteImage()} style={{ width: "55px" }} /> : <img src={downvoteb} onClick={(e, i) => this.changeDownvoteImage()} style={{ width: "45px" }} />}</center>
                                                            <span className="ml-5 mt-1"><img src={share} style={{ width: "22px" }} /> <span className="ml-2 comment">Share</span></span></div>
                                                    </div><br /> */}

                                            </Card>
                                        </div> : (props.location.state.referrer == "Suggestion") ?
                                            <div className="mt-2">
                                                {suggestionData.map((data, i) => (
                                                    <div>
                                                        <Card>
                                                            <div className="row">
                                                                <Col md={2}>
                                                                    <Card className="ml-2 mt-1 mb-2 card-padding-sm">
                                                                        <center> {data.vote == 1 ? <img src={upvote} onClick={(e, i) => changeUpvoteSuggestionImage(data, 2)} style={{ width: "45px", marginTop: "-0.6rem" }} /> : <img src={upvoteb} onClick={(e) => changeUpvoteSuggestionImage(data, 1)} style={{ width: "45px" }} />}</center>
                                                                        <center className="mt-3 likes mb-2">{data.totallike == null ? 0 : data.totallike}</center>
                                                                        <center> {data.vote == -1 ? <img src={downvote} onClick={(e, i) => changeDownvoteSuggestionImage(data, 2)} className="mt-2" style={{ width: "45px", marginTop: "-0.6rem" }} /> : <img src={downvoteb} onClick={(e) => changeDownvoteSuggestionImage(data, 0)} className="mt-2" style={{ width: "45px" }} />}</center>
                                                                    </Card>
                                                                </Col>
                                                                <Col md={10}>
                                                                    <div className="ml-2 mt-1 mb-2">
                                                                        <div className="card-padding">
                                                                            <h6 className="t-today">{data.productName}</h6>
                                                                            <div className="posted">Posted by {data.userName == null ? "anonymous" : data.userName}&nbsp;&nbsp;{data.updatedAt.split("T")[0].split("-").reverse().join("-")}</div>
                                                                            <h6 className="title">{data.title}</h6>
                                                                            <div className="desc">{data.suggestion}</div>
                                                                            {
                                                                                data?.tag?.length
                                                                                    ?
                                                                                    <div className='flex flex-wrap gap-x-3 gap-y-3 py-3'>
                                                                                        {data?.tag?.map(tag => <Tag text={tag} />
                                                                                        )}
                                                                                    </div>
                                                                                    : ''
                                                                            }
                                                                            <div className="mt-2"><img src={commenticon} style={{ width: "20px", cursor: "pointer" }} /> <span className="comment">{data.commentCount == null ? 0 : data.commentCount} Comments</span><span className="ml-5"><img src={share} style={{ width: "20px", cursor: "pointer" }} onClick={() => shareSuggestion(data)} /> <span className="comment">{data.shareCount == null ? 0 : data.shareCount} Shares</span></span></div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </div>
                                                        </Card><br />
                                                        {data.userCreate ?
                                                            <Card>
                                                                <form className="ml-4 mr-4 mb-4 mt-4">
                                                                    <div className="form-group mt-2">
                                                                        <label style={{ fontFamily: "Poppins" }}>Add comment:</label>
                                                                        <textarea type="text"
                                                                            rows={2}
                                                                            style={{ overflowY: "none", fontFamily: "Poppins", resize: "none" }}
                                                                            className="form-control form-bg"
                                                                            aria-describedby="text"
                                                                            value={comment}
                                                                            name="comment"
                                                                            placeholder="Write your comment.."
                                                                            onChange={(e) => setComment(e.target.value)}
                                                                        />
                                                                    </div>
                                                                    {comment !== "" ?
                                                                        <Button
                                                                            style={{ float: "right", width: "5rem" }}
                                                                            variant="default" size="md"
                                                                            className="submit-button"
                                                                            onClick={createSuggestionComment}

                                                                        >
                                                                            Send
                                                                        </Button>
                                                                        :
                                                                        <Button
                                                                            style={{ float: "right", width: "5rem" }}
                                                                            variant="default" size="md"
                                                                            className="submit-button"
                                                                            disabled={true}

                                                                        >
                                                                            Send
                                                                        </Button>
                                                                    }
                                                                </form>
                                                            </Card> :
                                                            <Card className=" c-padding">
                                                                <Row style={{ width: "100%" }}>
                                                                    <Col md={2}>
                                                                        <div className="c-box"><img src={lock} className="lock" /></div>
                                                                    </Col>
                                                                    <Col md={10}>
                                                                        <div className='c-text'>You are not allowed to comment here.</div>
                                                                        <br />
                                                                    </Col>
                                                                </Row>
                                                            </Card>}<br />
                                                    </div>
                                                ))}
                                                <Card>
                                                    {allComments.length == 0 ? <center className="no-details-text">No Comments to View </center> :
                                                        <div>
                                                            <div style={{ fontSize: "16px", fontFamily: "Poppins" }} className="ml-4 mt-2">    All Comments</div><br />
                                                            {allComments.map((data, i) => (
                                                                <div>
                                                                    <Row style={{ width: "100%" }}>
                                                                        <Col md={2}>
                                                                            <img src={profilepicture} style={{ marginLeft: "30px", width: "50px" }} />
                                                                        </Col>
                                                                        <Col md={10}>
                                                                            <div className='c-comments'>
                                                                                <span style={{ display: "flex" }}><span style={{ fontSize: "14px" }}>{data.userName} </span>&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ marginTop: "5px", color: "#AAAAAA", fontSize: "10px" }}>{data.updatedAt.split("T")[0].split("-").reverse().join("-")}</span></span>
                                                                                <div style={{ fontSize: "14px" }}>{data.comment}</div>
                                                                                <div style={{ display: "flex", marginTop: "10px" }}>
                                                                                    {/* <center>{uImg ? <img src={upvote} onClick={(e, i) => changeUpvoteImage()} style={{ width: "55px" }} /> : <img src={upvoteb} onClick={(e, i) => changeUpvoteImage()} style={{ width: "45px" }} />}</center>
                                                                        <center className="mt-2 likes"> &nbsp;&nbsp;{count}&nbsp;&nbsp;</center>
                                                                        <center>{dImg ? <img src={downvote} onClick={(e, i) => changeDownvoteImage()} style={{ width: "55px" }} /> : <img src={downvoteb} onClick={(e, i) => changeDownvoteImage()} style={{ width: "45px" }} />}</center> */}
                                                                                    <center> {data.vote == 1 ? <img src={upvote} onClick={(e, i) => likeDislikeUpvoteSuggestionComment(data, 2)} style={{ width: "55px" }} /> : <img src={upvoteb} onClick={(e) => likeDislikeUpvoteSuggestionComment(data, 1)} style={{ width: "45px" }} />}</center>
                                                                                    <center className="mt-2 likes"> &nbsp;{data.totallike == null ? 0 : data.totallike}&nbsp;</center>
                                                                                    <center> {data.vote == -1 ? <img src={downvote} onClick={(e, i) => likeDislikeDownvoteSuggestionComment(data, 2)} style={{ width: "55px" }} /> : <img src={downvoteb} onClick={(e) => likeDislikeDownvoteSuggestionComment(data, 0)} style={{ width: "45px" }} />}</center>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row><br />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    }
                                                    {/* <div className='c-comments'>
                                                    <span style={{ display: "flex" }}><span style={{ fontSize: "18px" }}>John Doe </span>&nbsp;&nbsp;&nbsp;&nbsp;<li style={{ marginTop: "5px", color: "#AAAAAA", fontSize: "10px" }}>1 hour</li></span>
                                                    <div style={{ fontSize: "14px" }}>Suscipit egestas dolor massa magna nullam enim, dignissim. Sed est morbi auctor nibh morbi. Quisque tortor potenti fusce scelerisque adipiscing. Lectus mauris consectetur ut urna sit pellentesque blandit arcu in.</div>
                                                    <div style={{ display: "flex", marginTop: "10px" }}>
                                                        <center>{uImg ? <img src={upvote} onClick={(e, i) => this.changeUpvoteImage()} style={{ width: "55px" }} /> : <img src={upvoteb} onClick={(e, i) => this.changeUpvoteImage()} style={{ width: "45px" }} />}</center>
                                                        <center className="mt-2 likes"> &nbsp;&nbsp;{count}&nbsp;&nbsp;</center>
                                                        <center>{dImg ? <img src={downvote} onClick={(e, i) => this.changeDownvoteImage()} style={{ width: "55px" }} /> : <img src={downvoteb} onClick={(e, i) => this.changeDownvoteImage()} style={{ width: "45px" }} />}</center>
                                                        <span className="ml-5 mt-1"><img src={share} style={{ width: "22px" }} /> <span className="ml-2 comment">Share</span></span></div>
                                                </div><br /> */}

                                                </Card>
                                            </div>
                                            : <div className="mt-2">
                                                <Card>
                                                    {ratingData.map((data, i) => (
                                                        <div className="row">
                                                            <Col md={2}>
                                                                {/* <Card className="ml-2 mt-1 mb-2 card-padding-sm">
                                                                    <center> {data.vote == 1 ? <img src={upvote} onClick={(e, i) => changeUpvoteSuggestionImage(data, 2)} style={{ width: "60px", marginTop: "-0.6rem" }} /> : <img src={upvoteb} onClick={(e) => changeUpvoteSuggestionImage(data, 1)} style={{ width: "50px" }} />}</center>
                                                                    <center className="mt-3 likes mb-2">{data.totallike == null ? 0 : data.totallike}</center>
                                                                    <center> {data.vote == -1 ? <img src={downvote} onClick={(e, i) => changeDownvoteSuggestionImage(data, 2)} className="mt-2" style={{ width: "60px", marginTop: "-0.6rem" }} /> : <img src={downvoteb} onClick={(e) => changeDownvoteSuggestionImage(data, 0)} className="mt-2" style={{ width: "50px" }} />}</center>
                                                                </Card> */}
                                                            </Col>
                                                            <Col md={10}>
                                                                <div className="ml-2 mt-1 mb-2">
                                                                    <div className="card-padding">
                                                                        <h6 className="t-today">{data.companyName}</h6>
                                                                        <div className="posted">Posted by {data.userName == null ? "anonymous" : data.userName} &nbsp;&nbsp;{data.createdAt.split("T")[0].split("-").reverse().join("-")}</div>
                                                                        <div className="title">{data.productName}<span style={{ float: "right" }}>{data.rating}</span></div>
                                                                        <div className="desc">{data.comment}</div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </div>
                                                    ))}
                                                </Card><br />
                                            </div>

                                    }
                                </div>
                            </div>
                        </Col>

                        <Col md={4}>
                            <Card>
                                <div className="card-padding">
                                    <h6 className="t-tags">Greyffiti's Tags</h6>
                                    {Tags.map((data, i) => (
                                        <div>&nbsp;&nbsp;&nbsp;<Button id="talkbubble1" variant="default" className="tags_btn"><span className="circle"></span>{data.tags[0]}</Button>
                                            &nbsp;&nbsp;&nbsp; &nbsp;<Button id="talkbubble1" variant="default" className="tags_btn"><span className="circle"></span>{data.tags[1]}</Button>
                                            <Button id="talkbubble1" variant="default" className="mt-3 ml-3 tags_btn"><span className="circle"></span>{data.tags[2]}</Button></div>
                                    ))}<br /><br />
                                </div>
                            </Card>
                            <Card className="mt-4">
                                <div className="card-padding">
                                    <h6 className="t-tags">Moderators</h6>
                                    {Moderators.map((data, i) => (
                                        <div className="top-products-new">
                                            <img src={data.image} style={{ width: "30px" }} />
                                            <div className="ml-2 text-topproducts">{data.name}</div>
                                        </div>
                                    ))}
                                </div>
                            </Card>
                            <center className="mt-5 guidelines">
                                <div><span>User Agreement</span> <Link to="/privacypolicy" className="link" style={{ color: "black" }}><span className="ml-3">Privacy Policy</span></Link> <span className="ml-3">Content Policy</span></div>
                                <div className="mt-3">Moderators Guidelines</div>
                                <div className="mt-5">
                                    Copyright {new Date().getFullYear()} Feddup. All right reserved.
                                </div>
                            </center>
                        </Col>
                    </Row >

                </div > <br />
            </div >
        </div >
    );
}


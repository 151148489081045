import moment from "moment-timezone";
import { timeZoneCityToCountry } from "./constant";

const getTimeZoneDetails = (userTimeZone) => {
    var userRegion;
    var userCity;
    var userCountry;
    var timeZoneHours;

    var tzArr = userTimeZone.split("/");
    userRegion = tzArr[0];
    userCity = tzArr[tzArr.length - 1];
    userCountry = timeZoneCityToCountry[userTimeZone];
    timeZoneHours = moment().tz(userTimeZone).format('z') + '(' + moment().tz(userTimeZone).format('Z') + 'HRS)'
    return { userTimeZone, userRegion, userCity, userCountry, timeZoneHours }
}

export default getTimeZoneDetails;
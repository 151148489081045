import React, { Component } from "react";
import { Button, Form, Col, Row, Image, Modal, Card, Nav } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import fedduplogo from "../images/fedduplogo.png";
import loginimg from "../images/login-img.png";
import facebook from "../images/facebook.png";
import google from "../images/google.png";
import './Login.css';
import axios from 'axios';
import { config } from '../Config/Config';
import forgotImg from '../images/forgot-screen-img.png';
import landingpage from "../images/landingpage.png";
import toast from "react-hot-toast";
import { decrypt, encrypt } from "../utils";

const list = ["adventurer", "adventurer-neutral", "croodles", "croodles-neutral", "avataaars", "bottts", "big-ears", "micah", "open-peeps", "miniavs", "identicon"]
export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            showPassword: false,
            nav: false,
            login: false,
            verify: false,
            close: false,
            registerFlow: true,
            modal: false,
            product: false,
            admin: false,
            usersettings: false,
            blocked: false,
            styleItem: "",
            company: false,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    getRandomItem = (arr) => {
        const randomIndex = Math.floor(Math.random() * arr.length);
        const item = arr[randomIndex];
        return item;
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleSubmit(event) {
        event.preventDefault();
        this.validateLoginDetails();
        localStorage.setItem("value", this.state.email)
    }
    showPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    getUserType(accessToken) {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + accessToken,
            "applicationType": "web"
        }

        axios.get(config.userUrl + 'user/getUserType', { headers: headers })
            .then(function (response) {
                console.log("getUserType", response.data);
            })
            .catch(function (error) {
                console.log("Error", error);
            });

    }

    validateLoginDetails = (accessToken) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + accessToken,
            "applicationType": "web"
        }
        var payload = {
            "email": this.state.email,
            "password": encrypt(this.state.password)
        }
        var self = this
        axios.post(config.apiUrl + 'auth/login', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("Login successfull");
                    localStorage.setItem("junkStore", encrypt(response.data.accessToken))
                    localStorage.setItem("name", response.data.name)
                    localStorage.setItem("role", response.data.role)
                    localStorage.setItem("c", response.data.country)
                    localStorage.setItem("isActive", response.data.isActive);
                    localStorage.setItem("ssSeen", true);

                    const getUserDetails = async () => {
                        let headers = {
                            "Content-Type": 'application/json',
                            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                            "applicationType": "web"
                        }
                        axios.get(config.userUrl + 'user/details', { headers: headers })
                            .then(function (response) {
                                if (response.data.status === true) {
                                    localStorage.setItem("icon", response.data.data?.profilePicture || `https://api.dicebear.com/7.x/${list[Math.floor(Math.random() * list.length)]}/svg?seed=${response.data?.data?.firstName}`)

                                } else {
                                    console.log(response.data.message)
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }

                    getUserDetails();

                    if (response.data.role == "admin" && response.data.userStatus == "0") {
                        self.setState({ company: true })
                    } else if (response.data.role == "admin" && response.data.userStatus == "1") {
                        self.setState({ product: true })
                    } else if (response.data.role == "admin" && response.data.userStatus == "2") {
                        self.setState({ plan: true })
                    } else if (response.data.role == "admin" && response.data.userStatus == "3") {
                        self.setState({ admin: true })
                    } else if (response.data.role == "subAdmin") {
                        self.setState({ admin: true })
                    } else if (response.data.role == "individual" && response.data.isActive == 2) {
                        self.setState({ usersettings: true })
                    } else if (response.data.role == "individual" && response.data.isActive == 0) {
                        self.setState({ blocked: true })
                    } else {
                        self.setState({ login: true })
                    }
                } else if (response.data.status === false && response.data.userStatus === "unverified") {
                    self.setState({ close: true })
                } else {
                    document.getElementById('status').innerText = msg
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    verifyOtp = () => {
        this.setState({ verify: true })
    }
    sendOtp = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var payload = {
            "email": this.state.email,
            "registerFlow": this.state.registerFlow
        }
        var self = this;
        axios.post(config.apiUrl + 'auth/sendOtp', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    self.setState({ modal: true, close: false })
                } else {
                    document.getElementById('status').innerText = msg
                }
            })
            .catch(function (error) {
                toast("Error")
                console.log(error);
            });
    }
    render() {
        const { login, verify, plan, company, product, admin, usersettings, blocked } = this.state;
        if (login) {
            return <Redirect
                to={{
                    pathname: "/userfeed",
                    state: { email: this.state.email }
                }}
            />
        }
        if (usersettings) {
            return <Redirect
                to={{
                    pathname: "/activateordeactivate",
                    state: { email: this.state.email }
                }}
            />
        }
        if (plan) {
            return <Redirect
                to={{
                    pathname: "/buildprofile/pricing",
                    state: { email: this.state.email }
                }}
            />
        }
        if (product) {
            return <Redirect
                to={{
                    pathname: "/buildprofile/addproduct",
                    state: { email: this.state.email }
                }}
            />
        }
        if (admin) {
            return <Redirect
                to={{
                    pathname: "/admindashboard",
                    state: { email: this.state.email }
                }}
            />
        }
        if (company) {
            return <Redirect
                to={{
                    pathname: "/buildprofile/details",
                    state: { email: this.state.email }
                }}
            />
        }
        if (verify) {
            return <Redirect
                to={{
                    pathname: "/verifyemail",
                    state: { email: this.state.email, registerFlow: this.state.registerFlow }
                }}
            />
        }
        return (
            <div className="flex flex-col justify-between h-[100dvh]">
                <span> <Link to="/"> <img src={fedduplogo} className="logo-login mt-1" /></Link>
                    {/* <span className="help">Help?</span> */}
                </span>
                <Row className="flex justify-center items-center" style={{ width: "100%" }}>
                    <Col sm={7} md={7}>
                        <Image src={loginimg} className="img-login" />
                    </Col>
                    <Col sm={5} md={5}>
                        <div className="login">
                            <h3 className="forgot-top font_family font-bold text-6xl leading-10">Hi there,<br /><span className="text-3xl">what's making you feddup?</span></h3>
                            <Form onSubmit={this.handleSubmit} className="font_family max-w-[410px]">
                                <div className="mt-4 form-group">
                                    <input type="email" className="form-control border" aria-describedby="text"
                                        value={this.state.email}
                                        name="email"
                                        placeholder="Email"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="mt-4 form-group">
                                    <input type={this.state.showPassword ? "text" : "password"}
                                        name="password"
                                        className="form-control border"
                                        value={this.state.password}
                                        placeholder="Password"
                                        onChange={this.onChange}
                                    />
                                    <span className="showPassword"
                                        onClick={this.showPassword}
                                    >
                                        {this.state.showPassword ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                                    </span>

                                    <span id="status" className="error"></span>
                                    <br />
                                    <Link to="/forgotpassword" className="mt-2 forgot-password underline">
                                        Forgot Password?
                                    </Link>
                                </div>
                                <div className='btn-outer mx-auto'>
                                    <div className='btn-1 login-btn-1'></div>
                                    <Button
                                        variant="default" size="md"
                                        type="submit"
                                        className="login_button btn-2 login-btn-2"
                                    >
                                        Login
                                    </Button>
                                </div><br />
                                <div>
                                    {/* <span ><img src={google} style={{ width: "7%" }} className="mr-2" /><img src={facebook} style={{ width: "7%" }} className="ml-4" /></span>
                                    <br /><br /> */}
                                    <p>Don't have an account ?<Link to="/signup"><span style={{ color: "#E6570E" }} className="underline"> Register here</span></Link></p>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
                <Modal
                    size="lg"
                    show={this.state.close}
                    onHide={() => this.setState({ close: false })}
                    dialogClassName="w-full max-w-[38rem]"
                    aria-labelledby="example-modal-sizes-title-lg"
                    className="mt-20"
                >
                    <Modal.Header closeButton>
                        <Modal.Title >
                            {/* <h5 style={{ marginTop: "5px", fontFamily: "poppins" }}>&nbsp;Edit Suggestion Post</h5> */}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <center>
                            <p className="forgot-text ml-2 mr-2 font-medium text-3xl" style={{ marginTop: "-2rem" }}>Your Email is not yet Verified</p>
                            <p className="forgot-text-sm ml-2 mr-2">We sent an email you an email with the OTP earlier.</p><img alt="" src={landingpage} className="forgotImg mt-4" /></center><br /><br />
                        <Form className="forgot-email">
                            <center>
                                <div className="flex justify-between" style={{ width: "80%" }}>
                                    <span style={{ color: "#E6570E", cursor: "pointer", fontFamily: 'Poppins' }} onClick={this.sendOtp}> Resend Email</span>&nbsp;&nbsp;&nbsp;
                                    <Link to={{
                                        pathname: "/verifyemail",
                                        state: { email: this.state.email, registerFlow: this.state.registerFlow }
                                    }}  ><div className='btn-outer mx-auto'>
                                            <div className='btn-1 login-btn-1' style={{ marginLeft: "20px" }}></div>
                                            <Button
                                                variant="default" size="md"
                                                type="submit"
                                                className="login_button btn-2 login-btn-2"
                                            >Verify Now
                                            </Button>
                                        </div></Link>
                                </div>
                            </center>
                        </Form><br />
                    </Modal.Body>
                </Modal>
                <Modal
                    size="sm"
                    show={this.state.modal}
                    onHide={() => this.setState({ modal: false })}
                    dialogClassName="modal-60w"
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title >
                            {/* <h5 style={{ marginTop: "5px", fontFamily: "poppins" }}>&nbsp;Edit Suggestion Post</h5> */}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <center>
                            <p className="forgot-text" style={{ marginTop: "-2rem" }}>Email has been sent again.</p>
                            <p className="forgot-text-sm ml-2 mr-2">Please check your email.<br />If not recieved yet, please check in spam folder</p><img alt="" src={forgotImg} className="forgotImg" /></center><br /><br />
                        <Button
                            variant="default" size="md"
                            type="submit"
                            className="login_button"
                            onClick={this.verifyOtp}
                        >
                            Verify
                        </Button><br />
                    </Modal.Body>
                </Modal>
                <footer>
                    <p className="text-center py-6">Copyright {new Date().getFullYear()} Feddup. All right reserved</p>
                </footer>

            </div>
        );
    }
}

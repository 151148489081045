import React, { useState } from 'react'
import NavBar from '../NavBar/NavBar'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import moment from 'moment'
import { useQuery } from 'react-query'
import { config } from '../Config/Config'
import axios from 'axios'
import { decrypt } from '../utils'


const getCompanyDetails = () => {
    let headers = {
        "Content-Type": 'application/json',
        "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
        "applicationType": "web"
    }
    return axios.get(config.userUrl + 'admin/getCompanyProfileDetailsByAdmin', { headers: headers })
}

function Billing() {
    const history = useHistory()
    const [search, setSearch] = useState('')

    const { data: companyData } = useQuery("company-details", getCompanyDetails, {
        retry: false,
        refetchOnWindowFocus: false,
    });

    return (
        <section className='bg-[#F5F5F5] min-h-screen'>
            <NavBar />
            <div className='pt-24 px-5 2xl:px-10'>
                {companyData?.data?.companyDetail ? <div className='flex items-center gap-4'>
                    <svg className='cursor-pointer' onClick={() => history.goBack()} width="73" height="23" viewBox="0 0 73 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M70.8057 13.0742C71.6341 13.0742 72.3057 12.4026 72.3057 11.5742C72.3057 10.7458 71.6341 10.0742 70.8057 10.0742V13.0742ZM0.939507 10.5136C0.353722 11.0993 0.353722 12.0491 0.939507 12.6349L10.4855 22.1808C11.0712 22.7666 12.021 22.7666 12.6068 22.1808C13.1926 21.595 13.1926 20.6453 12.6068 20.0595L4.12149 11.5742L12.6068 3.08894C13.1926 2.50315 13.1926 1.5534 12.6068 0.967617C12.021 0.38183 11.0712 0.38183 10.4855 0.967617L0.939507 10.5136ZM70.8057 10.0742L2.00017 10.0742V13.0742L70.8057 13.0742V10.0742Z" fill="black" />
                    </svg>
                    <div className='w-24 h-24 rounded-full bg-white overflow-hidden'>
                        <img src={companyData?.data?.companyDetail?.companyIcon} className='w-full h-full object-contain' alt="" />
                    </div>
                    <h1 className='text-3xl font-medium'>{companyData?.data?.companyDetail?.companyName}</h1>
                </div> : ''}
                <div className='w-full max-w-7xl mx-auto py-4'>
                    <div className='flex items-center gap-2 px-4 py-1 rounded-full max-w-xl bg-white border'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                        </svg>
                        <input type="text" value={search} onChange={e => setSearch(e.target.value)} className='p-1 outline-none border-none bg-transparent flex-grow' placeholder='Search' />
                    </div>

                    <div className='bg-white p-3 rounded-lg mt-4 min-h-[40dvh] max-h-[60dvh] overflow-y-auto scroll-hidden'>
                        <table className='table-auto w-full' style={{ borderCollapse: 'separate', borderSpacing: '0px 12px' }}>
                            <thead>
                                <tr>
                                    <td><p className='text-lg font-medium pb-0.5 border-b-2 border-orange-500 max-w-max'>Invoice Number</p></td>
                                    <td><p className='text-lg font-medium pb-0.5 border-b-2 border-orange-500 max-w-max mx-auto'>Date</p></td>
                                    <td><p className='text-lg font-medium pb-0.5 border-b-2 border-orange-500 max-w-max mx-auto'>Currency</p></td>
                                    <td><p className='text-lg font-medium pb-0.5 border-b-2 border-orange-500 max-w-max mx-auto'>Amount</p></td>
                                    <td><p className='text-lg font-medium pb-0.5 border-b-2 border-orange-500 max-w-max mx-auto'>Transaction ID</p></td>
                                    <td><p className='text-lg font-medium pb-0.5 border-b-2 border-orange-500 max-w-max mx-auto'>Payment Mode</p></td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {[1, 2, 3, 5, 6].map((item, i) => <tr key={i}>
                                    <td className='border-b border-orange-300 p-1'>qwertyedf123456543</td>
                                    <td className='border-b border-orange-300 p-1 text-center'>{moment().format('DD/MM/YY')}</td>
                                    <td className='border-b border-orange-300 p-1 text-center'>INR</td>
                                    <td className='border-b border-orange-300 p-1 text-center'>199</td>
                                    <td className='border-b border-orange-300 p-1 text-center'>qwertyedf123456543</td>
                                    <td className='border-b border-orange-300 p-1 text-center'>Card</td>
                                    <td className='border-b border-orange-300 p-1'>
                                        <div className='p-1 bg-[#FFB858] rounded w-8 h-8 grid place-items-center cursor-pointer'>
                                            <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.07227 14.3691V16.6191C1.07227 17.2159 1.30932 17.7882 1.73128 18.2101C2.15323 18.6321 2.72553 18.8691 3.32227 18.8691H16.8223C17.419 18.8691 17.9913 18.6321 18.4133 18.2101C18.8352 17.7882 19.0723 17.2159 19.0723 16.6191V14.3691M14.5723 9.86914L10.0723 14.3691M10.0723 14.3691L5.57227 9.86914M10.0723 14.3691V0.869141" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Billing
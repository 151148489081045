import React, { useRef, useState, useEffect, version } from "react";
import { Button, Form, Modal, Dropdown, Card, Row, Col, OverlayTrigger, Tooltip, Popover } from "react-bootstrap";
import { Link, NavLink, Redirect, useLocation } from "react-router-dom";
import fedduplogo from "../images/fedduplogo.png";
import NavBar from "../NavBar/NavBar";
import './CompanyFeed.css';
import landingpage from '../images/landingpage.png';
import fillTag from "../images/fill-tag.svg";
import trending from '../images/trending.svg';
import newData from '../images/new.svg';
import top from '../images/top.svg';
import background from '../images/background-img.svg';
import axios from 'axios';
import { config } from '../Config/Config';
// import { Editor } from 'react-draft-wysiwyg';
// import { convertToRaw, EditorState } from "draft-js";
// import draftToHtmlPuri from "draftjs-to-html";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import premium from "../images/premium.svg";
import separator from "../images/separator.svg"
import ProductRatingSection from "./ProductRatingSection";
import ProductFeedbackSection from "./ProductFeedbackSection";
import ProductSuggestionSection from "./ProductSuggestionSection";
import ProductAnnouncementSection from "./ProductAnnouncementSection";
import RatingImages from "../utils/DynamicRating/DynamicRatingImages";
import Tag from "../utils/Tag";
import { ratingDescription } from "../utils/ratingDescription";
import PageNotFound from "../UsersFeed/PageNotFound";
import noReviews from "../images/no-reviews.svg";
import NotLoggedIn from "./NotLoggedIn";
import { decrypt, openLink } from "../utils";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}


export default function CompanyFeed(props) {
    const query = useQuery();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedValue, setSelectedValue] = useState(0);
    const [trendingTopProduct, setTrendingTopProduct] = useState([])
    const [item, setItem] = useState("Ratings")
    const [rate1, setRate1] = useState(true);
    const [follow, setFollow] = useState(false);
    const [userFollowers, setUserFollowers] = useState("");
    const [rating, setRating] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [companyId, setCompanyId] = useState("")
    const [productId, setProductId] = useState("")
    const [productName, setProductName] = useState("")
    const [productWebsite, setProductWebsite] = useState("")
    const [productType, setProductType] = useState("")
    const [productSector, setProductSector] = useState("")
    const [productTags, setProductTags] = useState([])
    const [productIcon, setProductIcon] = useState("")
    const [productDesc, setProductDesc] = useState("")
    const [emptyProduct, setEmptyProduct] = useState(false)
    const [defaultTags, setDefaultTags] = useState([])
    const [showTags, setShowTags] = useState(false);
    const [tags, setTags] = useState([]);
    const [currentVersion, setCurrentVersion] = useState('');
    const [versions, setVersions] = useState([]);
    const [selectedVersion, setSelectedVersion] = useState('')
    const [membershipType, setMembershipType] = useState('')
    const [companyIcon, setCompanyIcon] = useState('')
    const [productOverallRating, setProductOverallRating] = useState(0);
    const [productOverallRaters, setProductOverallRaters] = useState(0);
    const [overallRatingCountArray, setOverallRatingCountArray] = useState([])
    const [overallNumberOfRatings, setOverallNumberOfRatings] = useState(0);
    const [isGuideline, setIsGuideline] = useState(false);
    const [guidelines, setGuideLines] = useState([]);
    const [privatePage, setPrivatePage] = useState(false);
    const [requested, setRequested] = useState(false)
    const [popUp, setPopUp] = useState({
        open: false,
        type: '',
        onCancel: null,
        onConfirm: null
    })

    const [state, setState] = useState(false)


    const value = decodeURI(window.location.pathname);
    const urlItems = value.split('/');
    useEffect(() => {
        window.scrollTo(0, 0);
        getProductId();
        getDefaultTags();
        getTrendingTopProducts();
        if (query.get('type')) {
            if (query.get('type') == 'Suggestion' || query.get('type') == 'Feedback' || query.get('type') == 'Announcement') {
                setItem(query.get('type'))
            }
        }
    }, [window.location.pathname])

    useEffect(() => {
        if (productId) {
            getProductDetails();
        }
    }, [productId])
    const ref = useRef(null);
    const addRatingHover = (e, val) => {
        setRating(val)
        console.log("rating", rating)
    }
    const feedback = () => {
        setItem("Feedback")
        setSelectedValue(0)
    }
    const ratingProduct = () => {
        setItem("Ratings")
    }
    const suggestion = () => {
        setItem("Suggestion")
        setSelectedValue(0)
    }
    const announcements = () => {
        setItem("Announcement")
        setSelectedValue(0)
    }
    const ratingNew1 = () => {
        setRate1(!rate1)
    }

    const getTrendingTopProducts = () => {
        let headers;
        if (localStorage.getItem('junkStore')) {
            headers = {
                'Accept': 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                'Content-Type': 'application/json'
            }
        } else {
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        var payload = {
        }
        axios.post(config.userUrl + 'user/getTrendingTopProduct', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    console.log("trendingTopProduct successfull");
                    setTrendingTopProduct(response.data.topProduct)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    const getDefaultTags = () => {
        var headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getDefaultTag', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    for (let i = 0; i < response.data.getDefaultTag.length; i++) {
                        response.data.getDefaultTag[i].isSelected = false
                    }
                    setDefaultTags(response.data.getDefaultTag)
                } else {
                    console.log("error");
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    const getProductId = async () => {
        let headers;
        if (localStorage.getItem('junkStore')) {
            headers = {
                'Accept': 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        } else {
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        let result = await axios.get(config.userUrl + `by/${urlItems[2]}/${urlItems[3]}/${urlItems[4]}`, { headers: headers })
            .then(function (response) {
                return response.data
            })
            .catch(function (error) {
                console.log("Error", error);
                window.location.replace('/404');
                setEmptyProduct(true)
            });

        if (result?.companyData?.[0]?.id) {
            setProductId(result.companyData[0]?.id)
        } else {
            window.location.replace('/404');
            setEmptyProduct(true)
        }
    };
    const getProductDetails = async () => {
        let headers;
        if (localStorage.getItem('junkStore')) {
            headers = {
                'Accept': 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                'Content-Type': 'application/json'
            }
        } else {
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        console.log("productId", productId)
        var payload = {
            "productId": productId,
        }
        let result = await axios.post(config.userUrl + "user/getProductDetailsByProductId", payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setPrivatePage(response.data.productDetails.pageType == 'Private')
                    setCompanyName(response.data.productDetails.companyName)
                    setGuideLines(response.data.productDetails.guidelines)
                    setCompanyId(response.data.productDetails.companyId)
                    setProductId(response.data.productDetails?.id)
                    setProductName(response.data.productDetails.productName)
                    setProductIcon(response.data.productDetails.productIcon)
                    setProductDesc(response.data.productDetails.productDescription)
                    setProductWebsite(response.data.productDetails.productWebsite)
                    setProductSector(response.data.productDetails.productSector)
                    setTags(response.data.productDetails.searchTags?.split(","));
                    setProductType(response.data.productDetails.productType)
                    setMembershipType(response.data.productDetails.membershipType)
                    setCompanyIcon(response.data.productDetails.companyIcon)
                    let arr = [...response.data.numberOfRating];
                    arr = arr.map((item) =>
                        Number(item.count)
                    )
                    let ratingData = Math.max(...arr)
                    setProductTags(response.data.productTag[0]?.tag?.split(","))
                    setFollow(response.data.follow)
                    setUserFollowers(response.data.userFollowers)
                    setIsLoading(false)
                    setCurrentVersion(response.data.currentVersion);
                    let versionArray = response.data.versions || [];
                    versionArray.sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate))
                    setVersions(versionArray);
                    setSelectedVersion(response.data.currentVersion)
                    setRequested(response.data.requested)
                    setProductOverallRaters(response.data.numberOfRaters)
                    setProductOverallRating(response.data.averageRating)
                    setOverallRatingCountArray(response.data.numberOfRating);
                    setOverallNumberOfRatings(ratingData + 3);
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    const getProductRating = (version) => {
        if (productId === '') return
        let headers;
        if (localStorage.getItem('junkStore')) {
            headers = {
                'Accept': 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                'Content-Type': 'application/json'
            }
        } else {
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        var payload = {
            "productId": productId,
        }
        axios.post(config.userUrl + "user/getProductRating", payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    let arr = [...response.data.numberOfRating];
                    arr = arr.map((item) =>
                        Number(item.count)
                    )
                    let ratingData = Math.max(...arr)
                    setProductOverallRaters(response.data.numberOfRaters)
                    setProductOverallRating(response.data.averageRating)
                    setOverallRatingCountArray(response.data.numberOfRating);
                    setOverallNumberOfRatings(ratingData + 3);
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    const followProduct = (id, val) => {
        if (localStorage.getItem('junkStore')) {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                "applicationType": "web"
            }
            var payload = {
                "productId": productId,
                "companyIdBelongTo": companyId,
                "flag": val
            }
            setPopUp({
                open: false
            });
            axios.post(config.userUrl + "user/createProductFollowByUser", payload, { headers: headers })
                .then(function (response) {
                    if (response.data.status === true) {
                        console.log("followOrUnfollowProduct successfull");

                        getProductDetails();
                    }
                })
                .catch(function (error) {
                    console.log("Error", error);
                });
        } else {
            console.log("Cannot follow")
        }
    };
    const requestPageAccess = () => {
        if (localStorage.getItem('junkStore')) {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                "applicationType": "web"
            }
            var payload = {
                "productId": productId,
                "companyId": companyId,
            }
            setRequested(true);
            axios.post(config.userUrl + "user/requestPageAccess", payload, { headers: headers })
                .then(function (response) {
                    if (response.data.status === true) {
                        getProductDetails();
                    }
                })
                .catch(function (error) {
                    console.log("Error", error);
                });
        } else {
            console.log("Cannot follow")
        }
    };
    const withdrawPageRequest = () => {
        if (localStorage.getItem('junkStore')) {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                "applicationType": "web"
            }
            var payload = {
                "productId": productId,
                "companyId": companyId,
            }
            setRequested(false);
            axios.post(config.userUrl + "user/withdrawPageRequest", payload, { headers: headers })
                .then(function (response) {
                    if (response.data.status === true) {
                        getProductDetails();
                    }
                })
                .catch(function (error) {
                    console.log("Error", error);
                });
        } else {
            console.log("Cannot follow")
        }
    };


    const onSelect = (eventKey) => {
        setSelectedValue(parseInt(eventKey))
    }
    const onVersionSelect = (id) => {
        let current = [];
        if (id === currentVersion?.id) {
            current[0] = currentVersion;
        } else {
            current = versions.filter(version => version.id === id)
        }
        setSelectedVersion(current[0]);

    }

    const [visible, setVisible] = useState(true)

    const toggleVisible = () => {
        if (window.scrollY > 250) {
            setVisible(false)
        }
        else if (window.scrollY <= 250) {
            setVisible(true)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible);
        return () => {
            window.removeEventListener('scroll', toggleVisible)
        }
    }, [visible])

    if (emptyProduct) {
        return (
            <PageNotFound />
        )
    }

    return (
        <div>
            {state ? <NotLoggedIn setState={setState} /> : <></>}
            <NavBar />
            <div className={`flex gap-1 justify-around ${visible === false ? "fixed top-12 z-50 p-2" : "invisible"} w-full`}>
                <div className="w-full max-w-7xl flex items-center gap-10 px-2">
                    <div className="flex-grow flex items-center justify-between shadow-xl bg-white py-3 rounded-lg px-2">
                        <div className="flex items-center gap-4">
                            <div className='btn-outer-section'>
                                <Card className={`btn-inner-section text-xs py-2 px-3 ${item === "Ratings" ? "feed-card f-color" : "feed-card-a f-color-g"}`} onClick={ratingProduct}>Ratings</Card>
                            </div>

                            <div className='btn-outer-section'>
                                <Card className={`btn-inner-section text-xs py-2 px-3 ${item === "Feedback" ? "feed-card f-color " : "feed-card-a f-color-g"}`} onClick={feedback}>Feddback</Card>
                            </div>


                            <div className='btn-outer-section'>
                                <Card className={`btn-inner-section text-xs py-2 px-3 ${item === "Suggestion" ? "feed-card f-color" : "feed-card-a f-color-g"}`} onClick={suggestion}>Suggestion</Card>
                            </div>

                            <div className='btn-outer-section'>
                                <Card className={`btn-inner-section text-xs py-2 px-3 ${item === "Announcement" ? "feed-card f-color" : "feed-card-a f-color-g"}`} onClick={announcements}>Announcements</Card>
                            </div>
                        </div>

                        <div className="flex items-center gap-4">
                            <Dropdown onSelect={onVersionSelect} align="end">
                                {currentVersion?.version == "0.0.0" ? "" : <Dropdown.Toggle variant="default" className="abc feed-card-a min-w-[80px] w-max" style={{ float: "right", border: "1px solid #E6570E", borderRadius: "5px" }} >
                                    <div className="flex items-center justify-between relative gap-2 px-1 w-full">
                                        <div className="flex flex-col gap-0 items-end justify-center">
                                            <span className="text-xs font-semibold">{selectedVersion && selectedVersion.version}</span>
                                            <span className={`text-[10px] text-right ${selectedVersion?.id !== currentVersion?.id ? "hidden" : ""}`}>{selectedVersion?.id === currentVersion?.id ? "(latest)" : ""}</span>
                                        </div>
                                        <b><i className="fa fa-angle-down ml-1"></i></b>
                                    </div>
                                </Dropdown.Toggle>}

                                <Dropdown.Menu className="border-none min-w-max px-2">
                                    {versions && versions?.slice(0, 3).map((version) => {
                                        return (
                                            <Dropdown.Item eventKey={version?.id} className="text-black" key={version?.id}>{version.version}{version?.latestVersion ? `(latest)` : ''}</Dropdown.Item>
                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown onSelect={onSelect}>
                                <Dropdown.Toggle variant="default" className="abc feed-card-a" style={{ float: "right", minWidth: "70px", border: "1px solid #E6570E", borderRadius: "5px" }} >
                                    <img src={selectedValue == 1 ? top : (selectedValue == 2 ? newData : trending)} style={{ width: "20px" }} />&nbsp; &nbsp;<b><i className="fa fa-angle-down"></i></b>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="border-none min-w-max">
                                    <Dropdown.Item eventKey={0} className="d-text"><div className="flex items-center gap-1"><img src={trending} style={{ width: "20px" }} /> &nbsp;Trending</div></Dropdown.Item>
                                    <Dropdown.Item eventKey={2} className="d-text"><div className="flex items-center gap-1"><img src={newData} style={{ width: "20px" }} /> &nbsp;New</div></Dropdown.Item>
                                    <Dropdown.Item eventKey={1} className="d-text"><div className="flex items-center gap-1"><img src={top} style={{ width: "20px" }} /> &nbsp;Top</div></Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="w-full max-w-sm"></div>
                </div>
            </div>

            <div className="bg-gray-100 min-h-[100dvh]">
                <img src={background} style={{ width: "100%" }} />
                <div className="mx-auto w-full flex items-start gap-10 max-w-7xl transform -mt-20 2xl:-mt-32 px-3">
                    <div className="w-full flex-grow">
                        <div className="z-20">
                            <Card className="rounded-xl relative">
                                <div className="absolute rounded-xl -bottom-0.5 top-0 left-0 right-0" style={{ background: `linear-gradient(95.84deg, #E6570E 0%, #FFB858 99.7%)` }}>

                                </div>
                                <div className="card-padding relative rounded-xl z-10 !m-0 bg-white" style={{ minHeight: '200px' }}>
                                    <Row style={{ width: "100%", padding: "20px" }} className="rounded-xl">
                                        <Col md={3}>
                                            <div className="flex flex-col justify-between items-center" style={{ width: "140px" }}>
                                                <img src={productIcon} style={{ width: "100%", objectFit: 'contain' }} />
                                                <img src={separator} alt="" className="w-28 h-auto my-2" />
                                                <img src={companyIcon} className="w-28 !h-[50px] mt-2 object-contain" />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div><span className="text-3xl font-medium">{productName}</span> <span className="text-lg">({productType})</span></div>
                                            <div className="font-medium">By {companyName}</div>
                                            <div className="flex items-center gap-4 py-1">
                                                <p onClick={() => openLink(productWebsite)} className="text-sky-500 hover:text-sky-600 flex items-center gap-1 text-sm cursor-pointer">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
                                                    </svg>
                                                    <span>Visit Website</span>
                                                </p>
                                                <span className="cursor-pointer text-yellow-500 text-sm" onClick={() => setShowTags(true)}>View product tags</span>
                                            </div>

                                            <p className="text-gray-400 text-sm my-1">{productDesc}</p>

                                            <div className=" mb-2"> <span className="text-2xl font-medium">{userFollowers}</span><span className="ml-2 text-base text-gray-400">Followers</span></div>
                                            <div className="flex items-center gap-5">
                                                {localStorage.getItem('junkStore') ?
                                                    <div className='btn-outer'>
                                                        <div className='btn-1'>
                                                        </div>
                                                        {follow ?
                                                            <Button variant="default" onClick={(e, i) => privatePage ? setPopUp({
                                                                open: true,
                                                                onCancel: () => setPopUp({
                                                                    open: false
                                                                }),
                                                                onConfirm: () => followProduct(companyId, false)
                                                            })
                                                                : followProduct(companyId, false)} className="btn-2 group"><span className="group-hover:hidden inline-block">Following</span><span className="hidden group-hover:inline-block">- Unfollow</span></Button>
                                                            :
                                                            privatePage
                                                                ?
                                                                requested
                                                                    ?
                                                                    <Button variant="default" onClick={() => withdrawPageRequest()} className="btn-2 !font-normal">Withdraw</Button>
                                                                    :
                                                                    <Button variant="default" onClick={(e, i) => requestPageAccess()} className="btn-2 !font-normal">Request to join</Button>
                                                                :
                                                                <Button variant="default" onClick={(e, i) => followProduct(companyId, true)} className="btn-2">+ Follow</Button>}
                                                    </div> : ""}


                                            </div>

                                            <Modal
                                                size="md"
                                                show={showTags}
                                                onHide={() => setShowTags(false)}
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered
                                            >
                                                <Modal.Header closeButton>
                                                    <Modal.Title >
                                                        <div><span>{productName}</span> <span className="productType">({productType})</span><span> Search Tags</span></div>
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className="transform -translate-y-6">
                                                        {tags ?
                                                            <div className="flex flex-wrap items-start p-2 rounded min-h-[20vh]">
                                                                {tags.map((tag, i) => {
                                                                    return (
                                                                        <Tag text={tag} key={i} />
                                                                        // <span key={i} className="mr-2 rounded-2xl  min-w-[100px] min-h-[30px] text-center px-2" style={{ backgroundColor: "#FFB858", fontSize: "18px" }}>{tag}</span>
                                                                    )
                                                                })}
                                                            </div> :
                                                            <div className="flex flex-wrap ml-24 p-2 min-h-[10vh] text-2xl">No Tags Present Now!!</div>
                                                        }
                                                    </div>
                                                </Modal.Body>
                                            </Modal>
                                        </Col>
                                        <Col md={3}>
                                            <div className="absolute flex flex-col justify-between items-end w-full h-full">
                                                <img src={premium} alt="" className={`w-20 h-20 ${membershipType === "Premium" ? "" : "invisible"}`} />
                                                <div className="flex flex-col justify-end items-end">
                                                    <div><span className="text-gray-500 text-sm">({productOverallRaters})</span><span className="text-2xl font-bold text-black">{productOverallRating} </span></div>
                                                    {/* <div style={{ backgroundColor: "#E6570E", width: `${((Math.round(productOverallRating * 10) / 10) / 5) * 100}%` }}> <img src={ratingImg} style={{ width: "140px" }} /></div> */}
                                                    {productOverallRating > 0 ?
                                                        <RatingImages rating={productOverallRating} />
                                                        :
                                                        <img src={noReviews} alt='' style={{ width: "40px", height: "40px" }} />
                                                    }
                                                    <p className='2xl:text-lg xl:text-base text-sm font-semibold'>{ratingDescription(productOverallRating)}</p>

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                </div>
                            </Card>
                            {!privatePage || follow ? <div className="mt-3">
                                <div className="row mx-1">
                                    <Col md={12}>
                                        <div className={`row flex items-center justify-between ${visible === false ? "invisible" : "mt-3"}`}>
                                            <div className="flex items-center gap-4">
                                                <div className='btn-outer-section'>
                                                    <Card className={`btn-inner-section text-xs py-2 px-3 ${item === "Ratings" ? "feed-card f-color" : "feed-card-a f-color-g"}`} onClick={ratingProduct}>Ratings</Card>
                                                </div>

                                                <div className='btn-outer-section'>
                                                    <Card className={`btn-inner-section text-xs py-2 px-3 ${item === "Feedback" ? "feed-card f-color " : "feed-card-a f-color-g"}`} onClick={feedback}>Feddback</Card>
                                                </div>

                                                <div className='btn-outer-section'>
                                                    <Card className={`btn-inner-section text-xs py-2 px-3 ${item === "Suggestion" ? "feed-card f-color" : "feed-card-a f-color-g"}`} onClick={suggestion}>Suggestion</Card>
                                                </div>

                                                <div className='btn-outer-section'>
                                                    <Card className={`btn-inner-section text-xs py-2 px-3 ${item === "Announcement" ? "feed-card f-color" : "feed-card-a f-color-g"}`} onClick={announcements}>Announcements</Card>
                                                </div>
                                            </div>

                                            <div className="flex items-center gap-4">
                                                <Dropdown onSelect={onVersionSelect}>
                                                    {currentVersion?.version == "0.0.0" ? "" : <Dropdown.Toggle variant="default" className="abc feed-card-a min-w-[80px] w-max" style={{ float: "right", border: "1px solid #E6570E", borderRadius: "5px" }} >
                                                        <div className="flex gap-1 items-center justify-between px-1 w-full">
                                                            <div className="flex flex-col gap-0 items-end justify-center">
                                                                <span className="text-xs font-semibold">{selectedVersion && selectedVersion.version}</span>
                                                                <span className={`text-[10px] ${selectedVersion?.id !== currentVersion?.id ? "hidden" : ""}`}>{selectedVersion?.id === currentVersion?.id ? "(latest)" : ""}</span>
                                                            </div>
                                                            <b><i className="fa fa-angle-down ml-1"></i></b>
                                                        </div>
                                                    </Dropdown.Toggle>}

                                                    <Dropdown.Menu className="border-none min-w-max px-2">
                                                        {versions && versions?.slice(0, 3).map((version) => {
                                                            return (
                                                                <Dropdown.Item eventKey={version?.id} className="text-black" key={version?.id}>{version.version}{version?.latestVersion ? `(latest)` : ''}</Dropdown.Item>
                                                            )
                                                        })}
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                                <Dropdown onSelect={onSelect}>
                                                    <Dropdown.Toggle variant="default" className="abc feed-card-a" style={{ float: "right", minWidth: "70px", border: "1px solid #E6570E", borderRadius: "5px" }} >
                                                        <img src={selectedValue == 1 ? top : (selectedValue == 2 ? newData : trending)} style={{ width: "20px" }} />&nbsp; &nbsp;<b><i className="fa fa-angle-down"></i></b>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className="border-none min-w-max">
                                                        <Dropdown.Item eventKey={0} className="d-text"><div className="flex items-center gap-1"><img src={trending} style={{ width: "20px" }} /> &nbsp;Trending</div></Dropdown.Item>
                                                        <Dropdown.Item eventKey={2} className="d-text"><div className="flex items-center gap-1"><img src={newData} style={{ width: "20px" }} /> &nbsp;New</div></Dropdown.Item>
                                                        <Dropdown.Item eventKey={1} className="d-text"><div className="flex items-center gap-1"><img src={top} style={{ width: "20px" }} /> &nbsp;Top</div></Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                            </div> : ''}
                        </div>




                        {
                            privatePage && !follow ?
                                <>
                                    <div className="bg-red-200 h-96 w-full mt-4 rounded-lg text-white flex flex-col items-center justify-center" style={{ background: `linear-gradient(180deg, #E6570E 0%, rgba(230, 87, 14, 0) 100%)` }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-20 h-20">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                        </svg>
                                        <h1 className="text-3xl font-medium mt-2 mb-1">This is a private page.</h1>
                                        <p className="text-lg">Request to join this page to post and review here</p>
                                    </div>
                                </>
                                :
                                item == "Feedback" ?
                                    <ProductFeedbackSection setState={setState} companyName={companyName} productName={productName} productType={productType} sharedId={query.get('id')} guidelines={guidelines?.length ? () => setIsGuideline(true) : null} canReview={selectedVersion?.reviewEnabled} initialTags={productTags?.map(tag => ({ isSelected: false, tag: tag }))} companyId={companyId} productId={productId} selectedVersion={selectedVersion} />
                                    : (item == "Suggestion") ?
                                        <ProductSuggestionSection setState={setState} companyName={companyName} productName={productName} productType={productType} sharedId={query.get('id')} guidelines={guidelines?.length ? () => setIsGuideline(true) : null} canReview={selectedVersion?.reviewEnabled} initialTags={productTags?.map(tag => ({ isSelected: false, tag: tag }))} companyId={companyId} productId={productId} selectedVersion={selectedVersion} selectedValue={selectedValue} />
                                        : (item == "Ratings") ?
                                            <ProductRatingSection guidelines={guidelines?.length ? () => setIsGuideline(true) : null} companyId={companyId} productId={productId} selectedVersion={selectedVersion} overallNumberOfRatings={overallNumberOfRatings} overallRatingCountArray={overallRatingCountArray} getProductRating={getProductRating} />
                                            : <ProductAnnouncementSection setState={setState} companyName={companyName} productName={productName} productType={productType} sharedId={query.get('id')} initialTags={productTags?.map(tag => ({ isSelected: false, tag: tag }))} companyId={companyId} productId={productId} selectedVersion={selectedVersion} />
                        }
                    </div>
                    <div className={`w-full max-w-sm relative`} style={{
                        position: 'sticky',
                        top: '80px'
                    }}>
                        <div className="">
                            <div style={{ border: "2px solid #FFB633", borderRadius: "10px", padding: "8px" }} className="bg-white">
                                <div className="card-padding">
                                    <h6 className="t-tags">{productName}'s Tags</h6>
                                    {
                                        productTags?.filter(el => el?.length)?.length
                                            ?
                                            <div className='flex flex-wrap gap-x-3 gap-y-3 py-3'>
                                                {productTags?.map(tag => <Tag tag={tag} text={tag} />
                                                )}
                                            </div>
                                            :
                                            <div className="mt-3 flex">No tags</div>
                                    }

                                </div>
                            </div>
                            <div className="mt-4 flex flex-col relative" >
                                <div className="bg-white" style={{ border: "2px solid #FFB633", borderRadius: "10px", padding: "8px" }}>
                                    <h6 className="t-tags">Top Products</h6>
                                    {trendingTopProduct.length == 0 ? <div className="no-details-text">No Trending Products</div> :
                                        <div>
                                            {trendingTopProduct.map((data, i) => (
                                                <Link key={i} to={{
                                                    pathname: `/by/${data.companyName}/${data.productName}/${data.productType}`,
                                                    state: {
                                                        id: data.productId,
                                                        productName: data.productName,
                                                        companyName: data.companyName,
                                                        productTypeString: data.productType
                                                    }
                                                }} style={{ color: "black", textDecoration: "none" }}>
                                                    <div className="top-products-new">
                                                        <img src={data.productIcon} style={{ width: "30px" }} />
                                                        <div className="ml-2 text-topproducts">{data.productName}</div>
                                                    </div>
                                                </Link>
                                            ))}
                                        </div>}
                                </div>
                                <center className="mt-3 guidelines">
                                    <div className="mt-3 text-base">
                                        &copy; {new Date().getFullYear()} Feddup. All right reserved.
                                    </div>
                                </center>
                            </div>

                        </div>
                    </div >

                </div >
            </div>
            {isGuideline && <div className='fixed inset-0 grid place-items-center' style={{ zIndex: 13000 }}>
                <div className='fixed inset-0 bg-black bg-opacity-50' onClick={() => setIsGuideline(false)}>
                </div>
                <div className='px-5 py-4 bg-white rounded-lg w-full max-w-5xl min-h-[650px] z-10 flex flex-col opacTranslate'>
                    <h1 className='text-2xl font-medium'>Community guidelines</h1>
                    <div className='flex-grow space-y-2 pt-3 pb-4'>
                        {
                            guidelines.map((item, i) => {
                                return (
                                    <div className='flex items-center gap-2 w-full'>
                                        <h1 className='text-xl font-medium w-5 text-right'>{i + 1}</h1>
                                        <div className='flex items-center w-full gap-2 rounded-lg pl-2 pr-4' style={{ background: 'rgba(230, 87, 14, 0.12)' }}>
                                            <input type="text" readOnly value={item} className='flex-grow p-2 bg-transparent focus:outline-none' />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='flex items-center justify-center'>

                        <div className='btn-outer'>
                            <div className='btn-1 ml-auto' style={{ width: "150px", height: "35px" }}>
                            </div>
                            <Button
                                variant="default" size="lg"
                                type="submit"
                                style={{ float: "right", width: "150px", height: "35px", lineHeight: "14px", fontSize: "18px" }}
                                className="btn-2 mr-[1px]"
                                onClick={() => setIsGuideline(false)}
                            >
                                Understood
                            </Button>
                        </div>
                    </div>
                </div>
            </div>}

            <Modal
                size='lg'
                show={popUp.open}
                onHide={popUp.onCancel}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body className='w-full'>

                    <div className='flex flex-col'>
                        <div className='flex flex-col items-center justify-center flex-grow pt-10 pb-16'>
                            <svg width="176" height="176" viewBox="0 0 176 176" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M88.1143 175.359C136.439 175.359 175.614 136.184 175.614 87.8594C175.614 39.5345 136.439 0.359375 88.1143 0.359375C39.7893 0.359375 0.614258 39.5345 0.614258 87.8594C0.614258 136.184 39.7893 175.359 88.1143 175.359Z" fill="#FFB858" />
                                <path d="M88.1143 105.932L88.1143 38.9961" stroke="#E6570E" stroke-width="15" stroke-linecap="round" />
                                <path d="M88.1143 136.724C93.6371 136.724 98.1143 132.246 98.1143 126.724C98.1143 121.201 93.6371 116.724 88.1143 116.724C82.5914 116.724 78.1143 121.201 78.1143 126.724C78.1143 132.246 82.5914 136.724 88.1143 136.724Z" fill="#E6570E" />
                            </svg>

                            <div className="mt-3 text-3xl font-medium text-orange">Are you sure to unfollow?</div>
                            <p className="mt-2 text-lg">You have to request again to follow</p>
                        </div>

                        <div className='flex items-center justify-between w-full '>
                            <button className='py-1.5 px-4 rounded-lg text-white font-medium text-lg bg-orange-300' onClick={popUp.onCancel}>No, Cancel</button>
                            <button className='py-1.5 px-4 rounded-lg text-white font-medium text-lg bg-orange capitalize' onClick={popUp.onConfirm}>Yes, Unfollow</button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div >
    );
}
// const uploadImageCallBack = async (image) => {
//     let data = new FormData()
//     data.append("file", image)
//     data.append("upload_preset", "ankit_kumar")
//     data.append("cloud_name", "ankit628792")
//     const resp = await fetch(`https://api.cloudinary.com/v1_1/ankit628792/image/upload`, {
//         method: "post",
//         body: data
//     })
//     let res = await resp.json();
//     return res
// }
function uploadImageCallBack(file) {
    return new Promise(
        (resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', 'https://api.cloudinary.com/v1_1/ankit628792/image/upload');
            const data = new FormData();
            data.append('file', file);
            data.append("upload_preset", "ankit_kumar")
            data.append("cloud_name", "ankit628792")
            xhr.send(data);
            xhr.addEventListener('load', () => {
                const response = JSON.parse(xhr.responseText);
                console.log(response)
                resolve(response);
            });
            xhr.addEventListener('error', () => {
                const error = JSON.parse(xhr.responseText);
                console.log(error)
                reject(error);
            });
        }
    );
}
// const options = {
//     options: ['inline', 'link', 'image'],
//     inline: {
//         inDropdown: false,
//         className: undefined,
//         component: undefined,
//         dropdownClassName: undefined,
//         options: ['bold', 'italic', 'underline'],
//     },
//     link: {
//         inDropdown: false,
//         className: undefined,
//         component: undefined,
//         popupClassName: undefined,
//         dropdownClassName: undefined,
//         showOpenOptionOnHover: true,
//         defaultTargetOption: '_self',
//         options: ['link', 'unlink'],
//         link: { className: undefined },
//         unlink: { className: undefined },
//         linkCallback: undefined
//     },
//     image: {
//         uploadCallback: uploadImageCallBack,
//         alt: { present: true, mandatory: true }
//     },
// }



import React, { useEffect, useState } from 'react'
import NavBar1 from '../NavBar/NavBar1'
import arrowLeft from "../images/arrow-left-black.svg"
import axios from 'axios'
import { config } from '../Config/Config';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { decrypt } from '../utils';

function Members() {
    const history = useHistory();
    const { productid } = useParams();
    const [item, setItem] = useState("Members")
    const [searchInput, setSearchInput] = useState("")
    const [productIcon, setProductIcon] = useState('')
    const [productName, setProductName] = useState('')
    const [productType, setProductType] = useState('')
    const [members, setMembers] = useState([])
    const [requests, setRequests] = useState([])
    const [popUp, setPopUp] = useState({
        open: false,
        type: '',
        onCancel: null,
        onConfirm: null
    })


    useEffect(() => {
        getMembersAndRequests();
        if (window.location.href?.includes("type=request")) {
            setItem("Requests")
        }
        window.scrollTo(0, 0)
    }, [])

    const getMembersAndRequests = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "productId": productid
        }
        axios.post(config.userUrl + "admin/getAllMembersAndRequest", payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    setProductIcon(response.data.product.productIcon)
                    setProductName(response.data.product.productName)
                    setProductType(() => response.data.product.productType === 1 ? "Desktop Application" : "Web Application")
                    setMembers(response.data.members)
                    setRequests(response.data.requests)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    const removeUser = (user) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "id": user.id
        }
        setPopUp({
            open: false
        });
        axios.post(config.userUrl + "admin/removeMember", payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    getMembersAndRequests()
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    }
    const removeRequests = (user) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "id": user.id
        }
        axios.post(config.userUrl + "admin/removeRequests", payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    getMembersAndRequests();
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    }
    const addRequest = (user) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "id": user.id,
            "productId": productid,
        }
        axios.post(config.userUrl + "admin/addRequest", payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    getMembersAndRequests()
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    }

    const filter = (item) => {
        return item?.userName?.toLowerCase().includes(searchInput?.toLowerCase())
    }

    return (
        <div>
            <NavBar1 />
            <div className='bg-gray-100 h-screen pl-16 pr-24 mt-20 pt-10'>
                <div className='flex gap-4 items-center'>
                    <img src={arrowLeft} alt='' onClick={() => history.goBack()} className='cursor-pointer' />
                    <div className='bg-white rounded-full' style={{ width: "100px", height: "100px" }}>
                        <img src={productIcon} alt='' />
                    </div>
                    <div className='flex flex-col'>
                        <span className='text-2xl font-semibold'>{productName}</span>
                        <span className='text-xl font-medium'>{productType}</span>
                    </div>
                </div>
                <div className='max-w-5xl mx-28 mt-8'>
                    <div className='flex gap-6 mb-4'>
                        <div className='btn-outer-section w-32'>
                            <div className={item == "Members" ? "feed-card f-color btn-inner-section" : "feed-card-a f-color-g btn-inner-section"} onClick={() => setItem("Members")}>Members</div>
                        </div>
                        <div className='btn-outer-section w-32'>
                            <div className={item == "Requests" ? "feed-card f-color btn-inner-section" : "feed-card-a f-color-g btn-inner-section"} onClick={() => setItem("Requests")}>Requests</div>
                        </div>
                    </div>
                    <span className='text-2xl font-semibold ml-2'>{item === "Members" ? `${members.length} Member(s)` : `${requests.length} Request(s)`} </span>
                    <div className="mt-4 flex justify-between">
                        <input
                            type="text"
                            placeholder="Search for member"
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                            id="search"
                            className="abc form-control ml-0 bg-white rounded-full"
                            style={{ width: "460px", height: "45px", borderRadius: "25px", paddingLeft: "3rem" }}
                            aria-label="Search keyword"
                        />
                    </div>
                    <div className='bg-white mt-4 rounded-xl px-4'>

                        <Table style={{ display: item == 'Members' ? members?.length ? 'table' : 'none' : requests?.length ? 'table' : 'none' }} responsive bordered={false}>
                            <thead>
                                {item === "Members" ?
                                    <tr className=''>
                                        <th className='text-orange-600 underline underline-offset-4'><div className="text-black">User</div></th>
                                        <th className='text-orange-600 underline underline-offset-4'><div className="text-black">Joined On</div></th>
                                        <th className='text-orange-600 underline underline-offset-4'><div className="text-black">No Of Posts</div></th>
                                        <th></th>
                                    </tr> :
                                    <tr className=''>
                                        <th className='text-orange-600 underline underline-offset-4'><div className="text-black">User</div></th>
                                        <th className='text-orange-600 underline underline-offset-4'><div className="text-black ">Requested On</div></th>
                                        <th></th>
                                    </tr>
                                }
                            </thead>
                            <tbody>
                                {item === "Members" ?
                                    members && members.filter(filter).map((member, index) => {
                                        return (
                                            <tr className='font-semibold' key={index}>
                                                <td><div className=''>{member.userName}</div></td>
                                                <td><div className=''>{moment(member.updatedAt).format("DD/MM/YY")}</div></td>
                                                <td><div className=''>{member.postCount}</div></td>
                                                <td>
                                                    <div className=' flex justify-end items-center'>
                                                        <Button
                                                            variant="default" size="sm"
                                                            type="submit"
                                                            style={{ width: "100px", height: "30px", lineHeight: "10px", fontSize: "14px" }}
                                                            className='bg-orange-400 rounded-lg text-white'
                                                            onClick={() => {
                                                                setPopUp({
                                                                    open: true,
                                                                    onCancel: () => setPopUp({
                                                                        open: false
                                                                    }),
                                                                    onConfirm: () => removeUser(member)
                                                                })
                                                            }}
                                                        >
                                                            Remove User
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :

                                    requests && requests.filter(filter).map((request, index) => {
                                        return (
                                            <tr className='font-semibold' key={index}>
                                                <td><div className=''>{request.userName}</div></td>
                                                <td>
                                                    <div className=''>
                                                        <span className='font-bold'>{moment(request.updatedAt).format("DD/MM/YY")} </span>
                                                        <span className='text-md pl-2'>Auto reject in 12 days</span>
                                                    </div>
                                                </td>
                                                <td className='d-flex align-items-end'>
                                                    <div className='w-full flex justify-end items-end gap-4'>
                                                        <Button
                                                            variant="default" size="sm"
                                                            type="submit"
                                                            style={{ width: "100px", height: "30px", lineHeight: "10px", fontSize: "14px" }}
                                                            className='bg-green-700 rounded-lg text-white'
                                                            onClick={() => {
                                                                addRequest(request)
                                                            }}
                                                        >
                                                            Approve
                                                        </Button>
                                                        <Button
                                                            variant="default" size="sm"
                                                            type="submit"
                                                            style={{ width: "100px", height: "30px", lineHeight: "10px", fontSize: "14px" }}
                                                            className='bg-orange-400 rounded-lg text-white'
                                                            onClick={() => {
                                                                removeRequests(request)
                                                            }}
                                                        >
                                                            Reject
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>

            <Modal
                size='lg'
                show={popUp.open}
                onHide={popUp.onCancel}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body className='w-full'>

                    <div className='flex flex-col'>
                        <div className='flex flex-col items-center justify-center flex-grow pt-10 pb-16'>
                            <svg width="176" height="176" viewBox="0 0 176 176" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M88.1143 175.359C136.439 175.359 175.614 136.184 175.614 87.8594C175.614 39.5345 136.439 0.359375 88.1143 0.359375C39.7893 0.359375 0.614258 39.5345 0.614258 87.8594C0.614258 136.184 39.7893 175.359 88.1143 175.359Z" fill="#FFB858" />
                                <path d="M88.1143 105.932L88.1143 38.9961" stroke="#E6570E" stroke-width="15" stroke-linecap="round" />
                                <path d="M88.1143 136.724C93.6371 136.724 98.1143 132.246 98.1143 126.724C98.1143 121.201 93.6371 116.724 88.1143 116.724C82.5914 116.724 78.1143 121.201 78.1143 126.724C78.1143 132.246 82.5914 136.724 88.1143 136.724Z" fill="#E6570E" />
                            </svg>

                            <div className="mt-3 text-3xl font-medium text-orange">Are you sure to remove the user?</div>
                        </div>

                        <div className='flex items-center justify-between w-full '>
                            <button className='py-1.5 px-4 rounded-lg text-white font-medium text-lg bg-orange-300' onClick={popUp.onCancel}>No, Cancel</button>
                            <button className='py-1.5 px-4 rounded-lg text-white font-medium text-lg bg-orange capitalize' onClick={popUp.onConfirm}>Yes, Remove</button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Members
import React, { useRef, useState, useEffect } from "react";
import { Button, Form, Modal, Dropdown, Card, Row, Col, OverlayTrigger, Tooltip, Popover } from "react-bootstrap";
import { Link, NavLink, Redirect, useHistory } from "react-router-dom";
import fedduplogo from "../images/fedduplogo.png";
import NavBar from "../NavBar/NavBar";
import './Profile.css';
import fillTag from "../images/fill-tag.svg";
import top from '../images/new.svg';
import oldest from '../images/oldest.svg';
import mostcommented from '../images/mostcommented.svg';
import mostshared from '../images/mostshared.svg';
import upvoteb from '../images/upvote-b.svg';
import downvoteb from '../images/downvote-b.svg';
import upvote from '../images/upvote.svg';
import downvote from '../images/downvote.svg';
import ImgEdit from "../images/edit.svg";
import imgDel from "../images/delete.svg";
import dots from "../images/dots.svg";
import share from '../images/share.svg';
import comment from '../images/comments.svg';
import background from '../images/background-img.svg';
import usersettings from '../images/usersettings.svg';
import axios from 'axios';
import { config } from '../Config/Config';
import DeleteFeedbackPopup from "../UsersFeed/DeleteFeedbackPopup";
import EditFeedbackPopup from "../UsersFeed/EditFeedbackPopup";
import DeleteSuggestionPopup from "../UsersFeed/DeleteSuggestionPopup";
import EditSuggestionPopup from "../UsersFeed/EditSuggestionPopup";
import Tag from "../utils/Tag";
import suggestionAccepted from '../images/suggestion-accepted.svg';
import suggestionRejected from '../images/suggestion-rejected.svg';
import suggestionInprogress from '../images/suggestion-inprogress.svg'
import orating1 from "../images/fill-rating1.png";
import orating2 from "../images/fill-rating2.png";
import orating3 from "../images/fill-rating3.png";
import orating4 from "../images/fill-rating4.png";
import orating5 from "../images/fill-rating5.png";
import moment from "moment";
import { ratingDescription, userRatingDesc } from "../utils/ratingDescription";
import RatingImages from "../utils/DynamicRating/DynamicRatingImages";
import noReviews from "../images/no-reviews.svg";
import ShareBox from "../UsersFeed/ShareBox";
import PopupComments from "../UsersFeed/PopupComments";
import ViewImage from "../UsersFeed/ViewImage";
import PopupSuggestionComments from "../UsersFeed/PopupSuggestionComments";
import { decrypt } from "../utils";

export default function AdminProfile() {
    const history = useHistory()
    const [deletePost, setDeletePost] = useState(false);
    const [edit, setEdit] = useState(false);
    const [deleteSuggestionPost, setDeleteSuggestionPost] = useState(false);
    const [editSuggestionPost, setEditSuggestionPost] = useState(false);
    const [feedId, setFeedId] = useState("");
    const [suggId, setSuggId] = useState("");
    const [title, setTitle] = useState("");
    const [image, setImage] = useState("");
    const [postFeedback, setPostFeedback] = useState("");
    const [postSuggestion, setPostSuggestion] = useState("");
    const [userFeedbacks, setUserFeedbacks] = useState([]);
    const [userSuggestions, setUserSuggestions] = useState([]);
    const [userRatings, setUserRatings] = useState([]);
    const [visitedPages, setVisitedPages] = useState([]);
    const [userName, setUserName] = useState("");
    const [activeType, setActiveType] = useState("Feedback");
    const [selectedValue, setSelectedValue] = useState(0);
    const [feedbackCount, setFeedbackCount] = useState("");
    const [suggestionCount, setSuggestionCount] = useState("");
    const [tag, setTag] = useState([]);
    const [flagActive, setFlagActive] = useState('');
    const [productId, setProductId] = useState();
    const [isFollow, setIsFollow] = useState(false);
    const [followedProducts, serFollowedProducts] = useState([])

    useEffect(() => {
        getAllUserFeedbacks(0);
        getAllUserSuggestions(0);
        getAllUserRatings();
        getFollowedProducts();
    }, [])

    useEffect(() => {
        if (activeType == 'flagged') {
            setFlagActive('Feedback')
        }
        else {
            setFlagActive('')
        }
    }, [activeType])

    const feedback = () => {
        setActiveType("Feedback")
        setSelectedValue(0)
    }
    const suggestion = () => {
        setActiveType("Suggestion")
        setSelectedValue(0)
    }
    const ratings = () => {
        setActiveType("Rating")

    }
    function shareArticle(data, val) {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        if (navigator.share) {
            navigator.share({
                url: `https://www.feddup.com/${data.title}`,
            }).then(() => {
                console.log('Thanks for sharing!', data.title);
            }).catch(err => {
                console.log(
                    "Error while using Web share API:");
                console.log(err);
            });
        } else {
            console.log("Sorry! Your browser does not support Web Share API");
        }
        if (val == "feedback") {
            var payload = {
                "feedbackIdd": data.id,
                "userIdFeedbackBelongTo": data.feedbackByUserId
            }
            console.log("payload", payload)
            axios.post(config.userUrl + 'user/shareFeedbackByUser', payload, { headers: headers })
                .then(function (response) {
                    if (response.data.status == true) {
                        // setTimeout(() => {
                        //     getAllFeedbacks(0);
                        // }, 1000);
                        console.log("feedback shared successfully", response.data.message, payload);
                    } else {
                        console.log("Error", payload);
                    }
                })
                .catch(function (error) {
                    console.log("error", error, payload);
                });
        } else if (val == "suggestion") {
            var payload = {
                "suggestionId": data.id,
                "userIdSuggestionBelongTo": data.suggestionByUserId
            }
            console.log("payload", payload)
            axios.post(config.userUrl + 'user/shareSuggestionByUser', payload, { headers: headers })
                .then(function (response) {
                    if (response.data.status == true) {
                        // setTimeout(() => {
                        //     getSuggestions(0);
                        // }, 1000)
                        console.log("suggestion shared successfully", response.data.message, payload);
                    } else {
                        console.log("Error", payload);
                    }
                })
                .catch(function (error) {
                    console.log("error", error, payload);
                });
        } else {
            var payload = {
                "announcementId": data.id,
                "userIdAnnouncementBelongTo": data.announcementByAdminId
            }
            axios.post(config.userUrl + 'user/announcementShare', payload, { headers: headers })
                .then(function (response) {
                    if (response.data.status == true) {
                        console.log("announcement shared successfully", response.data.message, payload);
                    } else {
                        console.log("Error", payload);
                    }
                })
                .catch(function (error) {
                    console.log("error", error, payload);
                });
        }
    };
    const ref = useRef(null);
    const onChangeFeedbackRecommendation = (val) => {
        setSelectedValue(val);
        getAllUserFeedbacks(val)
    }
    const onChangeSuggestionRecommendation = (val) => {
        setSelectedValue(val);
        getAllUserSuggestions(val)
    }
    const onSelect = (eventKey) => {
        setSelectedValue(parseInt(eventKey))
        getAllUserSuggestions(parseInt(eventKey))
    }
    const changeUpvoteImage = () => {
        console.log("data")
    }
    const changeDownvoteImage = () => {
        console.log("data")
    }
    const getAllUserFeedbacks = (eventKey) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "type": parseInt(eventKey)
        }
        axios.post(config.userUrl + 'user/getUSerDetailFeedback', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setUserFeedbacks(response.data.feedback)
                    setFeedbackCount(response.data.getFeedbackCount)
                    setSuggestionCount(response.data.getSuggestionCount)
                    setUserName(response.data.user.userName)
                    setVisitedPages(response.data.visitPage)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const getAllUserSuggestions = (eventKey) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "type": parseInt(eventKey)
        }
        axios.post(config.userUrl + 'user/getUSerDetailSuggestion', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setUserSuggestions(response.data.suggestion)
                    setFeedbackCount(response.data.getFeedbackCount)
                    setSuggestionCount(response.data.getSuggestionCount)
                    setUserName(response.data.user.userName)
                    setVisitedPages(response.data.visitPage)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const getAllUserRatings = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getUSerDetailRating', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setUserRatings(response.data.userRating)
                    setFeedbackCount(response.data.getFeedbackCount)
                    setSuggestionCount(response.data.getSuggestionCount)
                    setUserName(response.data.user.userName)
                    setVisitedPages(response.data.visitPage)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const getFollowedProducts = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getFollowedProducts', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    serFollowedProducts(response.data.data)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    function editFeedback(id) {
        setProductId(id?.productId)
        setEdit(!edit)
        setTitle(id.title)
        setPostFeedback(id.feedback)
        setImage(id.feedbackImage)
        setTag(id.tag)
        setFeedId(id.id)
    }
    function closeEditFeedback() {
        setEdit(false)
        setTitle("")
        setPostFeedback("")
        setImage("")
        setTag("")
        setFeedId("")
    }

    function editSuggestion(id) {
        setProductId(id.productId)
        setEditSuggestionPost(!editSuggestionPost)
        setTitle(id.title)
        setPostSuggestion(id.suggestion)
        setImage(id.suggestionImage)
        setTag(id.tag)
        setSuggId(id.id)
    }
    function closeEditSuggestion() {
        setEditSuggestionPost(false)
        setTitle("")
        setPostSuggestion("")
        setTag("")
        setImage("")
        setSuggId("")
    }
    function deleteFeedback(id) {
        setDeletePost(!deletePost)
        setFeedId(id.id)
    }
    function closeDeleteFeedback() {
        setDeletePost(false)
        setFeedId("")
    }
    function deleteSuggestion(id) {
        setDeleteSuggestionPost(!deleteSuggestionPost)
        setSuggId(id.id)
    }
    function closeDeleteSuggestion() {
        setDeleteSuggestionPost(false)
        setSuggId("")
    }

    function changeUpvoteFeedback(id, val) {
        if (localStorage.getItem('junkStore')) {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                "applicationType": "web"
            }

            var payload = {
                "feedbackId": id.id,
                "feedback": val,
                "userIdFeedbackBelongTo": id.feedbackByUserId
            }
            console.log("upvote", payload)
            if (val == 1) {
                console.log("upvote", payload)
                let idx = userFeedbacks?.findIndex(item => item.id == id.id);
                let arr = [...userFeedbacks];
                arr[idx].vote = 1;
                arr[idx].totallike = Number(arr[idx].totallike) + 1;
                setUserFeedbacks(arr);
                axios.post(config.userUrl + 'user/likeOrDislikeFeedback', payload, { headers: headers })
                    .then(function (response) {
                        if (response.data.status === true) {
                            // getAllFeedbacksByProductId(selectedValue);
                        }
                    })
                    .catch(function (error) {
                        let idx = userFeedbacks?.findIndex(item => item.id == id.id);
                        let arr = [...userFeedbacks];
                        arr[idx].vote = 0;
                        arr[idx].totallike = Number(arr[idx].totallike) - 1;
                        setUserFeedbacks(arr);
                        console.log(error, payload)
                    })
            }
        } else {
            console.log("No upvote")
        }
    }
    function changeDownvoteFeedback(id, val) {
        if (localStorage.getItem('junkStore')) {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                "applicationType": "web"
            }

            var payload = {
                "feedbackId": id.id,
                "feedback": val,
                "userIdFeedbackBelongTo": id.feedbackByUserId
            }
            console.log("upvote", payload)
            if (val == 0) {
                let idx = userFeedbacks?.findIndex(item => item.id == id.id);
                let arr = [...userFeedbacks];
                arr[idx].vote = -1;
                arr[idx].totallike = Number(arr[idx].totallike) - 1;
                setUserFeedbacks(arr);
                axios.post(config.userUrl + 'user/likeOrDislikeFeedback', payload, { headers: headers })
                    .then(function (response) {
                        if (response.data.status === true) {
                            // getAllFeedbacksByProductId(selectedValue);
                        }
                    })
                    .catch(function (error) {
                        let idx = userFeedbacks?.findIndex(item => item.id == id.id);
                        let arr = [...userFeedbacks];
                        arr[idx].vote = 0;
                        arr[idx].totallike = Number(arr[idx].totallike) + 1;
                        setUserFeedbacks(arr);
                        console.log(error, payload)
                    })
            }
        } else {
            console.log('No Downvote')
        }
    }

    function changeUpvoteSuggestion(id, val) {
        if (localStorage.getItem('junkStore')) {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                "applicationType": "web"
            }

            var payload = {
                "suggestionId": id.id,
                "suggestion": val,
                "userIdSuggestionBelongTo": id.suggestionByUserId
            }

            if (val == 1) {
                let idx = userSuggestions?.findIndex(item => item.id == id.id);
                let arr = [...userSuggestions];
                arr[idx].vote = 1;
                arr[idx].totallike = Number(arr[idx].totallike) + 1;
                setUserSuggestions(arr);

                axios.post(config.userUrl + 'user/likeOrDislikeSuggestion', payload, { headers: headers })
                    .then(function (response) {
                        if (response.data.status == true) {
                            // getAllSuggestionsByProductId(selectedValue);
                        }
                    })
                    .catch(function (error) {
                        arr[idx].vote = 0;
                        arr[idx].totallike = Number(arr[idx].totallike) - 1;
                        setUserSuggestions(arr);
                        console.log(error, payload)
                    })
            }
        } else {
            console.log('No upvote')
        }
    }
    function changeDownvoteSuggestion(id, val) {
        if (localStorage.getItem('junkStore')) {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                "applicationType": "web"
            }

            var payload = {
                "suggestionId": id.id,
                "suggestion": val,
                "userIdSuggestionBelongTo": id.suggestionByUserId
            }
            if (val == 0) {
                let idx = userSuggestions?.findIndex(item => item.id == id.id);
                let arr = [...userSuggestions];
                arr[idx].vote = -1;
                arr[idx].totallike = Number(arr[idx].totallike) - 1;
                setUserSuggestions(arr);

                axios.post(config.userUrl + 'user/likeOrDislikeSuggestion', payload, { headers: headers })
                    .then(function (response) {
                        if (response.data.status == true) {
                            console.log("upvote")
                            // getAllSuggestionsByProductId(selectedValue);
                        }
                    })
                    .catch(function (error) {
                        arr[idx].vote = 0;
                        arr[idx].totallike = Number(arr[idx].totallike) + 1;
                        setUserSuggestions(arr);
                        console.log(error, payload)
                    })
            }
        } else {
            console.log('No downvote')
        }
    }

    const [viewImage, setViewImage] = useState(false)
    const [popup, setPopup] = useState(false)
    const [popupData, setPopupData] = useState([])
    const [popupSuggestion, setPopupSuggestion] = useState(false);
    const [popupSuggestionData, setPopupSuggestionData] = useState([]);

    const openImage = (data) => {
        setViewImage(data.feedbackImage || data.suggestionImage)
    }

    const closeImage = () => {
        setViewImage(false)
    }
    const togglePopup = (data) => {
        if (localStorage.getItem('junkStore')) {
            setPopup(!popup)
            setPopupData({ ...data })
        } else {
            setPopup(false)
        }
    }

    const togglePopupSuggestion = (data) => {
        if (localStorage.getItem('junkStore')) {
            setPopupSuggestion(!popupSuggestion)
            setPopupSuggestionData({ ...data })
        } else {
            setPopupSuggestion(false)
        }
    }

    return (
        <div className="overflow-x-hidden">
            {popupSuggestion && <PopupSuggestionComments openSuggestionImage={openImage} closePopup={togglePopupSuggestion} data={popupSuggestionData} selectedValue={0} />}

            {popup && <PopupComments openImage={openImage} closePopup={togglePopup} getAllFeedbacks={() => { }} data={popupData} selectedValue={0} />}
            {viewImage && <ViewImage close={closeImage} image={viewImage} />}

            {deletePost && <DeleteFeedbackPopup closePopup={closeDeleteFeedback} getAllFeedbacks={getAllUserFeedbacks} data={feedId} selectedValue={selectedValue} />}
            {edit && <EditFeedbackPopup productId={productId} closePopup={closeEditFeedback} feedId={feedId} image={image} getAllFeedbacks={getAllUserFeedbacks} title={title} postFeedback={postFeedback} tag={tag} selectedValue={selectedValue} />}
            {deleteSuggestionPost && <DeleteSuggestionPopup closePopup={closeDeleteSuggestion} getSuggestions={getAllUserSuggestions} data={suggId} selectedValue={selectedValue} />}
            {editSuggestionPost && <EditSuggestionPopup productId={productId} closePopup={closeEditSuggestion} image={image} getSuggestions={getAllUserSuggestions} suggId={suggId} title={title} postSuggestion={postSuggestion} tag={tag} selectedValue={selectedValue} />}
            <NavBar />
            <div id="background-profile" style={{ height: '100dvh', overflowY: 'hidden' }}>
                <div style={{ paddingTop: "5rem", borderTop: "1px solid lightgrey", borderBottom: "none", borderLeft: "none", borderRight: "none" }}>
                </div>


                <div className="w-full max-w-[90rem] mx-auto px-5 2xl:px-0">
                    <Row style={{ width: "100%" }} className="no-gutters">
                        <Col md={4}>
                            <Card className="mt-1" style={{ borderRadius: "6px", width: "85%" }}>
                                <img src={background} className="bg-p-img" />
                                <img src={background} className="bg-p-img -mt-1" />
                                <div className="relative px-4" >
                                    <div className=" -mt-10 flex flex-col justify-center items-center max-w-max">
                                        <div className="profile-Img">
                                            <img src={localStorage.getItem("icon")} style={{ width: "55px" }} />
                                        </div>
                                        <div className="text-gray-800 my-1 font-medium">{userName}</div>
                                    </div>
                                    <span className="absolute top-3 right-3">
                                        <Link to="/usersettings/account" className="link">
                                            <img src={usersettings} style={{ width: "24px" }} />
                                        </Link>
                                    </span>
                                </div>

                                <div className="grid grid-cols-2 gap-y-4 p-4">
                                    <div className="flex items-center gap-3">
                                        <div>
                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M46.4766 1.79395H1.47461V46.7939H46.4766V1.79395Z" fill="#E6570E" />
                                                <path d="M46.4758 1.79395H1.7207V46.549H46.4758V1.79395Z" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M35.3469 26.7686C35.3469 28.0049 35.0996 29.2412 34.7287 30.3539C33.2451 34.8047 29.0416 38.0191 24.0963 38.0191C19.151 38.0191 14.9475 34.8047 13.4639 30.3539C13.093 29.2412 12.8457 28.0049 12.8457 26.7686H35.3469Z" fill="black" stroke="black" stroke-width="5.6693" stroke-miterlimit="10" stroke-linecap="round" />
                                                <path d="M22.6143 8.50529C22.7379 10.4834 21.9961 14.4397 16.9272 18.8904C10.251 18.025 7.53106 15.0578 6.542 13.327C6.1711 12.7088 6.04747 12.3379 6.04747 12.2143C5.42931 9.98888 6.66564 7.51622 8.89103 6.89806C11.1164 6.2799 13.5891 7.51622 14.2072 9.74162C13.5891 7.51622 14.8254 5.04357 17.0508 4.4254C19.2762 3.80724 21.7488 5.04357 22.367 7.26896C22.4906 7.26896 22.6143 7.76349 22.6143 8.50529Z" fill="black" />
                                                <path d="M25.5806 8.50529C25.4569 10.4834 26.1987 14.4397 31.2677 18.8904C37.9438 18.025 40.6638 15.0578 41.6528 13.327C42.0237 12.7088 42.1474 12.3379 42.1474 12.2143C42.7655 9.98888 41.5292 7.51622 39.3038 6.89806C37.0784 6.2799 34.6058 7.51622 33.9876 9.74162C34.6058 7.51622 33.3694 5.04357 31.144 4.4254C28.9186 3.80724 26.446 5.04357 25.8278 7.26896C25.7042 7.26896 25.5806 7.76349 25.5806 8.50529Z" fill="black" />
                                                <path d="M35.3469 26.7686C35.3469 28.0049 35.0996 29.2412 34.7287 30.3539H13.4639C13.093 29.2412 12.8457 28.0049 12.8457 26.7686H35.3469Z" fill="white" />
                                            </svg>
                                        </div>
                                        <div className="text-right">
                                            <h1 className="text-3xl text-orange">2</h1>
                                            <h1 className="font-medium">Rating</h1>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-3">
                                        <div>
                                            <svg width="48" height="37" viewBox="0 0 51 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M37.9453 25.959L46.854 34.8817" stroke="#E6570E" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M46.874 25.979L37.9253 34.8617" stroke="#E6570E" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M0.524118 8.16177C0.524118 12.2158 3.81056 15.5022 7.8646 15.5022C11.9186 15.5022 15.2051 12.2158 15.2051 8.16177C15.2051 4.10773 11.9186 0.821289 7.8646 0.821289C3.81056 0.821289 0.524118 4.10773 0.524118 8.16177Z" fill="#FFB858" />
                                                <path d="M13.0625 17.7368C14.6091 18.566 13.9043 19.8729 15.4509 20.7021C16.9975 21.5313 17.7022 20.2244 19.2488 21.0536C20.7954 21.8828 20.0906 23.1897 21.6372 24.0189C23.1838 24.8481 23.8885 23.5412 25.4351 24.3704C26.9817 25.1996 26.2769 26.5065 27.8235 27.3357" stroke="#E6570E" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M15.7422 12.9883C17.2888 13.8175 16.584 15.1243 18.1306 15.9535C19.6771 16.7827 20.3819 15.4759 21.9285 16.3051C23.4751 17.1343 22.7703 18.4411 24.3169 19.2703C25.8634 20.0995 26.5682 18.7927 28.1148 19.6219C29.6613 20.4511 28.9566 21.7579 30.5032 22.5871" stroke="#E6570E" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M18.4199 8.24072C19.9665 9.06992 19.2617 10.3768 20.8083 11.206C22.3549 12.0352 23.0596 10.7283 24.6062 11.5575C26.1528 12.3867 25.448 13.6936 26.9946 14.5228C28.5412 15.352 29.2459 14.0451 30.7925 14.8743C32.3391 15.7035 31.6343 17.0104 33.1809 17.8396" stroke="#E6570E" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M49.9118 23.7268C49.7397 25.361 48.529 28.4798 43.4573 31.3482C37.7314 29.632 35.7692 26.7976 35.1481 25.2345C34.9108 24.6736 34.8551 24.352 34.8726 24.251C34.6466 22.3401 36.0802 20.5077 38.1174 20.3394C40.1546 20.171 42.1455 21.5545 42.3716 23.4654C42.1455 21.5545 43.5792 19.7221 45.6163 19.5538C47.6535 19.3854 49.6444 20.7689 49.8705 22.6798C49.9788 22.6985 50.017 23.121 49.9118 23.7268Z" fill="#04C700" />
                                            </svg>

                                        </div>
                                        <div className="text-right">
                                            <h1 className="text-3xl text-orange">{suggestionCount}</h1>
                                            <h1 className="font-medium">Suggestions</h1>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-3">
                                        <div>
                                            <svg width="48" height="33" viewBox="0 0 52 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M26.5681 13.7588V28.0347H31.4498H33.4545H35.6136H42.6695L45.6641 31.7062L50.9766 13.7588L26.5681 13.7588Z" fill="#E6570E" stroke="#E6570E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M34.0854 18.6585C34.1306 19.3817 33.8594 20.828 32.0063 22.4551C29.5656 22.1388 28.5712 21.054 28.2096 20.4212C28.074 20.1952 28.0288 20.0596 28.0288 20.0144C27.8028 19.2009 28.2548 18.2969 29.0684 18.0709C29.8819 17.8449 30.7859 18.2969 31.0119 19.1105C30.7859 18.2969 31.2379 17.3929 32.0515 17.1669C32.865 16.941 33.769 17.3929 33.995 18.2065C34.0402 18.2065 34.0854 18.3873 34.0854 18.6585Z" fill="#FFB858" />
                                                <path d="M42.7949 18.9365L45.2356 21.3772" stroke="#FFB858" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M45.2356 18.9365L42.7949 21.3772" stroke="#FFB858" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M36.013 20.1336C36.013 21.1985 36.8762 22.0617 37.9411 22.0617C39.0059 22.0617 39.8691 21.1985 39.8691 20.1336C39.8691 19.0688 39.0059 18.2056 37.9411 18.2056C36.8762 18.2056 36.013 19.0688 36.013 20.1336Z" fill="#FFB858" />
                                                <path d="M25.3851 1.69434V15.9702H20.5034H18.4987H16.3396L9.28366 15.9702L6.289 19.6418L0.976562 1.69434L25.3851 1.69434Z" fill="#FFB858" stroke="#FFB858" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M17.8677 6.59403C17.8225 7.3172 18.0937 8.76355 19.9469 10.3907C22.3876 10.0743 23.3819 8.98954 23.7435 8.35676C23.8791 8.13077 23.9243 7.99518 23.9243 7.94998C24.1503 7.13641 23.6983 6.23245 22.8847 6.00645C22.0712 5.78046 21.1672 6.23245 20.9412 7.04601C21.1672 6.23245 20.7152 5.32848 19.9017 5.10249C19.0881 4.8765 18.1841 5.32848 17.9581 6.14205C17.9129 6.14205 17.8677 6.32284 17.8677 6.59403Z" fill="#E6570E" />
                                                <path d="M9.1582 6.87207L6.7175 9.31278" stroke="#E6570E" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M6.7175 6.87207L9.1582 9.31278" stroke="#E6570E" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M15.9401 8.06918C15.9401 9.13402 15.0769 9.99725 14.0121 9.99725C12.9472 9.99725 12.084 9.13402 12.084 8.06918C12.084 7.00434 12.9472 6.14111 14.0121 6.14111C15.0769 6.14111 15.9401 7.00434 15.9401 8.06918Z" fill="#E6570E" />
                                            </svg>
                                        </div>
                                        <div className="text-right">
                                            <h1 className="text-3xl text-orange">{feedbackCount}</h1>
                                            <h1 className="font-medium">Feedbacks</h1>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-3">
                                        <div className="w-5">

                                        </div>
                                        <div className={`text-right ${followedProducts?.length ? 'cursor-pointer' : ''}`} onClick={() => followedProducts?.length ? setIsFollow(true) : {}}>
                                            <h1 className="text-3xl text-orange">{followedProducts?.length}</h1>
                                            <h1 className="font-medium">Following pages</h1>
                                        </div>
                                    </div>
                                </div>


                                <div className="mt-3"></div>
                            </Card>
                            <Card className="mt-2" style={{ borderRadius: "6px", width: "85%" }}>
                                <div className="ml-2 mt-2 mb-2">
                                    <h6 className="mt-3 text-orange font-bold text-xl">Pages you recently visited</h6>
                                    <div>
                                        {visitedPages.length == 0 ? <div className="mt-2 n-pages"><div className="no-details-text">No Visited Pages</div></div> :
                                            <div className="flex flex-col gap-1">
                                                {visitedPages.map((data, i) => (
                                                    <Link to={{
                                                        pathname: `/by/${data.companyName}/${data.productName}/${data.productTypeString}`
                                                    }} style={{ color: "black", textDecoration: "none" }}>
                                                        <div className="flex items-center gap-3 px-3 py-1 hover:bg-gray-100 rounded-lg">
                                                            <img src={data.productIcon} className="w-16 h-16 object-contain bg-white" />
                                                            <div className="">
                                                                <h1 className="text-lg font-medium">{data.productName}</h1>
                                                                <h1 className="text-sm text-gray-400">{data.companyName}</h1>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        {
                            isFollow
                                ?
                                <Col md={8}>
                                    <div className="flex items-center gap-2 cursor-pointer" onClick={() => setIsFollow(false)}>
                                        <svg width="100" height="23" viewBox="0 0 161 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M159.24 10.2153C160.069 10.2153 160.74 10.8869 160.74 11.7153C160.74 12.5438 160.069 13.2153 159.24 13.2153V10.2153ZM1.32094 12.776C0.735138 12.1902 0.735138 11.2405 1.32094 10.6547L10.8669 1.10873C11.4527 0.522943 12.4024 0.522943 12.9882 1.10873C13.574 1.69452 13.574 2.64426 12.9882 3.23005L4.50291 11.7153L12.9882 20.2006C13.574 20.7864 13.574 21.7361 12.9882 22.3219C12.4024 22.9077 11.4527 22.9077 10.8669 22.3219L1.32094 12.776ZM159.24 13.2153L2.38159 13.2153V10.2153L159.24 10.2153V13.2153Z" fill="black" />
                                        </svg>
                                        <span>View your posts</span>
                                    </div>
                                    <h1 className="text-2xl font-medium text-orange my-3">Following Pages</h1>
                                    <div className="flex flex-col gap-3 overflow-y-auto scrollbar-hide" style={{ height: '80dvh' }}>
                                        {
                                            followedProducts?.length
                                                ?
                                                followedProducts.map((product) => {
                                                    return (
                                                        <div className="flex items-end gap-2 bg-white px-3 pb-3 rounded-lg border-y-2 border-orange-500">
                                                            <div>
                                                                <img src={product.productIcon} className="w-28 h-28 rounded object-contain bg-white" alt="" />
                                                            </div>
                                                            <div className="flex-grow pl-2">
                                                                <h1 className="text-2xl font-medium">{product.productName} <span className="text-lg font-normal">{product.productTypeString}</span></h1>
                                                                <p className="text-sm">By {product.companyName}</p>
                                                                <p className="text-gray-400 max-w-sm text-sm">{product.productDescription}</p>
                                                            </div>
                                                            <div>
                                                                <div className="flex items-baseline gap-2 pl-3">
                                                                    <h1 className="text-xl font-medium">{product.followers}</h1>
                                                                    <h1 className="text-sm text-gray-400">Followers</h1>
                                                                </div>
                                                                <div className='btn-outer mt-2'>
                                                                    <div className='btn-1' style={{
                                                                        width: '120px',
                                                                        height: '32px'
                                                                    }}>
                                                                    </div>
                                                                    <Button variant="default" className="btn-2 group" style={{
                                                                        width: '120px',
                                                                        height: '32px',
                                                                        fontSize: '16px',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                    }}><span>Following</span></Button>
                                                                </div>

                                                            </div>
                                                            <div className='flex flex-col gap-1 items-end justify-end'>
                                                                <p className='text-2xl font-semibold'><span className='text-sm text-gray-500'>({product.numberOfRaters})</span>{Number(product.averageRating)}</p>
                                                                {!product.numberOfRaters ? <img src={noReviews} alt='' style={{ width: "40px", height: "40px" }} /> :
                                                                    <div className='flex'>
                                                                        {product.numberOfRaters > 0 && <RatingImages rating={Number(product.averageRating)} />}
                                                                    </div>
                                                                }
                                                                {/* <div style={{ backgroundColor: "#E6570E", marginTop: "1px", width: `${((Math.round(productDetails[i].averageRating * 10) / 10) / 5) * 100}%` }}> <img src={ratingImg} style={{ width: "140px" }} /></div> */}
                                                                <p className=' xl:text-base text-right text-sm font-semibold'>{ratingDescription(Number(product.averageRating))}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <>
                                                </>
                                        }
                                        <div className="p-10">

                                        </div>
                                    </div>

                                </Col>
                                :
                                <Col md={8}>
                                    <div>
                                        <div className="flex items-center justify-between pb-6 gap-6">

                                            <div className="flex items-center gap-8">
                                                <div onClick={ratings} className={`${activeType == "Rating" ? 'bg-orange text-white' : 'bg-white text-gray-800'} py-1.5 px-4 border-2 border-orange-500 rounded-md text-lg font-medium cursor-pointer`}>
                                                    <span>Ratings</span>
                                                </div>
                                                <div onClick={feedback} className={`${activeType == "Feedback" ? 'bg-orange text-white' : 'bg-white text-gray-800'} py-1.5 px-4 border-2 border-orange-500 rounded-md text-lg font-medium cursor-pointer`}>
                                                    <span>Feddbacks</span>
                                                </div>
                                                <div onClick={suggestion} className={`${activeType == "Suggestion" ? 'bg-orange text-white' : 'bg-white text-gray-800'} py-1.5 px-4 border-2 border-orange-500 rounded-md text-lg font-medium cursor-pointer`}>
                                                    <span>Suggestions</span>
                                                </div>
                                            </div>

                                            <div onClick={() => setActiveType('flagged')} className={`${activeType == 'flagged' ? 'bg-orange text-white' : 'bg-white text-gray-800'} py-1.5 px-4 border-2 border-orange-500 rounded-md text-lg font-medium cursor-pointer`}>
                                                <span>Flagged</span>
                                            </div>
                                        </div>
                                        {activeType == "Feedback" || flagActive == 'Feedback' ?
                                            <div className="mt-1">
                                                <div className="card-padding">
                                                    {activeType == 'Feedback' ? <div className="ml-1 w-full bg-transparent flex items-center gap-1" >
                                                        <div className={`${selectedValue == 0 ? "bg-[#FFB858] text-gray-900" : " text-gray-500"} px-3 py-2 rounded-full text-sm flex items-center gap-2 cursor-pointer`} onClick={() => onChangeFeedbackRecommendation(0)}><img src={top} style={{ width: "18px" }} className={`${selectedValue == 0 ? 'filter brightness-0' : ''}`} /><span>Newest</span></div>
                                                        <div className={`${selectedValue == 2 ? "bg-[#FFB858] text-gray-900" : " text-gray-500"} px-3 py-2 rounded-full text-sm flex items-center gap-2 cursor-pointer`} onClick={() => onChangeFeedbackRecommendation(2)}><img src={mostcommented} style={{ width: "18px" }} className={`${selectedValue == 2 ? 'filter brightness-0' : ''}`} /><span>Most Commented</span></div>
                                                        <div className={`${selectedValue == 3 ? "bg-[#FFB858] text-gray-900" : " text-gray-500"} px-3 py-2 rounded-full text-sm flex items-center gap-2 cursor-pointer`} onClick={() => onChangeFeedbackRecommendation(3)}><img src={mostshared} style={{ width: "18px" }} className={`${selectedValue == 3 ? 'filter brightness-0' : ''}`} /><span>Most Shared</span></div>
                                                        <div className={`${selectedValue == 1 ? "bg-[#FFB858] text-gray-900" : " text-gray-500"} px-3 py-2 rounded-full text-sm flex items-center gap-2 cursor-pointer`} onClick={() => onChangeFeedbackRecommendation(1)}><img src={oldest} style={{ width: "18px" }} className={`${selectedValue == 1 ? 'filter brightness-0' : ''}`} /><span>Oldest</span></div>
                                                    </div> :
                                                        flagActive == 'Feedback'
                                                            ?
                                                            <div className="ml-1 w-full bg-transparent flex items-center gap-1" >
                                                                <div className={`${flagActive == 'Rating' ? "bg-[#FFB858] text-gray-900" : " text-gray-500"} px-4 py-2 rounded-full flex items-center gap-2 cursor-pointer`} onClick={() => setFlagActive('Rating')}><span>Rating</span></div>
                                                                <div className={`${flagActive == 'Feedback' ? "bg-[#FFB858] text-gray-900" : " text-gray-500"} px-4 py-2 rounded-full flex items-center gap-2 cursor-pointer`} onClick={() => setFlagActive('Feedback')}><span>Feedback</span></div>
                                                                <div className={`${flagActive == 'Suggestion' ? "bg-[#FFB858] text-gray-900" : " text-gray-500"} px-4 py-2 rounded-full flex items-center gap-2 cursor-pointer`} onClick={() => setFlagActive('Suggestion')}><span>Suggestion</span></div>
                                                            </div>
                                                            :
                                                            ''
                                                    }
                                                    {userFeedbacks?.filter(item => activeType == 'flagged' ? item.delete || item?.flag : !item.delete && !item?.flag).length == 0 ? <Card className="mt-2 no-pages"><center className="no-details-text"><img src={downvoteb} className="no-pages-img" /><br />No Feddbacks</center></Card> :
                                                        <div className="mt-2 feddback-profile">
                                                            {userFeedbacks?.filter(item => activeType == 'flagged' ? item.delete || item?.flag : !item.delete || !item?.flag).map((data, i) => (
                                                                <div className="mt-2" key={i}>
                                                                    <Card className=" rounded-[10px] border-0 mb-8">
                                                                        <div className="row">
                                                                            <Col md={2}>
                                                                                <Card className="ml-2 mt-1 mb-2 py-4 vote-bg border-0 rounded-[10px]" style={{ height: "96%" }}>
                                                                                    <center className={localStorage.getItem('junkStore') ? "cursor" : ""}> {data.vote == 1 ? <img src={upvote} onClick={(e, i) => changeUpvoteFeedback(data, 2)} style={{ width: "55px" }} /> : <img src={upvoteb} onClick={(e) => changeUpvoteFeedback(data, 1)} style={{ width: "45px" }} />}</center><br />
                                                                                    <center className="likes font-medium">{data.totallike == null ? 0 : data.totallike}</center><br />
                                                                                    <center className={localStorage.getItem('junkStore') ? "cursor" : ""}> {data.vote == -1 ? <img src={downvote} onClick={(e, i) => changeDownvoteFeedback(data, 2)} style={{ width: "55px" }} /> : <img src={downvoteb} onClick={(e) => changeDownvoteFeedback(data, 0)} style={{ width: "45px" }} />}</center>
                                                                                </Card>
                                                                            </Col>
                                                                            <Col md={10}>
                                                                                <div className="ml-1 my-3">
                                                                                    <div className="card-padding">
                                                                                        <div className={`${data.userCreate ? "" : "hidden"}`}>
                                                                                            {data.userCreate ?
                                                                                                <Dropdown align="end">
                                                                                                    <Dropdown.Toggle variant="default" style={{ float: "right" }} className="abc feed-card-a -mt-2 h-7">
                                                                                                        <img src={dots} style={{ width: "3px" }} />
                                                                                                    </Dropdown.Toggle>
                                                                                                    <Dropdown.Menu className="border-none shadow-md">
                                                                                                        <Dropdown.Item className="d-text" onClick={(e, i) => editFeedback(data)} ><span className='flex gap-2'><img src={ImgEdit} style={{ width: "20px" }} /> &nbsp;Edit</span></Dropdown.Item>
                                                                                                        <Dropdown.Item className="d-text" onClick={(e, i) => deleteFeedback(data)} style={{ color: "red" }}><span className='flex gap-2'><img src={imgDel} style={{ width: "20px" }} /> &nbsp;Delete</span></Dropdown.Item>
                                                                                                    </Dropdown.Menu>
                                                                                                </Dropdown> : null}
                                                                                        </div>
                                                                                        <div className={localStorage.getItem('junkStore') ? "cursor" : ""}>
                                                                                            <div className="flex items-center gap-2 text-sm" onClick={() => history.push(`/by/${data.companyName}/${data.productName}/${data.productTypeString}`, { id: data.id })}>
                                                                                                <img src={data.productIcon} className="w-8 h-8 object-contain" alt="" />
                                                                                                <h1 className="font-medium">{data.productName} <span className="font-normal text-gray-500">({data.productTypeString})</span></h1>
                                                                                            </div>
                                                                                            <div onClick={() => togglePopup(data)}>

                                                                                                <div className="flex gap-1 mb-2 cursor-pointer">
                                                                                                    <span className="text-orange-600 font-medium text-lg">{data.title}</span>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <div className="posted mb-2">Posted by {data.userName == null ? "anonymous" : data.userName} &nbsp;&nbsp;{data.updatedAt.split("T")[0].split("-").reverse().join("-")}</div>
                                                                                                    <div className="mb-2" dangerouslySetInnerHTML={{
                                                                                                        __html: data.feedback
                                                                                                    }} />
                                                                                                    {
                                                                                                        data?.tag?.length
                                                                                                            ?
                                                                                                            <div className='flex flex-wrap gap-x-3 gap-y-3 py-3'>
                                                                                                                {data?.tag?.map(tag => <Tag text={tag} />
                                                                                                                )}
                                                                                                            </div>
                                                                                                            : ''
                                                                                                    }
                                                                                                    <div className="my-2"><img src={data.feedbackImage} style={{ width: "150px" }} /></div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className={localStorage.getItem('junkStore') ? "cursor flex py-4" : "flex py-4"}>
                                                                                            <div className="flex items-center gap-x-2.5">
                                                                                                <img src={comment} style={{ width: "20px" }} className={localStorage.getItem('junkStore') ? "cursor" : ""} />
                                                                                                <span className="comment" >{data.commentCount == null ? 0 : data.commentCount} Comments</span>
                                                                                            </div>
                                                                                            <ShareBox shareCount={data.shareCount} path={`/${data.companyName}/${data.productName}/${data.productTypeString}`} type={"Feedback"} postId={data.id} callback={() => { }} />
                                                                                        </div>
                                                                                        {
                                                                                            activeType == 'flagged'
                                                                                                ?
                                                                                                data.delete ?
                                                                                                    <div className=" pb-3 relative">
                                                                                                        <div className="h-0.5 w-full bg-orange"></div>
                                                                                                        <h1 className="mt-3 font-medium text-lg">Delete Note from Admin</h1>
                                                                                                        <p className="text-gray-500">{data?.reason || 'Reason not mentioned'}</p>
                                                                                                        <div className="flex gap-1 items-start text-xs absolute top-2 right-3">
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                                                            </svg>
                                                                                                            <div className="min-w-max">
                                                                                                                <p>Post will be removed in</p>
                                                                                                                {data.deletedOn && <p className="text-center">{moment(moment(data.deletedOn).add(2, 'days')).fromNow(true)}</p>}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    data.flag ?
                                                                                                        <div className=" pb-3">
                                                                                                            <div className="h-0.5 w-full bg-orange"></div>
                                                                                                            <h1 className="mt-3 font-medium text-lg">Flag Note from Admin</h1>
                                                                                                            <p className="text-gray-500">{data?.note || data?.reason || 'Reason not mentioned'}</p>
                                                                                                        </div>
                                                                                                        :
                                                                                                        <></>
                                                                                                :
                                                                                                <></>
                                                                                        }

                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </div>
                                                                    </Card>
                                                                </div>
                                                            )
                                                            )}
                                                        </div>
                                                    }
                                                </div>

                                            </div> : (activeType == "Suggestion" || flagActive == 'Suggestion') ?
                                                <div>
                                                    <div className="card-padding">
                                                        {
                                                            activeType == 'Suggestion'
                                                                ?
                                                                <div className="ml-1 w-full bg-transparent flex items-center gap-1" >
                                                                    <div className={`${selectedValue == 0 ? "bg-[#FFB858] text-gray-900" : " text-gray-500"} px-3 py-2 text-sm rounded-full flex items-center gap-2 cursor-pointer`} onClick={() => onChangeSuggestionRecommendation(0)}><img src={top} style={{ width: "18px" }} className={`${selectedValue == 0 ? 'filter brightness-0' : ''}`} /><span>Newest</span></div>
                                                                    <div className={`${selectedValue == 2 ? "bg-[#FFB858] text-gray-900" : " text-gray-500"} px-3 py-2 text-sm rounded-full flex items-center gap-2 cursor-pointer`} onClick={() => onChangeSuggestionRecommendation(2)}><img src={mostcommented} style={{ width: "18px" }} className={`${selectedValue == 2 ? 'filter brightness-0' : ''}`} /><span>Most Commented</span></div>
                                                                    <div className={`${selectedValue == 3 ? "bg-[#FFB858] text-gray-900" : " text-gray-500"} px-3 py-2 text-sm rounded-full flex items-center gap-2 cursor-pointer`} onClick={() => onChangeSuggestionRecommendation(3)}><img src={mostshared} style={{ width: "18px" }} className={`${selectedValue == 3 ? 'filter brightness-0' : ''}`} /><span>Most Shared</span></div>
                                                                    <div className={`${selectedValue == 1 ? "bg-[#FFB858] text-gray-900" : " text-gray-500"} px-3 py-2 text-sm rounded-full flex items-center gap-2 cursor-pointer`} onClick={() => onChangeSuggestionRecommendation(1)}><img src={oldest} style={{ width: "18px" }} className={`${selectedValue == 1 ? 'filter brightness-0' : ''}`} /><span>Oldest</span></div>
                                                                    <Dropdown onSelect={onSelect}>
                                                                        <Dropdown.Toggle variant="default" className={`${[4, 5, 6, 7].includes(selectedValue) ? "bg-[#FFB858] text-gray-900" : " text-gray-500"} rounded-full`}>
                                                                            <div>{selectedValue == 6 ? "Rejected" : selectedValue == 5 ? "Accepted" : selectedValue == 4 ? "Indiscussion" : "All"}&nbsp; &nbsp;<b><i class="fa fa-angle-down"></i></b></div>
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu className="border-none  shadow-md">
                                                                            <Dropdown.Item eventKey={7} className="text-gray-500">All</Dropdown.Item>
                                                                            <Dropdown.Item eventKey={4} className="text-gray-500">Indiscussion</Dropdown.Item>
                                                                            <Dropdown.Item eventKey={5} className="text-gray-500">Accepted</Dropdown.Item>
                                                                            <Dropdown.Item eventKey={6} className="text-gray-500">Rejected</Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                                :
                                                                flagActive == 'Suggestion'
                                                                    ?
                                                                    <div className="ml-1 w-full bg-transparent flex items-center gap-1" >
                                                                        <div className={`${flagActive == 'Rating' ? "bg-[#FFB858] text-gray-900" : " text-gray-500"} px-4 py-2 rounded-full flex items-center gap-2 cursor-pointer`} onClick={() => setFlagActive('Rating')}><span>Rating</span></div>
                                                                        <div className={`${flagActive == 'Feedback' ? "bg-[#FFB858] text-gray-900" : " text-gray-500"} px-4 py-2 rounded-full flex items-center gap-2 cursor-pointer`} onClick={() => setFlagActive('Feedback')}><span>Feedback</span></div>
                                                                        <div className={`${flagActive == 'Suggestion' ? "bg-[#FFB858] text-gray-900" : " text-gray-500"} px-4 py-2 rounded-full flex items-center gap-2 cursor-pointer`} onClick={() => setFlagActive('Suggestion')}><span>Suggestion</span></div>
                                                                    </div>
                                                                    :
                                                                    ''
                                                        }


                                                        {userSuggestions?.filter(item => activeType == 'flagged' ? item.delete || item?.flag : !item.delete && !item?.flag)?.length == 0 ? <Card className="mt-2 no-pages"><center className="no-details-text"><img src={downvoteb} className="no-pages-img" /><br />No Suggestions</center></Card> :
                                                            <div className="mt-2 feddback-profile">
                                                                {userSuggestions?.filter(item => activeType == 'flagged' ? item.delete || item?.flag : !item.delete || !item?.flag)?.map((data, i) => (
                                                                    <div className="mt-2" key={i}>
                                                                        <Card className={data.status == 4 ? "suggestion-border-accepted mt-4" : (data.status == 2 ? "suggestion-border-pending mt-4" : (data.status == 3 ? "suggestion-border-rejected mt-4" : "suggestion-border mt-4"))}>
                                                                            <div className="suggestion-img-container">
                                                                                <img src={data.status == 4 ? suggestionAccepted : (data.status == 2 ? suggestionInprogress : (data.status == 3 ? suggestionRejected : null))} alt="" style={{ width: "40px" }} />
                                                                            </div>
                                                                            <div className="row">
                                                                                <Col md={2}>
                                                                                    <Card className="ml-2 mt-1 mb-2 vote-bg rounded-[10px] border-0 py-4" style={{ height: "96%" }}>
                                                                                        <center className={localStorage.getItem('junkStore') ? "cursor" : ""}> {data.vote == 1 ? <img src={upvote} onClick={(e, i) => changeUpvoteSuggestion(data, 2)} style={{ width: "55px" }} /> : <img src={upvoteb} onClick={(e, i) => changeUpvoteSuggestion(data, 1)} style={{ width: "45px" }} />}</center><br />
                                                                                        <center className="likes">{data.totallike == null ? 0 : data.totallike}</center><br />
                                                                                        <center className={localStorage.getItem('junkStore') ? "cursor" : ""}> {data.vote == -1 ? <img src={downvote} onClick={(e, i) => changeDownvoteSuggestion(data, 2)} style={{ width: "55px" }} /> : <img src={downvoteb} onClick={(e, i) => changeDownvoteSuggestion(data, 0)} style={{ width: "45px" }} />}</center>
                                                                                    </Card>
                                                                                </Col>
                                                                                <Col md={10}>
                                                                                    <div className="mt-2 mb-2">
                                                                                        <div className="card-padding">
                                                                                            <div >
                                                                                                {(data.userCreate) ?
                                                                                                    <Dropdown align="end">
                                                                                                        <Dropdown.Toggle variant="default" style={{ float: "right" }} className="abc feed-card-a  h-7">
                                                                                                            <img src={dots} style={{ width: "3px" }} />
                                                                                                        </Dropdown.Toggle>

                                                                                                        <Dropdown.Menu className="border-none shadow-md">
                                                                                                            <Dropdown.Item className="d-text" onClick={(e) => editSuggestion(data)} ><span className='flex gap-2'><img src={ImgEdit} style={{ width: "20px" }} /> &nbsp;Edit</span></Dropdown.Item>
                                                                                                            <Dropdown.Item className="d-text" onClick={(e) => deleteSuggestion(data)} style={{ color: "red" }}><span className='flex gap-2'><img src={imgDel} style={{ width: "20px" }} /> &nbsp;Delete</span></Dropdown.Item>
                                                                                                        </Dropdown.Menu>
                                                                                                    </Dropdown> : null}
                                                                                            </div>
                                                                                            <div className={localStorage.getItem('junkStore') ? "cursor" : ""}>

                                                                                                <div className="flex items-center gap-2 text-sm" onClick={() => history.push(`/by/${data.companyName}/${data.productName}/${data.productTypeString}`, { id: data.id })}>
                                                                                                    <img src={data.productIcon} className="w-8 h-8 object-contain" alt="" />
                                                                                                    <h1 className="font-medium">{data.productName} <span className="font-normal text-gray-500">({data.productTypeString})</span></h1>
                                                                                                </div>
                                                                                                <div onClick={() => togglePopupSuggestion(data)}>
                                                                                                    <div className="flex gap-1 mb-2 cursor-pointer">
                                                                                                        <span className="text-orange-600 font-medium text-lg">{data.title}</span>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <div className="posted mb-2">Posted by {data.userName == null ? "anonymous" : data.userName} &nbsp;&nbsp;{data.createdAt.split("T")[0].split("-").reverse().join("-")}</div>
                                                                                                        <div className="mb-2" dangerouslySetInnerHTML={{
                                                                                                            __html: data.suggestion
                                                                                                        }} />
                                                                                                        {
                                                                                                            data?.tag?.length
                                                                                                                ?
                                                                                                                <div className='flex flex-wrap gap-x-3 gap-y-3 py-3'>
                                                                                                                    {data?.tag?.map(tag => <Tag text={tag} />
                                                                                                                    )}
                                                                                                                </div>
                                                                                                                : ''
                                                                                                        }
                                                                                                        <div className="my-2"><img src={data.suggestionImage} style={{ width: "150px" }} /></div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className={localStorage.getItem('junkStore') ? "cursor flex py-4" : "flex py-4"}>
                                                                                                <div className="flex items-center">
                                                                                                    <img src={comment} style={{ width: "20px" }} className={localStorage.getItem('junkStore') ? "cursor" : ""} />
                                                                                                    <span className="comment" >{data.commentCount == null ? 0 : data.commentCount} Comments</span>
                                                                                                </div>

                                                                                                <ShareBox shareCount={data.shareCount} path={`/${data.companyName}/${data.productName}/${data.productTypeString}`} type={"Suggestion"} postId={data.id} callback={() => { }} />

                                                                                            </div>
                                                                                            {
                                                                                                activeType == 'flagged'
                                                                                                    ?
                                                                                                    data.delete ?
                                                                                                        <div className=" pb-3 relative">
                                                                                                            <div className="h-0.5 w-full bg-orange"></div>
                                                                                                            <h1 className="mt-3 font-medium text-lg">Delete Note from Admin</h1>
                                                                                                            <p className="text-gray-500">{data?.reason || 'Reason not mentioned'}</p>
                                                                                                            <div className="flex gap-1 items-start text-xs absolute top-2 right-3">
                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                                                                </svg>
                                                                                                                <div className="min-w-max">
                                                                                                                    <p>Post will be removed in</p>
                                                                                                                    {data.deletedOn && <p className="text-center">{moment(moment(data.deletedOn).add(2, 'days')).fromNow(true)}</p>}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        :
                                                                                                        data.flag ?
                                                                                                            <div className=" pb-3">
                                                                                                                <div className="h-0.5 w-full bg-orange"></div>
                                                                                                                <h1 className="mt-3 font-medium text-lg">Flag Note from Admin</h1>
                                                                                                                <p className="text-gray-500">{data?.note || data?.reason || 'Reason not mentioned'}</p>
                                                                                                            </div>
                                                                                                            :
                                                                                                            <></>
                                                                                                    :
                                                                                                    <></>
                                                                                            }

                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </div>
                                                                        </Card>
                                                                    </div>
                                                                )
                                                                )}
                                                            </div>
                                                        }
                                                    </div>
                                                </div> :
                                                activeType == 'Rating' || flagActive == 'Rating'
                                                    ?
                                                    <div className="px-2.5 py-1">
                                                        {flagActive == 'Rating'
                                                            ?
                                                            <div className="ml-1 w-full bg-transparent flex items-center gap-1" >
                                                                <div className={`${flagActive == 'Rating' ? "bg-[#FFB858] text-gray-900" : " text-gray-500"} px-4 py-2 rounded-full flex items-center gap-2 cursor-pointer`} onClick={() => setFlagActive('Rating')}><span>Rating</span></div>
                                                                <div className={`${flagActive == 'Feedback' ? "bg-[#FFB858] text-gray-900" : " text-gray-500"} px-4 py-2 rounded-full flex items-center gap-2 cursor-pointer`} onClick={() => setFlagActive('Feedback')}><span>Feedback</span></div>
                                                                <div className={`${flagActive == 'Suggestion' ? "bg-[#FFB858] text-gray-900" : " text-gray-500"} px-4 py-2 rounded-full flex items-center gap-2 cursor-pointer`} onClick={() => setFlagActive('Suggestion')}><span>Suggestion</span></div>
                                                            </div>
                                                            :
                                                            ''}
                                                        {userRatings?.filter(item => activeType == 'flagged' ? item?.report : !item?.report)?.length == 0 ? <Card className="no-pages mt-3"><center className="no-details-text"><img src={downvoteb} className="no-pages-img" /><br />No Ratings</center></Card> :
                                                            <div className=" feddback-profile-ratings flex flex-col gap-4 mt-3">
                                                                {userRatings?.filter(item => activeType == 'flagged' ? item?.report : !item?.report)?.map((data, i) => (
                                                                    !data.comment?.trim() ? <></> :
                                                                        <div md={12} className="bg-white rounded-2xl review-card relative">
                                                                            <div className="flex items-center justify-end gap-2 text-sm px-3 transform translate-y-3">
                                                                                <img src={data.productIcon} className="w-8 h-8 object-contain" alt="" />
                                                                                <h1 className="font-medium">{data.productName} <span className="font-normal text-gray-500">({data.productTypeString})</span></h1>
                                                                            </div>
                                                                            <div className="card-padding flex gap-2 justify-center items-center w-full ">
                                                                                <div className="flex flex-col justify-center items-center pl-2 transform translate-y-2">
                                                                                    <img src={data.rating === 5 ? orating5 : data.rating === 4 ? orating4 : data.rating === 3 ? orating3 : data.rating === 2 ? orating2 : orating1} style={{ width: "60px" }} />
                                                                                    <p className="min-w-max font-semibold text-sm text-center">{userRatingDesc(data.rating)}</p>
                                                                                </div>
                                                                                <div className="flex-grow w-full text-center pr-20">
                                                                                    <p className="c-rating productName font-medium">{data.comment}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex flex-col justify-end items-end transform -translate-y-3 pr-3">
                                                                                <div className="font-medium text-sm italic">-{data.userName == null ? "anonymous" : data.userName}</div>
                                                                                <div style={{ fontWeight: "400", fontSize: "10px" }} className='text-gray-500'>Posted on {moment(data.updatedAt, "YYYY-MM-DD HH:mm:ss.SSSZ").format("DD/MM/YY")}</div>
                                                                            </div>
                                                                        </div>
                                                                )
                                                                )}
                                                            </div>
                                                        }
                                                    </div>
                                                    :
                                                    ''
                                        }
                                    </div>
                                </Col>
                        }
                    </Row>
                </div >

                <br />
            </div >
        </div >
    );
}


import { clsx } from 'clsx';
import { useState } from 'react';
import Select from 'react-select';

const controlStyles = {
    base: "border-2 border-slate-300 hover:cursor-pointer px-2 rounded-md",
    focus: "",
    nonFocus: "",
};
const placeholderStyles = "text-gray-500";
const selectInputStyles = "";
const valueContainerStyles = "";
const singleValueStyles = "leading-7 ml-1";
const indicatorsContainerStyles = "p-1 gap-1";
const clearIndicatorStyles =
    "text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800";
const menuStyles = "p-1 mt-2 shadow-  bg-white rounded-lg";
const groupHeadingStyles = "ml-3 mt-2 mb-1 text-gray-500 text-sm";
const optionStyles = {
    base: "w-32 hover:cursor-pointer hover:bg-red-400 pb-2 pl-2",
    focus: "active:bg-red-400",
    selected: "after:content-['✔'] after:ml-2 after:text-green-500 text-gray-500",
};
const noOptionsMessageStyles =
    "text-gray-500 p-2 bg-gray-50 ";


const ReactSelect2 = (props) => {
    const [value, setValue] = useState("")

    return (
        <Select
            isSearchable={false}
            defaultValue={props.defaultValue}
            value={props.value}
            className="w-64"
            placeholder={props.placeholder}
            options={props.options}
            hideSelectedOptions={false}
            unstyled
            isDisabled={props.isDisabled}
            onChange={(option) => {
                props.handleChange(option, props.id)
            }}
            styles={{
                input: (base) => ({
                    ...base,
                    "input:focus": {
                        boxShadow: "none",
                    },
                }),
                control: (base) => ({
                    ...base,
                    transition: "none",
                }),
            }}
            components={{
                DropdownIndicator: () => <span><svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.7625 0.441406L7.5 6.01448L13.2375 0.441406L15 2.15713L7.5 9.4581L0 2.15713L1.7625 0.441406Z" fill="black" fillOpacity="0.54" />
                </svg>
                </span>, IndicatorSeparator: () => null
            }}
            classNames={{
                control: ({ isFocused }) =>
                    clsx(
                        isFocused ? controlStyles.focus : controlStyles.nonFocus,
                        controlStyles.base,
                    ),
                placeholder: () => placeholderStyles,
                input: () => selectInputStyles,
                valueContainer: () => valueContainerStyles,
                singleValue: () => singleValueStyles,
                indicatorsContainer: () => indicatorsContainerStyles,
                clearIndicator: () => clearIndicatorStyles,
                menu: () => menuStyles,
                groupHeading: () => groupHeadingStyles,
                option: ({ isFocused, isSelected }) =>
                    clsx(
                        isFocused && optionStyles.focus,
                        isSelected && optionStyles.selected,
                        optionStyles.base,
                    ),
                noOptionsMessage: () => noOptionsMessageStyles,
            }}
            {...props}
        />
    )
}
export default ReactSelect2
import React from 'react';
import '../CompanyFeed/CompanyFeed.css';
import { Row, Col, Modal, Form, FormControl, Card, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import upvoteb from '../images/upvote-b.svg';
import downvoteb from '../images/downvote-b.svg';
import upvote from '../images/upvote.svg';
import downvote from '../images/downvote.svg';
import comment from '../images/comments.svg';
import share from '../images/share.svg';
import lock from '../images/lock.png';
import orangehide from '../images/hide-orange.png';
import deletei from "../images/delete-i.svg";
import axios from 'axios';
import { config } from '../Config/Config';

class AnnouncementSuccessPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            successPost: true
        }
    }
    componentDidMount() {
        console.log("props", this.props)
    }

    render() {
        const { successPost } = this.state
        return (
            <Modal
                size="sm"
                show={successPost}
                onHide={this.props.closePopup}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <center><span className="pin-pop-text">Announcement Created Successfully</span><br /></center>

                </Modal.Body>
            </Modal>
        );
    }
}

export default AnnouncementSuccessPopup;


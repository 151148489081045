import React, { useRef, useState, useEffect } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { Button, Form, Modal, Dropdown, Card, Row, Col, OverlayTrigger, Tooltip, Popover } from "react-bootstrap";
import fillTag from "../images/fill-tag.svg";
import emptyTag from "../images/empty-tag.svg";
import { FileUploader } from "react-drag-drop-files";
import deleteIcon from "../images/delete-image.svg";
import uploaded from "../images/uploaded-tick.svg";
import announcementTag from "../images/announcement-popup.svg";
import useDebounce, { decrypt } from "../utils";
import Tag from "../utils/Tag";

function NewVersion({ toggleNewVersion, productId, companyId, initialTags, onSuccess }) {
    const [section, setSection] = useState("version");
    const [announcementTitle, setAnnouncementTitle] = useState("");
    const [announcementDescription, setAnnouncementDescription] = useState("");
    const [images, setImages] = useState([])
    const [defaultTags, setDefaultTags] = useState(initialTags || [])
    const [selectedTags, setSelectedTags] = useState([])
    const [announcementPost, setAnnouncementPost] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [version, setVersion] = useState('')
    const [feedbackForms, setFeedbackForms] = useState([]);
    const [selectedForm, setSelectedForm] = useState("");
    const [formNameError, setFormNameError] = useState('');
    const [isExist, setIsExist] = useState(false)

    useEffect(() => {
        // getDefaultTags();
        getAllFeedbackForms();
    }, [])

    const debounced = useDebounce(version, 400)

    useEffect(() => {
        if (debounced) {
            validateVersion()
        }
    }, [debounced])

    const getDefaultTags = () => {
        var headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getDefaultTag', { headers: headers })
            .then(function (response) {
                console.log("getDefaultTags", response);
                if (response.data.status === true) {
                    setDefaultTags(response.data.getDefaultTag)
                    console.log("tags", response.data.getDefaultTag)
                } else {
                    console.log("error");
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const getAllFeedbackForms = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "productId": productId,
        }
        axios.post(config.userUrl + "admin/getAllProductForms", payload, { headers: headers })
            .then((response) => {
                return Promise.all(
                    response.data.productForms.map(product => {
                        var payload = {
                            "formId": product.formId,
                            "productId": productId,
                        }
                        return axios.post(config.userUrl + "admin/getFeedbackForm", payload, { headers: headers })
                    })
                );
            })
            .then(responses => {
                let currentForms = (responses.map(response => response.data.feedbackForms));
                setFeedbackForms(currentForms);
            })
            .catch((err) => {
                console.log(err)
            });
    }
    const postAnnouncement = async (versionId) => {
        setLoading(true);
        const requests = images.map((image) => {
            console.log(image[0]);
            return uploadImage(image[0])
        });
        const imageUrls = await Promise.all(requests);

        var headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let tagsData = [];
        tagsData = defaultTags.filter(item => (item.isSelected === true) ? item.tag : null)
        console.log("newTags", defaultTags, tagsData)
        var payload = {
            "productId": productId,
            "companyId": companyId,
            "title": announcementTitle,
            "announcement": announcementDescription,
            "announcementImages": imageUrls,
            "tag": tagsData.map(item => item.tag),
            "versionId": versionId
        }
        console.log("payload", payload);
        axios.post(config.userUrl + 'admin/createProductAnnouncementByAdmin', payload, { headers: headers })
            .then(function (response) {
                console.log("createProductAnnouncementByUser", response);
                if (response.data.status === true) {
                    console.log("success", response.data.announcement.id);
                    setShowSuccess(true);
                    onSuccess();
                    setTimeout(() => {
                        toggleNewVersion();
                        setShowSuccess(false);
                    }, 3000)
                } else {
                    console.log("error", payload);
                }
            })
            .catch(function (error) {
                console.log("Error", error);
                setLoading(false)
            });
    };
    const deleteImage = (i) => {
        let cur_images = images.filter((image, index) => { if (index !== i) return image })
        console.log(cur_images);
        setImages(cur_images);
    }
    const uploadImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "dpx0yz1k8")
        data.append("cloud_name", "greyffiti")
        const resp = await fetch(`https://api.cloudinary.com/v1_1/greyffiti/image/upload`, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        return res.secure_url
    }
    const handleTags = (e, tag) => {
        const updated = defaultTags.map(data => (data.tag === tag) ?
            { ...data, isSelected: !data.isSelected } : data)
        setDefaultTags(updated)
        setSelectedTags(updated)
    }

    const createVersion = async (e, postAnnouncementFlag) => {
        setLoading(true);
        var headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "productId": productId,
            "version": version,
            "formId": selectedForm,
        }
        axios.post(config.userUrl + 'admin/createNewVersion', payload, { headers: headers })
            .then(function (response) {
                console.log("createProductAnnouncementByUser", response);
                if (response.data.status === true) {
                    console.log("success new version", response.data);
                    if (postAnnouncementFlag) {
                        postAnnouncement(response.data.newVersion.id);
                    } else {
                        setShowSuccess(true);
                        onSuccess();
                        setTimeout(() => {
                            toggleNewVersion();
                            setShowSuccess(false);
                        }, 3000)
                    }
                } else {
                    console.log("error", payload);
                }
                setLoading(false)
            })
            .catch(function (error) {
                console.log("Error", error);
                setLoading(false)
            });

    }
    const validateVersion = async () => {
        var headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "productId": productId,
            "version": version,
        }
        axios.post(config.userUrl + 'admin/validateVersion', payload, { headers: headers })
            .then(function (response) {
                if (response.data.data) {
                    setIsExist(true)
                }
                else {
                    setIsExist(false)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });

    }
    const handleNextClick = () => {
        if (!version) {
            setFormNameError("Version Number should not be empty")
            return;
        }
        setSection('announcement')
    }

    return (
        <div>

            {showSuccess ?
                <Modal show={showSuccess} onHide={() => setShowSuccess(false)} size="md" centered >
                    <Modal.Body>
                        <div className='flex flex-col gap-4 justify-center items-center'>
                            <img src={uploaded} alt='' />
                            <p className='text-3xl font-semibold text-orange-600'>Version Added Successfully</p>
                        </div>
                    </Modal.Body>
                </Modal> :
                <Modal show={true} onHide={toggleNewVersion} size="xl" dialogClassName='' centered>
                    <Modal.Title>
                        <p className='text-2xl m-2 font-semibold  underline text-orange-400 underline-offset-8 p-3'><span className='text-black'>Create New Version</span></p>
                    </Modal.Title>
                    <Modal.Body>
                        <div className="flex flex-col items-center min-h-[70dvh]">
                            {section === "version" ?
                                <div className="w-full max-w-2xl">
                                    <div className="">
                                        <div className="mb-6 -mt-4">
                                            <ul className="progressbar relative">
                                                <div className="h-0.5 w-2/3 lg:w-full max-w-md bg-gray-200 absolute left-1/2 transform -translate-x-1/2 top-4"></div>
                                                <li className="active" style={{ color: "black" }}>Version Info</li>
                                                <li style={{ color: "black" }}>Announcement</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='flex flex-col mt-12 max-w-lg mx-auto'>
                                        <label className="font-medium text-lg mb-0">Version Name</label>
                                        <input type="text" className="form-control border placeholder-gray-500 mt-1 text-gray-800 focus:text-gray-800 w-full focus:ring-0" aria-describedby="text"
                                            value={version}
                                            name="version"
                                            placeholder="Enter version"
                                            onFocus={() => setFormNameError("")}
                                            onChange={(e) => { setIsExist(false); setVersion(e.target.value) }}
                                        />
                                        <div className="div">{formNameError && (
                                            <div className="error mb-4">{formNameError}</div>
                                        )}</div>
                                        <div className="div h-4">{isExist && (
                                            <div className="error mb-4">{"Version Already Exist"}</div>
                                        )}</div>
                                        <label className="font-medium text-lg mb-0 mt-3">Choose Form</label>
                                        <div className="flex border p-2 text-xl text-center rounded-lg bg-white w-full text-gray-800 mt-1">
                                            <select className="font-normal focus:outline-none w-[29rem] cursor-pointer px-2"
                                                value={selectedForm}
                                                onChange={(e) => setSelectedForm(e.target.value)}
                                            >
                                                <option value="" disabled selected hidden>Choose from created forms</option>
                                                {feedbackForms && feedbackForms.map((form, i) => {
                                                    return (
                                                        <option value={form.id} key={i}>{form.formName}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='btn-outer ml-auto mt-32 mr-10'>
                                        <div className='btn-1' style={{ width: "120px", height: "30px" }}></div>
                                        <Button
                                            variant="default"
                                            type="submit"
                                            className="btn-2"
                                            disabled={isExist}
                                            style={{ position: "absolute", fontSize: "16px", width: "120px", height: "30px", lineHeight: "10px" }}
                                            onClick={handleNextClick}
                                        >Next Step
                                        </Button>
                                    </div>
                                </div> :
                                <div className="w-full max-w-2xl">
                                    <div className="mb-6 -mt-4">
                                        <ul className="progressbar relative">
                                            <div className="h-0.5 w-2/3 lg:w-full max-w-md bg-[#E6570E] absolute left-1/2 transform -translate-x-1/2 top-4"></div>
                                            <li className="active" style={{ color: "black" }}>Version Info</li>
                                            <li className="active" style={{ color: "black" }}>Announcement</li>
                                        </ul>
                                    </div>
                                    <Row style={{ width: "100%", position: 'relative' }} className="">
                                        <div className="flex justify-end absolute -top-12 -left-12">
                                            <img src={announcementTag} alt="" className="w-16" />
                                        </div>
                                        <Col md={12} >
                                            <form>
                                                <Card >
                                                    <Form.Control
                                                        className="mb-2 border placeholder-gray-500 text-black focus:text-black focus:ring-0"
                                                        style={{ fontFamily: "poppins" }}
                                                        type="text"
                                                        name="announcementTitle"
                                                        value={announcementTitle}
                                                        placeholder="Announcement Title"
                                                        onChange={(e) => setAnnouncementTitle(e.target.value)} />
                                                </Card>
                                                <Card className="border">
                                                    <textarea
                                                        className="form-control placeholder-gray-500 text-black focus:text-black focus:ring-0 border-none"
                                                        id="exampleFormControlTextarea1"
                                                        style={{ fontFamily: "poppins", resize: "none" }}
                                                        rows="4"
                                                        maxLength={280}
                                                        value={announcementDescription}
                                                        name="announcementDescription"
                                                        onChange={(e) => setAnnouncementDescription(e.target.value)}
                                                        placeholder="Announcement Description"
                                                    />
                                                    {/* <Editor onChange={onChange} /> */}
                                                </Card>
                                                <div className="mb-2" style={{ fontFamily: "poppins", marginLeft: "2%", fontSize: "12px" }}>{announcementDescription.length}/280 characters</div>
                                                {/* <div className="mt-3"> <AddImage onChangeImage={onChangeImage} /></div><br /> */}
                                                <FileUploader
                                                    multiple={true}
                                                    onDrop={(e) => setImages([...images, e])} // function to get uploaded files
                                                    onSelect={(e) => setImages([...images, e])}
                                                    onDelete={deleteImage}
                                                    name="file"
                                                    classes="drag-drop-announcement mx-auto"
                                                    children={images && images.length === 0 ?
                                                        <div className="mb-4">
                                                            <div className="b-image h-auto py-4 border rounded text-center"><span className="text-black text-base">Choose a file or drag and drop here</span><br /><span className="text-black text-sm">Upload Upto 6 files</span></div>
                                                            <div style={{ fontSize: "12px", color: "grey", float: "right" }}>Only JPG's,PNG's,JPEG's are allowed</div>
                                                        </div> :
                                                        <></>
                                                    }
                                                    types={["JPG", "PNG", "JPEG"]}
                                                />
                                                <div className={`flex gap-2 h-auto p-4 mb-4 border rounded text-center relative ${images.length === 0 ? "hidden" : ""}`}>
                                                    {images.map((image, i) => {
                                                        return (
                                                            <div className="flex" key={i}>
                                                                <img src={URL.createObjectURL(image[0])} alt="" style={{ width: "100px", height: "100px" }} />
                                                                <span className="relative top-0 right-0 border-2 max-h-3 rounded-full w-3  border-red-700" onClick={() => deleteImage(i)}><img src={deleteIcon} alt="" className="mx-auto my-[2px]" /></span>
                                                            </div>
                                                        )
                                                    })}

                                                    <FileUploader
                                                        multiple={true}
                                                        onDrop={(e) => setImages([...images, e])} // function to get uploaded files
                                                        onSelect={(e) => setImages([...images, e])}
                                                        onDelete={deleteImage}
                                                        name="file"
                                                        classes=""
                                                        children={images.length < 6 ?
                                                            <div className="border flex justify-center items-center p-6 rounded-lg cursor-pointer" style={{ width: "100px", height: "100px" }}>
                                                                <span className="text-3xl">+</span>
                                                            </div>
                                                            : <></>}
                                                        types={["JPG", "PNG", "JPEG"]}
                                                    />
                                                    <span className="absolute bottom-0 right-0 pr-4 font-semibold">{images.length}/6</span>
                                                </div>

                                                {!!defaultTags.length && <Card className="border min-h-[3rem]">
                                                    <center className="mt-2 mb-3">
                                                        <div className="flex items-center flex-wrap justify-center gap-2">
                                                            {defaultTags.map((data, i) => (
                                                                <span onClick={(e) => handleTags(e, data.tag)} className="cursor-pointer">
                                                                    <Tag color={data.isSelected ? '' : '#999'} text={data.tag} />
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </center>
                                                </Card>}
                                            </form>
                                            <br />
                                            <div>
                                                <OverlayTrigger style={(announcementTitle.length != 0) && (announcementDescription.length != 0) ? { display: "none" } : { cursor: "pointer" }}
                                                    placement="left"
                                                    overlay={
                                                        <Tooltip id={`tooltip-top`} style={(announcementTitle.length != 0) && (announcementDescription.length != 0) ? { display: "none" } : { cursor: "pointer" }}>
                                                            {(announcementTitle.length != 0) && (announcementDescription.length != 0) ?
                                                                <div style={{ display: "none" }}>

                                                                </div>
                                                                : <div className='tool-text'>
                                                                    Please add title and description
                                                                </div>
                                                            }
                                                        </Tooltip>
                                                    }
                                                >
                                                    {(announcementTitle.length != 0) && (announcementDescription.length != 0) ?
                                                        <div className='btn-outer float-right'>
                                                            <div className='btn-1 ml-auto' style={{ width: "120px", height: "35px" }}>
                                                            </div>
                                                            <Button
                                                                variant="default" size="md"
                                                                type="submit"
                                                                style={{ float: "right", width: "120px", height: "35px", lineHeight: "18px" }}
                                                                className="btn-2 mr-[1px]"
                                                                onClick={(e) => createVersion(e, true)}
                                                                disabled={loading ? true : false}

                                                            >
                                                                {loading ? "Please Wait" : "Publish"}
                                                            </Button></div> :
                                                        <div className='btn-outer float-right'>
                                                            <div className='btn-1 ml-auto' style={{ width: "120px", height: "30px" }}>
                                                            </div>
                                                            <Button
                                                                variant="default" size="md"
                                                                type="submit"
                                                                style={{ float: "right", width: "120px", height: "30px", lineHeight: "12px" }}
                                                                className="btn-2 mr-[1px]"
                                                            >
                                                                Publish
                                                            </Button>
                                                        </div>
                                                    }
                                                </OverlayTrigger>
                                                <div className="float-left flex gap-4">
                                                    <Button
                                                        variant="default" size="md"
                                                        type="submit"
                                                        style={{ width: "100px" }}
                                                        className="text-black bg-orange-400 font-medium text-base"
                                                        onClick={() => setSection("version")}
                                                    >
                                                        Back
                                                    </Button>
                                                    <Button
                                                        variant="default" size="md"
                                                        type="submit"
                                                        style={{ width: "100px" }}
                                                        className="text-black bg-orange-600 font-medium text-base"
                                                        onClick={createVersion}
                                                    >
                                                        Skip
                                                    </Button>
                                                </div>
                                            </div>
                                            <br />
                                        </Col>
                                    </Row>
                                </div>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            }
        </div>
    )
}

export default NewVersion
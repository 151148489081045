import React, { Component } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Button, Card, Navbar, Form, Nav, FormControl, Modal } from "react-bootstrap";
import fedduplogo from '../images/fedduplogo.png';
import home from '../images/home.svg';
import profile from '../images/profilepicture.png';
import profileicon from '../images/profileicon.svg';
import dashboard from '../images/dashboard.svg';
import usersettings from '../images/usersettings.svg';
import faq from '../images/faq.svg';
import helpcenter from '../images/helpcenter.svg';
import logout from '../images/logout.svg';
import notification from '../images/notifications.svg';
import fillnotification from '../images/fill-notification.svg';
import './NavBar.css';
import axios from 'axios';
import { config } from '../Config/Config';
import PopupSuggestionComments from '../UsersFeed/PopupSuggestionComments';
import SuggestionComments from '../AdminFeed/SuggestionComments';
import PopupComments from '../UsersFeed/PopupComments';
import FeedbackComments from '../AdminFeed/FeedbackComments';
import { decrypt } from '../utils';

export default class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clicked: false,
            loading: false,
            searchValue: "",
            close: false,
            searchModal: false,
            searchResults: [],
            redirect: false,
            productName: "",
            companyName: "",
            productType: "",
            notifications: [],
            displayNotifications: false,
            notificationFlag: false,
            log: false,
            viewSuggestionImage: false,
            popupSuggestionImage: "",
            popupSuggestion: false,
            popupSuggestionData: [],
            suggestionPop: false,
            popup: false,
            popupFeedbackData: [],
            viewFeedbackImage: false,
            popupFeedbackImage: "",
            feedbackPop: false,
            canSwitchPanel: null
        }
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        if (localStorage.getItem('junkStore')) {
            this.getNotificationFlag();
            this.verifyCanSwitchPanel();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }
    getNotificationFlag = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getNotificationFlag', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    self.setState({ notificationFlag: response.data.flag })
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    getNotifications = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getNotifications', { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    self.setState({
                        notifications: response.data.notifications
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    verifyCanSwitchPanel = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/canSwitchPanel', { headers: headers })
            .then(function (response) {
                self.setState({ canSwitchPanel: response?.data?.user || null })
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    switchPanel = (role) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var self = this;
        let roleId;
        if (window.localStorage.getItem('role') == 'individual') {
            roleId = role;
        }
        else {
            roleId = 1
        }
        axios.post(config.userUrl + 'user/switchPanel', { roleId: roleId }, { headers: headers })
            .then(function (response) {
                if (roleId == 1) {
                    window.location.replace('/userfeed')
                }
                else {
                    window.location.replace('/admindashboard')
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    showNotifications = () => {
        if (localStorage.getItem('junkStore')) {
            this.setState({ notificationFlag: false, displayNotifications: !this.state.displayNotifications })
            this.getNotifications()
        } else {
            this.setState({ notificationFlag: false, displayNotifications: false })
        }
    }
    openModal = () => {
        if (localStorage.getItem('junkStore')) {
            this.setState({ close: !this.state.close })
        } else {
            this.setState({ close: false })
        }
    }
    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }
    onChangeHandler = (e) => {
        console.log("length", e.target.value)
        this.search(e.target.value);
        this.setState({ searchValue: e.target.value });
        console.log("a", e.target.value.length)
    };
    search = async val => {
        this.setState({ loading: true });
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var self = this;
        axios.get(config.userUrl + `user/searchCompanyAndProduct?search=${val}`, { headers: headers })
            .then(function (response) {
                console.log("searchCompanyAndProduct", response);
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("searchCompanyAndProduct successfull", response.data);
                    if (self.state.searchValue.length !== 0) {
                        self.setState({ searchResults: response.data.searchProduct, searchModal: true })
                    } else {
                        self.setState({ searchResults: [], searchModal: false })
                    }

                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });

        this.setState({ loading: false });
    };
    showProductDetailsPage = (data) => {
        if ((localStorage.getItem('role') == "admin") || (localStorage.getItem('role') == "subAdmin")) {
            this.setState({
                redirect: false,
                id: data.productId,
                productName: data.productName,
                companyName: data.companyName,
                productType: data.productTypeString
            })
        } else {
            this.setState({
                redirect: true,
                id: data.productId,
                productName: data.productName,
                companyName: data.companyName,
                productType: data.productTypeString
            })
        }
    }
    logoutFromApp = () => {
        localStorage.clear();
        this.setState(
            {
                log: true
            })
    }
    handleSubmit = (e, id) => {
        e.preventDefault();
        if ((localStorage.getItem('role') == "admin") || (localStorage.getItem('role') == "subAdmin")) {
            this.setState({ searchModal: false })
            window.location.replace(`/company/products/?q=${this.state.searchValue}`)
        } else {
            window.location.replace(`/company/products/?q=${this.state.searchValue}`)
        }
    }
    closeSearch = (e) => {
        console.log("search")
        this.setState({ searchModal: false, searchValue: "" })
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef?.current?.contains(event.target)) {
            this.setState({ searchModal: false, searchValue: "", close: false, displayNotifications: false })
        }
    }
    openSuggestionImage = (data) => {
        this.setState({ viewSuggestionImage: true, popupSuggestionImage: data.suggestionImage })
    }
    togglePopupSuggestion = (data) => {
        if (localStorage.getItem('junkStore')) {
            this.setState({ popupSuggestion: !this.state.popupSuggestion, popupSuggestionData: data })
        } else {
            this.setState({ popupSuggestion: false })
        }
    }
    toggleAdminPopupSuggestion = (data) => {
        if (localStorage.getItem('junkStore')) {
            this.setState({ suggestionPop: !this.state.suggestionPop, popupSuggestionData: data })
        } else {
            this.setState({ suggestionPop: false })
        }
    }
    openFeedbackImage = (data) => {
        this.setState({ viewFeedbackImage: true, popupFeedbackImage: data.feedbackImage })
    }
    togglePopupFeedback = (data) => {
        if (localStorage.getItem('junkStore')) {
            this.setState({ popup: !this.state.popup, popupFeedbackData: data })
        } else {
            this.setState({ popup: false })
        }
    }
    toggleAdminPopupFeedback = (data) => {
        if (localStorage.getItem('junkStore')) {
            this.setState({ feedbackPopup: !this.state.feedbackPopup, popupFeedbackData: data })
        } else {
            this.setState({ feedbackPopup: false })
        }
    }
    openSuggestionPopup = (data) => {
        if (data.type == 1) {
            this.setState({ popup: true, popupFeedbackData: data })
        } else {
            this.setState({ popupSuggestion: true, popupSuggestionData: data })
        }
    }
    openAdminSuggestionPopup = (data) => {
        if (data.type == 1) {
            this.setState({ suggestionPop: true, popupSuggestionData: data })
        } else {
            this.setState({ suggestionPop: true, popupSuggestionData: data })
        }
    }

    render() {
        const { searchResults, notifications, feedbackPop, suggestionPop, popupFeedbackData, popup, popupSuggestion, popupSuggestionData, redirect, log, canSwitchPanel } = this.state;
        const diff_hours = (dt2, dt1) => {
            var diff = (dt2.getTime() - dt1.getTime()) / 1000;
            diff /= (60 * 60);
            return Math.abs(Math.round(diff));
        }
        var dt1 = new Date();
        var dt2 = new Date("2022-12-22T11:27:26.284Z");
        //console.log("diff", Math.round(diff_hours(dt1, dt2)));
        // const nDate = notifications.map((i, idx) => {
        //     var c = new Date(i.createdDate)
        //     var expiry = c.getDate() + "-" + parseInt(c.getMonth() + 1) + "-" + c.getFullYear();
        //     return expiry;
        // })

        if (redirect) {
            return <Link to={{
                pathname: `/by/${this.state.companyName}/${this.state.productName}/${this.state.productType}`,
                // pathname: `/company/productpage/${this.state.productName}`,
                state: {
                    id: this.state.productId
                }
            }} />
        }
        if (log) {
            return <Redirect to="/" />
        }
        // var options = searchResults.map(data => console.log("data", data))
        const options = [
            { value: 'chocolate', label: 'Chocolate' },
            { value: 'strawberry', label: 'Strawberry' },
            { value: 'vanilla', label: 'Vanilla' }
        ]

        return (
            <div>
                {popupSuggestion && <PopupSuggestionComments openSuggestionImage={this.openSuggestionImage} closePopup={this.togglePopupSuggestion} data={popupSuggestionData} selectedValue={1} />}
                {suggestionPop && <SuggestionComments openSuggestionImage={this.openSuggestionImage} closePopup={this.toggleAdminPopupSuggestion} data={popupSuggestionData} selectedValue={1} />}
                {popup && <PopupComments openImage={this.openFeedbackImage} closePopup={this.togglePopupFeedback} data={popupFeedbackData} selectedValue={1} />}
                {feedbackPop && <FeedbackComments openImage={this.openFeedbackImage} closePopup={this.toggleAdminPopupFeedback} data={popupFeedbackData} selectedValue={1} />}
                <Navbar className="nav-height" fixed='top' >
                    {Boolean(!localStorage.getItem('junkStore')) ?
                        <Navbar.Brand href="/" > <img className="navbar-logo" src={fedduplogo} /> </Navbar.Brand > :
                        (decrypt(localStorage.getItem('junkStore')) && localStorage.getItem('role') == "individual") ?
                            <Navbar.Brand href="/userfeed" > <img className="navbar-logo" src={fedduplogo} /> </Navbar.Brand >
                            :
                            <Navbar.Brand href="/admindashboard" > <img className="navbar-logo" src={fedduplogo} /> </Navbar.Brand >
                    }
                    <Nav className="ml-auto mr-right flex items-center" >
                        {canSwitchPanel ? <div className='btn-outer' onClick={() => this.switchPanel(canSwitchPanel?.role)}>
                            <div className='btn-1' style={{ width: "160px", height: '36px' }}>
                            </div>
                            <Button
                                variant="default" size="md"
                                type="submit"
                                className={`btn-2`}
                                style={{ width: "160px", height: '36px', lineHeight: 0.7 }}
                            >
                                Switch Panel
                            </Button>
                        </div> : <></>}
                        <Form onSubmit={this.handleSubmit}>
                            {!localStorage.getItem('junkStore') ? <Link to="/login">
                                <div className='btn-outer mt-1'>
                                    <div className='btn-1' style={{ width: "150px", height: '38px' }}>
                                    </div>
                                    <Button
                                        variant="default" size="md"
                                        type="submit"
                                        className={`btn-2`}
                                        style={{ width: "150px", height: '38px', lineHeight: 0.7 }}
                                    >
                                        Login
                                    </Button>
                                </div>
                            </Link> :
                                localStorage.getItem('role') == "individual"
                                    ?
                                    <FormControl
                                        type="text"
                                        placeholder="Search"
                                        value={this.state.searchValue}
                                        onChange={e => this.onChangeHandler(e)}
                                        id="search" className="abc form-control search_box"
                                        aria-label="Search keyword"
                                        style={{ marginTop: '-1px' }}
                                    /> : <></>}

                        </Form> &nbsp;&nbsp;
                        {!localStorage.getItem('junkStore') ?
                            "" : <>
                                {this.state.searchModal ?
                                    <div>
                                        {(localStorage.getItem('role') == "admin") || (localStorage.getItem('role') == "subAdmin") ?
                                            <div>
                                                <Card id="card-search" ref={this.wrapperRef}>
                                                    <div>
                                                        {this.state.searchResults.length == 0 ? <div>
                                                            No Results found</div> :
                                                            <div>
                                                                {this.state.searchResults.slice(0, 5).map((data, i) =>
                                                                (
                                                                    <div style={{ cursor: "pointer" }} onClick={() => this.setState({ searchModal: false })}>
                                                                        <Link to={`/by/${data.companyName}/${data.productName}/${data.productTypeString}`} className="link">
                                                                            <span className="companyName">{data.companyName} /</span> {data.productName}</Link>
                                                                    </div>

                                                                ))}
                                                            </div>
                                                        }
                                                    </div>
                                                </Card>
                                            </div> :
                                            <div >
                                                <Card id="card-search" ref={this.wrapperRef}>
                                                    <div >
                                                        {this.state.searchResults.length == 0 ? <div onKeyUp={e => this.closeSearch(e)}>No Results found</div> :
                                                            <div>
                                                                {this.state.searchResults.slice(0, 5).map((data, i) =>
                                                                (
                                                                    <div style={{ cursor: "pointer" }} className="border-underline" onClick={() => this.setState({ searchModal: false })}>
                                                                        <Link to={{
                                                                            pathname: `/by/${data.companyName}/${data.productName}/${data.productTypeString}`,
                                                                            state: {
                                                                                id: data.productId
                                                                            }
                                                                        }} className="link" style={{ display: "flex" }}><img src={data.productIcon == null ? profile : data.productIcon} className={data.productIcon == null ? "search-p-img" : "search-p-img_"} />&nbsp;<div className={data.productIcon == null ? "search-t-gap" : "search-t-gap_"} ><span className="companyName">{data.companyName} / </span><span>{data.productName}</span></div></Link>

                                                                    </div>
                                                                ))}
                                                            </div>}
                                                    </div>
                                                </Card>
                                            </div>
                                        }
                                    </div> : ""
                                }</>
                        }
                        {localStorage.getItem('junkStore') ?
                            <>{this.state.notificationFlag ?
                                <Nav.Link href="#" className=''><img src={fillnotification} onClick={this.showNotifications} className="mt-1 ml-3 notification-icon" /></Nav.Link>
                                :
                                <Nav.Link href="#" className=''><img src={notification} onClick={this.showNotifications} className="mt-1 ml-3 notification-icon" /></Nav.Link>}
                                <Nav.Link href="#">
                                    <div onClick={this.openModal} className='flex flex-col items-center justify-center'>
                                        <img src={!localStorage.getItem("icon") ? profile : localStorage.getItem("icon")} className="icon" />
                                        <div className='flex items-center gap-1'>
                                            <span style={{ color: "black", fontSize: "13px", fontFamily: "poppins" }}>{localStorage.getItem('name')}</span>
                                            <i className="fa fa-angle-down" ></i>
                                        </div>
                                    </div>
                                </Nav.Link></>
                            : ""}
                        {!localStorage.getItem('junkStore') ?
                            "" : <>
                                {this.state.displayNotifications ?
                                    <div>
                                        {(localStorage.getItem('role') == "admin") || (localStorage.getItem('role') == "subAdmin") ?
                                            <div>
                                                <Card id="notification-search" style={{ border: '1px solid #FFB633' }} ref={this.wrapperRef}>
                                                    <div>
                                                        {this.state.notifications.length == 0 ? <div>
                                                            No Notifications</div> :
                                                            <div>
                                                                <div className="text-center mb-1 text-lg">{this.state.notifications?.length} Notifications</div>
                                                                <div className='flex flex-col gap-1'>
                                                                    {this.state.notifications.map((data, i) =>
                                                                    (
                                                                        <div style={{ cursor: "pointer", borderBottom: '1px solid #E6570E' }} className='py-2.5' onClick={() => this.setState({ displayNotifications: false })}>
                                                                            <div onClick={() => this.openAdminSuggestionPopup(data)}>
                                                                                <div className="w-full flex items-start gap-2">
                                                                                    <img src={data.productIcon} className="w-16 h-16 object-contain flex-shrink-0" />
                                                                                    <div className='flex-grow'>
                                                                                        <div className="text-lg font-medium flex-grow">{data.productName}</div>
                                                                                        <div className="text-sm text-gray-500 flex-grow -mt-1">{data.productType == 1 ? 'Desktop App' : 'Web App'}</div>
                                                                                        <div className='NotificationName mt-1'>{data.description}</div>
                                                                                    </div>
                                                                                    <span style={{ float: 'right', top: 0, fontWeight: "500", fontSize: "12px" }}>{Math.ceil((new Date().getTime() - new Date(data.notificationDate).getTime()) / (1000 * 60 * 60 * 24)) == 0 ? "Today" : Math.ceil((new Date().getTime() - new Date(data.notificationDate).getTime()) / (1000 * 60 * 60 * 24)) + " day(s)"}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </Card>
                                            </div> :
                                            <div >
                                                <Card id="notification-search" className='shadow-md' ref={this.wrapperRef}>
                                                    <div >
                                                        {this.state.notifications.length == 0 ? <div >No Notifications</div> :
                                                            <div>
                                                                <div className="text-center mb-1 text-lg">{this.state.notifications?.length} Notifications</div>
                                                                <div className='flex flex-col gap-1'>
                                                                    {this.state.notifications.map((data, i) =>
                                                                    (
                                                                        <div style={{ cursor: "pointer", borderBottom: '1px solid #E6570E' }} className='py-2.5' onClick={() => this.setState({ displayNotifications: false })}>
                                                                            <div onClick={() => this.openSuggestionPopup(data)}>
                                                                                <div className="w-full flex items-start gap-2">
                                                                                    <img src={data.productIcon} className="w-16 h-16 object-contain flex-shrink-0" />
                                                                                    <div className='flex-grow'>
                                                                                        <div className="text-lg font-medium flex-grow">{data.productName}</div>
                                                                                        <div className="text-sm text-gray-500 flex-grow -mt-1">{data.productType == 1 ? 'Desktop App' : 'Web App'}</div>
                                                                                        <div className='NotificationName mt-1'>{data.description}</div>
                                                                                    </div>
                                                                                    <span style={{ float: 'right', top: 0, fontWeight: "500", fontSize: "12px" }}>{Math.ceil((new Date().getTime() - new Date(data.notificationDate).getTime()) / (1000 * 60 * 60 * 24)) == 0 ? "Today" : Math.ceil((new Date().getTime() - new Date(data.notificationDate).getTime()) / (1000 * 60 * 60 * 24)) + " day(s)"}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>}
                                                    </div>
                                                </Card>
                                            </div>
                                        }
                                    </div> : ""
                                }</>}

                        {this.state.close ?
                            <>
                                {(localStorage.getItem('role') == "admin") || (localStorage.getItem('role') == "subAdmin") ?
                                    <Card className="card-profile shadow-lg" ref={this.wrapperRef}>
                                        <div style={{ width: '150px' }} className="nav-profile-text-sm mt-2 ml-2 mr-2">My Stuff</div>
                                        <div style={{ width: '150px' }} className="forgot-text-sm m-1"><Link to="/adminprofile" className="link flex items-center gap-2"><img src={profileicon} style={{ width: "20px" }} /><span>Profile</span></Link></div>
                                        <div style={{ width: '150px' }} className="forgot-text-sm m-1"><Link to="/admindashboard" className="link flex items-center gap-2"><img src={dashboard} style={{ width: "20px" }} /><span>Dashboard</span></Link></div>
                                        <div style={{ width: '150px' }} className="forgot-text-sm m-1"><Link to="/usersettings/account" className="link flex items-center gap-2"><img src={usersettings} style={{ width: "20px" }} /><span>Admin Settings</span></Link></div>
                                        <hr className='bg-orange-300 my-2 h-[1px]' />
                                        <div style={{ width: '150px' }} className="nav-profile-text-sm ml-2 mr-2">More</div>
                                        <div style={{ width: '150px' }} className="forgot-text-sm m-1"><Link to="/faqs" className="link flex items-center gap-2"><img src={faq} style={{ width: "20px" }} /><span>FAQ's</span></Link></div>
                                        <hr className='bg-orange-300 my-2 h-[1px]' />
                                        <div style={{ width: '150px' }} className="forgot-text-sm ml-2 mr-2 cursor flex items-center gap-2 text-orange" onClick={this.logoutFromApp}><img src={logout} style={{ width: "20px" }} /><span>Logout</span></div>
                                    </Card> :
                                    <Card className="card-profile shadow-lg" ref={this.wrapperRef}>
                                        <div style={{ width: '150px' }} className="nav-profile-text-sm mt-2 ml-2 mr-2">My Stuff</div>
                                        <div style={{ width: '150px' }} className="forgot-text-sm m-1"><Link to="/profile" className="link flex items-center gap-2"><img src={profileicon} style={{ width: "20px" }} /><span>Profile</span></Link></div>
                                        <div style={{ width: '150px' }} className="forgot-text-sm m-1"><Link to="/usersettings/account" className="link flex items-center gap-2"><img src={usersettings} style={{ width: "20px" }} /><span>User Settings</span></Link></div>
                                        <hr className='bg-orange-300 my-2 h-[1px]' />
                                        <div style={{ width: '150px' }} className="nav-profile-text-sm ml-2 mr-2">More</div>
                                        <div style={{ width: '150px' }} className="forgot-text-sm m-1"><Link to="/faqs" className="link flex items-center gap-2"><img src={faq} style={{ width: "20px" }} /><span>FAQ's</span></Link></div>
                                        <hr className='bg-orange-300 my-2 h-[1px]' />
                                        <div style={{ width: '150px' }} className="forgot-text-sm ml-2 mr-2 cursor flex items-center gap-2 text-orange" onClick={this.logoutFromApp}><img src={logout} style={{ width: "20px" }} /><span>Logout</span></div>
                                    </Card>}
                            </> : null}
                    </Nav >


                </Navbar >
            </div >
        )
    }
}

import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { config } from '../Config/Config';
import { Button, Card, Col, Dropdown, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PopupAnnouncementComments from "../UsersFeed/PopupAnnouncementComments";
import downvoteb from '../images/downvote-b.svg';
import upvote from '../images/upvote.svg';
import upvoteb from '../images/upvote-b.svg';
import downvote from '../images/downvote.svg';
import fillTag from "../images/fill-tag.svg";
import share from '../images/share.svg';
import ViewAnnouncementImage from '../UsersFeed/ViewAnnouncementImage';
import OpenShareAnnouncementPopup from '../UsersFeed/OpenShareAnnouncementPopup';
import moment from 'moment';
import Tag from '../utils/Tag';
import ShareBox from '../UsersFeed/ShareBox';
import { decrypt } from '../utils';


function ProductAnnouncementSection(props) {
    const { companyId, productId, selectedVersion, initialTags, sharedId, companyName, productName, productType, setState } = props;

    const [productAnnouncements, setProductAnnouncements] = useState([]);
    const [uImg, setUImg] = useState(false);
    const [count, setCount] = useState(999);
    const [dImg, setDImg] = useState(true);
    const [selectedValue, setSelectedValue] = useState(0);
    const [popupAnnouncement, setPopupAnnouncement] = useState(false);
    const [popupAnnouncementData, setPopupAnnouncementData] = useState([]);
    const [openShareAnnouncement, setOpenShareAnnouncement] = useState(false);
    const [sortOption, setSortOption] = useState('');
    const [sortOption2, setSortOption2] = useState('');
    const [sortedData, setSortedData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [viewAnnouncementImage, setViewAnnouncementImage] = useState(false);
    const [popupAnnouncementImage, setPopupAnnouncementImage] = useState("");


    useEffect(() => {
        setSortedData([]);
        getAllAnnouncements();
    }, [selectedVersion])

    const getAllAnnouncements = () => {
        if (selectedVersion?.id === "") return;
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + (localStorage.getItem('junkStore') ? decrypt(localStorage.getItem('junkStore')) : ''),
            "applicationType": "web"
        }
        var payload = {
            "productId": productId,
            "versionId": selectedVersion.id
        }
        axios.post(config.userUrl + "admin/getProductAnnouncements", payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    setProductAnnouncements(response.data.announcementDetails)
                    setSortedData(response.data.announcementDetails)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const getAllAnnouncementsByProductId = async (eventKey) => {
        let headers;
        if (localStorage.getItem('junkStore')) {
            headers = {
                'Accept': 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                'Content-Type': 'application/json'
            }
        } else {
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        var payload = {
            "productId": productId,
            "type": parseInt(eventKey)
        }
        axios.post(config.userUrl + "user/getAnnouncementByProductId ", payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("getAnnouncementByProductId  successfull");
                    setProductAnnouncements(response.data.announcement)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };


    function changeUpvoteImageAnnouncement(id, val) {
        if (localStorage.getItem('junkStore')) {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                "applicationType": "web"
            }
            console.log("dataup", id.id)
            setUImg(!uImg)
            if (uImg) {
                setCount(count - 1)
                setDImg(false)

            } else {
                setCount(count + 1)
                // setDImg(false)
                setDImg(false)
            }
            var payload = {
                "announcementId": id.id,
                "announcement": val,
                "adminIdAnnouncementBelongTo": id.announcementByAdminId
            }
            console.log("upvote", payload)
            if (val == 1) {
                console.log("upvote", payload)
                let idx = sortedData?.findIndex(item => item.id == id.id);
                let arr = [...sortedData];
                arr[idx].vote = 1;
                arr[idx].totallike = Number(arr[idx].totallike) + 1;
                setSortedData(arr);
                axios.post(config.userUrl + 'user/likeOrDislikeAnnouncement', payload, { headers: headers })
                    .then(function (response) {
                        if (response.data.status == true) {
                            // getAllAnnouncements();
                            console.log("upvote")
                        }
                    })
                    .catch(function (error) {
                        arr[idx].vote = 0;
                        arr[idx].totallike = Number(arr[idx].totallike) - 1;
                        setSortedData(arr);
                        console.log(error, payload)
                    })
            }
        } else {
            console.log('No upvote')
        }
    }
    function changeDownvoteImageAnnouncement(id, val) {
        if (localStorage.getItem('junkStore')) {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                "applicationType": "web"
            }
            console.log("data", id)
            setDImg(!dImg)
            if (!dImg) {
                setUImg(false)
                setCount(count - 1)
            } else {
                // setDImg(!dImg)
                setUImg(false)
                setDImg(false)
            }
            var payload = {
                "announcementId": id.id,
                "announcement": val,
                "adminIdAnnouncementBelongTo": id.announcemntByUserId
            }
            console.log("upvote", payload)
            if (val == 0) {
                let idx = sortedData?.findIndex(item => item.id == id.id);
                let arr = [...sortedData];
                arr[idx].vote = -1;
                arr[idx].totallike = Number(arr[idx].totallike) - 1;
                setSortedData(arr);
                axios.post(config.userUrl + 'user/likeOrDislikeAnnouncement', payload, { headers: headers })
                    .then(function (response) {
                        if (response.data.status == true) {
                            console.log("upvote")
                            getAllAnnouncements();
                        }
                    })
                    .catch(function (error) {
                        let idx = sortedData?.findIndex(item => item.id == id.id);
                        let arr = [...sortedData];
                        arr[idx].vote = 0;
                        arr[idx].totallike = Number(arr[idx].totallike) + 1;
                        setSortedData(arr);
                        console.log(error, payload)
                    })
            }
        } else {
            console.log('No Downvote')
        }
    }
    const togglePopupAnnouncement = (data) => {
        setPopupAnnouncement(!popupAnnouncement)
        setPopupAnnouncementData({ ...data, productTypeString: productType })
    }
    const toggleShareAnnouncementPopup = (data) => {
        if (localStorage.getItem('junkStore')) {
            setOpenShareAnnouncement(!openShareAnnouncement)
            setPopupAnnouncementData(data)
        } else {
            setOpenShareAnnouncement(false);
        }
    }
    const filter = () => {
        let announcementsArray = [...productAnnouncements]
        let sortedAnnouncements = []
        if (sortOption2.length > 0) {
            if (sortOption2 === "Anonymous") {
                sortedAnnouncements = announcementsArray.filter(announcement => announcement.userName === null)
            }
            else if (sortOption2 === "Registered") {
                sortedAnnouncements = announcementsArray.filter(announcement => announcement.userName !== null)
            }
            else {
                sortedAnnouncements = [...productAnnouncements];
            }
            announcementsArray = [...sortedAnnouncements];
        }
        if (sortOption.length > 0) {
            if (sortOption === "Latest to Oldest") {
                sortedAnnouncements = announcementsArray.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            }
            else {
                sortedAnnouncements = announcementsArray.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
            }
            announcementsArray = [...sortedAnnouncements];
        }
        setSortedData(sortedAnnouncements);
    }
    const handleToggle = () => {
        if (sortOption || sortOption2)
            filter();
        setIsOpen(!isOpen)
    }
    const openAnnouncementImage = (data) => {
        setViewAnnouncementImage(true)
        setPopupAnnouncementImage(data.image)
    }
    const closeAnnouncementImage = () => {
        setViewAnnouncementImage(false)
    }

    useEffect(() => {
        if (productAnnouncements?.length && sharedId) {
            let idx = productAnnouncements.findIndex(item => item?.id == sharedId);
            if (idx > -1)
                togglePopupAnnouncement(productAnnouncements[idx])
        }
    }, [productAnnouncements.length, sharedId])

    return (
        <div>
            {popupAnnouncement && <PopupAnnouncementComments openAnnouncementImage={openAnnouncementImage} getAnnouncements={getAllAnnouncementsByProductId} closePopup={togglePopupAnnouncement} data={popupAnnouncementData} selectedValue={selectedValue} />}
            {viewAnnouncementImage && <ViewAnnouncementImage close={closeAnnouncementImage} image={popupAnnouncementImage} />}
            {openShareAnnouncement && <OpenShareAnnouncementPopup closePopup={toggleShareAnnouncementPopup} getAnnouncements={getAllAnnouncementsByProductId} data={popupAnnouncementData} selectedValue={selectedValue} />}
            <div className="mt-1 feddback-company">
                <div className="mb-32">
                    <div className="rating-title">
                        <p className="rating-text">Announcements</p>
                        <div className='btn-outer'>
                            <div className='btn-1'>
                            </div>
                            {/* <Button variant="default" className="btn-2">Sort & Filter <b><i className="fa fa-angle-down"></i></b></Button> */}
                            <Dropdown className="btn-2 flex justify-end items-end" align="end" show={isOpen} onToggle={handleToggle}>
                                <Dropdown.Toggle variant="default" className="text-white focus:outline-none focus:ring-0" >
                                    <span className="text-center text-lg">Sort & Filter <b><i className="fa fa-angle-down"></i></b></span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="border-orange-400 rounded-lg min-w-[15rem] z-20">
                                    <span className='text-black text-lg px-2'>Sort</span>
                                    <div className="flex flex-col gap-1 text-balck font-medium px-2 mb-2">
                                        <div className='flex items-center gap-2'>
                                            <button
                                                className={`rounded-sm w-3 h-3 focus:outline-none ${sortOption === "" ? 'bg-orange-600 text-white' : 'bg-gray-200'
                                                    }`}
                                                onClick={() => setSortOption("")}
                                            >
                                            </button>
                                            <span>Default</span>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <button
                                                className={`rounded-sm w-3 h-3 focus:outline-none ${sortOption === "Latest to Oldest" ? 'bg-orange-600 text-white' : 'bg-gray-200'
                                                    }`}
                                                onClick={() => setSortOption("Latest to Oldest")}
                                            >
                                            </button>
                                            <span>Latest to Oldest</span>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <button
                                                className={`rounded-sm w-3 h-3 focus:outline-none ${sortOption === "Oldest to Latest" ? 'bg-orange-600 text-white' : 'bg-gray-200'
                                                    }`}
                                                onClick={() => setSortOption("Oldest to Latest")}
                                            >
                                            </button>
                                            <span>Oldest to Latest</span>
                                        </div>
                                    </div>
                                    <span className='text-black text-lg px-2 mt-4'>Review Type</span>
                                    <div className="flex flex-col gap-1 text-black font-medium px-2 mb-4">
                                        <div className='flex items-center gap-2'>
                                            <button
                                                className={`rounded-sm w-3 h-3 focus:outline-none ${sortOption2 === "Registered" ? 'bg-orange-600 text-white' : 'bg-gray-200'
                                                    }`}
                                                onClick={() => setSortOption2("Registered")}
                                            >
                                            </button>
                                            <span>Registered Users</span>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <button
                                                className={`rounded-sm w-3 h-3 focus:outline-none ${sortOption2 === "Anonymous" ? 'bg-orange-600 text-white' : 'bg-gray-200'
                                                    }`}
                                                onClick={() => setSortOption2("Anonymous")}
                                            >
                                            </button>
                                            <span>Anonymous Users</span>
                                        </div>
                                    </div>
                                    {/* <div className="flex justify-end mt-4 px-2">
                                    <span className="bg-orange-600 text-white rounded-lg text-sm p-1 cursor-pointer" onClick={filter}>Apply Filters</span>
                                </div> */}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    {sortedData.length == 0 ? <Card className="border-none no_pages"><center className="no-details-text"><img src={downvoteb} className="no-pages-img" /><br />No Announcements</center></Card> :
                        sortedData.map((data, i) => (
                            <div className="mt-2 relative" key={i}>
                                {
                                    (data.announcementType?.includes("New Update") || data.tag?.includes('New Version') || data.tag?.includes('New Update')) ?
                                        <span className='absolute -top-7 -left-6 z-10 w-14 h-14'>
                                            <svg className='w-full h-full' viewBox="0 0 104 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M24.2985 68.5917C26.9285 79.8135 40.7161 86.1789 55.094 82.8093C69.4719 79.4396 78.9955 67.6109 76.3655 56.3891C73.7355 45.1674 59.9479 38.802 45.57 42.1716C31.1921 45.5413 21.6686 57.37 24.2985 68.5917Z" fill="#FFB858" />
                                                <path d="M18.4388 93.412L11.4248 65.4432L24.1425 47.9321L42.2882 57.9344L51.5948 67.6726L56.6981 54.3222L67.1475 51.475L74.3261 36.1709L102.91 72.2373L18.4388 93.412Z" fill="#E6570E" stroke="#FFB858" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M59.9062 37.5947C59.6545 34.8281 63.2112 34.3945 62.9594 31.6279C62.7076 28.8612 59.1509 29.2948 58.8991 26.5282C58.6473 23.7616 62.204 23.328 61.9522 20.5613C61.7004 17.7947 58.1437 18.2283 57.8919 15.4617C57.6401 12.695 61.1969 12.2614 60.9451 9.49481" stroke="#FFB858" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M47.1152 38.2676C46.3141 35.6125 49.763 34.6237 48.9619 31.9686C48.1607 29.3135 44.7117 30.3023 43.9105 27.6472C43.1093 24.9921 46.5583 24.0034 45.7571 21.3483C44.956 18.6932 41.507 19.6819 40.7058 17.0268C39.9046 14.3717 43.3536 13.383 42.5524 10.7279" stroke="#FFB858" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M33.5156 43.8721C32.1164 41.4489 35.3065 39.8628 33.9072 37.4397C32.508 35.0166 29.3179 36.6027 27.9186 34.1795C26.5193 31.7564 29.7095 30.1703 28.3102 27.7471C26.911 25.324 23.7208 26.9101 22.3216 24.487C20.9223 22.0638 24.1124 20.4777 22.7132 18.0546" stroke="#FFB858" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </span>
                                        : (data.announcementType?.includes("Bug Fix") || data.tag?.includes('Bug Fix')) ?
                                            <span className='absolute -top-5 -left-5 z-10 w-14 h-14'>
                                                <svg className='w-full h-full' viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="25" cy="25" r="25" fill="#E6570E" />
                                                    <path d="M25.5 46.0686C27.9853 46.0686 30 44.0539 30 41.5686C30 39.0833 27.9853 37.0686 25.5 37.0686C23.0147 37.0686 21 39.0833 21 41.5686C21 44.0539 23.0147 46.0686 25.5 46.0686Z" fill="#FFB858" />
                                                    <path d="M23 32.8823C23 30.1941 27.2659 30.1941 27.2659 27.5059C27.2659 24.8176 23 24.8176 23 22.1294C23 19.4411 27.2659 19.4411 27.2659 16.7529C27.2659 14.0647 23 14.0647 23 11.3764C23 8.6882 27.2659 8.68819 27.2659 5.99996" stroke="#FFB858" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </span>
                                            :
                                            <span className='absolute -top-7 -left-7 z-10 w-20 h-16'>
                                                <svg className='w-full h-full' viewBox="0 0 85 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M51.8643 31.5141C54.4394 30.5157 55.5803 33.4584 58.1554 32.4601C60.7306 31.4617 59.5898 28.5191 62.165 27.5207C64.7402 26.5224 65.881 29.465 68.4562 28.4667C71.0314 27.4683 69.8906 24.5257 72.4658 23.5273C75.041 22.529 76.1818 25.4716 78.757 24.4733" stroke="#FFB858" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M47.625 26.0025C49.9817 24.5622 51.6275 27.2552 53.9841 25.8149C56.3408 24.3746 54.695 21.6817 57.0517 20.2414C59.4083 18.8011 61.0541 21.4941 63.4108 20.0538C65.7675 18.6135 64.1217 15.9205 66.4783 14.4803C68.835 13.04 70.4808 15.7329 72.8375 14.2927" stroke="#FFB858" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M43.895 21.0082C45.9089 19.118 48.0688 21.4192 50.0826 19.5291C52.0964 17.6389 49.9365 15.3377 51.9504 13.4475C53.9642 11.5573 56.1241 13.8585 58.1379 11.9683C60.1518 10.0782 57.9919 7.77696 60.0057 5.88678C62.0195 3.9966 64.1794 6.2978 66.1933 4.40762" stroke="#FFB858" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M40.5824 17.1598C40.5824 17.1598 40.226 21.9491 44.7199 28.6757C49.2138 35.4022 54.2177 37.6934 54.2177 37.6934L47.1961 42.356L44.182 44.3613L41.2071 46.333L31.0583 53.0722L27 62.5L5.47461 40.473L40.5824 17.1598Z" fill="#E6570E" stroke="#E6570E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </span>
                                }
                                <Card className="border-dashed border-orange-400 rounded-[10px] border-t-[3px] border-l-[3px] border-r-0 border-b-0 mb-6">
                                    <div className="row py-2">
                                        <Col md={2}>
                                            <Card className="ml-2 vote-bg rounded-[10px] min-h-[12rem] py-4 border-none h-full">
                                                <center className={localStorage.getItem('junkStore') ? "cursor" : ""}> {data.vote == 1 ? <img src={upvote} onClick={(e, i) => changeUpvoteImageAnnouncement(data, 2)} style={{ width: "55px" }} /> : <img src={upvoteb} onClick={(e, i) => changeUpvoteImageAnnouncement(data, 1)} style={{ width: "45px" }} />}</center><br />
                                                <center className="likes">{data.totallike == null ? 0 : data.totallike}</center><br />
                                                <center className={localStorage.getItem('junkStore') ? "cursor" : ""}> {data.vote == -1 ? <img src={downvote} onClick={(e, i) => changeDownvoteImageAnnouncement(data, 2)} className="mt-2" style={{ width: "55px" }} /> : <img src={downvoteb} onClick={(e, i) => changeDownvoteImageAnnouncement(data, 0)} style={{ width: "45px" }} />}</center>
                                            </Card>
                                        </Col>
                                        <Col md={10}>
                                            <div className="ml-2">
                                                <div className="card-padding flex flex-col justify-between py-2">
                                                    {data.pin ? <div className='flex items-center gap-2 -mt-2'>
                                                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M3.94073 13.7296L9.08947 6.21469L6.38797 4.95496L3.94073 13.7296Z" fill="black" />
                                                            <path d="M6.20129 8.88249C8.18696 9.80842 10.4574 9.14206 11.2725 7.39412C12.0876 5.64619 11.1386 3.47859 9.15294 2.55265C7.16726 1.62672 4.89681 2.29308 4.08173 4.04102C3.26666 5.78895 4.21561 7.95655 6.20129 8.88249Z" fill="#FFB858" />
                                                        </svg>
                                                        <p className='text-orange-300 text-sm'>Pinned post</p>
                                                    </div> : <></>}
                                                    <h6 className="t-today">{data.title}</h6>
                                                    <div onClick={() => localStorage.getItem('junkStore') ? togglePopupAnnouncement(data) : setState(true)} className={localStorage.getItem('junkStore') ? "cursor mt-1" : "mt-1"}>
                                                        <div className="posted py-1">Posted by {data.userName == null ? "anonymous" : data.userName} &nbsp;&nbsp;{moment(data.updatedAt).fromNow()} {moment(data.updatedAt).diff(moment(data.createdAt), 'minutes') > 1 ? <span>(Edited)</span> : ''}</div>

                                                        <div className={`text-gray-800 py-2 font-medium`} dangerouslySetInnerHTML={{
                                                            __html: data.announcement
                                                        }} />
                                                        {
                                                            data?.tag?.length
                                                                ?
                                                                <div className='flex flex-wrap gap-x-3 gap-y-3 py-3'>
                                                                    {data?.tag?.map(tag => <Tag text={tag} />
                                                                    )}
                                                                </div>
                                                                : ''
                                                        }
                                                        {data.images?.length ?
                                                            <div className='flex flex-col items-center justify-center'>
                                                                <div className='flex items-center gap-4'>
                                                                    <div className='w-60 h-56 '>
                                                                        <img src={data?.images[0]} className='w-full h-full object-contain' alt="" />
                                                                    </div>
                                                                    {data?.images[1] ?
                                                                        <div className='w-60 h-56'>
                                                                            <img src={data?.images[1]} className='w-full h-full object-contain' alt="" />
                                                                        </div>
                                                                        :
                                                                        ''
                                                                    }
                                                                    {
                                                                        data.images?.length > 2 ?
                                                                            <h1 className='text-xl font-medium text-orange text-right'><span className='text-2xl font-bold'>+{data.images?.slice(2, 6)?.length}</span><br />More</h1>
                                                                            :
                                                                            ''
                                                                    }
                                                                </div>
                                                                {/* {
                                                                    data.images?.length > 2 ?
                                                                        <div className='flex items-start gap-3 pt-4'>
                                                                            {data.images?.slice(2, 6)?.map((img, i) => <img key={i} src={img} style={{ width: "100px", height: '75px', objectFit: 'contain', objectPosition: 'top' }} />)}
                                                                        </div>
                                                                        :
                                                                        ''
                                                                } */}
                                                            </div>
                                                            : ''}
                                                    </div>
                                                    <div className={`${localStorage.getItem('junkStore') ? "cursor flex items-end" : "flex items-end"} ${data.tag.length == 0 ? "mt-14" : ""} mt-10`}>
                                                        <ShareBox boxClass="!ml-0" shareCount={data.shareCount} path={`/${companyName}/${productName}/${productType}`} type={"Announcement"} postId={data.id} callback={() => { }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </div>
                                </Card>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )
}

export default ProductAnnouncementSection
import React, { Component } from "react";
import { Button, Form, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import fedduplogo from "../images/fedduplogo.png";
import forgotImg from '../images/forgot-screen-img.png';
import './Login.css';
import axios from 'axios';
import { config } from '../Config/Config';
import toast from "react-hot-toast";
import { decrypt, encrypt } from "../utils";

const validPasswordRegex =
    /^.*(?=.{10,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*]).*$/
export default class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            registerFlow: false,
            redirect: false,
            showOldPassword: false,
            showNewPassword: false,
            showConfirmPassword: false,
            oldPasswordError: "",
            newPasswordError: "",
            confirmPasswordError: ""
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.validateOldPassword = this.validateOldPassword.bind(this);
        this.validateNewPassword = this.validateNewPassword.bind(this);
        this.validateConfirmPassword = this.validateConfirmPassword.bind(this);
        this.validateField = this.validateField.bind(this);
    }
    onChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
        return;
    }
    changePassword = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var encryptedOldPassword = encrypt(this.state.oldPassword);
        var encryptedNewPassword = encrypt(this.state.newPassword);
        var self = this;
        var payload = {
            "oldPassword": encryptedOldPassword,
            "newPassword": encryptedNewPassword
        }
        axios.post(config.apiUrl + 'auth/userChangePassword', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("Password reset success!!")
                    self.setState({
                        redirect: true
                    })
                    localStorage.clear();
                } else {
                    console.log(response.data.message)
                    document.getElementById('password').innerText = msg
                }
            })
            .catch(function (error) {
                console.log(error);
                document.getElementById('error').innerText = error
            });
    }
    showOldPassword = () => {
        this.setState({ showOldPassword: !this.state.showOldPassword });
    };
    showNewPassword = () => {
        this.setState({ showNewPassword: !this.state.showNewPassword });
    };
    showConfirmPassword = () => {
        this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
    };
    handleBlur(event) {
        const { name } = event.target;
        this.validateField(name);
        return;
    }
    validateOldPassword() {
        let oldPasswordError = "";
        const value = this.state.oldPassword;
        if (value.trim === "") oldPasswordError = "Old Password is required";
        else if (!validPasswordRegex.test(value))
            oldPasswordError = "Minimum 10 with 1 capital, 1 numeric and 1 character (!@#$%^&*)";
        this.setState({
            oldPasswordError
        });
        return oldPasswordError === "";
    }
    validateNewPassword() {
        let newPasswordError = "";
        const value = this.state.newPassword;
        if (value.trim === "") newPasswordError = "New Password is required";
        else if (!validPasswordRegex.test(value))
            newPasswordError = "Minimum 10 with 1 capital, 1 numeric and 1 character (!@#$%^&*)";
        this.setState({
            newPasswordError
        });
        return newPasswordError === "";
    }

    validateConfirmPassword() {
        let confirmPasswordError = "";
        if (this.state.newPassword !== this.state.confirmPassword)
            confirmPasswordError = "Password does not match";
        this.setState({
            confirmPasswordError
        });
        return confirmPasswordError === "";
    }
    validateField(name) {
        let isValid = false;
        if (name === "oldPassword") isValid = this.validateOldPassword();
        else if (name === "newPassword")
            isValid = this.validateNewPassword();
        else if (name === "confirmPassword")
            isValid = this.validateConfirmPassword();
        return isValid;
    }
    handleSubmit(event) {
        event.preventDefault();
        let formFileds = [
            "oldPassword",
            "newPassword",
            "confirmPassword"
        ];
        let isValid = true;
        formFileds.forEach(field => {
            isValid = this.validateField(field) && isValid;
        });
        if (!isValid) {
            toast("Please enter the details")
            document.getElementById('status').innerHTML = "<div>Please enter valid password</div>"
        } else {
            this.changePassword()
        }
    }
    render() {
        const { redirect } = this.state
        if (redirect) {
            return <Redirect
                to={{
                    pathname: "/successresetpassword",
                    state: { email: this.state.email }
                }} />
        }
        return (
            <div>
                <a href="/"><img alt="" src={fedduplogo} className="logo-login" /></a>
                <center><Card className="w-full max-w-md pb-10 pt-6"> <center>
                    <p className="forgot-text">Reset Password</p>
                    <p className="forgot-text-sm max-w-sm">Make sure your new password is not the same as the previous one.</p></center>
                    <br />
                    <Form className="font_family">
                        <center>
                            <div style={{ width: "80%" }}>
                                <div className="mt-2 form-group">
                                    <input type={this.state.showOldPassword ? "text" : "password"}
                                        name="oldPassword"
                                        className="form-control border"
                                        value={this.state.oldPassword}
                                        placeholder="Old Password"
                                        onChange={this.onChange}
                                        onBlur={this.handleBlur}
                                    />
                                    <span className="showPassword"
                                        onClick={this.showOldPassword}
                                    >
                                        {this.state.showOldPassword ? <i class="fa fa-eye" aria-hidden="true"></i> :
                                            // <i class="fa fa-eye-slash" aria-hidden="true"></i>
                                            <svg width="16" height="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2 10.7188C2 10.7188 5.5 14.7188 12 14.7188C18.5 14.7188 22 10.7188 22 10.7188" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4 12.3633L2 14.7183" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M21.9999 14.7192L20.0039 12.3672" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M8.914 14.3984L8 17.2184" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M15.0625 14.4062L15.9995 17.2183" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        }
                                    </span>
                                    <div className="div">{this.state.oldPasswordError && (
                                        <div className="error">{this.state.oldPasswordError}</div>
                                    )}</div>
                                </div>
                                <div className="mt-2 form-group">
                                    <input type={this.state.showNewPassword ? "text" : "password"}
                                        name="newPassword"
                                        className="form-control border"
                                        value={this.state.newPassword}
                                        placeholder="New Password"
                                        onChange={this.onChange}
                                        onBlur={this.handleBlur}
                                    />
                                    <span className="showPassword"
                                        onClick={this.showNewPassword}
                                    >
                                        {this.state.showNewPassword ? <i class="fa fa-eye" aria-hidden="true"></i> :
                                            // <i class="fa fa-eye-slash" aria-hidden="true"></i>
                                            <svg width="16" height="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2 10.7188C2 10.7188 5.5 14.7188 12 14.7188C18.5 14.7188 22 10.7188 22 10.7188" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4 12.3633L2 14.7183" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M21.9999 14.7192L20.0039 12.3672" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M8.914 14.3984L8 17.2184" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M15.0625 14.4062L15.9995 17.2183" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        }
                                    </span>
                                    <div className="div">{this.state.newPasswordError && (
                                        <div className="error">{this.state.newPasswordError}</div>
                                    )}</div>
                                </div>
                                <div className="mt-2 form-group">
                                    <input type={this.state.showConfirmPassword ? "text" : "password"}
                                        name="confirmPassword"
                                        className="form-control border"
                                        value={this.state.confirmPassword}
                                        placeholder="Re-enter New Password"
                                        onChange={this.onChange}
                                        onBlur={this.handleBlur}
                                    />
                                    <span className="showPassword"
                                        onClick={this.showConfirmPassword}
                                    >
                                        {this.state.showConfirmPassword ? <i class="fa fa-eye" aria-hidden="true"></i> :
                                            // <i class="fa fa-eye-slash" aria-hidden="true"></i>
                                            <svg width="16" height="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2 10.7188C2 10.7188 5.5 14.7188 12 14.7188C18.5 14.7188 22 10.7188 22 10.7188" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4 12.3633L2 14.7183" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M21.9999 14.7192L20.0039 12.3672" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M8.914 14.3984L8 17.2184" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M15.0625 14.4062L15.9995 17.2183" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        }
                                    </span>
                                    <div className="div">{this.state.confirmPasswordError && (
                                        <div className="error">{this.state.confirmPasswordError}</div>
                                    )}</div>
                                </div><br />
                                <div className='btn-outer mt-3'>
                                    <div className='btn-1' style={{ height: "40px", width: '150px' }}></div>
                                    <Button
                                        variant="default"
                                        type="submit"
                                        className="btn-2"
                                        style={{ position: "absolute", fontSize: "16px", height: "40px", width: '150px', lineHeight: "16px", left: 0, top: 0 }}
                                        onClick={this.handleSubmit}
                                    >
                                        Submit
                                    </Button>
                                </div>
                                <div id="password" className="errormsg"></div>
                                <div id="error" className="errormsg"></div>
                                <div id="status" className="errormsg"></div>
                            </div>
                            <br />
                            <br />
                            <Link to="/login" className="link"><span className="login-here border-b border-amber-500">Login here</span></Link>
                        </center>
                    </Form><br />
                </Card>
                </center>
            </div>
        );
    }
}

import React, { Component } from "react";
import { Button, Form, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import fedduplogo from "../images/fedduplogo.png";
import welcomefeddup from '../images/welcomefeddup.png';
import './Login.css';

export default class WelcomeFeddup extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div >
                <a href="/"><img alt="" src={fedduplogo} className="logo-login" /></a>
                <center className="mt-20">
                    <Card className="w-full max-w-md py-10" > <center>
                    <p className="welcomeFeddup-text">Welcome to Feddup</p>
                    <br />
                    <img alt="" src={welcomefeddup} className="forgotImg" /></center><br />
                    <p style={{ fontFamily: "Poppins", fontSize: "18px", color: "#FF8B66" }}>Your account has been verified!</p>
                    <Form className="forgot-email mt-4">
                        <center>
                            <div style={{ width: "80%" }}>
                                <Link to="/login" className="link">
                                    <div className='btn-outer mx-auto'>
                                        <div className='btn-1 login-btn-1' style={{marginLeft:"20px"}}></div>
                                        <Button
                                            variant="default" size="md" block
                                            type="submit"
                                            className="login_button btn-2 login-btn-2"
                                        >
                                            Login Here
                                        </Button>
                                    </div></Link>
                            </div>
                        </center>
                    </Form><br />
                </Card>
                </center>
            </div >
        );
    }
}

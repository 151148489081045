import React, { useRef, useState, useEffect } from "react";
import { Button, Form, Modal, Dropdown, Card, Row, Col, OverlayTrigger, Tooltip, Popover } from "react-bootstrap";
import { Link, useParams, useLocation, NavLink, Redirect } from "react-router-dom";
import fedduplogo from "../images/fedduplogo.png";
import NavBar from "../NavBar/NavBar";
import { Feedback } from '../UsersFeed/Feedback';
import { Suggestion } from '../UsersFeed/Suggestion';
import { Announcements } from '../UsersFeed/Announcements';
import './AdminFeed.css';
import { FaStar } from "react-icons/fa";
import fillTag from "../images/fill-tag.svg";
import emptyTag from "../images/empty-tag.svg";
import upvoteb from '../images/upvote-b.svg';
import downvoteb from '../images/downvote-b.svg';
import upvote from '../images/upvote.svg';
import downvote from '../images/downvote.svg';
import comment from '../images/comments.svg';
import share from '../images/share.svg';
import reportIcon from '../images/report.svg';
import saveIcon from '../images/save.svg';
import statusIcon from '../images/status.svg';
import post from '../images/post.png';
import trending from '../images/trending.svg';
import newData from '../images/new.svg';
import top from '../images/top.svg';
import ratingImg from '../images/showrating.PNG';
import background from '../images/background-img.svg';
import profilepicture from '../images/profilepicture.png';
import ratingimg from '../images/ratingimg.png';
import { Tags } from "../UsersFeed/TrendingTags";
import { Moderators } from "../CompanyFeed/Moderators";
import { BsArrowLeft } from "react-icons/bs";
import i from "../images/i.svg";
import out from "../images/out.svg";
import cross from "../images/cross.svg";
import hide from "../images/hide.svg";
import pin from "../images/pin.svg";
import imgDel from "../images/delete.svg";
import dots from "../images/dots.svg";
import deletei from "../images/delete-i.svg";
import rating1 from '../images/rating1.png';
import rating2 from '../images/rating2.png';
import rating3 from '../images/rating3.png';
import rating4 from '../images/rating4.png';
import rating5 from '../images/rating5.png';
import DeleteFeedbackByAdmin from "./DeleteFeedbackByAdmin";
import DeleteSuggestionByAdmin from "./DeleteSuggestionByAdmin";
import PinFeedback from "./PinFeedback";
import FeedbackComments from "./FeedbackComments";
import SuggestionComments from "./SuggestionComments";
import createicon from "../images/create-post-icon.svg";
import imageicon from "../images/image-icon.svg";
import linkicon from "../images/link-icon.svg";
import Hide from "./Hide";
import axios from 'axios';
import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { config } from '../Config/Config';
import Editor from "../CompanyFeed/Editor";
import AddImage from "../CompanyFeed/AddImage";
import AnnouncementComments from "./AnnouncementComments";
import ReportFeedbackByAdmin from "./ReportFeedbackByAdmin";
import SaveFeedback from "./SaveFeedback";
import ReportSuggestionByAdmin from "./ReportSuggestionByAdmin";
import ViewImage from "../UsersFeed/ViewImage";
import ViewSuggestionImage from "../UsersFeed/ViewSuggestionImage";
import ViewAnnouncementImage from "../UsersFeed/ViewAnnouncementImage";
import OpenShareFeedbackByAdmin from "./OpenShareFeedbackByAdmin";
import OpenShareSuggestionByAdmin from "./OpenShareSuggestionByAdmin";
import OpenShareAnnouncementByAdmin from "./OpenShareAnnouncementByAdmin";
import arrowLetf from "../images/left-arrow.svg";
import Tag from "../utils/Tag";
import { decrypt, encrypt } from "../utils";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default function AdminFeed() {
    const params = useParams()
    let query = useQuery();
    const [deletePost, setDeletePost] = useState(false);
    const [pinValue, setPinValue] = useState(false);
    const [saveValue, setSaveValue] = useState(false);
    const [hideValue, setHideValue] = useState(false);
    const [report, setReport] = useState(false);
    const [reportSuggestion, setReportSuggestion] = useState(false);
    const [status, setStatus] = useState("");
    const [deletePostSuggestion, setDeletePostSuggestion] = useState(false);
    const [pinFeedback, setPinFeedback] = useState(false);
    const [pinSuggestion, setPinSuggestion] = useState(false);
    const [saveFeedback, setSaveFeedback] = useState(false);
    const [saveSuggestion, setSaveSuggestion] = useState(false);
    const [saveAnnouncement, setSaveAnnouncement] = useState(false);
    const [hideFeedback, setHideFeedback] = useState(false);
    const [hideSuggestion, setHideSuggestion] = useState(false);
    const [openShareFeedback, setOpenShareFeedback] = useState(false);
    const [openShareSuggestion, setOpenShareSuggestion] = useState(false);
    const [openShareAnnouncement, setOpenShareAnnouncement] = useState(false);
    const [addFeedback, setAddFeedback] = useState(false);
    const [selectedValue, setSelectedValue] = useState(0);
    const [feedbackByUserId, setFeedbackByUserId] = useState("");
    const [suggestionByUserId, setSuggestionByUserId] = useState("");
    const [feedbackTitle, setFeedbackTitle] = useState("");
    const [feedbackDescription, setFeedbackDescription] = useState("");
    const [suggestionTitle, setSuggestionTitle] = useState("");
    const [suggestionDescription, setSuggestionDescription] = useState("");
    const [addSuggestion, setAddSuggestion] = useState(false);
    const [image, setImage] = useState("")
    const [addAnnouncement, setAddAnnouncement] = useState(false);
    const [announcementTitle, setAnnouncementTitle] = useState("");
    const [announcementDescription, setAnnouncementDescription] = useState("");
    const [addRating, setAddRating] = useState(false);
    const [ratingGiven, setRatingGiven] = useState("");
    const [ratingComment, setRatingComment] = useState("");
    const [edit, setEdit] = useState(false);
    const [show, setShow] = useState(false);
    const [feedShow, setFeedShow] = useState(false);
    const [scroll, setScroll] = useState(0)
    const [item, setItem] = useState(query.get("item"));
    const [feedId, setFeedId] = useState("");
    const [suggestionId, setSuggestionId] = useState("");
    const [announcementId, setAnnouncementId] = useState("");
    const [rate1, setRate1] = useState(true);
    const [uImg, setUImg] = useState(false);
    const [dImg, setDImg] = useState(true);
    const [count, setCount] = useState(999);
    const [follow, setFollow] = useState(true);
    const [popup, setPopup] = useState(false);
    const [popupData, setPopupData] = useState([]);
    const [suggestionPop, setSuggestionPop] = useState(false);
    const [announcementPop, setAnnouncementPop] = useState(false);
    const [announcementPopData, setAnnouncementPopData] = useState([]);
    const [suggestionPopData, setSuggestionPopData] = useState([]);
    const [feedbackData, setFeedbackData] = useState([]);
    const [suggestionData, setSuggestionData] = useState([]);
    const [announcementData, setAnnouncementData] = useState([]);
    const [popSuggestion, setPopSuggestion] = useState(false)
    const [popFeedback, setPopFeedback] = useState(false)
    const [companyName, setCompanyName] = useState("")
    const [productName, setProductName] = useState("")
    const [productSector, setProductSector] = useState("")
    const [productType, setProductType] = useState("")
    const [productId, setProductId] = useState("")
    const [companyId, setCompanyId] = useState("")
    const [productWebsite, setProductWebsite] = useState("")
    const [productIcon, setProductIcon] = useState("")
    const [avgRating, setAvgRating] = useState("")
    const [noOfPeopleGivenRating, setNoOfPeopleGivenRating] = useState("")
    const [ratingCommentDetails, setRatingCommentDetails] = useState([])
    const [productFollowers, setProductFollowers] = useState("")
    const [id, setId] = useState(query.get("productId"))
    const [rating, setRating] = useState("")
    const [defaultTags, setDefaultTags] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    const [ratingCountArray, setRatingCountArray] = useState([])
    const [viewImage, setViewImage] = useState(false)
    const [popupImage, setPopupImage] = useState("")
    const [viewSuggestionImage, setViewSuggestionImage] = useState(false);
    const [popupSuggestionImage, setPopupSuggestionImage] = useState("");
    const [viewAnnouncementImage, setViewAnnouncementImage] = useState(false);
    const [popupAnnouncementImage, setPopupAnnouncementImage] = useState("");
    const [emptyProduct, setEmptyProduct] = useState(false);
    const [state, setState] = useState("")
    const onChange = (data) => {
        setState(data)
    }
    const value = decodeURI(window.location.pathname);
    const urlItems = value.split('/');
    useEffect(() => {
        getProductId();
        getProductDetails();
        getDefaultTags();
        if (query.get("item") == "Feedback") {
            getFeedbacks(0);
        } else if (query.get("item") == "Suggestion") {
            getSuggestions(0);
        } else if (query.get("item") == "Announcements") {
            getAnnouncements(0);
        } else {
            setItem("Ratings")
            getProductDetails();
        }
    }, [])
    const getProductId = async () => {
        let headers;
        if (localStorage.getItem('junkStore')) {
            headers = {
                'Accept': 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        } else {
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        let result = await axios.get(config.userUrl + `by/${urlItems[2]}/${urlItems[3]}/${urlItems[4]}`, { headers: headers })
            .then(function (response) {
                return response.data
            })
            .catch(function (error) {
                console.log("Error", error);
                setEmptyProduct(true)
            });
        if (result?.companyData?.[0]?.id) {
            setProductId(result.companyData[0].id)
        }
    };
    const ref = useRef(null);
    const feedback = () => {
        setItem("Feedback")
    }
    const suggestion = () => {
        setItem("Suggestion")
    }
    const announcements = () => {
        setItem("Announcements")
    }
    function hidePopover() {
        document.getElementById("test").style.display = "none";
    }
    function suggestionPopover() {
        setPopSuggestion(!popSuggestion)
    }
    function feedbackPopover() {
        setPopFeedback(!popFeedback)
    }
    const handleFeedback = () => {
        setItem("Feedback")
        getFeedbacks(0);
        setSelectedValue(0);
    }
    const handleSuggestion = () => {
        setItem("Suggestion")
        getSuggestions(0);
        setSelectedValue(0);
    }
    const handleComment = () => {
        setItem("Ratings")
    }
    const handleAnnouncements = () => {
        setItem("Announcements")
        getAnnouncements(0);
        setSelectedValue(0);
    }
    function deleteSuggestion(id) {
        setDeletePostSuggestion(!deletePostSuggestion)
        setSuggestionId(id.id)
    }
    function closeDeleteSuggestion() {
        setDeletePostSuggestion(false)
        setSuggestionId("")
    }
    const handleTags = (e, tag) => {
        const updated = defaultTags.map(data => (data.tag === tag) ?
            { ...data, isSelected: !data.isSelected } : data)
        setDefaultTags(updated)
        setSelectedTags(updated)
    }
    const getDefaultTags = () => {
        var headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getDefaultTag', { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    // for (let i = 0; i < response.data.getDefaultTag.length; i++) {
                    //     response.data.getDefaultTag[i].isSelected = false
                    // }
                    setDefaultTags(response.data.getDefaultTag)
                } else {
                    console.log("error");
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const getProductDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        axios.get(config.userUrl + "admin/getRatingCommentForProductByAdmin", { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    setCompanyName(response.data.companyName)
                    setProductName(response.data.productName)
                    setProductType(response.data.productType)
                    setProductSector(response.data.productSector)
                    setProductWebsite(response.data.productWebsite)
                    setProductIcon(response.data.productIcon)
                    setProductFollowers(response.data.userFollowers)
                    setProductId(response.data.productId)
                    setCompanyId(response.data.companyId)
                    setAvgRating(response.data.averageRating)
                    setNoOfPeopleGivenRating(response.data.ratingCount)
                    setRatingCommentDetails(response.data.ratingDetails)
                    setRatingCountArray(response.data.numberOfRating)

                    // setFollower(response.data.follower)
                    // setRating(response.data.rating[0].rating)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const postFeedback = () => {
        var headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let tagsData = [];
        tagsData = defaultTags.filter(item => (item.isSelected === true) ? item.tag : null)
        var tokenId = encrypt({ "companyId": companyId, "productId": productId })
        var payload = {
            "tokenId": tokenId,
            "title": feedbackTitle,
            "feedback": feedbackDescription,
            "tag": tagsData.map(item => item.tag),
        }
        if (feedbackTitle) {
            axios.post(config.userUrl + 'admin/createProductFeedbackByAdmin', payload, { headers: headers })
                .then(function (response) {
                    // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                    if (response.data.status === true) {
                        setAddFeedback(false)
                        getFeedbacks(0)
                    } else {
                        console.log("error", payload);
                    }
                })
                .catch(function (error) {
                    console.log("Error", error);
                });
        }
    };

    const postAnnouncement = () => {
        var headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let tagsData = [];
        tagsData = defaultTags.filter(item => (item.isSelected === true) ? item.tag : null)
        var tokenId = encrypt({ "companyId": companyId, "productId": productId })
        var payload = {
            "productId": productId,
            "companyId": companyId,
            "title": announcementTitle,
            "announcement": announcementDescription,
            "announcementImage": image,
            "tag": tagsData.map(item => item.tag),
        }
    };

    function changeUpvoteImage(id, val) {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        setUImg(!uImg)
        if (uImg) {
            setDImg(false)

        } else {
            // setDImg(false)
            setDImg(false)
        }
        var payload = {
            "feedbackId": id.id,
            "feedback": val,
            "userIdFeedbackBelongTo": id.feedbackByUserId
        }
        axios.post(config.userUrl + 'admin/likeOrDislikeFeedbackByAdmin', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    getFeedbacks(selectedValue);
                }
            })
            .catch(function (error) {
                console.log(error, payload)
            })
    }
    function changeDownvoteImage(id, val) {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        setDImg(!dImg)
        if (!dImg) {
            setUImg(false)
        } else {
            // setDImg(!dImg)
            setUImg(false)
            setDImg(false)
        }
        var payload = {
            "feedbackId": id.id,
            "feedback": val,
            "userIdFeedbackBelongTo": id.feedbackByUserId
        }
        axios.post(config.userUrl + 'admin/likeOrDislikeFeedbackByAdmin', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    getFeedbacks(selectedValue);
                }
            })
            .catch(function (error) {
                console.log(error, payload)
            })
    }
    const getFeedbacks = (eventKey) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let product = decrypt(localStorage.getItem('junk'))
        var payload = {
            "productId": product,
            "type": parseInt(eventKey)
        }
        axios.post(config.userUrl + 'admin/getFeedbackByProductIdByAdmin', payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    setFeedbackData(response.data.feedback)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const getSuggestions = (eventKey) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let product = decrypt(localStorage.getItem('junk'))
        var payload = {
            "productId": product,
            "type": parseInt(eventKey)
        }
        axios.post(config.userUrl + 'admin/getSuggestionByProductIdByAdmin', payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    setSuggestionData(response.data.suggestion)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    function deleteFeedback(id) {
        setDeletePost(!deletePost)
        setFeedId(id.id)
    }
    function closeDeleteFeedback() {
        setDeletePost(false)
        setFeedId("")
    }
    function pinFeedbackPost(id, val) {
        setPinFeedback(!pinFeedback)
        setFeedId(id.id)
        setPinValue(val)
        pinFeedbackByAdmin(id, val);
    }
    const pinFeedbackByAdmin = (data, val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "feedbackId": data.id,
            "pin": val
        }
        axios.post(config.userUrl + 'admin/productAdminFeedbackPinOrUnpin', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    getFeedbacks(selectedValue)
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    function closePinFeedback() {
        setPinFeedback(false)
        setFeedId("")
    }
    function saveFeedbackPost(id, val) {
        setSaveFeedback(!saveFeedback)
        setFeedId(id.id)
        setSaveValue(val)
        saveFeedbackByAdmin(id, val);
    }
    const saveFeedbackByAdmin = (data, val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "feedbackId": data.id,
            "feedbackGivenBy": data.feedbackByUserId
        }
        axios.post(config.userUrl + 'admin/saveFeedbackByAdmin', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    getFeedbacks(selectedValue)
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    function saveAnnouncementPost(id, val) {
        setSaveAnnouncement(!saveAnnouncement)
        setAnnouncementId(id.id)
        setSaveValue(val)
        saveAnnouncementByAdmin(id, val);
    }
    const saveAnnouncementByAdmin = (data, val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "announcementId": data.id,
            "announcementGivenBy": data.announcementByAdminId
        }
        axios.post(config.userUrl + 'admin/saveAnnouncementByAdmin', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log("success");
                    getAnnouncements(selectedValue)
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    function closeSaveFeedback() {
        setSaveFeedback(false)
        setFeedId("")
    }
    function saveSuggestionPost(id, val) {
        setSaveSuggestion(!saveSuggestion)
        setSuggestionId(id.id)
        setSaveValue(val)
        saveSuggestionByAdmin(id, val);
    }
    const saveSuggestionByAdmin = (data, val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "suggestionId": data.id,
            "suggestionGivenBy": data.suggestionByUserId
        }
        console.log("payload", payload)
        axios.post(config.userUrl + 'admin/saveSuggestionByAdmin', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log("success");
                    getSuggestions(selectedValue)
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    function hideFeedbackPost(id, val) {
        setHideFeedback(!hideFeedback)
        setFeedId(id.id)
        setHideValue(val)
        hideFeedbackByAdmin(id, val);
    }
    function reportFeedbackPost(id, val) {
        setReport(!report)
        setFeedId(id.id)
        setFeedbackByUserId(id.feedbackByUserId)
    }
    function reportSuggestionPost(id, val) {
        setReportSuggestion(!reportSuggestion)
        setSuggestionId(id.id)
        setSuggestionByUserId(id.suggestionByUserId)
        console.log("uuuuu", reportSuggestion, suggestionByUserId)

    }
    const hideFeedbackByAdmin = (data, val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "feedbackId": data.id,
            "hide": val
        }
        console.log("payload", payload)
        axios.post(config.userUrl + 'admin/productAdminFeedbackHideOrUnhide ', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log("success");
                    getFeedbacks(selectedValue)
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    function closeHideFeedback() {
        setHideFeedback(false)
        setFeedId("")
    }
    function pinSuggestionPost(id, val) {
        setPinSuggestion(!pinSuggestion)
        setSuggestionId(id.id)
        setPinValue(val)
        pinSuggestionByAdmin(id, val);
    }
    const pinSuggestionByAdmin = (data, val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "suggestionId": data.id,
            "pin": val
        }
        console.log("payload", payload)
        axios.post(config.userUrl + 'admin/productAdminSuggestionPinOrUnpin', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log("success");
                    getSuggestions(selectedValue)
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    function closePinSuggestion() {
        setPinSuggestion(false)
        setSuggestionId("")
    }
    function closeSaveSuggestion() {
        setSaveSuggestion(false)
        setSuggestionId("")
    }
    function closeSaveFeedback() {
        setSaveFeedback(false)
        setFeedId("")
    }
    function closeSaveAnnouncement() {
        setSaveAnnouncement(false)
        setAnnouncementId("")
    }
    function hideSuggestionPost(id, val) {
        setHideSuggestion(!hideSuggestion)
        setSuggestionId(id.id)
        setHideValue(val)
        hideSuggestionByAdmin(id, val);
    }
    const hideSuggestionByAdmin = (data, val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "suggestionId": data.id,
            "hide": val
        }
        console.log("payload", payload)
        axios.post(config.userUrl + 'admin/productAdminSuggestionHideOrUnhide', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log("success");
                    getSuggestions(selectedValue)
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    function closeHideSuggestion() {
        setHideSuggestion(false)
        setSuggestionId("")
    }
    function closeReportFeedback() {
        setFeedId("")
        setReport(false)
    }
    function closeReportSuggestion() {
        setSuggestionId("")
        setReportSuggestion(false)
    }
    function changeUpvoteImageSuggestion(id, val) {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        console.log("dataup", id.id)
        setUImg(!uImg)
        if (uImg) {
            setCount(count - 1)
            setDImg(false)

        } else {
            setCount(count + 1)
            // setDImg(false)
            setDImg(false)
        }
        var payload = {
            "suggestionId": id.id,
            "suggestion": val,
            "userIdSuggestionBelongTo": id.suggestionByUserId
        }
        console.log("upvote", payload)
        axios.post(config.userUrl + 'admin/likeOrDislikeSuggestionByAdmin', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    getSuggestions(selectedValue);
                    console.log("upvote")
                }
            })
            .catch(function (error) {
                console.log(error, payload)
            })
    }
    function changeDownvoteImageSuggestion(id, val) {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        console.log("data", id)
        setDImg(!dImg)
        if (!dImg) {
            setUImg(false)
            setCount(count - 1)
        } else {
            // setDImg(!dImg)
            setUImg(false)
            setDImg(false)
        }
        var payload = {
            "suggestionId": id.id,
            "suggestion": val,
            "userIdSuggestionBelongTo": id.suggestionByUserId
        }
        console.log("upvote", payload)
        axios.post(config.userUrl + 'admin/likeOrDislikeSuggestionByAdmin', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log("upvote")
                    getSuggestions(selectedValue);
                }
            })
            .catch(function (error) {
                console.log(error, payload)
            })
    }
    function changeUpvoteImageAnnouncement(id, val) {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        console.log("dataup", id.id)
        setUImg(!uImg)
        if (uImg) {
            setCount(count - 1)
            setDImg(false)

        } else {
            setCount(count + 1)
            // setDImg(false)
            setDImg(false)
        }
        var payload = {
            "announcementId": id.id,
            "announcement": val,
            "userIdAnnouncementBelongTo": id.announcementByAdminId
        }
        console.log("upvote", payload)
        axios.post(config.userUrl + 'admin/likeOrDislikeAnnouncementByAdmin', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    getAnnouncements(selectedValue);
                    console.log("upvote")
                }
            })
            .catch(function (error) {
                console.log(error, payload)
            })
    }
    function changeDownvoteImageAnnouncement(id, val) {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        console.log("data", id)
        setDImg(!dImg)
        if (!dImg) {
            setUImg(false)
            setCount(count - 1)
        } else {
            // setDImg(!dImg)
            setUImg(false)
            setDImg(false)
        }
        var payload = {
            "announcementId": id.id,
            "announcement": val,
            "userIdAnnouncementBelongTo": id.announcementByAdminId
        }
        console.log("upvote", payload)
        axios.post(config.userUrl + 'admin/likeOrDislikeAnnouncementByAdmin', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log("upvote")
                    getAnnouncements(selectedValue);
                }
            })
            .catch(function (error) {
                console.log(error, payload)
            })
    }
    function shareArticle(data, val) {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        if (navigator.share) {
            navigator.share({
                url: config.webUrl + `${data.productName}`,
            }).then(() => {
                console.log('Thanks for sharing!', data.title);
            }).catch(err => {
                console.log(
                    "Error while using Web share API:");
                console.log(err);
            });
        } else {
            console.log("Sorry! Your browser does not support Web Share API");
        }
        if (val == "feedback") {
            var payload = {
                "feedbackIdd": data.id,
                "userIdFeedbackBelongTo": data.feedbackByUserId
            }
            console.log("payload", payload)
            axios.post(config.userUrl + 'admin/shareFeedbackByAdmin', payload, { headers: headers })
                .then(function (response) {
                    if (response.data.status == true) {
                        setTimeout(() => {
                            getFeedbacks(selectedValue);
                        }, 1000);
                        console.log("feedback shared successfully");
                    } else {
                        console.log("Error", payload);
                    }
                })
                .catch(function (error) {
                    console.log("error", error, payload);
                });
        } else if (val == "suggestion") {
            var payload = {
                "suggestionId": data.id,
                "userIdSuggestionBelongTo": data.suggestionByUserId
            }
            console.log("payload", payload)
            axios.post(config.userUrl + 'admin/shareSuggestionByAdmin', payload, { headers: headers })
                .then(function (response) {
                    if (response.data.status == true) {
                        setTimeout(() => {
                            getSuggestions(selectedValue);
                        }, 1000)
                        console.log("suggestion shared successfully");
                    } else {
                        console.log("Error", payload);
                    }
                })
                .catch(function (error) {
                    console.log("error", error, payload);
                });
        } else {
            var payload = {
                "announcementId": data.id,
                "userIdAnnouncementBelongTo": data.announcementByAdminId
            }
            console.log("payload", payload)
            axios.post(config.userUrl + 'admin/shareAnnouncementByAdmin', payload, { headers: headers })
                .then(function (response) {
                    if (response.data.status == true) {
                        setTimeout(() => {
                            getAnnouncements(selectedValue);
                        }, 1000)
                        console.log("announcement shared successfully");
                    } else {
                        console.log("Error", payload);
                    }
                })
                .catch(function (error) {
                    console.log("error", error, payload);
                });
        }
    };
    // const getSuggestions = () => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "applicationType": "web"
    //     }
    //     axios.get(config.userUrl + 'user/getPendingSuggestion', { headers: headers })
    //         .then(function (response) {
    //             console.log("getFeedback", response);
    //             // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
    //             if (response.data.status === true) {
    //                 console.log("getFeedback successfull", response.data);
    //                 setSuggestionData(response.data.suggestion)
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("Error", error);
    //         });
    // };
    const getAnnouncements = (eventKey) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let product = decrypt(localStorage.getItem('junk'))
        var payload = {
            "productId": product,
            "type": parseInt(eventKey)
        }
        axios.post(config.userUrl + "admin/getAnnouncementByProductIdByAdmin", payload, { headers: headers })
            .then(function (response) {
                console.log("getAnnouncementByProductId");
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("getAnnouncementByProductId  successfull");
                    setAnnouncementData(response.data.announcement)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const togglePopup = (data) => {
        setPopup(!popup)
        setPopupData(data)
    }
    const toggleSuggestionPopup = (data) => {
        setSuggestionPop(!suggestionPop)
        setSuggestionPopData(data)
    }
    const toggleAnnouncementPopup = (data) => {
        setAnnouncementPop(!announcementPop)
        setAnnouncementPopData(data)
    }
    const onSelect = (eventKey) => {
        setSelectedValue((parseInt(eventKey)))
        if (item == "Feedback") {
            getFeedbacks(eventKey);
        } else if (item == "Suggestion") {
            getSuggestions(eventKey);
        } else if (item == "Announcements") {
            getAnnouncements(eventKey);
        } else {
            getProductDetails();
        }
        console.log("selectedValue", selectedValue)
    }
    const data = {
        labels: ["User(s)", "User(s)", "User(s)", "User(s)", "User(s)"],
        datasets: [
            {
                data: ratingCountArray.map((data) => data.count),
                borderColor: "#FF8B66",
                barThickness: 6,
                backgroundColor: ["#FF8B66"]
            }
        ]
    }
    const handleStatusChange = (e, data) => {
        console.log('selected option', e.target.value);
        setStatus(e.target.value)
        changeStatus(data.id)
    }
    const changeStatus = (id) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "suggestionId": id,
            "status": parseInt(status)
        }
        console.log("payload", payload)
        axios.post(config.userUrl + 'admin/productAdminSuggestionStatusEdit', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log("success");
                    getSuggestions(selectedValue)
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const onChangeImage = (data) => {
        setImage(data)
        console.log("s", data)
    }
    const closeImage = () => {
        setViewImage(false)
    }
    const openImage = (data) => {
        setViewImage(true)
        setPopupImage(data.feedbackImage)
    }
    const closeSuggestionImage = () => {
        setViewSuggestionImage(false)
    }
    const openSuggestionImage = (data) => {
        setViewSuggestionImage(true)
        setPopupSuggestionImage(data.suggestionImage)
    }
    const closeAnnouncementImage = () => {
        setViewAnnouncementImage(false)
    }
    const openAnnouncementImage = (data) => {
        setViewAnnouncementImage(true)
        setPopupAnnouncementImage(data.announcementImage)
    }
    const toggleShareFeedbackPopup = (data) => {
        setOpenShareFeedback(!openShareFeedback)
        setPopupData(data)
    }
    const toggleShareSuggestionPopup = (data) => {
        setOpenShareSuggestion(!openShareSuggestion)
        setSuggestionPopData(data)
    }
    const toggleShareAnnouncementPopup = (data) => {
        setOpenShareAnnouncement(!openShareAnnouncement)
        setAnnouncementPopData(data)
    }
    return (
        <div>
            {popup && <FeedbackComments openImage={openImage} closePopup={togglePopup} getAllFeedbacks={getFeedbacks} data={popupData} selectedValue={selectedValue} />}
            {suggestionPop && <SuggestionComments openSuggestionImage={openSuggestionImage} closePopup={toggleSuggestionPopup} getSuggestions={getSuggestions} data={suggestionPopData} selectedValue={selectedValue} />}
            {announcementPop && <AnnouncementComments openAnnouncementImage={openAnnouncementImage} closePopup={toggleAnnouncementPopup} getAnnouncements={getAnnouncements} data={announcementPopData} selectedValue={selectedValue} />}
            {deletePost && <DeleteFeedbackByAdmin closePopup={closeDeleteFeedback} getAllFeedbacks={getFeedbacks} data={feedId} selectedValue={selectedValue} />}
            {deletePostSuggestion && <DeleteSuggestionByAdmin closePopup={closeDeleteSuggestion} getSuggestions={getSuggestions} data={suggestionId} selectedValue={selectedValue} />}
            {pinFeedback && <PinFeedback closePopup={closePinFeedback} getAllFeedbacks={getFeedbacks} data={pinValue} selectedValue={selectedValue} />}
            {pinSuggestion && <PinFeedback closePopup={closePinSuggestion} getAllFeedbacks={getFeedbacks} data={pinValue} selectedValue={selectedValue} />}
            {saveFeedback && <SaveFeedback closePopup={closeSaveFeedback} getAllFeedbacks={getFeedbacks} selectedValue={selectedValue} />}
            {saveSuggestion && <SaveFeedback closePopup={closeSaveSuggestion} getAllFeedbacks={getFeedbacks} selectedValue={selectedValue} />}
            {saveAnnouncement && <SaveFeedback closePopup={closeSaveAnnouncement} selectedValue={selectedValue} />}
            {hideFeedback && <Hide closePopup={closeHideFeedback} getAllFeedbacks={getFeedbacks} data={hideValue} selectedValue={selectedValue} />}
            {hideSuggestion && <Hide closePopup={closeHideSuggestion} getAllFeedbacks={getFeedbacks} data={hideValue} selectedValue={selectedValue} />}
            {report && <ReportFeedbackByAdmin closePopup={closeReportFeedback} getAllFeedbacks={getFeedbacks} data={feedId} feedbackGivenBy={feedbackByUserId} selectedValue={selectedValue} />}
            {reportSuggestion && <ReportSuggestionByAdmin closePopup={closeReportSuggestion} getAllSuggestions={getSuggestions} data={suggestionId} suggestionGivenBy={suggestionByUserId} selectedValue={selectedValue} />}
            {viewImage && <ViewImage close={closeImage} image={popupImage} />}
            {viewSuggestionImage && <ViewSuggestionImage close={closeSuggestionImage} image={popupSuggestionImage} />}
            {viewAnnouncementImage && <ViewAnnouncementImage close={closeAnnouncementImage} image={popupAnnouncementImage} />}
            {openShareFeedback && <OpenShareFeedbackByAdmin closePopup={toggleShareFeedbackPopup} getAllFeedbacks={getFeedbacks} data={popupData} selectedValue={selectedValue} />}
            {openShareSuggestion && <OpenShareSuggestionByAdmin closePopup={toggleShareSuggestionPopup} getSuggestions={getSuggestions} data={suggestionPopData} selectedValue={selectedValue} />}
            {openShareAnnouncement && <OpenShareAnnouncementByAdmin closePopup={toggleShareAnnouncementPopup} getAnnouncements={getAnnouncements} data={announcementPopData} selectedValue={selectedValue} />}
            <NavBar />
            <div id="background-admin">
                <img src={background} style={{ marginTop: "3rem", width: "100%" }} />
                <div className="usersfeed-l-r">

                    {(localStorage.getItem('role') == "admin") || (localStorage.getItem('role') == "subAdmin") ?
                        <Row className="row-width-company">
                            <Col md={8}>
                                <Card className="border-none">
                                    <div className="card-padding">
                                        {/* <Row style={{ width: "100%" }} className="no-gutters">
                                            <Col md={3}>
                                                <img src={config.webUrl + productIcon} style={{ marginTop: "6px", width: "140px", height: "142px" }} />
                                            </Col>
                                            <Col md={9}>
                                                <div><span className="standalone">{productName}</span> <span className="productType">({productType == 1 ? "Standalone" : "Web App"})</span></div>
                                                <div className="companyName">By {companyName}</div>
                                                <div ><a href={productWebsite} target='_blank' className="desc">Website: {productWebsite} <i class="fa fa-external-link" aria-hidden="true"></i></a></div>
                                                <div className="mt-2 desc">Product Sector: {productSector == 1 ? "Development Tool" : (productSector == 2 ? "Ui/Ux Tool" : "3D")}</div>
                                                <div className="row">
                                                    <Col md={8}>
                                                        <div className="mt-1 mb-1 followers-sm"> {productFollowers} Followers</div>
                                                    </Col>
                                                    <Col md={4}>
                                                        <div style={{ float: "right", display: "flex" }}><span className="ml-5 mt-1 followers-sm">({noOfPeopleGivenRating}) </span><span className="followers">&nbsp;{avgRating}</span></div><br />
                                                      
                                                        <div style={{ float: "right", display: "flex" }}>
                                                            <div style={{ backgroundColor: "#E6570E", width: `${((Math.round(avgRating * 10) / 10) / 5) * 100}%` }}> <img src={ratingImg} style={{ width: "135px" }} /></div>
                                                            
                                                        </div>
                                                    </Col>
                                                </div>
                                            </Col>
                                        </Row> */}
                                        <Row style={{ width: "100%", padding: "20px" }} className="no-gutters">
                                            <Col md={3}>
                                                <img src={productIcon} style={{ marginTop: "6px", width: "126px", height: "128px" }} />
                                            </Col>
                                            <Col md={6}>
                                                <div><span className="standalone">{productName}</span> <span className="productType">({productType == 1 ? "Standalone" : "Web App"})</span></div>
                                                <div className="companyName">By {companyName}</div>
                                                <div ><span className="desc">Website: </span><a href={productWebsite} target='_blank' className="productWebsite-link">{productWebsite} <i class="fa fa-external-link" aria-hidden="true"></i></a></div>
                                                <div className="mt-1 desc">Product Sector: {productSector == 1 ? "Development Tool" : (productSector == 2 ? "Ui/Ux Tool" : "3D")}</div>
                                                <div className="mt-1 mb-1 followers-sm"> {productFollowers} Followers</div>
                                            </Col>
                                            <Col md={3}>
                                                <div >
                                                    <div>
                                                        <Bar data={data} className="ml-3"
                                                            options={{
                                                                scales: {
                                                                    x: {
                                                                        ticks: {
                                                                            display: false,
                                                                        },
                                                                        grid: {
                                                                            display: false,
                                                                        },
                                                                    },

                                                                    y: {
                                                                        grid: {
                                                                            display: false,
                                                                        },
                                                                    },
                                                                },
                                                                plugins: {
                                                                    legend: { display: false }
                                                                },
                                                                tooltips: {
                                                                    callbacks: {
                                                                        label: function (tooltipItem) {
                                                                            return tooltipItem.yLabel;
                                                                        }
                                                                    }
                                                                }

                                                            }
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{ position: "absolute", right: "2px" }}>
                                                    <div style={{ backgroundColor: "#E6570E", marginTop: "1px", width: `${((Math.round(avgRating * 10) / 10) / 5) * 100}%` }}> <img src={ratingImg} style={{ width: "140px" }} /></div>
                                                </div><br /><div className="mt-2"></div>
                                                <div style={{ float: "right", display: "flex" }}><span className="ml-5 followers">{avgRating}</span><span className="mt-1 followers-sm-c">({noOfPeopleGivenRating}) </span></div>

                                            </Col>
                                        </Row>

                                    </div>
                                </Card>
                                <div>
                                    <div className="row">
                                        <Col md={12}>
                                            <div className="row mt-2">
                                                <Col md={2}>
                                                    <Card className={item == "Ratings" ? "border-none feed-card f-color" : "border-none feed-card-a f-color-g"} onClick={handleComment}>Ratings</Card>
                                                </Col>
                                                <Col md={2}>
                                                    <Card className={item == "Feedback" ? "border-none feed-card f-color" : "border-none feed-card-a f-color-g"} onClick={handleFeedback}>Feddback</Card>
                                                </Col>
                                                <Col md={3}>
                                                    <Card className={item == "Suggestion" ? "border-none feed-card f-color" : "border-none feed-card-a f-color-g"} onClick={handleSuggestion}>Suggestion</Card>
                                                </Col>
                                                <Col md={3}>
                                                    <Card className={item == "Announcements" ? "border-none feed-card f-color" : "border-none feed-card-a f-color-g"} onClick={handleAnnouncements}>Announcements</Card>
                                                </Col>
                                                <Col md={2}>
                                                    {item == "Ratings" ? null :
                                                        <Dropdown onSelect={onSelect}>
                                                            <Dropdown.Toggle variant="default" className="abc feed-card-a" style={{ float: "right" }} >
                                                                <img src={selectedValue == 1 ? top : (selectedValue == 2 ? newData : trending)} style={{ width: "20px" }} />&nbsp; &nbsp;<b><i class="fa fa-angle-down"></i></b>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item eventKey={0} className="d-text"><img src={trending} style={{ width: "20px" }} /> &nbsp;Trending</Dropdown.Item>
                                                                <Dropdown.Item eventKey={2} className="d-text"><img src={newData} style={{ width: "20px" }} /> &nbsp;New</Dropdown.Item>
                                                                <Dropdown.Item eventKey={1} className="d-text"><img src={top} style={{ width: "20px" }} /> &nbsp;Top</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    }
                                                </Col>
                                            </div>
                                        </Col>
                                    </div>
                                </div>

                                {item == "Feedback" ?
                                    <div >
                                        {feedbackData.length == 0 ? <Card className="mt-3 no_pages"><center className="no-details-text"><img src={downvoteb} className="no-pages-img" /><br />No Feddbacks</center></Card> :
                                            <div className="mt-1 feddback-admin">
                                                {feedbackData.map((data, i) => (
                                                    <div className="mt-1">
                                                        <Card className="border-none">
                                                            <div className="row">
                                                                <Col md={2}>
                                                                    <Card className="ml-2 mt-1 mb-2 card-padding-sm">
                                                                        <center className="cursor"> {data.vote == 1 ? <img src={upvote} onClick={(e, i) => changeUpvoteImage(data, 2)} style={{ width: "55px" }} /> : <img src={upvoteb} onClick={(e) => changeUpvoteImage(data, 1)} style={{ width: "45px" }} />}</center><br />
                                                                        <center className="likes">{data.totallike == null ? 0 : data.totallike}</center><br />
                                                                        <center className="cursor"> {data.vote == -1 ? <img src={downvote} onClick={(e, i) => changeDownvoteImage(data, 2)} className="mt-2" style={{ width: "55px" }} /> : <img src={downvoteb} onClick={(e) => changeDownvoteImage(data, 0)} style={{ width: "45px" }} />}</center>
                                                                    </Card>
                                                                </Col>
                                                                <Col md={10}>
                                                                    <div className="ml-2 mt-2 mb-2">
                                                                        <div className="card-padding">

                                                                            <Dropdown >
                                                                                <Dropdown.Toggle variant="default" style={{ float: "right" }} className="abc feed-card-a drop-Down-a">
                                                                                    <img src={dots} style={{ width: "3px" }} />
                                                                                </Dropdown.Toggle>

                                                                                <Dropdown.Menu className="border-none">
                                                                                    <Dropdown.Item className="d-text">{data.pin ? <span onClick={(e, i) => pinFeedbackPost(data, false)}><img src={pin} style={{ width: "20px" }} /> &nbsp;Unpin</span> : <span onClick={(e, i) => pinFeedbackPost(data, true)}><img src={pin} style={{ width: "20px" }} /> &nbsp;Pin</span>}</Dropdown.Item>
                                                                                    <Dropdown.Item className="d-text">{data.hide ? <span onClick={(e, i) => hideFeedbackPost(data, false)}><img src={hide} style={{ width: "20px" }} /> &nbsp;Unhide</span> : <span onClick={(e, i) => hideFeedbackPost(data, true)}><img src={hide} style={{ width: "20px" }} /> &nbsp;Hide</span>}</Dropdown.Item>
                                                                                    <Dropdown.Item className="d-text">{data.save ? <span ><img src={saveIcon} style={{ width: "20px" }} /> &nbsp;Saved</span> : <span onClick={(e, i) => saveFeedbackPost(data, true)}><img src={saveIcon} style={{ width: "20px" }} /> &nbsp;Save</span>}</Dropdown.Item>
                                                                                    <Dropdown.Item className="d-text">{data.report ? <span ><img src={reportIcon} style={{ width: "20px" }} /> &nbsp;Reported</span> : <span onClick={(e, i) => reportFeedbackPost(data, true)}><img src={reportIcon} style={{ width: "20px" }} /> &nbsp;Report</span>}</Dropdown.Item>
                                                                                    <Dropdown.Item className="d-text" style={{ color: "red" }}>{data.delete ? <span><img src={imgDel} style={{ width: "20px" }} /> &nbsp;Deleted</span> : <span onClick={(e, i) => deleteFeedback(data)}><img src={imgDel} style={{ width: "20px" }} /> &nbsp;Delete</span>}</Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                            <div onClick={() => togglePopup(data)} className="cursor">
                                                                                <h6 className="t-today">{data.title}</h6>
                                                                                <div className="posted mb-1">{data.pin ? <div><img src={pin} style={{ width: "11px", marginTop: "-2px" }} /> pinned</div> : null}</div>
                                                                                <div className="posted mb-1">{data.hide ? <div><img src={hide} style={{ width: "11px", marginTop: "-2px" }} /> Hidden post</div> : null}</div>

                                                                                <div className="cursor">
                                                                                    <div className="posted">Posted by {data.userName == null ? "anonymous" : data.userName} &nbsp;&nbsp;{data.updatedAt.split("T")[0].split("-").reverse().join("-")}</div>

                                                                                    <div className="title" dangerouslySetInnerHTML={{
                                                                                        __html: data.feedback
                                                                                    }} />
                                                                                    {
                                                                                        data?.tag?.length
                                                                                            ?
                                                                                            <div className='flex flex-wrap gap-x-3 gap-y-3 py-3'>
                                                                                                {data?.tag?.map(tag => <Tag text={tag} />
                                                                                                )}
                                                                                            </div>
                                                                                            : ''
                                                                                    }
                                                                                    <div className="my-2"><img src={data.feedbackImage} style={{ width: "150px" }} /></div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="mt-2"><span onClick={() => togglePopup(data)}><img src={comment} style={{ width: "20px", cursor: "pointer" }} /> <span className="comment">{data.commentCount == null ? 0 : data.commentCount} Comments</span></span><span className="ml-5" onClick={() => toggleShareFeedbackPopup(data)}><img src={share} style={{ width: "20px", cursor: "pointer" }} /> <span className="comment">{data.shareCount == null ? 0 : data.shareCount} Shares</span></span></div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                )
                                                )}
                                            </div>
                                        }
                                    </div> :
                                    (item == "Suggestion") ?
                                        <div>
                                            {suggestionData.length == 0 ? <Card className="mt-3 no_pages"><center className="no-details-text"><img src={downvoteb} className="no-pages-img" /><br />No Suggestions</center></Card> :
                                                <div className="mt-1 feddback-admin">
                                                    {suggestionData.map((data, i) => (
                                                        <div className="mt-1">
                                                            <Card className={data.status == 4 ? "suggestion-border-accepted" : (data.status == 2 ? "suggestion-border-pending" : (data.status == 3 ? "suggestion-border-rejected" : "suggestion-border"))}>
                                                                <div className="row">
                                                                    <Col md={2}>
                                                                        <Card className="ml-2 mt-1 mb-2 card-padding-sm">
                                                                            <center className="cursor"> {data.vote == 1 ? <img src={upvote} onClick={(e, i) => changeUpvoteImageSuggestion(data, 2)} style={{ width: "55px" }} /> : <img src={upvoteb} onClick={(e) => changeUpvoteImageSuggestion(data, 1)} style={{ width: "45px" }} />}</center><br />
                                                                            <center className="likes">{data.totallike == null ? 0 : data.totallike}</center><br />
                                                                            <center className="cursor"> {data.vote == -1 ? <img src={downvote} onClick={(e, i) => changeDownvoteImageSuggestion(data, 2)} style={{ width: "55px" }} /> : <img src={downvoteb} onClick={(e) => changeDownvoteImageSuggestion(data, 0)} style={{ width: "45px" }} />}</center>
                                                                        </Card>
                                                                    </Col>
                                                                    <Col md={10}>
                                                                        <div className="ml-2 mt-2 mb-2">
                                                                            <div className="card-padding">
                                                                                <Dropdown >
                                                                                    <Dropdown.Toggle variant="default" style={{ float: "right" }} className="abc feed-card-a drop-Down-a">
                                                                                        <img src={dots} style={{ width: "3px" }} />
                                                                                    </Dropdown.Toggle>

                                                                                    <Dropdown.Menu>
                                                                                        <Dropdown.Item className="d-text">{data.pin ? <span onClick={(e, i) => pinSuggestionPost(data, false)}><img src={pin} style={{ width: "20px" }} /> &nbsp;Unpin</span> : <span onClick={(e, i) => pinSuggestionPost(data, true)}><img src={pin} style={{ width: "20px" }} /> &nbsp;Pin</span>}</Dropdown.Item>
                                                                                        <Dropdown.Item className="d-text">{data.hide ? <span onClick={(e, i) => hideSuggestionPost(data, false)}><img src={hide} style={{ width: "20px" }} /> &nbsp;Unhide</span> : <span onClick={(e, i) => hideSuggestionPost(data, true)}><img src={hide} style={{ width: "20px" }} /> &nbsp;Hide</span>}</Dropdown.Item>
                                                                                        <Dropdown.Item className="d-text">{data.save ? <span ><img src={saveIcon} style={{ width: "20px" }} /> &nbsp;Saved</span> : <span onClick={(e, i) => saveSuggestionPost(data, true)}><img src={saveIcon} style={{ width: "20px" }} /> &nbsp;Save</span>}</Dropdown.Item>
                                                                                        {/* <Dropdown.Item className="d-text">{data.status==1 ? <span ><img src={statusIcon} style={{ width: "20px" }} /> &nbsp;Status</span> : <span onClick={(e, i) => saveSuggestionPost(data, true)}><img src={statusIcon} style={{ width: "20px" }} /> &nbsp;Status</span>}</Dropdown.Item> */}
                                                                                        <Dropdown.Item className="d-text">{data.report ? <span ><img src={reportIcon} style={{ width: "20px" }} /> &nbsp;Reported</span> : <span onClick={(e, i) => reportSuggestionPost(data, true)}><img src={reportIcon} style={{ width: "20px" }} /> &nbsp;Report</span>}</Dropdown.Item>
                                                                                        <Dropdown.Item className="d-text" style={{ color: "red" }}>{data.delete ? <span><img src={imgDel} style={{ width: "20px" }} /> &nbsp;Deleted</span> : <span onClick={(e, i) => deleteSuggestion(data)}><img src={imgDel} style={{ width: "20px" }} /> &nbsp;Delete</span>}</Dropdown.Item>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                                <div onClick={() => toggleSuggestionPopup(data)} className="cursor">
                                                                                    <h6 className="t-today">{data.title}</h6>
                                                                                    <div className="posted mb-1">{data.delete ? <div><img src={imgDel} style={{ width: "11px", marginTop: "-2px" }} /> deleted</div> : null}</div>
                                                                                    <div className="posted">Posted by {data.userName == null ? "anonymous" : data.userName}&nbsp;&nbsp;{data.updatedAt.split("T")[0].split("-").reverse().join("-")}</div>
                                                                                    <div className="posted mb-1">{data.pin ? <div><img src={pin} style={{ width: "11px", marginTop: "-2px" }} /> pinned</div> : null}</div>
                                                                                    <div className="posted mb-1">{data.hide ? <div><img src={hide} style={{ width: "11px", marginTop: "-2px" }} /> Hidden post</div> : null}</div>

                                                                                    <div className="title" dangerouslySetInnerHTML={{
                                                                                        __html: data.suggestion
                                                                                    }} />
                                                                                    {
                                                                                        data?.tag?.length
                                                                                            ?
                                                                                            <div className='flex flex-wrap gap-x-3 gap-y-3 py-3'>
                                                                                                {data?.tag?.map(tag => <Tag text={tag} />
                                                                                                )}
                                                                                            </div>
                                                                                            : ''
                                                                                    }
                                                                                    <div className="my-2"><img src={data.suggestionImage} style={{ width: "150px" }} /></div>
                                                                                </div>
                                                                                <div className="mt-2"><span onClick={() => toggleSuggestionPopup(data)}><img src={comment} style={{ width: "20px", cursor: "pointer" }} /> <span className="comment">{data.commentCount == null ? 0 : data.commentCount} Comments</span></span><span className="ml-5" onClick={() => toggleShareSuggestionPopup(data)}><img src={share} style={{ width: "20px", cursor: "pointer" }} /> <span className="comment">{data.shareCount == null ? 0 : data.shareCount} Shares</span></span>
                                                                                    <Dropdown >
                                                                                        <Dropdown.Toggle variant="default" style={{ float: "right" }} className="abc status-drop-Down-a">
                                                                                            {data.status == 4 ? "Accepted" : (data.status == 2 ? "Indiscussion" : (data.status == 3 ? "Rejected" : "None"))}<i class="fa fa-pencil ml-1" aria-hidden="true" ></i>
                                                                                        </Dropdown.Toggle>
                                                                                        <Dropdown.Menu>
                                                                                            <Dropdown.Item className="d-text"><input type="radio" value="4" checked={data.status == 4} onChange={(e) => handleStatusChange(e, data)} />Accepted</Dropdown.Item>
                                                                                            <Dropdown.Item className="d-text"><input type="radio" value="2" checked={data.status == 2} onChange={(e) => handleStatusChange(e, data)} />Indiscussion</Dropdown.Item>
                                                                                            <Dropdown.Item className="d-text"><input type="radio" value="3" checked={data.status == 3} onChange={(e) => handleStatusChange(e, data)} />Rejected</Dropdown.Item>
                                                                                            <Dropdown.Item className="d-text"><input type="radio" value="1" checked={data.status == 1} onChange={(e) => handleStatusChange(e, data)} />None</Dropdown.Item>
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown></div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </div>
                                                            </Card>
                                                        </div>
                                                    )
                                                    )}
                                                </div>
                                            }
                                        </div> : (item == "Announcements") ?
                                            <div>
                                                <Card className="mt-2 border-none" onClick={(e) => setAddAnnouncement(true)}>
                                                    <div className="ml-3 mt-1 mb-1 mr-4 no-details-text" style={{ cursor: "pointer" }}>
                                                        <div className="row">
                                                            <Col md={1}><img src={createicon} style={{ width: "40px", padding: "5px" }} /></Col>
                                                            <Col md={9}><div className="create-post"><span className="ml-4">Add Anouncement</span></div></Col>
                                                            <Col md={1}><img src={imageicon} style={{ width: "30px", marginTop: "2px", padding: "5px" }} /></Col>
                                                            <Col md={1}><img src={linkicon} style={{ width: "30px", padding: "5px", marginTop: "2px" }} /></Col>
                                                        </div>
                                                        {/* <span><img src={createicon} style={{ width: "30px" }} /><span className="ml-3 create-post"><span>Create Post</span></span><span style={{ float: "right" }}><img src={imageicon} style={{ width: "30px", padding: "5px" }} /><img src={linkicon} style={{ width: "30px", padding: "5px" }} /></span></span> */}
                                                    </div>
                                                </Card>
                                                <Modal
                                                    size="xl"
                                                    show={addAnnouncement}
                                                    onHide={() => setAddAnnouncement(false)}
                                                    aria-labelledby="contained-modal-title-vcenter"
                                                    centered
                                                >
                                                    <Modal.Header closeButton>
                                                        <Modal.Title >
                                                            <h5 style={{ marginTop: "5px", fontFamily: "poppins" }}>&nbsp;Create Announcement</h5>
                                                        </Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className="mt-1 mb-2">
                                                            <Form>
                                                                <Form.Control style={{ fontFamily: "poppins" }} type="text"
                                                                    name="announcementTitle" value={announcementTitle}
                                                                    placeholder="Title"
                                                                    onChange={(e) => setAnnouncementTitle(e.target.value)} />

                                                                <textarea
                                                                    className="mt-3 form-control"
                                                                    id="exampleFormControlTextarea1"
                                                                    style={{ fontFamily: "poppins", resize: "none" }}
                                                                    rows="5"
                                                                    maxLength={280}
                                                                    value={announcementDescription}
                                                                    name="announcementDescription"
                                                                    onChange={(e) => setAnnouncementDescription(e.target.value)}
                                                                    placeholder="Description"
                                                                />
                                                                {/* <Editor onChange={onChange} /> */}
                                                                <div style={{ fontFamily: "poppins", fontSize: "12px" }}>{announcementDescription.length}/280 characters</div>
                                                                <div className="mt-3"> <AddImage onChangeImage={onChangeImage} /></div><br />
                                                                {defaultTags.map((data, i) => (
                                                                    <Button key={i} className="abc family"
                                                                        variant="default" size="sm" value={data.tag}
                                                                        onClick={(e) => handleTags(e, data.tag)}>
                                                                        <div> {data.isSelected ? <div><img src={fillTag} style={data.tag.length >= 15 ? { width: "200px", height: "35px" } : { width: "150px", height: "35px" }} /><div className={data.tag.length >= 15 ? "tag-data-fill" : "tag-data-fill_"} >{data.tag}</div></div> :
                                                                            <div><img src={emptyTag} style={data.tag.length >= 15 ? { width: "200px", height: "35px" } : { width: "150px", height: "35px" }} /><div className={data.tag.length >= 15 ? "tag-data-empty" : "tag-data-empty_"} >{data.tag}</div></div>}  </div>
                                                                    </Button>
                                                                ))}
                                                            </Form>
                                                            <div className="mt-3 suggestion-box-text" style={{ float: "left" }}>Post guidelines <span style={{ color: "#E6570E" }}>read here.</span></div>
                                                            <br />
                                                            <OverlayTrigger style={(announcementTitle.length != 0) && (announcementDescription.length != 0) ? { display: "none" } : { cursor: "pointer" }}
                                                                placement="left"
                                                                overlay={
                                                                    <Tooltip id={`tooltip-top`} style={(announcementTitle.length != 0) && (announcementDescription.length != 0) ? { display: "none" } : { cursor: "pointer" }}>
                                                                        {(announcementTitle.length != 0) && (announcementDescription.length != 0) ?
                                                                            <div style={{ display: "none" }}>

                                                                            </div>
                                                                            : <div className='tool-text'>
                                                                                Please add title and description
                                                                            </div>
                                                                        }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                {(announcementTitle.length != 0) && (announcementDescription.length != 0) ?
                                                                    <Button
                                                                        variant="default" size="md"
                                                                        type="submit"
                                                                        style={{ float: "right" }}
                                                                        className="abc submit-button"
                                                                        onClick={postAnnouncement}

                                                                    >
                                                                        submit
                                                                    </Button> :
                                                                    <Button
                                                                        variant="default" size="md"
                                                                        type="submit"
                                                                        style={{ float: "right" }}
                                                                        className="abc submit-button-grey"
                                                                    >
                                                                        submit
                                                                    </Button>
                                                                }
                                                            </OverlayTrigger>
                                                        </div>
                                                    </Modal.Body>
                                                </Modal>
                                                {announcementData.length == 0 ? <Card className="mt-3 no_pages"><center className="no-details-text"><img src={downvoteb} className="no-pages-img" /><br />No Announcements</center></Card> :
                                                    <div className="mt-1 feddback-admin-announcement">
                                                        {announcementData.map((data, i) => (
                                                            <div className="mt-1">
                                                                <Card className="border-none">
                                                                    <div className="row">
                                                                        <Col md={2}>
                                                                            <Card className="ml-2 mt-1 mb-2 card-padding-sm">
                                                                                <center className="cursor"> {data.vote == 1 ? <img src={upvote} onClick={(e, i) => changeUpvoteImageAnnouncement(data, 2)} style={{ width: "55px" }} /> : <img src={upvoteb} onClick={(e) => changeUpvoteImageAnnouncement(data, 1)} style={{ width: "45px" }} />}</center><br />
                                                                                <center className="likes">{data.totallike == null ? 0 : data.totallike}</center><br />
                                                                                <center className="cursor"> {data.vote == -1 ? <img src={downvote} onClick={(e, i) => changeDownvoteImageAnnouncement(data, 2)} style={{ width: "55px" }} /> : <img src={downvoteb} onClick={(e) => changeDownvoteImageAnnouncement(data, 0)} style={{ width: "45px" }} />}</center>
                                                                            </Card>
                                                                        </Col>
                                                                        <Col md={10}>
                                                                            <div className="ml-2 mt-2 mb-2">
                                                                                <div className="card-padding">
                                                                                    <Dropdown >
                                                                                        <Dropdown.Toggle variant="default" style={{ float: "right" }} className="abc feed-card-a drop-Down-a">
                                                                                            <img src={dots} style={{ width: "3px" }} />
                                                                                        </Dropdown.Toggle>

                                                                                        <Dropdown.Menu>

                                                                                            <Dropdown.Item className="d-text">{data.save ? <span ><img src={saveIcon} style={{ width: "20px" }} /> &nbsp;Saved</span> : <span onClick={(e, i) => saveAnnouncementPost(data, true)}><img src={saveIcon} style={{ width: "20px" }} /> &nbsp;Save</span>}</Dropdown.Item>
                                                                                            {/* <Dropdown.Item className="d-text">{data.pin ? <span onClick={(e, i) => pinFeedbackPost(data, false)}><img src={pin} style={{ width: "20px" }} /> &nbsp;Unpin</span> : <span onClick={(e, i) => pinFeedbackPost(data, true)}><img src={pin} style={{ width: "20px" }} /> &nbsp;Pin</span>}</Dropdown.Item>
                                                                                            <Dropdown.Item className="d-text">{data.hide ? <span onClick={(e, i) => hideFeedbackPost(data, false)}><img src={hide} style={{ width: "20px" }} /> &nbsp;Unhide</span> : <span onClick={(e, i) => hideFeedbackPost(data, true)}><img src={hide} style={{ width: "20px" }} /> &nbsp;Hide</span>}</Dropdown.Item>
                                                                                            <Dropdown.Item className="d-text">{data.report ? <span ><img src={reportIcon} style={{ width: "20px" }} /> &nbsp;Reported</span> : <span onClick={(e, i) => reportFeedbackPost(data, true)}><img src={reportIcon} style={{ width: "20px" }} /> &nbsp;Report</span>}</Dropdown.Item>
                                                                                            <Dropdown.Item className="d-text" style={{ color: "red" }}>{data.delete ? <span><img src={imgDel} style={{ width: "20px" }} /> &nbsp;Deleted</span> : <span onClick={(e, i) => deleteFeedback(data)}><img src={imgDel} style={{ width: "20px" }} /> &nbsp;Delete</span>}</Dropdown.Item> */}
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                    <div onClick={() => toggleAnnouncementPopup(data)} className="cursor">
                                                                                        <h6 className="t-today">{data.title}</h6>
                                                                                        <div className="posted">Posted by {data.userName == null ? "anonymous" : data.userName}&nbsp;&nbsp;{data.updatedAt.split("T")[0].split("-").reverse().join("-")}</div>


                                                                                        <div className="title" dangerouslySetInnerHTML={{
                                                                                            __html: data.announcement
                                                                                        }} />
                                                                                        {
                                                                                            data?.tag?.length
                                                                                                ?
                                                                                                <div className='flex flex-wrap gap-x-3 gap-y-3 py-3'>
                                                                                                    {data?.tag?.map(tag => <Tag text={tag} />
                                                                                                    )}
                                                                                                </div>
                                                                                                : ''
                                                                                        }
                                                                                        <div className="my-2"><img src={data.announcementImage} style={{ width: "150px" }} /></div>
                                                                                    </div>
                                                                                    <div className="mt-2"><span className="ml-1 cursor" onClick={() => toggleShareAnnouncementPopup(data)}><img src={share} style={{ width: "20px", cursor: "pointer" }} /> <span className="comment">{data.shareCount == null ? 0 : data.shareCount} Shares</span></span></div>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </div>
                                                                </Card>
                                                            </div>
                                                        )
                                                        )}
                                                    </div>
                                                }
                                            </div> :
                                            <div>
                                                {ratingCommentDetails.length == 0 ? <center>No Ratings</center> :
                                                    <div className="mt-1 feddback-admin">
                                                        {ratingCommentDetails.map((data, i) => (
                                                            <div className="mt-2">
                                                                {data.comment == "" ? "" :
                                                                    <Card className="border-none">
                                                                        <div className="row">
                                                                            {/* <Col md={2}>
                                                                    <Card className="ml-2 mt-2 mb-2 card-padding-sm">
                                                                        <center> {uImg ? <img src={upvote} onClick={changeUpvoteImage} style={{ width: "40px" }} /> : <img src={upvoteb} onClick={changeUpvoteImage} style={{ width: "40px" }} />}</center>
                                                                        <center className="mt-1 likes">{count}</center>
                                                                        <center> {dImg ? <img src={downvoteb} onClick={changeUpvoteImage} className="mt-2" style={{ width: "40px" }} /> : <img src={downvote} onClick={changeUpvoteImage} className="mt-2" style={{ width: "40px" }} />}</center>
                                                                    </Card>
                                                                </Col> */}
                                                                            <Col md={12}>
                                                                                <div className="ml-2 mt-2 mb-3">
                                                                                    {/* <div className="card-padding">
                                                                            <h6 className="t-today">{data.comment}</h6>
                                                                            <div className="posted">Posted by {data.userName == null ? "anonymous" : data.userName}&nbsp;&nbsp;{data.updatedAt.split("T")[0].split("-").reverse().join("-")}</div>
                                                                            <div className="mt-1 desc">{data.rating}</div>
                                                                        </div> */}
                                                                                    <div style={{ width: "100%" }} className="row no-gutters">
                                                                                        <div className="col-md-9">
                                                                                            <div className="c-rating productName">{data.comment}</div>
                                                                                        </div>
                                                                                        <div className="col-md-3">
                                                                                            <span style={{ position: "absolute", right: "10px" }}><span><img src={rating1} style={data.rating >= 1 ? { backgroundColor: "#E6570E", marginRight: "1px", width: "25px" } : { width: "25px", marginRight: "1px" }} /><img src={rating2} style={data.rating >= 2 ? { backgroundColor: "#E6570E", width: "25px", marginRight: "1px" } : { width: "25px", marginRight: "1px" }} /><img src={rating3} style={data.rating >= 3 ? { backgroundColor: "#E6570E", width: "25px", marginRight: "1px" } : { width: "25px", marginRight: "1px" }} /><img src={rating4} style={data.rating >= 4 ? { backgroundColor: "#E6570E", width: "25px", marginRight: "1px" } : { width: "25px", marginRight: "1px" }} /><img src={rating5} style={data.rating == 5 ? { backgroundColor: "#E6570E", width: "25px" } : { width: "25px" }} /></span></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <div style={{display:"flex"}} className="t-today productName"><div className="r-comment">{data.comment}</div><span style={{ alignItems:"right" }}><span><img src={rating1} style={data.rating >= 1 ? { backgroundColor: "#E6570E", marginRight: "1px", width: "25px" } : { width: "25px", marginRight: "1px" }} /><img src={rating2} style={data.rating >= 2 ? { backgroundColor: "#E6570E", width: "25px", marginRight: "1px" } : { width: "25px", marginRight: "1px" }} /><img src={rating3} style={data.rating >= 3 ? { backgroundColor: "#E6570E", width: "25px", marginRight: "1px" } : { width: "25px", marginRight: "1px" }} /><img src={rating4} style={data.rating >= 4 ? { backgroundColor: "#E6570E", width: "25px", marginRight: "1px" } : { width: "25px", marginRight: "1px" }} /><img src={rating5} style={data.rating == 5 ? { backgroundColor: "#E6570E", width: "25px" } : { width: "25px" }} /></span></span></div>
                                                                                <div className="mt-1 posted">Posted by {data.userName == null ? "anonymous" : data.userName} &nbsp;&nbsp;{data.createdAt.split("T")[0].split("-").reverse().join("-")}</div> */}
                                                                                    <div className="mt-1 desc">{data.comment}</div>
                                                                                </div>
                                                                            </Col>
                                                                        </div>
                                                                    </Card>
                                                                }
                                                            </div>
                                                        )
                                                        )}</div>
                                                }
                                            </div>
                                }
                            </Col>

                            <Col md={4}>
                                <Card className="border-none">
                                    <div className="card-padding">
                                        <h6 className="mt-2 t-tags">Greyffiti's Tags</h6>
                                        {/* {Tags.map((data, i) => (
                                        <div>&nbsp;&nbsp;&nbsp;<Button id="talkbubble1" variant="default" className="tags_btn"><span class="circle"></span>{data.tags[0]}</Button>
                                            &nbsp;&nbsp;&nbsp; &nbsp;<Button id="talkbubble1" variant="default" className="tags_btn"><span class="circle"></span>{data.tags[1]}</Button>
                                            <Button id="talkbubble1" variant="default" className="mt-3 ml-3 tags_btn"><span class="circle"></span>{data.tags[2]}</Button></div>
                                    ))}<br /><br /> */}
                                        <div className="mt-2"></div>
                                        {/* {defaultTags.length == 0 || defaultTags.length == null ?
                                            <div class="flex">No trending tags
                                            </div> :
                                            <div>
                                                {defaultTags.map((data, i) => (
                                                    <Button key={i}
                                                        style={{ cursor: "auto" }}
                                                        variant="default" size="sm"
                                                        id={"talkbubble1"}
                                                        className={"ml-3 mt-2 tags_btn"} >
                                                        <span className="circle"></span>{data.tag}
                                                    </Button>
                                                ))}<br /><br />
                                            </div>} */}
                                        {defaultTags.length == 0 || defaultTags.length == null ?
                                            <div className="mt-3 flex">No tags
                                            </div> :
                                            (defaultTags.length) == 1 ?
                                                <div className="mt-3 mb-1"><Button variant="default" className="abc"><div><img src={fillTag} style={defaultTags[0].tag.length >= 15 ? { width: "200px", height: "30px" } : { width: "9vw", height: "30px" }} /><div className={defaultTags[0].tag.length >= 15 ? "tags-data-fill" : "tags-data-fill_"} >{defaultTags[0].tag}</div></div></Button><br /><br /><br /><br />
                                                </div> :
                                                (defaultTags.length == 2) ?
                                                    <div className="mt-3 mb-1"><Button variant="default" className="abc"><div><img src={fillTag} style={defaultTags[0].tag.length >= 15 ? { width: "200px", height: "30px" } : { width: "9vw", height: "30px" }} /><div className={defaultTags[0].tag.length >= 15 ? "tags-data-fill" : "tags-data-fill_"} >{defaultTags[0].tag}</div></div></Button>
                                                        <Button variant="default" className="abc"><div><img src={fillTag} style={defaultTags[1].tag.length >= 15 ? { width: "200px", height: "30px" } : { width: "9vw", height: "30px" }} /><div className={defaultTags[1].tag.length >= 15 ? "tags-data-fill" : "tags-data-fill_"} >{defaultTags[1].tag}</div></div></Button><br /><br /><br /><br />
                                                    </div>
                                                    : (defaultTags.length == 3) ?
                                                        <div className="mt-2"><Button variant="default" className="abc"><div><img src={fillTag} style={defaultTags[0].tag.length >= 15 ? { width: "200px", height: "30px" } : { width: "9vw", height: "30px" }} /><div className={defaultTags[0].tag.length >= 15 ? "tags-data-fill" : "tags-data-fill_"} >{defaultTags[0].tag}</div></div></Button>
                                                            <Button variant="default" className="abc"><div><img src={fillTag} style={defaultTags[1].tag.length >= 15 ? { width: "200px", height: "30px" } : { width: "9vw", height: "30px" }} /><div className={defaultTags[1].tag.length >= 15 ? "tags-data-fill" : "tags-data-fill_"} >{defaultTags[1].tag}</div></div></Button><br />
                                                            <Button variant="default" className="abc" style={{ marginTop: "-3px" }}><div><img src={fillTag} style={defaultTags[2].tag.length >= 15 ? { width: "200px", height: "30px" } : { width: "9vw", height: "30px" }} /><div className={defaultTags[2].tag.length >= 15 ? "tags-data-fill" : "tags-data-fill_"} >{defaultTags[2].tag}</div></div></Button><br /><br />
                                                        </div>
                                                        : (defaultTags.length == 4) ?
                                                            <div className="mt-2"><Button variant="default" className="abc"><div><img src={fillTag} style={defaultTags[0].tag.length >= 15 ? { width: "200px", height: "30px" } : { width: "9vw", height: "30px" }} /><div className={defaultTags[0].tag.length >= 15 ? "tags-data-fill" : "tags-data-fill_"} >{defaultTags[0].tag}</div></div></Button>
                                                                <Button variant="default" className="abc"><div><img src={fillTag} style={defaultTags[1].tag.length >= 15 ? { width: "200px", height: "30px" } : { width: "9vw", height: "30px" }} /><div className={defaultTags[1].tag.length >= 15 ? "tags-data-fill" : "tags-data-fill_"} >{defaultTags[1].tag}</div></div></Button><br />
                                                                <Button variant="default" className="abc" style={{ marginTop: "-3px" }}><div><img src={fillTag} style={defaultTags[2].tag.length >= 15 ? { width: "200px", height: "30px" } : { width: "9vw", height: "30px" }} /><div className={defaultTags[2].tag.length >= 15 ? "tags-data-fill" : "tags-data-fill_"} >{defaultTags[2].tag}</div></div></Button>
                                                                <Button variant="default" className="abc" style={{ marginTop: "-3px" }}><div><img src={fillTag} style={defaultTags[3].tag.length >= 15 ? { width: "200px", height: "30px" } : { width: "9vw", height: "30px" }} /><div className={defaultTags[3].tag.length >= 15 ? "tags-data-fill" : "tags-data-fill_"} >{defaultTags[3].tag}</div></div></Button><br /><br />
                                                            </div> :
                                                            <div className="mt-2"><Button variant="default" className="abc"><div><img src={fillTag} style={defaultTags[0].tag.length >= 15 ? { width: "200px", height: "30px" } : { width: "9vw", height: "30px" }} /><div className={defaultTags[0].tag.length >= 15 ? "tags-data-fill" : "tags-data-fill_"} >{defaultTags[0].tag}</div></div></Button>
                                                                <Button variant="default" className="abc"><div><img src={fillTag} style={defaultTags[1].tag.length >= 15 ? { width: "200px", height: "30px" } : { width: "9vw", height: "30px" }} /><div className={defaultTags[1].tag.length >= 15 ? "tags-data-fill" : "tags-data-fill_"} >{defaultTags[1].tag}</div></div></Button><br />
                                                                <Button variant="default" className="abc" style={{ marginTop: "-21px" }}><div><img src={fillTag} style={defaultTags[2].tag.length >= 15 ? { width: "200px", height: "30px" } : { width: "9vw", height: "30px" }} /><div className={defaultTags[2].tag.length >= 15 ? "tags-data-fill" : "tags-data-fill_"} >{defaultTags[2].tag}</div></div></Button>
                                                                <Button variant="default" className="abc" style={{ marginTop: "-21px" }}><div><img src={fillTag} style={defaultTags[3].tag.length >= 15 ? { width: "200px", height: "30px" } : { width: "9vw", height: "30px" }} /><div className={defaultTags[3].tag.length >= 15 ? "tags-data-fill" : "tags-data-fill_"} >{defaultTags[3].tag}</div></div></Button><br />
                                                                <Button variant="default" className="abc" style={{ marginTop: "-21px" }}><div><img src={fillTag} style={defaultTags[4].tag.length >= 15 ? { width: "200px", height: "30px" } : { width: "9vw", height: "30px" }} /><div className={defaultTags[4].tag.length >= 15 ? "tags-data-fill" : "tags-data-fill_"} >{defaultTags[4].tag}</div></div></Button><br />
                                                            </div>}
                                    </div>
                                </Card>
                                {/* <Card className="mt-4">
                                    <div className="card-padding">
                                        <h6 className="t-tags">Moderators</h6>
                                        {Moderators.map((data, i) => (
                                            <div className="top-products-new">
                                                <img src={data.image} style={{ width: "30px" }} />
                                                <div className="ml-2 text-topproducts">{data.name}</div>
                                            </div>
                                        ))}
                                    </div>
                                </Card> */}
                                <center className="mt-5 guidelines">
                                    <div><span>User Agreement</span> <Link to="/privacypolicy" className="link" style={{ color: "black" }}><span className="ml-3">Privacy Policy</span></Link> <span className="ml-3">Content Policy</span></div>
                                    <div className="mt-3">Moderators Guidelines</div>
                                    <div className="mt-5">
                                        Copyright {new Date().getFullYear()} Feddup. All right reserved.
                                    </div>
                                </center>
                            </Col>
                        </Row > : <center><h4>No access for Individual user</h4></center>}

                </div > <br />
            </div >
        </div >
    );
}


export const ratingDescription = (rating) => {
    rating = rating || 0;

    if (rating == 0)
        return <span className="text-black">No reviews yet!</span>
    else if (rating <= 1)
        return <span className="text-black">Users are<span className="text-orange"> Fed </span> up!</span>
    else if (rating <= 2)
        return <span className="text-black">Users<span className="text-orange"> Hate </span> this app!</span>
    else if (rating <= 3)
        return <span className="text-black">Users Find this<span className="text-orange"> OK </span>!</span>
    else if (rating <= 4)
        return <span className="text-black">Users<span className="text-orange"> Like </span> this app!</span>
    else if (rating <= 5)
        return <span className="text-black">Users<span className="text-orange"> Love </span> this app!</span>
    else
        return <span className="text-black">No reviews yet!</span>
}
export const userRatingDesc = (rating) => {
    rating = rating || 0;
    if (rating === 1)
        return "Fed up!"
    else if (rating === 2)
        return "Hate it!!"
    else if (rating === 3)
        return "It's Ok!"
    else if (rating === 4)
        return "Like it!"
    else if (rating === 5)
        return "Love it!"
    else
        return "No reviews yet"

}
import React, { useRef, useState, useEffect } from "react";
import { Button, Form, Modal, Dropdown, Card, Row, Col, OverlayTrigger, Tooltip, Popover, FormControl } from "react-bootstrap";
import { Link, NavLink, Redirect, useLocation } from "react-router-dom";
import NavBar from "../NavBar/NavBar";
import './CompanyFeed.css';
import axios from 'axios';
import background from '../images/background-img.png';
import ratingImg from '../images/showrating.PNG';
import { config } from '../Config/Config';
import RatingImages from "../utils/DynamicRating/DynamicRatingImages";
import noReviews from "../images/no-reviews.svg";
import { ratingDescription } from "../utils/ratingDescription";
import { CircleFadeLoader } from "react-loaders-kit";
import { useQuery } from "react-query";
import imageIcon from "../images/image-icon.svg"
import premium from "../images/premium.svg"
import fedduplogo from '../images/fedduplogo.png';
import rating1 from '../images/rating1.png';
import rating2 from '../images/rating2.png';
import rating3 from '../images/rating3.png';
import rating4 from '../images/rating4.png';
import rating5 from '../images/rating5.png';
import { decrypt } from "../utils";

function useFetchQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default function SearchResults(props) {
    const [products, setProducts] = useState([]);
    const [companies, setCompanies] = useState([])
    const [companyProducts, setCompanyProducts] = useState([])
    const [loading, setLoading] = useState(true);
    const [selectedCompany, setSelectedCompany] = useState()
    const [results, setResults] = useState([]);
    const [filteredResults, setFilteredResults] = useState([])
    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState({
        type: 'all',
        membershipType: 'all',
        sortBy: '',
        rating: []
    })

    let query = useFetchQuery();
    useEffect(() => {
        getProductByCompanyId();
    }, [])

    const getProductByCompanyId = () => {
        let headers;
        if (localStorage.getItem('junkStore')) {
            headers = {
                'Accept': 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                'Content-Type': 'application/json'
            }
        } else {
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        axios.get(config.userUrl + `user/searchCompanyAndProductDetails?search=${query.get('q')}`, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setProducts(response.data.products);
                    setCompanies(response.data.companies);
                    setResults([...response.data.products, ...response.data.companies].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))
                    setFilteredResults([...response.data.products, ...response.data.companies].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))
                }
                setLoading(false)
            })
            .catch(function (error) {
                setLoading(false)
                console.log("Error", error);
            });

    };

    const { isLoading, refetch: getCompanyProducts } = useQuery(
        'getCompanyProducts',
        () => {
            let headers;
            if (localStorage.getItem('junkStore')) {
                headers = {
                    'Accept': 'application/json',
                    "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                    'Content-Type': 'application/json'
                }
            } else {
                headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
            var payload = {
                "companyId": selectedCompany.companyId,
            }
            return axios.post(config.userUrl + "user/getCompanyProducts", payload, { headers: headers })
        },

        {
            enabled: false,
            onSuccess: (responses) => {
                setCompanyProducts(responses.data.products || [])
            }
        }
    );

    useEffect(() => {
        if (selectedCompany) {
            window.scrollTo(0, 0);
            getCompanyProducts();
        }
    }, [selectedCompany])

    const handleRating = (rating) => {
        let rats = filter.rating;
        let idx = rats.findIndex(item => item == rating)
        if (idx > -1) {
            rats.splice(idx, 1);
        }
        else {
            rats.push(Number(rating));
        }
        setFilter({ ...filter, rating: rats })
    }

    const applyFilter = () => {
        let { type, membershipType, rating, sortBy } = filter;
        let arr = [...results];
        if (type == 'company') {
            arr = [...companies];
        }
        else if (type == 'product') {
            arr = [...products];
        }

        if (membershipType == 'free') {
            arr = arr.filter(item => item.membershipType?.toLowerCase() !== 'premium')
        }
        else if (membershipType == 'pro enabled') {
            arr = arr.filter(item => item.membershipType?.toLowerCase() == 'premium')
        }

        if (rating.length) {
            arr = arr.filter(item => rating.find(rat => item?.averageRating ? Math.round(Number(item?.averageRating)) == rat : false))
        }

        if (sortBy == 'most followed') {
            arr.sort((a, b) => (b?.followers || 0) - (a?.followers || 0))
        }
        else if (sortBy == 'latest version') {
            arr.sort((a, b) => new Date(b.version || new Date(0).toISOString()) - new Date(a.version) || new Date(0).toISOString())
        }
        else if (sortBy == 'suggestion accepted') {
            arr.sort((a, b) => (b?.suggestionCount || 0) - (a?.suggestionCount || 0))
        }

        setFilteredResults(arr)
    }

    useEffect(() => {
        applyFilter()
    }, [filter])

    const loaderProps = {
        loading: loading || isLoading,
        size: 40,
        duration: 0.5,
        colors: ['#5e22f0']
    }

    const followProduct = (productId, companyId, val) => {
        if (localStorage.getItem('junkStore')) {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                "applicationType": "web"
            }
            var payload = {
                "productId": productId,
                "companyIdBelongTo": companyId,
                "flag": val
            }
            axios.post(config.userUrl + "user/createProductFollowByUser", payload, { headers: headers })
                .catch(function (error) {
                    console.log("Error", error);
                });
        } else {
            console.log("Cannot follow")
        }
    };

    const requestPageAccess = (productId, companyId) => {
        if (localStorage.getItem('junkStore')) {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                "applicationType": "web"
            }
            var payload = {
                "productId": productId,
                "companyId": companyId,
            }

            axios.post(config.userUrl + "user/requestPageAccess", payload, { headers: headers })
                .then(function (response) {
                    if (response.data.status === true) {
                    }
                })
                .catch(function (error) {
                    console.log("Error", error);
                });
        } else {
            console.log("Cannot follow")
        }
    };

    const handleFollowing = (index, productId) => {

        let arr = [...filteredResults];
        let value = !Boolean(arr[index].following);
        let followers = value ? arr[index].followers + 1 : arr[index].followers - 1
        arr[index].following = value
        arr[index].followers = followers
        let prods = [...products];
        let idx = prods.findIndex(item => item.productId == productId);
        setFilteredResults(arr);
        if (idx > -1) {
            prods[idx].following = value
            prods[idx].followers = followers
            setProducts(prods);

            followProduct(productId, prods[idx].companyId, value)
        }
    }
    const handleRequestAccess = (index, productId) => {
        let arr = [...filteredResults];
        arr[index].requested = true
        let prods = [...products];
        let idx = prods.findIndex(item => item.productId == productId);
        setFilteredResults(arr);
        if (idx > -1) {
            prods[idx].requested = true
            setProducts(prods);

            requestPageAccess(productId, prods[idx].companyId)
        }
    }

    return (
        <div>

            <NavBar />
            {
                !selectedCompany
                    ?

                    <div id="background-company" className="p-5">
                        <div className="search-res-heading mt-10 sticky top-20">Search Results for "{query.get('q')}"</div>
                        {
                            loading ?
                                <div className='mx-auto flex flex-col w-full items-center'><CircleFadeLoader {...loaderProps} /><span className='text-2xl font-semibold'>Loading...</span></div>
                                :

                                results?.length == 0
                                    ?
                                    <NoResults />
                                    :

                                    <div className="w-full max-w-[90rem] py-10 flex items-start gap-10">
                                        <div className="w-full max-w-xs bg-white rounded-xl sticky top-36">

                                            <div className="p-3">
                                                <h1 className="font-medium">Show Only</h1>
                                                {
                                                    ['company', 'product', 'all'].map((item, i) => (
                                                        <div key={i} className="flex items-center gap-2 mt-2 cursor-pointer" onClick={() => setFilter({ ...filter, type: item })}>
                                                            <div className="border-2 border-gray-800 rounded-full w-4 h-4 flex justify-center items-center">
                                                                {filter.type == item && <button className="w-full h-full rounded-full bg-orange-500">
                                                                </button>}
                                                            </div>
                                                            <p className="capitalize text-sm">{item}</p>
                                                        </div>
                                                    ))
                                                }

                                            </div>
                                            <hr className="border-t border-t-orange-500" />
                                            <div className="p-3">
                                                <h1 className="font-medium">Show Only</h1>
                                                {
                                                    ['pro enabled', 'free', 'all'].map((item, i) => (
                                                        <div key={i} className="flex items-center gap-2 mt-2 cursor-pointer" onClick={() => setFilter({ ...filter, membershipType: item })}>
                                                            <div className="border-2 border-gray-800 rounded-full w-4 h-4 flex justify-center items-center">
                                                                {filter.membershipType == item && <button className="w-full h-full rounded-full bg-orange-500">
                                                                </button>}
                                                            </div>
                                                            <p className="capitalize text-sm">{item}</p>
                                                        </div>
                                                    ))
                                                }

                                            </div>
                                            <hr className="border-t border-t-orange-500" />
                                            <div className="p-3">
                                                <div className="flex items-center justify-between pb-2">
                                                    <h1 className="font-medium">Emotions</h1>
                                                    <div className="py-1 px-2 bg-orange-300 rounded-full flex items-center gap-2 text-xs max-w-max cursor-pointer" onClick={() => setFilter({ ...filter, rating: [] })}>
                                                        <span>Clear</span>
                                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M4.03711 10.1336L10.5371 3.63361M4.03711 3.63361L10.5371 10.1336" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="flex items-center gap-1">
                                                    <img src={rating1} onClick={() => handleRating(1)} alt="" className={`w-8 h-8 cursor-pointer ${filter.rating.includes(1) ? 'bg-orange' : ''}`} />
                                                    <img src={rating2} onClick={() => handleRating(2)} alt="" className={`w-8 h-8 cursor-pointer ${filter.rating.includes(2) ? 'bg-orange' : ''}`} />
                                                    <img src={rating3} onClick={() => handleRating(3)} alt="" className={`w-8 h-8 cursor-pointer ${filter.rating.includes(3) ? 'bg-orange' : ''}`} />
                                                    <img src={rating4} onClick={() => handleRating(4)} alt="" className={`w-8 h-8 cursor-pointer ${filter.rating.includes(4) ? 'bg-orange' : ''}`} />
                                                    <img src={rating5} onClick={() => handleRating(5)} alt="" className={`w-8 h-8 cursor-pointer ${filter.rating.includes(5) ? 'bg-orange' : ''}`} />
                                                </div>

                                            </div>
                                            <hr className="border-t border-t-orange-500" />
                                            <div className="p-3">
                                                <h1 className="font-medium">Show Only</h1>
                                                {
                                                    ['most followed', 'latest version', 'suggestion accepted'].map((item, i) => (
                                                        <div key={i} className="flex items-center gap-2 mt-2 cursor-pointer" onClick={() => setFilter({ ...filter, sortBy: item })}>
                                                            <div className="border-2 border-gray-800 rounded-full w-4 h-4 flex justify-center items-center">
                                                                {filter.sortBy == item && <button className="w-3 h-3 rounded-full bg-orange-500">
                                                                </button>}
                                                            </div>
                                                            <p className="capitalize text-sm">{item}</p>
                                                        </div>
                                                    ))
                                                }

                                            </div>
                                        </div>
                                        {
                                            filteredResults.length == 0 ?
                                                <div className="flex-grow items-center justify-center">
                                                    <NoResults />
                                                </div>
                                                :
                                                <div className="flex-grow flex flex-col gap-5">

                                                    {
                                                        filteredResults.map((data, i) => {

                                                            if (data.type == 'product') {

                                                                return (

                                                                    <div key={i} className="flex items-end gap-2 bg-white px-3 pb-3 rounded-lg border-y-2 border-orange-500">
                                                                        <div>
                                                                            <img src={data?.productIcon || fedduplogo} style={{ padding: data?.productIcon ? 0 : 16 }} className="w-28 h-28 rounded object-contain bg-white" alt="" />
                                                                        </div>
                                                                        <Link to={{
                                                                            pathname: `/by/${data.companyName}/${data.productName}/${data.productTypeString}`,
                                                                            state: {
                                                                                id: data.productId
                                                                            }
                                                                        }} className="link flex-grow pl-2">

                                                                            <div>
                                                                                <h1 className="text-2xl font-semibold">{data?.productName} <span className="text-lg font-normal">{data?.productTypeString}</span></h1>
                                                                                <p className="text-sm">By {data?.companyName}</p>
                                                                                <p className="text-gray-400 max-w-sm text-sm">{data?.productDescription}</p>
                                                                            </div>
                                                                        </Link>
                                                                        <div>
                                                                            <div className="flex items-baseline gap-2 pl-3">
                                                                                <h1 className="text-xl font-medium">{data?.followers || 0}</h1>
                                                                                <h1 className="text-sm text-gray-400">Followers</h1>
                                                                            </div>
                                                                            <div className='btn-outer mt-2'>
                                                                                <div className='btn-1' style={{
                                                                                    width: '120px',
                                                                                    height: '32px'
                                                                                }}>
                                                                                </div>
                                                                                {
                                                                                    data.pageType == 'Private' && !data.following
                                                                                        ?
                                                                                        data.requested
                                                                                            ?
                                                                                            <Button variant="default" className="btn-2 !font-normal" style={{
                                                                                                width: '120px',
                                                                                                height: '32px',
                                                                                                fontSize: '16px',
                                                                                                display: 'flex',
                                                                                                alignItems: 'center',
                                                                                                justifyContent: 'center',
                                                                                            }}>Requested</Button>
                                                                                            :
                                                                                            <Button variant="default" onClick={() => handleRequestAccess(i, data.productId)} className="btn-2 !font-normal" style={{
                                                                                                width: '120px',
                                                                                                height: '32px',
                                                                                                fontSize: '14px',
                                                                                                display: 'flex',
                                                                                                alignItems: 'center',
                                                                                                justifyContent: 'center',
                                                                                            }}>Request to join</Button>

                                                                                        :
                                                                                        <Button variant="default" className={`btn-2 ${data.following ? 'group' : ''}`} style={{
                                                                                            width: '120px',
                                                                                            height: '32px',
                                                                                            fontSize: '16px',
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            justifyContent: 'center',
                                                                                        }}
                                                                                            onClick={() => handleFollowing(i, data.productId)}
                                                                                        >
                                                                                            <>
                                                                                                <span className="group-hover:hidden">
                                                                                                    {
                                                                                                        data.following ?
                                                                                                            'Following'
                                                                                                            :
                                                                                                            'Follow'
                                                                                                    }
                                                                                                </span>
                                                                                                <span className="hidden group-hover:inline-block">Unfollow</span>
                                                                                            </>
                                                                                        </Button>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex flex-col gap-1 items-end justify-end'>
                                                                            <p className='text-2xl font-semibold'><span className='text-sm text-gray-500'>({data?.numberOfRaters})</span>{Number(data?.averageRating)}</p>
                                                                            {!data?.numberOfRaters ? <img src={noReviews} alt='' style={{ width: "40px", height: "40px" }} /> :
                                                                                <div className='flex'>
                                                                                    {data?.numberOfRaters > 0 && <RatingImages rating={Number(data?.averageRating)} />}
                                                                                </div>
                                                                            }
                                                                            <p className=' xl:text-base text-right text-sm font-semibold'>{ratingDescription(Number(data?.averageRating))}</p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            else if (data.type == 'company') {
                                                                return (
                                                                    <div key={i} className="flex items-end gap-2 px-3 pb-3 rounded-lg" style={{ background: data.membershipType == "Premium" ? 'linear-gradient(135deg, rgba(255, 244, 225, 1), rgba(251, 255, 226, 1))' : 'linear-gradient(135deg, rgba(255, 229, 216, 1), rgba(255, 244, 225, 1) )' }}>
                                                                        <div>
                                                                            <img src={data?.companyIcon || fedduplogo} style={{ padding: data?.companyIcon ? 0 : 16 }} className="w-28 h-28 rounded object-contain" alt="company logo" />
                                                                        </div>
                                                                        <div className="flex-grow pl-2">
                                                                            <h1 className="text-2xl font-medium">{data?.companyName} <span className="text-lg font-normal">{data?.productTypeString}</span></h1>
                                                                            <div className="flex items-center gap-1">
                                                                                <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M15.2988 10.5829C15.2988 11.3785 14.9828 12.1416 14.4201 12.7042C13.8575 13.2668 13.0945 13.5829 12.2988 13.5829C11.5032 13.5829 10.7401 13.2668 10.1775 12.7042C9.6149 12.1416 9.29883 11.3785 9.29883 10.5829C9.29883 9.78724 9.6149 9.02417 10.1775 8.46157C10.7401 7.89896 11.5032 7.58289 12.2988 7.58289C13.0945 7.58289 13.8575 7.89896 14.4201 8.46157C14.9828 9.02417 15.2988 9.78724 15.2988 10.5829Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                                    <path d="M19.7988 10.5829C19.7988 17.7249 12.2988 21.8329 12.2988 21.8329C12.2988 21.8329 4.79883 17.7249 4.79883 10.5829C4.79883 8.59376 5.589 6.68611 6.99553 5.27958C8.40205 3.87306 10.3097 3.08289 12.2988 3.08289C14.288 3.08289 16.1956 3.87306 17.6021 5.27958C19.0087 6.68611 19.7988 8.59376 19.7988 10.5829Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                                </svg>
                                                                                <p className="text-sm">{data?.headquarter}</p>
                                                                            </div>
                                                                            <p className="text-gray-400 max-w-sm text-sm">{data?.companyWebsite}</p>
                                                                        </div>
                                                                        <div className="pr-6">
                                                                            <div className="flex items-baseline gap-2 pl-1">
                                                                                <h1 className="text-xl font-medium">{data?.pagesCount} Feddup pages</h1>
                                                                            </div>
                                                                            <div className='btn-outer mt-3' style={{ opacity: data?.pagesCount == 0 ? 0 : 1 }}>
                                                                                <div className='btn-1' style={{
                                                                                    width: '150px',
                                                                                    height: '32px'
                                                                                }}>
                                                                                </div>
                                                                                <Button
                                                                                    onClick={() => setSelectedCompany(data)}
                                                                                    disabled={data?.pagesCount == 0}
                                                                                    variant="default" className="btn-2 group" style={{
                                                                                        width: '150px',
                                                                                        height: '32px',
                                                                                        fontSize: '16px',
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'center',
                                                                                    }}><span>View all pages</span></Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                        }
                                    </div>

                        }
                    </div >
                    :
                    <div id="background-company" className="p-5">
                        <div className="w-full max-w-[90rem] py-10 " id='background-admin'>
                            <svg width="161" height="23" className="cursor-pointer" onClick={() => setSelectedCompany()} viewBox="0 0 161 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M159.24 10.2153C160.069 10.2153 160.74 10.8869 160.74 11.7153C160.74 12.5438 160.069 13.2153 159.24 13.2153V10.2153ZM1.32094 12.776C0.735138 12.1902 0.735138 11.2405 1.32094 10.6547L10.8669 1.10873C11.4527 0.522943 12.4024 0.522943 12.9882 1.10873C13.574 1.69452 13.574 2.64426 12.9882 3.23005L4.50291 11.7153L12.9882 20.2006C13.574 20.7864 13.574 21.7361 12.9882 22.3219C12.4024 22.9077 11.4527 22.9077 10.8669 22.3219L1.32094 12.776ZM159.24 13.2153L2.38159 13.2153V10.2153L159.24 10.2153V13.2153Z" fill="black" />
                            </svg>


                            <div className='flex gap-12 py-4 px-4 rounded-2xl min-h-[25vh] w-full' style={{ background: selectedCompany?.membershipType == "Premium" ? 'linear-gradient(135deg, rgba(255, 244, 225, 1), rgba(251, 255, 226, 1))' : 'linear-gradient(135deg, rgba(255, 229, 216, 1), rgba(255, 244, 225, 1) )' }}>
                                <div className='max-w-3xl flex items-center pl-4'>
                                    {selectedCompany?.companyIcon ?
                                        <img src={selectedCompany?.companyIcon} alt="company-logo" className='max-w-[10rem]' />
                                        : <img src={imageIcon} alt="company-logo" className='w-20' />
                                    }

                                </div>
                                <div className='max-w-6xl flex flex-col my-auto gap-1 flex-grow'>
                                    <p className='font-bold text-2xl'>{selectedCompany?.companyName}</p>
                                    <div className="flex items-center gap-1">
                                        <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.2988 10.5829C15.2988 11.3785 14.9828 12.1416 14.4201 12.7042C13.8575 13.2668 13.0945 13.5829 12.2988 13.5829C11.5032 13.5829 10.7401 13.2668 10.1775 12.7042C9.6149 12.1416 9.29883 11.3785 9.29883 10.5829C9.29883 9.78724 9.6149 9.02417 10.1775 8.46157C10.7401 7.89896 11.5032 7.58289 12.2988 7.58289C13.0945 7.58289 13.8575 7.89896 14.4201 8.46157C14.9828 9.02417 15.2988 9.78724 15.2988 10.5829Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M19.7988 10.5829C19.7988 17.7249 12.2988 21.8329 12.2988 21.8329C12.2988 21.8329 4.79883 17.7249 4.79883 10.5829C4.79883 8.59376 5.589 6.68611 6.99553 5.27958C8.40205 3.87306 10.3097 3.08289 12.2988 3.08289C14.288 3.08289 16.1956 3.87306 17.6021 5.27958C19.0087 6.68611 19.7988 8.59376 19.7988 10.5829Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <p className="text-sm">{selectedCompany?.headquarter}</p>
                                    </div>
                                    <a href={selectedCompany?.companyWebsite} target="_blank" className={`${selectedCompany?.companyWebsite ? "" : "hidden"}`} style={{ color: 'inherit', textDecoration: 'inherit' }}>{selectedCompany?.companyWebsite ? selectedCompany?.companyWebsite : "Company Website"}</a>
                                    <p className="text-sm">{selectedCompany?.companyDescription}</p>
                                </div>
                                <div className='flex float-right max-w-3xl ml-auto my-auto mr-8'>
                                    {selectedCompany?.membershipType === "Free" ?
                                        <div className='flex flex-col items-center'>
                                            <img src={premium} alt="" />
                                        </div>
                                        : <div>
                                            <img src={premium} alt="" />
                                        </div>}
                                </div>

                            </div>
                            <p className='text-2xl font-semibold my-4 underline text-orange-400 underline-offset-8'><span className='text-black'>Feddup Pages</span></p>
                            <div className="flex items-center justify-between">
                                <div className='flex-grow'>
                                    <Form className={`${selectedCompany?.membershipType === "Premium" ? "" : "hidden"}`}>
                                        <FormControl
                                            type="text"
                                            placeholder="Search"
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                            id="search"
                                            className="abc form-control ml-0 bg-white rounded-full"
                                            style={{ width: "380px", height: "45px", borderRadius: "25px", paddingLeft: "3rem" }}
                                            aria-label="Search keyword"
                                        />
                                    </Form>
                                </div>


                                <div className="flex items-center gap-4">
                                    <div className="text-right text-white font-medium py-2 px-4 bg-orange rounded-lg ">
                                        <h1 className="cursor-pointer">{companyProducts?.filter(item => item.pageType !== "Private").length}</h1>
                                        <h1 className="cursor-pointer">Public Feddup Pages</h1>
                                    </div>
                                    <div className="text-right text-white font-medium py-2 px-4 bg-orange-400 rounded-lg relative overflow-hidden">
                                        {
                                            selectedCompany?.membershipType == "Free"
                                            &&
                                            <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                                        }
                                        <h1 className="cursor-pointer">{companyProducts?.filter(item => item.pageType == "Private").length}</h1>
                                        <h1 className="cursor-pointer">Private Feddup Pages</h1>
                                    </div>
                                </div>

                            </div>
                            {isLoading ? <div className='mx-auto flex flex-col'><CircleFadeLoader {...loaderProps} /><span className='text-2xl font-semibold'>Loading...</span></div> : null}

                            <div className="grid grid-cols-2 xl:grid-cols-3 gap-20 pt-10 2xl:px-5">
                                {companyProducts?.filter(item => item?.productName?.toLowerCase().includes(search?.toLowerCase()))?.map((product, i) => <Product key={i} product={product} />)}
                            </div>
                        </div>
                    </div>
            }
        </div >
    );
}




const NoResults = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-[50vh]">
            <svg width="189" height="160" viewBox="0 0 189 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M182.346 246.554H40.8621V198.172L73.3871 179.674L40.8621 156.907V86.9779L4.47461 57.2985L182.346 4.64883V246.554Z" fill="#E6570E" stroke="#E6570E" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M138.231 102.224C138.231 114.014 130.1 114.014 130.1 125.804C130.1 137.595 138.231 137.595 138.231 149.385C138.231 161.175 130.1 161.175 130.1 172.966C130.1 184.756 138.231 184.756 138.231 196.546C138.231 208.337 130.1 208.337 130.1 220.127" stroke="white" strokeWidth="10" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M115.876 102.224C115.876 114.014 107.744 114.014 107.744 125.804C107.744 137.595 115.876 137.595 115.876 149.385C115.876 161.175 107.744 161.175 107.744 172.966C107.744 184.756 115.876 184.756 115.876 196.546C115.876 208.337 107.744 208.337 107.744 220.127" stroke="white" strokeWidth="10" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M115.324 55.1134L139.513 79.3025" stroke="#FFB858" strokeWidth="10" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M139.513 55.1134L115.324 79.3025" stroke="#FFB858" strokeWidth="10" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <h1 className=" text-xl font-medium text-gray-800 mt-3">No Result Found</h1>
            <h1 className=" text-xl font-medium text-gray-800">Search Again!</h1>
        </div>
    )
}

const Product = ({ product }) => {
    return (
        <Link to={`/admin/productdetails/${product.productName}/${product.id}/`} style={{ color: 'inherit', textDecoration: 'inherit' }}>
            <div className='relative h-60 w-full max-w-sm'>
                <div className='products-container min-w-24 h-28 flex items-center'>
                    <img src={product.productIcon} alt="" style={{ width: "80px" }} />
                </div>
                <div className='bg-white max-w-md rounded-xl p-2 h-full'>
                    <p className='ml-20 2xl:text-2xl xl:text-xl text-lg font-semibold'>{product.productName} {product.productType === "Standalone" ? "Desktop" : product.productType}<span className={`float-right font-normal text-base rounded-xl 2xl:px-4 xl:px-3 px-2 mr-2 ${product.pageType === "Private" ? "bg-orange-600 text-white" : "bg-orange-400 text-white"}`}>{product.pageType}</span></p>
                    <div className='flex flex-col '>
                        <div className='flex flex-col ml-20'>
                            <p className='text-sm font-semibold'>{product.productType === "Standalone" ? "Desktop Application" : "Web Application"}</p>
                        </div>
                        <div className='flex items-end mt-4'>
                            <div className='flex text-center px-4 gap-3' style={{ paddingBottom: product.averageRating > 0 ? 20 : 0 }}>
                                <div>
                                    <p className='xl:text-xl text-lg font-medium'>{product.followers || 0}</p>
                                    <p className='xl:text-lg text-base font-medium'>Followers</p>
                                </div>
                                <div>
                                    <p className='xl:text-xl text-lg font-medium'>{product.version?.version || 0}</p>
                                    <p className='xl:text-lg text-base font-medium'>Version</p>
                                </div>
                            </div>
                            <div className='flex flex-col items-end ml-auto mr-4 my-auto gap-2 text-right'>
                                <p className='text-3xl font-semibold'><span className='text-sm text-gray-500'>({product.numberOfRaters})</span>{product.averageRating}</p>
                                {product.numberOfRaters === 0 ? <img src={noReviews} alt='' style={{ width: "40px", height: "40px" }} /> :
                                    <div className='flex'>
                                        {product.averageRating > 0 && <RatingImages rating={product.averageRating} />}
                                    </div>
                                }
                                <p className='2xl:text-lg xl:text-base text-sm font-semibold'>{ratingDescription(product.averageRating)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { config } from '../Config/Config';
import cancelWhiteIcon from "../images/cancel-x-white.svg";
import { Button, Col, Dropdown, Form, FormControl, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import { decrypt, loaderProps } from '../utils';
import { CircleFadeLoader } from 'react-loaders-kit';
import fillTag from "../images/fill-tag.svg";
import emptyTag from "../images/empty-tag-gray.svg";
import pinOrange from "../images/pinned-orange.svg";
import pinGray from "../images/pinned-gray.svg"
import FeedbackComments from './FeedbackComments';
import Tag from '../utils/Tag';
import ViewImage from '../UsersFeed/ViewImage';


function ProductFeedbacks({ productid, membershipType, selectedVersionId, productTags, handleFlag, deletePost, openReasonPopUp, userRole }) {
    const [section, setSection] = useState("Feddbacks");
    const [openStates, setOpenStates] = useState([]);
    const [selectedTags, setSelectedTags] = useState(Array(productTags?.length).fill(false));;
    const [show, setShow] = useState(false)
    const [sortedData, setSortedData] = useState([])
    const [sortOption, setSortOption] = useState('')
    const [sortOption2, setSortOption2] = useState('All')
    const [sortOption3, setSortOption3] = useState([])
    const [searchInput, setSearchInput] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const [feddbacks, setFeddbacks] = useState([])
    const [loading, setLoading] = useState(false)
    const [popup, setPopup] = useState(false);
    const [popupData, setPopupData] = useState([]);
    const [viewImage, setViewImage] = useState(false)
    const [popupImage, setPopupImage] = useState("")
    const [selectedValue, setSelectedValue] = useState(0);
    const [feedbackData, setFeedbackData] = useState([]);
    const [showToast, setShowToast] = useState(false)
    const [pinnedFeedbacksCount, setPinnedFeedbacksCount] = useState(0)

    useEffect(() => {
        setSortedData([]);
        getAllFeddbacks();
    }, [selectedVersionId])
    const getAllFeddbacks = () => {
        if (selectedVersionId === "") return;
        setLoading(true);
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "productId": productid,
            "versionId": selectedVersionId
        }
        axios.post(config.userUrl + "admin/getProductFeddbacks", payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    setFeddbacks(response.data.feedbackDetails?.filter(item => item.title))
                    setSortedData(response.data.feedbackDetails?.filter(item => item.title))
                    setOpenStates(Array(response.data.feedbackDetails.length).fill(false))
                    setPinnedFeedbacksCount(response.data.pinnedFeedbacksCount)
                }
            })
            .then(() => {
                setSortOption("Latest to Oldest")
                setSortOption2("All")
                setSortOption3([])
                setLoading(false);
            })
            .catch(function (error) {
                console.log("Error", error);
                setLoading(false);
            });
    };
    const pinFeedback = (feedback) => {
        if (pinnedFeedbacksCount === 5) {
            setShowToast(true);
            setTimeout(() => {
                setShowToast(false)
            }, 3000)
            return;
        }
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "id": feedback.feedbackId
        }
        axios.post(config.userUrl + "admin/pinFeedback", payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    feedback.pin = true;
                    setPinnedFeedbacksCount(pinnedFeedbacksCount + 1)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const unpinFeedback = (suggestion) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "id": suggestion.feedbackId,
        }
        axios.post(config.userUrl + "admin/unpinFeedback", payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    suggestion.pin = false;
                    setPinnedFeedbacksCount(pinnedFeedbacksCount - 1)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        const currentData = feddbacks;
        if (searchValue !== '') {
            const filteredData = currentData.filter((item) => {
                return item.feedback.toLowerCase().includes(searchValue.toLowerCase()) && item.feedback.feedback
            })
            setFilteredResults(filteredData)
        }
        else {
            setFilteredResults(currentData)
        }
    }
    const handleButtonClick = (rowIndex) => {
        setOpenStates(openStates.map((isOpen, index) => index === rowIndex ? !isOpen : isOpen));
    }
    const handleTagSelectButtonClick = (rowIndex) => {
        let newSelectedTagsArray = selectedTags.map((isOpen, index) => rowIndex === index ? !isOpen : isOpen);
        setSelectedTags(newSelectedTagsArray);
        let tagsSelected = [];
        productTags.forEach((tag, i) => {
            if (newSelectedTagsArray[i] === true)
                tagsSelected.push(tag);
        })
        setSortOption3(tagsSelected);
    }
    useEffect(() => {
        filter()
    }, [sortOption, sortOption2, sortOption3])
    const filter = () => {
        let feddbacksArray = [...feddbacks]
        let sortedFeddbacks = []
        if (sortOption2.length > 0) {
            if (sortOption2 === "Pinned Only") {
                sortedFeddbacks = feddbacksArray.filter(feddbacks => feddbacks.pin === true)
            }
            else if (sortOption2 === "Except Pinned") {
                sortedFeddbacks = feddbacksArray.filter(feddbacks => feddbacks.pin === false)
            }
            else {
                sortedFeddbacks = [...feddbacks];
            }
            feddbacksArray = [...sortedFeddbacks];
        }
        if (sortOption.length > 0) {
            if (sortOption === "Latest to Oldest") {
                sortedFeddbacks = feddbacksArray.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            }
            else {
                sortedFeddbacks = feddbacksArray.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
            }
            feddbacksArray = [...sortedFeddbacks];
        }
        if (sortOption3.length > 0) {
            sortedFeddbacks = feddbacksArray.filter(feddback => {
                let currentTagArray = feddback.tag;
                const filteredArray = currentTagArray.filter(element => sortOption3.includes(element));
                if (filteredArray.length > 0)
                    return feddback;
            })
        }
        setSortedData(sortedFeddbacks);
    }
    const getFeedbacks = (eventKey) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let product = decrypt(localStorage.getItem('junk'))
        var payload = {
            "productId": product,
            "type": parseInt(eventKey)
        }
        axios.post(config.userUrl + 'admin/getFeedbackByProductIdByAdmin', payload, { headers: headers })
            .then(function (response) {
                console.log("getFeedback");
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("getFeedback successfull");
                    setFeedbackData(response.data.feedback)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const openImage = (data) => {
        setViewImage(true)
        setPopupImage(data.feedbackImage)
    }
    const togglePopup = (data) => {
        setPopup(!popup)
        setPopupData(data)
    }

    return (
        <div>
            {viewImage && <ViewImage close={() => setViewImage(false)} image={popupImage} />}
            {popup && <FeedbackComments openImage={openImage} closePopup={togglePopup} getAllFeedbacks={getFeedbacks} data={popupData} selectedValue={selectedValue} />}
            <div>
                <div className='flex gap-6 mt-8 ml-10 '>
                    <p className={`text-md font-semibold cursor-pointer ${section === "Feddbacks" ? "underline text-orange-400 underline-offset-8" : ""}`} onClick={() => setSection("Feddbacks")}><span className='text-black'>Feddbacks</span></p>
                    <p className={`text-md font-semibold cursor-pointer ${section === "Replied" ? "underline text-orange-400 underline-offset-8" : ""}`} onClick={() => setSection("Replied")}><span className='text-black'>Replied</span></p>
                    {/* <p className={`text-md font-semibold cursor-pointer ${section === "Insights" ? "underline text-orange-400 underline-offset-8" : ""}`} onClick={() => setSection("Insights")}><span className='text-black'>Insights</span></p> */}
                </div>
                <Row className="ml-1 mt-6">
                    <Col md={3} className='flex flex-col bg-white mb-10 p-3 rounded-xl h-max sticky top-[80px]'>
                        <div className='flex justify-between items-center'>
                            <p className='text-2xl font-semibold  underline text-orange-400 underline-offset-8'><span className='text-black'>Sort&Filter</span></p>
                            <span className='bg-orange-600 rounded-xl text-white text-xs px-2 py-0.5 flex items-center gap-2 justify-center cursor-pointer' onClick={() => { setSortOption("Latest to Oldest"); setSortOption2("All"); setSortOption3([]); setSortedData(feddbacks) }}><span>Clear All</span><img src={cancelWhiteIcon} alt='' className='float-right w-5' /></span>
                        </div>
                        <br />
                        <div className="flex flex-col gap-2 text-balck font-medium" onChange={(e) => setSortOption(e.target.value)}>
                            <div className='flex items-center gap-2'>
                                <button
                                    className={`rounded-full w-4 h-4 ${sortOption === "Latest to Oldest" ? 'bg-black text-white' : 'bg-gray-200'
                                        }`}
                                    onClick={() => setSortOption("Latest to Oldest")}
                                >
                                </button>
                                <span>Latest to Oldest</span>
                            </div>
                            <div className='flex items-center gap-2'>
                                <button
                                    className={`rounded-full w-4 h-4 ${sortOption === "Oldest to Latest" ? 'bg-black text-white' : 'bg-gray-200'
                                        }`}
                                    onClick={() => setSortOption("Oldest to Latest")}
                                >
                                </button>
                                <span>Oldest to Latest</span>
                            </div>
                        </div>
                        <br />
                        <div>
                            <p className='text-xl font-medium mt-4 flex items-center justify-between'>Pinned </p>
                            <div className="flex flex-col gap-2 text-balck font-medium" onChange={(e) => setSortOption2(e.target.value)}>
                                <div className='flex items-center gap-2'>
                                    <button
                                        className={`rounded-full w-4 h-4 ${sortOption2 === "Pinned Only" ? 'bg-black text-white' : 'bg-gray-200'
                                            }`}
                                        onClick={() => setSortOption2("Pinned Only")}
                                    >
                                    </button>
                                    <span>Pinned Only</span>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <button
                                        className={`rounded-full w-4 h-4 ${sortOption2 === "Except Pinned" ? 'bg-black text-white' : 'bg-gray-200'
                                            }`}
                                        onClick={() => setSortOption2("Except Pinned")}
                                    >
                                    </button>
                                    <span>Except Pinned</span>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <button
                                        className={`rounded-full w-4 h-4 ${sortOption2 === "All" ? 'bg-black text-white' : 'bg-gray-200'
                                            }`}
                                        onClick={() => setSortOption2("All")}
                                    >
                                    </button>
                                    <span>All</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p className='text-xl font-medium mt-4 flex items-center justify-between'>Filter By Tags</p>
                            <div className='flex items-center flex-wrap gap-3 py-3'>
                                {productTags && productTags.map((data, i) => {
                                    return (
                                        <span className='cursor-pointer' onClick={() => handleTagSelectButtonClick(i)}>
                                            <Tag text={data} color={selectedTags[i] ? '#E6570E' : '#999'} />
                                        </span>
                                    )
                                })}
                            </div>
                        </div>
                    </Col>
                    <Col md={9} className='flex flex-col gap-4'>
                        <div className='flex justify-between'>
                            <div className='flex flex-col gap-4'>
                                <p className='text-2xl font-semibold text-black'>Feddbacks</p>
                                <div className={`${membershipType === "Free" ? "hidden" : ""}`}>
                                    <Form className=''>
                                        <FormControl
                                            type="text"
                                            placeholder="Search for user"
                                            value={searchInput}
                                            onChange={(e) => searchItems(e.target.value)}
                                            id="search"
                                            className="abc form-control ml-0 bg-white rounded-full"
                                            style={{ width: "480px", height: "45px", borderRadius: "25px", paddingLeft: "3rem" }}
                                            aria-label="Search keyword"
                                        />
                                    </Form>
                                </div>
                            </div>
                            <div className='flex rounded-xl items-center'>
                                <img src={pinOrange} alt='' className='w-10' />
                                <span className='text-lg font-medium'>Pinned {pinnedFeedbacksCount < 0 ? 0 : pinnedFeedbacksCount}/5</span>
                            </div>
                        </div>
                        {loading ? <div className='mx-auto flex flex-col'><CircleFadeLoader {...loaderProps} /><span className='text-2xl font-semibold'>Loading...</span></div> : null}
                        {loading === false && searchInput.length > 0 ?
                            filteredResults?.filter(item => section == 'Replied' ? item?.reply : true).length === 0 ? <div className='mx-auto flex text-2xl font-semibold'>No feedbacks found</div>
                                : filteredResults?.filter(item => section == 'Replied' ? item?.reply : true).map((feddback, i) => {
                                    return (
                                        <div key={i}>
                                            <Row className='mt-4 ml-2 items-center'>
                                                <Col md={2} className='text-black font-medium'>
                                                    <p>{feddback.userName ? feddback.userName : "Anonymous User"}</p>
                                                    <p>{moment(feddback.updatedAt, "YYYY-MM-DD HH:mm:ss.SSSZ").format("DD/MM/YY")}</p>
                                                </Col>
                                                <Col md={4}>
                                                    <p className='text-base font-semibold'>{feddback.title}</p>
                                                </Col>
                                                {
                                                    section === "Replied"
                                                        ?
                                                        <>
                                                            <Col md={3}>
                                                                <div>
                                                                    <p className='text-right'>{feddback.reply?.comment?.length > 30 ? feddback.reply?.comment?.slice(0, 30) + '...' : feddback.reply?.comment}</p>
                                                                    <p className='text-right text-sm italic'>-{feddback.reply?.role == 'individual' ? 'user' : feddback.reply?.role}</p>
                                                                </div>
                                                            </Col>
                                                        </>
                                                        :
                                                        <>
                                                            <Col md={2}>
                                                                <span>{`${feddback.tag?.length > 1 ? feddback.tag?.length + " Tags" : feddback.tag?.length === 1 ? "1 Tag" : "0 Tag"}`}</span>
                                                            </Col>
                                                            <Col md={1}>
                                                                <img className='cursor-pointer' src={feddback.pin ? pinOrange : pinGray} alt="" onClick={() => feddback.pin ? unpinFeedback(feddback) : pinFeedback(feddback)} />
                                                            </Col>
                                                        </>
                                                }
                                                <Col md={1}>
                                                    <Button
                                                        variant="default"
                                                        type="submit"
                                                        className="bg-orange-400 text-white h-8 w-24 m-3 leading-3 rounded-lg"
                                                        onClick={() => togglePopup(feddback)}
                                                    >Comment</Button>
                                                </Col>
                                                <Col md={2} className='flex items-center relative'>
                                                    <Dropdown align="end" show={openStates[i]} onToggle={() => handleButtonClick(i)}>
                                                        <Dropdown.Toggle variant="default" className="text-white focus:outline-none focus:ring-0" >
                                                            <svg className="w-6 h-6" aria-hidden="true" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z">
                                                                </path>
                                                            </svg>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu className="border-black rounded-lg min-w-max w-44 px-2 z-20">
                                                            {userRole != 'moderator' && <p className="hover:bg-orange-200 hover:font-medium rounded-md py-1 px-2 w-full cursor-pointer" onClick={() => openReasonPopUp({ type: 'deleting', action: () => deletePost({ id: feddback.feedbackId, type: 'feedback', remove: true, callback: () => getAllFeddbacks() }) })}>Delete this post</p>}
                                                            {
                                                                feddback.flag
                                                                    ?
                                                                    <p className="hover:bg-orange-200 hover:font-medium rounded-md py-1 px-2 w-full cursor-pointer" onClick={() => openReasonPopUp({ type: 'unflagging', noPopUp: true, action: () => handleFlag({ id: feddback.feedbackId, type: 'feedback', flag: false, callback: () => getAllFeddbacks() }) })}>UnFlag this post</p>
                                                                    : <p className="hover:bg-orange-200 hover:font-medium rounded-md py-1 px-2 w-full cursor-pointer" onClick={() => openReasonPopUp({ type: 'flagging', action: () => handleFlag({ id: feddback.feedbackId, type: 'feedback', flag: true, callback: () => getAllFeddbacks() }) })}>Flag this post</p>
                                                            }
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </Col>
                                            </Row>
                                            <hr className="bg-orange-600 w-full mx-auto rounded-sm h-[1px]" />
                                        </div>
                                    )
                                })
                            : loading === false && sortedData.filter(item => section == 'Replied' ? item?.reply : true).length === 0 ? <div className='mx-auto flex text-2xl font-semibold'>Change your filter to load more feedbacks</div>
                                : sortedData && sortedData.filter(item => section == 'Replied' ? item?.reply : true).map((feddback, i) => {
                                    return (
                                        <div key={i}>
                                            <Row className='mt-4 ml-2 items-center'>
                                                <Col md={2} className='text-black font-medium'>
                                                    <p>{feddback.userName ? feddback.userName : "Anonymous User"}</p>
                                                    <p>{moment(feddback.updatedAt, "YYYY-MM-DD HH:mm:ss.SSSZ").format("DD/MM/YY")}</p>
                                                </Col>
                                                <Col md={4}>
                                                    <p className='text-base font-semibold'>{feddback.title}</p>
                                                </Col>
                                                {
                                                    section === "Replied"
                                                        ?
                                                        <>
                                                            <Col md={3}>
                                                                <div>
                                                                    <p className='text-right'>{feddback.reply?.comment?.length > 30 ? feddback.reply?.comment?.slice(0, 30) + '...' : feddback.reply?.comment}</p>
                                                                    <p className='text-right text-sm italic'>-{feddback.reply?.role == 'individual' ? 'user' : feddback.reply?.role}</p>
                                                                </div>
                                                            </Col>
                                                        </>
                                                        :
                                                        <>
                                                            <Col md={1}>
                                                                <span>{`${feddback.tag?.length > 1 ? feddback.tag?.length + " Tags" : feddback.tag?.length === 1 ? "1 Tag" : "0 Tag"}`}</span>
                                                            </Col>
                                                            <Col md={2}>
                                                                <img className='cursor-pointer' src={feddback.pin ? pinOrange : pinGray} alt="" onClick={() => feddback.pin ? unpinFeedback(feddback) : pinFeedback(feddback)} />
                                                            </Col>
                                                        </>
                                                }
                                                <Col md={1}>
                                                    <Button
                                                        variant="default"
                                                        type="submit"
                                                        className="bg-orange-400 text-white h-8 w-24 m-3 leading-3 rounded-lg"
                                                        onClick={() => togglePopup(feddback)}
                                                    >Comment</Button>
                                                </Col>
                                                <Col md={2} className='flex items-center relative justify-center'>
                                                    <Dropdown align="end" show={openStates[i]} onToggle={() => handleButtonClick(i)}>
                                                        <Dropdown.Toggle variant="default" className="text-white focus:outline-none focus:ring-0" >
                                                            <svg className="w-6 h-6" aria-hidden="true" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z">
                                                                </path>
                                                            </svg>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu className="border-black rounded-lg min-w-max w-44 px-2 z-20">
                                                            {/* <p className="hover:bg-orange-200 hover:font-medium rounded-md py-1 px-2 w-full cursor-pointer" onClick={() => handleButtonClick(i)}>Report this review</p> */}
                                                            {userRole != 'moderator' && <p className="hover:bg-orange-200 hover:font-medium rounded-md py-1 px-2 w-full cursor-pointer" onClick={() => openReasonPopUp({ type: 'deleting', action: () => deletePost({ id: feddback.feedbackId, type: 'feedback', remove: true, callback: () => getAllFeddbacks() }) })}>Delete this post</p>}
                                                            {
                                                                feddback.flag
                                                                    ?
                                                                    <p className="hover:bg-orange-200 hover:font-medium rounded-md py-1 px-2 w-full cursor-pointer" onClick={() => openReasonPopUp({ type: 'unflagging', noPopUp: true, action: () => handleFlag({ id: feddback.feedbackId, type: 'feedback', flag: false, callback: () => getAllFeddbacks() }) })}>UnFlag this post</p>
                                                                    : <p className="hover:bg-orange-200 hover:font-medium rounded-md py-1 px-2 w-full cursor-pointer" onClick={() => openReasonPopUp({ type: 'flagging', action: () => handleFlag({ id: feddback.feedbackId, type: 'feedback', flag: true, callback: () => getAllFeddbacks() }) })}>Flag this post</p>
                                                            }
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </Col>
                                            </Row>
                                            <hr className="bg-orange-600 w-full mx-auto rounded-sm h-[1px]" />
                                        </div>
                                    )
                                })}
                    </Col>
                </Row>
            </div>
            <Modal
                show={showToast}
                onHide={() => setShowToast(false)}
                dialogClassName="modal-3w"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <p className='text-lg'>You cannot pin more than 5 feedbacks</p>
                    </Modal.Title>
                </Modal.Header>
            </Modal>
        </div>
    )
}

export default ProductFeedbacks
import React from 'react';
import '../CompanyFeed/CompanyFeed.css';
import { Row, Col, Modal, Form, FormControl, Card, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import upvoteb from '../images/upvote-b.svg';
import downvoteb from '../images/downvote-b.svg';
import upvote from '../images/upvote.svg';
import downvote from '../images/downvote.svg';
import comment from '../images/comments.svg';
import share from '../images/share.svg';
import lock from '../images/lock.png';
import orangepin from '../images/pin-orange.png';
import deletei from "../images/delete-i.svg";
import axios from 'axios';
import { config } from '../Config/Config';

class PinFeedback extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deletePost: true
        }
    }
    componentDidMount() {
        console.log("props")
    }

    render() {
        const { deletePost } = this.state
        return (
            <Modal
                size="sm"
                show={deletePost}
                onHide={this.props.closePopup}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    {this.props.data ?
                        <center><span><img src={orangepin} style={{ width: "15px" }} /></span><span className="ml-3 pin-pop-text">Post Pinned Successfully</span><br /></center>
                        : <center><span><img src={orangepin} style={{ width: "15px" }} /></span><span className="ml-3 pin-pop-text">Post Unpinned Successfully</span><br /></center>}
                </Modal.Body>
            </Modal>
        );
    }
}

export default PinFeedback;


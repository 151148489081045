import React, { useEffect, useState } from 'react'

function UpgradeContact({ onClose }) {
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
        if (isCopied) {
            setTimeout(() => setIsCopied(false), 2000)
        }
    }, [isCopied])


    return (
        <div className='fixed inset-0 bg-transparent grid place-items-center' style={{ zIndex: 12000 }}>
            <div className='bg-black bg-opacity-60 fixed inset-0' onClick={onClose}></div>
            <div className='bg-[#FFB633] w-full max-w-3xl z-10 rounded-lg min-h-[420px] p-4 relative opacTranslate'>
                <h1 className='text-3xl font-bold max-w-[31rem] text-white'>Upgrade now & unlock more from feddup!</h1>
                <div className='text-white flex flex-col items-center justify-center gap-5 mt-8'>
                    <h1 className='text-2xl font-medium'>Write to us at</h1>
                    <div className='flex items-center gap-8 relative cursor-pointer' onClick={() => { navigator.clipboard.writeText('fred@feddup.me'); setIsCopied(true) }}>
                        <p className='text-4xl font-bold'>fred@feddup.me</p>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-gray-50">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z" />
                        </svg>
                        {isCopied ? <div className='absolute -top-8 right-0 bg-orange px-4 py-1.5 text-white font-medium text-sm rounded-full z-50'>
                            <h1>Copied</h1>
                        </div> : ''}
                    </div>
                    <p className='text-2xl font-medium'>For more details on pricing and arrange a demo.</p>
                    <button className='py-2 px-10 rounded-lg bg-orange font-medium text-lg mt-4 outline-none border-none' onClick={onClose}>Close</button>
                </div>

            </div>
        </div>
    )
}

export default UpgradeContact
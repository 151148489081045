import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import './CompanyFeed.css';
import imageIcon from '../images/image-icon.svg';

const fileTypes = ["JPG", "PNG"];

function AddImage({ editById, imageUrl, onChangeImage }) {
    const [file, setFile] = useState(null);
    const [image, setImage] = useState("");
    const handleChange = async (file) => {
        setFile(file);
        console.log("file", file)
        if (file) {
            const res = await uploadImage(file);
            if (res) {
                console.log("res", res)
                setImage(res)
            }
            onChangeImage(res)
        }
    };
    const deleteImage = () => {
        setFile(null);
        setImage("")
    }
    const uploadImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "feddUp")
        data.append("cloud_name", "feddup")
        const resp = await fetch(`https://api.cloudinary.com/v1_1/feddup/image/upload`, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        return res.secure_url
    }


    return (
        <>
            <FileUploader
                multiple={false}
                handleChange={handleChange} // function to get uploaded files
                name="file"
                className="w-full"
                children={
                    <div className="drag-drop">
                        <div className="i-flex">
                            <img src={imageIcon} style={{ marginLeft: "5px", marginTop: "-5px", width: "20px" }} />
                            <div className="ml-2 b-image"><span className="ml-2">Choose a file or drag and drop here</span></div>
                        </div>
                        <div style={{ fontSize: "12px", color: "grey", float: "right" }}>Only JPG's,PNG's are allowed</div>
                    </div>}
                types={["JPG", "PNG", 'JPEG']}
            /><br />
            <>{file ? <div><img src={image} style={{ width: "200px" }} /><br /><span style={{ marginLeft: "23%", cursor: "pointer" }} onClick={deleteImage}><i class="fa fa-trash-o" style={{ cursor: "pointer" }} aria-hidden="true"></i></span></div> :
                <>
                    {imageUrl != null ? <div><img src={imageUrl} style={imageUrl ? { width: "150px", height: "100px" } : { width: "0" }} /><br /><span style={{ marginLeft: "23%", cursor: "pointer" }} ><i class="fa fa-trash-o" style={imageUrl ? { cursor: "pointer" } : { display: "none" }} onClick={() => editById("img")} aria-hidden="true"></i></span></div> : ""}
                </>
            }</>
        </>

    );
}

export default AddImage;
{/* <>{file ? <div><img src={image} style={{ width: "200px" }} /><br /><span style={{ marginLeft: "23%", cursor: "pointer" }} onClick={deleteImage}><i class="fa fa-trash-o" style={{ cursor: "pointer" }} aria-hidden="true"></i></span></div> :
<>
    {imageUrl!=null ? <div><img src={imageUrl} style={{ width: "150px", height: "100px" }} /><br /><span style={{ marginLeft: "23%", cursor: "pointer" }} onClick={() => editFeedbackById("img")}><i class="fa fa-trash-o" style={{ cursor: "pointer" }} aria-hidden="true"></i></span></div> : ""}
</>
}</> */}
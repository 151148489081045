import React, { useEffect, useState } from 'react'
import NavBar from '../NavBar/NavBar'
import background from '../images/background-img.svg'
import arrowLeft from "../images/arrow-left-black.svg"
import axios from 'axios'
import camera from "../images/camera.svg";
import { config } from '../Config/Config'
import { Button, Card, Form, Modal } from 'react-bootstrap'
import premiumRotated from "../images/premium-rotated.svg"
import uploaded from "../images/uploaded-tick.svg";
import { Link } from 'react-router-dom'
import UpgradePopUp from '../Terms/UpgradePopUp'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { decrypt } from '../utils'

function EditCompanyDetails() {
    const history = useHistory()
    const [membershipType, setMembershipType] = useState("Premium")
    const [companyId, setCompanyId] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [companyWebsite, setCompanyWebsite] = useState("")
    const [companyIcon, setCompanyIcon] = useState("")
    const [companyHeadquarter, setCompanyHeadquarter] = useState("")
    const [companyDescription, setCompanyDescription] = useState("")
    const [showDowngradeModal, setShowDowngradeModal] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [websiteError, setWebsiteError] = useState("")
    const [headquarterError, setHeadquarterError] = useState("")
    const [image, setImage] = useState("");
    const [isUpgrade, setIsUpgrade] = useState(false);
    const [pages, setPages] = useState([])
    const [pageModal, setPageModal] = useState(false)

    useEffect(() => {
        try {
            window.scrollTo(0, 0);
            getCompanyDetails();
            getAllProducts();
        } catch (error) {
            console.log(error);
        }
    }, [])

    const getAllProducts = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'admin/allProducts', { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    setPages(response?.data?.products)
                } else {
                    console.log(response.data.message)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const getCompanyDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'admin/getCompanyProfileDetailsByAdmin', { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    setCompanyName(response.data.companyDetail.companyName);
                    response.data.companyDetail.companyWebsite && setCompanyWebsite(response.data.companyDetail.companyWebsite);
                    setCompanyIcon(response.data.companyDetail.companyIcon);
                    response.data.companyDetail.companyheadquarter && setCompanyHeadquarter(response.data.companyDetail.companyheadquarter);
                    setCompanyId(response.data.companyDetail.companyId);
                    response.data.companyDetail.companyDescription && setCompanyDescription(response.data.companyDetail.companyDescription);
                    setMembershipType(response.data.companyDetail.membershipType);
                } else {
                    console.log(response.data.message)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    let updateCompanyDetails = (e) => {
        // console.log("Compnay Details Posted");
        e.preventDefault();
        if (!verifyFields()) return;
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "companyIcon": companyIcon,
            "companyWebsite": companyWebsite,
            "companyheadquarter": companyHeadquarter,
            "description": companyDescription
        }
        axios.post(config.userUrl + 'admin/updateCompanyDetails', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("Company details updated successfully!!");
                    setShowSuccess(true);
                    setTimeout(() => { setShowSuccess(false) }, 3000)

                } else {
                    console.log(response.data.message)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    const downgradeCompanyMembership = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "companyId": companyId,
            pIds: pages?.map(el => !el.selected)
        }
        axios.post(config.userUrl + "admin/downgradeCompanyMembership", payload, { headers: headers })
            .then(function (response) {
                console.log("getRatigetAllUsersRegionBase", response);
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    setMembershipType("Free")
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const verifyFields = () => {
        let result = true;
        if (companyWebsite.length === 0) {
            setWebsiteError("Please enter a valid address")
            result = false;
        }
        if (companyHeadquarter.length === 0) {
            setHeadquarterError("Please enter a headquarter")
            result = false;
        }
        return result;
    }


    const handleClick = (i) => {
        let arr = [...pages];
        arr[i].selected = !Boolean(pages[i]?.selected)
        setPages(arr)
    }

    const handleDowngrade = () => {
        setPageModal(false);
        setShowDowngradeModal(true);
    }


    return (
        <div className='bg-gray-100 min-h-screen'>

            {pageModal ? <div className='fixed inset-0 grid place-items-center' style={{ zIndex: 9900 }}>
                <div className='fixed inset-0 bg-black opacity-50' onClick={() => setPageModal(false)}></div>

                <div className='relative bg-white rounded-xl p-4 w-full max-w-xl opacAnimation'>
                    <h1 className='text-2xl text-gray-800 font-medium'>Select your 3 favorite pages</h1>
                    <p className='font-light text-gray-500 italic max-w-lg'>Pages selected will be left in account. If a private page is selected it will be converted to a public page. Pages not selected will be deleted data will NOT be recoverable.</p>
                    <p className='text-lg text-right'>{pages?.filter(el => el?.selected)?.length}/3 pages selected</p>
                    <p className='text-sm text-right italic text-orange-600'>If no page selected all created pages will be deleted</p>

                    <div className='rounded-lg h-[300px] bg-[#FFF1ED] p-3 overflow-y-auto scroll-hidden mt-2 mb-4'>
                        <div className='grid grid-cols-2 items-start justify-start gap-4'>

                            {pages?.map((item, i) => <div onClick={() => pages?.filter(el => el?.selected)?.length < 3 ? handleClick(i) : {}} key={i} className='flex items-center gap-2 font-medium cursor-pointer max-w-max'>
                                <div className={`w-4 h-4 rounded-sm ${item?.selected ? 'bg-orange-500' : 'bg-gray-300'}`}></div>
                                <h1>{item?.productName}</h1>
                            </div>)
                            }
                        </div>
                    </div>

                    <div className='flex justify-between'>
                        <button className='py-1 px-2 rounded-lg text-white outline-none border-none bg-[#649E36]' onClick={() => setPageModal(false)}>Cancel, Do not downgrade</button>
                        <button className='py-1 px-2 rounded-lg text-white outline-none border-none bg-orange-500 disabled:bg-gray-500' onClick={() => handleDowngrade()}>Downgrade with selected pages</button>
                    </div>
                </div>
            </div> : ''}
            {showDowngradeModal ? <div className='fixed inset-0 grid place-items-center' style={{ zIndex: 12000 }}>
                <div className='fixed inset-0 bg-black opacity-50' onClick={() => setShowDowngradeModal(false)}></div>

                <div className='relative bg-[#E6570E] text-white rounded-xl p-4 w-full max-w-2xl flex flex-col items-center justify-center opacAnimation'>
                    <svg className='my-3' width="150" height="150" viewBox="0 0 175 158" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M87.6274 62.6095V95.2238M6.71768 124.585C-0.814064 137.631 8.60497 153.93 23.6598 153.93H151.595C166.641 153.93 176.06 137.631 168.537 124.585L104.578 13.714C97.0464 0.668251 78.2084 0.668251 70.6766 13.714L6.71768 124.585ZM87.6274 121.315H87.6883V121.385H87.6274V121.315Z" stroke="#FFB633" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                    <h1 className='text-2xl font-medium'>Are you sure you want to downgrade your account</h1>
                    <p className='font-light italic max-w-lg mt-1'>The downgrade will take affect after the current billing cycle is completed</p>

                    <div className='flex justify-between w-full mt-20'>
                        <button className='py-1.5 text-lg px-10 rounded-lg text-white outline-none border-none bg-[#649E36]' onClick={() => setShowDowngradeModal(false)}>Cancel</button>
                        <button className='py-1.5 text-lg px-10 rounded-lg text-white outline-none border-none bg-[#FFB633]' onClick={() => { setShowDowngradeModal(false); downgradeCompanyMembership() }}>Confirm</button>
                    </div>
                </div>
            </div> : ''}

            <NavBar />
            <div className=" relative pb-9" >
                <img src={background} className='w-full mt-12' alt="" />
                <Link to='/admindashboard'>
                    <img src={arrowLeft} alt='' className='absolute top-[5vh] left-[5vw]' />
                </Link>

                <div className='transform -translate-y-14 max-w-3xl mx-auto'>

                    <div className=' flex items-center gap-4 min-w-max'>
                        <div className='w-32 h-32 rounded-full bg-white overflow-hidden'>
                            {companyIcon ?
                                <label htmlFor="imageInput" className='flex flex-col group bg-white rounded-full w-full h-full relative cursor-pointer'>
                                    <img src={image ? URL.createObjectURL(image) : companyIcon} alt="" className="w-auto h-full object-contain group-hover:opacity-4" />
                                    <div className="absolute z-10 inset-0 w-full h-full px-2 flex justify-center items-center text-center opacity-0 group-hover:opacity-100 group-hover:bg-black group-hover:bg-opacity-60 rounded-full text-sm text-white font-bold">
                                        Upload new logo
                                    </div>
                                    <input id="imageInput" type='file' accept="image/png, image/jpeg, image/jpg" className="hidden" onChange={(e) => { setImage(e.target.files[0]) }} />
                                </label>
                                :
                                <label htmlFor="fileInput" className='flex flex-col bg-white rounded-full w-full h-full relative group cursor-pointer'>
                                    <img src={image ? URL.createObjectURL(image) : camera} alt="" className="w-auto h-full object-contain group-hover:opacity-40" />
                                    <div className="absolute z-10 inset-0 w-full h-full flex justify-center items-center text-center opacity-0 px-3 group-hover:opacity-100 group-hover:bg-black group-hover:bg-opacity-60 rounded-full text-sm text-white font-semibold">
                                        Upload new logo
                                    </div>
                                    <input id="fileInput" type='file' accept="image/png, image/jpeg, image/jpg" className="hidden" onChange={(e) => { setImage(e.target.files[0]) }} />
                                </label>
                            }
                        </div>
                        <p className="pt-6 text-2xl font-medium">{companyName}</p>
                    </div>
                </div>
                <div className='flex flex-col gap-6 max-w-2xl  mx-auto transform -translate-y-6'>
                    <form>
                        <Card className="border mb-3">
                            <textarea
                                className="form-control text-black"
                                style={{ fontFamily: "poppins", resize: "none" }}
                                rows="4"
                                maxLength={280}
                                value={companyDescription}
                                name="description"
                                onChange={(e) => setCompanyDescription(e.target.value)}
                                placeholder="Company Description"
                            />
                        </Card>
                        <Card className="border mb-3">
                            <textarea
                                className={`form-control text-black ${websiteError === "" ? "" : "border-red-600"}`}
                                style={{ fontFamily: "poppins", resize: "none" }}
                                rows="1"
                                maxLength={150}
                                value={companyWebsite}
                                name="website"
                                onChange={(e) => { setCompanyWebsite(e.target.value); setWebsiteError("") }}
                                placeholder="Company Website"
                            />
                            <div>{websiteError && (
                                <div className="error">{websiteError}</div>
                            )}</div>
                        </Card>
                        <Card className="border mb-3">
                            <textarea
                                className={`form-control text-black ${headquarterError === "" ? "" : "border-red-600"}`}
                                style={{ fontFamily: "poppins", resize: "none" }}
                                rows="1"
                                maxLength={150}
                                value={companyHeadquarter}
                                name="headquarter"
                                onChange={(e) => { setCompanyHeadquarter(e.target.value); setHeadquarterError("") }}
                                placeholder="Company Headquarter"
                            />
                            <div>{headquarterError && (
                                <div className="error">{headquarterError}</div>
                            )}</div>
                        </Card>
                        <div className='btn-outer ml-auto my-4'>
                            <div className='btn-1'>
                            </div>
                            <Button
                                variant="default" size="md"
                                type="submit"
                                className="btn-2 "
                                onClick={updateCompanyDetails}
                            >
                                Update Page
                            </Button>
                        </div>
                    </form>
                    {membershipType === "Premium" ?
                        <div className='flex flex-col mb-20'>
                            <div className='flex'>
                                <div className='flex flex-col w-full'>
                                    <p className='text-lg font-bold'>Manage User access</p>
                                    <p className='text-md font-normal'>Manage Admin, sub-admin and Moderators across Organizaition</p>
                                    <div className='flex justify-between mt-4'>
                                        <Link to="/admin/manageusers" className='mt-4 mx-auto'>
                                            <div className='btn-outer'>
                                                <div className='btn-1' style={{ width: "180px" }}>
                                                </div>
                                                <Button
                                                    variant="default" size="md"
                                                    type="submit"
                                                    className="btn-2 "
                                                    style={{ width: "180px" }}
                                                // onClick={postAnnouncement}

                                                >
                                                    Manage Users
                                                </Button>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div> : <></>}
                </div>
                <hr className="bg-orange-600  max-w-3xl mx-auto rounded-sm h-[1px] my-4" />
                {membershipType === "Free" ?
                    <div className='flex flex-col justify-center items-center mb-16'>
                        <div className='flex gap-4 max-w-3xl'>
                            <img src={premiumRotated} alt='' className='relative -top-10' />
                            <div className='flex flex-col'>
                                <p className='text-lg font-bold'>Upgrade Page</p>
                                <p className='text-md font-medium'>You are on a free Organization account. Upgrade to Premium and use feddup to the fullest...</p>
                                <div className='flex items-center gap-40'>
                                    <Link to="/pricing">
                                        <div className='btn-outer mt-4'>
                                            <div className='btn-1' style={{ width: "180px" }}>
                                            </div>
                                            <Button
                                                variant="default" size="md"
                                                type="submit"
                                                className="btn-2 "
                                                style={{ width: "180px", fontSize: "12px" }}
                                            // onClick={postAnnouncement}

                                            >
                                                View features and pricing
                                            </Button>
                                        </div>
                                    </Link>
                                    <div className='btn-outer mt-4'>
                                        <div className='btn-1' style={{ width: "200px" }}>
                                        </div>
                                        <Button
                                            variant="default" size="md"
                                            type="submit"
                                            className="btn-2 "
                                            style={{ width: "200px", fontSize: "12px" }}
                                            onClick={() => setIsUpgrade(true)}
                                        >
                                            Upgrade your Organization
                                        </Button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='flex flex-col max-w-4xl mx-auto mb-32'>
                        <div className='flex gap-4 max-w-3xl mx-auto mb-12'>
                            <img src={premiumRotated} alt='' className='relative -top-10' />
                            <div className='flex flex-col'>
                                <p className='text-lg font-bold'>You are a Premium Organization</p>
                                <p className='text-md font-medium'>Premium organizations unlock more features such as private feddup pages, adding sub admins, moderators. Youcan create unlimited feddup pages</p>
                                <div className='flex justify-end'>
                                    <div className='btn-outer mt-4'>
                                        <div className='btn-1' style={{ width: "180px" }}>
                                        </div>
                                        <Button
                                            variant="default" size="md"
                                            type="submit"
                                            className="btn-2 "
                                            style={{ width: "180px" }}
                                            onClick={() => history.push('/admin/billing')}
                                        >
                                            View Billing
                                        </Button></div>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-4 max-w-4xl ml-14'>
                            <p className='text-lg font-medium'>Downgrade your Organization to free tier</p>
                            <div className='flex justify-between gap-6 items-center'>
                                <p className='max-w-3xl'>Downgrading to free tier will convert all pages to free plan, generic feddup form will be used instead of custom forms created by you.
                                    Private Pages will be made public moderators will be removed from Organization</p>

                                <Button
                                    variant="default" size="md"
                                    type="submit"
                                    style={{ float: "right", width: "120px", height: "35px", lineHeight: "14px", fontSize: "18px" }}
                                    className='bg-orange-600 rounded-xl text-white'
                                    onClick={() => setPageModal(true)}
                                >
                                    Downgrade
                                </Button>
                            </div>
                        </div>
                    </div>}
                <hr className="bg-orange-600  max-w-3xl mx-auto rounded-sm h-[1px] mb-4" />
                <div className='flex flex-col w-full gap-4 mb-8 items-center absolute bg-gray-100 pb-20 '>
                    <div className='flex flex-col gap-2'>
                        <p className='text-2xl font-semibold mb-4'>Company state</p>
                        <p className='text-lg font-semibold'>Deactivate Company</p>
                        <div className='flex items-center gap-20'>
                            <p className='text-md font-medium w-[35rem] text-gray-700'>All the pages created under this firm will be unlisted until company is reactivated.
                                Users  will not be alb eto post anything in the pages</p>
                            <Button
                                variant="default" size="md"
                                type="submit"
                                style={{ float: "right", width: "120px", height: "35px", lineHeight: "14px", fontSize: "18px" }}
                                className='bg-orange-400 rounded-xl text-white'
                            >
                                Deactivate
                            </Button>
                        </div>
                        <p className='text-lg font-semibold mt-10'>Delete Company</p>
                        <div className='flex items-center gap-20'>
                            <p className='text-md font-medium w-[35rem] text-gray-700'>All the pages admin sub admin and moderators added to firm will be deleted.
                                You can recreate the company but will NOT be able to use the same email as before</p>
                            <Button
                                variant="default" size="md"
                                type="submit"
                                style={{ float: "right", width: "120px", height: "35px", lineHeight: "14px", fontSize: "18px" }}
                                className='bg-orange-600 rounded-xl text-white'
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                </div>
                <Modal show={showSuccess} onHide={() => setShowSuccess(false)} size="md" centered >
                    <Modal.Body>
                        <div className='flex flex-col gap-4 justify-center items-center'>
                            <img src={uploaded} alt='' />
                            <p className='text-2xl font-semibold text-orange-600'>Company details updated successfully</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            {
                isUpgrade ? <UpgradePopUp onClose={() => setIsUpgrade(false)} /> : <></>
            }
        </div>
    )
}

export default EditCompanyDetails
import React, { useEffect, useState } from 'react'
import NavBar1 from '../NavBar/NavBar1'
import arrowLeft from "../images/arrow-left-black.svg"
import axios from 'axios'
import { config } from '../Config/Config';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import rating1 from '../images/rating1.png';
import rating2 from '../images/rating2.png';
import rating3 from '../images/rating3.png';
import rating4 from '../images/rating4.png';
import rating5 from '../images/rating5.png';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar, Pie } from 'react-chartjs-2';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { decrypt, geoUrl } from '../utils';
import { scaleLinear } from 'd3-scale';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

function ProductInsight() {
    const history = useHistory();
    const { productId, versionId } = useParams();
    const [productIcon, setProductIcon] = useState('');
    const [productName, setProductName] = useState('');
    const [productType, setProductType] = useState('');
    const [version, setVersion] = useState('');
    const [versionNumberOfRatings, setVersionNumberOfRatings] = useState('');
    const [versionRatingCountArray, setVersionRatingCountArray] = useState([]);
    const [latestVersion, setLatestVersion] = useState(false);
    const [versionMapData, setVersionMapData] = useState([]);
    const [productNumberOfRatings, setProductNumberOfRatings] = useState('');
    const [productRatingCountArray, setProductRatingCountArray] = useState([]);
    const [productMapData, setProductMapData] = useState([]);
    const [usersCount, setUsersCount] = useState([]);
    const [quesData, setQuesData] = useState([10, 12, 15]);
    const [form, setForm] = useState();
    const [feedbacks, setFeedbacks] = useState([])
    const [formLoading, setFormLoading] = useState(true);
    const [genericRating, setGenericRating] = useState(0)
    const [responseType, setResponseType] = useState(1)

    useEffect(() => {
        getVersionForm()
        getProductAndVersionRatings();
        getRegionCountByProductIdAndVersionId();
        getUserCountsByVersion();
    }, [])
    const getProductAndVersionRatings = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "productId": productId,
            "versionId": versionId
        }
        axios.post(config.userUrl + "admin/getProductAndVersionrating", payload, { headers: headers })
            .then((response) => {
                setProductIcon(response.data.productDetails.productIcon);
                setProductName(response.data.productDetails.productName);
                setProductType(response.data.productDetails.productType);
                setVersion(response.data.versionDetails.version);
                setLatestVersion(response.data.versionDetails.latestVersion);
                let arr = [...response.data.versionNumberOfRating];
                arr = arr.map((item) =>
                    Number(item.count)
                )
                let ratingData = Math.max(...arr)
                setVersionNumberOfRatings(ratingData + 3);
                setVersionRatingCountArray(response.data.versionNumberOfRating)
                let arr2 = [...response.data.productNumberOfRating];
                arr2 = arr2.map((item) =>
                    Number(item.count)
                )
                let ratingData2 = Math.max(...arr2)
                setProductNumberOfRatings(ratingData2 + 3);
                setProductRatingCountArray(response.data.productNumberOfRating)
            })
            .catch((err) => {
                console.log(err)
            });
    };

    const getRegionCountByProductIdAndVersionId = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "productId": productId,
            "versionId": versionId
        }

        axios.post(config.userUrl + "admin/getAllUsersRegionBaseByProductIdAndVersionId", payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setProductMapData(response.data.productReviewers)
                    setVersionMapData(response.data.versionReviewers);
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    const getVersionForm = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "productId": productId,
            "versionId": versionId,
        }
        axios.post(config.userUrl + "admin/getFormByVersion", payload, { headers: headers })
            .then(function (response) {
                if (response.data.form) {
                    setForm(response.data?.form)
                    setFeedbacks(response.data?.feedback);
                    if (response.data?.feedback) {
                        let arr = barGraph(response?.data?.feedback)
                        setQuesData(arr);
                    }
                }
                setFormLoading(false)
            })
            .catch(function (error) {
                console.log("Error", error);
                setFormLoading(false)
            });
    };
    const getUserCountsByVersion = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "productId": productId,
            "versionId": versionId
        }
        axios.post(config.userUrl + "admin/getUserCounts", payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setUsersCount([parseInt(response.data.usersCount[0]["anonymous_users"]), parseInt(response.data.usersCount[0]["registered_users"])]);
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    const data = {
        labels:
            ["User(s)", "User(s)", "User(s)", "User(s)", "User(s)"],
        datasets: [
            {
                data: [...versionRatingCountArray.map((data) => data.count), versionNumberOfRatings],
                borderColor: "#FFB633",
                backgroundColor: ["#FFB633"],
                borderRadius: 20,
                borderWidth: 1,
                barThickness: 20
            },
        ]
    }

    const pieChartData2 = {
        datasets: [
            {
                label: '# of Votes',
                data: [feedbacks?.filter(item => !item?.responses)?.length, feedbacks?.filter(item => item?.responses)?.length],
                backgroundColor: [
                    '#E6570E',
                    '#FFB633'
                ],
                borderColor: [
                    '#E6570E',
                    '#FFB633'
                ],
                borderWidth: 1,
            },
        ],
    };

    const pieChartData = {
        datasets: [
            {
                label: '# of Votes',
                data: [...usersCount],
                backgroundColor: [
                    '#E6570E',
                    '#FFB633'
                ],
                borderColor: [
                    '#E6570E',
                    '#FFB633'
                ],
                borderWidth: 1,
            },
        ],
    };
    const productData = {
        labels:
            ["User(s)", "User(s)", "User(s)", "User(s)", "User(s)"],
        datasets: [
            {
                data: [...productRatingCountArray.map((data) => data.count), productNumberOfRatings],
                borderColor: "#FFB633",
                backgroundColor: ["#FFB633"],
                borderRadius: 20,
                borderWidth: 1,
                barThickness: 20
            },
        ]
    }
    const getBackgroundColor = (data) => {
        if (data[0] === data[1] === data[2]) {
            return ["#04C700", "#04C700", "#04C700"]
        } else if (data[0] === data[1] && data[0] > data[2]) {
            return ["#04C700", "#04C700", "#FFB633"]
        } else if (data[0] === data[2] && data[0] > data[1]) {
            return ["#04C700", "#FFB633", "#04C700"]
        } else if (data[1] === data[2] && data[1] > data[0]) {
            return ["#FFB633", "#04C700", "#04C700"]
        } else if (data[0] > data[1] && data[0] > data[2]) {
            if (data[1] > data[2]) return ["#04C700", "#E6570E", "#FFB633"]
            else if (data[1] === data[2]) return ["#04C700", "#FFB633", "#FFB633"]
            else return ["#04C700", "#FFB633", "#E6570E"]
        } else if (data[1] > data[0] && data[1] > data[2]) {
            if (data[0] > data[2]) return ["#E6570E", "#04C700", "#FFB633"]
            else if (data[0] === data[2]) return ["#FFB633", "#04C700", "#FFB633"]
            else return ["#FFB633", "#04C700", "#E6570E"]
        } else if (data[2] > data[0] && data[2] > data[1]) {
            if (data[0] > data[1]) return ["#E6570E", "#FFB633", "#04C700"]
            else if (data[0] === data[1]) return ["#FFB633", "#FFB633", "#04C700"]
            else return ["#FFB633", "#E6570E", "#04C700"]
        } else
            return ["#FFB633", "#FFB633", "#FFB633"]
    }
    const questionsData = {
        labels: ["Q1", "Q2", "Q3"],
        datasets: [
            {
                data: [...quesData, 18],
                // borderColor: ["#04C700", "#E6570E", "#FFB633"],
                backgroundColor: getBackgroundColor(quesData),
                borderRadius: 10,
                borderWidth: 1,
                barThickness: 15
            },
        ]
    }
    const dataLabelPlugin = {
        afterDatasetsDraw: (chart) => {
            const ctx = chart.ctx;
            chart.data.datasets.forEach((dataset, index) => {
                const meta = chart.getDatasetMeta(index);
                meta.data.forEach((bar, index) => {
                    const data = dataset.data[index];
                    ctx.fillText(data, bar.x - 7, bar.y - 5);
                });
            });
        }
    };
    const getMostAnsweredQues = () => {
        if (quesData[0] > quesData[1] && quesData[0] > quesData[2]) return "Q1";
        else if (quesData[1] > quesData[0] && quesData[1] > quesData[2]) return "Q2";
        else if (quesData[2] > quesData[0] && quesData[2] > quesData[1]) return "Q3";
        else if (quesData[1] === quesData[0] && quesData[1] === quesData[2]) return "Q1";
        else return "No Question"
    };
    const getMostSkippedQues = () => {
        if (quesData[0] < quesData[1] && quesData[0] < quesData[2]) return "Q1";
        else if (quesData[1] < quesData[0] && quesData[1] < quesData[2]) return "Q2";
        else if (quesData[2] < quesData[0] && quesData[2] < quesData[1]) return "Q3";
        else if (quesData[1] === quesData[0] && quesData[1] === quesData[2]) return "Q1";
        else return "No Question"
    };
    const colorScale = scaleLinear()
        .domain([0, Math.max(...versionMapData.map(d => responseType == 2 ? d.sadRating : d.happyRating))])
        .range(["#FF8B66", " #E6570E"]);


    const getAnswerCount = (que, options) => {
        let arr = feedbacks?.filter(item => item.responses);
        let count = 0;

        let obj = {}
        if (options?.length) {

            obj = options.reduce((acc, val) => {
                acc[`${val?.toString()}`] = 0;
                return acc;
            }, {});

            for (let item of arr) {
                if (Array.isArray(item?.responses))
                    item.responses?.map((data, i) => {
                        if (data[i + 1].type == 'generic' || data[i + 1].type == 'textfield') {

                        }
                        else {
                            if (data[i + 1].type == 'rating' && data[i + 1].question == que.question) {
                                if (options[data[i + 1]?.response - 1])
                                    obj[options[data[i + 1]?.response - 1]] += 1
                                else if (options[data[i + 1]?.resposne - 1])
                                    obj[options[data[i + 1]?.resposne - 1]] += 1
                            }
                            else {
                                if (data[i + 1].question == que.question) {
                                    obj[data[i + 1]?.response] += 1
                                }
                            }

                            if (data[i + 1].type == 'mcq') {
                                if (data[i + 1].question == que.question) {
                                    let answers = []
                                    if (data[i + 1]?.response) {
                                        answers = data[i + 1]?.response?.split(',').filter(el => el.trim()).map(el => el.trim());
                                    }
                                    else if (data[i + 1]?.resposne) {
                                        answers = data[i + 1]?.resposne?.split(',').filter(el => el.trim()).map(el => el.trim());
                                    }
                                    if (answers?.length) {
                                        for (let index = 0; index < data[i + 1]?.options?.length; index++) {
                                            const element = data[i + 1]?.options[index];
                                            if (answers.includes(element)) {
                                                obj[element] += 1
                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }
                    )
            }
        }

        return Object.keys(obj).map(key => obj[key])
    }


    const barGraph = (feedbackArr) => {
        let arr = feedbackArr?.filter(item => item.responses);
        let totalRating = 0;
        let ratingCount = 0;

        let obj = {
            1: 0,
            2: 0,
            3: 0,
        }
        for (let item of arr) {
            item.responses.map((data, i) => {
                if (data[i + 1] && data[i + 1]?.type !== 'generic') {

                    if (data[i + 1]?.response)
                        obj[i + 1] += 1
                    else if (data[i + 1]?.resposne)
                        obj[i + 1] += 1
                }
                else {
                    if (data[i + 1]?.response) {
                        totalRating += (data[i + 1]?.response?.rating || 0)
                        ratingCount += 1
                    }
                    else if (data[i + 1]?.resposne) {
                        totalRating += (data[i + 1]?.resposne?.rating || 0)
                        ratingCount += 1
                    }
                }
            }
            )
        }

        setGenericRating((totalRating / ratingCount).toFixed(2))
        return Object.keys(obj).map(key => obj[key])
    }

    return (
        <div className='bg-gray-100 min-h-[100dvh] overflow-y-auto'>
            <NavBar1 />

            <div className='pl-16 pr-24 mt-20 pt-6 pb-12'>
                <div className='flex gap-4 items-center mb-3'>
                    <img src={arrowLeft} alt='' onClick={() => history.goBack()} className='cursor-pointer' />
                    <div className='bg-white rounded-full' style={{ width: "100px", height: "100px" }}>
                        <img src={productIcon} alt='' />
                    </div>
                    <div className='flex flex-col'>
                        <span className='text-2xl font-semibold'>{productName}</span>
                        <span className='text-xl font-medium'>{productType === 1 ? "Desktop Application" : "Web Application"}</span>
                    </div>
                </div>
                <Row className='mb-8'>
                    <Col md={4}>
                        <div className='flex flex-col justify-center items-start'>
                            <p className='text-orange-600 text-3xl font-semibold'>Version: {version} {latestVersion ? "(Latest)" : ""}</p>
                            <p className='text-orange-400 text-3xl font-semibold'>Insights</p>
                            <p className='text-lg'>Showing for current active form</p>
                        </div>
                    </Col>
                </Row>
                {
                    formLoading ?
                        <h1 className='text-3xl font-medium text-gray-400'>Loading...</h1>
                        :
                        form
                            ?
                            <>
                                <div className='mt-4 mb-12 w-full grid grid-cols-3 gap-5'>
                                    <div md={4} xl={3}>
                                        {usersCount.length === 0 ?
                                            <div className='flex flex-col bg-white rounded-lg px-5 py-3 min-h-[320px]'>
                                                <p className=' font-medium p-1'>Posts based on registrations</p>
                                                <div className='flex justify-center items-center w-full h-full flex-grow'>
                                                    <div className='-rotate-45 transform text-black text-xl font-medium italic'>
                                                        Insufficient data
                                                    </div>
                                                </div>
                                            </div> :
                                            <div className='bg-white rounded-lg px-5 py-4 min-h-[320px]'>
                                                <p className='font-medium text-left'>Posts based on registrations</p>
                                                <div className='flex flex-col items-center '>
                                                    <div className='w-[240px]'>
                                                        <Pie data={pieChartData} />
                                                    </div>
                                                    <div className='flex justify-around items-center mt-3 gap-12 text-sm'>
                                                        <div className='flex items-center gap-2'>
                                                            <span className='bg-orange-400 rounded-full w-4 h-4'></span>
                                                            <span>Registered</span>
                                                        </div>
                                                        <div className='flex items-center gap-2'>
                                                            <span className='bg-orange-600 rounded-full w-4 h-4'></span>
                                                            <span>Anonymous</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>
                                    <div md={4} xl={3}>
                                        {quesData.length === 0 ?
                                            <div className='flex flex-col bg-white rounded-lg py-3 min-h-[320px]'>
                                                <p className='font-medium p-1'>Form answered rate</p>
                                                <div className='flex justify-center items-center w-full h-full'>
                                                    <div className='-rotate-45 transform text-black text-xl font-medium italic'>
                                                        Insufficient data
                                                    </div>
                                                </div>
                                            </div> :
                                            <div className="bg-white rounded-lg min-h-[320px] flex flex-col justify-between px-5 py-4 w-full">
                                                <div className="flex  justify-between">
                                                    <p className='font-medium'>Form answered rate</p>
                                                    <p className='text-[10px] font-light'>*Generic question not included</p>
                                                </div>
                                                <div className="relative w-full flex flex-col gap-4 items-center justify-center">
                                                    <div className='w-[240px]'>
                                                        <Bar data={questionsData} plugins={[dataLabelPlugin]}
                                                            options={{
                                                                indexAxis: 'x',
                                                                aspectRatio: 1,
                                                                responsive: true,
                                                                scales: {
                                                                    x: {
                                                                        ticks: {
                                                                            display: true
                                                                        },
                                                                        grid: {
                                                                            drawBorder: false,
                                                                            display: false,
                                                                        },
                                                                        barPercentage: 0.8,
                                                                        categoryPercentage: 0.8
                                                                    },

                                                                    y: {
                                                                        ticks: {
                                                                            display: false,
                                                                        },
                                                                        grid: {
                                                                            drawBorder: false,
                                                                            display: false,
                                                                        },
                                                                    },
                                                                },
                                                                plugins: {
                                                                    tooltip: {
                                                                        enabled: false
                                                                    },
                                                                    legend: {
                                                                        display: false
                                                                    }
                                                                }
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                    <div className='flex flex-col items-center'>
                                                        {
                                                            !quesData[0] || !quesData[1] || !quesData[1] ?
                                                                <></>
                                                                :
                                                                <>
                                                                    <p className='text-xs font-light'>{getMostAnsweredQues()} was the most answered question in the form</p>
                                                                    {
                                                                        getMostAnsweredQues() == getMostSkippedQues()
                                                                            ?
                                                                            <p className='text-xs font-light'>No question was skipped in the form</p>
                                                                            :
                                                                            <p className='text-xs font-light'>{getMostSkippedQues()} was the most skipped question in the form</p>
                                                                    }
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>
                                    <div md={4} xl={3}>
                                        {usersCount.length === 0 ?
                                            <div className='flex flex-col bg-white rounded-lg px-5 py-3 min-h-[320px]'>
                                                <p className=' font-medium p-1'>Filled questionnaire</p>
                                                <div className='flex justify-center items-center w-full h-full flex-grow'>
                                                    <div className='-rotate-45 transform text-black text-xl font-medium italic'>
                                                        Insufficient data
                                                    </div>
                                                </div>
                                            </div> :
                                            <div className='bg-white rounded-lg px-5 py-4 min-h-[320px]'>
                                                <p className='font-medium text-left'>Filled questionnaire</p>
                                                <div className='flex flex-col items-center '>
                                                    <div className='w-[240px]'>
                                                        <Pie data={pieChartData2} />
                                                    </div>
                                                    <div className='flex justify-around items-center mt-3 gap-12 text-sm'>
                                                        <div className='flex items-center gap-2'>
                                                            <span className='bg-orange-400 rounded-full w-4 h-4'></span>
                                                            <span>Filled form</span>
                                                        </div>
                                                        <div className='flex items-center gap-2'>
                                                            <span className='bg-orange-600 rounded-full w-4 h-4'></span>
                                                            <span>Only Generic Que</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>
                                </div>

                                <Row>

                                    <Col className=' mx-auto w-full max-w-3xl'>

                                        {
                                            form?.questions?.map((item, i) => {
                                                if (item[i + 1]?.type == 'generic') {
                                                    return (
                                                        <>
                                                            <div className='flex flex-col mt-12'>
                                                                <p className='text-black font-medium text-lg'>Generic question: Rate your overall experience</p>
                                                                <div className="bg-white border-dashed border-orange-600 rounded-[10px] p-4 mt-3 ml-3" style={{ borderWidth: "2px 2px 0 0" }}>
                                                                    <div className="flex">
                                                                        <div className="flex-shrink-0 flex flex-col gap-2.5">
                                                                            <span><img src={rating5} className="w-12 h-12 flex-shrink-0 bg-orange-600 mt-1" /></span>
                                                                            <span><img src={rating4} className="w-12 h-12 flex-shrink-0 bg-orange-600" /></span>
                                                                            <span><img src={rating3} className="w-12 h-12 flex-shrink-0 bg-orange-600" /></span>
                                                                            <span><img src={rating2} className="w-12 h-12 flex-shrink-0 bg-orange-600" /></span>
                                                                            <span><img src={rating1} className="w-12 h-12 flex-shrink-0 bg-orange-600" /></span>
                                                                        </div>
                                                                        <div className="relative w-full" style={{ width: "600px", height: "300px" }}>
                                                                            <div className="absolute w-8 h-4 top-0 left-0 bg-white z-10"></div>
                                                                            <Bar data={productData} plugins={[ChartDataLabels]}
                                                                                width={600}
                                                                                height={300}
                                                                                options={{
                                                                                    indexAxis: 'y',
                                                                                    responsive: true,
                                                                                    scales: {
                                                                                        x: {
                                                                                            ticks: {
                                                                                                display: false
                                                                                            },
                                                                                            grid: {
                                                                                                drawBorder: false,
                                                                                                display: false,
                                                                                            },
                                                                                        },

                                                                                        y: {
                                                                                            ticks: {
                                                                                                display: false,
                                                                                            },
                                                                                            grid: {
                                                                                                drawBorder: false,
                                                                                                display: false,
                                                                                            },

                                                                                        },
                                                                                    },
                                                                                    plugins: {
                                                                                        legend: { display: false },
                                                                                        datalabels: {
                                                                                            color: '#000000',
                                                                                            anchor: 'end',
                                                                                            align: 'end',
                                                                                            offset: 0,
                                                                                            font: {
                                                                                                size: 14,
                                                                                            },

                                                                                            formatter: function (value) {
                                                                                                if (value > 0)
                                                                                                    return value + ' Users';
                                                                                                else
                                                                                                    return "0 Users";

                                                                                            },
                                                                                        },
                                                                                    },
                                                                                    tooltips: {
                                                                                        callbacks: {
                                                                                            title: function (tooltipItem, data) {
                                                                                                return data['labels'][tooltipItem[0]['index']];
                                                                                            },
                                                                                            labelColor: function (context) {
                                                                                                return {
                                                                                                    backgroundColor: 'white',
                                                                                                };
                                                                                            },
                                                                                        },
                                                                                        label: function (tooltipItem, data) {
                                                                                            return data['datasets'][0]['data'][tooltipItem['index']];
                                                                                        },
                                                                                        afterLabel: function (tooltipItem, data) {
                                                                                            var dataset = data['datasets'][0];
                                                                                            var percent = 95;
                                                                                            return '(' + percent + '%)';
                                                                                        }
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="bg-white py-2 mt-3 ml-3 rounded-xl">
                                                                    <div className='flex justify-between'>
                                                                        <p className=' ml-3 mt-3 font-semibold text-xl'>Top 5 countries - {responseType == 1 ? 'Happy' : 'Sad'} response</p>
                                                                        <div className='flex gap-2 mr-3'>
                                                                            <img src={rating5} onClick={() => setResponseType(1)} className={`w-12 h-12 flex-shrink-0 cursor-pointer ${responseType == 1 ? 'bg-orange-600' : 'bg-gray-300'} mt-2`} />
                                                                            <img src={rating1} onClick={() => setResponseType(2)} className={`w-12 h-12 flex-shrink-0 cursor-pointer ${responseType == 2 ? 'bg-orange-600' : 'bg-gray-300'} mt-2`} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex items-end justify-end gap-4 relative mapItem'>
                                                                        <div className='min-w-max bg-orange-200 bg-opacity-20 absolute top-4 left-0 rounded-r-lg'>
                                                                            {versionMapData.filter(el => responseType == 2 ? Number(el?.sadRating) < 3 : responseType == 1 ? Number(el?.happyRating) > 2 : true).map((data, i) => (
                                                                                <div key={i} className='flex items-center gap-3 px-3 pb-2 first:pt-2'>
                                                                                    <div md={3} className='text-orange text-base capitalize'>
                                                                                        {data.country}
                                                                                    </div>
                                                                                    <div md={3}>
                                                                                        <span className="text-orange-500 font-semibold text-xl">{responseType == 2 ? data.sadCount : responseType == 1 ? data.happyCount : data.count}</span>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        <ComposableMap projectionConfig={{ scale: 180 }} style={{ width: '80%', height: '350px' }}>
                                                                            {versionMapData.length > 0 && (
                                                                                <Geographies geography={geoUrl}>
                                                                                    {({ geographies }) =>
                                                                                        geographies.map(geo => {
                                                                                            const cur = versionMapData.find(s => s.country.toLowerCase() === geo.properties.name.toLowerCase());
                                                                                            let value = responseType == 2 ? Number(cur?.sadRating) : Number(cur?.happyRating);
                                                                                            return (
                                                                                                <Geography
                                                                                                    key={geo.rsmKey}
                                                                                                    geography={geo}
                                                                                                    fill={value ? colorScale(value) : '#C0C0C0'}
                                                                                                    stroke='#FFFFFF'
                                                                                                />
                                                                                            );
                                                                                        })
                                                                                    }
                                                                                </Geographies>
                                                                            )}
                                                                        </ComposableMap>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div className='flex flex-col mt-12'>
                                                            <p className='text-black font-medium text-lg'>Q{i + 1}. {item[i + 1]?.question}</p>
                                                            <div className="bg-white border-dashed border-orange-600 rounded-[10px] p-4 mt-3 ml-3" style={{ borderWidth: "2px 2px 0 0" }}>
                                                                <div className="flex items-stretch">
                                                                    <div className="flex-shrink-0 flex flex-col items-start justify-around pb-2">
                                                                        {
                                                                            item[i + 1].type !== 'textfield'
                                                                                ?
                                                                                item[i + 1].type == 'rating'
                                                                                    ?
                                                                                    item[i + 1].ratingDesc?.map((item, index) => <div className=" grid place-items-center font-medium" key={item}><p>Opt{index + 1}:</p></div>)
                                                                                    :
                                                                                    item[i + 1].options?.map((item, index) => <div className=" grid place-items-center font-medium" key={item}><p>Opt{index + 1}:</p></div>)
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        item[i + 1].type == 'textfield'
                                                                            ?
                                                                            <h1>Not Applicable</h1>
                                                                            :
                                                                            item[i + 1].type == 'rating'
                                                                                ?
                                                                                <>
                                                                                    <div className="relative w-full" style={{ width: "600px", height: "300px" }}>
                                                                                        <div className="absolute w-8 h-4 top-0 left-0 bg-white z-10"></div>

                                                                                        <Bar width={600} height={300} redraw={true} data={
                                                                                            {
                                                                                                labels:
                                                                                                    Array(item[i + 1].ratingDesc?.length).fill("User(s)").map(_ => _),
                                                                                                datasets: [
                                                                                                    {
                                                                                                        data: [...getAnswerCount(item[i + 1], item[i + 1].ratingDesc), 12],
                                                                                                        borderColor: "#FFB633",
                                                                                                        backgroundColor: ["#FFB633"],
                                                                                                        borderRadius: 20,
                                                                                                        borderWidth: 1,
                                                                                                        barThickness: 20,
                                                                                                        maxBarThickness: 20
                                                                                                    },
                                                                                                ]
                                                                                            }
                                                                                        }
                                                                                            plugins={[ChartDataLabels]}
                                                                                            options={{
                                                                                                indexAxis: 'y',
                                                                                                responsive: true,
                                                                                                scales: {
                                                                                                    x: {
                                                                                                        ticks: {
                                                                                                            display: false
                                                                                                        },
                                                                                                        grid: {
                                                                                                            drawBorder: false,
                                                                                                            display: false,
                                                                                                        },
                                                                                                    },

                                                                                                    y: {
                                                                                                        ticks: {
                                                                                                            display: false,
                                                                                                        },
                                                                                                        grid: {
                                                                                                            drawBorder: false,
                                                                                                            display: false,
                                                                                                        },

                                                                                                    },
                                                                                                },
                                                                                                plugins: {
                                                                                                    legend: { display: false },
                                                                                                    datalabels: {
                                                                                                        color: '#000000',
                                                                                                        anchor: 'end',
                                                                                                        align: 'end',
                                                                                                        offset: 0,
                                                                                                        font: {
                                                                                                            size: 14,
                                                                                                        },

                                                                                                        formatter: function (value) {
                                                                                                            if (value > 0)
                                                                                                                return value + ' Users';
                                                                                                            else
                                                                                                                return "0 Users";

                                                                                                        },
                                                                                                    },
                                                                                                },
                                                                                                tooltips: {
                                                                                                    callbacks: {
                                                                                                        title: function (tooltipItem, data) {
                                                                                                            return data['labels'][tooltipItem[0]['index']];
                                                                                                        },
                                                                                                        labelColor: function (context) {
                                                                                                            return {
                                                                                                                backgroundColor: 'white',
                                                                                                            };
                                                                                                        },
                                                                                                    },
                                                                                                    label: function (tooltipItem, data) {
                                                                                                        return data['datasets'][0]['data'][tooltipItem['index']];
                                                                                                    },
                                                                                                    afterLabel: function (tooltipItem, data) {
                                                                                                        var dataset = data['datasets'][0];
                                                                                                        var percent = 95;
                                                                                                        return '(' + percent + '%)';
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <div className="relative w-full" style={{ width: "600px", height: "300px" }}>
                                                                                    <div className="absolute w-8 h-4 top-0 left-0 bg-white z-10"></div>

                                                                                    <Bar width={600} height={300} redraw={true} data={
                                                                                        {
                                                                                            labels:
                                                                                                Array(item[i + 1].options?.length).fill("User(s)").map(_ => _),
                                                                                            datasets: [
                                                                                                {
                                                                                                    data: [...getAnswerCount(item[i + 1], item[i + 1].options), 12],
                                                                                                    borderColor: "#FFB633",
                                                                                                    backgroundColor: ["#FFB633"],
                                                                                                    borderRadius: 20,
                                                                                                    borderWidth: 1,
                                                                                                    barThickness: 20,
                                                                                                    maxBarThickness: 20
                                                                                                },
                                                                                            ]
                                                                                        }
                                                                                    }
                                                                                        plugins={[ChartDataLabels]}
                                                                                        options={{
                                                                                            indexAxis: 'y',
                                                                                            responsive: true,
                                                                                            scales: {
                                                                                                x: {
                                                                                                    ticks: {
                                                                                                        display: false
                                                                                                    },
                                                                                                    grid: {
                                                                                                        drawBorder: false,
                                                                                                        display: false,
                                                                                                    },
                                                                                                },

                                                                                                y: {
                                                                                                    ticks: {
                                                                                                        display: false,
                                                                                                    },
                                                                                                    grid: {
                                                                                                        drawBorder: false,
                                                                                                        display: false,
                                                                                                    },

                                                                                                },
                                                                                            },
                                                                                            plugins: {
                                                                                                legend: { display: false },
                                                                                                datalabels: {
                                                                                                    color: '#000000',
                                                                                                    anchor: 'end',
                                                                                                    align: 'end',
                                                                                                    offset: 0,
                                                                                                    font: {
                                                                                                        size: 14,
                                                                                                    },

                                                                                                    formatter: function (value) {
                                                                                                        if (value > 0)
                                                                                                            return value + ' Users';
                                                                                                        else
                                                                                                            return "0 Users";

                                                                                                    },
                                                                                                },
                                                                                            },
                                                                                            tooltips: {
                                                                                                callbacks: {
                                                                                                    title: function (tooltipItem, data) {
                                                                                                        return data['labels'][tooltipItem[0]['index']];
                                                                                                    },
                                                                                                    labelColor: function (context) {
                                                                                                        return {
                                                                                                            backgroundColor: 'white',
                                                                                                        };
                                                                                                    },
                                                                                                },
                                                                                                label: function (tooltipItem, data) {
                                                                                                    return data['datasets'][0]['data'][tooltipItem['index']];
                                                                                                },
                                                                                                afterLabel: function (tooltipItem, data) {
                                                                                                    var dataset = data['datasets'][0];
                                                                                                    var percent = 95;
                                                                                                    return '(' + percent + '%)';
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                    )

                                                }
                                            })
                                        }

                                    </Col>
                                </Row>

                            </>
                            :
                            <h1 className='text-3xl font-medium text-gray-400'>There is no form associated with this version</h1>
                }
            </div>
        </div>
    )
}

export default ProductInsight
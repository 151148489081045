
import React, { useState } from "react";
import { Button, Form, Card } from "react-bootstrap";
import { Link, useHistory } from 'react-router-dom';
import fedduplogo from "../images/fedduplogo.png";
import axios from 'axios';
import { config } from '../Config/Config';
import './Login.css';
import toast from "react-hot-toast";
import { encrypt } from "../utils";

const validPasswordRegex =
    /^.*(?=.{10,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*]).*$/;

const ResetPassword = (props) => {
    const history = useHistory();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [email] = useState(props.location.state.email);
    const [authKey] = useState(props.location.state.authKey);

    const handleSubmit = (event) => {
        event.preventDefault();
        let formFileds = ["password", "confirmPassword"];
        let isValid = true;
        formFileds.forEach(field => {
            isValid = validateField(field) && isValid;
        });
        if (!isValid) {
           toast("Please enter valid password");
            document.getElementById('status').innerHTML = "<div>Please enter valid password</div>";
        } else {
            resetPassword();
        }
    };

    const onChange = (event) => {
        const { name, value } = event.target;
        if (name === "password") {
            setPassword(value);
            validateField("password");
        } else if (name === "confirmPassword") {
            setConfirmPassword(value);
            validateField("confirmPassword");
        }
    };

    const validatePassword = () => {
        let passwordError = "";
        const value = password.trim();
        if (value === "") passwordError = "Password is required";
        else if (!validPasswordRegex.test(value))
            passwordError = "Minimum 10 with 1 capital, 1 numeric and 1 character (!@#$%^&*)";
        setPasswordError(passwordError);
        return passwordError === "";
    };

    const validateConfirmPassword = () => {
        let confirmPasswordError = "";
        if (password !== confirmPassword)
            confirmPasswordError = "Password does not match";
        setConfirmPasswordError(confirmPasswordError);
        return confirmPasswordError === "";
    };

    const validateField = (name) => {
        let isValid = false;
        if (name === "password") isValid = validatePassword();
        else if (name === "confirmPassword") isValid = validateConfirmPassword();
        return isValid;
    };

    const resetPassword = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        };
        var encryptedPassword = encrypt(password);

        var payload = {
            "email": email,
            "password": encryptedPassword,
            "authKey": authKey
        };

        axios.post(config.apiUrl + 'auth/forgotPassword/resetPassword', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1);
                if (response.data.status === true) {
                    console.log("Password reset success!!");
                    history.push("/successresetpassword", { email: email })
                } else {
                    console.log(response.data.message);
                    // document.getElementById('status').innerText = msg;
                }
            })
            .catch(function (error) {
                console.error(error);
                // document.getElementById('error').innerText = error;
            });
    };

    const showPasswordHandler = () => {
        setShowPassword(!showPassword);
    };

    const showConfirmPasswordHandler = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <div className="h-screen flex flex-col">
        <Link to="/login"><img alt="" src={fedduplogo} className="logo-login" /></Link>
            <div className="w-full flex-grow grid place-items-center pb-20">
                <Card className="cards-width pt-6 pb-10"> <center>
                <p className="forgot-text" style={{ fontWeight: 600 }}>Forgot Password</p>
                <p className="forgot-text-sm mt-3">Enter your new password.</p></center><br />
                <Form className="font_family">
                    <center>
                        <div style={{ width: "80%" }}>
                            <div className="mt-2 form-group">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    className="form-control border"
                                    value={password}
                                    placeholder="Password"
                                    onChange={onChange}
                                    onBlur={() => validateField("password")}
                                />
                                <span className="showPassword" onClick={showPasswordHandler}>
                                    {showPassword ? <i className="fa fa-eye" aria-hidden="true"></i> :
                                        // <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                        <svg width="16" height="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2 10.7188C2 10.7188 5.5 14.7188 12 14.7188C18.5 14.7188 22 10.7188 22 10.7188" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M4 12.3633L2 14.7183" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M21.9999 14.7192L20.0039 12.3672" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M8.914 14.3984L8 17.2184" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M15.0625 14.4062L15.9995 17.2183" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    }
                                </span>
                                <div className="div">{passwordError && <div className="error">{passwordError}</div>}</div>
                            </div>
                            <div className="mt-2 form-group">
                                <input
                                    type={showConfirmPassword ? "text" : "password"}
                                    name="confirmPassword"
                                    className="form-control border"
                                    value={confirmPassword}
                                    placeholder="Re-enter Password"
                                    onChange={onChange}
                                    onBlur={() => validateField("confirmPassword")}
                                />
                                <span className="showPassword" onClick={showConfirmPasswordHandler}>
                                    {showConfirmPassword ? <i className="fa fa-eye" aria-hidden="true"></i> :
                                        // <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                        <svg width="16" height="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2 10.7188C2 10.7188 5.5 14.7188 12 14.7188C18.5 14.7188 22 10.7188 22 10.7188" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M4 12.3633L2 14.7183" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M21.9999 14.7192L20.0039 12.3672" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M8.914 14.3984L8 17.2184" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M15.0625 14.4062L15.9995 17.2183" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    }
                                </span>
                                <div className="div">
                                    {confirmPasswordError && <div className="error">{confirmPasswordError}</div>}
                                </div>
                            </div><br />
                            <div className='btn-outer mt-3'>
                                <div className='btn-1' style={{ height: "40px", width: '150px' }}></div>
                                <Button
                                    variant="default"
                                    type="submit"
                                    className="btn-2"
                                    style={{ position: "absolute", fontSize: "16px", height: "40px", width: '150px', lineHeight: "16px", left: 0, top: 0 }}
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                        <br />
                        <br />
                        <Link to="/login" className="link"><span className="login-here border-b border-amber-500">Login here</span></Link>
                    </center>
                </Form><br />
            </Card>
            </div>
        </div>
    );
};

export default ResetPassword;

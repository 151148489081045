import React from 'react';
import '../CompanyFeed/CompanyFeed.css';
import { Row, Col, Modal, Form, FormControl, Card, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import upvoteb from '../images/upvote-b.svg';
import downvoteb from '../images/downvote-b.svg';
import upvote from '../images/upvote.svg';
import downvote from '../images/downvote.svg';
import comment from '../images/comments.svg';
import share from '../images/share.svg';
import fillTag from "../images/fill-tag.svg";
import lock from '../images/lock.png';
import profilepicture from '../images/profilepicture.png';
import axios from 'axios';
import { config } from '../Config/Config';

class ViewImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          show:true 
        }
    }
    componentDidMount() {
        console.log("props", this.props)
    }
    render() {
        return (
            <Modal
                size="lg"
                show={this.state.show}
                onHide={this.props.close}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton style={{ zIndex: "100", outline: "none" }}>
                </Modal.Header>
                <Modal.Body>
                            <div className='border-none'>
                                <center className="row" style={{ marginTop: "-3.5rem" }}>
                                    <Col md={12}>
                                        <div className="mt-2 mb-2">
                                            <div className="card-padding">
                                                <div><img src={this.props.image} style={{ width: "100%"}} /></div>
                                            </div>
                                        </div>
                                    </Col>
                                </center>
                            </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default ViewImage;


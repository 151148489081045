import React, { useEffect, useState, version } from 'react'
import NavBar1 from '../NavBar/NavBar1'
import arrowLeft from "../images/arrow-left-black.svg"
import axios from 'axios'
import { config } from '../Config/Config';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import dropDwonIcon from "../images/drop-down-orange.svg"
import { Button, ButtonGroup, Col, Form, FormControl, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

import ProductReviews from './ProductReviews';
import ProductFeedbacks from './ProductFeedbacks';
import ProductSuggestions from './ProductSuggestions';
import ProductAnnouncements from './ProductAnnouncements';
import NewVersion from './NewVersion';
import FlaggedPost from './FlaggedPost';
import { useRef } from 'react';
import { decrypt } from '../utils';

// const handleViewFormClick = (id) => {
//     history.push("/admin/viewform/id", { name: formName, id, productid });
// }

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function AllReviews() {
    let query = useQuery()
    const history = useHistory();
    const { productname, productid, section } = useParams()
    const [productName, setProductName] = useState(productname)
    const [productIcon, setProductIcon] = useState('');
    const [productType, setProductType] = useState('');
    const [currentVersion, setCurrentVersion] = useState({})
    const [companyId, setCompanyId] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [membershipType, setMembershipType] = useState('')
    const [versions, setVersions] = useState(null)
    const [versionIds, setVersionIds] = useState([]);
    const [item, setItem] = useState(section)
    const [showNewVersion, setShowNewVersion] = useState(false)
    const [selectedVersionId, setSelectedVersionId] = useState('')
    const [loading, setLoading] = useState(false);
    const [productTags, setProductTags] = useState([])
    const [checkState, setCheckState] = useState(false);
    const [reason, setReason] = useState('');
    const [reasonPopUp, setReasonPopUp] = useState(false);
    const [flagged, setFlagged] = useState(false);
    const [note, setNote] = useState('')
    const [popUpType, setPopUpType] = useState('');
    const [userRole, setUserRole] = useState('')

    const reasonRef = useRef()
    const noteRef = useRef()

    useEffect(() => {
        setUserRole(localStorage.getItem('role').toLowerCase())
        getAllVersionDetails();
        getAllProductTags();
        if (query.get('flagged')) {
            if (query.get('flagged') == 'true') {
                setFlagged(true)
            }
        }
        if (query.get('type')) {
            if (query.get('type') == 'suggestions' || query.get('type') == 'feddbacks' || query.get('type') == 'reviews') {
                setItem(query.get('type'))
            }
        }

        window.scrollTo(0, 0)
    }, [])

    const getAllVersionDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "productId": productid
        }
        axios.post(config.userUrl + "admin/getProductVersionDetail", payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    setProductIcon(response.data.productDetail[0].productIcon)
                    setProductName(response.data.productDetail[0].productName)
                    setProductType(() => response.data.productDetail[0].productType === 1 ? "Desktop Application" : "Web Application")
                    setCompanyId(response.data.productDetail[0].id);
                    setCompanyName(response.data.productDetail[0].companyName)
                    setMembershipType(response.data.productDetail[0].membershipType)
                    setCurrentVersion(response.data.currentVersion)
                    setSelectedVersionId(response.data.currentVersion.id)
                    let versionArray = response.data.versions || [];
                    versionArray.sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate));
                    versionArray = versionArray.map((_, i) => ({ ..._, active: i < 3 }))
                    setVersions(versionArray)
                    setCheckState(versionArray[0])
                    let versionIds = response.data.versions.map(version => version.id)
                    versionIds = [...versionIds, response.data.currentVersion.id];
                    setVersionIds(versionIds);
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const getAllProductTags = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "productId": productid
        }
        axios.post(config.userUrl + "admin/getProductTags", payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    setProductTags(response.data.productTags?.tag?.split(","))
                }
            })
            .catch(function (error) {
                console.log("Error", error);
                setLoading(false);
            });

    }
    const reviewStatusUpdate = ({ versionId, reviewEnabled }) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "productId": productid,
            "versionId": versionId,
            reviewEnabled: reviewEnabled
        }
        axios.post(config.userUrl + "admin/reviewStatusUpdate", payload, { headers: headers })
            .then(function (response) {
                console.log(response)
            })
            .catch(function (error) {
                console.log("Error", error);
                setLoading(false);
            });

    }
    const handleVersionToggle = (e) => {
        if (userRole != 'moderator') {
            let idx = versions.findIndex(item => item?.id == checkState.id);

            if (idx > -1) {
                let arr = [...versions]
                arr[idx].reviewEnabled = e.target.checked
                setVersions(arr);
                setCheckState({ ...checkState, reviewEnabled: e.target.checked });
                reviewStatusUpdate({ versionId: arr[idx].id, reviewEnabled: e.target.checked })
            }
        }
        // setCheckState(e.target.checked);
    }
    const handleVersionSelect = (e) => {
        setSelectedVersionId(e.target.value);
        versions.filter(version => {
            if (version.id === e.target.value) {
                setCheckState(version)
            }
        })
    }

    const toggleNewVersion = () => {
        setShowNewVersion(!showNewVersion);
    }

    const handleViewFormClick = ({ name, id }) => {
        history.push("/admin/viewform/id", { name: name, id, productid });
    }

    const handleFlag = async ({ id, type, flag, callback }) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            id, type, flag, reason: reasonRef.current?.value?.slice(0, 250)?.trim(), note: noteRef.current?.value?.slice(0, 100)?.trim(),
            role: localStorage.getItem("role"),
            action: userRole == 'moderator' ? 'no action' : flag ? 'post flagged' : 'post unflagged'
        }

        axios.post(config.userUrl + "admin/handleFlag", payload, { headers: headers })
            .then(function (response) {
                console.log("handleFlag ", response)
                if (typeof callback == 'function') {
                    callback();
                }
                setReason('');
                setNote('')
            })
            .catch(function (error) {
                console.log("Error", error);
                setLoading(false);
            });
    }
    const deletePost = async ({ id, type, remove, callback }) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            id, type, remove, reason: reasonRef.current?.value?.slice(0, 250)?.trim()
        }
        axios.post(config.userUrl + "admin/deletePost", payload, { headers: headers })
            .then(function (response) {
                console.log("deletePost ", response)
                if (typeof callback == 'function') {
                    callback();
                }
                setReason('');
                setNote('')
            })
            .catch(function (error) {
                console.log("Error", error);
                setLoading(false);
            });
    }


    const reportReview = async ({ id, type, flag, callback }) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            reviewId: id,
            reportDescription: reasonRef.current?.value?.slice(0, 250)?.trim(),
            reasonToReject: noteRef.current?.value?.slice(0, 100)?.trim(),
            role: localStorage.getItem("role"),
            action: userRole == 'moderator' ? 'no action' : 'post flagged'
        }
        axios.post(config.userUrl + "admin/addReviewReportByAdmin", payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    if (typeof callback == 'function') {
                        callback();
                    }
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    }

    const removeReviewReport = async ({ id, type, flag, callback }) => {

        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }

        var payload = {
            id: id,
        }
        axios.post(config.userUrl + "admin/removeReviewReport", payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    if (typeof callback == 'function') {
                        callback();
                    }
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });

    }

    const closeReasonPopUp = () => {
        setReasonPopUp(false);
        setReason('');
        setNote('');
        setPopUpType('')
    }

    const openReasonPopUp = ({ action, noPopUp, type }) => {
        setPopUpType(type)
        if (noPopUp) {
            action();
        }
        else {
            setReasonPopUp({
                action,
            })
        }
    }

    const submitReason = () => {
        if (typeof reasonPopUp.action == 'function')
            reasonPopUp.action()
        closeReasonPopUp();
    }


    return (
        <div className='bg-gray-100 min-h-screen'>
            {showNewVersion && <NewVersion toggleNewVersion={toggleNewVersion} productId={productid} companyId={companyId} />}
            <Modal
                show={reasonPopUp}
                onHide={() => closeReasonPopUp()}
                size='lg'
                aria-labelledby="example-custom-modal-styling-title"
                centered
                className='z-[1290] bg-black bg-opacity-50'
            >
                <Modal.Header>
                    <div>
                        <h1 className='text-orange font-medium text-3xl'>Reason for {popUpType} post</h1>
                        <p className='text-sm mt-1'>Please provide the reason for {popUpType} this post from feddup page. This will be shared with user.</p>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <section className='px-10'>
                        {userRole != 'moderator' && <div className='relative w-full transform -translate-y-3'>
                            <textarea ref={reasonRef} className='w-full h-40 border border-gray-300 rounded-lg p-2 overflow-y-auto resize-none' placeholder={`Reason for ${popUpType} post`} value={reason} onChange={(e) => e.target.value?.length < 251 ? setReason(e.target.value) : {}}></textarea>
                            <span className='absolute bottom-3 right-4 text-gray-400 font-medium'>{reason?.length}/250</span>
                        </div>}

                        {userRole == 'moderator' && <div className='relative w-full transform -translate-y-3'>
                            <textarea ref={noteRef} className='w-full h-20 border border-gray-300 rounded-lg resize-none p-2 overflow-y-auto' placeholder='Additional Note' value={note} onChange={(e) => e.target.value?.length < 101 ? setNote(e.target.value) : {}} ></textarea>
                            <span className='absolute bottom-3 right-4 text-gray-400 font-medium'>{note?.length}/100</span>
                        </div>}
                        <div className='py-4 flex items-center justify-between'>
                            <button onClick={() => closeReasonPopUp()} className='text-lg text-orange'>Cancel</button>
                            <div className='btn-outer'>
                                <div className='btn-1' style={{ height: "36px" }}></div>
                                <Button
                                    variant="default"
                                    type="submit"
                                    className="btn-2"
                                    disabled={!reason?.length && (userRole == 'moderator' ? !note?.length : true)}
                                    style={{ position: "absolute", fontSize: "14px", height: "36px", lineHeight: "12px" }}
                                    onClick={() => submitReason()}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </section>
                </Modal.Body>
            </Modal>

            <NavBar1 />
            <div className='pl-16 pr-24 mt-20 py-14 '>
                <div className='flex gap-4 items-center'>
                    <img src={arrowLeft} alt='' onClick={() => flagged ? setFlagged(false) : history.goBack()} className='cursor-pointer' />
                    <div className='bg-white rounded-full flex items-center justify-center' style={{ width: "100px", height: "100px" }}>
                        <img src={productIcon} alt='' className='' />
                    </div>
                    <div className='flex flex-col'>
                        <span className='text-2xl font-semibold'>{productName}</span>
                        <span className='text-xl font-medium'>{productType}</span>
                    </div>
                </div>
                {membershipType === "Free" ?
                    <div className='mt-4 ml-2'>
                        <p className='text-orange-600 text-2xl'>All Reviews</p>
                        <p className='text-orange-400 text-lg'>Upgrade to Premium account to version your application</p>
                    </div>
                    :
                    <><div className='flex justify-between mt-4 ml-2'>
                        <div className="inline-block relative w-72">
                            <select
                                value={selectedVersionId}
                                className="reviews-select block appearance-none w-full text-orange-600 text-2xl font-semibold pr-8 rounded leading-tight focus:outline-none cursor-pointer"
                                onChange={handleVersionSelect}
                            >
                                {/* {currentVersion && <option value={currentVersion?.id}>Version: {currentVersion?.version}(latest)</option>} */}
                                {versions && versions.map((version, i) => {
                                    return (
                                        <option value={version.id} key={i}>Version: {version.version}{version.latestVersion ? `(latest)` : ''}</option>
                                    )
                                })}
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg width="31" height="18" viewBox="0 0 31 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.2928 16.9962C15.0739 17.7772 16.3402 17.7772 17.1212 16.9962L29.8492 4.26828C30.6302 3.48723 30.6302 2.2209 29.8492 1.43985C29.0681 0.658801 27.8018 0.658801 27.0207 1.43985L15.707 12.7536L4.39332 1.43985C3.61227 0.658801 2.34594 0.658801 1.5649 1.43985C0.783847 2.2209 0.783847 3.48723 1.5649 4.26828L14.2928 16.9962ZM13.707 14.252V15.582H17.707V14.252H13.707Z" fill="#FFB858" />
                                </svg>
                            </div>
                        </div>
                        {flagged || userRole == 'moderator' ? <></> : <div className='btn-outer'>
                            <div className='btn-1' style={{ height: "30px" }}></div>
                            <Button
                                variant="default"
                                type="submit"
                                className="btn-2"
                                style={{ position: "absolute", fontSize: "16px", height: "30px", lineHeight: "12px" }}
                                onClick={toggleNewVersion}
                            >New Version
                            </Button>
                        </div>}
                    </div>

                    </>
                }

                {
                    flagged
                        ?
                        <>
                            <h1 className='text-xl font-medium text-orange-400 pl-2'>Flagged Posts</h1>
                            <div className='flex ml-2 my-3'>
                                <div className='flex gap-6'>
                                    <div className='btn-outer-section w-40'>
                                        <div className={item == "reviews" ? "feed-card f-color btn-inner-section" : "feed-card-a f-color-g btn-inner-section"} onClick={() => setItem("reviews")}>Reviews</div>
                                    </div>
                                    <div className='btn-outer-section w-40'>
                                        <div className={item == "feddbacks" ? "feed-card f-color btn-inner-section" : "feed-card-a f-color-g btn-inner-section"} onClick={() => setItem("feddbacks")}>Feddbacks</div>
                                    </div>
                                    <div className='btn-outer-section w-40'>
                                        <div className={item == "suggestions" ? "feed-card f-color btn-inner-section" : "feed-card-a f-color-g btn-inner-section"} onClick={() => setItem("suggestions")}>Suggestions</div>
                                    </div>
                                </div>
                            </div>

                            <FlaggedPost userRole={userRole} selectedVersionId={selectedVersionId} category={item} productId={productid} handleFlag={handleFlag} removeReviewReport={removeReviewReport} deletePost={deletePost} openReasonPopUp={openReasonPopUp} />
                        </>
                        :

                        <>
                            {membershipType === "Free" ? '' : <div className='flex items-center mt-4 ml-2 gap-8'>
                                <div className='flex flex-col text-gray-700'>
                                    <p className='text-xl'>User can provide reviews?</p>
                                    <p className='text-xs'>Auto switch off after 3 versions</p>
                                </div>
                                {
                                    !checkState?.active
                                        ?
                                        <label className="relative inline-flex items-center cursor-pointer">
                                            <input
                                                type="checkbox"
                                                value="older"
                                                checked={checkState.reviewEnabled}
                                                className="sr-only peer"
                                                readOnly={true} />
                                            <div className={`w-14 h-7 peer-focus:outline-none rounded-full peer
                             after:content-[''] after:absolute after:top-0.5 after:left-0.5 after:bg-[#A6A6A6]
                              after:rounded-full after:h-6 after:w-6 after:transition-all                            
                              bg-[#963909]
                              `
                                            }
                                            >
                                            </div>
                                        </label>
                                        :
                                        (checkState?.reviewEnabled)
                                            ?
                                            <label className="relative inline-flex items-center cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    value="older"
                                                    className="sr-only peer"
                                                    checked={true}
                                                    onChange={(e) => checkState?.latestVersion ? {} : handleVersionToggle(e)} />
                                                <div className={`w-14 h-7 bg-orange-600 peer-focus:outline-none rounded-full peer peer-checked:after:left-[30px] 
                             after:content-[''] after:absolute after:top-0.5 after:left-0.5 after:bg-white
                              after:rounded-full after:h-6 after:w-6 after:transition-all  
                             ${"peer-checked:bg-[#04C700]"}
                            
                             ${checkState?.latestVersion ? 'peer-checked:bg-[#038100] peer-checked:after:bg-[#A6A6A6]' : 'peer-checked:after:bg-gray-50'} 
                             `}
                                                >
                                                </div>
                                            </label>
                                            : <label className="relative inline-flex items-center cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    value="older"
                                                    checked={false}
                                                    className="sr-only peer"
                                                    onChange={handleVersionToggle} />
                                                <div className={`w-14 h-7 bg-orange-600 peer-focus:outline-none rounded-full peer peer-checked:after:left-[30px] 
                             after:content-[''] after:absolute after:top-0.5 after:left-0.5 after:bg-white
                              after:rounded-full after:h-6 after:w-6 after:transition-all  
                             ${"peer-checked:bg-green-800"}
                             ${"peer-checked:after:bg-[#E6570E]"}`}
                                                >
                                                </div>
                                            </label>
                                }
                            </div>}

                            <>
                                <div className='flex gap-16 items-center'>
                                    {membershipType === "Premium" &&
                                        <>
                                            <div className='btn-outer mt-8 ml-2'>
                                                <div className='btn-1' style={{ height: "30px" }}></div>
                                                <Button
                                                    variant="default"
                                                    type="submit"
                                                    className="btn-2"
                                                    style={{ position: "absolute", fontSize: "14px", height: "30px", lineHeight: "12px" }}
                                                    disabled={!checkState?.form}
                                                    onClick={() => handleViewFormClick({ name: checkState?.form?.formName, id: checkState.form?.id })}
                                                >View Review Form
                                                </Button>
                                            </div>
                                            <div className='btn-outer mt-8 ml-2'>
                                                <div className='btn-1' style={{ height: "30px" }}></div>
                                                <Button
                                                    variant="default"
                                                    type="submit"
                                                    className="btn-2"
                                                    style={{ position: "absolute", fontSize: "14px", height: "30px", lineHeight: "12px" }}
                                                    onClick={() => history.push(`/admin/insights/${productid}/${selectedVersionId}`)}
                                                >View Insights
                                                </Button>
                                            </div>
                                        </>
                                    }
                                    <div className='btn-outer mt-8 ml-2'>
                                        <div className='btn-1' style={{ height: "30px" }}></div>
                                        <Button
                                            variant="default"
                                            type="submit"
                                            className="btn-2"
                                            style={{ position: "absolute", fontSize: "14px", height: "30px", lineHeight: "12px" }}
                                            onClick={() => setFlagged(true)}
                                        >View Flagged
                                        </Button>
                                    </div>
                                </div>

                                <div className='flex mt-8 ml-2 '>
                                    <div className='flex gap-6'>
                                        <div className='btn-outer-section w-40'>
                                            <div className={item == "reviews" ? "feed-card f-color btn-inner-section" : "feed-card-a f-color-g btn-inner-section"} onClick={() => setItem("reviews")}>Reviews</div>
                                        </div>
                                        <div className='btn-outer-section w-40'>
                                            <div className={item == "feddbacks" ? "feed-card f-color btn-inner-section" : "feed-card-a f-color-g btn-inner-section"} onClick={() => setItem("feddbacks")}>Feddbacks</div>
                                        </div>
                                        <div className='btn-outer-section w-40'>
                                            <div className={item == "suggestions" ? "feed-card f-color btn-inner-section" : "feed-card-a f-color-g btn-inner-section"} onClick={() => setItem("suggestions")}>Suggestions</div>
                                        </div>
                                        <div className='btn-outer-section w-44'>
                                            <div className={item == "announcements" ? "feed-card f-color btn-inner-section" : "feed-card-a f-color-g btn-inner-section"} onClick={() => setItem("announcements")}>Announcements</div>
                                        </div>
                                    </div>
                                </div>
                                {item === "reviews" ?
                                    <ProductReviews userRole={userRole} handleFlag={reportReview} deletePost={deletePost} openReasonPopUp={openReasonPopUp} productid={productid} selectedVersionId={selectedVersionId} membershipType={membershipType} item={item} versionIds={versionIds} />
                                    : item === "feddbacks" ? <ProductFeedbacks userRole={userRole} handleFlag={handleFlag} deletePost={deletePost} openReasonPopUp={openReasonPopUp} productid={productid} selectedVersionId={selectedVersionId} membershipType={membershipType} item={item} productTags={productTags} />
                                        : item === "suggestions" ? <ProductSuggestions userRole={userRole} handleFlag={handleFlag} deletePost={deletePost} openReasonPopUp={openReasonPopUp} productid={productid} selectedVersionId={selectedVersionId} membershipType={membershipType} item={item} productTags={productTags} /> :
                                            <ProductAnnouncements userRole={userRole} productid={productid} companyId={companyId} selectedVersionId={selectedVersionId} membershipType={membershipType} item={item} productTags={productTags} />}
                            </>
                        </>
                }
            </div>
        </div >
    )
}

export default AllReviews


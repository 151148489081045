import moment from 'moment'
import React, { useEffect } from 'react'
import usePayment from '../utils/usePayment'
import PaymentSuccess from '../SignUp/PaymentSuccess';

function Expired({ subscription }) {
    const { createOrder, success, setSuccess } = usePayment();
    useEffect(() => {
        if (success) {
            setTimeout(() => {
                closeSuccess()
            }, 3000)
        }
    }, [success])

    const closeSuccess = () => {
        setSuccess(false);
        localStorage.removeItem('ssSeen')
        window.location.replace('/admindashboard');
    }
    if (success) return <PaymentSuccess closeSuccess={closeSuccess} />

    return (
        <div className='fixed inset-0 grid place-items-center' style={{ zIndex: 9990 }}>
            <div className='bg-black bg-opacity-70 fixed inset-0'></div>

            <div className='relative bg-[#E6570E] text-white w-full max-w-2xl rounded-xl flex flex-col justify-between gap-10 p-4'>
                <div>
                    <h1 className='text-2xl font-bold'>Oh No! Its not looking good!</h1>
                    <p className='italic font-light text-lg'>Your subscription ended on {moment(subscription?.expiryDate).format("DD/MM/YY")}</p>
                </div>

                <div className='flex items-center justify-around my-10'>
                    <p className='text-2xl max-w-xs'>Pay now to avoid any portal access issues!</p>
                    <button className='py-1.5 px-6 text-lg rounded-lg bg-[#649E36]' onClick={() => createOrder({ planId: subscription?.metadata?.planId })}>Pay now</button>
                </div>
                <p className='italic font-light text-lg max-w-xl mx-auto'>Please note, if not renewed before {moment(subscription?.expiryDate).add(7, 'days').format("DD/MM/YY")}, all data will be deleted and cannot be retrieved.</p>

                <div className='flex items-center justify-end'>
                    <button className='py-1.5 px-6 text-lg rounded-lg bg-[#E6900E]' onClick={() => { localStorage.removeItem("ssSeen"); window.location.reload() }}>Continue to portal</button>
                </div>
            </div>
        </div>
    )
}

export default Expired
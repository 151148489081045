import React from 'react'
import { useCreateOrder } from '.';
import useRazorpay from 'react-razorpay';
import { useState } from 'react';

const usePayment = () => {
    const [success, setSuccess] = useState(false)
    const [Razorpay] = useRazorpay();
    const { mutate: createOrder } = useCreateOrder((order) => {
        const options = {
            ...order,
            name: "Feddup",
            description: "Test Transaction",
            image: "https://res.cloudinary.com/feddupnew/image/upload/v1697110112/feddUp.png",
            handler: function (response) {
                console.log("HANDLER ", response)
                // let response = {
                //     "razorpay_payment_id": "pay_MnDYCtJ7vngNyn",
                //     "razorpay_order_id": "order_MnDWg5U6FhR7gS",
                //     "razorpay_signature": "0daec3122a3371320668285a3a776a7c8cffbec722dd970c74c3db29b96318c6"
                // }
                setSuccess(true)
            },
            theme: {
                color: "#E6570E",
            },
        };

        const rzp1 = new Razorpay(options);

        rzp1.on("payment.failed", function (response) {
            console.log("FAILED ", response)
            // let response = {
            //     "error": {
            //         "code": "BAD_REQUEST_ERROR",
            //         "description": "Your payment has been cancelled. Try again or complete the payment later.",
            //         "source": "customer",
            //         "step": "payment_authentication",
            //         "reason": "payment_cancelled",
            //         "metadata": {
            //             "payment_id": "pay_MnDaruiCjrkQxo",
            //             "order_id": "order_MnDaYMelr28jVO"
            //         }
            //     }
            // }
        });

        rzp1.open();
    })

    return { success, setSuccess, createOrder }
}


export default usePayment
import React, { useRef, useState, useEffect } from "react";
import { Button, Form, Dropdown, Modal, Card, Row, Col } from "react-bootstrap";
import { Link, Redirect, NavLink, useHistory } from "react-router-dom";
import fedduplogo from "../images/fedduplogo.png";
import NavBar from "../NavBar/NavBar";
import './UsersFeed.css';
import upvoteb from '../images/upvote-b.svg';
import downvoteb from '../images/downvote-b.svg';
import upvote from '../images/upvote.svg';
import downvote from '../images/downvote.svg';
import comment from '../images/comments.svg';
import share from '../images/share.svg';
import trending from '../images/trending.svg';
import newData from '../images/new.svg';
import top from '../images/top.svg';
import "./hideScrollbar.css";
import './arrowsWithContent.css';
import { Feedback } from './Feedback';
import { Suggestion } from './Suggestion';
import { Announcements } from './Announcements';
import { TopProducts } from "./TopProducts";
import { TopWebApps } from "./TopwWebApps";
// import { Tags } from "./TrendingTags";
import axios from 'axios';
import { config } from '../Config/Config';
import ImgEdit from "../images/edit.svg";
import imgDel from "../images/delete.svg";
import dots from "../images/dots.svg";
import fillTag from "../images/fill-tag.svg";
import emptyTag from "../images/empty-tag.svg";
import PopupBox from "../CompanyFeed/PopupBox";
import PopupComments from './PopupComments';
import PopupSuggestionComments from './PopupSuggestionComments';
import PopupAnnouncementComments from './PopupAnnouncementComments';
import InfiniteScroll from "react-infinite-scroll-component";
import ViewAllCommentsForProduct from "./ViewAllCommentForAProduct";
import DeleteFeedbackPopup from "./DeleteFeedbackPopup";
import EditFeedbackPopup from "./EditFeedbackPopup";
import DeleteSuggestionPopup from "./DeleteSuggestionPopup";
import EditSuggestionPopup from "./EditSuggestionPopup";
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState } from "draft-js";
import draftToHtmlPuri from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";
import { stateToHTML } from "draft-js-export-html";
import ViewImage from "./ViewImage";
import ViewSuggestionImage from "./ViewSuggestionImage";
import OpenShareFeedbackPopup from "./OpenShareFeedbackPopup";
import OpenShareSuggestionPopup from "./OpenShareSuggestionPopup";
import OpenShareAnnouncementPopup from "./OpenShareAnnouncementPopup";
import arrowLetf from "../images/left-arrow.svg";
import arrowRight from "../images/right-arrow.svg";
import suggestionImage from "../images/suggestion-img.svg";
import suggestionAccepted from '../images/suggestion-accepted.svg';
import suggestionRejected from '../images/suggestion-rejected.svg';
import suggestionInprogress from '../images/suggestion-inprogress.svg'
import Tag from "../utils/Tag";
import moment from "moment";
import { CircleFadeLoader } from "react-loaders-kit";
import ShareBox from "./ShareBox";
import { decrypt } from "../utils";

export default function UsersFeed() {
    const history = useHistory()
    const [deletePost, setDeletePost] = useState(false);
    const [edit, setEdit] = useState(false);
    const [deleteSuggestionPost, setDeleteSuggestionPost] = useState(false);
    const [editSuggestionPost, setEditSuggestionPost] = useState(false);
    const [show, setShow] = useState(false);
    const [scroll, setScroll] = useState(0);
    const [title, setTitle] = useState("");
    const [postFeedback, setPostFeedback] = useState("");
    const [viewImage, setViewImage] = useState(false);
    const [popupImage, setPopupImage] = useState("");
    const [viewSuggestionImage, setViewSuggestionImage] = useState(false);
    const [popupSuggestionImage, setPopupSuggestionImage] = useState("");
    const [viewAnnouncementImage, setViewAnnouncementImage] = useState(false);
    const [popupAnnouncementImage, setPopupAnnouncementImage] = useState("");
    const [feedId, setFeedId] = useState("");
    const [suggId, setSuggId] = useState("");
    const [image, setImage] = useState("");
    const [selectedValue, setSelectedValue] = useState(0);
    const [selectNewValue, setSelectNewValue] = useState("");
    const [postSuggestion, setPostSuggestion] = useState("");
    const [trendingToday, setTrendingToday] = useState([
        { "id": 0, "productIcon": "https://res.cloudinary.com/dpx0yz1k8/image/upload/v1670243069/logo_pesdot.png", "productName": "abc" },
        { "id": 1, "productIcon": "https://res.cloudinary.com/dpx0yz1k8/image/upload/v1670308446/fedduplogo_vsrtja.png", "productName": "abc" },
        { "id": 2, "productIcon": "https://res.cloudinary.com/dpx0yz1k8/image/upload/v1670243069/logo_pesdot.png", "productName": "abc" },
        { "id": 3, "productIcon": "https://res.cloudinary.com/dpx0yz1k8/image/upload/v1670308446/fedduplogo_vsrtja.png", "productName": "abc" },
        { "id": 4, "productIcon": "https://res.cloudinary.com/dpx0yz1k8/image/upload/v1670243069/logo_pesdot.png", "productName": "abc" },
        { "id": 5, "productIcon": "https://res.cloudinary.com/dpx0yz1k8/image/upload/v1670308446/fedduplogo_vsrtja.png", "productName": "abc" },
        { "id": 6, "productIcon": "https://res.cloudinary.com/dpx0yz1k8/image/upload/v1670243069/logo_pesdot.png", "productName": "abc" },
        { "id": 7, "productIcon": "https://res.cloudinary.com/dpx0yz1k8/image/upload/v1670308446/fedduplogo_vsrtja.png", "productName": "abc" },
        { "id": 8, "productIcon": "https://res.cloudinary.com/dpx0yz1k8/image/upload/v1670243069/logo_pesdot.png", "productName": "abc" },
    ])
    const [trendingTags, setTrendingTags] = useState([])
    const [trendingTopProduct, setTrendingTopProduct] = useState([])
    const [trendingTopWebApp, setTrendingTopWebApp] = useState([])
    const [feedbackData, setFeedbackData] = useState([]);
    const [suggestionData, setSuggestionData] = useState([]);
    const [ratingData, setRatingData] = useState([]);
    const [newRatingData, setNewRatingData] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(4);
    const [announcementData, setAnnouncementData] = useState([]);
    const [item, setItem] = useState("Feedback")
    const [popSuggestion, setPopSuggestion] = useState(false)
    const [uImg, setUImg] = useState(false);
    const [dImg, setDImg] = useState(false);
    const [count, setCount] = useState(0);
    const [popup, setPopup] = useState(false);
    const [openShareFeedback, setOpenShareFeedback] = useState(false);
    const [openShareSuggestion, setOpenShareSuggestion] = useState(false);
    const [openShareAnnouncement, setOpenShareAnnouncement] = useState(false);
    const [popupSuggestion, setPopupSuggestion] = useState(false);
    const [popupAnnouncement, setPopupAnnouncement] = useState(false);
    const [popupData, setPopupData] = useState([]);
    const [popupSuggestionData, setPopupSuggestionData] = useState([]);
    const [popupAnnouncementData, setPopupAnnouncementData] = useState([]);
    const [tag, setTag] = useState([]);
    const [viewAllComments, setViewAllComments] = useState(false);
    const [viewAllSuggestionComments, setViewAllSuggestionComments] = useState(false);
    const [viewAllRatingComments, setViewAllRatingComments] = useState(false);
    const [viewAllCommentsByFeedbackId, setViewAllCommentsByFeedbackId] = useState("");
    const [viewAllCommentsBySuggestionId, setViewAllCommentsBySuggestionId] = useState("");
    const [viewAllCommentsByRatingId, setViewAllCommentsByRatingId] = useState("");
    const [isRatingLoading, setIsRatingLoading] = useState(true);
    const [editorDesc, setEditorDesc] = useState("");
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [productId, setProductId] = useState()
    const [trendingWebApp, setTrendingWebApp] = useState([])
    const [trendingDesktopApp, setTrendingDesktopApp] = useState([])
    const [tagValues, setTagValues] = useState(
        [
            { id: 0, tag: "Updates", isSelected: false },
            { id: 1, tag: "New Version", isSelected: false },
            { id: 2, tag: "New Tags", isSelected: false },
        ]
    )
    const [mainSection, setMainSection] = useState(false);

    const [loading, setLoading] = useState(true);

    const loaderProps = {
        loading,
        size: 40,
        duration: 0.5,
        colors: ['#5e22f0']
    }

    const onEditorStateChange = (state) => {
        setEditorState(state)
    };
    const htmlText = draftToHtmlPuri(
        convertToRaw(editorState.getCurrentContent())
    );
    // const itemsPrev = usePrevious(trendingToday);
    // const apiRef = React.useRef({});
    // React.useEffect(() => {
    //   if (trendingToday.length > itemsPrev?.length) {
    //     apiRef.current?.scrollToItem?.(
    //       apiRef.current?.getItemElementById(trendingToday.slice(-1)?.[0]?.id)
    //       // same as
    //       // document.querySelector(`[data-key='${items.slice(-1)?.[0]?.id}']`)
    //     );
    //   }
    // }, [trendingToday, itemsPrev]);
    /**Scroll Function */
    const [visible, setVisible] = useState(true)
    const toggleVisible = () => {
        if (window.pageYOffset > 250) {
            setVisible(false)
        }
        else if (window.pageYOffset <= 250) {
            setVisible(true)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible);
        return () => {
            window.removeEventListener('scroll', toggleVisible)
        }
    }, [visible]);

    const getUserType = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }

        axios.get(config.userUrl + 'user/getUserType', { headers: headers })
            .then(function (response) {
                if (response.data.user) {
                    localStorage.setItem("role", response.data.user.role)
                    if (response.data.user.productIds)
                        localStorage.setItem("productIds", JSON.stringify(response.data.user.productIds))
                    if (response.data.user.role !== 'individual') {
                        window.location.replace('/admindashboard')
                    }
                    else {
                        setLoading(false)
                    }
                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log("Error", error);
            });

    }

    useEffect(() => {
        getUserType();
        // getRatings();
        window.scrollTo(0, 0);
        getAllFeedbacks(0);
        getTrendingToday();
        getTrendingTopProducts();
        getTrendingWebApps();
        getTrendingTags();
    }, [])
    const onChange = (e) => {
        setEditorDesc(e.target.value)
        console.log("desc", e.target.value, editorDesc)
    };
    const ref = useRef(null);
    function suggestionPopover() {
        setPopSuggestion(!popSuggestion)
    }
    function getTrendingToday() {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
        }
        axios.post(config.userUrl + 'user/getTrendingTodayItem', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    console.log("trendingToday successfull");
                    console.log(response.data.trendingTodayProduct)
                    if (response.data.trendingTodayProduct?.length) {

                        setTrendingWebApp(response?.data?.trendingTodayProduct?.filter(item => item.productType == "Web App"))
                        setTrendingDesktopApp(response?.data?.trendingTodayProduct?.filter(item => item.productType == "Desktop App"))
                        setTrendingToday(response.data.trendingTodayProduct)
                    }
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    function getTrendingTags() {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
        }
        axios.post(config.userUrl + 'user/getTrendingTopTag', payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("trendingTags successfull");
                    setTrendingTags(response.data.tag)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const getAllFeedbacks = (eventKey) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "type": parseInt(eventKey)
        }
        axios.post(config.userUrl + 'user/getAllFeedbacks', payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("getFeedback successfull", response.data);
                    setFeedbackData(response.data.feedback?.filter(item => item?.title))
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const getSuggestions = (eventKey) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "type": parseInt(eventKey)
        }
        axios.post(config.userUrl + 'user/getAllSuggestions', payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("getSuggestion successfull");
                    setSuggestionData(response.data.suggestion?.filter(item => item?.title))
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const getRatings = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getRatingCommentForAllProduct', { headers: headers })
            .then(function (response) {
                console.log("getRatings", response);
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("getRatings successfull", response.data);
                    setRatingData(response.data.ratingDetails)
                    // setIsRatingLoading(false)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    const fetchMoreRatingData = () => {
        console.log("ratingData", ratingData, newRatingData)
        setTimeout(() => {
            setNewRatingData(ratingData.concat(Array.from({ length: 5 })))
        }, 1500);
    };
    const getAllAnnouncements = (eventKey) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "type": parseInt(eventKey)
        }
        axios.post(config.userUrl + 'user/getAllAnnouncements', payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("getAllAnnouncements successfull");
                    setAnnouncementData(response.data.announcement)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    const getTrendingTopProducts = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
        }
        axios.post(config.userUrl + 'user/getTrendingTopProduct', payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("trendingTopProduct successfull");
                    setTrendingTopProduct(response.data.topProduct)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const getTrendingWebApps = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
        }
        axios.post(config.userUrl + 'user/getTrendingTopWebApp ', payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("trendingWebApps successfull");
                    setTrendingTopWebApp(response.data.topWebApp)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const feedback = () => {
        setItem("Feedback")
        getAllFeedbacks(0);
        setSelectedValue(0)
    }
    const suggestion = () => {
        console.log("item2", item)
        setItem("Suggestion")
        getSuggestions(0);
        setSelectedValue(0)
    }
    const ratings = () => {
        console.log("item3", item)
        setItem("Ratings")
        getRatings();
    }
    const announcements = () => {
        console.log("item3", item)
        setItem("Announcements")
        getAllAnnouncements(0);
        setSelectedValue(0)
    }
    // function changeUpvoteImage(id) {
    //     console.log("dataup", id.id)
    //     setUImg(!uImg)
    //     if (uImg) {
    //         setCount(count - 1)
    //         setDImg(false)

    //     } else {
    //         setCount(count + 1)
    //         // setDImg(false)
    //         setDImg(false)
    //     }
    // }
    function changeUpvoteImage(id, val) {
        id["vote"] = val;
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        setUImg(!uImg)
        if (uImg) {
            setCount(count - 1)
            setDImg(false)

        } else {
            setCount(count + 1)
            // setDImg(false)
            setDImg(false)
        }
        var payload = {
            "feedbackId": id.id,
            "feedback": val,
            "userIdFeedbackBelongTo": id.feedbackByUserId
        }
        console.log("upvote", payload)
        axios.post(config.userUrl + 'user/likeOrDislikeFeedback', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    getAllFeedbacks(selectedValue);
                    console.log("upvote", payload, response.data)
                }
            })
            .catch(function (error) {
                console.log(error, payload)
            })
    }
    // function changeDownvoteImage(id) {
    //     console.log("data", id)
    //     setDImg(!dImg)
    //     if (!dImg) {
    //         setUImg(false)
    //         setCount(count - 1)
    //     } else {
    //         // setDImg(!dImg)
    //         setUImg(false)
    //         setDImg(false)
    //     }
    // }
    function changeDownvoteImage(id, val) {
        id["vote"] = val;
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        setDImg(!dImg)
        if (!dImg) {
            setUImg(false)
            setCount(count - 1)
        } else {
            // setDImg(!dImg)
            setUImg(false)
            setDImg(false)
        }
        var payload = {
            "feedbackId": id.id,
            "feedback": val,
            "userIdFeedbackBelongTo": id.feedbackByUserId
        }
        console.log("upvote", payload)
        axios.post(config.userUrl + 'user/likeOrDislikeFeedback', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    getAllFeedbacks(selectedValue);
                    console.log("upvote", payload, response.data)
                }
            })
            .catch(function (error) {
                console.log(error, payload)
            })
    }
    function changeUpvoteImageSuggestion(id, val) {
        id["vote"] = val;
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        setUImg(!uImg)
        if (uImg) {
            setCount(count - 1)
            setDImg(false)

        } else {
            setCount(count + 1)
            // setDImg(false)
            setDImg(false)
        }
        var payload = {
            "suggestionId": id.id,
            "suggestion": val,
            "userIdSuggestionBelongTo": id.suggestionByUserId
        }
        console.log("upvote", payload)
        axios.post(config.userUrl + 'user/likeOrDislikeSuggestion', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    getSuggestions(selectedValue);
                    console.log("upvote", payload, response.data)
                }
            })
            .catch(function (error) {
                console.log(error, payload)
            })
    }
    function changeDownvoteImageSuggestion(id, val) {
        id["vote"] = val;
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        setDImg(!dImg)
        if (!dImg) {
            setUImg(false)
            setCount(count - 1)
        } else {
            // setDImg(!dImg)
            setUImg(false)
            setDImg(false)
        }
        var payload = {
            "suggestionId": id.id,
            "suggestion": val,
            "userIdSuggestionBelongTo": id.suggestionByUserId
        }
        console.log("upvote", payload)
        axios.post(config.userUrl + 'user/likeOrDislikeSuggestion', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log("upvote", payload, response.data)
                    getSuggestions(selectedValue);
                }
            })
            .catch(function (error) {
                console.log(error, payload)
            })
    }
    function changeUpvoteImageAnnouncement(id, val) {
        id["vote"] = val;
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        setUImg(!uImg)
        if (uImg) {
            setCount(count - 1)
            setDImg(false)

        } else {
            setCount(count + 1)
            // setDImg(false)
            setDImg(false)
        }
        var payload = {
            "announcementId": id.id,
            "announcement": val,
            "userIdAnnouncementBelongTo": "54067d60-f089-11ec-bb8d-59b2cc6c8842"
        }
        console.log("upvote", payload)
        axios.post(config.userUrl + 'user/likeOrDislikeAnnouncement', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    getAllAnnouncements(selectedValue);
                    console.log("upvote", payload, response.data)
                }
            })
            .catch(function (error) {
                console.log(error, payload)
            })
    }
    function changeDownvoteImageAnnouncement(id, val) {
        id["vote"] = val;
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        setDImg(!dImg)
        if (!dImg) {
            setUImg(false)
            setCount(count - 1)
        } else {
            // setDImg(!dImg)
            setUImg(false)
            setDImg(false)
        }
        var payload = {
            "announcementId": id.id,
            "announcement": val,
            "userIdAnnouncementBelongTo": "54067d60-f089-11ec-bb8d-59b2cc6c8842"
        }
        console.log("upvote", payload)
        axios.post(config.userUrl + 'user/likeOrDislikeAnnouncement', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log("upvote", payload, response.data)
                    getAllAnnouncements(selectedValue);
                }
            })
            .catch(function (error) {
                console.log(error, payload)
            })
    }
    // console.log("item", typeof (parseInt(item)))
    function editFeedback(id) {
        setEdit(!edit)
        setProductId(id?.productId)
        setTitle(id.title)
        setPostFeedback(id.feedback)
        setImage(id.feedbackImage)
        setTag(id.tag)
        setFeedId(id.id)
    }
    function closeEditFeedback() {
        setEdit(false)
        setTitle("")
        setPostFeedback("")
        setImage("")
        setTag("")
        setFeedId("")
    }

    function editSuggestion(id) {
        setProductId(id?.productId)
        setEditSuggestionPost(!editSuggestionPost)
        setTitle(id.title)
        setPostSuggestion(id.suggestion)
        setImage(id.suggestionImage)
        setTag(id.tag)
        setSuggId(id.id)
    }
    function closeEditSuggestion() {
        setEditSuggestionPost(false)
        setTitle("")
        setPostSuggestion("")
        setTag("")
        setImage("")
        setSuggId("")
    }
    function deleteFeedback(id) {
        setDeletePost(!deletePost)
        setFeedId(id.id)
    }
    function closeDeleteFeedback() {
        setDeletePost(false)
        setFeedId("")
    }
    function deleteSuggestion(id) {
        setDeleteSuggestionPost(!deleteSuggestionPost)
        setSuggId(id.id)
    }
    function closeDeleteSuggestion() {
        setDeleteSuggestionPost(false)
        setSuggId("")
    }
    function showViewAllComments(id) {
        setViewAllCommentsByFeedbackId(id)
        setViewAllComments(true)
    }
    function showViewAllSuggestionComments(id) {
        setViewAllCommentsBySuggestionId(id)
        setViewAllSuggestionComments(true)
    }
    function showViewAllRatingComments(id) {
        setViewAllCommentsByRatingId(id)
        setViewAllRatingComments(true)
    }
    const editFeedbackById = (id) => {
        let tagsData = [];
        tagsData = tagValues.filter(item => (item.isSelected === true) ? item.tag : null)
        var payload = {
            "feedbackId": feedId,
            "title": title,
            "feedback": postFeedback,
            "tag": tagsData.map(item => item.tag),
        }
        console.log("payload", id, payload)
        axios.post(config.userUrl + 'user/updateFeedbackByCreater', payload)
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, payload);
                    setEdit(false)
                    getAllFeedbacks(selectedValue);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const deleteFeedbackById = (id) => {
        console.log("del", feedId)
        var payload = {
            "feedbackId": feedId
        }
        console.log("payload", id, feedId, payload)
        axios.post(config.userUrl + 'user/deleteFeedbackByCreater', payload)
            .then(function (response) {
                if (response.data.status == true) {
                    console.log("success");
                    setDeletePost(false)
                    getAllFeedbacks(selectedValue);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const editSuggestionById = (id) => {
        var payload = {
            "suggestionId": suggId,
            "title": title,
            "suggestion": postSuggestion,
            "tag": ["Updates"]
        }
        console.log("payload", id, payload)
        axios.post(config.userUrl + 'user/updateSuggestionByCreater', payload)
            .then(function (response) {
                if (response.data.status == true) {
                    console.log("suggestion updated successfully");
                    setEdit(false)
                    getSuggestions(selectedValue)
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const deleteSuggestionById = (id) => {
        console.log("del", suggId)
        var payload = {
            "suggestionId": suggId
        }
        console.log("payload", id, suggId, payload)
        axios.post(config.userUrl + 'user/deleteSuggestionByCreater', payload)
            .then(function (response) {
                if (response.data.status == true) {
                    console.log("success");
                    setDeletePost(false)
                    getSuggestions(selectedValue);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    function shareFeedback(data) {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        if (navigator.share) {
            navigator.share({
                url: `https://www.feddup.com/${data.title}`,
            }).then(() => {
                console.log('Thanks for sharing!', data.title);
            }).catch(err => {
                console.log(
                    "Error while using Web share API:");
                console.log(err);
            });
        } else {
            console.log("Sorry! Your browser does not support Web Share API");
        }
        var payload = {
            "feedbackIdd": data.id,
            "userIdFeedbackBelongTo": data.feedbackByUserId
        }
        console.log("payload", payload)
        axios.post(config.userUrl + 'user/shareFeedbackByUser', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    setTimeout(() => {
                        getAllFeedbacks(selectedValue);
                    }, 1000);
                    console.log("feedback shared successfully");
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            })
    };
    function shareArticle(data, val) {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        if (navigator.share) {
            navigator.share({
                url: `https://www.feddup.com/${data.title}`,
            }).then(() => {
                console.log('Thanks for sharing!', data.title);
            }).catch(err => {
                console.log(
                    "Error while using Web share API:");
                console.log(err);
            });
        } else {
            console.log("Sorry! Your browser does not support Web Share API");
        }
        if (val == "feedback") {
            var payload = {
                "feedbackIdd": data.id,
                "userIdFeedbackBelongTo": data.feedbackByUserId
            }
            console.log("payload", payload)
            axios.post(config.userUrl + 'user/shareFeedbackByUser', payload, { headers: headers })
                .then(function (response) {
                    if (response.data.status == true) {
                        setTimeout(() => {
                            getAllFeedbacks(selectedValue);
                        }, 1000);
                        console.log("feedback shared successfully");
                    } else {
                        console.log("Error", payload);
                    }
                })
                .catch(function (error) {
                    console.log("error", error, payload);
                });
        } else if (val == "suggestion") {
            var payload = {
                "suggestionId": data.id,
                "userIdSuggestionBelongTo": data.suggestionByUserId
            }
            console.log("payload", payload)
            axios.post(config.userUrl + 'user/shareSuggestionByUser', payload, { headers: headers })
                .then(function (response) {
                    if (response.data.status == true) {
                        setTimeout(() => {
                            getSuggestions(selectedValue);
                        }, 1000)
                        console.log("suggestion shared successfully");
                    } else {
                        console.log("Error", payload);
                    }
                })
                .catch(function (error) {
                    console.log("error", error, payload);
                });
        } else {
            var payload = {
                "announcementId": data.id,
                "userIdAnnouncementBelongTo": data.announcementByAdminId
            }
            console.log("payload", payload)
            axios.post(config.userUrl + 'user/announcementShare', payload, { headers: headers })
                .then(function (response) {
                    if (response.data.status == true) {
                        setTimeout(() => {
                            getAllAnnouncements(selectedValue);
                        }, 1000)
                        console.log("announcement shared successfully");
                    } else {
                        console.log("Error", payload);
                    }
                })
                .catch(function (error) {
                    console.log("error", error, payload);
                });
        }
    };
    const handleTags = (e, tag) => {
        const updated = tagValues.map(data => (data.tag === tag) ?
            { ...data, isSelected: !data.isSelected } : data)
        setTagValues(updated)
    }
    const togglePopup = (data) => {
        setPopup(!popup)
        setPopupData(data)
    }
    let totalPages = Math.ceil((trendingToday.length) / (pageSize));
    const nextPage = () => {
        if (pageNo < totalPages) {
            setPageNo(pageNo + 1)
        }
    }
    const previousPage = () => {
        if (pageNo > 1) {
            setPageNo(pageNo - 1)
        }
    }
    // if (popup) {
    //     return <PopupComments closePopup={togglePopup} data={popupData} />
    // }
    if (viewAllComments) {
        return <Redirect to={{
            pathname: `/userfeed/${item}`,
            state: {
                referrer: item,
                id: viewAllCommentsByFeedbackId
            }
        }} />
    }
    if (viewAllSuggestionComments) {
        return <Redirect to={{
            pathname: `/userfeed/${item}`,
            state: {
                referrer: item,
                id: viewAllCommentsBySuggestionId
            }
        }} />
    }
    if (viewAllRatingComments) {
        return <Redirect to={{
            pathname: `/userfeed/${item}`,
            state: {
                referrer: item,
                id: viewAllCommentsByRatingId
            }
        }} />
    }
    const togglePopupSuggestion = (data) => {
        setPopupSuggestion(!popupSuggestion)
        setPopupSuggestionData(data)
    }
    // if (popupSuggestion) {
    //     return <PopupSuggestionComments closePopup={togglePopupSuggestion} data={popupSuggestionData} />
    // }
    const togglePopupAnnouncement = (data) => {
        setPopupAnnouncement(!popupAnnouncement)
        setPopupAnnouncementData(data)
    }
    const onSelect = (eventKey) => {
        setSelectedValue(parseInt(eventKey))
        if (item == "Feedback") {
            getAllFeedbacks(eventKey);
        } else if (item == "Suggestion") {
            getSuggestions(eventKey);
        } else if (item == "Announcements") {
            getAllAnnouncements(eventKey);
        } else {
            getRatings()
        }
        console.log("selectedValue", eventKey)
    }
    const closeImage = () => {
        setViewImage(false)
    }
    const openImage = (data) => {
        setViewImage(true)
        setPopupImage(data.feedbackImage)
    }
    const closeSuggestionImage = () => {
        setViewSuggestionImage(false)
    }
    const openSuggestionImage = (data) => {
        setViewSuggestionImage(true)
        setPopupSuggestionImage(data.suggestionImage)
    }
    const closeAnnouncementImage = () => {
        setViewAnnouncementImage(false)
    }
    const openAnnouncementImage = (data) => {
        setViewAnnouncementImage(true)
        setPopupAnnouncementImage(data.announcementImage)
    }
    const toggleShareFeedbackPopup = (data) => {
        setOpenShareFeedback(!openShareFeedback)
        setPopupData(data)
    }
    const toggleShareSuggestionPopup = (data) => {
        setOpenShareSuggestion(!openShareSuggestion)
        setPopupSuggestionData(data)
    }
    const toggleShareAnnouncementPopup = (data) => {
        setOpenShareAnnouncement(!openShareAnnouncement)
        setPopupAnnouncementData(data)
    }




    return (
        <div>
            <NavBar />
            {viewImage && <ViewImage close={closeImage} image={popupImage} />}
            {viewSuggestionImage && <ViewSuggestionImage close={closeSuggestionImage} image={popupSuggestionImage} />}
            {viewAnnouncementImage && <ViewSuggestionImage close={closeAnnouncementImage} image={popupAnnouncementImage} />}
            {popup && <PopupComments openImage={openImage} closePopup={togglePopup} getAllFeedbacks={getAllFeedbacks} data={popupData} selectedValue={selectedValue} />}
            {popupSuggestion && <PopupSuggestionComments openSuggestionImage={openSuggestionImage} closePopup={togglePopupSuggestion} getSuggestions={getSuggestions} data={popupSuggestionData} selectedValue={selectedValue} />}
            {popupAnnouncement && <PopupAnnouncementComments openAnnouncementImage={openAnnouncementImage} closePopup={togglePopupAnnouncement} getAnnouncements={getAllAnnouncements} data={popupAnnouncementData} selectedValue={selectedValue} />}
            {deletePost && <DeleteFeedbackPopup closePopup={closeDeleteFeedback} getAllFeedbacks={getAllFeedbacks} data={feedId} selectedValue={selectedValue} />}
            {edit && <EditFeedbackPopup productId={productId} closePopup={closeEditFeedback} feedId={feedId} image={image} getAllFeedbacks={getAllFeedbacks} title={title} postFeedback={postFeedback} tag={tag} selectedValue={selectedValue} />}
            {deleteSuggestionPost && <DeleteSuggestionPopup closePopup={closeDeleteSuggestion} getSuggestions={getSuggestions} data={suggId} selectedValue={selectedValue} />}
            {editSuggestionPost && <EditSuggestionPopup productId={productId} closePopup={closeEditSuggestion} image={image} getSuggestions={getSuggestions} suggId={suggId} title={title} postSuggestion={postSuggestion} tag={tag} selectedValue={selectedValue} />}
            {openShareFeedback && <OpenShareFeedbackPopup closePopup={toggleShareFeedbackPopup} getAllFeedbacks={getAllFeedbacks} data={popupData} selectedValue={selectedValue} />}
            {openShareSuggestion && <OpenShareSuggestionPopup closePopup={toggleShareSuggestionPopup} getSuggestions={getSuggestions} data={popupSuggestionData} selectedValue={selectedValue} />}
            {openShareAnnouncement && <OpenShareAnnouncementPopup closePopup={toggleShareAnnouncementPopup} getAnnouncements={getAllAnnouncements} data={popupAnnouncementData} selectedValue={selectedValue} />}

            {
                loading
                    ?
                    <>
                        <div className='mx-auto flex flex-col items-center justify-center h-96'><CircleFadeLoader {...loaderProps} /><span className='text-2xl font-semibold'>Loading...</span></div>
                    </>
                    :

                    <div id="background">
                        <div className="usersfeed-l-r"><br /><br />
                            <Row className="row-width flex items-start">
                                <Col md={mainSection ? 3 : 8}
                                    onClick={(event) => { if (mainSection === true) setMainSection(false) }}
                                >
                                    <Card className="rounded-[10px] h-52 border-0" style={{ cursor: "pointer" }}>
                                        {mainSection ? <div className="flex items-center justify-around px-4 py-12">
                                            <div><img className="w-10" src={arrowLetf} alt="arrow-left" /></div>
                                            <div className="flex flex-col gap-4">
                                                <div className="font-semibold xl:text-xl 2xl:text-2xl text-orange-600 leading-4">Trending</div>
                                                <div className="font-semibold xl:text-2xl 2xl:text-3xl leading-4 text-orange-400">Web Apps</div>
                                                <div className="ml-auto font-semibold xl:text-xl 2xl:text-2xl text-orange-600 leading-4">Today</div>
                                            </div>
                                        </div> :
                                            <div className="w-full card_padding p-3">
                                                <h1 className="font-semibold text-xl text-orange-600 leading-4 my-2 ml-2">Trending <span className="text-orange-400">Web Apps</span> Today</h1>
                                                <div className="flex items-center gap-3 w-full px-12">
                                                    {(trendingWebApp?.length && pageNo > 1) ? < div onClick={previousPage} className="previous" style={{ color: ` ${pageNo === 1 ? "grey" : ""}` }}><i class="fa fa-angle-left fa-3x" aria-hidden="true"></i></div> : <></>}

                                                    <div className="flex flex-grow items-center justify-evenly gap-6 overflow-x-auto scrollbar-hide">
                                                        {trendingWebApp.slice(pageNo * 4 - 4, pageNo * 4).map((data, i) => (
                                                            <div className="row trending-today-bg !w-auto" key={i}>
                                                                <div className="col-md-12">
                                                                    <Link to={{
                                                                        pathname: `/by/${data.companyName}/${data.productName}/${data.productType}`,
                                                                        state: {
                                                                            id: data.id
                                                                        }
                                                                    }}>
                                                                        <div className="my-2 scroll-cir-icon"> <img src={data?.productIcon || fedduplogo} className="w-full h-full rounded-full" alt={i} /></div>

                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {trendingWebApp?.length > 4 ? <div onClick={nextPage} className="next" style={{ color: ` ${pageNo < totalPages ? "" : "grey"}` }}><i class="fa fa-angle-right fa-3x" aria-hidden="true"></i></div> : <></>}
                                                </div>
                                            </div>
                                        }
                                    </Card>
                                </Col>
                                <Col className={mainSection ? 8 : 3}
                                    onClick={(event) => { if (mainSection === false) setMainSection(true) }}
                                >
                                    <Card className="rounded-[10px] h-52 border-0" style={{ cursor: "pointer" }}>
                                        {mainSection === false ?
                                            <div className="flex items-center justify-around px-2 py-12">
                                                <div className="flex flex-col gap-4">
                                                    <div className="font-semibold xl:text-xl 2xl:text-2xl text-orange-600 leading-4">Trending</div>
                                                    <div className="font-semibold xl:text-2xl 2xl:text-3xl leading-4 text-orange-400">Desktop Apps</div>
                                                    <div className="ml-auto font-semibold xl:text-xl 2xl:text-2xl text-orange-600 leading-4">Today</div>
                                                </div>
                                                <div><img className="w-10" src={arrowRight} alt="arrow-left" /></div>
                                            </div> :
                                            <div className="w-full card_padding p-3">
                                                <h1 className="font-semibold text-xl text-orange-600 leading-4 my-2 ml-2">Trending <span className="text-orange-400"> Desktop Apps</span> Today</h1>
                                                <div className="flex items-center gap-3 w-full px-12">
                                                    {(trendingDesktopApp?.length && pageNo > 1) ? < div onClick={previousPage} className="previous" style={{ color: ` ${pageNo === 1 ? "grey" : ""}` }}><i class="fa fa-angle-left fa-3x" aria-hidden="true"></i></div> : <></>}

                                                    <div className="flex flex-grow items-center justify-evenly gap-6 overflow-x-auto scrollbar-hide">
                                                        {trendingDesktopApp.slice(pageNo * 4 - 4, pageNo * 4).map((data, i) => (
                                                            <div className="row trending-today-bg !w-auto" key={i}>
                                                                <div className="col-md-12">
                                                                    <Link to={{
                                                                        pathname: `/by/${data.companyName}/${data.productName}/${data.productType}`,
                                                                        state: {
                                                                            id: data.id
                                                                        }
                                                                    }}>
                                                                        <div className="my-2 scroll-cir-icon"> <img src={data?.productIcon || fedduplogo} className="w-full h-full rounded-full" alt={i} /></div>

                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {trendingDesktopApp?.length > 4 ? <div onClick={nextPage} className="next" style={{ color: ` ${pageNo < totalPages ? "" : "grey"}` }}><i class="fa fa-angle-right fa-3x" aria-hidden="true"></i></div> : <></>}
                                                </div>
                                            </div>
                                        }
                                    </Card>
                                </Col>
                                <Col md={1} />
                            </Row>
                            <Row className="row-width">
                                <Col md={8}>
                                    <div className="my-2">
                                        <div className="row">
                                            <Col md={12}>
                                                <div className={`row cards_width_col px-20 py-2 ${visible === false ? "fixed-buttons mx-auto w-[80%] bg-white" : "mt-3 mx-auto"}`}>
                                                    <Col md={4} className="mb-2">
                                                        <div className='btn-outer-section'>
                                                            <Card className={item == "Feedback" ? "feed-card f-color btn-inner-section" : "feed-card-a f-color-g btn-inner-section"} onClick={feedback}>Feddback</Card>
                                                        </div>
                                                    </Col>
                                                    <Col md={4}>
                                                        <div className='btn-outer-section'>
                                                            <Card className={item == "Suggestion" ? "feed-card f-color btn-inner-section" : "feed-card-a f-color-g btn-inner-section"} onClick={suggestion}>Suggestion</Card>
                                                        </div>
                                                    </Col>
                                                    <Col md={4} className="feedback-btn">
                                                        <div className='btn-outer-section'>
                                                            <Card className={item == "Announcements" ? "feed-card f-color btn-inner-section" : "feed-card-a f-color-g btn-inner-section"} onClick={announcements}>Announcements</Card>
                                                        </div>
                                                    </Col>

                                                </div>
                                            </Col>
                                        </div>
                                    </div>

                                    {item == "Feedback" ?
                                        <div className="mt-2">
                                            {feedbackData.length == 0 ? <Card className="mt-2 no-pages-"><center className="no-details-text"><img src={downvoteb} className="no-pages-img" /><br />You follow no feddup pages</center></Card> :
                                                <div>
                                                    {feedbackData.map((data, i) => (
                                                        <div className="mt-2" key={i}>
                                                            <Card className=" rounded-[10px] border-0 mb-8">
                                                                <div className="row">
                                                                    <Col md={2}>
                                                                        <Card className="ml-2 mt-1 mb-2 py-4 vote-bg border-0 rounded-[10px]" style={{ height: "96%" }}>
                                                                            <center className={localStorage.getItem('junkStore') ? "cursor" : ""}> {data.vote === 1 ? <img src={upvote} onClick={(e, i) => changeUpvoteImage(data, 2, i)} className="w-16" /> : <img src={upvoteb} onClick={(e) => changeUpvoteImage(data, 1)} className="w-14" />}</center><br />
                                                                            <center className="likes">{data.totallike == null ? 0 : data.totallike}</center><br />
                                                                            <center className={localStorage.getItem('junkStore') ? "cursor" : ""}> {data.vote === -1 ? <img src={downvote} onClick={(e, i) => changeDownvoteImage(data, 2)} className="w-16" /> : <img src={downvoteb} onClick={(e) => changeDownvoteImage(data, 0)} className="w-14" />}</center>
                                                                        </Card>
                                                                    </Col>
                                                                    <Col md={10}>
                                                                        <div className="ml-1 my-3">
                                                                            <div className="card-padding">
                                                                                <div className={`${data.userCreate ? "" : "hidden"}`}>
                                                                                    {data.userCreate ?
                                                                                        <Dropdown align="end">
                                                                                            <Dropdown.Toggle variant="default" style={{ float: "right" }} className="abc feed-card-a -mt-2 h-7">
                                                                                                <img src={dots} style={{ width: "3px" }} />
                                                                                            </Dropdown.Toggle>
                                                                                            <Dropdown.Menu className="border-none">
                                                                                                <Dropdown.Item className="d-text" onClick={(e, i) => editFeedback(data)} ><span className='flex gap-2'><img src={ImgEdit} style={{ width: "20px" }} /> &nbsp;Edit</span></Dropdown.Item>
                                                                                                <Dropdown.Item className="d-text" onClick={(e, i) => deleteFeedback(data)} style={{ color: "red" }}><span className='flex gap-2'><img src={imgDel} style={{ width: "20px" }} /> &nbsp;Delete</span></Dropdown.Item>
                                                                                            </Dropdown.Menu>
                                                                                        </Dropdown> : null}
                                                                                </div>
                                                                                <div className={localStorage.getItem('junkStore') ? "cursor" : ""}>
                                                                                    {/* <Link to={{
                                                                                pathname: ,
                                                                                state: {
                                                                                    id: data.productId
                                                                                }
                                                                            }}> */}
                                                                                    <div className="flex gap-1 mb-2 cursor-pointer" onClick={() => history.push(`/by/${data.companyName}/${data.productName}/${data.productTypeString}`, { id: data.productId })}>
                                                                                        <img src={data?.productIcon || fedduplogo} alt="" style={{ width: "30px", height: "30px", objectFit: 'contain' }} />
                                                                                        <span className="text-orange-600 font-medium text-lg">{data.productName}</span>
                                                                                        <span className="text-orange-600 font-normal text-sm leading-7">{data.productTypeString}</span>
                                                                                    </div>
                                                                                    {/* </Link> */}
                                                                                    <div onClick={() => togglePopup(data)}>
                                                                                        <div className="posted mb-2">Posted by {data.userName == null ? "anonymous" : data.userName} &nbsp;&nbsp;{data.updatedAt.split("T")[0].split("-").reverse().join("-")} {moment(data.updatedAt).diff(moment(data.createdAt), 'minutes') > 1 ? <span>(Edited)</span> : ''}</div>
                                                                                        <div className="text-xl font-bold mb-2">{data.title}</div>
                                                                                        <div className="mb-2" dangerouslySetInnerHTML={{
                                                                                            __html: data.feedback
                                                                                        }} />
                                                                                        {
                                                                                            data?.tag?.length
                                                                                                ?
                                                                                                <div className='flex flex-wrap gap-x-3 gap-y-3 py-3'>
                                                                                                    {data?.tag?.map(tag => <Tag text={tag} />
                                                                                                    )}
                                                                                                </div>
                                                                                                : ''
                                                                                        }
                                                                                        <div className="my-2"><img src={data.feedbackImage} style={{ width: "150px" }} /></div>

                                                                                    </div>
                                                                                </div>
                                                                                <div className={localStorage.getItem('junkStore') ? "cursor flex py-4" : "flex py-4"}>
                                                                                    <div onClick={() => togglePopup(data)} className="flex items-center gap-x-2.5">
                                                                                        <img src={comment} style={{ width: "20px" }} className={localStorage.getItem('junkStore') ? "cursor" : ""} />
                                                                                        <span className="comment" >{data.commentCount == null ? 0 : data.commentCount} Comments</span>
                                                                                    </div>
                                                                                    <ShareBox shareCount={data.shareCount} path={`/${data.companyName}/${data.productName}/${data.productTypeString}`} type={"Feedback"} postId={data.id} callback={() => getAllFeedbacks(0)} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </div>
                                                            </Card>
                                                        </div>
                                                    )
                                                    )}
                                                </div>
                                            }
                                        </div> : (item == "Suggestion") ?
                                            <div className="mt-2">
                                                {suggestionData.length == 0 ? <Card className="mt-2 no-pages"><center className="no-details-text"><img src={downvoteb} className="no-pages-img" /><br />You follow no feddup pages</center></Card> :
                                                    <div className="feddback-company-userfeed suggestion-margin">
                                                        {suggestionData.map((data, i) => (

                                                            <div className="mt-2" key={i}>
                                                                <Card className={data.status == 4 ? "suggestion-border-accepted mt-4" : (data.status == 2 ? "suggestion-border-pending mt-4" : (data.status == 3 ? "suggestion-border-rejected mt-4" : "suggestion-border mt-4"))}>
                                                                    <div className="suggestion-img-container">
                                                                        <img src={data.status == 4 ? suggestionAccepted : (data.status == 2 ? suggestionInprogress : (data.status == 3 ? suggestionRejected : null))} alt="" style={{ width: "40px" }} />
                                                                    </div>
                                                                    <div className="row">
                                                                        <Col md={2}>
                                                                            <Card className="ml-2 mt-1 mb-2 vote-bg rounded-[10px] border-0 py-4" style={{ height: "96%" }}>
                                                                                <center className={localStorage.getItem('junkStore') ? "cursor" : ""}> {data.vote == 1 ? <img src={upvote} onClick={(e, i) => changeUpvoteImageSuggestion(data, 2)} className="w-16" /> : <img src={upvoteb} onClick={(e, i) => changeUpvoteImageSuggestion(data, 1)} className="w-14" />}</center><br />
                                                                                <center className="likes">{data.totallike == null ? 0 : data.totallike}</center><br />
                                                                                <center className={localStorage.getItem('junkStore') ? "cursor" : ""}> {data.vote == -1 ? <img src={downvote} onClick={(e, i) => changeDownvoteImageSuggestion(data, 2)} className="w-16" /> : <img src={downvoteb} onClick={(e, i) => changeDownvoteImageSuggestion(data, 0)} className="w-14" />}</center>
                                                                            </Card>
                                                                        </Col>
                                                                        <Col md={10}>
                                                                            <div className="mt-2 mb-2">
                                                                                <div className="card-padding">
                                                                                    <div >
                                                                                        {(data.userCreate) ?
                                                                                            <Dropdown align="end">
                                                                                                <Dropdown.Toggle variant="default" style={{ float: "right" }} className="abc feed-card-a -mt-2 h-7">
                                                                                                    <img src={dots} style={{ width: "3px" }} />
                                                                                                </Dropdown.Toggle>

                                                                                                <Dropdown.Menu>
                                                                                                    <Dropdown.Item className="d-text" onClick={(e) => editSuggestion(data)} ><span className='flex gap-2'><img src={ImgEdit} style={{ width: "20px" }} /> &nbsp;Edit</span></Dropdown.Item>
                                                                                                    <Dropdown.Item className="d-text" onClick={(e) => deleteSuggestion(data)} style={{ color: "red" }}><span className='flex gap-2'><img src={imgDel} style={{ width: "20px" }} /> &nbsp;Delete</span></Dropdown.Item>
                                                                                                </Dropdown.Menu>
                                                                                            </Dropdown> : null}
                                                                                    </div>
                                                                                    <div className={localStorage.getItem('junkStore') ? "cursor" : ""}>
                                                                                        <div className="flex gap-1 my-2 cursor-pointer" onClick={() => history.push(`/by/${data.companyName}/${data.productName}/${data.productTypeString}`, { id: data.productId })}>
                                                                                            <img src={data?.productIcon || fedduplogo} alt="" style={{ width: "30px", height: "30px", objectFit: 'contain' }} />
                                                                                            <span className="text-orange-600 font-medium text-lg">{data.productName}</span>
                                                                                            <span className="text-orange-600 font-normal text-sm leading-7">{data.productTypeString}</span>
                                                                                        </div>
                                                                                        <div onClick={() => togglePopupSuggestion(data)}>
                                                                                            <div className="posted mb-2">Posted by {data.userName == null ? "anonymous" : data.userName} &nbsp;&nbsp;{data.createdAt.split("T")[0].split("-").reverse().join("-")} {moment(data.updatedAt).diff(moment(data.createdAt), 'minutes') > 1 ? <span>(Edited)</span> : ''}</div>
                                                                                            <div className="text-xl font-bold mb-2">{data.title}</div>
                                                                                            <div className="mb-2" dangerouslySetInnerHTML={{
                                                                                                __html: data.suggestion
                                                                                            }} />
                                                                                            {
                                                                                                data?.tag?.length
                                                                                                    ?
                                                                                                    <div className='flex flex-wrap gap-x-3 gap-y-3 py-3'>
                                                                                                        {data?.tag?.map(tag => <Tag text={tag} />
                                                                                                        )}
                                                                                                    </div>
                                                                                                    : ''
                                                                                            }
                                                                                            <div className="my-2"><img src={data.suggestionImage} style={{ width: "150px" }} /></div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className={localStorage.getItem('junkStore') ? "cursor flex py-4" : "flex py-4"}>
                                                                                        <div onClick={() => togglePopupSuggestion(data)} className="flex items-center gap-x-2.5">
                                                                                            <img src={comment} style={{ width: "20px" }} className={localStorage.getItem('junkStore') ? "cursor" : ""} />
                                                                                            <span className="comment" >{data.commentCount == null ? 0 : data.commentCount} Comments</span>
                                                                                        </div>
                                                                                        <ShareBox shareCount={data.shareCount} path={`/${data.companyName}/${data.productName}/${data.productTypeString}`} type={"Suggestion"} postId={data.id} callback={() => getSuggestions(0)} />
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </div>
                                                                </Card>
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                            </div> :
                                            <div className="mt-2 ">
                                                {/* {announcementData.map((data, i) => (
                                            <div className="mt-2">
                                                <Card>
                                                    <div className="row">
                                                        <Col md={2}>
                                                            <Card className="ml-2 mt-2 mb-2 card-padding-sm">
                                                                <center> {data.vote == 1 ? <img src={upvote} onClick={(e, i) => changeUpvoteImageAnnouncement(data, 2)} style={{ width: "60px", marginTop: "-0.6rem" }} /> : <img src={upvoteb} onClick={(e, i) => changeUpvoteImageAnnouncement(data, 1)} style={{ width: "50px" }} />}</center>
                                                                <center className="mt-3 likes mb-2">{data.totallike == null ? 0 : data.totallike}</center>
                                                                <center> {data.vote == -1 ? <img src={downvote} onClick={(e, i) => changeDownvoteImageAnnouncement(data, 2)} className="mt-2" style={{ width: "60px", marginTop: "-0.6rem" }} /> : <img src={downvoteb} onClick={(e, i) => changeDownvoteImageAnnouncement(data, 0)} className="mt-2" style={{ width: "50px" }} />}</center>
                                                            </Card>
                                                        </Col>
                                                        <Col md={10}>
                                                            <div className="mt-2 mb-2">
                                                                <div className="card-padding">
                                                                    <h6 className="t-today">{data.heading}</h6>
                                                                    <div className="posted">Posted by {data.userName} &nbsp;&nbsp;{data.updatedAt.split("T")[0].split("-").reverse().join("-")}</div>
                                                                    <div className="title">{data.title}</div>
                                                                    <div className="mt-1 desc">{data.announcement}</div>
                                                                    <br />
                                                                    <img src={post} style={{ marginTop: "10px", width: "100%" }} /><br /><br />
                                                                    <div><img src={comment} style={{ width: "25px" }} /> <span className="comment" onClick={() => togglePopupAnnouncement(data)}>{data.commentCount == null ? 0 : data.commentCount} Comments</span><span className="ml-5"><img src={share} style={{ width: "25px" }} onClick={() => shareArticle(data, "announcement")} /> <span className="comment">{data.shareCount == null ? 0 : data.shareCount} Shares</span></span></div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </Card>
                                            </div>
                                        )
                                        )} */}
                                                {/* <div
                                        >

                                            <InfiniteScroll
                                                dataLength={ratingData.length}
                                                next={() => fetchMoreRatingData()}
                                                hasMore={true}
                                                loader={<h4>Loading...</h4>}
                                                endMessage={
                                                    <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                    </p>
                                                }
                                            > */}
                                                {announcementData.length == 0 ? <Card className="mt-2 no-pages"><center className="no-details-text"><img src={downvoteb} className="no-pages-img" /><br />You follow no feddup pages</center></Card> :
                                                    <div>
                                                        {announcementData.map((data, i) => (
                                                            <div className="mt-2 relative" key={i}>
                                                                {
                                                                    (data.announcementType?.includes("New Update") || data.tag?.includes('New Version') || data.tag?.includes('New Update')) ?
                                                                        <span className='absolute -top-7 -left-6 z-10 w-12 h-12'>
                                                                            <svg className='w-full h-full' viewBox="0 0 104 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M24.2985 68.5917C26.9285 79.8135 40.7161 86.1789 55.094 82.8093C69.4719 79.4396 78.9955 67.6109 76.3655 56.3891C73.7355 45.1674 59.9479 38.802 45.57 42.1716C31.1921 45.5413 21.6686 57.37 24.2985 68.5917Z" fill="#FFB858" />
                                                                                <path d="M18.4388 93.412L11.4248 65.4432L24.1425 47.9321L42.2882 57.9344L51.5948 67.6726L56.6981 54.3222L67.1475 51.475L74.3261 36.1709L102.91 72.2373L18.4388 93.412Z" fill="#E6570E" stroke="#FFB858" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M59.9062 37.5947C59.6545 34.8281 63.2112 34.3945 62.9594 31.6279C62.7076 28.8612 59.1509 29.2948 58.8991 26.5282C58.6473 23.7616 62.204 23.328 61.9522 20.5613C61.7004 17.7947 58.1437 18.2283 57.8919 15.4617C57.6401 12.695 61.1969 12.2614 60.9451 9.49481" stroke="#FFB858" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M47.1152 38.2676C46.3141 35.6125 49.763 34.6237 48.9619 31.9686C48.1607 29.3135 44.7117 30.3023 43.9105 27.6472C43.1093 24.9921 46.5583 24.0034 45.7571 21.3483C44.956 18.6932 41.507 19.6819 40.7058 17.0268C39.9046 14.3717 43.3536 13.383 42.5524 10.7279" stroke="#FFB858" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M33.5156 43.8721C32.1164 41.4489 35.3065 39.8628 33.9072 37.4397C32.508 35.0166 29.3179 36.6027 27.9186 34.1795C26.5193 31.7564 29.7095 30.1703 28.3102 27.7471C26.911 25.324 23.7208 26.9101 22.3216 24.487C20.9223 22.0638 24.1124 20.4777 22.7132 18.0546" stroke="#FFB858" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                        </span>
                                                                        : (data.announcementType?.includes("Bug Fix") || data.tag?.includes('Bug Fix')) ?
                                                                            <span className='absolute -top-5 -left-5 z-10 w-10 h-10'>
                                                                                <svg className='w-full h-full' viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <circle cx="25" cy="25" r="25" fill="#E6570E" />
                                                                                    <path d="M25.5 46.0686C27.9853 46.0686 30 44.0539 30 41.5686C30 39.0833 27.9853 37.0686 25.5 37.0686C23.0147 37.0686 21 39.0833 21 41.5686C21 44.0539 23.0147 46.0686 25.5 46.0686Z" fill="#FFB858" />
                                                                                    <path d="M23 32.8823C23 30.1941 27.2659 30.1941 27.2659 27.5059C27.2659 24.8176 23 24.8176 23 22.1294C23 19.4411 27.2659 19.4411 27.2659 16.7529C27.2659 14.0647 23 14.0647 23 11.3764C23 8.6882 27.2659 8.68819 27.2659 5.99996" stroke="#FFB858" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                            </span>
                                                                            :
                                                                            <span className='absolute -top-5 -left-5 z-10 w-14 h-14'>
                                                                                <svg className='w-full h-full' viewBox="0 0 85 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M51.8643 31.5141C54.4394 30.5157 55.5803 33.4584 58.1554 32.4601C60.7306 31.4617 59.5898 28.5191 62.165 27.5207C64.7402 26.5224 65.881 29.465 68.4562 28.4667C71.0314 27.4683 69.8906 24.5257 72.4658 23.5273C75.041 22.529 76.1818 25.4716 78.757 24.4733" stroke="#FFB858" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                                    <path d="M47.625 26.0025C49.9817 24.5622 51.6275 27.2552 53.9841 25.8149C56.3408 24.3746 54.695 21.6817 57.0517 20.2414C59.4083 18.8011 61.0541 21.4941 63.4108 20.0538C65.7675 18.6135 64.1217 15.9205 66.4783 14.4803C68.835 13.04 70.4808 15.7329 72.8375 14.2927" stroke="#FFB858" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                                    <path d="M43.895 21.0082C45.9089 19.118 48.0688 21.4192 50.0826 19.5291C52.0964 17.6389 49.9365 15.3377 51.9504 13.4475C53.9642 11.5573 56.1241 13.8585 58.1379 11.9683C60.1518 10.0782 57.9919 7.77696 60.0057 5.88678C62.0195 3.9966 64.1794 6.2978 66.1933 4.40762" stroke="#FFB858" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                                    <path d="M40.5824 17.1598C40.5824 17.1598 40.226 21.9491 44.7199 28.6757C49.2138 35.4022 54.2177 37.6934 54.2177 37.6934L47.1961 42.356L44.182 44.3613L41.2071 46.333L31.0583 53.0722L27 62.5L5.47461 40.473L40.5824 17.1598Z" fill="#E6570E" stroke="#E6570E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                            </span>
                                                                }
                                                                <Card className="rounded-[10px] mb-12 border-none">
                                                                    <div className="row">
                                                                        <Col md={2}>
                                                                            <Card className="ml-2 mt-1 mb-2 vote-bg rounded-[10px] py-4 border-none" style={{ height: "91%" }}>
                                                                                <center className={localStorage.getItem('junkStore') ? "cursor" : ""}> {data.vote == 1 ? <img src={upvote} onClick={(e, i) => changeUpvoteImageAnnouncement(data, 2)} className="w-16" /> : <img src={upvoteb} onClick={(e, i) => changeUpvoteImageAnnouncement(data, 1)} className="w-14" />}</center><br />
                                                                                <center className="likes">{data.totallike == null ? 0 : data.totallike}</center><br />
                                                                                <center className={localStorage.getItem('junkStore') ? "cursor" : ""}> {data.vote == -1 ? <img src={downvote} onClick={(e, i) => changeDownvoteImageAnnouncement(data, 2)} className="mt-2 w-16" /> : <img src={downvoteb} onClick={(e, i) => changeDownvoteImageAnnouncement(data, 0)} className="w-14" />}</center>
                                                                            </Card>
                                                                        </Col>
                                                                        <Col md={10}>
                                                                            <div className="ml-2 mt-2 mb-2">
                                                                                <div className="card-padding">
                                                                                    <div className={localStorage.getItem('junkStore') ? "cursor" : ""}>
                                                                                        <div className="flex gap-1 mb-2 cursor-pointer" onClick={() => history.push(`/by/${data.companyName}/${data.productName}/${data.productTypeString}`, { id: data.productId })}>
                                                                                            <img src={data?.productIcon || fedduplogo} alt="" style={{ width: "30px", height: "30px", objectFit: 'contain' }} />
                                                                                            <span className="text-orange-600 font-medium text-lg">{data.productName}</span>
                                                                                            <span className="text-orange-600 font-normal text-sm leading-7">{data.productTypeString}</span>
                                                                                        </div>
                                                                                        <div onClick={() => togglePopupAnnouncement(data)}>
                                                                                            <div className="posted mb-2">Posted by {data.userName == null ? "anonymous" : data.userName} &nbsp;&nbsp;{data.createdAt.split("T")[0].split("-").reverse().join("-")} {moment(data.updatedAt).diff(moment(data.createdAt), 'minutes') > 1 ? <span>(Edited)</span> : ''}</div>
                                                                                            <div className="text-xl font-bold mb-2">{data.title}</div>
                                                                                            <div className="mb-2" dangerouslySetInnerHTML={{
                                                                                                __html: data.announcement
                                                                                            }} />
                                                                                            {
                                                                                                data?.tag?.length
                                                                                                    ?
                                                                                                    <div className='flex flex-wrap gap-x-3 gap-y-3 py-3'>
                                                                                                        {data?.tag?.map(tag => <Tag text={tag} />
                                                                                                        )}
                                                                                                    </div>
                                                                                                    : ''
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <div className={localStorage.getItem('junkStore') ? "mt-2 cursor" : "mt-2"}><span onClick={() => toggleShareAnnouncementPopup(data)}><img src={share} style={{ width: "20px" }} /> <span className="comment">{data.shareCount == null ? 0 : data.shareCount} Shares</span></span></div> */}
                                                                                    <div className={localStorage.getItem('junkStore') ? "cursor flex py-4" : "flex py-4"}>
                                                                                        <ShareBox boxClass="!ml-0" shareCount={data.shareCount} path={`/${data.companyName}/${data.productName}/${data.productTypeString}`} type={"Announcement"} postId={data.id} callback={() => getAllAnnouncements(0)} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </div>
                                                                </Card>
                                                            </div>
                                                        )
                                                        )}
                                                    </div>}<br />
                                            </div>}
                                </Col>
                                <Col md={3} className={`mt-2 border-none ${visible === false ? "fixed-side ml-[60vw] max-w-[310px]" : ""}`}>
                                    <Card className="mt-2 border-none">
                                        <div className="card-padding">
                                            <h6 className="mt-2 t-tags">Top Products</h6>
                                            {trendingTopProduct.length == 0 ? <div className="no-details-text">No Trending Top Products</div> :
                                                <div>
                                                    {trendingTopProduct.map((data, i) => (
                                                        <Link key={i} to={{
                                                            // pathname: `/company/productpage/${data.productName}`,
                                                            // state: {
                                                            //     id: data.id
                                                            // }
                                                            pathname: `/by/${data.companyName}/${data.productName}/${data.productType}`,
                                                            state: {
                                                                id: data.productId,
                                                                productName: data.productName,
                                                                companyName: data.companyName,
                                                                productTypeString: data.productType
                                                            }
                                                        }} style={{ color: "black", textDecoration: "none" }}>
                                                            <div className="top-products-new">
                                                                <img src={data.productIcon} style={{ width: "30px" }} />
                                                                <div className="ml-2 text-topproducts">{data.productName}</div>
                                                            </div>
                                                        </Link>
                                                    ))}
                                                </div>}
                                        </div>
                                    </Card>
                                    <Card className="mt-2 border-none">
                                        <div className="card-padding">
                                            <h6 className="mt-2 t-tags">Top Web Apps</h6>
                                            {trendingTopWebApp.length == 0 ? <div className="no-details-text">No Trending Web Apps</div> :
                                                <div>
                                                    {trendingTopWebApp.slice(0, 4).map((data, i) => (
                                                        <Link key={i}
                                                            to={{
                                                                // pathname: `/company/productpage/${data.productName}`,
                                                                // state: {
                                                                //     id: data.id
                                                                // }
                                                                pathname: `/by/${data.companyName}/${data.productName}/${data.productType}`,
                                                                state: {
                                                                    id: data.productId,
                                                                    productName: data.productName,
                                                                    companyName: data.companyName,
                                                                    productTypeString: data.productType
                                                                }
                                                            }}
                                                            style={{ color: "black", textDecoration: "none" }}>
                                                            <div className="top-products-new">
                                                                <img src={data.productIcon} style={{ width: "30px" }} />
                                                                <div className="ml-2 text-topproducts">{data.productName}</div>
                                                            </div>
                                                        </Link>
                                                    ))}
                                                </div>}
                                        </div>
                                    </Card>
                                    <center className="mt-5 guidelines">
                                        <div className="mt-4 text-base">
                                            &copy; {new Date().getFullYear()} Feddup. All right reserved.
                                        </div>
                                    </center>
                                </Col>
                            </Row>


                        </div > <br />
                    </div >
            }
        </div >
    );
}
const options = {
    options: ['inline', 'link', 'image'],
    inline: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['bold', 'italic', 'underline'],
    },
    link: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        dropdownClassName: undefined,
        showOpenOptionOnHover: true,
        defaultTargetOption: '_self',
        options: ['link', 'unlink'],
        link: { className: undefined },
        unlink: { className: undefined },
        linkCallback: undefined
    },
    // image: {
    //     className: undefined,
    //     component: undefined,
    //     popupClassName: undefined,
    //     urlEnabled: true,
    //     uploadEnabled: true,
    //     alignmentEnabled: true,
    //     uploadCallback: undefined,
    //     previewImage: false,
    //     inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
    //     alt: { present: false, mandatory: false },
    //     defaultSize: {
    //         height: 'auto',
    //         width: 'auto',
    //     },
    image: {
        uploadCallback: uploadImageCallBack,
        alt: { present: true, mandatory: true }
    },
}
function uploadImageCallBack(file) {
    return new Promise(
        (resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', 'https://api.imgur.com/3/image');
            xhr.setRequestHeader('Authorization', 'Client-ID c166b3ccc22');
            const data = new FormData();
            data.append('image', file);
            xhr.send(data);
            xhr.addEventListener('load', () => {
                const response = JSON.parse(xhr.responseText);
                console.log(response)
                resolve(response);
            });
            xhr.addEventListener('error', () => {
                const error = JSON.parse(xhr.responseText);
                console.log(error)
                reject(error);
            });
        }
    );
}
// function LeftArrow() {
//     const { isFirstItemVisible, scrollPrev } =
//         React.useContext(VisibilityContext);

//     return (
//         <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
//             Left
//         </Arrow>
//     );
// }

// function RightArrow() {
//     const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

//     return (
//         <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()}>
//             Right
//         </Arrow>
//     );
// }


import React from 'react';
import '../CompanyFeed/CompanyFeed.css';
import { Row, Col, Modal, Form, FormControl, Card, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import upvoteb from '../images/upvote-b.svg';
import downvoteb from '../images/downvote-b.svg';
import upvote from '../images/upvote.svg';
import downvote from '../images/downvote.svg';
import comment from '../images/comments.svg';
import share from '../images/share.svg';
import lock from '../images/lock.png';
import profilepicture from '../images/profilepicture.png';
import deletei from "../images/delete-i.svg";
import axios from 'axios';
import { config } from '../Config/Config';
import { decrypt } from '../utils';

class ReportFeedbackByAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reportPost: true,
            report: ""
        }
    }
    componentDidMount() {
        console.log("props", this.props.data)
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    reportFeedbackById = (val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "feedbackId": this.props.data,
            "feedbackGivenBy": this.props.feedbackGivenBy,
            "reportDescription": this.state.report
        }
        console.log("payload", payload)
        var self = this;
        axios.post(config.userUrl + 'admin/addFeedbackReportByAdmin', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, payload);
                    self.props.getAllFeedbacks(self.props.selectedValue)
                    self.props.closePopup();

                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    render() {
        const { reportPost } = this.state
        return (
            <Modal
                size="sm"
                show={reportPost}
                onHide={this.props.closePopup}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="mb-2" >
                        <div className="suggestion-box-text">Why do you want to report post?</div>
                        <br />
                        <Form.Check
                            label="Unrelated"
                            name="report"
                            type="radio"
                            value={1}
                            onChange={this.onChange}
                        />
                        <Form.Check
                            label="Language"
                            name="report"
                            type="radio"
                            value={2}
                            onChange={this.onChange}
                        />
                        <Form.Check
                            label="Repeated"
                            name="report"
                            type="radio"
                            value={3}
                            onChange={this.onChange}
                        /><br />
                        <center>
                            <Button
                                variant="default" size="md"
                                type="submit"
                                onClick={this.reportFeedbackById}
                                className="reportyes-button"

                            >
                                Report
                            </Button><br />
                            <Button
                                variant="default" size="md"
                                type="submit"
                                className="mt-2 deleteno-button"
                                onClick={this.props.closePopup}
                            >
                                No
                            </Button>
                        </center>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default ReportFeedbackByAdmin;


import pinkDot from "../images/pink-dot.svg";
import yellowDot from "../images/yellow-dot.svg";
import redDot from "../images/red-dot.svg";
import greenDot from "../images/green-dot.svg";
import { createRef, useEffect, useState } from "react";
import Countries from './world-country.json'
import { useMutation } from "react-query";
import axios from "axios";
import { config } from "../Config/Config";
import toast from "react-hot-toast";

const eKey = 'feddup'
export function encrypt(message) {
    if (typeof message === 'object') {
        message = JSON.stringify(message);
    }
    let encryptedMessage = '';
    for (let i = 0; i < message.length; i++) {
        const char = message[i];
        const charCode = char.charCodeAt(0);
        const keyCharCode = eKey.charCodeAt(i % eKey.length); // Retrieve the character code of the key at the corresponding position
        const encryptedCharCode = (charCode + keyCharCode) % 256; // Perform simple shift using the key value
        const encryptedChar = String.fromCharCode(encryptedCharCode);
        encryptedMessage += encryptedChar;
    }
    return encryptedMessage;
}

export const decrypt = (encryptedMessage) => {
    let decryptedMessage = '';
    for (let i = 0; i < encryptedMessage.length; i++) {
        const char = encryptedMessage[i];
        const charCode = char.charCodeAt(0);
        const keyCharCode = eKey.charCodeAt(i % eKey.length); // Retrieve the character code of the key at the corresponding position
        const decryptedCharCode = (charCode - keyCharCode + 256) % 256; // Reverse the shift using the key value
        const decryptedChar = String.fromCharCode(decryptedCharCode);
        decryptedMessage += decryptedChar;
    }
    if (decryptedMessage.includes('{') && decryptedMessage.includes('}')) {
        decryptedMessage = JSON.parse(decryptedMessage);
    }
    return decryptedMessage;
}

export const openLink = (link) => {
    if (!link?.substring(0, 4)?.includes('http')) {
        link = 'https://' + link
    }
    return window.open(link, '_blank')
}

export const uploadImage = async (image) => {
    let data = new FormData()
    data.append("file", image)
    data.append("upload_preset", "feddup")
    data.append("cloud_name", "feddupnew")
    const resp = await fetch(`https://api.cloudinary.com/v1_1/feddupnew/image/upload`, {
        method: "post",
        body: data
    })
    let res = await resp.json();
    return res.secure_url
}
export const loaderProps = {
    size: 40,
    duration: 0.5,
    colors: ['#5e22f0']
}
export const suggestionsOptions = [
    { value: 1, label: <span className="flex gap-2 text-sm"><img src={pinkDot} alt="No action" className="w-3" />No action</span> },
    { value: 2, label: <span className="flex gap-3 text-sm"><img src={yellowDot} alt="In discussion" className="w-3" />In discussion</span> },
    { value: 3, label: <span className="flex gap-2 text-sm"><img src={redDot} alt="Reject" className="w-3" />Reject</span> },
    { value: 4, label: <span className="flex gap-2 text-sm"><img src={greenDot} alt="NAccept" className="w-3" />Accept</span> }
];
export const scaleTypeOptions = [
    { value: "Agree-Disagree", label: "Agree-Disagree" },
    { value: "Happy-Unhappy", label: "Happy-Unhappy" },
    { value: "Likely-Unlikely", label: "Likely-Unlikely" },
    { value: "Often-Rarely", label: "Often-Rarely" },
    { value: "Satisfied-Unsatisfied", label: "Satisfied-Unsatisfied" },
    { value: "Yes-No", label: "Yes/Uncertain/No" },
];
export const wordLimitOptions = [
    { value: "Max 50 words", label: "Max 50 words" },
    { value: "50-100 words", label: "50 - 100 words" },
    { value: "Max 100 words", label: "Max 100 words" },
    { value: "Max 150 words", label: "Max 150 words" }
];

export const ratingDescAgreeDisagree = () => {
    return ["Highly Disagree", "Disagree", "Neutral", "Agree", "Highly Agree"]
}
export const ratingDescLikelyUnlikely = () => {
    return ["Highly Unlikely", "Unlikely", "Neutral", "Likely", "Highly Likely"]
}
export const ratingDescHappyUnhappy = () => {
    return ["Very Unhappy", "Unhappy", "Neutral", "Happy", "Very Happy"]
}
export const ratingDescOftenRarely = () => {
    return ["Very rarely", "Rarely", "Neutral", "Often", "Very Often"]
}
export const ratingDescSatisfiedUndsatisfied = () => {
    return ["Very unsatisfied", "Unsatisfied", "Neutral", "Satisfied", "Very Satisfied"]
}
// export const geoUrl = 'https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json';
export const geoUrl = Countries;


export default function useDebounce(value, delay = 300) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value)
        }, delay);
        return () => {
            clearTimeout(handler);
        }
    }, [value, delay]);

    return debouncedValue;
}

export const useClickOutside = (handler, ref = null) => {
    const domRef = ref || createRef(null);

    useEffect(() => {
        const localHandler = (e) => {
            if (!domRef.current) return;
            if (!domRef.current.contains(e.target)) handler();
        };
        document.addEventListener("mousedown", localHandler);
        return () => document.removeEventListener("mousedown", localHandler);
    }, [domRef, handler]);

    return domRef;
};

export const getInvoice = (data) => {
    let headers = {
        "Content-Type": 'application/json',
        "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
        "applicationType": "web"
    }
    return axios.get(config.userUrl + 'order/invoice', { headers: headers })
}

const createOrder = (data) => {
    if (data?.planId) {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        return axios.post(config.userUrl + 'order/create', data, { headers: headers })
    }
    else {
        return console.log("Plan not found")
    }
}

export const useCreateOrder = (callback) => {
    return useMutation(createOrder, {
        onSuccess: (res) => {
            let response = res?.data
            if (response.success === true) {
                if (typeof callback == 'function') {
                    callback(response.data)
                }
            }
        },
        onError: (e) => {
            console.log(e)
            console.error("Oops! Unable to create order")
            toast("Oops! Unable to create order", { id: 'err' })
        }
    })
}

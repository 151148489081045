import React, { Component } from "react";
import { Button, Form, Container, Card, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import './LandingPage.css';
import Footer from "./Footer";

export default function ContentPolicy() {

    return (
        <div>
            <Header />
            <div style={{ marginTop: "5rem" }}>
                <center><span className="contentpolicy">Content Policy</span></center><br />
                <div className="contentpolicy-text">
                    Tincidunt quisque eu porta tristique turpis et lobortis vel.Sit tempus, bibendum ultrices facilisis.Risus nulla elementum morbi nullam sed duis amet egestas morbi.Cras dictumst nisl nam in id.Morbi sit elementum viverra vel amet.Vulputate arcu malesuada aliquet nullam ipsum, pretium.Pretium, turpis eget ut hendrerit pellentesque nunc.Bibendum orci arcu in mollis accumsan aenean tristique diam risus.Gravida non id dui id aliquet lectus dignissim risus.Mus risus ornare elit netus commodo lacus bibendum at.Gravida adipiscing velit mi auctor elementum lobortis lacus tortor arcu.Semper placerat at at porttitor id dignissim diam.Pretium ut viverra cras nunc vestibulum, facilisis tempor non tortor.Cras ultricies sem leo vulputate eget.Cursus pellentesque enim vel ac vel eleifend velit, pellentesque.<br />
                    Imperdiet dui amet pulvinar bibendum pulvinar ut sed viverra.Sodales ultricies senectus nulla faucibus aliquam.Pulvinar semper ut accumsan ut.Lorem scelerisque vitae vivamus ut sapien urna, placerat est.Est purus facilisi consequat tortor arcu ante fermentum, pharetra, velit.Sem eu mauris tempus vestibulum faucibus neque, id.Tempor pharetra est, lectus molestie non varius faucibus sagittis, ullamcorper.Quam vel, vitae a eu velit in ut tincidunt viverra.Leo cursus ac sed vitae convallis ac sem sit urna.In dictum eget proin fusce sit aliquam vestibulum.Vulputate ut elementum donec convallis tincidunt semper aliquet malesuada ultricies.Erat lectus faucibus leo duis elementum.Convallis mi sit maecenas ornare blandit dui tortor.Amet dolor vitae id ultricies in turpis eget feugiat venenatis.<br />
                    Tortor orci felis est molestie turpis.Placerat gravida ac ut bibendum sed scelerisque convallis nisi.Diam tellus vel dictum ac.Id eget phasellus egestas enim sit sit.Dignissim sapien aenean vivamus risus tortor.Nunc amet ultrices enim sagittis nunc ultricies massa.Turpis commodo quam dictumst aliquet dolor, faucibus.<br />
                    Quam vitae morbi odio sed.Platea aliquam quam vestibulum facilisi urna, tristique tincidunt scelerisque in.Pellentesque pretium nunc donec neque, nisl, ac morbi.Tellus mauris, aliquet nisl mattis ut.Quam mauris nisl, purus, blandit non.Quisque vel donec amet fames lorem.Pellentesque quam ut congue nunc condimentum faucibus sed est tempus.In nullam consectetur leo viverra nisi quam non vitae.Ultrices etiam faucibus tincidunt tristique blandit volutpat iaculis.Habitasse elit magna semper convallis.
                    Imperdiet dui amet pulvinar bibendum pulvinar ut sed viverra.Sodales ultricies senectus nulla faucibus aliquam.Pulvinar semper ut accumsan ut.Lorem scelerisque vitae vivamus ut sapien urna, placerat est.Est purus facilisi consequat tortor arcu ante fermentum, pharetra, velit.Sem eu mauris tempus vestibulum faucibus neque, id.Tempor pharetra est, lectus molestie non varius faucibus sagittis, ullamcorper.Quam vel, vitae a eu velit in ut tincidunt viverra.Leo cursus ac sed vitae convallis ac sem sit urna.In dictum eget proin fusce sit aliquam vestibulum.Vulputate ut elementum donec convallis tincidunt semper aliquet malesuada ultricies.Erat lectus faucibus leo duis elementum.Convallis mi sit maecenas ornare blandit dui tortor.Amet dolor vitae id ultricies in turpis eget feugiat venenatis.
                </div>
            </div><br /><br />
            <Footer />
        </div>
    );
}


import React, { Component } from "react";
import { Button, Form, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import fedduplogo from "../images/fedduplogo.png";
import loginimg from "../images/login-img.png";
import '../Login/Login.css';
import axios from 'axios';
import { config } from '../Config/Config';

export default class AddModerators extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subAdminEmail: "",
            moderatorEmail1: "",
            moderatorEmail2: "",
            moderatorEmail3: "",
            moderatorEmail4: "",
            pricingPage: false
        }
        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    onChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
        return;
    }
    handleSubmit(e) {
        e.preventDefault();
        this.addDetails();
    }
    addDetails = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var self = this;
        var payload = {
            "subAdmin": this.state.subAdminEmail,
            "moderator": [this.state.moderatorEmail1, this.state.moderatorEmail2, this.state.moderatorEmail3, this.state.moderatorEmail4]
        }
        axios.post(config.userUrl + 'user/sendEmailNewRegister', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    console.log("Email sent successfully!!")
                    console.log("payload", payload)
                    self.setState({ pricingPage: true })

                } else {
                    console.log("error")

                }
            })
            .catch(function (error) {
                console.log("payload", payload)
                console.log(error);
            });
    }
    render() {
        if (this.state.pricingPage) {
            return <Redirect to="/pricing" />
        }
        return (
            <div>
                <span> <a href="/"><img src={fedduplogo} className="logo-login" /></a></span>
                <Row style={{ width: "100%" }}>
                    <Col sm={7} md={7}>
                        <Image src={loginimg} className="img-login" />
                    </Col>
                    <Col sm={5} md={5}>
                        <div className="login">
                            {/* <h3 className="forgot-top font_family">Hi there,<br /> what's on your mind?</h3> */}
                            <Form onSubmit={this.handleSubmit} className="font_family">
                                <label>Add Sub-Admin</label>
                                <div className="form-group">
                                    <input type="email" className="form-control border" aria-describedby="text"
                                        value={this.state.subAdminEmail}
                                        name="subAdminEmail"
                                        placeholder="Email"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <label>Add Moderator</label>
                                <div className="form-group">
                                    <input type="email" className="form-control border" aria-describedby="text"
                                        value={this.state.moderatorEmail1}
                                        name="moderatorEmail1"
                                        placeholder="Email1"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <input type="email" className="form-control border" aria-describedby="text"
                                        value={this.state.moderatorEmail2}
                                        name="moderatorEmail2"
                                        placeholder="Email2"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <input type="email" className="form-control border" aria-describedby="text"
                                        value={this.state.moderatorEmail3}
                                        name="moderatorEmail3"
                                        placeholder="Email3"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <input type="email" className="form-control border" aria-describedby="text"
                                        value={this.state.moderatorEmail4}
                                        name="moderatorEmail4"
                                        placeholder="Email4"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <Button
                                    variant="default" size="md" block
                                    type="submit"
                                    className="login_button"
                                >
                                    Save
                                </Button><br />
                                {/* <center>
                                    <span ><img src={google} style={{ width: "7%" }} className="mr-2" /><img src={facebook} style={{ width: "7%" }} className="ml-4" /></span><br /><br />
                                    <p>Don't have an account ?<Link to="/signup"><span style={{ color: "#E6570E" }}> Register here</span></Link></p>
                                </center> */}
                            </Form>
                        </div>
                    </Col>
                </Row>
                <center className="mt-4 copyright">Copyright {new Date().getFullYear()} Feddup. All right reserved</center>
            </div>
        );
    }
}

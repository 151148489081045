
import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col, Image, Container } from "react-bootstrap";
import { Redirect } from 'react-router-dom';
import fedduplogo from "../images/fedduplogo.png";
import thanks from "../images/thanksforregistering.png";
import '../Login/Login.css';
import axios from 'axios';
import { config } from '../Config/Config';
import free from "../images/free-tag.svg";
import premium from "../images/premium.svg";
import usePayment from "../utils/usePayment";
import toast from "react-hot-toast";
import PaymentSuccess from "./PaymentSuccess";
import { decrypt } from "../utils";

const Pricing = () => {
    const [token, setToken] = useState(decrypt(localStorage.getItem('junkStore')));
    const [plan, setPlan] = useState("Basic");
    const [pDuration, setPDuration] = useState("Yearly");
    const [redirect2, setRedirect2] = useState(false);
    const [pricing, setPricing] = useState([]);
    const { createOrder, success, setSuccess } = usePayment();

    useEffect(() => {
        getAllPlans();
    }, []);

    useEffect(() => {
        if (success) {
            setTimeout(() => {
                closeSuccess()
            }, 3000)
        }
    }, [success])

    const closeSuccess = () => {
        setSuccess(false);
        window.location.replace('/admindashboard');
    }


    const onChange = (e) => {
        const { name } = e.target;
        if (name === "Company") {
            setPlan("Company");
        } else {
            setPlan("Basic");
        }
    };

    const goBack = () => {
        setRedirect2(true);
    };

    const getAllPlans = () => {
        const headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        };
        const payload = {
            planDuration: pDuration
        };
        axios.post(config.userUrl + 'user/getAllPlan', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setPricing(response.data.planDetails);
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    const postSubscription = () => {

        if (plan == "Basic") {
            const headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + token,
                "applicationType": "web"
            };
            const payload = {
                "planName": plan,
                "planDuration": pDuration,
                "currency": 'INR',
                "planPrice": 0,
                "planDescription": 'Free Plan',
                "expiryDate": new Date()
            };

            axios.post(config.userUrl + 'user/addSubscription', payload, { headers: headers })
                .then(function (response) {
                    console.log(response);
                    if (response.data.status === true) {
                        window.location.replace('/admindashboard');
                    } else {
                        console.log("error");
                    }
                })
                .catch(function (error) {
                    console.log("error", error, payload);
                });
        }
        else {
            let plan = pricing.find(el => el.planName == "Company");
            if (plan) {
                createOrder({ planId: plan.id })
            }
            else {
                toast.error("Plan not found", { id: 'err' })
            }
        }
    };

    if (redirect2) {
        return <Redirect to={{ pathname: "/buildprofile/addProduct" }} />;
    }

    return (
        <div>
            {success ? <PaymentSuccess closeSuccess={closeSuccess} /> : <></>}
            <Row className="w-full">
                <Col md={4}>  <img src={fedduplogo} className="logo-login" style={{ width: "15%" }} alt="feddup-logo" /></Col>
                <Col md={4} className="ml-16">
                    <div className="text-4xl text-orange-600 font-bold mt-4 min-w-max">Let's get you all set up!</div>
                    <div className="font-normal text-3xl text-orange-400 ml-4">3 quick and easy steps</div>
                </Col>
            </Row>

            <Container style={{ marginTop: "2rem" }}>
                <Row>
                    <Col sm={4} md={4} className="mt-16">
                        <center>    <Image src={thanks} style={{ width: "70%" }} /></center>
                    </Col>
                    <Col sm={8} md={8}>
                        <div className="max-w-3xl">
                            <div className="container mb-4">
                                <ul className="progressbar">
                                    <li className="active" style={{ color: "black" }}>Company Info</li>
                                    <li className="active" style={{ color: "black" }}>First Feddup Page</li>
                                    <li className="current" style={{ color: "black" }}>Choose Plan</li>
                                </ul>
                            </div>
                            <Row className="ml-8">
                                <Form className="font_family">
                                    <div className="flex gap-4">
                                        <div className={`flex flex-col  bg-gray-50 max-w-xs p-4 rounded-[10px] ${plan === "Basic" ? "border-2 border-orange-600" : ""}`}>
                                            <div className="flex items-center gap-4 mb-4 border-0 px-8">
                                                <img src={free} alt="" />
                                                <p className="font-semibold text-2xl underline text-orange-600 "><span className="text-black">Free</span></p>
                                            </div>
                                            <ul className="list-disc px-4 mb-4">
                                                <li>3 Feddup pages</li>
                                                <li>No Moderators can be added to feddup pages</li>
                                                <li>Standard Feedback forms</li>
                                            </ul>
                                            <div className='relative mx-auto mt-auto'>
                                                <div className='btn-1 ' style={{ width: "100px", height: "30px" }}></div>
                                                <Button
                                                    name="Basic"
                                                    variant="default" size="md"
                                                    onClick={onChange}
                                                    className="login_button btn-2"
                                                    style={{ fontSize: "16px", width: "100px", height: "30px", lineHeight: "20px" }}
                                                >
                                                    {plan === "Basic" ? "Selected" : "Select"}
                                                </Button>
                                            </div>
                                        </div>

                                        <div className={`flex flex-col  bg-gray-50 max-w-xs p-4 rounded-[10px] ${plan !== "Basic" ? "border-2 border-orange-600" : ""}`}>
                                            <div className="flex items-center gap-4 mb-4 border-0 ">
                                                <img src={premium} alt="" />
                                                <div className="flex flex-col">
                                                    <p className="font-semibold text-2xl underline text-orange-600 "><span className="text-black">Premium</span></p>
                                                    <span className="font-medium text-2xl">₹199/month</span>
                                                </div>
                                            </div>
                                            <ul className="list-disc px-4 mb-4">
                                                <li>Unlimited feddup pages</li>
                                                <li>Add Moderators to pages</li>
                                                <li>Create custom feedback forms</li>
                                                <li>In-depth analysis of feddup pages</li>
                                                <li>and much more</li>
                                            </ul>
                                            <div className='relative mx-auto mt-auto'>
                                                <div className='btn-1 ' style={{ width: "100px", height: "30px" }}></div>
                                                <Button
                                                    name="Company"
                                                    variant="default" size="md"
                                                    onClick={onChange}
                                                    className="login_button btn-2"
                                                    style={{ fontSize: "16px", width: "100px", height: "30px", lineHeight: "20px" }}
                                                >
                                                    {plan === "Company" ? "Selected" : "Select"}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-6 font-normal text-lg">Learn more about pricing...<span style={{ color: "#E6570E", cursor: "pointer" }}> Click Here</span></div>
                                    <div className="flex justify-between mt-4">
                                        <Button
                                            variant="default" size="md"
                                            style={{ float: "right", width: "100px", height: "30px", borderRadius: "10px", backgroundColor: "#FFB858", color: "black", fontSize: "16px", lineHeight: "20px" }}
                                            className="login_button border-0"
                                            onClick={goBack}
                                        >
                                            Back
                                        </Button>
                                        <div className='relative'>
                                            <div className='btn-1 ' style={{ width: "100px", height: "30px" }}></div>
                                            <Button
                                                variant="default" size="md"
                                                onClick={postSubscription}
                                                className="login_button btn-2"
                                                style={{ fontSize: "16px", width: "100px", height: "30px", lineHeight: "20px" }}
                                            >
                                                Finish
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Pricing;

import React, { Component } from "react";
import { Button, Form, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import fedduplogo from "../images/fedduplogo.png";
import forgotImg from '../images/forgot-screen-img.png';
import './Login.css';

export default class EmailNotVerified extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            registerFlow: false,
            redirect: false
        }
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    render() {
        const { redirect } = this.state
        if (redirect) {
            return <Redirect
                to={{
                    pathname: "/verify",
                    state: { email: this.state.email, registerFlow: this.state.registerFlow }
                }} />
        }
        return (
            <div>
                <a href="/"><img alt="" src={fedduplogo} className="logo-login" /></a>
                <center><Card style={{ width: "26%" }}> <center>
                    <p className="forgot-text ml-2 mr-2">Your Email is not yet Verified</p>
                    <p className="forgot-text-sm ml-2 mr-2">Email has been sent while signing up, to verify the account.</p><img alt="" src={forgotImg} className="forgotImg" /></center><br /><br />
                    <Form className="forgot-email">
                        <center>
                            <div style={{ width: "80%" }}>
                                {/* <Link to="/login" className="link">
                                    <Button
                                        variant="default" size="md" block
                                        type="submit"
                                        className="login_button"
                                    >
                                        Login Here
                                    </Button></Link> */}
                                <span style={{ color: "#E6570E", cursor: "pointer", fontFamily: 'Poppins' }} > Resend Email</span>
                                <br />
                            </div><br />
                        </center>
                    </Form><br />
                </Card>
                </center>
            </div>
        );
    }
}

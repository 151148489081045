import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import addFileIcon from "../images/add-file.svg";
import rating1 from '../images/rating1.png';
import rating2 from '../images/rating2.png';
import rating3 from '../images/rating3.png';
import rating4 from '../images/rating4.png';
import rating5 from '../images/rating5.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function PreviewAndPublish({ questions, setQuestions, togglePreview, createFeedbackForm, edit, updateFeedbackform, formStatus, productId }) {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  const handlePublish = () => {
    if (formStatus) {
      history.push(`./feddbackforms/${productId}`);
      return;
    }
    if (edit) updateFeedbackform();
    else createFeedbackForm();
  }
  return (

    <div className='bg-gray-200 min-h-screen px-20 mt-16 py-16'>
      <Row className='flex mb-20'>
        <Col md={2}>
        </Col>
        <Col md={7}>
          <div className='flex flex-col'>
            <p className='text-2xl text-black font-semibold mb-2'>New feature form</p>
            {questions && questions.map((ques, i) => {
              if (ques[i + 1]["type"] === "mcq") {
                return (
                  <div key={i + "mcq"} className='bg-white h-max px-4 py-8 rounded-xl mb-4'>
                    <p className='text-base text-black font-semibold'>1. {ques[i + 1]["question"]}</p>

                    <form className='flex flex-col gap-3 mt-2'>
                      {ques[i + 1].options.map((option, index) => {
                        return (
                          <label className='flex gap-3' key={index + "mcqoptions"}>
                            <input type="checkbox" name={ques[i + 1]["question"]} value={option} disabled />
                            {option}
                          </label>
                        )
                      })}
                    </form>
                    <div className={`${ques[i + 1]["required"] ? "mt-3 flex gap-8 items-center" : "hidden"}`}>
                      <div className='w-2 h-2 bg-orange-600 border'></div>
                      <p className='text-sm text-black font-medium'>Respondents must answer this question</p>
                    </div>
                  </div>
                )
              }
              else if (ques[i + 1]["type"] === "singlechoice") {
                return (
                  <div key={i + "singlechoice"} className='bg-white h-max px-4 py-8 rounded-xl mb-4'>
                    <p className='text-base text-black font-semibold'>{i + 1}. {ques[i + 1]["question"]}</p>

                    <form className='flex flex-col gap-3 mt-2'>
                      {ques[i + 1].options.map((option, index) => {
                        return (
                          <label className='flex gap-3' key={index + "singleoptions"}>
                            <input type="radio" name={ques[i + 1]["question"]} value={option} disabled />
                            {option}
                          </label>
                        )
                      })}
                    </form>
                    <div className={`${ques[i + 1]["required"] ? "mt-3 flex gap-8 items-center" : "hidden"}`}>
                      <div className='w-2 h-2 bg-orange-600 border'></div>
                      <p className='text-sm text-black font-medium'>Respondents must answer this question</p>
                    </div>
                  </div>
                )
              }
              else if (ques[i + 1]["type"] === "rating") {
                return (
                  <div key={i + "rating"} className='bg-white h-max px-4 py-8 rounded-xl mb-4'>
                    <p className='text-base text-black font-semibold'>{i + 1}. {ques[i + 1]["question"]}</p>
                    {ques[i + 1]["scaleType"] === "Yes-No" ?
                      <div className='flex w-full justify-center gap-8'>
                        <div className='flex items-center gap-2'>
                          <span className='w-4 h-4 rounded-full border'></span>
                          <p>Yes</p>
                        </div>
                        <div className='flex items-center gap-2'>
                          <span className='w-4 h-4 rounded-full border'></span>
                          <p>Uncertain</p>
                        </div>
                        <div className='flex items-center gap-2'>
                          <span className='w-4 h-4 rounded-full border'></span>
                          <p>No</p>
                        </div>

                      </div> :
                      <div className='flex my-4'>
                        <div className='flex w-32 gap-2 items-center'>
                          <img src={ques[i + 1]["scoringDirection"] === "Ascending" ? rating1 : rating5} alt='' className='w-10' />
                          <p>{ques[i + 1]["scoringDirection"] === "Ascending" ? ques[i + 1]["ratingDesc"][0] : ques[i + 1]["ratingDesc"][4]}</p>
                        </div>
                        <div className='flex w-32 gap-2 items-center'>
                          <img src={ques[i + 1]["scoringDirection"] === "Ascending" ? rating2 : rating4} alt='' className='w-10' />
                          <p>{ques[i + 1]["scoringDirection"] === "Ascending" ? ques[i + 1]["ratingDesc"][1] : ques[i + 1]["ratingDesc"][3]}</p>
                        </div>
                        <div className='flex w-32 gap-2 items-center'>
                          <img src={rating3} alt='' className='w-10' />
                          <p>{ques[i + 1]["ratingDesc"][2]}</p>
                        </div>
                        <div className='flex w-32 gap-2 items-center'>
                          <img src={ques[i + 1]["scoringDirection"] === "Ascending" ? rating4 : rating2} alt='' className='w-10' />
                          <p>{ques[i + 1]["scoringDirection"] === "Ascending" ? ques[i + 1]["ratingDesc"][3] : ques[i + 1]["ratingDesc"][1]}</p>
                        </div>
                        <div className='flex w-32 gap-2 items-center'>
                          <img src={ques[i + 1]["scoringDirection"] === "Ascending" ? rating5 : rating1} alt='' className='w-10' />
                          <p>{ques[i + 1]["scoringDirection"] === "Ascending" ? ques[i + 1]["ratingDesc"][4] : ques[i + 1]["ratingDesc"][0]}</p>
                        </div>
                      </div>
                    }
                    <div className={`${ques[i + 1]["required"] ? "mt-3 flex gap-8 items-center" : "hidden"}`}>
                      <div className='w-2 h-2 bg-orange-600 border'></div>
                      <p className='text-sm text-black font-medium'>Respondents must answer this question</p>
                    </div>
                  </div>
                )
              }
              else if (ques[i + 1]["type"] === "textfield") {
                return (
                  <div key={i + "textfield"} className='bg-white h-max px-4 py-8 rounded-xl mb-4'>
                    <p className='text-base text-black font-semibold'>{i + 1}. {ques[i + 1]["question"]}</p>
                    <div className='border rounded-lg w-full flex flex-col gap-2 my-2'>
                      <textarea
                        className="p-2 min-h-[7rem] resize-none w-full focus:outline-none bg-white"
                        placeholder="Enter additional feedback here"
                        disabled
                      />
                      <div className='flex justify-between p-2'>
                        <img src={addFileIcon} alt='' className='w-6' />
                        <p className='text-gray-400'>{ques[i + 1]["wordLimit"]}</p>
                      </div>
                    </div>
                    <div className={`${ques[i + 1]["required"] ? "mt-3 flex gap-8 items-center" : "hidden"}`}>
                      <div className='w-2 h-2 bg-orange-600 border'></div>
                      <p className='text-sm text-black font-medium'>Respondents must answer this question</p>
                    </div>
                  </div>
                )
              }
              else if (ques[i + 1]["type"] === "generic") {
                return (
                  <div key={i + "textfield"} className='bg-white h-max px-4 py-8 rounded-xl mb-4'>
                    <p className="text-2xl">Generic Question</p>
                    <p className='my-3 text-black text-lg '>Rate your overall Experience for this version</p>
                    <div className='flex w-full mt-2 my-4'>
                      <div className='flex w-48 gap-2 items-center'>
                        <img src={rating5} alt='' className='w-10' />
                        <p>Love it!</p>
                      </div>
                      <div className='flex w-48 gap-2 items-center'>
                        <img src={rating4} alt='' className='w-10' />
                        <p>Like it!</p>
                      </div>
                      <div className='flex w-48 gap-2 items-center'>
                        <img src={rating3} alt='' className='w-10' />
                        <p>It's OK!</p>
                      </div>
                      <div className='flex w-48 gap-2 items-center'>
                        <img src={rating2} alt='' className='w-10' />
                        <p>Hate it!</p>
                      </div>
                      <div className='flex w-48 gap-2 items-center'>
                        <img src={rating1} alt='' className='w-10' />
                        <p>Fedup!</p>
                      </div>
                    </div>
                    <textarea
                      className="border border-gray-400 rounded p-2 min-h-[12rem] mb-2 resize-none w-full border-gray focus:outline-none"
                      placeholder="Additional Thoughts"
                      disabled
                    />
                    <div className={`${ques[i + 1]["required"] ? "mt-3 flex gap-8 items-center" : "hidden"}`}>
                      <div className='w-2 h-2 bg-orange-600 border'></div>
                      <p className='text-sm text-black font-medium'>Respondents must answer this question</p>
                    </div>
                  </div>
                )
              }
            })}
          </div>
        </Col>
        <Col md={2} >
          <div className='flex flex-col justify-center items-center mt-8 text-white'>
            <div className='bg-orange-400 w-28 h-auto p-2 mb-4 flex justify-center items-center rounded-lg cursor-pointer' onClick={() => { togglePreview(); setQuestions(questions.slice(0, questions.length - 1)) }}>Edit Form</div>
            <div className='bg-green-700 min-w-[7rem] h-auto p-2 flex justify-center items-center rounded-lg cursor-pointer' onClick={handlePublish}>{formStatus ? "View All Forms" : "Publish"}</div>
          </div>
        </Col>
      </Row>

    </div>
  )
}

export default PreviewAndPublish
import React, { Component } from "react";
import { Button, Form, Card } from "react-bootstrap";
import fedduplogo from "../images/fedduplogo.png";
import forgotImg from '../images/forgot-screen-img.png';
import './Login.css';
import axios from 'axios';
import { config } from '../Config/Config';
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

export default class ThanksForRegistering extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: this.props.location.state.email,
            registerFlow: this.props.location.state.registerFlow,
            name: this.props.location.state.firstName,
            redirect: false,
            verify: false
        }
    }
    componentDidMount() {
    }
    sendOtp = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        var payload = {
            "email": this.state.email,
            "registerFlow": this.state.registerFlow
        }
        var self = this;
        axios.post(config.apiUrl + 'auth/sendOtp', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("Otp sent successfully!!")
                    self.setState({ verify: true })
                } else {
                    document.getElementById('status').innerText = msg
                }
            })
            .catch(function (error) {
                alert("Error")
                console.log(error);
            });
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    verify = () => {
        this.setState({ redirect: true })
        this.sendOtp();
    }
    render() {
        const { redirect, verify } = this.state
        if (redirect) {
            return <Redirect
                to={{
                    pathname: "/verifyemail",
                    state: { email: this.state.email, registerFlow: this.state.registerFlow, name: this.state.name }
                }} />
        }
        if (verify) {
            return <Redirect
                to={{
                    pathname: "/emailsent",
                    state: { email: this.state.email, registerFlow: this.state.registerFlow, name: this.state.name }
                }} />
        }
        return (
            <div>
               <a href="/"><img alt="" src={fedduplogo} className="logo-login mt-1" /></a>
                <div className="h-[90dvh] grid place-items-center pb-20">
                    <Card className="cards-width !w-full !max-w-lg"> <center>
                        <p className="forgot-text">Thanks For Registering</p>
                        <p className="forgot-text-sm">We’ve sent an email to verify the account.</p><img alt="" src={forgotImg} className="forgotImg" /></center><br /><br />
                        <Form className="forgot-email">
                            <center>
                                <div className='btn-outer mx-auto'>
                                    <div className='btn-1 login-btn-1' style={{ marginLeft: "20px" }}></div>
                                    <Button
                                        variant="default" size="md"
                                        type="submit"
                                        className="login_button btn-2 login-btn-2"
                                        onClick={this.verify}
                                    >
                                        Verify
                                    </Button>
                                </div><br />
                                <span className="login-here" onClick={this.sendOtp}>Resend email</span>

                            </center>
                        </Form><br />
                    </Card>
                </div>
            </div>
        );
    }
}

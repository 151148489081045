import React, { useEffect, useState } from 'react'
import NavBar from '../NavBar/NavBar'
import axios from 'axios';
import { config } from '../Config/Config';
import imageIcon from "../images/image-icon.svg"
import locationPin from "../images/location-pin.svg"
import premium from "../images/premium.svg"
import { Link, useHistory } from 'react-router-dom';
import { Button, Form, FormControl } from 'react-bootstrap';
import feddupRoundLogo from "../images/feddup-logo-round.svg"
import rating4 from "../images/fill-rating4.png";
import rating5 from "../images/fill-rating5.png";
import { CircleFadeLoader } from 'react-loaders-kit';
import ratingImg from '../images/showrating.PNG';
import { useQueries, useQuery } from 'react-query';
import noReviews from "../images/no-reviews.svg";
import { ratingDescription } from '../utils/ratingDescription';
import RatingImages from '../utils/DynamicRating/DynamicRatingImages';
import UpgradePopUp from '../Terms/UpgradePopUp';
import usePayment from '../utils/usePayment';
import moment from 'moment';
import PaymentSuccess from '../SignUp/PaymentSuccess';
import ExpiringSoon from '../Terms/ExpiringSoon';
import Expired from '../Terms/Expired';
import feddUpLogo from '../images/fedduplogo.png';
import { decrypt } from '../utils';



const getCompanyDetails = () => {
    let headers = {
        "Content-Type": 'application/json',
        "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
        "applicationType": "web"
    }
    return axios.get(config.userUrl + 'admin/getCompanyProfileDetailsByAdmin', { headers: headers })
}

const getProductIds = (companyId) => {
    let headers = {
        "Content-Type": 'application/json',
        "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
        "applicationType": "web"
    }
    let payload = {
        "companyId": companyId
    }
    return axios.post(config.userUrl + 'admin/getProductIdsFromCompanyId', payload, { headers: headers })
}

const getProductDetails = (products) => {
    let newProductDetails = products.forEach(async (product, i) => {
        let data = await getEachProductDetail(product.id)
        return data;
    })
}
const getEachProductDetail = async (productId) => {
    let headers;
    if (localStorage.getItem('junkStore')) {
        headers = {
            'Accept': 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            'Content-Type': 'application/json'
        }
    } else {
        headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }
    var payload = {
        "productId": productId,
    }
    return await axios.post(config.userUrl + "user/getProductDetailsByProductId", payload, { headers: headers })
};

function AdminLandingPage() {
    const [membershipType, setMembershipType] = useState("Premium")
    const [companyId, setCompanyId] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [companyWebsite, setCompanyWebsite] = useState("")
    const [companyIcon, setCompanyIcon] = useState("")
    const [companyHeadquarter, setCompanyHeadquarter] = useState("")
    const [searchInput, setSearchInput] = useState("")
    const [filteredResults, setFilteredResults] = useState([])
    const [productIds, setProductIds] = useState([])
    const [loading, setLoading] = useState(true)
    const [productDetails, setProductDetails] = useState([])
    const history = useHistory();
    const [userRole, setUserRole] = useState('');
    const [userProductIds, setUserProductIds] = useState([])
    const [isUpgrade, setIsUpgrade] = useState(false);
    const { createOrder, success, setSuccess } = usePayment()
    const [subscription, setSubscription] = useState()

    // localStorage.getItem('ssSeen')

    useEffect(() => {
        if (success) {
            setTimeout(() => {
                closeSuccess()
            }, 3000)
        }
    }, [success])

    const closeSuccess = () => {
        setSuccess(false);
        window.location.replace('/admindashboard');
    }

    const filter = (item) => {
        if (userRole !== 'admin') {
            if (userProductIds?.includes(item?.productDetails?.id)) {
                return true
            }
            else {
                return false
            }
        }
        else
            return true
    }

    const { data: companyData } = useQuery("company-details", getCompanyDetails, {
        refetchOnWindowFocus: false,
        onSuccess: (response) => {
            setCompanyName(response.data.companyDetail.companyName);
            setCompanyWebsite(response.data.companyDetail.companyWebsite);
            setCompanyIcon(response.data.companyDetail.companyIcon);
            setCompanyHeadquarter(response.data.companyDetail.companyheadquarter);
            setCompanyId(response.data.companyDetail.companyId);
            setMembershipType(response.data.companyDetail.membershipType);
            if (response?.data?.companyDetail?.email == response?.data?.currentUser.email) {
                if (response?.data?.subscription)
                    setSubscription(response?.data?.subscription)
                else{
                    localStorage.removeItem('ssSeen')
                }
            }
        }
    });

    useEffect(() => {
        getUserType();
        let role = localStorage.getItem('role')?.toLowerCase();
        let pIds = JSON.parse(localStorage.getItem('productIds'))
        setUserProductIds(pIds || [])
        if (role) {
            setUserRole(role)
            if (role == 'individual') {
                window.location.replace('/userfeed')
            }
        }
    }, [])

    const getUserType = () => {
        let token = localStorage.getItem('junkStore')
        if (token) {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                "applicationType": "web"
            }

            axios.get(config.userUrl + 'user/getUserType', { headers: headers })
                .then(function (response) {
                    if (response.data.user) {
                        setUserRole(response.data.user.role)
                        if (response.data.user.productIds) {
                            setProductIds(response.data.user.productIds)
                            localStorage.setItem("productIds", JSON.stringify(response.data.user.productIds))
                        }
                        if (response.data.user.role == 'individual') {
                            window.location.replace('/userfeed')
                        }
                    }
                })
                .catch(function (error) {
                    console.log("Error", error);
                });
        }

    }

    const company_id = companyData?.data?.companyDetail?.companyId
    const { data: product_ids } = useQuery("product-ids", () => getProductIds(company_id), {
        enabled: !!company_id,
        refetchOnWindowFocus: false,
        onSuccess: (response) => {
            setProductIds(response.data.productIds);
            setLoading(false)
        }
    })
    const product_id = product_ids?.data?.productIds;
    const { data: product_details } = useQuery(
        ['products', product_id],
        () => Promise.all(product_id.map(product => {
            let headers;
            if (localStorage.getItem('junkStore')) {
                headers = {
                    'Accept': 'application/json',
                    "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                    'Content-Type': 'application/json'
                }
            } else {
                headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
            var payload = {
                "productId": product.id,
            }
            return axios.post(config.userUrl + "user/getProductDetailsByProductId", payload, { headers: headers })
        })),
        {
            enabled: !!product_ids,
            refetchOnWindowFocus: false,
            onSuccess: (responses) => {
                setProductDetails(responses.map(response => response.data));
            }
        }
    );


    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
    }
    const loaderProps = {
        loading,
        size: 40,
        duration: 0.5,
        colors: ['#5e22f0']
    }
    const handleSubmit = () => {

    }

    return (
        <div className='bg-gray-100 min-h-screen'>
            {
                (subscription && membershipType == "Premium" && localStorage.getItem('ssSeen')) ?
                    (moment(subscription?.expiryDate).diff(moment(), 'days') < 0)
                        ?
                        <Expired subscription={subscription} />
                        :
                        (moment(subscription?.expiryDate).diff(moment(), 'days') < 8)
                            ?
                            <ExpiringSoon subscription={subscription} />
                            :
                            <></>
                    :
                    <></>
            }

            {success ? <PaymentSuccess closeSuccess={closeSuccess} /> : <></>}
            <NavBar />
            <div className='flex flex-col  px-16 mt-16 pb-20' id='background-admin'>
                {
                    (subscription && membershipType == "Premium") ?
                        moment(subscription?.expiryDate).diff(moment(), 'days') < 8 ? <div className={` ${moment(subscription?.expiryDate).diff(moment(), 'days') < 0 ? 'bg-[#E6570E]' : 'bg-[#FF9900]'} w-full flex items-center justify-between rounded-lg py-2 px-6 mt-2`}>
                            {moment(subscription?.expiryDate).diff(moment(), 'days') < 0 ? <div className='flex items-center gap-4 text-white'>
                                <p>Premium Subscription has ended</p>
                                <svg width="10" height="10" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="7.5" cy="8" r="7.5" fill="white" />
                                </svg>
                                <p>login and pages will be disabled in: {moment(subscription?.expiryDate).add(14, 'days').diff(moment(), 'days')} day(s)</p>
                            </div> :
                                <div className='flex items-center gap-4 text-white'>
                                    <p>Premium Subscription ending soon</p>
                                    <svg width="10" height="10" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="7.5" cy="8" r="7.5" fill="white" />
                                    </svg>
                                    <p>{moment(subscription?.expiryDate).diff(moment(), 'days')} day(s) left</p>
                                    <svg width="10" height="10" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="7.5" cy="8" r="7.5" fill="white" />
                                    </svg>
                                    <p>If not renewed you will loose access after 1 week of ending</p>
                                </div>
                            }
                            <button onClick={() => createOrder({ planId: subscription?.metadata?.planId })} className='bg-[#649E36] py-1 px-4 rounded-lg text-white'>Renew Now</button>
                        </div> : '' : ''}
                {userRole != 'moderator' && <div className='flex gap-12 py-4 px-4 mt-8 rounded-2xl min-h-[25vh]' style={{ background: companyData?.data?.companyDetail?.membershipType == "Premium" ? 'linear-gradient(135deg, rgba(255, 244, 225, 1), rgba(251, 255, 226, 1))' : 'linear-gradient(135deg, rgba(255, 229, 216, 1), rgba(255, 244, 225, 1) )' }}>
                    <div className='max-w-3xl flex items-center pl-4'>
                        {companyIcon ?
                            <img src={companyIcon} alt="company-logo" className='max-w-[10rem]' />
                            : <img src={imageIcon} alt="company-logo" className='w-20' />
                        }

                    </div>
                    <div className='max-w-6xl flex flex-col my-auto gap-1'>
                        <p className='font-bold text-2xl'>{companyName}</p>
                        <div className='flex'><img src={locationPin} alt='' /> <span className='font-semibold text-xl'>{`${companyHeadquarter ? companyHeadquarter : "Headquarter"}`}</span></div >
                        <a href={companyWebsite} target="_blank" className={`font-medium text-lg ${companyWebsite ? "" : "hidden"}`} style={{ color: 'inherit', textDecoration: 'inherit' }}>{companyWebsite ? companyWebsite : "Company Website"}</a>
                        {userRole == 'admin' ? <Link to={`/editdetails/${companyName}/${companyId}`} className='bg-orange-400 rounded text-sm px-3 text-white max-w-max mt-2 cursor-pointer'>Edit Company Profile</Link> : <></>}
                    </div>
                    <div className='flex float-right max-w-3xl ml-auto my-auto mr-8'>
                        {membershipType === "Free" ?
                            <div className='flex flex-col items-center'>
                                <img src={premium} alt="" />
                                <p className='font-semibold text-lg'>Unlock more from FeddUp</p>
                                <div className='btn-outer mt-4'>
                                    <div className='btn-1' style={{ width: "180px" }}>
                                    </div>
                                    <Button
                                        variant="default" size="md"
                                        type="submit"
                                        className="btn-2 "
                                        style={{ width: "180px" }}
                                        onClick={() => setIsUpgrade(true)}
                                    >
                                        Upgrade to Pro
                                    </Button></div>
                            </div>
                            : <div>
                                <img src={premium} alt="" />
                            </div>}
                    </div>

                </div>}

                <p className='text-2xl font-semibold my-4 underline text-orange-400 underline-offset-8'><span className='text-black'>Your Feddup Pages</span></p>
                <div className={`${membershipType === "Free" ? "hidden" : ""}`}>
                    <Form onSubmit={handleSubmit} className=''>
                        <FormControl
                            type="text"
                            placeholder="Search"
                            value={searchInput}
                            onChange={(e) => searchItems(e.target.value)}
                            id="search"
                            className="abc form-control ml-0 bg-white rounded-full"
                            style={{ width: "380px", height: "45px", borderRadius: "25px", paddingLeft: "3rem" }}
                            aria-label="Search keyword"
                        />
                    </Form>
                </div>
                {loading ? <div className='mx-auto flex flex-col'><CircleFadeLoader {...loaderProps} /><span className='text-2xl font-semibold'>Loading...</span></div> : null}

                <div>
                    <div className='btn-outer ml-auto mr-8 mb-4'>
                        {userRole == 'admin' && <><div className='btn-1' style={{ width: "180px" }}>
                        </div>
                            <Button
                                variant="default" size="md"
                                type="submit"
                                disabled={productIds.length > 2 && membershipType === "Free"}
                                className={`btn-2 ${productIds.length > 2 && membershipType === "Free" ? "disabled" : "enabled"}`}
                                style={{ width: "180px" }}
                                onClick={() => history.push(`/admin/createnewfeddup/${companyId}`)}
                            >
                                Create New Page
                            </Button></>}
                    </div>

                    <div className='relative grid grid-cols-3 gap-12'>
                        {
                            productDetails && productDetails.filter(item => filter(item)).map((product, i) => {
                                return (
                                    product.productDetails?.productName?.toLowerCase()?.includes(searchInput?.toLowerCase()) &&
                                    <Link to={`/admin/productdetails/${product.productDetails.productName}/${product.productDetails.id}/`} style={{ color: 'inherit', textDecoration: 'inherit' }} key={i}>
                                        <div className='relative h-60'>
                                            <div className='products-container min-w-24 h-28 flex items-center'>
                                                <img src={product.productDetails.productIcon || feddUpLogo} alt="" style={{ width: "80px" }} />
                                            </div>
                                            <div className='bg-white max-w-md rounded-xl p-2 h-full'>
                                                <p className='ml-20 2xl:text-2xl xl:text-xl text-lg font-semibold'>{product.productDetails.productName}</p>
                                                <div className='flex flex-col ml-20'>
                                                    <div className='flex flex-col'>
                                                        <p className='text-sm font-semibold'>{product.productDetails.productType}</p>
                                                    </div>
                                                    <div className='flex mt-4'>
                                                        <div className='flex-flex-col text-center'>
                                                            <p className='2xl:text-2xl xl:text-xl text-lg font-medium'>{product.userFollowers}</p>
                                                            <p className='2xl:text-xl xl:text-lg text-base font-medium'>Followers</p>
                                                            {/* <p className='2xl:text-2xl xl:text-xl text-lg mt-2 font-medium'>{product.productDetails.productVersion ? product.productDetails.productVersion : 0}</p> */}
                                                            <p className='2xl:text-2xl xl:text-xl text-lg mt-2 font-medium'>{product?.versions?.length ? product.versions[0].version : 0}</p>
                                                            <p className='2xl:text-xl xl:text-lg text-base font-medium'>Version</p>
                                                        </div>
                                                        <div className='flex flex-col items-end ml-auto mr-4 my-auto gap-2'>
                                                            <p className='text-3xl font-semibold'><span className='text-sm text-gray-500'>({product.numberOfRaters})</span>{product.averageRating}</p>
                                                            {product.numberOfRaters === 0 ? <img src={noReviews} alt='' style={{ width: "40px", height: "40px" }} /> :
                                                                <div className='flex'>
                                                                    {product.averageRating > 0 && <RatingImages rating={product.averageRating} />}
                                                                </div>
                                                            }
                                                            {/* <div style={{ backgroundColor: "#E6570E", marginTop: "1px", width: `${((Math.round(productDetails[i].averageRating * 10) / 10) / 5) * 100}%` }}> <img src={ratingImg} style={{ width: "140px" }} /></div> */}
                                                            <p className='2xl:text-lg xl:text-base text-sm font-semibold'>{ratingDescription(product.averageRating)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })}
                    </div>
                </div>
                {membershipType === "Free" && productIds.length > 2 ?
                    <div className='flex flex-col justify-center items-center mt-10'>
                        <p className='text-orange-600 text-3xl italic'>You have reached the limit for feddup pages</p>
                        <div className='flex mt-4 gap-4'>
                            <img src={premium} alt='' />
                            <div className='flex flex-col'>
                                <p className='text-lg font-bold'>Unlock more from Feddup</p>
                                <p className='text-lg font-semibold'>Create unlimited pages,add moderators.Create Private pages and Much More</p>
                                <div className='flex justify-around'>
                                    <div className='btn-outer mt-4'>
                                        <div className='btn-1' style={{ width: "180px" }}>
                                        </div>
                                        <Button
                                            variant="default" size="md"
                                            type="submit"
                                            className="btn-2 "
                                            style={{ width: "180px" }}
                                            onClick={() => setIsUpgrade(true)}
                                        >
                                            Upgrade to Pro
                                        </Button>
                                    </div>
                                    {/* <div className='btn-outer mt-4'>
                                        <div className='btn-1' style={{ width: "180px" }}>
                                        </div>
                                        <Button
                                            variant="default" size="md"
                                            type="submit"
                                            className="btn-2 "
                                            style={{ width: "180px" }}
                                        // onClick={postAnnouncement}

                                        >
                                            View all features
                                        </Button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    : <></>}
            </div >
            {
                isUpgrade ? <UpgradePopUp onClose={() => setIsUpgrade(false)} /> : <></>
            }
        </div>
    )
}

export default AdminLandingPage
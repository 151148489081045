import React, { useRef, useState, useEffect, version } from "react";
import { Button, Form, Modal, Dropdown, Card, Row, Col, OverlayTrigger, Tooltip, Popover } from "react-bootstrap";
import { Link, NavLink, Redirect, useParams } from "react-router-dom";
import NavBar from "../NavBar/NavBar";
import './AdminFeed.css';
import background from '../images/background-img.svg';
import profilepicture from '../images/profilepicture.png';
import newtab from '../images/newtab.svg';
import axios from 'axios';
import { config } from '../Config/Config';
import EditFeddup from "./EditFeddup";
import arrowLeft from "../images/arrow-left-black.svg";
import linkIcon from "../images/link-text.svg";
import CreateAnnouncement from "./CreateAnnouncement";
import gearIcon from "../images/gear-icon.svg"
import rightArrow from "../images/right-arrow.svg";
import premium from "../images/premium.svg";
import moment from 'moment';
import copyIcon from "../images/copy-script.svg"
import greyDot from "../images/grey-dot.svg";
import addMore from "../images/add-more.svg";
import { ratingDescription } from "../utils/ratingDescription";
import RatingImages from "../utils/DynamicRating/DynamicRatingImages";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { decrypt, encrypt, geoUrl } from "../utils";
import { scaleLinear } from "d3-scale";
import NewVersion from "./NewVersion";
import editIcon from "../images/eye-icon.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Tag from "../utils/Tag";
import fedduplogo from '../images/fedduplogo.png';
import UpgradePopUp from "../Terms/UpgradePopUp";

const sortVersionList = (arr) => {

    const sortedVersions = arr.sort((a, b) => {
        const versionA = a.version.split('.').map(Number);
        const versionB = b.version.split('.').map(Number);

        for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
            const numA = versionA[i] || 0;
            const numB = versionB[i] || 0;

            if (numA < numB) {
                return 1;
            } else if (numA > numB) {
                return -1;
            }
        }

        return 0;
    });

    return sortedVersions

}


export default function ProductDetail() {
    const history = useHistory();
    const { productid } = useParams();
    const [productId, setProductId] = useState("")
    const [productName, setProductName] = useState("")
    const [productTypeString, setProductTypeString] = useState("")
    const [productDescription, setProductDescription] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [productSector, setProductSector] = useState("")
    const [productType, setProductType] = useState("")
    const [productIcon, setProductIcon] = useState("")
    const [pageType, setPageType] = useState("")
    const [feedbackCount, setFeedbackCount] = useState("")
    const [suggestionCount, setSuggestionCount] = useState("")
    const [announcementCount, setAnnouncementCount] = useState("")
    const [flaggedFeedbackCount, setFlaggedFeedbackCount] = useState("")
    const [flaggedSuggestionCount, setFlaggedSuggestionCount] = useState("")
    const [flaggedRatingCount, setFlaggedRatingCount] = useState("")
    const [flaggedRating, setFlaggedRating] = useState([])
    const [flaggedAnnouncementCount, setFlaggedAnnouncementCount] = useState("")
    const [feedback, setFeedback] = useState([])
    const [suggestion, setSuggestion] = useState([])
    const [announcement, setAnnouncement] = useState([])
    const [flaggedFeedback, setFlaggedFeedback] = useState([])
    const [flaggedSuggestion, setFlaggedSuggestion] = useState([])
    const [flaggedAnnouncement, setFlaggedAnnouncement] = useState([])
    const [tags, setTags] = useState([])
    const [region, setRegion] = useState([])
    const [tagCount, setTagCount] = useState("")
    const [companyId, setCompanyId] = useState("")
    const [admin, setAdmin] = useState(false)
    const [item, setItem] = useState("popup")
    const [id, setId] = useState("")
    const [popup, setPopup] = useState(false)
    const [announcePopup, setAnnouncePopup] = useState(false);
    const [productDetail, setProductDetail] = useState("");
    const [userType, setUserType] = useState("Free");
    const [members, setMembers] = useState([]);
    const [membersCount, setMembersCount] = useState(0);
    const [requests, setRequests] = useState([]);
    const [requestsCount, setRequestsCount] = useState(0);
    const [moderators, setModerators] = useState([]);
    const [moderatorsCount, setModeratorsCount] = useState([]);
    const [currentVersion, setCurrentVersion] = useState("");
    const [versions, setVersions] = useState([])
    const [showCopyCodeMoadl, setShowCopyCodeModal] = useState(false)
    const [copied, setCopied] = useState(false)
    const [searchTags, setSearchTags] = useState([])
    const [ratingDesc, setRatingDesc] = useState('')
    const [noOfRatings, setNoOfRatings] = useState(0)
    const [followers, setFollowers] = useState('0')
    const [avgRating, setAvgRating] = useState('0')
    const [ratingsDetails, setRatingsDetails] = useState([])
    const [showNewVersion, setShowNewVersion] = useState(false)
    const [feedbackForm, setFeedbackForm] = useState('')
    const [productTags, setProductTags] = useState([]);
    const [userRole, setUserRole] = useState('')
    const [linkCopied, setLinkCopied] = useState(false)
    const [keyCopied, setKeyCopied] = useState(false)
    const [isUpgrade, setIsUpgrade] = useState(false);
    const [apiKey, setApiKey] = useState()
    const [eye, setEye] = useState(false)

    const code = `<div id="feddupDiv"></div>
                <script id="#feddup12345">
                  var nodeId = "${productid}";
                  </script>
                  <script src="${config.popupUrl}/popup.js">
                  </script>`

    useEffect(() => {
        let role = localStorage.getItem('role')?.toLowerCase()
        if (role)
            setUserRole(role)
        getProductByIdByAdmin();
        getRegionCountByAdmin();
    }, [])

    useEffect(() => {
        const section = document.getElementById('main-section');
        section.scrollIntoView({ behavior: 'smooth' });

        window.scrollTo(0, 0)
    }, [window.location.pathname])

    useEffect(() => {
        if (linkCopied) {
            setTimeout(() => setLinkCopied(false), 2000)
        }
    }, [linkCopied])

    useEffect(() => {
        if (keyCopied) {
            setTimeout(() => setKeyCopied(false), 2000)
        }
    }, [keyCopied])


    const getProductByIdByAdmin = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "id": productid
        }
        axios.post(config.userUrl + "admin/getProductByIdByAdmin", payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull product dEtails", response.data);
                    setProductTags(response.data.getTagByProductId[0]?.tag?.split(","))
                    setProductDetail(response.data.product)
                    setProductName(response.data.product.productName)
                    setCompanyName(response.data.product.companyName)
                    setProductTypeString(() => response.data.product.productType === 1 ? "Standalone" : "Web App")
                    setProductDescription(response.data.product.productDescription)
                    setPageType(response.data.product.pageType)
                    setId(response.data.product.id)
                    setProductId(response.data.productId)
                    localStorage.setItem("junk", encrypt(response.data.product.id))
                    setProductType(response.data.product.productType)
                    setProductSector(response.data.product.productSector)
                    setApiKey(response.data?.product?.apiKey)
                    setProductIcon(response.data.product.productIcon)
                    setSearchTags(() => response.data.product?.searchTags ? response.data.product.searchTags?.split(",") : [])
                    setFeedbackCount(response.data.getFeedbackCount)
                    setFlaggedFeedbackCount(response.data.getFeedbackFlagCount)
                    setSuggestionCount(response.data.getSuggestionCount)
                    setFlaggedSuggestionCount(response.data.getSuggestionFlagCount)
                    setAnnouncementCount(response.data.getAnnouncementCount)
                    setFlaggedAnnouncementCount(response.data.getAnnouncementFlagCount)
                    setFeedback(response.data.getFeedback)
                    setSuggestion(response.data.getSuggestion)
                    setAnnouncement(response.data.getAnnouncement)
                    setFlaggedFeedback(response.data.getFeedbackFlag)
                    setFlaggedSuggestion(response.data.getSuggestionFlag)
                    setFlaggedAnnouncement(response.data.getAnnouncementFlag)
                    response.data.getTagByProductId[0]?.tag && setTags(response.data.getTagByProductId[0]?.tag.split(","))
                    response.data.getTagByProductId[0]?.tag && setTagCount(response.data.getTagByProductId[0]?.tag.split(",").length)
                    setCompanyId(response.data.product.companyId)
                    setUserType(response.data.product.membershipType)
                    setMembers(response.data.members)
                    setMembersCount(response.data.membersCount)
                    setRequests(response.data.requests)
                    setRequestsCount(response.data.requestsCount)
                    setModerators(response.data.moderators)
                    setModeratorsCount(response.data.moderatorsCount)
                    setCurrentVersion(response.data.currentVersion)
                    setVersions(response.data.versions);
                    setFlaggedRatingCount(response.data.flaggedRatingCommentCount)
                    setFlaggedRating(response.data.flaggedRatingComment)
                    if (response.data.averageRating[0]) {
                        setRatingDesc(ratingDescription(response.data.averageRating[0]["avgrating"]))
                    }
                    setNoOfRatings(response.data.numberOfRating)
                    setFollowers(response.data.userFollowers)
                    setAvgRating(response.data.averageRating[0]["avgrating"])
                    setRatingsDetails(response.data.ratingDetails)
                    if (response.data.feedbackForm?.length)
                        setFeedbackForm(response.data.feedbackForm[0])
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    const getRegionCountByAdmin = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        axios.get(config.userUrl + "admin/getAllUsersRegionBase?productId=" + productid, { headers: headers })
            .then(function (response) {
                console.log("getRatigetAllUsersRegionBase", response);
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    setRegion(response.data.allUsers)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };


    const resetApiKey = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        axios.post(config.userUrl + "admin/resetApiKey", {
            companyId,
            productId
        }, { headers: headers })
            .then(function (response) {
                if (response.data?.key)
                    setApiKey(response.data?.key)
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };


    const showAdminFeed = (val) => {
        setAdmin(true)
        setItem(val)
        console.log("asd")

        // document.addEventListener('click', function (event) {
        //     var location = config.webUrl + "admin";
        //     window.open(location, "_blank");
        // }, false);

    }
    // if (admin) {
    //     var itemNew = { productId: productId, item: item }
    //     return <a href="http://localhost:3000/adminhome/:id" target="_blank" ></a>
    // }
    const toggleEditFeddup = () => {
        setPopup(false)
    }
    const toggleOpenEditFeddup = () => {
        setPopup(true)
    }
    const toggleAnnouncePopup = () => {
        setAnnouncePopup(false)
    }
    const toggleOpenAnnouncePopup = () => {
        setAnnouncePopup(true)
    }
    const copyToClipboard = () => {
        navigator.clipboard.writeText(code);
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 3000)
    }
    const colorScale = scaleLinear()
        .domain([0, Math.max(...region.map(d => d.count))])
        .range(["#FF8B66", " #E6570E"]);

    const toggleNewVersion = () => {
        setShowNewVersion(!showNewVersion)
    }

    const showFlagged = (type) => {
        history.push(`/admin/allreviews/${productName}/${productid}/feddbacks?flagged=true&type=${type}`)
    }

    return (
        <div id="main-section">
            {/* {admin && <a href={"adminhome/:id"} target="_blank" />} */}
            {/* {admin && <Redirect to={{ pathname: "/admin", state: { item: item, productId: productId } }} />} */}
            {popup && <EditFeddup closePopup={toggleEditFeddup} data={id} />}
            {announcePopup && <CreateAnnouncement closePopup={toggleAnnouncePopup} tags={productTags.map(tag => ({ isSelected: false, tag: tag }))} product={productDetail} versionId={currentVersion?.id} />}
            {showNewVersion && <NewVersion onSuccess={() => getProductByIdByAdmin()} initialTags={tags.map(tag => ({ isSelected: false, tag }))} toggleNewVersion={toggleNewVersion} productId={productid} companyId={companyId} />}
            <NavBar />
            <div id="background">
                <img src={background} style={{ marginTop: "3rem", width: "100%", }} />
                <Link className="top-20 left-8 z-10 bg-white w-48 rounded-lg pl-4 py-2 absolute" to="/admindashboard" style={{ color: 'inherit', textDecoration: 'inherit' }}><img src={arrowLeft} alt="" className="w-16" />
                    <span>View all Feddup Pages</span>
                </Link>
                <div className="usersfeed-l-r">
                    <Row className="row-width-company">
                        <Col md={6}>
                            <Card className="border-none rounded-xl h-48">
                                <div className="card-padding">
                                    <Row style={{ width: "100%" }}>
                                        <Col md={3}>
                                            <img src={productIcon || fedduplogo} style={{ marginTop: "5px", width: "110px", height: "110px" }} />
                                        </Col>
                                        <Col md={9}>
                                            <div className="mt-2">
                                                <span className={`mr-2 rounded-xl text-center px-2 text-sm text-white ${pageType === "Private" ? "bg-orange-600 " : "bg-orange-400 "}`}>{pageType}</span><br />
                                                <div className="flex ">
                                                    <span className="text-xl font-bold">{productName}</span> <span className="text-xl font-bold">&nbsp;{productTypeString === "Standalone" ? "Desktop Application" : "Web Application"}</span>
                                                    {userRole != 'moderator' && <Link className="ml-auto" to={`/admin/editproduct/${userType}/${productName}/${productId}`} style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                                        <img src={gearIcon} alt="" />
                                                    </Link>}
                                                </div>
                                            </div>
                                            <div className="mt-2 desc text-gray-500">{productDescription}</div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="flex my-4 px-4 text-gray-800 w-full">
                                    {userRole != 'moderator' && <div className="px-2 flex cursor-pointer gap-2" onClick={() => setShowCopyCodeModal(true)}>
                                        <img src={linkIcon} alt="" />
                                        <span >Copy Code & Endpoints</span>
                                    </div>}

                                    <span className="ml-auto">{followers} Followers</span>
                                </div>
                            </Card>
                            {userRole != 'moderator' && <div className="row mt-[11px]">
                                <Col md={6}>
                                    <Card className="border-none text-gray-500 hover:text-black  hover:shadow-xl py-3 feed-card-a text-xl font-semibold" onClick={() => toggleOpenAnnouncePopup()}>Create Announcement</Card>
                                </Col>
                                <Col md={6}>
                                    {
                                        userType === "Premium"
                                            ?
                                            <Link to={`/admin/feddbackforms/${productId}`} style={{ color: 'inherit', textDecoration: 'inherit' }}><Card className="border-none text-gray-500 hover:text-black hover:shadow-xl py-3 feed-card-a text-xl font-semibold" >View Feddup Forms</Card></Link>
                                            :
                                            <Card className="border-none text-gray-500 hover:text-black hover:shadow-xl py-3 feed-card-a text-xl font-semibold !bg-gray-300 group" onClick={() => setIsUpgrade(true)}>
                                                <span className="inline-block group-hover:hidden">View Feddup Forms</span>
                                                <span className="hidden group-hover:inline-block">Upgrade to premium</span>
                                            </Card>
                                    }
                                </Col>
                            </div>}
                            {userType === "Premium" && pageType === "Private" ?
                                <Card className="mt-4 border-none rounded-xl h-48 relative">
                                    <div className="card-padding-ad-dashboard">
                                        <div>
                                            <div className="feedback-left-align">Members</div>
                                            <div className="feedback-count-right-align">{membersCount}</div>
                                        </div><br /><br />
                                        {members && members.length === 0 ? <div className="no-details-text">No Members</div> :
                                            <div>
                                                {members.map((data, i) => (
                                                    <div className="feedback-f-family" key={i}>{1 + i}. {data.userName} </div>
                                                ))}
                                            </div>
                                        }

                                        <Link to={`/admin/${productName}/${productId}/members`} className="cursor absolute bottom-4 right-4">
                                            <img src={newtab} style={{ float: "right", marginTop: "-1.5rem", width: "30px" }} />
                                        </Link>
                                    </div>
                                </Card>
                                : null}
                            <Card className={`border-none rounded-xl h-48 relative ${userType === "Premium" && pageType === "Private" ? "mt-3" : "mt-4"}`}>
                                <div className="card-padding-ad-dashboard">
                                    <div>
                                        <div className="feedback-left-align">Feddback</div>
                                        <div className="feedback-count-right-align">{feedbackCount}</div>
                                    </div><br /><br />
                                    {feedback && feedback.length == 0 ? <div className="no-details-text">No Feddbacks</div> :
                                        <div>
                                            {feedback.map((data, i) => (
                                                <div className="feedback-f-family" key={i}>{1 + i}. {data.title} </div>
                                            ))}
                                        </div>
                                    }
                                    <Link className="cursor absolute bottom-4 right-4" to={`/admin/allreviews/${productName}/${productId}/feddbacks`}><img src={newtab} style={{ float: "right", marginTop: "-1.5rem", width: "30px" }} /></Link>
                                </div>
                            </Card>
                            <Card className="border-none rounded-xl h-48 relative mt-3">
                                <div className="card-padding-ad-dashboard">
                                    <div>
                                        <div className="feedback-left-align">Ratings</div>
                                        <div className="feedback-count-right-align flex flex-col items-end">
                                            <p className='text-3xl font-semibold text-black'><span className='text-sm text-gray-400'>({noOfRatings})</span>{avgRating}</p>
                                            <div className='flex'>
                                                {/* <img src={rating4} alt='' style={{ width: "30px", height: "30px" }} />
                                                <img src={rating5} alt='' style={{ width: "30px", height: "30px" }} /> */}
                                                {avgRating > 0 && <RatingImages rating={avgRating} />}
                                            </div>
                                            <p className='text-sm font-semibold'>{ratingDesc}</p>
                                        </div>
                                    </div><br /><br />
                                    {ratingsDetails && ratingsDetails.length == 0 ? <div className="no-details-text">No Ratings</div> :
                                        <div>
                                            {ratingsDetails.map((data, i) => (
                                                <div className="feedback-f-family" key={i}>{1 + i}. {data.comment?.length > 40 ? data.comment.substring(0, 40) + "..." : data.comment} </div>
                                            ))}
                                        </div>
                                    }
                                    <Link className="cursor absolute bottom-4 right-4" to={`/admin/allreviews/${productName}/${productId}/reviews`}><img src={newtab} style={{ float: "right", marginTop: "-1.5rem", width: "30px" }} /></Link>
                                </div>
                            </Card>
                            <Card className="mt-3 border-none rounded-xl h-48 relative">
                                <div className="card-padding-ad-dashboard">
                                    <div>
                                        <div className="feedback-left-align">Announcements</div>
                                        <div className="feedback-count-right-align">{announcementCount}</div>
                                    </div><br /><br />
                                    {announcement && announcement.length == 0 ? <div className="no-details-text">No Announcements</div> :
                                        <div>
                                            {announcement.map((data, i) => (
                                                <div className="feedback-f-family" key={i}>{1 + i}. {data.title} </div>
                                            ))}
                                        </div>
                                    }
                                    <Link className="cursor absolute bottom-4 right-4" to={`/admin/allreviews/${productName}/${productId}/announcements`}><img src={newtab} style={{ float: "right", marginTop: "-1.5rem", width: "30px" }} /></Link>
                                </div>
                            </Card>
                            <Card className="mt-3 border-none rounded-xl relative">
                                <div className="card-padding-ad-dashboard">
                                    <div>
                                        <div className="feedback-left-align">Tags</div>
                                        <div className="feedback-count-right-align">{tagCount}</div>
                                    </div>
                                    <br />
                                    <br />
                                    <div className="flex flex-wrap pb-3">
                                        {
                                            tags?.length
                                                ?
                                                <div className='flex flex-wrap gap-x-6 gap-y-3 p-3'>
                                                    {tags?.map(tag => <Tag text={tag} />
                                                    )}
                                                </div>
                                                :
                                                <div className="no-details-text">No Tags</div>
                                        }
                                    </div>
                                    {userRole != 'moderator' && <Link className="cursor-pointer absolute bottom-4 right-4" to={`/admin/editproduct/${userType}/${productName}/${productId}/tags`} style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                        <img src={newtab} style={{ float: "right", marginTop: "-1.5rem", width: "30px" }} />
                                    </Link>}
                                </div>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card className="border-none rounded-xl h-[17rem]">
                                {userType === "Free" ?
                                    <div className="card-padding-ad-dashboard">
                                        <div className="feedback-left-align">
                                            <p>Versions</p>
                                        </div>
                                        <div className="flex justify-center items-center my-10 gap-4 h-full">
                                            <img src={premium} alt="" />
                                            <div className="flex flex-col items-center">
                                                <p className="text-xl font-semibold">Upgrade to Premium</p>
                                                <div className='btn-outer mt-3'>
                                                    <div className='btn-1' style={{ height: "40px", width: '150px' }}></div>
                                                    <Button
                                                        variant="default"
                                                        type="submit"
                                                        className="btn-2"
                                                        style={{ position: "absolute", fontSize: "16px", height: "40px", width: '150px', lineHeight: "16px" }}
                                                        onClick={() => setIsUpgrade(true)}
                                                    >Upgrade Now
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <div className="card-padding-ad-dashboard flex flex-col gap-4 rel">
                                        <div>
                                            <div className="feedback-left-align">
                                                <p>Version: {currentVersion?.version}</p>
                                                <p className="text-sm font-light">{currentVersion?.releaseDate ? moment(currentVersion?.releaseDate, "YYYY-MM-DD HH:mm:ss.SSSZ").fromNow() : "No Version Available"}</p>
                                                {userRole != 'moderator' &&
                                                    (!feedbackForm ?
                                                        <div className={`flex justify-center items-center gap-1`}>
                                                            <span className="text-sm text-black">No Feedback Form</span>
                                                        </div> :
                                                        <Card className="border-none text-black font-semibold" >
                                                            <div className={`flex justify-center items-center gap-1`}>
                                                                <span className="text-base">{feedbackForm?.formName}</span>
                                                                <img src={editIcon} alt="" className="w-6 h-8 cursor-pointer"
                                                                    onClick={() => { history.push(`/admin/viewform/${feedbackForm?.id}`, { name: feedbackForm?.formName, id: feedbackForm.id, productid }) }}
                                                                />
                                                            </div>
                                                        </Card>)
                                                }
                                            </div>
                                            <div className="feedback-count-right-align relative">
                                                {userRole != 'moderator' && <div className='btn-outer mt-2'>
                                                    <div className='btn-1' style={{ height: "30px" }}></div>
                                                    <Button
                                                        variant="default"
                                                        type="submit"
                                                        className="btn-2"
                                                        style={{ position: "absolute", fontSize: "12px", height: "30px", lineHeight: "16px" }}
                                                        onClick={toggleNewVersion}
                                                    >Create New Version
                                                    </Button>
                                                </div>}
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-2 max-w-md w-md h-24">
                                            {versions.length === 0 ? <div className="ml-2 text-center">
                                                <p className="text-xl text-orange-600 font-bold">No Previous Versions</p>
                                            </div> : sortVersionList(versions).map((version, i) => {
                                                return (
                                                    <div className="ml-2" key={i}>
                                                        <p className="text-xl text-orange-600 font-bold">Version {version?.version}</p>
                                                        <p className="text-sm font-light ml-2  text-orange-600">
                                                            {moment(version.releaseDate, "YYYY-MM-DD HH:mm:ss.SSSZ").format("DD/MM/YY")}-{moment(version.deprecationDate, "YYYY-MM-DD HH:mm:ss.SSSZ").format("DD/MM/YY")}
                                                        </p>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                        <Link className="cursor ml-auto top-0 relative" to={`/admin/allreviews/${productName}/${productId}/reviews`}>
                                            <div className='btn-outer' style={{ top: "-14px" }}>
                                                <div className='btn-1' style={{ height: "30px" }}></div>
                                                <Button
                                                    variant="default"
                                                    type="submit"
                                                    className="btn-2"
                                                    style={{ position: "absolute", fontSize: "16px", height: "30px", lineHeight: "16px" }}
                                                // onClick={postAnnouncement}

                                                >All Reviews
                                                </Button>
                                            </div>
                                        </Link>
                                    </div>}
                            </Card>

                            {userType === "Premium" && pageType === "Private" ?
                                <Card className="mt-3 border-none rounded-xl h-48">
                                    <div className="card-padding-ad-dashboard">
                                        <div>
                                            <div className="feedback-left-align">Requests</div>
                                            <div className="feedback-count-right-align">{requestsCount}</div>
                                        </div><br /><br />
                                        {requests && requests.length === 0 ? <div className="no-details-text">No Request</div> :
                                            <div>
                                                {requests.map((data, i) => (
                                                    <div className="feedback-f-family" key={i}>{1 + i}. {data.userName} </div>
                                                ))}
                                            </div>
                                        }
                                        {/* <div className="cursor"><a href={config.webUrl + `admin/${companyName}/${productName}/${productTypeString}?item=${"Feedback"}`} target="_blank"><img src={newtab} style={{ float: "right", marginTop: "-1.5rem", width: "30px" }} /></a></div> */}
                                        <Link to={`/admin/${productName}/${productId}/members?type=request`} className="cursor absolute bottom-4 right-4">
                                            <img src={newtab} style={{ float: "right", marginTop: "-1.5rem", width: "30px" }} />
                                        </Link>
                                    </div>
                                </Card>
                                : null}
                            <Card className="mt-3 border-none rounded-xl h-48 relative">
                                <div className="card-padding-ad-dashboard">
                                    <div>
                                        <div className="feedback-left-align">Suggestions</div>
                                        <div className="feedback-count-right-align">{suggestionCount}</div>
                                    </div><br /><br />
                                    {suggestion && suggestion.length == 0 ? <div className="no-details-text">No Suggestions</div> :
                                        <div>
                                            {suggestion.map((data, i) => (
                                                <div className="feedback-f-family" key={i}>{1 + i}. {data.title} </div>
                                            ))}
                                        </div>
                                    }
                                    <Link className="cursor absolute bottom-4 right-4" to={`/admin/allreviews/${productName}/${productId}/suggestions`}><img src={newtab} style={{ float: "right", marginTop: "-1.5rem", width: "30px" }} /></Link>
                                </div>
                            </Card>
                            <Card className="mt-3 border-none rounded-xl h-48 relative bg-gray-100">
                            </Card>
                            <Card className="mt-3 border-none rounded-xl h-48">
                                {userType === "Free" ?
                                    <div className="card-padding-ad-dashboard">
                                        <div className="feedback-left-align">
                                            <p>Moderators</p>
                                        </div>
                                        <div className="flex justify-center items-center my-10 gap-8">
                                            <img src={premium} alt="" />
                                            <div className="flex flex-col items-center">
                                                <p className="text-xl font-semibold">Upgrade to Premium</p>
                                                <div className='btn-outer mt-3'>
                                                    <div className='btn-1' style={{ height: "40px", width: '150px' }}></div>
                                                    <Button
                                                        variant="default"
                                                        type="submit"
                                                        className="btn-2"
                                                        style={{ position: "absolute", fontSize: "16px", height: "40px", width: '150px', lineHeight: "16px" }}
                                                        onClick={() => setIsUpgrade(true)}
                                                    >Upgrade Now
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <div className="card-padding-ad-dashboard">
                                        <div>
                                            <div className="feedback-left-align">Portal Access</div>
                                            <div className="feedback-count-right-align">{moderatorsCount}</div>
                                        </div><br /><br />
                                        {moderators && moderators.length == 0 ? <div className="no-details-text">No Users</div> :
                                            <div className="grid grid-cols-2 gap-y-2">
                                                {moderators.map((data, i) => (
                                                    <div className="feedback-f-family flex items-center" key={i}><img src={data.profilePicture ? data.profilePicture : greyDot} alt='' className={data.profilePicture ? "w-10" : "w-5"} />&nbsp;&nbsp;{data.userName} </div>
                                                ))}
                                            </div>
                                        }
                                        {<Link className="cursor absolute bottom-4 right-4" to={`/${productName}/users`}><img src={newtab} style={{ float: "right", marginTop: "-1.5rem", width: "30px" }} /></Link>}
                                    </div>}
                            </Card>
                            <Card className="mt-3 border-none rounded-xl">
                                <div className="card-padding-ad-dashboard">
                                    <div>
                                        <div className="feedback-left-align">Product Search Tags</div>
                                        <div className="feedback-count-right-align">{searchTags?.length}</div>
                                    </div><br /><br />
                                    <div className="flex flex-wrap pb-3">
                                        {
                                            searchTags?.length
                                                ?
                                                <div className='flex flex-wrap gap-x-3 gap-y-3 py-3'>
                                                    {searchTags?.map(tag => <Tag text={tag} />
                                                    )}
                                                </div>
                                                :
                                                <div className="no-details-text">No Search Tags</div>
                                        }
                                    </div>
                                    {userRole != 'moderator' && <Link className="cursor-pointer absolute bottom-4 right-4" to={`/admin/editproduct/${userType}/${productName}/${productId}/tags`} style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                        <img src={newtab} style={{ float: "right", marginTop: "-1.5rem", width: "30px" }} />
                                    </Link>}
                                </div>
                            </Card>
                        </Col>
                        <Col md={12}>
                            <Card className="mt-4 border-none rounded-xl mapItem">
                                <div className="flex items-start gap-4">
                                    <div md={6} className="card-padding-ad-dashboard flex flex-col">
                                        <div>
                                            <div className="feedback-left-align">Region Based Reviews</div>
                                        </div>
                                        {region && region.length == 0 ? <div className="no-details-text">No Reviews yet</div> :
                                            <div className="mt-10">
                                                {region.map((data, i) => (
                                                    <div key={i} className='flex items-center gap-3 px-3 pb-2 first:pt-2'>
                                                        <div md={3} className='text-orange text-lg'>
                                                            {data.country}
                                                        </div>
                                                        <div md={3}>
                                                            <span className="text-orange-500 font-semibold text-2xl">{data.count}</span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                    <div md={6} className="">
                                        <ComposableMap projectionConfig={{ scale: 160 }} style={{ width: '100%', height: '600px' }}>
                                            {(
                                                <Geographies geography={geoUrl}>
                                                    {({ geographies }) =>
                                                        geographies.map(geo => {
                                                            const cur = region.find(s => s.country.toLowerCase() === geo.properties.name.toLowerCase());
                                                            return (
                                                                <Geography
                                                                    key={geo.rsmKey}
                                                                    geography={geo}
                                                                    fill={cur ? colorScale(cur.count) : '#C0C0C0'}
                                                                    stroke="#FFFFFF"
                                                                />
                                                            );
                                                        })
                                                    }
                                                </Geographies>
                                            )}
                                        </ComposableMap>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card className="mt-3 border-none rounded-xl h-48 relative">
                                <div className="card-padding-ad-dashboard">
                                    <div>
                                        <div className="feedback-left-align">Flagged Feedbacks</div>
                                        <div className="feedback-count-right-align">{flaggedFeedbackCount}</div>
                                    </div><br /><br />
                                    {flaggedFeedback && flaggedFeedback.length == 0 ? <div className="no-details-text">No Feedbacks</div> :
                                        <div>
                                            {flaggedFeedback.map((data, i) => (
                                                <div className="feedback-f-family" key={i}>{1 + i}. {data.title} </div>
                                            ))}
                                        </div>
                                    }
                                    <div className="cursor absolute bottom-4 right-4"><p onClick={() => showFlagged('feddbacks')}><img src={newtab} style={{ float: "right", marginTop: "-1.5rem", width: "30px" }} /></p></div>
                                </div>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card className="mt-3 border-none rounded-xl h-48 relative">
                                <div className="card-padding-ad-dashboard">
                                    <div>
                                        <div className="feedback-left-align">Flagged Suggestions</div>
                                        <div className="feedback-count-right-align">{flaggedSuggestionCount}</div>
                                    </div><br /><br />
                                    {flaggedSuggestion && flaggedSuggestion.length == 0 ? <div className="no-details-text">No Suggestions</div> :
                                        <div>
                                            {flaggedSuggestion.map((data, i) => (
                                                <div className="feedback-f-family" key={i}>{1 + i}. {data.title} </div>
                                            ))}
                                        </div>
                                    }
                                    <div className="cursor absolute bottom-4 right-4"><p onClick={() => showFlagged('suggestions')}><img src={newtab} style={{ float: "right", marginTop: "-1.5rem", width: "30px" }} /></p></div>
                                </div>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card className="mt-3 border-none rounded-xl h-48 relative">
                                <div className="card-padding-ad-dashboard">
                                    <div>
                                        <div className="feedback-left-align">Flagged Reviews</div>
                                        <div className="feedback-count-right-align">{flaggedRatingCount}</div>
                                    </div><br /><br />
                                    {flaggedRating && flaggedRating.length == 0 ? <div className="no-details-text">No Reviews</div> :
                                        <div>
                                            {flaggedRating.map((data, i) => (
                                                <div className="feedback-f-family" key={i}>{1 + i}. {data.comment} </div>
                                            ))}
                                        </div>
                                    }
                                    <div className="cursor absolute bottom-4 right-4"><p onClick={() => showFlagged('reviews')}><img src={newtab} style={{ float: "right", marginTop: "-1.5rem", width: "30px" }} /></p></div>
                                </div>
                            </Card>
                        </Col>
                    </Row >

                </div > <br />
                <Modal size="xl" show={showCopyCodeMoadl} onHide={() => setShowCopyCodeModal(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className='text-orange-600 underline underline-offset-8'>
                                <span className="text-black">Feddup Page code Plugin</span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="-mt-4 flex gap-4 pl-3">
                            <div className='btn-outer-section w-[22rem]'>
                                <div className={item == "popup" ? "feed-card f-color btn-inner-section" : "feed-card-a f-color-g btn-inner-section"} onClick={() => setItem("popup")}>Pop up Code</div>
                            </div>
                            <div className='btn-outer-section w-[22rem]'>
                                <div className={item == "request" ? "feed-card f-color btn-inner-section" : "feed-card-a f-color-g btn-inner-section"} onClick={() => setItem("request")}>Requests</div>
                            </div>
                        </div>
                        {item === "popup" ?
                            <div className="flex flex-col mt-4 h-[400px]">
                                <p className="text-black">Inject this code to you application fronted to showcase the feddup up pop up. Allowing users to rate/review, provide suggestions etc </p>
                                <div className="relative mt-8 mb-16 bg-gray-700 mx-auto w-10/12 h-80 rounded-xl">
                                    {copied ? <div className='absolute -top-10 right-0 bg-orange px-4 py-1.5 text-white font-medium text-sm rounded-full z-50'>
                                        <h1>Copied</h1>
                                    </div> : ''}
                                    <div className="flex gap-1 w-full justify-end items-end p-2 text-white cursor-pointer" onClick={copyToClipboard}>
                                        <img src={copyIcon} alt="" />
                                        <span>Copy Script</span>
                                    </div>
                                    <p className="text-white pt-4 px-4">{`<div id="feddupDiv"></div>`}</p>
                                    <p className="text-white pt-4 px-4">{`<script id="#feddup12345">`}</p>
                                    <p className="text-white px-4">{`var nodeId = "${productid}";`}</p>
                                    <p className="text-white px-4"> {`</script>`}</p>
                                    <p className="text-white px-4">{`<script src="${config.popupUrl}/popup.js">`}</p>
                                    <p className="text-white px-4">{`</script>`}</p>
                                </div>
                            </div> :
                            <div className="flex flex-col mt-4 ml-3 font-medium text-md h-[400px]">
                                <p className="text-black">Utilize this API to receive your page’s avg rating, highlighted comments, number of followers and number of ratings </p>
                                <p className="text-black font-semibold mt-3 mb-2">API Endpoint</p>
                                <div className="bg-gray-200 w-11/12 h-10 rounded-lg p-2 relative pr-10 text-gray-800" onClick={() => { navigator.clipboard.writeText(encodeURI(`${config.userUrl}page/${productName}`)); setLinkCopied(true) }}>{encodeURI(`${config.userUrl}page/${productName}`)}
                                    <div className="flex gap-1 w-full justify-end items-end p-2 text-white cursor-pointer absolute right-0 top-0" >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-800">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z" />
                                        </svg>
                                    </div>
                                    {linkCopied ? <div className='absolute -top-10 right-0 bg-orange px-4 py-1.5 text-white font-medium text-sm rounded-full z-50'>
                                        <h1>Copied</h1>
                                    </div> : ''}
                                </div>
                                <p className="text-black font-semibold mt-3 mb-2">API Key</p>
                                <div className="bg-gray-200 w-11/12 h-10 rounded-lg p-2 relative pr-10 text-gray-800 select-none">{eye ? apiKey : '●●●●●●●●●●●●●●●●'}
                                    <div className="flex gap-3 w-full justify-end items-end p-2 text-white absolute right-0 top-0" >
                                        {eye
                                            ? <svg onClick={() => setEye(!eye)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-800 cursor-pointer">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                            </svg>
                                            :
                                            <svg onClick={() => setEye(!eye)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-800 cursor-pointer">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                            </svg>
                                        }
                                        <svg onClick={() => { navigator.clipboard.writeText(`${apiKey}`); setKeyCopied(true) }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-800 cursor-pointer">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z" />
                                        </svg>
                                    </div>
                                    {keyCopied ? <div className='absolute -top-10 right-0 bg-orange px-4 py-1.5 text-white font-medium text-sm rounded-full z-50'>
                                        <h1>Copied</h1>
                                    </div> : ''}
                                </div>
                                <div onClick={() => resetApiKey()} className="bg-[#FFB858] py-1.5 px-3 flex items-center gap-2 text-gray-800 max-w-max rounded-xl mt-2 cursor-pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                    </svg>
                                    <span>Generate new key</span>
                                </div>

                                <div className="grid place-items-center w-full mt-8">
                                    <a href={"/faces.zip"} target="_blank" rel="noreferrer" className="link">
                                        <div className="flex items-center gap-8 bg-[#FFB858] p-3 rounded-xl">

                                            <div className="flex items-center gap-0.5">
                                                <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M41.1133 1.98242H1.11328V41.9824H41.1133V1.98242Z" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M31.1691 34.358C31.1691 28.8331 26.6387 24.3027 21.1138 24.3027C15.589 24.3027 11.0586 28.8331 11.0586 34.358H31.1691Z" fill="black" />
                                                    <path d="M8.73828 10.0488L14.5946 16.0157" stroke="black" stroke-width="3.9997" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M14.5946 10.0488L8.73828 16.0157" stroke="black" stroke-width="3.9997" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M27.5225 10.0488L33.4893 16.0157" stroke="black" stroke-width="3.9997" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M33.4893 10.0488L27.5225 16.0157" stroke="black" stroke-width="3.9997" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M41.1133 1.98242H1.11328V41.9824H41.1133V1.98242Z" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M31.4201 34.358C31.4201 28.8331 26.8897 24.3027 21.3648 24.3027C15.84 24.3027 11.3096 28.8331 11.3096 34.358C17.7184 31.2641 25.1217 31.2641 31.4201 34.358Z" fill="black" />
                                                    <path d="M13.6307 19.7722C15.2174 19.7722 16.5037 18.486 16.5037 16.8993C16.5037 15.3126 15.2174 14.0264 13.6307 14.0264C12.0441 14.0264 10.7578 15.3126 10.7578 16.8993C10.7578 18.486 12.0441 19.7722 13.6307 19.7722Z" fill="black" />
                                                    <path d="M9.7627 12.2595C9.7627 12.2595 14.9561 11.3755 18.1605 17.5633" stroke="black" stroke-width="2.8346" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M29.9852 19.7722C31.5719 19.7722 32.8582 18.486 32.8582 16.8993C32.8582 15.3126 31.5719 14.0264 29.9852 14.0264C28.3986 14.0264 27.1123 15.3126 27.1123 16.8993C27.1123 18.486 28.3986 19.7722 29.9852 19.7722Z" fill="black" />
                                                    <path d="M33.8529 12.2585C33.8529 12.2585 28.6595 11.3745 25.4551 17.5624" stroke="black" stroke-width="2.8346" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M41.1133 1.98242H1.11328V41.9824H41.1133V1.98242Z" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M13.9296 15.7947C15.5162 15.7947 16.8025 14.5084 16.8025 12.9218C16.8025 11.3351 15.5162 10.0488 13.9296 10.0488C12.3429 10.0488 11.0566 11.3351 11.0566 12.9218C11.0566 14.5084 12.3429 15.7947 13.9296 15.7947Z" fill="black" />
                                                    <path d="M28.1835 15.7947C29.7701 15.7947 31.0564 14.5084 31.0564 12.9218C31.0564 11.3351 29.7701 10.0488 28.1835 10.0488C26.5968 10.0488 25.3105 11.3351 25.3105 12.9218C25.3105 14.5084 26.5968 15.7947 28.1835 15.7947Z" fill="black" />
                                                    <path d="M30.6159 24.9639C29.2899 28.9418 25.533 31.8147 21.1131 31.8147C16.6932 31.8147 12.9363 28.9418 11.6104 24.9639" stroke="black" stroke-width="2.8346" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M41.1133 1.98242H1.11328V41.9824H41.1133V1.98242Z" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M31.1691 24.3027C31.1691 25.4077 30.9481 26.5127 30.6166 27.5072C29.2906 31.4851 25.5337 34.358 21.1138 34.358C16.694 34.358 12.937 31.4851 11.6111 27.5072C11.2796 26.5127 11.0586 25.4077 11.0586 24.3027H31.1691Z" fill="black" stroke="black" stroke-width="5.6693" stroke-miterlimit="10" stroke-linecap="round" />
                                                    <path d="M13.9315 15.7947C15.5182 15.7947 16.8044 14.5084 16.8044 12.9218C16.8044 11.3351 15.5182 10.0488 13.9315 10.0488C12.3449 10.0488 11.0586 11.3351 11.0586 12.9218C11.0586 14.5084 12.3449 15.7947 13.9315 15.7947Z" fill="black" />
                                                    <path d="M28.2958 15.7947C29.8825 15.7947 31.1687 14.5084 31.1687 12.9218C31.1687 11.3351 29.8825 10.0488 28.2958 10.0488C26.7091 10.0488 25.4229 11.3351 25.4229 12.9218C25.4229 14.5084 26.7091 15.7947 28.2958 15.7947Z" fill="black" />
                                                </svg>
                                                <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M41.1133 1.98242H1.11328V41.9824H41.1133V1.98242Z" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M31.1691 24.3027C31.1691 25.4077 30.9481 26.5127 30.6166 27.5072C29.2906 31.4851 25.5337 34.358 21.1138 34.358C16.694 34.358 12.937 31.4851 11.6111 27.5072C11.2796 26.5127 11.0586 25.4077 11.0586 24.3027H31.1691Z" fill="black" stroke="black" stroke-width="5.6693" stroke-miterlimit="10" stroke-linecap="round" />
                                                    <path d="M19.7864 7.97974C19.8969 9.7477 19.234 13.2836 14.7036 17.2615C8.73673 16.488 6.30578 13.8361 5.4218 12.2891C5.09031 11.7367 4.97982 11.4052 4.97982 11.2947C4.42733 9.30571 5.53231 7.09577 7.52126 6.54328C9.51021 5.99079 11.7202 7.09577 12.2726 9.08472C11.7202 7.09577 12.8251 4.88582 14.8141 4.33334C16.803 3.78085 19.013 4.88582 19.5655 6.87477C19.676 6.87477 19.7864 7.31676 19.7864 7.97974Z" fill="black" />
                                                    <path d="M22.4386 7.97974C22.3281 9.7477 22.9911 13.2836 27.5215 17.2615C33.4883 16.488 35.9193 13.8361 36.8033 12.2891C37.1348 11.7367 37.2453 11.4052 37.2453 11.2947C37.7977 9.30571 36.6928 7.09577 34.7038 6.54328C32.7149 5.99079 30.5049 7.09577 29.9525 9.08472C30.5049 7.09577 29.4 4.88582 27.411 4.33334C25.4221 3.78085 23.2121 4.88582 22.6596 6.87477C22.5491 6.87477 22.4386 7.31676 22.4386 7.97974Z" fill="black" />
                                                    <path d="M31.1691 24.3027C31.1691 25.4077 30.9481 26.5127 30.6166 27.5072H11.6111C11.2796 26.5127 11.0586 25.4077 11.0586 24.3027H31.1691Z" fill="white" />
                                                </svg>
                                            </div>
                                            <div>
                                                <h1 className="text-2xl font-medium">Download Face</h1>
                                                <p>Add this to your website</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>}

                    </Modal.Body>
                </Modal>

            </div >

            {
                isUpgrade ? <UpgradePopUp onClose={() => setIsUpgrade(false)} /> : <></>
            }
        </div >
    );
}


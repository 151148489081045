import React, { useState, useEffect } from 'react';
import '../CompanyFeed/CompanyFeed.css';
import { Row, Col, Modal, Form, OverlayTrigger, Tooltip, FormControl, Card, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import upvoteb from '../images/upvote-b.png';
import downvoteb from '../images/downvote-b.svg';
import upvote from '../images/upvote.svg';
import downvote from '../images/downvote.svg';
import comment from '../images/comments.svg';
import share from '../images/share.svg';
import fillTag from "../images/fill-tag.svg";
import emptyTag from "../images/empty-tag.svg";
import AddImage from '../CompanyFeed/AddImage';
import imageicon from "../images/image-icon.svg";
import profilepicture from '../images/profilepicture.png';
import deletei from "../images/delete-i.svg";
import Editor from '../CompanyFeed/Editor';
import axios from 'axios';
import { config } from '../Config/Config';
import Tag from '../utils/Tag';
import AddImage2 from '../CompanyFeed/AddImage2';
import i from "../images/i.svg";
import { decrypt } from '../utils';


const EditSuggestionPopup = (props) => {
    const [editSuggestionPost, setEditSuggestionPost] = useState(true);
    const [suggestionId, setSuggestionId] = useState(props.suggId);
    const [suggestionTitle, setSuggestionTitle] = useState(props.title);
    const [suggestionDescription, setSuggestionDescription] = useState(props.postSuggestion);
    const [image, setImage] = useState(props.image);
    const [tag, setTag] = useState(props.tag);
    const [defaultTags, setDefaultTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [newTags, setNewTags] = useState([]);

    useEffect(() => {
        if (props.tag && props.initialTags) {
            let tags = props.initialTags?.map(item => {
                if (props.tag?.includes(item.tag)) {
                    item.isSelected = true
                }
                return item
            })
            setDefaultTags(tags)
        }
        else if (props.productId) {
            getTagsByProductId(props.productId)
        }

    }, []);


    const onChangeImage = (data) => {
        setImage(data);
        console.log("s", data);
    };

    const handleTags = (e, tagValue) => {
        const updated = defaultTags.map(data => (data.tag === tagValue) ? { ...data, isSelected: !data.isSelected } : data);
        setDefaultTags(updated);
        setSelectedTags(updated);
    };

    const editSuggestionById = (img) => {
        const headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        };
        let tagsData = defaultTags.filter(item => (item.isSelected === true) ? item.tag : null);
        const imgUrl = (img === "img") ? "" : image;
        const payload = {
            "suggestionId": suggestionId,
            "title": suggestionTitle,
            "suggestion": suggestionDescription,
            "suggestionImage": imgUrl,
            "tag": tagsData.map(item => item.tag)
        };
        console.log("payload", payload);
        console.log("url", imgUrl, "img", img);
        axios.post(config.userUrl + 'user/updateSuggestionByCreater', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    console.log("success", response.data.message, payload);
                    props.getSuggestions(props.selectedValue);
                    props.closePopup();
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };

    const getTagsByProductId = (id) => {
        const headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        };

        const payload = {
            productId: id
        };
        axios.post(config.userUrl + 'admin/getTagsByProductId', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    let tags = response?.data?.tags?.map(item => {
                        return {
                            tag: item,
                            isSelected: Boolean(props.tag?.includes(item))
                        }
                    })
                    setDefaultTags(tags)
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };

    return (
        <Modal
            size="xl"
            show={editSuggestionPost}
            onHide={props.closePopup}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className='flex items-baseline gap-2'>
                        <span className='h-12 w-20'>
                            <svg viewBox="0 0 123 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M92.0658 60.8291L113.51 82.3076" stroke="#E6570E" stroke-width="9" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M113.559 60.8772L92.0176 82.2594" stroke="#E6570E" stroke-width="9" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M0.984108 17.8875C0.984108 27.6462 8.8951 35.5572 18.6538 35.5572C28.4125 35.5572 36.3235 27.6462 36.3235 17.8875C36.3235 8.12876 28.4125 0.217773 18.6538 0.217773C8.8951 0.217773 0.984108 8.12876 0.984108 17.8875Z" fill="#FFB858" />
                                <path d="M31.1702 40.9355C34.893 42.9316 33.1965 46.0774 36.9194 48.0734C40.6422 50.0694 42.3387 46.9236 46.0615 48.9196C49.7844 50.9156 48.0879 54.0614 51.8108 56.0574C55.5336 58.0534 57.2301 54.9077 60.9529 56.9037C64.6758 58.8997 62.9793 62.0455 66.7021 64.0415" stroke="#E6570E" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M37.6181 29.5059C41.3409 31.5019 39.6445 34.6477 43.3673 36.6437C47.0902 38.6397 48.7866 35.4939 52.5095 37.4899C56.2323 39.4859 54.5359 42.6317 58.2587 44.6277C61.9815 46.6238 63.678 43.478 67.4009 45.474C71.1237 47.47 69.4272 50.6158 73.1501 52.6118" stroke="#E6570E" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M44.0659 18.0762C47.7887 20.0722 46.0922 23.218 49.8151 25.214C53.5379 27.21 55.2344 24.0642 58.9572 26.0602C62.6801 28.0562 60.9836 31.202 64.7065 33.1981C68.4293 35.1941 70.1258 32.0483 73.8486 34.0443C77.5715 36.0403 75.875 39.1861 79.5978 41.1821" stroke="#E6570E" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M120.872 56.3545C120.458 60.2882 117.543 67.7957 105.335 74.7004C91.5517 70.5692 86.8285 63.7463 85.3335 59.9837C84.7623 58.6335 84.6281 57.8594 84.6703 57.6163C84.1262 53.0165 87.5772 48.6056 92.481 48.2004C97.3849 47.7951 102.177 51.1254 102.721 55.7252C102.177 51.1254 105.628 46.7145 110.532 46.3093C115.436 45.904 120.228 49.2344 120.773 53.8341C121.033 53.8791 121.125 54.8963 120.872 56.3545Z" fill="#04C700" />
                            </svg>

                        </span>
                        <h5 style={{ fontFamily: "poppins" }} className='text-orange font-semibold'>Edit Suggestion</h5>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-top`}>
                                    <div className='tool-text'>
                                        Public community post where you can suggest new features and updates<br />
                                    </div>
                                </Tooltip>
                            }
                        >
                            <span className="ml-2 i-icon"><img src={i} style={{ width: "20px" }} /></span>
                        </OverlayTrigger>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="-mt-8 mb-2 px-5">
                    <Form>
                        <Form.Control style={{ fontFamily: "poppins" }} type="text" name="suggestionTitle"
                            value={suggestionTitle} placeholder="Suggestion Title"
                            onChange={(e) => setSuggestionTitle(e.target.value)} />
                        <textarea
                            className="mt-3 form-control"
                            id="exampleFormControlTextarea1"
                            style={{ fontFamily: "poppins", resize: "none" }}
                            rows="5"
                            maxLength={180}
                            name="suggestionDescription"
                            value={suggestionDescription}
                            placeholder="Let us know your ideas"
                            onChange={(e) => setSuggestionDescription(e.target.value)}
                        />
                        <div style={{ fontFamily: "poppins", fontSize: "12px" }}>{suggestionDescription.length}/180 characters</div>
                        <div className='flex items-start w-full py-4 gap-6'>
                            <div className="w-1/2"> <AddImage2 imageUrl={image} onChangeImage={onChangeImage} /></div>
                            <div className='w-1/2 h-[13rem] overflow-y-auto scrollbar-hide rounded-lg border-2 border-gray-500 p-2'>
                                <h1 className='text-orange font-medium'>Select Tags</h1>
                                <div className='flex flex-wrap gap-y-2 py-2'>
                                    {
                                        defaultTags.map((data, i) => (
                                            <span className='cursor-pointer' onClick={(e) => handleTags(e, data.tag)}>
                                                <Tag text={data.tag} color={data.isSelected ? '' : '#999'} />
                                            </span>
                                        ))}
                                </div>
                            </div>
                        </div>

                    </Form>
                    <div className='w-full flex items-center justify-between'>
                        <div onClick={props.closePopup} className=" text-orange font-medium text-lg cursor-pointer">Cancel</div>


                        <OverlayTrigger style={(suggestionTitle.length != 0) && (suggestionDescription.length != 0) ? { display: "none" } : { cursor: "pointer" }}
                            placement="left"
                            overlay={
                                <Tooltip id={`tooltip-top`} style={(suggestionTitle.length != 0) && (suggestionDescription.length != 0) ? { display: "none" } : { cursor: "pointer" }}>
                                    {(suggestionTitle.length != 0) && (suggestionDescription.length != 0) ?
                                        <div style={{ display: "none" }}>

                                        </div>
                                        : <div className='tool-text'>
                                            Please add title and description
                                        </div>
                                    }
                                </Tooltip>
                            }
                        >
                            {(suggestionTitle.length != 0) && (suggestionDescription.length != 0) ?
                                <div className='btn-outer transform translate-x-6'>
                                    <div className='btn-1' style={{ width: "120px", height: "30px" }}>
                                    </div>
                                    <Button
                                        variant="default"
                                        className="btn-2" style={{ width: "120px", height: "30px", lineHeight: "12px" }}
                                        onClick={() => editSuggestionById()}
                                    >Submit</Button>
                                </div>
                                :
                                <div className='btn-outer transform translate-x-6'>
                                    <div className='btn-1' style={{ width: "120px", height: "30px" }}>
                                    </div>
                                    <Button
                                        disabled={true}
                                        variant="default"
                                        className="btn-2" style={{ width: "120px", height: "30px", lineHeight: "12px", color: '#222' }}
                                    >Submit</Button>
                                </div>
                            }
                        </OverlayTrigger>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default EditSuggestionPopup;

import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { config } from '../Config/Config';
import rating1 from '../images/rating1.png';
import rating2 from '../images/rating2.png';
import rating3 from '../images/rating3.png';
import rating4 from '../images/rating4.png';
import rating5 from '../images/rating5.png';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import arrowLeft from "../images/arrow-left-black.svg"
import { decrypt } from '../utils';


function DetailedReview({ closePopUp, review, handleReport, handleHighlight, userRole }) {

    const [feedback, setFeedback] = useState()
    const [loading, setLoading] = useState(false)
    const [questions, setQuestions] = useState([])

    const getFeedback = async () => {
        setLoading(true)
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            feedbackId: review?.feedbackId,
        }
        axios.post(config.userUrl + "admin/getDetailedFeedback", payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                console.log(response.data)
                if (response.data.status === true) {
                    setFeedback(response.data.feedbacks[0])
                    setQuestions(response.data.feedbacks[0]?.responses)
                }
                setLoading(false)
            })
            .catch(function (error) {
                setLoading(false)
                console.log("Error", error);
            });

    }

    useEffect(() => {
        if (typeof review?.feedbackId == 'string')
            getFeedback()
    }, []);


    return (
        <div className='fixed inset-0 bg-transparent z-[1100] grid place-items-center'>
            <div onClick={closePopUp} className='fixed inset-0 bg-black bg-opacity-50'></div>
            <div className='p-10 bg-gray-100 relative z-10 rounded-lg w-full max-w-7xl h-[90dvh] overflow-y-auto overflow-x-hidden'>
                {
                    loading
                        ?
                        <h1 className='text-3xl font-medium text-gray-400 text-center mt-20'>Loading ...</h1>
                        :
                        <>
                            <div className='flex flex-col max-w-2xl mx-auto'>
                                <div className='flex items-center justify-between relative'>
                                    <div className=' absolute top-3 -left-32'>
                                        <img src={arrowLeft} alt='' onClick={() => closePopUp()} className='cursor-pointer' />
                                    </div>
                                    <div>
                                        <h1 className='text-2xl font-medium'>{review?.userName || 'Anonymous'}</h1>
                                        <h1 className='mb-6'>{moment(review?.updatedAt).format("DD/MM/YY")}</h1>
                                    </div>

                                </div>
                                {
                                    typeof review.feedbackId == 'string'
                                        ?
                                        questions && questions.map((ques, i) => {
                                            if (ques[i + 1]["type"] === "mcq") {
                                                return (
                                                    <div key={i + "mcq"} className='bg-white h-max px-4 py-8 rounded-xl mb-4'>
                                                        <p className='text-base text-black font-semibold'>1. {ques[i + 1]["question"]}</p>

                                                        <form className='flex flex-col gap-3 mt-2'>
                                                            {ques[i + 1].options.map((option, index) => {
                                                                return (
                                                                    <label className='flex gap-3' key={index + "mcqoptions"}>
                                                                        <input type="checkbox" name={ques[i + 1]["question"]} value={option} className='accent-orange-600' checked={ques[i + 1].response?.split(",").includes(option) || ques[i + 1].resposne?.split(",").includes(option)} readOnly />
                                                                        {option}
                                                                    </label>
                                                                )
                                                            })}
                                                        </form>
                                                        <div className={`${ques[i + 1]["required"] ? "mt-3 flex gap-8 items-center" : "hidden"}`}>
                                                            <div className='w-2 h-2 bg-orange-600 border'></div>
                                                            <p className='text-sm text-black font-medium'>Respondents must answer this question</p>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            else if (ques[i + 1]["type"] === "singlechoice") {
                                                return (
                                                    <div key={i + "singlechoice"} className='bg-white h-max px-4 py-8 rounded-xl mb-4'>
                                                        <p className='text-base text-black font-semibold'>{i + 1}. {ques[i + 1]["question"]}</p>

                                                        <form className='flex flex-col gap-3 mt-2'>
                                                            {ques[i + 1].options.map((option, index) => {
                                                                return (
                                                                    <label className='flex gap-3' key={index + "singleoptions"}>
                                                                        <input type="radio" name={ques[i + 1]["question"]} value={option} className='accent-orange-600' checked={ques[i + 1].response == option} readOnly />
                                                                        {option}
                                                                    </label>
                                                                )
                                                            })}
                                                        </form>
                                                        <div className={`${ques[i + 1]["required"] ? "mt-3 flex gap-8 items-center" : "hidden"}`}>
                                                            <div className='w-2 h-2 bg-orange-600 border'></div>
                                                            <p className='text-sm text-black font-medium'>Respondents must answer this question</p>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            else if (ques[i + 1]["type"] === "rating") {
                                                return (
                                                    <div key={i + "rating"} className='bg-white h-max px-4 py-8 rounded-xl mb-4'>
                                                        <p className='text-base text-black font-semibold'>{i + 1}. {ques[i + 1]["question"]}</p>
                                                        {ques[i + 1]["scaleType"] === "Yes-No" ?
                                                            <div className='flex w-full justify-center gap-8'>
                                                                <div className='flex items-center gap-2'>
                                                                    <span className='w-4 h-4 rounded-full border'></span>
                                                                    <p>Yes</p>
                                                                </div>
                                                                <div className='flex items-center gap-2'>
                                                                    <span className='w-4 h-4 rounded-full border'></span>
                                                                    <p>Uncertain</p>
                                                                </div>
                                                                <div className='flex items-center gap-2'>
                                                                    <span className='w-4 h-4 rounded-full border'></span>
                                                                    <p>No</p>
                                                                </div>

                                                            </div> :
                                                            <div className='flex my-4'>
                                                                <div className='flex w-32 gap-2 items-center'>
                                                                    <img src={ques[i + 1]["scoringDirection"] === "Ascending" ? rating1 : rating5} alt='' className='w-10' />
                                                                    <p>{ques[i + 1]["scoringDirection"] === "Ascending" ? ques[i + 1]["ratingDesc"][0] : ques[i + 1]["ratingDesc"][4]}</p>
                                                                </div>
                                                                <div className='flex w-32 gap-2 items-center'>
                                                                    <img src={ques[i + 1]["scoringDirection"] === "Ascending" ? rating2 : rating4} alt='' className='w-10' />
                                                                    <p>{ques[i + 1]["scoringDirection"] === "Ascending" ? ques[i + 1]["ratingDesc"][1] : ques[i + 1]["ratingDesc"][3]}</p>
                                                                </div>
                                                                <div className='flex w-32 gap-2 items-center'>
                                                                    <img src={rating3} alt='' className='w-10' />
                                                                    <p>{ques[i + 1]["ratingDesc"][2]}</p>
                                                                </div>
                                                                <div className='flex w-32 gap-2 items-center'>
                                                                    <img src={ques[i + 1]["scoringDirection"] === "Ascending" ? rating4 : rating2} alt='' className='w-10' />
                                                                    <p>{ques[i + 1]["scoringDirection"] === "Ascending" ? ques[i + 1]["ratingDesc"][3] : ques[i + 1]["ratingDesc"][1]}</p>
                                                                </div>
                                                                <div className='flex w-32 gap-2 items-center'>
                                                                    <img src={ques[i + 1]["scoringDirection"] === "Ascending" ? rating5 : rating1} alt='' className='w-10' />
                                                                    <p>{ques[i + 1]["scoringDirection"] === "Ascending" ? ques[i + 1]["ratingDesc"][4] : ques[i + 1]["ratingDesc"][0]}</p>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className={`${ques[i + 1]["required"] ? "mt-3 flex gap-8 items-center" : "hidden"}`}>
                                                            <div className='w-2 h-2 bg-orange-600 border'></div>
                                                            <p className='text-sm text-black font-medium'>Respondents must answer this question</p>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            else if (ques[i + 1]["type"] === "textfield") {
                                                return (
                                                    <div key={i + "textfield"} className='bg-white h-max px-4 py-8 rounded-xl mb-4'>
                                                        <p className='text-base text-black font-semibold'>{i + 1}. {ques[i + 1]["question"]}</p>
                                                        <div className='border rounded-lg w-full flex flex-col gap-2 my-2'>
                                                            <textarea
                                                                className="p-2 min-h-[7rem] resize-none w-full focus:outline-none bg-white"
                                                                placeholder="Enter additional feedback here"
                                                                readOnly
                                                                value={ques[i + 1].response?.details || ques[i + 1].response || 'None'}
                                                            />
                                                            <div className='flex justify-between p-2'>
                                                                {/* <img src={addFileIcon} alt='' className='w-6' /> */}
                                                                <p className='text-gray-400'>{ques[i + 1]["wordLimit"]}</p>
                                                            </div>
                                                        </div>
                                                        <div className={`${ques[i + 1]["required"] ? "mt-3 flex gap-8 items-center" : "hidden"}`}>
                                                            <div className='w-2 h-2 bg-orange-600 border'></div>
                                                            <p className='text-sm text-black font-medium'>Respondents must answer this question</p>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            else if (ques[i + 1]["type"] === "generic") {
                                                return (
                                                    <div key={i + "textfield"} className='bg-white h-max px-4 py-8 rounded-xl mb-4'>
                                                        <p className="text-2xl">Generic Question</p>
                                                        <p className='my-3 text-black text-lg '>Rate your overall Experience for this version</p>
                                                        <div className='flex w-full mt-2 my-4'>
                                                            <div className='flex w-48 gap-2 items-center'>
                                                                <img src={rating5} alt='' className={`w-10 ${ques[i + 1]?.response?.rating == 1 || ques[i + 1]?.resposne?.rating == 1 ? 'bg-orange-600' : ''}`} />
                                                                <p>Love it!</p>
                                                            </div>
                                                            <div className='flex w-48 gap-2 items-center'>
                                                                <img src={rating4} alt='' className={`w-10 ${ques[i + 1]?.response?.rating == 2 || ques[i + 1]?.resposne?.rating == 2 ? 'bg-orange-600' : ''}`} />
                                                                <p>Like it!</p>
                                                            </div>
                                                            <div className='flex w-48 gap-2 items-center'>
                                                                <img src={rating3} alt='' className={`w-10 ${ques[i + 1]?.response?.rating == 3 || ques[i + 1]?.resposne?.rating == 3 ? 'bg-orange-600' : ''}`} />
                                                                <p>It's OK!</p>
                                                            </div>
                                                            <div className='flex w-48 gap-2 items-center'>
                                                                <img src={rating2} alt='' className={`w-10 ${ques[i + 1]?.response?.rating == 4 || ques[i + 1]?.resposne?.rating == 4 ? 'bg-orange-600' : ''}`} />
                                                                <p>Hate it!</p>
                                                            </div>
                                                            <div className='flex w-48 gap-2 items-center'>
                                                                <img src={rating1} alt='' className={`w-10 ${ques[i + 1]?.response?.rating == 5 || ques[i + 1]?.resposne?.rating == 5 ? 'bg-orange-600' : ''}`} />
                                                                <p>Fedup!</p>
                                                            </div>
                                                        </div>
                                                        <textarea
                                                            className="border border-gray-400 rounded p-2 min-h-[12rem] mb-2 resize-none w-full border-gray focus:outline-none"
                                                            placeholder="Additional Thoughts"
                                                            readOnly
                                                            value={ques[i + 1]?.response?.details || ques[i + 1]?.resposne?.details || 'None'}
                                                        />
                                                        <div className={`${ques[i + 1]["required"] ? "mt-3 flex gap-8 items-center" : "hidden"}`}>
                                                            <div className='w-2 h-2 bg-orange-600 border'></div>
                                                            <p className='text-sm text-black font-medium'>Respondents must answer this question</p>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                        :
                                        <>
                                            <div className='bg-white h-max px-4 py-8 rounded-xl mb-4'>
                                                <p className="text-2xl">Generic Question</p>
                                                <p className='my-3 text-black text-lg '>Rate your overall Experience for this version</p>
                                                <div className='flex w-full mt-2 my-4'>
                                                    <div className='flex w-48 gap-2 items-center'>
                                                        <img src={rating5} alt='' className={`w-10 ${review?.rating == 1 ? 'bg-orange-600' : ''}`} />
                                                        <p>Love it!</p>
                                                    </div>
                                                    <div className='flex w-48 gap-2 items-center'>
                                                        <img src={rating4} alt='' className={`w-10 ${review?.rating == 2 ? 'bg-orange-600' : ''}`} />
                                                        <p>Like it!</p>
                                                    </div>
                                                    <div className='flex w-48 gap-2 items-center'>
                                                        <img src={rating3} alt='' className={`w-10 ${review?.rating == 3 ? 'bg-orange-600' : ''}`} />
                                                        <p>It's OK!</p>
                                                    </div>
                                                    <div className='flex w-48 gap-2 items-center'>
                                                        <img src={rating2} alt='' className={`w-10 ${review?.rating == 4 ? 'bg-orange-600' : ''}`} />
                                                        <p>Hate it!</p>
                                                    </div>
                                                    <div className='flex w-48 gap-2 items-center'>
                                                        <img src={rating1} alt='' className={`w-10 ${review?.rating == 5 ? 'bg-orange-600' : ''}`} />
                                                        <p>Fedup!</p>
                                                    </div>
                                                </div>
                                                <textarea
                                                    className="border border-gray-400 rounded p-2 min-h-[12rem] mb-2 resize-none w-full border-gray focus:outline-none"
                                                    placeholder="Additional Thoughts"
                                                    readOnly
                                                    value={review?.comment || 'None'}
                                                />

                                            </div>
                                        </>
                                }
                            </div>

                            <div className='p-6 w-full max-w-xs absolute top-10 right-5 flex flex-col items-center justify-center'>

                                {userRole != 'moderator' && typeof handleHighlight == 'function' && <div>
                                    {
                                        review?.highlighted
                                            ?
                                            <div className='relative group' onClick={handleHighlight}>
                                                <button className='text-[#FF94CB] border-2 bg-white py-2 px-4 rounded-lg inline-block group-hover:hidden' style={{ borderColor: '#FF94CB', outline: '#FF94CB' }}>Review Highlighted</button>
                                                <button className='text-[#E6570E] border-2 bg-white py-2 px-4 rounded-lg items-center gap-5 hidden group-hover:flex' style={{ borderColor: '#E6570E', outline: '#E6570E' }}>
                                                    <svg width="32" height="24" viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M31.5763 7.3458C31.8072 10.7275 30.4222 17.4908 20.9584 25.0996C8.49372 23.6201 3.41551 18.5476 1.5689 15.5886C0.876422 14.5319 0.645609 13.8978 0.645609 13.6864C-0.508524 9.88206 1.79975 5.65496 5.95462 4.59818C10.1095 3.54141 14.726 5.65496 15.8801 9.45935C14.726 5.65496 17.0343 1.42786 21.1892 0.371086C25.344 -0.685688 29.9606 1.42786 31.1147 5.23225C31.3455 5.23225 31.5763 6.07767 31.5763 7.3458Z" fill="#E6570E" />
                                                        <path d="M14.8292 8.23651L20.6243 12.2606L18.3705 16.2239L21.6346 18.0891L19.7999 25.099" stroke="white" stroke-width="3" />
                                                    </svg>

                                                    <span>Unhighlight</span>
                                                </button>

                                            </div>
                                            :
                                            <button className='bg-[#FF94CB] text-white py-2 px-4 rounded-lg' onClick={handleHighlight}>Highlight review</button>
                                    }
                                </div>}

                                {
                                    typeof handleReport == 'function'
                                        ?
                                        <h1 className='underline text-orange text-lg text-center mt-2 cursor-pointer' onClick={() => handleReport()}>Report Review</h1>
                                        :
                                        <></>
                                }

                            </div>
                        </>
                }
            </div>

        </div>
    )
}

export default DetailedReview
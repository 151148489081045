import { React, Component } from 'react';
import { Button, Form, Modal, Dropdown, Card, Row, Col, OverlayTrigger, Tooltip, Popover } from "react-bootstrap";
import { Link, NavLink, Redirect } from "react-router-dom";
import fedduplogo from "../images/fedduplogo.png";
import NavBar from "../NavBar/NavBar";
import './Profile.css';
import safety from '../images/safety.svg';
import profileicon from '../images/profileicon.svg';
import feedsettings from '../images/feedsettings.svg';
import usersettings from '../images/usersettings.svg';
import deactivate from '../images/deactivate.png';
import { Tags } from "../UsersFeed/TrendingTags";
import { Moderators } from "../CompanyFeed/Moderators";
import axios from 'axios';
import { config } from '../Config/Config';
import NavBar1 from '../NavBar/NavBar1';

export default class SafetyPrivacy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            twoFactor: false,
            security: false,
            personalize1: true,
            personalize2: true,
            personalize3: true,
            personalize4: true,
            manage: true
        }
    }
    changeTwoFactor(e) {
        this.setState({
            twoFactor: !this.state.twoFactor
        });
    }
    changeSecurity(e) {
        this.setState({
            security: !this.state.security
        });
    }
    changePersonalize1(e) {
        this.setState({
            personalize1: !this.state.personalize1
        });
    }
    changePersonalize2(e) {
        this.setState({
            personalize2: !this.state.personalize2
        });
    }
    changePersonalize3(e) {
        this.setState({
            personalize3: !this.state.personalize3
        });
    }
    changePersonalize4(e) {
        this.setState({
            personalize4: !this.state.personalize4
        });
    }
    changeManage(e) {
        this.setState({
            manage: !this.state.manage
        });
    }
    render() {
        return (
            <div>
                <NavBar1 />
                <div id="background">
                    <div className="usersettings-l-r"><br /><br />

                        <div className="user-settings-heading">User Settings</div><br />
                        <Row className="row-width">
                            <Col md={3}>
                                <Card className="card-usersettings">
                                    <NavLink to="/usersettings/account" activeClassName="active-user-text" className="link"><div className="user-settings-padding bg-user-text forgot-text-sm"><img src={usersettings} style={{ width: "20px" }} />&nbsp;Account</div></NavLink>
                                    {/* {(localStorage.getItem("role") == "admin")||(localStorage.getItem("role") == "subAdmin") ?
                                        <NavLink to="/usersettings/companyprofile" activeClassName="active-user-text " className="link"><div className="user-settings-padding bg-user-text forgot-text-sm"><img src={profileicon} style={{ width: "20px" }} /> &nbsp;Company Profile</div></NavLink>
                                        : null} */}
                                   
                                    <NavLink to="/usersettings/safety&privacy" activeClassName="active-user-text" className="link"><div className="user-settings-padding bg-user-text forgot-text-sm"><img src={safety} style={{ width: "20px" }} /> &nbsp;Safety & Privacy</div></NavLink>
                                    <NavLink to="/usersettings/feedsettings" activeClassName="active-user-text" className="link"><div className="user-settings-padding bg-user-text forgot-text-sm"><img src={feedsettings} style={{ width: "20px" }} /> &nbsp;Feed Settings</div></NavLink>
                                </Card>
                            </Col>
                            <Col md={9}>
                                <Card>
                                    <div className="card-user-padding">
                                        <h6 className="account-settings">Safety & Privacy</h6>
                                        <div className="account-preferrences">Security</div>
                                        <div style={{ marginTop: "-0.5rem" }}><hr /></div>
                                        <form>
                                            <label className="label-text">Two Factor Authentication</label>
                                            <label className="float"><input ref="switch1" name="twoFactor" checked={this.state.twoFactor} onChange={e => this.changeTwoFactor(e)} className="switch1" type="checkbox" />
                                                <div>
                                                    <div></div>
                                                </div>
                                            </label>
                                            <div className='username'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                                            <br />
                                            <label className="label-text">Security Question</label><label className="float">
                                                <input ref="switch1" name="security" checked={this.state.security} onChange={e => this.changeSecurity(e)} className="switch1" type="checkbox" />
                                                <div>
                                                    <div></div>
                                                </div>
                                            </label>
                                            <div className='username'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                                            <br />
                                            <div className="account-preferrences">Safety & Privacy</div>
                                            <div style={{ marginTop: "-0.5rem" }}><hr /></div>
                                            <label className="label-text">Personalize all of FeddUp based on the outbound links you click on</label>
                                            <label className="float"><input ref="switch1" name="personalize1" checked={this.state.personalize1} onChange={e => this.changePersonalize1(e)} className="switch1" type="checkbox" />
                                                <div>
                                                    <div></div>
                                                </div>
                                            </label>
                                            <div className='username'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                                            <br />
                                            <label className="label-text">Personalize ads based on information from our partners</label><label className="float">
                                                <input ref="switch1" name="personalize2" checked={this.state.personalize2} onChange={e => this.changePersonalize1(e)} className="switch1" type="checkbox" />
                                                <div>
                                                    <div></div>
                                                </div>
                                            </label>
                                            <div className='username'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                                            <br />
                                            <label className="label-text">Personalize ads based on your activity with our partners</label>
                                            <label className="float"><input ref="switch1" name="personalize3" checked={this.state.personalize3} onChange={e => this.changePersonalize3(e)} className="switch1" type="checkbox" />
                                                <div>
                                                    <div></div>
                                                </div>
                                            </label>
                                            <div className='username'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                                            <br />
                                            <label className="label-text">Personalize recommendations based on your activity with our partners</label><label className="float">
                                                <input ref="switch1" name="personalize4" checked={this.state.personalize4} onChange={e => this.changePersonalize4(e)} className="switch1" type="checkbox" />
                                                <div>
                                                    <div></div>
                                                </div>
                                            </label>
                                            <div className='username'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                                            <br />
                                            <label className="label-text">Manage third-party app authorization</label>
                                            <label className="float"><input ref="switch1" name="manage" checked={this.state.manage} onChange={e => this.changeManage(e)} className="switch1" type="checkbox" />
                                                <div>
                                                    <div></div>
                                                </div>
                                            </label>
                                            <div className='username'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                                        </form>
                                    </div>
                                </Card>
                            </Col>
                        </Row >

                    </div > <br />
                </div >
            </div >
        );
    }
}


import React, { useEffect, useState } from 'react'
import NavBar1 from '../NavBar/NavBar1'
import arrowLeft from "../images/arrow-left-black.svg"
import axios from 'axios'
import { config } from '../Config/Config';
import { Button, Card, Col, Dropdown, Form, Modal, Row, Table } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import filterIcon from "../images/filter.svg";
import downvoteb from '../images/downvote-b.svg';
import deleteConfirmtion from "../images/delete-confirmation.svg"
import correctTick from "../images/correct-tick.svg";
import { decrypt } from '../utils';

function FeddbackForms() {
    const { productid } = useParams();
    const history = useHistory();
    const [productIcon, setProductIcon] = useState("");
    const [productType, setProductType] = useState("");
    const [productName, setProductName] = useState("");
    const [showCurrentForm, setShowCurrentForm] = useState(false);
    const [newForm, setNewForm] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [versions, setVersions] = useState([])
    const [allForms, setAllForms] = useState([]);
    const [feedbackForms, setFeedbackForms] = useState([])
    const [versionDetails, setVersionDetails] = useState([])
    const [formName, setFormName] = useState("");
    const [selectedForm, setSelectedForm] = useState("");
    const [formNameError, setFormNameError] = useState("")
    const [filteredResults, setFilteredResults] = useState([])
    const [currentForm, setCurrentForm] = useState([])
    const [currentFormVersion, setCurrentFormVersion] = useState([])
    const [openStates, setOpenStates] = useState([]);
    const [showFilter, setShowFilter] = useState(false)
    const [sortOption, setSortOption] = useState('All');
    const [sortOption2, setSortOption2] = useState('All');
    const [sortedData, setSortedData] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteFormId, setDeleteFormId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [lastForms, setLastForms] = useState([])

    useEffect(() => {
        getAllFeedbackForms();
    }, [])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, [productName])
    // getLastThreeVersionForm



    const getLastThreeVersionForm = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "productId": productid,
        }
        axios.post(config.userUrl + "admin/getLastThreeVersionForm", payload, { headers: headers })
            .then((response) => {
                setLastForms(response.data.data)
                console.log(response)
            })
    }

    useEffect(() => {
        if (productid) {
            getLastThreeVersionForm();
        }
    }, [productid])

    const getAllFeedbackForms = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "productId": productid,
        }
        axios.post(config.userUrl + "admin/getAllProductForms", payload, { headers: headers })
            .then((response) => {
                setProductIcon(response.data.productDetails.productIcon);
                setProductName(response.data.productDetails.productName);
                setProductType(response.data.productDetails.productType);
                setCurrentForm(response.data.currentForm)
                setCurrentFormVersion(response.data.currentVersionDetails?.reverse());
                setAllForms(response.data.allForms);
                return Promise.all(
                    response.data.productForms.map(product => {
                        var payload = {
                            "formId": product.formId,
                            "productId": productid,
                        }
                        return axios.post(config.userUrl + "admin/getFeedbackForm", payload, { headers: headers })
                    })
                );
            })
            .then(responses => {
                // Set product details in state
                let currentForms = (responses.map(response => response.data.feedbackForms));
                console.log("Current forms", currentForms);
                currentForms.forEach((form) => form.versionDetails.sort((a, b) => new Date(a.updatedAt) > new Date(b.updatedAt)));
                console.log("Current forms", currentForms);
                setFeedbackForms(currentForms);
                setSortedData(currentForms)
                setOpenStates(Array(currentForms.length).fill(false))
                setShowDeleteModal(false);
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setIsLoading(false)
            });
    }
    const removeFeedbackForm = () => {
        setIsLoading(true);
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "formId": deleteFormId
        }
        setShowDeleteModal(false);
        axios.post(config.userUrl + "admin/deleteFeedbackForm", payload, { headers: headers })
            .then((response) => {
                console.log(response.data);
                getAllFeedbackForms();
            })
            .catch((err) => {
                console.log(err)
                setIsLoading(false)
            });
    }

    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        const currentData = feedbackForms
        if (searchValue !== '') {
            const filteredData = currentData.filter((item) => {
                return item["formName"].toLowerCase().includes(searchValue.toLowerCase())
            })
            setFilteredResults(filteredData)
        }
        else {
            setFilteredResults(currentData)
        }
    }
    const handleButtonClick = (rowIndex) => {
        setOpenStates(openStates.map((isOpen, index) => index === rowIndex ? !isOpen : isOpen));
    }
    const handleSubmit = () => {
        if (!formName) {
            setFormNameError("Form name cannot be empty")
            return;
        }
        if (formName.length < 3) {
            setFormNameError("Form name must be greater than 3 characters")
            return;
        }
        let duplicateCheck = false;
        feedbackForms.forEach(form => {
            if (form.formName === formName) {
                duplicateCheck = true
            }
        })
        if (duplicateCheck) {
            setFormNameError("Form name must not be same as existing forms")
            return;
        }
        history.push("/admin/newfeedbackform", { name: formName, id: selectedForm, versions: versions, productid });
    };
    const handleViewFormClick = (id) => {
        history.push("/admin/viewform/id", { name: formName, id, productid });
    }
    const handleDeleteFormClick = (id) => {
        setDeleteFormId(id);
        setShowDeleteModal(true)
    }
    useEffect(() => {
        filter()
    }, [sortOption, sortOption2])

    const filterToggle = () => {
        if (sortOption || sortOption2)
            filter()
        setShowFilter(!showFilter)
    }
    const filter = () => {
        let formsArry = [...feedbackForms]
        let sortedForms = []

        if (sortOption2.length > 0) {
            if (sortOption2 === "Draft") {
                sortedForms = formsArry.filter(form => form.status === "Saved as Draft")
            }
            else if (sortOption2 === "Published") {
                sortedForms = formsArry.filter(form => form.status !== "Saved as Draft")
            }
            else {
                sortedForms = [...feedbackForms];
            }
            formsArry = [...sortedForms];
        }
        if (sortOption.length > 0) {
            if (sortOption === "Green") {
                sortedForms = formsArry.filter(form => form.status === "In Use" && form.versionDetails.some((detail) => detail.latestVersion && detail.beingUsed));
            }
            else if (sortOption === "Yellow") {
                sortedForms = formsArry.filter(form => form.status === "Not In Use" && form.versionDetails.some((detail) => detail.latestVersion));
            }
            else if (sortOption === "Purple") {
                sortedForms = formsArry.filter(form => (form.status === "In Use" || form.status === "In Use(Previous version)") && form.versionDetails.every((detail) => detail.beingUsed && !detail.latestVersion));
            }
            else if (sortOption === "Orange") {
                sortedForms = formsArry.filter(form => form.status !== "In Use" && form.status !== "In Use(Previous version)" && form.versionDetails.every((detail) => !detail.latestVersion));
            }
            else {
                sortedForms = [...feedbackForms];
            }

            formsArry = [...sortedForms];
        }

        setSortedData(formsArry);
    }
    const getStatus = (formStatus, details) => {
        if (formStatus === "Saved as Draft") {
            return formStatus;
        }
        let status = "Not In Use";
        for (const element of details) {
            if (element.beingUsed && element.latestVersion) {
                status = "In Use"
                break;
            }
            if (element.beingUsed && !element.latestVersion) {
                status = "In Use Previous version " + element.version
            }
            else if (!element.beingUsed && element.latestVersion) {
                status = "Previously used with current version " + element.version
            }
        }
        return status;
    }
    const getBackGroundColor = (formStatus, details) => {
        let color = "bg-orange-600"

        if (formStatus === "Saved as Draft") {
            return color;
        }
        for (const element of details) {
            if (element.beingUsed && element.latestVersion) {
                color = "bg-green-700"
                break;
            }
            if (element.beingUsed && !element.latestVersion) {
                color = "bg-purple-800"
            }
            else if (!element.beingUsed && element.latestVersion) {
                color = "bg-orange-400"
            }
        }
        return color;
    }

    // console.log(currentForm) // get active version in this 

    return (
        <div className='bg-gray-100 pb-16 min-h-screen'>
            <NavBar1 />
            <div className=' pl-16 pr-24 mt-20 pt-6'>
                <div className='flex gap-4 items-center'>
                    <img src={arrowLeft} alt='' onClick={() => history.goBack()} className='cursor-pointer' />
                    <div className='bg-white rounded-full' style={{ width: "100px", height: "100px" }}>
                        <img src={productIcon} alt='' className='w-full h-full rounded-full' />
                    </div>
                    <div className='flex flex-col'>
                        <span className='text-2xl font-semibold'>{productName}</span>
                        <span className='text-xl font-medium'>{productType === 1 ? "Desktop Application" : "Web Application"}</span>
                    </div>
                </div>
                <div className='flex justify-end items-center mt-1 mx-4'>
                    <div className='btn-outer'>
                        <div className='btn-1' style={{ width: "120px", height: "30px" }}></div>
                        <Button
                            variant="default"
                            type="submit"
                            className="btn-2"
                            style={{ width: "120px", height: "30px", fontSize: "14px", lineHeight: "4px" }}
                            onClick={() => setNewForm(true)}
                        >New Form
                        </Button>
                    </div>
                </div>
                <div className='mt-4 ml-4 mr-3'>
                    <p className='text-2xl text-black font-semibold mt-4 '>Feddback Forms</p>
                    {
                        lastForms[0] ?
                            <div className='flex items-center mt-4 bg-[#BBEABA] rounded-lg py-3 px-10 justify-between'>
                                <div className='font-medium'>
                                    <p className='text-lg'>{lastForms[0]?.formName || "No Form Selected"}</p>
                                    <p> {lastForms[0]?.status}</p>
                                </div>
                                <h1 className='text-lg font-medium'>Version : {lastForms[0]?.version}</h1>
                                <span>
                                    {lastForms[0]?.id &&
                                        <Button
                                            variant="default" size="sm"
                                            style={{ width: "100px", height: "30px", lineHeight: "10px", fontSize: "14px" }}
                                            className='bg-orange-400 rounded-lg text-white'
                                            onClick={() => handleViewFormClick(lastForms[0]?.id)}
                                        >
                                            View Form
                                        </Button>
                                    }
                                </span>
                            </div>
                            :
                            <></>
                    }
                    <div className='flex gap-6'>
                        {
                            lastForms[1] ?
                                <div className='flex items-center my-3 bg-[#FFEA9F] rounded-lg py-3 px-10 justify-between flex-grow'>
                                    <div className='font-medium'>
                                        <p className='text-lg'>{lastForms[1]?.formName || "No Form Selected"}</p>
                                        <p> {lastForms[1]?.status}</p>
                                    </div>
                                    <h1 className='text-lg font-medium'>Version : {lastForms[1]?.version}</h1>
                                    <span>

                                        {lastForms[1]?.id && <Button
                                            variant="default" size="sm"
                                            style={{ width: "100px", height: "30px", lineHeight: "10px", fontSize: "14px" }}
                                            className='bg-orange-400 rounded-lg text-white'
                                            onClick={() => handleViewFormClick(lastForms[1]?.id)}
                                        >
                                            View Form
                                        </Button>}
                                    </span>
                                </div>
                                :
                                <></>
                        }
                        {
                            lastForms[2] ?
                                <div className='flex items-center my-3 bg-[#FFDBBA] rounded-lg py-3 px-10 justify-between flex-grow'>
                                    <div className='font-medium'>
                                        <p className='text-lg'>{lastForms[2]?.formName || "No Form Selected"}</p>
                                        <p> {lastForms[2]?.status}</p>
                                    </div>
                                    <h1 className='text-lg font-medium'>Version : {lastForms[2]?.version}</h1>
                                    <span>
                                        {lastForms[2]?.id &&
                                            <Button
                                                variant="default" size="sm"
                                                style={{ width: "100px", height: "30px", lineHeight: "10px", fontSize: "14px" }}
                                                className='bg-orange-400 rounded-lg text-white'
                                                onClick={() => handleViewFormClick(lastForms[2]?.id)}
                                            >
                                                View Form
                                            </Button>}
                                    </span>
                                </div>
                                :
                                <></>
                        }
                    </div>
                    {/* <div className='flex items-center mt-4 bg-green-300 rounded-lg'>
                        {currentForm.length === 0 ?
                            <div className='flex items-center justify-center p-4 w-full'>
                                <p className='text-lg font-medium'>No feedback form available for current version</p>
                            </div>
                            : <Row className='flex items-center pl-4 py-4 w-full'>
                                <Col md={3} className='font-semibold'>
                                    <p>{currentForm ? currentForm[0]?.formName : "Not Available"}</p>
                                    <p>{currentForm ? currentForm[0]?.status : ""}</p>
                                </Col>
                                <Col md={4} className='font-semibold'>
                                    {currentFormVersion && currentFormVersion.length < 4 ?
                                        <div className='flex'>
                                            {currentFormVersion.length === 1 ? <p>{currentFormVersion[0].version}</p> :
                                                currentFormVersion.length === 2 ? <p>{currentFormVersion[0].version},{currentFormVersion[1].version}</p> :
                                                    currentFormVersion.length === 3 ? <p>{currentFormVersion[0].version},{currentFormVersion[1].version},{currentFormVersion[2].version}</p>
                                                        : <p>No current version form available</p>
                                            }
                                        </div>
                                        :
                                        currentFormVersion && currentFormVersion.length > 3 ?
                                            <div className='flex'>
                                                <p>{currentFormVersion[0].version},{currentFormVersion[1].version},{currentFormVersion[2].version}&{currentFormVersion.length - 3}more</p>
                                            </div>
                                            : <p>No versions available</p>
                                    }
                                </Col>
                                <Col md={2} className='font-semibold'>
                                    {currentForm ? currentForm[0]?.questions?.length : "No"} Questions
                                </Col>
                                <Col md={2}>
                                    <Button
                                        variant="default" size="sm"
                                        style={{ width: "100px", height: "30px", lineHeight: "10px", fontSize: "14px" }}
                                        className='bg-orange-400 rounded-lg text-white'
                                        onClick={() => handleViewFormClick(currentForm[0]?.formId)}
                                    >
                                        View Form
                                    </Button>
                                </Col>
                                <Col md={1} className='font-semibold'>
                                    <div className='flex justify-end'>
                                    </div>
                                </Col>
                            </Row>
                        }
                    </div> */}
                    <div className="mt-3 flex justify-between">
                        <input
                            type="text"
                            placeholder="Search"
                            value={searchInput}
                            onChange={(e) => searchItems(e.target.value)}
                            id="search"
                            className="abc form-control ml-0 bg-white rounded-full placeholder-gray-500 text-black focus:text-black"
                            style={{ width: "460px", height: "45px", borderRadius: "25px", paddingLeft: "3rem" }}
                            aria-label="Search keyword"
                        />
                        <Dropdown align="end" show={showFilter} onToggle={filterToggle}>
                            <Dropdown.Toggle variant="default" className="text-white focus:outline-none focus:ring-0" >
                                <img src={filterIcon} alt='' className='' />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="border-orange-400 rounded-lg min-w-[12rem] z-20">
                                <span className='text-black px-2 text-lg'>Form Status</span>
                                <div className="flex gap-4 text-balck font-medium px-2 mb-3">
                                    <div className="relative w-6 h-6 rounded-full cursor-pointer" onClick={() => setSortOption("All")}>
                                        <div className="absolute top-0 left-0 w-1/2 h-1/2 rounded-tl-full bg-orange-400"></div>
                                        <div className="absolute top-0 right-0 w-1/2 h-1/2 rounded-tr-full bg-green-700"></div>
                                        <div className="absolute bottom-0 left-0 w-1/2 h-1/2 rounded-bl-full bg-orange-600"></div>
                                        <div className="absolute bottom-0 right-0 w-1/2 h-1/2 rounded-br-full bg-purple-800"></div>
                                        <img src={correctTick} alt="" className={`absolute w-5 h-6 ${sortOption === "All" ? "" : "hidden"}`} />
                                    </div>

                                    <div className='flex justify-center items-center w-6 h-6 bg-green-700 rounded-full cursor-pointer' onClick={() => setSortOption("Green")}>
                                        <img src={correctTick} alt='' className={`${sortOption === "Green" ? "" : "hidden"}`} />
                                    </div>
                                    <div className='flex justify-center items-center w-6 h-6 bg-orange-400 rounded-full cursor-pointer' onClick={() => setSortOption("Yellow")}>
                                        <img src={correctTick} alt='' className={`${sortOption === "Yellow" ? "" : "hidden"}`} />
                                    </div>
                                    <div className='flex justify-center items-center w-6 h-6 bg-purple-800 rounded-full cursor-pointer' onClick={() => setSortOption("Purple")}>
                                        <img src={correctTick} alt='' className={`${sortOption === "Purple" ? "" : "hidden"}`} />
                                    </div>
                                    <div className='flex justify-center items-center w-6 h-6 bg-orange-600 rounded-full cursor-pointer' onClick={() => setSortOption("Orange")}>
                                        <img src={correctTick} alt='' className={`${sortOption === "Orange" ? "" : "hidden"}`} />
                                    </div>
                                </div>
                                <span className='text-black px-2 mt-4 text-lg'>Form state</span>
                                <div className="flex flex-col gap-1 text-black font-medium px-2 mb-4">
                                    <div className='flex items-center gap-2'>
                                        <button
                                            className={`rounded-sm w-3 h-3 focus:outline-none ${sortOption2 === "All" ? 'bg-orange-600 text-white' : 'bg-gray-200'
                                                }`}
                                            onClick={() => setSortOption2("All")}
                                        >
                                        </button>
                                        <span>All</span>
                                    </div>
                                    <div className='flex items-center gap-2'>
                                        <button
                                            className={`rounded-sm w-3 h-3 focus:outline-none ${sortOption2 === "Published" ? 'bg-orange-600 text-white' : 'bg-gray-200'
                                                }`}
                                            onClick={() => setSortOption2("Published")}
                                        >
                                        </button>
                                        <span>Published</span>
                                    </div>
                                    <div className='flex items-center gap-2'>
                                        <button
                                            className={`rounded-sm w-3 h-3 focus:outline-none ${sortOption2 === "Draft" ? 'bg-orange-600 text-white' : 'bg-gray-200'
                                                }`}
                                            onClick={() => setSortOption2("Draft")}
                                        >
                                        </button>
                                        <span>Draft</span>
                                    </div>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    {searchInput.length > 1 ? filteredResults.map((form, index) => {
                        return (
                            <Row className='flex items-center pl-4 py-4 w-full' key={index}>
                                <Col md={3} className='font-semibold'>
                                    <p>{form.formName}</p>
                                    <p className='text-sm'>{getStatus(form.status, form.versionDetails)}</p>
                                </Col>
                                <Col md={4} className='font-semibold'>
                                    {form.versionDetails && form.versionDetails.length < 4 ?
                                        <div className='flex'>
                                            {form.versionDetails.length === 1 ? <p>{form.versionDetails[0].version}</p> :
                                                form.versionDetails.length === 2 ? <p>{form.versionDetails[0].version},{form.versionDetails[1].version}</p> :
                                                    form.versionDetails.length === 3 ? <p>{form.versionDetails[0].version},{form.versionDetails[1].version},{form.versionDetails[2].version}</p>
                                                        : <p>No version available</p>
                                            }
                                        </div>
                                        :
                                        form.versionDetails && form.versionDetails.length > 3 ?
                                            <div className='flex'>
                                                <p>{form.versionDetails[0].version},{form.versionDetails[1].version},{form.versionDetails[2].version}&{form.versionDetails.length - 3}more</p>
                                            </div>
                                            : <p>No version available</p>
                                    }
                                </Col>
                                <Col md={2} className='font-semibold'>
                                    {form.questions.length} Questions
                                </Col>
                                <Col md={2}>
                                    <Button
                                        variant="default" size="sm"
                                        style={{ width: "100px", height: "30px", lineHeight: "10px", fontSize: "14px" }}
                                        className='bg-orange-400 rounded-lg text-white'
                                        onClick={() => handleViewFormClick(form.id)}
                                    >
                                        View Form
                                    </Button>
                                </Col>
                                <Col md={1} className='font-semibold'>
                                    <div className='flex justify-between items-center'>
                                        <div className={`w-5 h-5 rounded-full cursor-pointer ${getBackGroundColor(form.status, form.versionDetails)} `}></div>
                                        {getStatus(form.status, form.versionDetails) == 'In Use' ? <></> : <Dropdown align="start" show={openStates[index]} onToggle={() => handleButtonClick(index)}>
                                            <Dropdown.Toggle variant="default" className="text-white focus:outline-none focus:ring-0" >
                                                <svg className="w-6 h-6" aria-hidden="true" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z">
                                                    </path>
                                                </svg>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className="border-black rounded-lg min-w-max px-2 z-20">
                                                <span className="cursor-pointer" onClick={() => handleDeleteFormClick(form.id)}>Delete this Form</span>
                                            </Dropdown.Menu>
                                        </Dropdown>}
                                    </div>
                                </Col>
                            </Row>
                        )
                    })
                        : sortedData.length === 0 ?
                            <Card className="flex w-full justify-center items-center mt-2 border-none bg-gray-100 p-10">
                                <center className="no-details-text">
                                    <img src={downvoteb} className="no-pages-img" />
                                    <br />No Feedback Forms
                                </center>
                            </Card> :
                            sortedData.map((form, index) => {
                                return (
                                    <Row className='flex items-center pl-4 py-4 w-full' key={index}>
                                        <Col md={3} className='font-semibold'>
                                            <p>{form.formName}</p>
                                            <p className='text-sm'>{getStatus(form.status, form.versionDetails)}</p>
                                        </Col>
                                        <Col md={4} className='font-semibold'>
                                            {form.versionDetails && form.versionDetails.length < 4 ?
                                                <div className='flex'>
                                                    {form.versionDetails.length === 1 ? <p>{form.versionDetails[0].version}</p> :
                                                        form.versionDetails.length === 2 ? <p>{form.versionDetails[0].version},{form.versionDetails[1].version}</p> :
                                                            form.versionDetails.length === 3 ? <p>{form.versionDetails[0].version},{form.versionDetails[1].version},{form.versionDetails[2].version}</p>
                                                                : <p>No version available</p>
                                                    }
                                                </div>
                                                :
                                                form.versionDetails && form.versionDetails.length > 3 ?
                                                    <div className='flex'>
                                                        <p>{form.versionDetails[0].version},{form.versionDetails[1].version},{form.versionDetails[2].version}&{form.versionDetails.length - 3}more</p>
                                                    </div>
                                                    : <p>No version available</p>
                                            }
                                        </Col>
                                        <Col md={2} className='font-semibold'>
                                            {form.questions.length} Questions
                                        </Col>
                                        <Col md={2}>
                                            <Button
                                                variant="default" size="sm"
                                                style={{ width: "100px", height: "30px", lineHeight: "10px", fontSize: "14px" }}
                                                className='bg-orange-400 rounded-lg text-white'
                                                onClick={() => handleViewFormClick(form.id)}
                                            >
                                                View Form
                                            </Button>
                                        </Col>
                                        <Col md={1} className='font-semibold'>
                                            <div className='flex justify-between items-center'>
                                                <div className={`w-5 h-5 rounded-full cursor-pointer ${getBackGroundColor(form.status, form.versionDetails)} `}></div>
                                                {getStatus(form.status, form.versionDetails) == 'In Use' ? <></> : <Dropdown align="start" show={openStates[index]} onToggle={() => handleButtonClick(index)}>
                                                    <Dropdown.Toggle variant="default" className="text-white focus:outline-none focus:ring-0" >
                                                        <svg className="w-6 h-6" aria-hidden="true" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z">
                                                            </path>
                                                        </svg>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className="border-black rounded-lg min-w-max px-2 z-20">
                                                        <span className="hover:bg-red-400 hover:font-bold rounded-md p-1 cursor-pointer" onClick={() => handleDeleteFormClick(form.id)}>Delete this Form</span>
                                                    </Dropdown.Menu>
                                                </Dropdown>}
                                            </div>
                                        </Col>
                                    </Row>
                                )
                            })
                    }
                </div>
            </div>
            <Modal show={newForm} onHide={() => { setFormName(""); setFormNameError(""); setSelectedForm(""); setNewForm(false) }} size="lg" dialogClassName='modal-newForm' centered>
                <Modal.Body>
                    <div className='flex flex-col px-4 w-full'>
                        <p className='text-2xl m-2 font-semibold  underline text-orange-400 underline-offset-8'><span className='text-black'>Create New Form</span></p>
                        <Form className='mt-4 '>
                            <Form.Group>
                                <Form.Label className='mb-0 mt-2 font-medium text-lg'>Form Name</Form.Label>
                                <Form.Control placeholder='Form name' className={`border rounded bg-white text-black ${formNameError ? "border-red-600" : ""}`} value={formName} onChange={(e) => { setFormNameError(""); setFormName(e.target.value) }} />
                                <div>{formNameError && (
                                    <div className="error">{formNameError}</div>
                                )}</div>
                            </Form.Group>
                            <Form.Group className='mt-4'>
                                <Form.Label className='mb-0 mt-1 font-medium text-lg'>
                                    <div className='flex flex-col'>
                                        <p>Import Form</p>
                                        <p className='text-xs'>Import questions from selected form over which you can edit.  </p>
                                    </div>
                                </Form.Label>
                                <div className="flex border px-2 py-1 w-full text-center rounded-md bg-white mt-2 text-black">
                                    <select
                                        className="focus:outline-none cursor-pointer w-full"
                                        value={selectedForm && selectedForm.formName}
                                        id="productType"
                                        onChange={(e) => { console.log(e.target.value); setSelectedForm(e.target.value) }}
                                    >
                                        <option value="" disabled hidden>Choose a Form</option>
                                        {feedbackForms && feedbackForms.map((form, i) => {
                                            return (
                                                <option value={form.id} key={i}>{form.formName}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </Form.Group>
                        </Form>
                        <div className='flex justify-end items-center mt-28 mx-4 w-full'>
                            <div className='btn-outer'>
                                <div className='btn-1' style={{ width: "120px", height: "30px" }}></div>
                                <Button
                                    variant="default"
                                    type="submit"
                                    className="btn-2"
                                    style={{ width: "120px", height: "30px", fontSize: "14px", lineHeight: "4px" }}
                                    onClick={handleSubmit}
                                >Create Form
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* Delete Confirmation Modal */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title className='mx-auto'><img src={deleteConfirmtion} alt='' /></Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-auto'><div className="text-orange-600 text-xl font-semibold">{`Are you sure you want to delete this form`}</div></Modal.Body>
                <Modal.Footer className='flex justify-between'>
                    <Button
                        variant="default" size="sm"
                        type="submit"
                        style={{ width: "100px", height: "30px", lineHeight: "10px", fontSize: "14px" }}
                        className='bg-orange-400 rounded-lg text-white'
                        onClick={() => {
                            setShowDeleteModal(false);
                            setDeleteFormId("");
                        }}>
                        No, Cancel
                    </Button>
                    <Button
                        variant="default" size="sm"
                        type="submit"
                        style={{ width: "100px", height: "30px", lineHeight: "10px", fontSize: "14px" }}
                        disabled={isLoading}
                        className='bg-orange-600 rounded-lg text-white'
                        onClick={() => {
                            removeFeedbackForm();
                        }}>
                        {"Yes, Delete"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default FeddbackForms
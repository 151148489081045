import { ReactComponent as Rating1 } from '../../images/rating-gray-1.svg';
import { ReactComponent as Rating2 } from '../../images/rating-gray-2.svg';
import { ReactComponent as Rating3 } from '../../images/rating-gray-3.svg';
import { ReactComponent as Rating4 } from '../../images/rating-gray-4.svg';
import { ReactComponent as Rating5 } from '../../images/rating-gray-5.svg';

import React, { useEffect, useState } from 'react'

function RatingImages({ rating }) {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    let currentPercentage = 100;
    if (rating < 2 && rating > 1) {
      currentPercentage = (rating - 1) * 100;
    }
    else if (rating < 3 && rating > 2) {
      currentPercentage = (rating - 2) * 100;
    }
    else if (rating < 4 && rating > 3) {
      currentPercentage = (rating - 3) * 100;
    }
    else if (rating < 5 && rating > 4) {
      currentPercentage = (rating - 4) * 100;
    }
    setPercentage(currentPercentage);
  }, [])
  
  return (
    rating < 2 ?
      <div className='flex'>
        <Rating1 fill={'#E6570E'} className="w-10 h-10"/>
        <Rating2 fill={`url(#gradient)`} className="w-10 h-10"/>
            <svg style={{ display: 'hidden', width: "0", height: "0" }}>
              <defs>
                <linearGradient id="gradient">
                  <stop offset={`${percentage}%`} stopColor={'#E6570E'} />
                  <stop offset={`${percentage}%`} stopColor="transparent" />
                </linearGradient>
              </defs>
            </svg>
      </div>
      : rating < 3 ?
        <div className='flex'>
          <Rating2 fill={'#E6570E'} className="w-10 h-10"/>
          <Rating3 fill={`url(#gradient)`} className="w-10 h-10"/>
            <svg style={{ display: 'hidden', width: "0", height: "0" }}>
              <defs>
                <linearGradient id="gradient">
                  <stop offset={`${percentage}%`} stopColor={'#E6570E'} />
                  <stop offset={`${percentage}%`} stopColor="transparent" />
                </linearGradient>
              </defs>
            </svg>
        </div>
        : rating < 4 ?
          <div className='flex'>
            <Rating3 fill={'#E6570E'} className="w-10 h-10"/>
            <Rating4 fill={`url(#gradient)`} className="w-10 h-10"/>
            <svg style={{ display: 'hidden', width: "0", height: "0" }}>
              <defs>
                <linearGradient id="gradient">
                  <stop offset={`${percentage}%`} stopColor={'#E6570E'} />
                  <stop offset={`${percentage}%`} stopColor="transparent" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          : <div className='flex'>
            <Rating4 fill={'#E6570E'} className="w-10 h-10"/>
            <Rating5 fill={`url(#gradient)`} className="w-10 h-10"/>
            <svg style={{ display: 'hidden', width: "0", height: "0" }}>
              <defs>
                <linearGradient id="gradient">
                  <stop offset={`${percentage}%`} stopColor={'#E6570E'} />
                  <stop offset={`${percentage}%`} stopColor="transparent" />
                </linearGradient>
              </defs>
            </svg>
          </div>
  )
}

export default RatingImages
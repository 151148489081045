import React, { useRef, useState } from 'react'
import RichTextEditor from 'react-rte'
import { BiImageAdd } from "react-icons/bi";
import toast from 'react-hot-toast';

const toolbarConfig = {

    INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD' },
        { label: 'Italic', style: 'ITALIC' },
    ],
    display: [
        'INLINE_STYLE_BUTTONS',
        'LINK_BUTTONS',
        // 'IMAGE_BUTTON'
    ]
};

function Editor({ onChange, placeholder, initialValue = '' }) {
    const imgRef = useRef()
    const [state, setState] = useState(RichTextEditor.createValueFromString(initialValue, 'html'))
    const handleChange = (value) => {
        setState(value);
        onChange(value.toString('html'));
        console.log(value)
    }


    // const uploadImage = async (image) => {
    //     let data = new FormData()
    //     data.append("file", image)
    //     data.append("upload_preset", "ankit_kumar")
    //     data.append("cloud_name", "ankit628792")
    //     const resp = await fetch(`https://api.cloudinary.com/v1_1/ankit628792/image/upload`, {
    //         method: "post",
    //         body: data
    //     })
    //     let res = await resp.json();
    //     return res.secure_url
    // }
    const uploadImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "dpx0yz1k8")
        data.append("cloud_name", "dpx0yz1k8")
        const resp = await fetch(`https://api.cloudinary.com/v1_1/dpx0yz1k8/image/upload`, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        return res.secure_url
    }
 
    const handleImage = async (e) => {
        console.log("fgh", state._cache.html)
        if (e.target.files && e.target.files[0]) {
            const res = await uploadImage(e.target.files[0]);
            if (res) {
                let str = `<div><img class="commentText" src='${res}'/></div>`
                try {
                    setState(RichTextEditor.createValueFromString(state._cache.html + str, 'html'))
                    imgRef.current.value = ""
                }
                catch (e) {
                    console.log(e)
                }
            }
        }
    }
    return (
        <div>
            <div className='relative' style={{ position: "relative" }}>
                <RichTextEditor placeholder={placeholder || ''} toolbarConfig={toolbarConfig} className='min-h-40 max-h-96 overflow-y-auto max-w-2xl rounded-md px-2 mt-4 border focus:border-gray-800 hover:border-gray-800 text-lg' value={state} onChange={handleChange} />
                <input type="file" className='hidden' ref={imgRef} onChange={(e) => toast.promise(
                    handleImage(e),
                    {
                        loading: 'Uploading File...',
                        success: <p>File Uploaded!</p>,
                        error: <p>Could not upload file!</p>,
                    }
                )} />
                <BiImageAdd className="icon-box" style={{ position: "absolute", top: "11px", right: "20px" }} onClick={() => imgRef.current.click()} />

                {/* <InsertPhotoIcon onClick={() => imgRef.current.click()} fontSize='medium' className='absolute bottom-3 left-60 text-gray-500 hover:text-gray-800 cursor-pointer z-20' /> */}
            </div>
        </div>
    )
}

export default Editor
import React, { useRef, useState, useEffect } from "react";
import { Button, Form, Modal, Dropdown, Card, Row, Col, OverlayTrigger, Tooltip, Popover } from "react-bootstrap";
import { Link, useParams, useLocation, NavLink, Redirect } from "react-router-dom";
import fedduplogo from "../images/fedduplogo.png";
import NavBar from "../NavBar/NavBar";
import './AdminFeed.css';
import axios from 'axios';
import AddImage from "../CompanyFeed/AddImage";
import imageicon from "../images/image-icon.svg";
import fillTag from "../images/fill-tag.svg";
import emptyTag from "../images/empty-tag.svg";
import { config } from '../Config/Config';
import Editor from "../CompanyFeed/Editor";
import AnnouncementSuccessPopup from "./AnnouncementSuccessPopup";
import announcementTag from "../images/announcement-tag.svg";
import bugfixTag from "../images/bugfix-tag.svg";
import newupdateTag from "../images/newupdate-tag.svg";
import { FileUploader } from "react-drag-drop-files";
import deleteIcon from "../images/delete-image.svg";
import uploaded from "../images/uploaded-tick.svg";
import Tag from "../utils/Tag";
import { decrypt } from "../utils";


const announcementTypes = [
    {
        id: 1,
        title: 'Generic Announcement',
        icon: announcementTag
    },
    {
        id: 2,
        title: 'Bug Fixes',
        icon: bugfixTag
    },
    {
        id: 1,
        title: 'New Update',
        icon: newupdateTag
    },
]

export default function CreateAnnouncement(props) {
    const [product, setProduct] = useState(props.product);
    const [announcementTitle, setAnnouncementTitle] = useState(props.title || "");
    const [announcementDescription, setAnnouncementDescription] = useState("");
    const [images, setImages] = useState([])
    const [defaultTags, setDefaultTags] = useState(props.tags || [])
    const [selectedTags, setSelectedTags] = useState([])
    const [redirect, setRedirect] = useState(false)
    const [announcementPost, setAnnouncementPost] = useState(false)
    const [state, setState] = useState("");
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [announcementType, setAnnouncementType] = useState("Generic Announcement");

    useEffect(() => {

        if (props.initialData?.title) {
            setAnnouncementTitle(props.initialData?.title || '');
            setAnnouncementDescription(props.initialData?.announcement || '');
            setAnnouncementType(props.initialData?.announcementType || "Generic Announcement");
            setImages(props.initialData?.images || [])
            setDefaultTags(props.tags.map(data => ({ isSelected: props.initialData?.tag?.includes(data.tag), tag: data.tag })))
        }
    }, [props.initialData])

    const handleTags = (e, tag) => {
        const updated = defaultTags?.map(data => (data.tag === tag) ?
            { ...data, isSelected: !data.isSelected } : data)
        setDefaultTags(updated)
        setSelectedTags(updated)
    }
    // useEffect(() => {
    //     getDefaultTags();
    // }, [])
    const getDefaultTags = () => {
        var headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getDefaultTag', { headers: headers })
            .then(function (response) {
                console.log("getDefaultTags", response);
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    // for (let i = 0; i < response.data.getDefaultTag.length; i++) {
                    //     response.data.getDefaultTag[i].isSelected = false
                    // }
                    setDefaultTags(response.data.getDefaultTag)
                    console.log("tags", response.data.getDefaultTag)
                } else {
                    console.log("error");
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    const postAnnouncement = async (e) => {
        e.preventDefault();
        setLoading(true);
        const requests = images?.slice(0, 6).map((image) => {
            console.log(image);
            if (typeof image == 'object') {
                return uploadImage(image)
            }
            else
                return image
        });
        const imageUrls = await Promise.all(requests);

        var headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let tagsData = [];
        tagsData = defaultTags.filter(item => (item.isSelected === true) ? item.tag : null)

        var payload = {
            "productId": product.id,
            "companyId": product.companyId,
            "versionId": props.versionId,
            "title": announcementTitle,
            "announcement": announcementDescription,
            "announcementImages": imageUrls,
            "tag": tagsData?.map(item => item.tag),
            "announcementType": announcementType
        }
        console.log("payload", payload);
        axios.post(config.userUrl + (props.initialData?.id ? 'admin/updateAnnouncementByCreaterAdmin' : 'admin/createProductAnnouncementByAdmin'), props.initialData?.id ? { ...payload, announcementId: props.initialData?.id } : payload, { headers: headers })
            .then(function (response) {
                console.log("createProductAnnouncementByUser", response);
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("success", payload);
                    if (typeof props.callback == 'function') {
                        props.callback()
                    }
                    setAnnouncementPost(true);
                    props.closePopup();
                    setShowSuccess(true);
                    setTimeout(() => {
                        setShowSuccess(false);
                    }, 3000)

                } else {
                    console.log("error", payload);
                }
                setLoading(false)
            })
            .catch(function (error) {
                console.log("Error", error);
                setLoading(false)
            });
    };
    function closeHideAnnouncement() {
        setAnnouncementPost(false)
        setRedirect(true)
    }
    const handleChange = async (file) => {
        setFile(file);
        console.log("file", file[0])
        if (file) {
            const res = await uploadImage(file[0]);
            if (res) {
                console.log("res", res)
                setImages([...images, { name: file[0].name, url: res }])
            }
            // onChangeImage(res)
        }
    };
    const deleteImage = (i) => {
        let cur_images = images.filter((image, index) => { if (index !== i) return image })
        console.log(cur_images);
        setImages(cur_images);
    }
    const uploadImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "dpx0yz1k8")
        data.append("cloud_name", "greyffiti")
        const resp = await fetch(`https://api.cloudinary.com/v1_1/greyffiti/image/upload`, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        return res.secure_url
    }

    return (
        <div className="px-8">
            {/* {announcementPost && <AnnouncementSuccessPopup closePopup={closeHideAnnouncement} />}
            {redirect && <Redirect to="/admindashboard" />}
            <NavBar /> */}
            {showSuccess ?
                <Modal show={showSuccess} onHide={() => setShowSuccess(false)} size="md" centered >
                    <Modal.Body>
                        <div className='flex flex-col gap-4 justify-center items-center'>
                            <img src={uploaded} alt='' />
                            <p className='text-2xl font-semibold text-orange-600'>Announcement Added Successfully</p>
                        </div>
                    </Modal.Body>
                </Modal> : <Modal size="xl" show={true} onHide={props.closePopup} className="px-4" centered>
                    <Modal.Header closeButton>
                        <Modal.Title className="text-orange-600 text-3xl font-medium">{props.initialData?.title ? 'Edit' : 'Create'} Announcement</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="-mt-8 px-5">
                            <Row style={{ width: "100%" }}>
                                <Col md={12} >
                                    <form>
                                        <Card >
                                            <Form.Control
                                                className="text-black mb-2 border"
                                                style={{ fontFamily: "poppins" }}
                                                type="text"
                                                name="announcementTitle"
                                                value={announcementTitle}
                                                placeholder="Announcement Title"
                                                onChange={(e) => setAnnouncementTitle(e.target.value)} />
                                        </Card>
                                        <Card className="border relative mb-2">
                                            <textarea
                                                className="form-control text-black"
                                                id="exampleFormControlTextarea1"
                                                style={{ fontFamily: "poppins", resize: "none" }}
                                                rows="4"
                                                maxLength={280}
                                                value={announcementDescription}
                                                name="announcementDescription"
                                                onChange={(e) => setAnnouncementDescription(e.target.value)}
                                                placeholder="Announcement Description"
                                            />
                                            {/* <Editor onChange={onChange} /> */}
                                            <div className="absolute right-4 bottom-2 z-10" style={{ fontFamily: "poppins", fontSize: "12px" }}>{announcementDescription.length}/280 characters</div>
                                        </Card>
                                        {/* <div className="mt-3"> <AddImage onChangeImage={onChangeImage} /></div><br /> */}
                                        <FileUploader
                                            multiple={true}
                                            onDrop={(e) => setImages([...images, ...e])} // function to get uploaded files
                                            onSelect={(e) => setImages([...images, ...e])}
                                            onDelete={deleteImage}
                                            name="file"
                                            classes="drag-drop-announcement mx-auto"
                                            children={images && images.length === 0 ?
                                                <div className="mb-2 relative">
                                                    <div className="b-image min-h-[144px] py-4 border rounded text-center flex flex-col items-center justify-center">
                                                        <span className="text-black text-lg">Choose a file or drag and drop here</span>
                                                        <span className="text-black">Upload Upto 6 files</span>
                                                    </div>
                                                    <div style={{ fontSize: "12px", color: "grey", float: "right" }} className="absolute bottom-2 right-4 z-10">Only JPG's,PNG's,JPEG's are allowed</div>
                                                </div> :
                                                <></>
                                            }
                                            types={["JPG", "PNG", "JPEG"]}
                                        />
                                        <div className={`flex gap-4 min-h-[110px] p-3 px-4 border rounded text-center relative ${images.length === 0 ? "hidden" : ""}`}>
                                            {images?.map((image, i) => {
                                                return (
                                                    <div className="flex relative" key={i}>
                                                        <img src={typeof image == 'object' ? URL.createObjectURL(image) : image} alt="" style={{ width: "100px", height: "100px" }} />
                                                        <svg onClick={() => deleteImage(i)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 cursor-pointer text-red-500 absolute -top-2 -right-2 bg-white rounded-full z-10">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                    </div>
                                                )
                                            })}
                                            <FileUploader
                                                multiple={true}
                                                onDrop={(e) => setImages([...images, ...e])} // function to get uploaded files
                                                onSelect={(e) => { setImages([...images, ...e]) }}
                                                onDelete={deleteImage}
                                                name="file"
                                                classes=""
                                                children={images.length < 6 ?
                                                    <div className="border flex justify-center items-center p-6 rounded-lg cursor-pointer" style={{ width: "100px", height: "100px" }}>
                                                        <span className="text-3xl">+</span>
                                                    </div>
                                                    : <></>}
                                                types={["JPG", "PNG", "JPEG"]}
                                            />
                                            <span className="absolute bottom-0 right-0 pr-4 font-semibold">{images.length}/6</span>
                                        </div>
                                        <Card className="border">
                                            <center className="mb-2">
                                                <div className="flex items-center flex-wrap justify-center gap-2 pt-2">
                                                    {defaultTags?.map((data, i) => (
                                                        <span onClick={(e) => handleTags(e, data.tag)} className="cursor-pointer">
                                                            <Tag color={data.isSelected ? '' : '#999'} text={data.tag} />
                                                        </span>
                                                    ))}
                                                </div>
                                            </center>
                                        </Card>
                                        <p className="text-lg font-medium text-gray-800 my-2">Choose announcement type</p>
                                        <div className="flex items-center gap-2 border px-2 max-w-max text-lg rounded">
                                            <label><img className="w-10 h-10" src={announcementTypes.find(item => item.title == announcementType)?.icon} alt="" /></label>
                                            <select name="announcemnet-type"
                                                className="font-medium focus:outline-none text-center flex-shrink-0"
                                                id="type"
                                                value={announcementType}
                                                onChange={(e) => setAnnouncementType(e.target.value)}
                                            >
                                                <option value="Generic Announcement">Generic Announcement</option>
                                                <option value="Bug Fixes">Bug Fixes</option>
                                                <option value="New Update">New Update</option>
                                            </select>
                                        </div>
                                    </form>
                                    <div>
                                        <OverlayTrigger style={(announcementTitle.length != 0) && (announcementDescription.length != 0) ? { display: "none" } : { cursor: "pointer" }}
                                            placement="left"
                                            overlay={
                                                <Tooltip id={`tooltip-top`} style={(announcementTitle.length != 0) && (announcementDescription.length != 0) ? { display: "none" } : { cursor: "pointer" }}>
                                                    {(announcementTitle.length != 0) && (announcementDescription.length != 0) ?
                                                        <div style={{ display: "none" }}>

                                                        </div>
                                                        : <div className='tool-text'>
                                                            Please add title and description
                                                        </div>
                                                    }
                                                </Tooltip>
                                            }
                                        >
                                            {(announcementTitle.length != 0) && (announcementDescription.length != 0) ?
                                                <div className='btn-outer float-right'>
                                                    <div className='btn-1 ml-auto' style={{ width: "120px", height: "35px" }}>
                                                    </div>
                                                    <Button
                                                        variant="default" size="md"
                                                        type="submit"
                                                        style={{ float: "right", width: "120px", height: "35px", lineHeight: "18px" }}
                                                        className="btn-2 mr-[1px]"
                                                        onClick={postAnnouncement}
                                                        disabled={loading ? true : false}

                                                    >
                                                        {loading ? "Please Wait" : "Publish"}
                                                    </Button></div> :
                                                <div className='btn-outer float-right'>
                                                    <div className='btn-1 ml-auto' style={{ width: "120px", height: "35px" }}>
                                                    </div>
                                                    <Button
                                                        variant="default" size="md"
                                                        type="submit"
                                                        style={{ float: "right", width: "120px", height: "35px", lineHeight: "18px" }}
                                                        className="btn-2 mr-[1px]"
                                                        disabled={true}
                                                    >
                                                        Publish
                                                    </Button>
                                                </div>
                                            }
                                        </OverlayTrigger>
                                        <Button
                                            variant="default" size="md"
                                            type="submit"
                                            style={{ float: "right" }}
                                            className="text-orange-600 font-medium text-xl"
                                            onClick={props.closePopup}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                    <br />
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                </Modal>}

        </div >
    )
}
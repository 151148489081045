import React, { useEffect, useState } from 'react'
import NavBar1 from '../NavBar/NavBar1'
import arrowLeft from "../images/arrow-left-black.svg"
import axios from 'axios'
import { config } from '../Config/Config';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import uploaded from "../images/uploaded-tick.svg";
import deleteConfirmtion from "../images/delete-confirmation.svg"
import { useQuery } from 'react-query';
import useDebounce, { decrypt } from '../utils';

const getPageDetails = (product) => {
    let headers = {
        "Content-Type": 'application/json',
        "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
        "applicationType": "web"
    }
    return axios.get(config.userUrl + 'admin/getPageDetails?productName=' + product, { headers: headers })
}

function ManagePageUsers() {
    const { productname } = useParams()
    const [companyId, setCompanyId] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [companyWebsite, setCompanyWebsite] = useState("")
    const [companyIcon, setCompanyIcon] = useState("")
    const [companyHeadquarter, setCompanyHeadquarter] = useState("")
    const [companyDescription, setCompanyDescription] = useState("")
    const [membershipType, setMembershipType] = useState("")
    const [adminName, setAdminName] = useState("")
    const [adminEmail, setAdminEmail] = useState("")
    const [item, setItem] = useState("Portal")
    const [searchInput, setSearchInput] = useState("")
    const [subAdminId, setSubAdminId] = useState("")
    const [subAdminName, setSubAdminName] = useState("")
    const [subAdminEmail, setSubAdminEmail] = useState("")
    const [moderatorId, setModeratorId] = useState("")
    const [moderatorName, setModeratorName] = useState("")
    const [moderatorEmail, setModeratorEmail] = useState("")
    const [subAdmins, setSubAdmins] = useState([])
    const [moderators, setModerators] = useState([])
    const [showSuccess, setShowSuccess] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [filteredResults, setFilteredResults] = useState([])
    const [emailError, setEmailError] = useState("")
    const [userRole, setUserRole] = useState('');
    const [productDetail, setProductDetail] = useState();
    const [allSubAdmins, setAllSubAdmins] = useState([])
    const [allModerators, setAllModerators] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const [addModal, setAddModal] = useState(false)
    const [admin, setAdmin] = useState();
    const [currentUser, setCurrentUser] = useState();
    const [showLeaveModal, setShowLeaveModal] = useState(false)

    const history = useHistory();

    useEffect(() => {
        let role = localStorage.getItem('role')?.toLowerCase();
        if (role)
            setUserRole(role)
        try {
            getCompanyDetails();
        } catch (error) {
            console.log(error);
        }
    }, []);


    const { data: pageRes, isLoading, refetch } = useQuery('getPageDetails', () => getPageDetails(productname), {
        refetchOnWindowFocus: false,
        onSuccess: (res) => {
            if (res.data.status) {
                setProductDetail(res.data.data);
                setSubAdmins(res.data.data.subAdmins);
                setModerators(res.data.data.moderators);
            }
        }
    })

    const debounced = useDebounce((item == 'Portal') ? subAdminEmail : moderatorEmail)

    useEffect(() => {
        if (debounced) {
            if ((item == 'Portal')) {
                let idx = subAdmins.findIndex(item => item?.email == debounced?.toLowerCase())
                if (idx > -1) {
                    setEmailError("Email already added")
                }
                // This sub-admin has reached the limit of 5 pages
            }
            else {
                let idx = moderators.findIndex(item => item?.email == debounced?.toLowerCase())
                if (idx > -1) {
                    setEmailError("Email already added")
                }
            }
        }
    }, [debounced])

    const handleShow = () => { setAddModal(true) };

    const getCompanyDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'admin/getCompanyProfileDetailsByAdmin', { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("Company details retrieved successfully!!", response.data);
                    setCompanyName(response.data.companyDetail.companyName);
                    setCompanyWebsite(response.data.companyDetail.companyWebsite);
                    setCompanyIcon(response.data.companyDetail.companyIcon);
                    setCompanyHeadquarter(response.data.companyDetail.companyheadquarter);
                    setCompanyId(response.data.companyDetail.companyId);
                    setCompanyDescription(response.data.companyDetail.companyDescription);
                    setMembershipType(response.data.companyDetail.membershipType);
                    setAdminName(response.data.companyDetail.userName);
                    setAdminEmail(response.data.companyDetail.email);
                    getAllModerators(response.data.companyDetail.companyId)
                    getAllSubAdmins(response.data.companyDetail.companyId)
                    setCurrentUser(response?.data?.currentUser);
                    setAdmin(response?.data?.admin);

                } else {
                    console.log(response.data.message)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const getAllSubAdmins = (companyId) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "companyId": companyId
        }
        axios.post(config.userUrl + "admin/getAllSubAdmins", payload, { headers: headers })
            .then((response) => {
                setAllSubAdmins(response.data.subadmins);
            }).catch((err) => {
                console.log(err)
            });
    }
    const getAllModerators = (companyId) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "companyId": companyId
        }
        axios.post(config.userUrl + "admin/getAllModerators", payload, { headers: headers })
            .then((response) => {
                setAllModerators(response.data.moderators);
            }).catch((err) => {
                console.log(err)
            });
    }


    const deleteSubAdmin = (id) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "id": id,
            productId: productDetail?.id,
            page: productDetail?.productName
        }
        axios.post(config.userUrl + "admin/deleteSubAdmin", payload, { headers: headers })
            .then((response) => {
                console.log(response.data);
                updateSubAdmins(id);
                getAllSubAdmins(productDetail?.companyId)
            }).catch((err) => {
                console.log(err)
            });
    };
    const updateSubAdmins = (id) => {
        let newSubAdminsArray = subAdmins.filter((subadmin) => subadmin.uuid !== id)
        setSubAdmins(newSubAdminsArray);
    }

    const updateModerators = (id) => {
        let newModeratorsArray = moderators.filter((moderator) => moderator.uuid !== id)
        setModerators(newModeratorsArray);
    }


    const addModAndSubAdminForPage = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            type: item == 'Portal' ? 'subadmin' : 'moderator',
            selectedUsers,
            "page": productDetail?.productName,
            "productId": productDetail?.id

        }
        handleCloseModal();
        axios.post(config.userUrl + "admin/addModAndSubAdminForPage", payload, { headers: headers })
            .then((response) => {
                refetch();
                if (item == 'Portal') {
                    getAllSubAdmins(productDetail?.companyId);
                } else {
                    getAllModerators(productDetail?.companyId);
                }
                setShowSuccess(true);
                setTimeout(() => {
                    setShowSuccess(false)
                }, 5000);
            }).catch((err) => {
                console.log(err)
            });
    }

    const deleteModerator = (id) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "id": id,
            productId: productDetail?.id,
            page: productDetail?.productName
        }
        axios.post(config.userUrl + "admin/deleteModerator", payload, { headers: headers })
            .then((response) => {
                updateModerators(id);
                getAllModerators(productDetail?.companyId)
            }).catch((err) => {
                console.log(err)
            });
    };

    const leavePage = (role) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "role": role,
            productId: productDetail?.id,
            page: productDetail?.productName,
            userId: currentUser?.userId
        }
        axios.post(config.userUrl + "admin/leavePage", payload, { headers: headers })
            .then((response) => {
                let productIds = JSON.parse(localStorage.getItem('productIds'))
                let idx = productIds.findIndex(item => item == payload.productId)
                if (idx > -1) {
                    productIds.splice(idx, 1);
                    localStorage.setItem('productIds', JSON.stringify(productIds))
                }
                window.location.replace('/admindashboard')
            }).catch((err) => {
                console.log(err)
            });
    };

    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        const currentData = item === "Portal" ? subAdmins : moderators;
        if (searchValue !== '') {
            const filteredData = currentData.filter((item) => {
                return item["userName"].toLowerCase().includes(searchValue.toLowerCase())
            })
            setFilteredResults(filteredData)
        }
        else {
            setFilteredResults(currentData)
        }
    }

    const handleSelect = (id) => {
        let idx = selectedUsers.findIndex(item => item == id);
        if (idx > -1) {
            let arr = [...selectedUsers];
            arr.splice(idx, 1)
            setSelectedUsers([...arr])
        } else {
            setSelectedUsers([...selectedUsers, id])
        }
    }

    const handleCloseModal = () => {
        setAddModal(false)
        setSelectedUsers([])
    }



    return (
        <div className='bg-gray-100 min-h-screen pb-16'>
            <NavBar1 />
            <div className='px-24 mt-20'>
                <div className='flex gap-4 items-center pt-4'>
                    <img src={arrowLeft} alt='' onClick={() => history.goBack()} className='cursor-pointer' />
                    <div className='bg-white flex items-center justify-center rounded-full w-32 h-32 overflow-hidden' style={{ width: "100px", height: "100px" }}>
                        <img src={productDetail?.productIcon} alt='' className='object-contain w-full h-full' />
                    </div>
                    <div>
                        <h1 className='text-2xl font-medium'>{productDetail?.productName} Portal Access</h1>
                        <p className='text-lg font-medium text-gray-500'>{productDetail?.productType == 1 ? 'Desktop App' : 'Web App'}</p>
                    </div>
                </div>
                <div className='max-w-5xl mx-28 mt-8'>
                    <div className='flex gap-6'>
                        <div className='btn-outer-section w-40'>
                            <div className={item == "Portal" ? "feed-card f-color btn-inner-section" : "feed-card-a f-color-g btn-inner-section"} onClick={() => setItem("Portal")}>Subadmin</div>
                        </div>
                        <div className='btn-outer-section w-40'>
                            <div className={item == "Moderators" ? "feed-card f-color btn-inner-section" : "feed-card-a f-color-g btn-inner-section"} onClick={() => setItem("Moderators")}>Moderators</div>
                        </div>
                    </div>
                    <div className='bg-white w-full mt-4 rounded-xl'>
                        <Row className='items-center px-6 py-4'>
                            <Col md={3} className='font-semibold'>
                                {
                                    currentUser?.role
                                        ?
                                        item == 'Portal' && currentUser?.role == 2 ?
                                            currentUser?.userName.charAt(0).toUpperCase() + currentUser?.userName.slice(1) :
                                            item !== 'Portal' && currentUser?.role == 4 ?
                                                currentUser?.userName.charAt(0).toUpperCase() + currentUser?.userName.slice(1) :
                                                admin?.userName.charAt(0).toUpperCase() + admin?.userName.slice(1)
                                        :
                                        admin?.userName.charAt(0).toUpperCase() + admin?.userName.slice(1)
                                }
                            </Col>
                            <Col md={2} className='font-semibold'>
                                {
                                    currentUser?.role ?
                                        currentUser?.role == 2 && item == 'Portal' ?
                                            'Sub Admin' :
                                            currentUser?.role == 4 && item !== 'Portal' ?
                                                'Moderator' :
                                                'Admin'
                                        :
                                        'Admin'
                                }
                            </Col>
                            <Col md={4} className='font-semibold'>
                                {
                                    currentUser?.role
                                        ?
                                        currentUser?.role == 2 && item == 'Portal' ?
                                            currentUser?.email :
                                            currentUser?.role == 4 && item !== 'Portal' ?
                                                currentUser?.email :
                                                admin?.email
                                        :
                                        admin?.email
                                }
                            </Col>

                            <Col md={3}>

                                {
                                    currentUser?.role
                                        ?
                                        <>
                                            {
                                                currentUser?.role == 2 && item == 'Portal' ?
                                                    <Button
                                                        variant="default" size="sm"
                                                        type="submit"
                                                        style={{ width: "150px", height: "30px", lineHeight: "14px", fontSize: "14px" }}
                                                        className='bg-orange rounded-lg text-white'
                                                        onClick={() => setShowLeaveModal(true)}
                                                    >
                                                        Leave Company
                                                    </Button>
                                                    :
                                                    currentUser?.role == 4 && item !== 'Portal' ?
                                                        <Button
                                                            variant="default" size="sm"
                                                            type="submit"
                                                            style={{ width: "100px", height: "30px", lineHeight: "10px", fontSize: "14px" }}
                                                            className='bg-orange-400 rounded-lg text-white'
                                                            onClick={() => setShowLeaveModal(true)}
                                                        >
                                                            Leave Page
                                                        </Button>
                                                        :
                                                        <></>
                                            }
                                        </>
                                        :
                                        <></>
                                }
                            </Col>
                        </Row>
                    </div>
                    <div className="mt-8 flex justify-between">
                        <input
                            type="text"
                            placeholder="Search"
                            value={searchInput}
                            onChange={(e) => searchItems(e.target.value)}
                            id="search"
                            className="abc form-control ml-0 bg-white rounded-full"
                            style={{ width: "460px", height: "45px", borderRadius: "25px", paddingLeft: "3rem" }}
                            aria-label="Search keyword"
                        />
                        {userRole != 'moderator' ?
                            userRole == 'admin'
                                ?
                                <div className='btn-outer'>
                                    <div className='btn-1' style={{ width: "150px", height: "35px" }}>
                                    </div>
                                    <Button
                                        variant="default" size="md"
                                        type="submit"
                                        className="btn-2 "
                                        style={{ width: "150px", height: "35px", lineHeight: "14px", fontSize: "14px" }}
                                        onClick={() => {
                                            handleShow()
                                        }}
                                    >
                                        {`Add ${item === "Portal" ? "sub admin" : "moderator"}`}
                                    </Button>
                                </div>
                                :
                                item != "Portal" && userRole == 'subadmin' ?
                                    <div className='btn-outer'>
                                        <div className='btn-1' style={{ width: "150px", height: "35px" }}>
                                        </div>
                                        <Button
                                            variant="default" size="md"
                                            type="submit"
                                            className="btn-2 "
                                            style={{ width: "150px", height: "35px", lineHeight: "14px", fontSize: "14px" }}
                                            onClick={() => {
                                                handleShow()
                                            }}
                                        >
                                            {`Add moderator`}
                                        </Button>
                                    </div>
                                    :
                                    ''
                            : ''}
                    </div>
                    <div className='bg-white mt-4 rounded-xl px-4'>
                        <Table responsive bordered={false}>
                            <thead>
                                <tr className=''>
                                    <th className='text-orange-600 underline underline-offset-4 pl-4'><span className="text-black">{item === "Portal" ? 'Sub Admin' : 'Moderator'}</span></th>
                                    <th className='text-orange-600 underline underline-offset-4 pl-4'><span className="text-black">Email</span></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {item === "Portal" ?
                                    searchInput.length > 1 ? filteredResults.map((subadmin, index) => {
                                        return (
                                            <tr className='font-semibold' key={index}>
                                                <td>{subadmin.userName}</td>
                                                <td>{subadmin.email}</td>
                                                <td>
                                                    {userRole != 'moderator' && <Button
                                                        variant="default" size="sm"
                                                        type="submit"
                                                        style={{ width: "100px", height: "30px", lineHeight: "10px", fontSize: "14px" }}
                                                        className='bg-orange-400 rounded-lg text-white'
                                                        onClick={() => {
                                                            setShowDeleteModal(subadmin?.uuid);
                                                        }}
                                                    >
                                                        Remove
                                                    </Button>}
                                                </td>
                                            </tr>
                                        )
                                    })
                                        : subAdmins && subAdmins.map((subadmin, index) => {
                                            return (
                                                <tr className='font-semibold' key={index}>
                                                    <td>{subadmin.userName}</td>
                                                    <td>{subadmin.email}</td>
                                                    <td>
                                                        {userRole != 'moderator' && <Button
                                                            variant="default" size="sm"
                                                            type="submit"
                                                            style={{ width: "100px", height: "30px", lineHeight: "10px", fontSize: "14px" }}
                                                            className='bg-orange-400 rounded-lg text-white'
                                                            onClick={() => {
                                                                setShowDeleteModal(subadmin?.uuid);
                                                            }}
                                                        >
                                                            Remove
                                                        </Button>}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    :
                                    searchInput.length > 1 ? filteredResults.map((moderator, index) => {
                                        return (
                                            <tr className='font-semibold' key={index}>
                                                <td>{moderator.userName}</td>
                                                <td>{moderator.email}</td>
                                                <td>
                                                    {userRole != 'moderator' && <Button
                                                        variant="default" size="sm"
                                                        type="submit"
                                                        style={{ width: "100px", height: "30px", lineHeight: "10px", fontSize: "14px" }}
                                                        className='bg-orange-400 rounded-lg text-white'
                                                        onClick={() => {
                                                            setShowDeleteModal(moderator?.uuid);
                                                        }}
                                                    >
                                                        Remove
                                                    </Button>}
                                                </td>
                                            </tr>
                                        )
                                    }) :
                                        moderators && moderators.map((moderator, index) => {
                                            return (
                                                <tr className='font-semibold' key={index}>
                                                    <td>{moderator.userName}</td>
                                                    <td>{moderator.email}</td>
                                                    <td>
                                                        {userRole != 'moderator' && <Button
                                                            variant="default" size="sm"
                                                            type="submit"
                                                            style={{ width: "100px", height: "30px", lineHeight: "10px", fontSize: "14px" }}
                                                            className='bg-orange-400 rounded-lg text-white'
                                                            onClick={() => {
                                                                setShowDeleteModal(moderator?.uuid);
                                                            }}
                                                        >
                                                            Remove
                                                        </Button>}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>


            {/* Success Modal */}
            <Modal show={showSuccess} onHide={() => setShowSuccess(false)} size="md" centered >
                <Modal.Body>
                    <div className='flex flex-col gap-4 justify-center items-center'>
                        <img src={uploaded} alt='' />
                        <p className='text-3xl font-semibold text-orange-600'>Added Successfully</p>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal show={showDeleteModal} size='lg' onHide={() => setShowDeleteModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title className='mx-auto mt-10'><img src={deleteConfirmtion} alt='' /></Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-auto'>
                    <div className="text-orange-600 text-3xl font-semibold">
                        {`Are you sure you want to remove ${item === "Portal" ? "Subadmin" : "Moderator"}?`}
                    </div>
                </Modal.Body>
                <Modal.Footer className='flex justify-between'>
                    <Button
                        variant="default" size="sm"
                        type="submit"
                        style={{ width: "150px", height: "36px", fontSize: "18px" }}
                        className='bg-orange-400 rounded-lg text-white'
                        onClick={() => {
                            setShowDeleteModal(false);

                        }}>
                        No, Cancel
                    </Button>
                    <Button
                        variant="default" size="sm"
                        type="submit"
                        style={{ width: "150px", height: "36px", fontSize: "18px" }}
                        className='bg-orange-600 rounded-lg text-white'
                        onClick={() => {
                            if (item === "Portal") deleteSubAdmin(showDeleteModal);
                            else deleteModerator(showDeleteModal);
                            setShowDeleteModal(false);
                        }}>
                        Yes, Remove
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showLeaveModal} size='lg' onHide={() => setShowLeaveModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title className='mx-auto mt-10'><img src={deleteConfirmtion} alt='' /></Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-auto'>
                    <div className="text-orange-600 text-3xl font-semibold">
                        {`Are you sure you want to leave this Feddup Page?`}
                    </div>
                </Modal.Body>
                <Modal.Footer className='flex justify-between'>
                    <Button
                        variant="default" size="sm"
                        type="submit"
                        style={{ width: "150px", height: "36px", fontSize: "18px" }}
                        className='bg-orange-400 rounded-lg text-white'
                        onClick={() => {
                            setShowLeaveModal(false);

                        }}>
                        No, Cancel
                    </Button>
                    <Button
                        variant="default" size="sm"
                        type="submit"
                        style={{ width: "150px", height: "36px", fontSize: "18px" }}
                        className='bg-orange-600 rounded-lg text-white'
                        onClick={() => {
                            if (item === "Portal") leavePage(2);
                            else leavePage(4);
                            setShowLeaveModal(false);
                        }}>
                        Yes, Remove
                    </Button>
                </Modal.Footer>
            </Modal>



            {addModal ? <div className='fixed inset-0 grid place-items-center' style={{ zIndex: 12000 }}>
                <div className='fixed inset-0 bg-black bg-opacity-60' onClick={handleCloseModal}></div>
                <div className='p-7 rounded-lg bg-white z-10 w-full max-w-5xl min-h-[500px] flex flex-col opacTranslate'>
                    <div>
                        <h1 className='text-4xl font-bold text-orange'>Add {item == 'Portal' ? 'Sub-admin' : 'Moderator'}</h1>
                        <p className='text-orange-300 mt-1 max-w-2xl'>
                            {
                                item == 'Portal'
                                    ?
                                    'Choose from sub-admins added before form the company portal access. If subadmin does not exists add from company portal access before adding to page.'
                                    :
                                    'Choose moderators which have been added to company access portal. If mod is not added. please inform the company admin to add mod. '
                            }
                        </p>
                    </div>
                    <div className='flex-grow max-h-[60dvh] overflow-y-auto'>
                        <div className='px-3 my-4 grid grid-cols-4 gap-y-4 items-start justify-start'>
                            {
                                item == 'Portal'
                                    ?
                                    allSubAdmins?.filter(item => item?.productIds?.length < 5 && !item?.productIds?.includes(productDetail?.id))?.length
                                        ?
                                        allSubAdmins?.filter(item => item?.productIds?.length < 5 && !item?.productIds?.includes(productDetail?.id)).map(item => {
                                            return (
                                                <Person onClick={() => handleSelect(item.uuid)} checked={selectedUsers?.includes(item.uuid)} name={item.userName} pages={item?.productIds?.length || 0} image={item.profilePicture} />
                                            )
                                        })
                                        :
                                        <h1 className='text-center text-4xl text-gray-300 mt-6 col-span-5 font-medium'>No sub admins available to add</h1>
                                    :
                                    allModerators?.filter(item => item?.productIds?.length < 5 && !item?.productIds?.includes(productDetail?.id))?.length
                                        ?
                                        allModerators?.filter(item => item?.productIds?.length < 5 && !item?.productIds?.includes(productDetail?.id)).map(item => {
                                            return (
                                                <Person onClick={() => handleSelect(item.uuid)} checked={selectedUsers?.includes(item.uuid)} name={item.userName} pages={item?.productIds?.length || 0} image={item.profilePicture} />
                                            )
                                        })
                                        :
                                        <h1 className='text-center text-4xl text-gray-300 mt-6 col-span-5 font-medium'>No moderator available to add</h1>
                            }
                        </div>
                    </div>

                    <div className='flex items-center justify-end pr-4'>
                        <div className='btn-outer'>
                            <div className='btn-1' style={{ width: "160px", height: "40px" }}>
                            </div>
                            <Button
                                variant="default" size="md"
                                type="submit"
                                className="btn-2 "
                                style={{ width: "160px", height: "40px", lineHeight: "16px", fontSize: "16px", background: selectedUsers?.length ? '#E6570E' : '#963909', color: '#FFF' }}
                                disabled={!selectedUsers?.length}
                                onClick={() => {
                                    addModAndSubAdminForPage()
                                }}
                            >
                                {`Add ${item === "Portal" ? "sub admin" : "moderator"}`}
                            </Button>
                        </div>
                    </div>

                </div>
            </div> : <></>}

        </div>
    )
}

export default ManagePageUsers

const Person = ({ onClick, checked, name, pages, image }) => (
    <div className='flex items-center gap-3 cursor-pointer' onClick={onClick}>
        <div className={`w-5 h-5 border border-gray-600 rounded-sm flex-shrink-0 ${checked ? 'bg-orange' : 'bg-transparent'}`}></div>
        <div className='flex items-center gap-2'>
            <div className='w-10 h-10 rounded-full bg-white'>
                <img src={image} className='w-full h-full rounded-full object-contain' alt="" />
            </div>
            <div>
                <h1 className='text-base font-medium leading-snug'>{name}</h1>
                <p className='text-sm text-gray-600'>{pages}/5 pages assigned</p>
            </div>
        </div>
    </div>
)
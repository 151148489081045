import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { config } from '../Config/Config';
import feddbacklogoblack from "../images/fedduplogo-black.svg";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Card, Button, Form, Modal, OverlayTrigger, Tooltip, Dropdown, Row, Col } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { userRatingDesc } from '../utils/ratingDescription';
import moment, { max } from 'moment';
import rating1 from '../images/rating1.png';
import rating2 from '../images/rating2.png';
import rating3 from '../images/rating3.png';
import rating4 from '../images/rating4.png';
import rating5 from '../images/rating5.png';
import orating1 from "../images/fill-rating1.png";
import orating2 from "../images/fill-rating2.png";
import orating3 from "../images/fill-rating3.png";
import orating4 from "../images/fill-rating4.png";
import orating5 from "../images/fill-rating5.png";
import grating1 from '../images/rating1-gray-outlined.svg';
import grating2 from '../images/rating2-gray-outlined.svg';
import grating3 from '../images/rating3-gray-outlined.svg';
import grating4 from '../images/rating4-gray-outlined.svg';
import grating5 from '../images/rating5-gray-outlined.svg';
import downvoteb from '../images/downvote-b.svg';
import cancelWhiteIcon from "../images/cancel-x-white.svg";
import feedbackIcon from "../images/feedback-icon.svg";
import ratingIcon from "../images/rating-popup-icon.svg";
import arrowLeftBlack from "../images/arrow-left-black.svg";
import arrowRightBlack from "../images/arrow-right-black.svg";
import arrowLeftGray from "../images/arrow-left-gray.svg";
import arrowRightGray2 from "../images/arrow-right-gray2.svg";
import AccessPopUp from './AccessPopUp';
import pinkHeart from "../images/pink-heart.svg";
import { decrypt, encrypt } from '../utils';

function ProductRatingSection(props) {
    const { companyId, productId, selectedVersion, getProductRating, canReview, guidelines } = props;
    const [overallNumberOfRatings, setOverallNumberOfRatings] = useState(props.overallNumberOfRatings)
    const [overallRatingCountArray, setOverallRatingCountArray] = useState(props.overallRatingCountArray)
    const [addRating, setAddRating] = useState(false);
    const [rating, setRating] = useState("")
    const [ratingComment, setRatingComment] = useState("");
    const [averageRating, setAverageRating] = useState("")
    const [noOfPeopleGivenRating, setNoOfPeopleGivenRating] = useState("")
    const [productRating, setProductRating] = useState([])
    const [ratingCountArray, setRatingCountArray] = useState([])
    const [numberOfRatings, setNumberOfRatings] = useState(0);
    const [selected1, setSelected1] = useState(false);
    const [selected2, setSelected2] = useState(false);
    const [selected3, setSelected3] = useState(false);
    const [selected4, setSelected4] = useState(false);
    const [selected5, setSelected5] = useState(false);
    const [sortOption, setSortOption] = useState('');
    const [selectedRating, setSelectedRating] = useState('');
    const [ratingSortOption, setRatingSortOption] = useState('');
    const [sortOption2, setSortOption2] = useState('');
    const [sortedData, setSortedData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [reviewEnabled, setReviewEnabled] = useState(false);
    const [section, setSection] = useState("confirmation");
    const [ratingType, setRatingType] = useState('feedback')
    const [currentPage, setCurrentPage] = useState(1)
    const [currentQuest, setCurrentQuest] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [responses, setResponses] = useState([]);
    const [skipped, setSkipped] = useState(false)
    const [feedbackRating, setFeedbackRating] = useState(0);
    const [genericRating, setGenericRating] = useState(0);
    const [isAccess, setIsAccess] = useState(true);
    const [accessPopUp, setAccessPopUp] = useState(false);
    const [feedbackForm, setFeedbackForm] = useState();
    const [noForm, setNoForm] = useState(false)
    const [ratingAccess, setRatingAccess] = useState(true)

    useEffect(() => {
        setOverallNumberOfRatings(props.overallNumberOfRatings);
        setOverallRatingCountArray(props.overallRatingCountArray);
    }, [props.overallNumberOfRatings, props.overallRatingCountArray?.length])
    useEffect(() => {
        getRatingComments(selectedVersion);
    }, [selectedVersion])

    useEffect(() => {
        if (selectedVersion?.id && productId)
            getFeedbackForm();

    }, [selectedVersion, productId])
    const addRatingPopup = () => {
        if (localStorage.getItem('junkStore')) {
            if (isAccess) {
                if (noForm) {
                    setAddRating(true);
                    setSection("rating")
                }
                else
                    setAddRating(true);
            }
            else {
                setAccessPopUp(true)
            }
        } else {
            setAddRating(false)
        }
    }
    const postRating = () => {
        if (localStorage.getItem('junkStore')) {
            let headers;
            if (localStorage.getItem('junkStore')) {
                headers = {
                    'Accept': 'application/json',
                    "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                    'Content-Type': 'application/json'
                }
            } else {
                headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
            var tokenId = encrypt({ "companyId": companyId, "productId": productId })
            var payload = {
                "tokenId": tokenId,
                "rating": rating,
                "comment": ratingComment,
                "versionId": selectedVersion?.id
            }
            axios.post(config.userUrl + 'user/addRatingCommentForProductByUser', payload, { headers: headers })
                .then(function (response) {
                    // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                    if (response.data.status === true) {
                        cleanUp();
                        getRatingComments(selectedVersion);
                        getProductRating();
                        validateReviewAdded();
                    } else {
                        console.log("error", payload);
                    }
                })
                .catch(function (error) {
                    console.log("Error", error);
                });
        } else {
            console.log("No rating token")
        }
    };

    const validateReviewAdded = () => {
        if (localStorage.getItem('junkStore')) {
            var headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                "applicationType": "web"
            }
            var tokenId = encrypt({ "companyId": companyId, "productId": productId })

            var payload = {
                "tokenId": tokenId,
                "versionId": selectedVersion.id,
                formId: feedbackForm?.id,
                type: 'rating',
                email: localStorage.getItem('value')
            }
            if (selectedVersion.id) {

                axios.post(config.userUrl + 'user/validateReviewAdded', payload, { headers: headers })
                    .then(function (response) {
                        if (response.data.status === true) {
                            setIsAccess(response.data?.access)
                            setRatingAccess(response.data?.ratingAccess)
                        } else {
                            console.log("error", payload);
                        }
                    })
                    .catch(function (error) {
                        console.log("Error", error);
                    });
            }
        } else {
            console.log("No Suggestion token")
        }
    };

    useEffect(() => {
        if (companyId && productId && selectedVersion?.id) {
            validateReviewAdded()
        }
    }, [companyId, productId, selectedVersion?.id, feedbackForm])

    const getRatingComments = (version) => {
        if (productId === '') return
        let headers;
        if (localStorage.getItem('junkStore')) {
            headers = {
                'Accept': 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                'Content-Type': 'application/json'
            }
        } else {
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        var payload = {
            "productId": productId,
            "versionId": version?.id,
        }
        if (version) {
            axios.post(config.userUrl + "user/getProductDetailsByProductIdAndVersionId", payload, { headers: headers })
                .then(function (response) {
                    if (response.data.status === true) {
                        let arr = [...response.data.numberOfRating];
                        arr = arr.map((item) =>
                            Number(item.count)
                        )
                        let ratingData = Math.max(...arr)
                        setNoOfPeopleGivenRating(response.data.numberOfRaters);
                        setNumberOfRatings(ratingData + 3);
                        setRatingCountArray(response.data.numberOfRating)
                        setAverageRating(response.data.avgRating[0]?.avgrating);
                        if (response.data?.ratingDetails) {
                            let newArr = response.data.ratingDetails?.sort((a, b) => b.highlighted - a.highlighted)
                            setProductRating(newArr || [])
                            setSortedData(newArr);
                        }
                        console.log("response.data.reviewEnabled.reviewEnabled", response.data.reviewEnabled.reviewEnabled);
                        setReviewEnabled(response.data.reviewEnabled.reviewEnabled);
                    }
                })
                .catch(function (error) {
                    console.log("Error", error);
                });
        }
    };
    const getFeedbackForm = () => {
        let headers;
        if (localStorage.getItem('junkStore')) {
            headers = {
                'Accept': 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                'Content-Type': 'application/json'
            }
        } else {
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        var payload = {
            "productId": productId,
            "versionId": selectedVersion?.id
        }
        axios.post(config.userUrl + "user/getCurrentFeedbackForm", payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    console.log("CUURENT FORM ", response?.data)
                    if (response.data.feedbackForm.length === 0) {
                        setNoForm(true)
                        setSkipped(true);
                        return;
                    }
                    setFeedbackForm(response.data.feedbackForm[0])
                    setQuestions(response.data.feedbackForm[0]?.questions);
                    setResponses(response.data.feedbackForm[0]?.questions);
                    setCurrentQuest(response.data.feedbackForm[0]?.questions[0][1])
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    function commentRating1(e, val) {
        console.log("rating", val)
        setSelected1(true)
        setSelected2(false)
        setSelected3(false)
        setSelected4(false)
        setSelected5(false)
        setRating(val);
    }
    function commentRating2(e, val) {
        console.log("rating", val)
        setSelected1(false)
        setSelected2(true)
        setSelected3(false)
        setSelected4(false)
        setSelected5(false)
        setRating(val);
    }
    function commentRating3(e, val) {
        console.log("rating", val)
        setSelected1(false)
        setSelected2(false)
        setSelected3(true)
        setSelected4(false)
        setSelected5(false)
        setRating(val);
    }
    function commentRating4(e, val) {
        console.log("rating", val)
        setSelected1(false)
        setSelected2(false)
        setSelected3(false)
        setSelected4(true)
        setSelected5(false)
        setRating(val);
    }
    function commentRating5(e, val) {
        console.log("rating", val)
        setSelected1(false)
        setSelected2(false)
        setSelected3(false)
        setSelected4(false)
        setSelected5(true)
        setRating(val);
    }

    const data = {
        labels:
            ["User(s)", "User(s)", "User(s)", "User(s)", "User(s)"],
        datasets: [
            {
                data: [...ratingCountArray.map((data) => data.count), numberOfRatings],
                borderColor: "#FFB633",
                backgroundColor: ["#FFB633"],
                borderRadius: 20,
                borderWidth: 1,
                barPercentage: 1,
                categoryPercentage: 0.5,
            },
            {
                data: [...overallRatingCountArray.map((data) => data.count), overallNumberOfRatings],
                borderColor: "#FF94CB",
                borderWidth: 1,
                backgroundColor: ["#FF94CB"],
                borderRadius: 20,
                barPercentage: 1,
                categoryPercentage: 0.5,
            }
        ]
    }

    const handleRatingClear = () => {
        setSelectedRating(0);
        setRatingSortOption(0);
        setSortOption("")
        setSortOption2("")
    }
    const filter = () => {
        let ratingsArry = [...productRating]
        let sortedRatings = []
        if (sortOption2.length > 0) {
            if (sortOption2 === "Anonymous") {
                sortedRatings = ratingsArry.filter(rating => rating.userName === null)
            }
            else if (sortOption2 === "Registered") {
                sortedRatings = ratingsArry.filter(rating => rating.userName !== null)
            }
            else {
                sortedRatings = [...productRating];
            }
            ratingsArry = [...sortedRatings];
        }
        if (sortOption.length > 0) {
            if (sortOption === "Latest to Oldest") {
                sortedRatings = ratingsArry.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            }
            else {
                sortedRatings = ratingsArry.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
            }
            ratingsArry = [...sortedRatings];
        }
        if (ratingSortOption > 0 && ratingSortOption < 6) {
            sortedRatings = ratingsArry.filter(rating => rating.rating === ratingSortOption)
        }
        setSortedData(sortedRatings);
    }
    const handleToggle = () => {
        if (sortOption || sortOption2 || ratingSortOption)
            filter()
        setIsOpen(!isOpen)
    }
    const cleanUp = () => {
        setAddRating(false);
        setSelected1(false);
        setSelected2(false);
        setSelected3(false);
        setSelected4(false);
        setSelected5(false);
        setRating('');
        setRatingComment("");
        setSection("confirmation")
        setRatingType("feedback");
        setSkipped(false);
        setCurrentPage(1);
        setFeedbackRating(0);
        setGenericRating(0);
        setResponses([]);
    }
    const handleBackClick = () => {
        if (currentPage === 1) return;
        setCurrentPage(currentPage - 1)
        if (questions.hasOwnProperty(currentPage - 2)) {
            setCurrentPage(currentPage - 1);
            setCurrentQuest(questions[currentPage - 2][currentPage - 1])
        }
        else {
            setCurrentQuest(null)
        }
    }
    const handleForwardClick = () => {
        if (currentPage === questions?.length) return;
        setCurrentPage(currentPage + 1);
        if (questions.hasOwnProperty(currentPage)) {
            setCurrentQuest(questions[currentPage][currentPage + 1])
        }
        else {
            setCurrentQuest(null)
        }
    }
    const handleFeedbackRating = (val1, val2) => {
        let res = [...responses];
        console.log("Rating", feedbackRating, val1, val2);
        if ((currentQuest["scoringDirection"] === "Ascending" && currentQuest?.resposne === val1) || (currentQuest["scoringDirection"] === "Descending" && currentQuest?.resposne === val2)) {
            setFeedbackRating(0)
            return;
        }
        if (currentQuest["scoringDirection"] === "Ascending") {
            setFeedbackRating(val1);
            res[currentPage - 1][currentPage]["resposne"] = val1;
        }
        else {
            setFeedbackRating(val2);
            res[currentPage - 1][currentPage]["resposne"] = val2;
        }
        setResponses(res)
    }
    const handleGenericRating = (val) => {
        if (genericRating === val) {
            setGenericRating(0)
            return;
        }
        setGenericRating(val);
        let res = [...responses];
        res[currentPage - 1][currentPage]["resposne"] = { "rating": val };
        setResponses(res)
    }
    const postFeedback = () => {
        if (localStorage.getItem('junkStore')) {
            let headers;
            if (localStorage.getItem('junkStore')) {
                headers = {
                    'Accept': 'application/json',
                    "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                    'Content-Type': 'application/json'
                }
            } else {
                headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
            var tokenId = encrypt({ "companyId": companyId, "productId": productId })
            var payload = {
                "tokenId": tokenId,
                "versionId": selectedVersion.id,
                "responses": skipped ? JSON.stringify(responses[0]) : JSON.stringify(responses),
                formId: feedbackForm?.id
            }
            axios.post(config.userUrl + 'user/addFeedbackByUser', payload, { headers: headers })
                .then(function (response) {
                    // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                    if (response.data.status === true) {
                        console.log("success");
                        getProductRating()
                        getRatingComments(selectedVersion)
                        cleanUp();
                        validateReviewAdded();
                    } else {
                        console.log("error", payload);
                    }
                })
                .catch(function (error) {
                    console.log("Error", error);
                });
        } else {
            console.log("No Suggestion token")
        }
    };
    const getMaxLength = (wordLimit) => {
        if (!wordLimit) return;
        if (wordLimit === "Max 50 words") return "50";
        else if (wordLimit === "50 - 100 words") return "100";
        else if (wordLimit === "Max 100 words") return "100";
        else return "150";

    }
    const getMinLength = (wordLimit) => {
        if (!wordLimit) return;
        if (wordLimit === "Max 50 words") return "0";
        else if (wordLimit === "50 - 100 words") return "50";
        else if (wordLimit === "Max 100 words") return "0";
        else return "0";

    }
    const minLength = getMinLength(currentQuest?.wordLimit)
    const maxLength = getMaxLength(currentQuest?.wordLimit)

    return (
        <div className="mt-2">
            <AccessPopUp active={accessPopUp} closePopUp={() => setAccessPopUp(false)} type={"Review"} />
            <div className={`rating-input cursor-pointer ${reviewEnabled ? "flex items-center" : "hidden"}`} onClick={addRatingPopup}>
                <div className="inp-1">How <img src={feddbacklogoblack} style={{ paddingLeft: "12px", width: "75px", height: "70px" }} /></div>
                <div className="inp-2">are you?</div>
                <div className="flex gap-1 cursor-pointer ml-5 items-center justify-center">
                    <span className="highlight-container"><span className="highlight"> <img src={rating1} className="w-9 h-9" /></span></span>
                    <br />
                    <span className="highlight-container"><span className="highlight"><img src={rating2} className="w-9 h-9" /></span></span>
                    <br />
                    <span className="highlight-container"><span className="highlight"> <img src={rating3} className="w-9 h-9" /> </span></span>
                    <br />
                    <span className="highlight-container"><span className="highlight"> <img src={rating4} className="w-9 h-9" /></span></span>
                    <br />
                    <span className="highlight-container"><span className="highlight">  <img src={rating5} className="w-9 h-9" /> </span></span>
                    <br />
                </div>
            </div>
            <div className="bg-white border-dashed border-orange-600 rounded-[10px] p-4 mt-4 " style={{ borderWidth: "2px 2px 0 0" }}>
                <div >
                    <span className="font-medium text-2xl leading-9">User Rating Overview</span>
                    <div style={{ float: "right", display: "flex" }}><span className="followers-sm-c">({noOfPeopleGivenRating}) </span><span className="font-medium text-2xl leading-4">{averageRating}</span></div>
                    <div className='flex justify-center items-center my-2 gap-4'>
                        <div className='flex justify-center items-center'><div className='w-4 h-2 border-orange-300 bg-orange-300'></div><span className='ml-1'> Current Version</span></div>
                        <div className='flex justify-center items-center'><div className='w-4 h-2 border-pink-400 bg-pink-400'></div><span className='ml-1'> Overall</span></div>
                    </div>
                </div>
                <div className="flex">
                    <div className="inline-block flex-shrink-0 ">
                        <span><img src={rating5} className="w-12 h-12 flex-shrink-0 bg-orange-600 mt-1" /></span>
                        <span><img src={rating4} className="w-12 h-12 flex-shrink-0 bg-orange-600 mt-[9px]" /></span>
                        <span><img src={rating3} className="w-12 h-12 flex-shrink-0 bg-orange-600 mt-[10px]" /></span>
                        <span><img src={rating2} className="w-12 h-12 flex-shrink-0 bg-orange-600 mt-[10px]" /></span>
                        <span><img src={rating1} className="w-12 h-12 flex-shrink-0 bg-orange-600 mt-[10px]" /></span>
                    </div>
                    <div className="relative w-full" style={{ width: "600px", height: "300px" }}>
                        <div className="absolute w-8 h-3 top-0 left-0 bg-white z-10"></div>
                        <Bar data={data} plugins={[ChartDataLabels]}
                            redraw={false}
                            options={{
                                indexAxis: 'y',
                                responsive: true,
                                scales: {
                                    x: {
                                        ticks: {
                                            display: false
                                        },
                                        grid: {
                                            drawBorder: false,
                                            display: false,
                                        },
                                    },

                                    y: {
                                        ticks: {
                                            display: false,
                                        },
                                        grid: {
                                            drawBorder: false,
                                            display: false,
                                        },

                                    },
                                },
                                plugins: {
                                    legend: { display: false },
                                    datalabels: {
                                        color: '#000000',
                                        anchor: 'end',
                                        align: 'end',
                                        offset: 0,
                                        font: {
                                            size: 14,
                                        },

                                        formatter: function (value) {
                                            if (value > 0)
                                                return value + ' Users';
                                            else
                                                return "0 Users";

                                        },
                                    },
                                },
                                tooltips: {
                                    callbacks: {
                                        title: function (tooltipItem, data) {
                                            return data['labels'][tooltipItem[0]['index']];
                                        },
                                        labelColor: function (context) {
                                            return {
                                                backgroundColor: 'white',
                                            };
                                        },
                                    },
                                    label: function (tooltipItem, data) {
                                        return data['datasets'][0]['data'][tooltipItem['index']];
                                    },
                                    afterLabel: function (tooltipItem, data) {
                                        var dataset = data['datasets'][0];
                                        var percent = 95;
                                        return '(' + percent + '%)';
                                    }
                                }
                            }
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="mt-1 feddback-company">
                <div className="mt-2 mb-32">
                    <div className="rating-title">
                        <p className="rating-text">Top User Rating</p>
                        <div className='btn-outer'>
                            <div className='btn-1'>
                            </div>
                            {/* <Button variant="default" className="btn-2">Sort & Filter <b><i className="fa fa-angle-down"></i></b></Button> */}
                            <Dropdown className="btn-2 flex justify-end items-end" align="end" show={isOpen} onToggle={handleToggle}>
                                <Dropdown.Toggle variant="default" className="text-white focus:outline-none focus:ring-0" >
                                    <span className="text-center text-lg">Sort & Filter <b><i className="fa fa-angle-down"></i></b></span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="border-orange-400 rounded-lg min-w-[15rem] z-20">
                                    <span className='text-black px-2 text-lg'>Sort</span>
                                    <div className="flex flex-col gap-1 text-balck font-medium px-2 mb-2">
                                        <div className='flex items-center gap-2'>
                                            <button
                                                className={`rounded-sm w-3 h-3 focus:outline-none ${sortOption === "" ? 'bg-orange-600 text-white' : 'bg-gray-200'
                                                    }`}
                                                onClick={() => setSortOption("")}
                                            >
                                            </button>
                                            <span>Default</span>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <button
                                                className={`rounded-sm w-3 h-3 focus:outline-none ${sortOption === "Latest to Oldest" ? 'bg-orange-600 text-white' : 'bg-gray-200'
                                                    }`}
                                                onClick={() => setSortOption("Latest to Oldest")}
                                            >
                                            </button>
                                            <span>Latest to Oldest</span>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <button
                                                className={`rounded-sm w-3 h-3 focus:outline-none ${sortOption === "Oldest to Latest" ? 'bg-orange-600 text-white' : 'bg-gray-200'
                                                    }`}
                                                onClick={() => setSortOption("Oldest to Latest")}
                                            >
                                            </button>
                                            <span>Oldest to Latest</span>
                                        </div>
                                    </div>
                                    <div className='text-md font-medium px-2 flex items-center justify-between text-lg'>Filter<span className='bg-orange-600 rounded-xl text-white text-sm w-16 flex items-center justify-between pl-2 cursor-pointer' onClick={handleRatingClear}>Clear <img src={cancelWhiteIcon} alt='' className='float-right w-4 cursor-pointer' /></span></div>
                                    <div className="mt-2">
                                        <div className="flex items-center justify-center" style={{ cursor: "pointer", display: "flex" }}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-top`}>
                                                        <div className='left-align'>
                                                            1
                                                        </div>
                                                    </Tooltip>
                                                }
                                            >
                                                <span className="highlight-container" onClick={() => { setSelectedRating(1); setRatingSortOption(1) }}><span className="highlight"> {selectedRating === 1 ? <img src={orating1} style={{ width: "30px", cursor: "pointer" }} /> : <img src={rating1} className="rating-hover" style={{ width: "30px", cursor: "pointer" }} />}&nbsp;</span></span>
                                            </OverlayTrigger><br />
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-top`}>
                                                        <div className='left-align'>
                                                            2
                                                        </div>
                                                    </Tooltip>
                                                }
                                            >
                                                <span className="highlight-container" onClick={() => { setSelectedRating(2); setRatingSortOption(2) }}><span className="highlight">{selectedRating === 2 ? <img src={orating2} style={{ width: "30px", cursor: "pointer" }} /> : <img src={rating2} className="rating-hover" style={{ width: "30px", cursor: "pointer" }} />}&nbsp;</span></span>
                                            </OverlayTrigger><br />
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-top`}>
                                                        <div className='left-align'>
                                                            3
                                                        </div>
                                                    </Tooltip>
                                                }
                                            >
                                                <span className="highlight-container"><span className="highlight" onClick={() => { setSelectedRating(3); setRatingSortOption(3) }}>  {selectedRating === 3 ? <img src={orating3} style={{ width: "30px", cursor: "pointer" }} /> : <img src={rating3} className="rating-hover" style={{ width: "30px", cursor: "pointer" }} />}&nbsp;</span></span>
                                            </OverlayTrigger><br />
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-top`}>
                                                        <div className='left-align'>
                                                            4
                                                        </div>
                                                    </Tooltip>
                                                }
                                            >
                                                <span className="highlight-container"><span className="highlight" onClick={() => { setSelectedRating(4); setRatingSortOption(4) }}>   {selectedRating === 4 ? <img src={orating4} style={{ width: "30px", cursor: "pointer" }} /> : <img src={rating4} className="rating-hover" style={{ width: "30px", cursor: "pointer" }} />}&nbsp;</span></span>
                                            </OverlayTrigger><br />

                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-top`}>
                                                        <div className='left-align'>
                                                            5
                                                        </div>
                                                    </Tooltip>
                                                }
                                            >
                                                <span className="highlight-container"><span className="highlight" onClick={() => { setSelectedRating(5); setRatingSortOption(5) }}>  {selectedRating === 5 ? <img src={orating5} style={{ width: "30px", cursor: "pointer" }} /> : <img src={rating5} className="rating-hover" style={{ width: "30px", cursor: "pointer" }} />}&nbsp;</span></span>
                                            </OverlayTrigger><br />

                                        </div>
                                        <p className="text-center text-sm -mt-5 mb-2">Choose a face</p>
                                    </div>
                                    <span className='text-black px-2 mt-4 text-lg'>Review Type</span>
                                    <div className="flex flex-col gap-1 text-black font-medium px-2 mb-4">
                                        <div className='flex items-center gap-2'>
                                            <button
                                                className={`rounded-sm w-3 h-3 focus:outline-none ${sortOption2 === "Registered" ? 'bg-orange-600 text-white' : 'bg-gray-200'
                                                    }`}
                                                onClick={() => setSortOption2("Registered")}
                                            >
                                            </button>
                                            <span>Registered Users</span>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <button
                                                className={`rounded-sm w-3 h-3 focus:outline-none ${sortOption2 === "Anonymous" ? 'bg-orange-600 text-white' : 'bg-gray-200'
                                                    }`}
                                                onClick={() => setSortOption2("Anonymous")}
                                            >
                                            </button>
                                            <span>Anonymous Users</span>
                                        </div>
                                    </div>
                                    {/* <div className="flex justify-end mt-4 px-2">
                                        <span className="bg-orange-600 text-white rounded-lg text-sm p-1 cursor-pointer" onClick={filter}>Apply Filters</span>
                                    </div> */}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>

                    {sortedData.length == 0 ? <Card className="mt-2 border-none no_pages"><center className="no-details-text"><img src={downvoteb} className="no-pages-img" /><br />No Ratings</center></Card> :
                        sortedData.map((data, i) => !data.comment ? <></> : (
                            <div className="mt-3" key={i}>
                                {data.comment == "" ? "" :
                                    <Card key={data.id} className="review-card">
                                        <div className="row pt-3 pr-4 relative">
                                            {
                                                data?.highlighted
                                                    ?
                                                    <img src={pinkHeart} alt="" className='absolute -top-3 -left-2' />
                                                    : ''
                                            }
                                            <Col md={12}>
                                                <div className="card-padding flex gap-2 justify-center items-center">
                                                    <div className="flex flex-col justify-center items-center pl-2 transform translate-y-4">
                                                        <img src={data.rating === 5 ? orating5 : data.rating === 4 ? orating4 : data.rating === 3 ? orating3 : data.rating === 2 ? orating2 : orating1} style={{ width: "60px" }} />
                                                        <p className="min-w-max font-semibold text-sm text-center">{userRatingDesc(data.rating)}</p>
                                                    </div>
                                                    <div className="flex-grow w-full text-center pr-10">
                                                        <p className="c-rating productName font-medium">{data.comment}</p>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col justify-end items-end transform -translate-y-3">
                                                    <div className="font-medium text-sm italic">-{data.userName == null ? "Anonymous" : data.userName}</div>
                                                    <div style={{ fontWeight: "400", fontSize: "10px" }} className='text-gray-500'>Posted on {moment(data.updatedAt, "YYYY-MM-DD HH:mm:ss.SSSZ").format("DD/MM/YY")}</div>
                                                </div>
                                            </Col>
                                        </div>
                                    </Card>
                                }
                            </div>
                        ))
                    }
                </div>
            </div>

            {/* Add Rating Modal */}
            <Modal
                size="lg"
                show={addRating}
                onHide={() => cleanUp()}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName='add-rating-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title >
                        <h1 className='cursor-pointer absolute top-4 right-10 text-lg font-normal' style={{ color: 'rgba(255, 160, 32, 1)' }} onClick={guidelines}>View Post Guidelines</h1>

                        {section === "confirmation" ?
                            <div>
                                <p className="mt-1 text-3xl text-orange-600 font-semibold">&nbsp;Rate this Product</p>
                                <p className="mt-3 ml-2 text-lg">&nbsp;Would you like to</p>
                            </div>
                            : section === "rating" ?
                                <div>
                                    <p className="mt-1 text-3xl text-orange-600 font-semibold">&nbsp;Rate this Product</p>
                                    <p className="mt-1 ml-2 text-lg">&nbsp;Overall Rating for this version</p>
                                </div>
                                : section === "feedback" && !skipped ?
                                    <div className={`flex justify-center items-center gap-4 ml-44 w-full ${skipped ? "hidden" : ""}`}>
                                        <img src={currentPage > 1 ? arrowLeftBlack : arrowLeftGray} alt='' className='w-12 cursor-pointer' onClick={handleBackClick} />
                                        <p className=' text-black text-lg '>Page {currentPage}/{questions?.length}</p>
                                        <img src={(currentPage !== 4 && !(currentPage === questions?.length ? !genericRating : currentQuest?.required && !currentQuest?.response)) ? arrowRightBlack : arrowRightGray2} alt='' className='w-16 cursor-pointer' onClick={(e) => (currentPage === questions?.length ? !genericRating : currentQuest?.required && !currentQuest?.response) ? {} : handleForwardClick(e)} />
                                    </div>
                                    :
                                    <div className='flex justify-end items-end h-full'>
                                        <p className='text-black text-xl mx-3 invisible'>Rate your overall Experience for this version</p>
                                    </div>
                        }

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {section === "confirmation" ?
                        <div className='flex flex-col gap-16 min-h-[360px] justify-between'>
                            <div class="grid grid-cols-2 gap-8 mx-16">
                                {noForm ?
                                    <></>
                                    :
                                     <div className={`bg-[#FBFBFB] flex flex-col gap-3 p-2 rounded-md cursor-pointer border-4 ${ratingType === "feedback" ? "border-[#FFB633]" : "border-transparent"}`} onClick={() => setRatingType("feedback")}>
                                        <p className='text-lg font-semibold'>Fill Feedback Form</p>
                                        <p className='text-lg font-normal'>Quick 4 questions to help the creators improve this product more.</p>
                                        <img src={feedbackIcon} alt='' className='ml-auto w-10' />
                                    </div>}
                                <div className={`bg-[#FBFBFB] flex flex-col gap-3 p-2 rounded-md ${ratingAccess ? 'cursor-pointer' : ''} border-4 relative ${ratingType === "rating" ? "border-[#FFB633]" : " border-transparent"}`} onClick={() => ratingAccess ? setRatingType("rating") : {}}>
                                    <p className='text-lg font-semibold'>Rate Only</p>
                                    <p className='text-lg font-normal'>Rate the application and provide additional thoughts.</p>
                                    <img src={ratingIcon} alt='' className='ml-auto w-10 mt-auto' />
                                    {ratingAccess ? <></> : <div className='absolute inset-0 bg-black bg-opacity-50 rounded-md grid place-items-center'>
                                        <h1 className='text-yellow-400 text-xl'>Already Provided</h1>
                                    </div>}
                                </div>
                            </div>
                            <div className="flex justify-end items-center gap-6">
                                <div className="text-orange-600 text-lg p-2 cursor-pointer"
                                    onClick={cleanUp}
                                >Cancel</div>
                                <div className='btn-outer'>
                                    <div className='btn-1' style={{ width: "120px", height: "30px" }}>
                                    </div>
                                    <Button
                                        variant="default"
                                        className="btn-2" style={{ width: "120px", height: "30px", lineHeight: "12px", marginLeft: "auto" }}
                                        onClick={() => {
                                            if (ratingType === "rating") setSection("rating")
                                            else {
                                                if (questions?.length)
                                                    setSection("feedback")
                                                else {
                                                    setSection("rating");
                                                }
                                            }
                                        }}
                                    >Continue</Button>
                                </div>
                            </div>
                        </div>
                        : 
                        section === "rating" ?
                            <div className="-mt-3 mb-2 min-h-[360px] flex flex-col justify-between">
                                <Form className='w-full max-w-2xl mx-auto'>
                                    <div className="flex justify-center items-center gap-0.5 cursor-pointer pb-8">
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id={`tooltip-top`}>
                                                    <div className='left-align'>
                                                        Fed Up!
                                                    </div>
                                                </Tooltip>
                                            }
                                        >
                                            <div className={`flex flex-col items-center h-14 gap-1 ${selected1 ? 'opacity-100' : 'opacity-40'}`}>
                                                <img src={selected1 ? orating1 : rating ? grating1 : rating1} onClick={(e, i) => commentRating1(e, 1)} className={`w-12 h-12 cursor-pointer`} />
                                                <span className={`text-sm font-medium ${selected1 ? "" : "hidden"}`}>Fed Up!</span>
                                            </div>
                                        </OverlayTrigger><br />
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id={`tooltip-top`}>
                                                    <div className='left-align'>
                                                        Hate it!
                                                    </div>
                                                </Tooltip>
                                            }
                                        >
                                            <div className={`flex flex-col items-center h-14 gap-1 ${selected2 ? 'opacity-100' : 'opacity-40'}`}>
                                                <img src={selected2 ? orating2 : rating ? grating2 : rating2} onClick={(e, i) => commentRating2(e, 2)} className={`w-12 h-12 cursor-pointer`} />
                                                <span className={`text-sm font-medium ${selected2 ? "" : "hidden"}`}>Hate it!</span>
                                            </div>
                                        </OverlayTrigger><br />
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id={`tooltip-top`}>
                                                    <div className='left-align'>
                                                        It's Ok!
                                                    </div>
                                                </Tooltip>
                                            }
                                        >
                                            <div className={`flex flex-col items-center h-14 gap-1 ${selected3 ? 'opacity-100' : 'opacity-40'}`}>
                                                <img src={selected3 ? orating3 : rating ? grating3 : rating3} onClick={(e, i) => commentRating3(e, 3)} className={`w-12 h-12 cursor-pointer`} />
                                                <span className={`text-sm font-medium ${selected3 ? "" : "hidden"}`}>It's Ok!</span>
                                            </div>
                                        </OverlayTrigger><br />
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id={`tooltip-top`}>
                                                    <div className='left-align'>
                                                        Like it!
                                                    </div>
                                                </Tooltip>
                                            }
                                        >
                                            <div className={`flex flex-col items-center h-14 gap-1 ${selected4 ? 'opacity-100' : 'opacity-40'}`}>
                                                <img src={selected4 ? orating4 : rating ? grating4 : rating4} onClick={(e, i) => commentRating4(e, 4)} className={`w-12 h-12 cursor-pointer`} />
                                                <span className={`text-sm font-medium ${selected4 ? "" : "hidden"}`}>Like it!</span>
                                            </div>
                                        </OverlayTrigger><br />

                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id={`tooltip-top`}>
                                                    <div className='left-align'>
                                                        Love it!
                                                    </div>
                                                </Tooltip>
                                            }
                                        >
                                            <div className={`flex flex-col items-center h-14 gap-1 ${selected5 ? 'opacity-100' : 'opacity-40'}`}>
                                                <img src={selected5 ? orating5 : rating ? grating5 : rating5} onClick={(e, i) => commentRating5(e, 5)} className={`w-12 h-12 cursor-pointe`} />
                                                <span className={`text-sm font-medium  ${selected5 ? "" : "hidden"}`}>Love it!</span>
                                            </div>
                                        </OverlayTrigger><br />

                                    </div>
                                    <textarea
                                        className="mt-3 form-control border placeholder-gray-500 text-black focus:text-black focus:ring-0"
                                        id="exampleFormControlTextarea1"
                                        style={{ fontFamily: "poppins", resize: "none" }}
                                        rows="5"
                                        maxLength={150}
                                        name="ratingComment"
                                        value={ratingComment}
                                        placeholder="Additional thoughts"
                                        onChange={(e) => setRatingComment(e.target.value)}
                                    />
                                    <div style={{ fontFamily: "poppins", fontSize: "12px" }}>{ratingComment.length}/150 characters</div>
                                </Form>
                                {/* <div className="mt-3 suggestion-box-text" style={{ float: "left" }}>Post guidelines <span style={{ color: "#E6570E" }}>read here.</span></div> */}
                                <br />
                                <div className="flex justify-end items-center gap-6">
                                    <div className="text-orange-600 text-lg p-2 cursor-pointer"
                                        onClick={cleanUp}
                                    >Cancel</div>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                {(!rating || !ratingComment) ?
                                                    <div className='tool-text'>
                                                        Please add Rating and Description
                                                    </div>
                                                    : null
                                                }
                                            </Tooltip>
                                        }
                                    >
                                        {(rating.length != 0) ?
                                            <div className='btn-outer'>
                                                <div className='btn-1' style={{ width: "120px", height: "30px" }}>
                                                </div>
                                                <Button
                                                    variant="default"
                                                    className="btn-2" style={{ width: "120px", height: "30px", lineHeight: "12px", marginLeft: "auto" }}
                                                    onClick={postRating}
                                                >Submit</Button>
                                            </div> :
                                            <div className='btn-outer'>
                                                <div className='btn-1' style={{ width: "120px", height: "30px" }}>
                                                </div>
                                                <Button
                                                    variant="default"
                                                    disabled={true}
                                                    className="btn-2 disabled" style={{ color: "gray", width: "120px", height: "30px", lineHeight: "12px", marginLeft: "auto" }}>Submit</Button>
                                            </div>
                                        }
                                    </OverlayTrigger>
                                </div>
                            </div>
                            :
                             section === "feedback" ?
                                <div className='flex flex-col justify-between gap-6'>
                                    <Row className='flex justify-between rounded-xl px-20'>
                                        {skipped ?
                                            <div key={currentPage - 1 + "textfield"} className='bg-white rounded-xl w-full'>
                                                <p className='text-black text-2xl -mt-8 mb-12'>Rate your overall Experience for this version</p>
                                                <div className="flex justify-center items-center gap-0.5 cursor-pointer pb-8">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id={`tooltip-top`}>
                                                                <div className='left-align'>
                                                                    Fed Up!
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div className={`flex flex-col items-center h-14 gap-1 ${genericRating === 1 ? 'opacity-100' : 'opacity-40'}`}>
                                                            <img src={genericRating === 1 ? orating1 : rating1} onClick={(e, i) => handleGenericRating(1)} className={`w-12 h-12 cursor-pointer`} />
                                                            <span className={`text-sm font-medium ${genericRating === 1 ? "" : "hidden"}`}>Fed Up!</span>
                                                        </div>
                                                    </OverlayTrigger><br />
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id={`tooltip-top`}>
                                                                <div className='left-align'>
                                                                    Hate it!
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div className={`flex flex-col items-center h-14 gap-1 ${genericRating === 2 ? 'opacity-100' : 'opacity-40'}`}>
                                                            <img src={genericRating === 2 ? orating2 : rating2} onClick={(e, i) => handleGenericRating(2)} className={`w-12 h-12 cursor-pointer`} />
                                                            <span className={`text-sm font-medium ${genericRating === 2 ? "" : "hidden"}`}>Hate it!</span>
                                                        </div>
                                                    </OverlayTrigger><br />
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id={`tooltip-top`}>
                                                                <div className='left-align'>
                                                                    It's Ok!
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div className={`flex flex-col items-center h-14 gap-1 ${genericRating === 3 ? 'opacity-100' : 'opacity-40'}`}>
                                                            <img src={genericRating === 3 ? orating3 : rating3} onClick={(e, i) => handleGenericRating(3)} className={`w-12 h-12 cursor-pointer`} />
                                                            <span className={`text-sm font-medium ${genericRating === 3 ? "" : "hidden"}`}>It's Ok!</span>
                                                        </div>
                                                    </OverlayTrigger><br />
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id={`tooltip-top`}>
                                                                <div className='left-align'>
                                                                    Like it!
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div className={`flex flex-col items-center h-14 gap-1 ${genericRating === 4 ? 'opacity-100' : 'opacity-40'}`}>
                                                            <img src={genericRating === 4 ? orating4 : rating4} onClick={(e, i) => handleGenericRating(4)} className={`w-12 h-12 cursor-pointer`} />
                                                            <span className={`text-sm font-medium ${genericRating === 4 ? "" : "hidden"}`}>Like it!</span>
                                                        </div>
                                                    </OverlayTrigger><br />

                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id={`tooltip-top`}>
                                                                <div className='left-align'>
                                                                    Love it!
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div className={`flex flex-col items-center h-14 gap-1 ${genericRating === 5 ? 'opacity-100' : 'opacity-40'}`}>
                                                            <img src={genericRating === 5 ? orating5 : rating5} onClick={(e, i) => handleGenericRating(5)} className={`w-12 h-12 cursor-pointe`} />
                                                            <span className={`text-sm font-medium  ${genericRating === 5 ? "" : "hidden"}`}>Love it!</span>
                                                        </div>
                                                    </OverlayTrigger><br />

                                                </div>
                                                <textarea
                                                    className="border border-gray-400 rounded p-2 min-h-[12rem] mt-6 resize-none w-full border-gray focus:outline-none placeholder:text-gray-500 focus:text-black text-black"
                                                    placeholder="Additional Thoughts"
                                                    value={responses[currentPage - 1][currentPage]?.["resposne"]?.["details"]}
                                                    onChange={(e) => responses[currentPage - 1][currentPage]["resposne"] = responses[currentPage - 1]?.[currentPage]?.["resposne"] ? { ...responses[currentPage - 1]?.[currentPage]?.["resposne"], "details": e.target.value } : { "details": e.target.value }}
                                                />
                                            </div> :
                                            currentQuest ?
                                                <Col md={12} >
                                                    <p className='text-lg text-gray-300'>Question {currentPage}</p>
                                                    <p className='text-base mt-1 text-black font-semibold'>{currentQuest["question"]}<span className={currentQuest["required"] ? `text-red-600 text-xl` : "hidden"}>*</span></p>
                                                    {currentQuest && currentQuest.type === "mcq" ?
                                                        <div key={currentPage - 1 + "mcq"} className='bg-white h-max py-8 rounded-xl mb-4'>

                                                            <form className='flex flex-col gap-3 mt-2'>
                                                                {currentQuest.options?.map((option, index) => {
                                                                    return (
                                                                        <label className='flex gap-3' key={index + "mcqoptions"}>
                                                                            <input type="checkbox" name={currentQuest["question"]} value={option}
                                                                                onChange={(e) => {
                                                                                    let currentResponses = [...responses];
                                                                                    let arr = currentResponses[currentPage - 1][currentPage]["response"]?.toString()
                                                                                    arr = arr?.split(",")
                                                                                    if (Array.isArray(arr)) {
                                                                                        if (arr.includes(e.target.value?.toString())) {
                                                                                            arr = arr.filter(item => item != e.target.value?.toString())
                                                                                            currentResponses[currentPage - 1][currentPage]["response"] = `${arr.join(",")}`;
                                                                                        }
                                                                                        else {
                                                                                            arr.push(e.target.value?.toString());
                                                                                            currentResponses[currentPage - 1][currentPage]["response"] = `${arr.join(",")}`;
                                                                                        }
                                                                                    }
                                                                                    else {
                                                                                        currentResponses[currentPage - 1][currentPage]["response"] = e.target.value?.toString();
                                                                                    }

                                                                                    setResponses(currentResponses)
                                                                                }}
                                                                                checked={responses[currentPage - 1][currentPage]["response"]?.split(",").includes(option?.toString())}
                                                                            />
                                                                            {option}
                                                                        </label>
                                                                    )
                                                                })}
                                                            </form>
                                                        </div>
                                                        :
                                                        currentQuest && currentQuest.type === "singlechoice" ?
                                                            <div key={currentPage - 1 + "singlechoice"} className='bg-white h-max py-8 rounded-xl mb-4'>
                                                                <form className='flex flex-col gap-3 mt-2'>
                                                                    {currentQuest.options?.map((option, index) => {
                                                                        return (
                                                                            <label className='flex gap-3' key={index + "singleoptions"}>
                                                                                <input type="radio" name={currentQuest["question"]} value={option}
                                                                                    onChange={(e) => {
                                                                                        let currentResponses = [...responses];
                                                                                        currentResponses[currentPage - 1][currentPage]["response"] = e.target.value;
                                                                                        setResponses(currentResponses)
                                                                                    }}
                                                                                    checked={responses[currentPage - 1][currentPage]["response"] === option}
                                                                                />
                                                                                {option}
                                                                            </label>
                                                                        )
                                                                    })}
                                                                </form>
                                                            </div>
                                                            :
                                                            currentQuest && currentQuest.type === "rating" ?
                                                                <div key={currentPage - 1 + "rating"} className='bg-white h-max py-16 rounded-xl mb-4'>
                                                                    {currentQuest["scaleType"] === "Yes-No" ?
                                                                        <div className='flex w-full justify-center gap-8'>
                                                                            <div className='flex items-center gap-2'>
                                                                                <span className='w-4 h-4 rounded-full border'></span>
                                                                                <p>Yes</p>
                                                                            </div>
                                                                            <div className='flex items-center gap-2'>
                                                                                <span className='w-4 h-4 rounded-full border'></span>
                                                                                <p>Uncertain</p>
                                                                            </div>
                                                                            <div className='flex items-center gap-2'>
                                                                                <span className='w-4 h-4 rounded-full border'></span>
                                                                                <p>No</p>
                                                                            </div>

                                                                        </div> :
                                                                        <div className='flex my-4 w-[50vw]'>
                                                                            <div className='flex w-32 gap-2 items-center'>
                                                                                <img src={currentQuest["scoringDirection"] === "Ascending" ? rating1 : rating5} alt=''
                                                                                    className={`${(currentQuest["scoringDirection"] === "Ascending" && currentQuest?.resposne === 1) || (currentQuest["scoringDirection"] === "Descending" && currentQuest?.resposne === 5) ? "bg-orange-600" : ""} w-10 cursor-pointer`}
                                                                                    onClick={() => handleFeedbackRating(1, 5)} />
                                                                                <p>{currentQuest["scoringDirection"] === "Ascending" ? currentQuest["ratingDesc"][0] : currentQuest["ratingDesc"][4]}</p>
                                                                            </div>
                                                                            <div className='flex w-32 gap-2 items-center'>
                                                                                <img src={currentQuest["scoringDirection"] === "Ascending" ? rating2 : rating4} alt=''
                                                                                    className={`${(currentQuest["scoringDirection"] === "Ascending" && currentQuest?.resposne === 2) || (currentQuest["scoringDirection"] === "Descending" && currentQuest?.resposne === 4) ? "bg-orange-600" : ""} w-10 cursor-pointer`}
                                                                                    onClick={() => handleFeedbackRating(2, 4)} />
                                                                                <p>{currentQuest["scoringDirection"] === "Ascending" ? currentQuest["ratingDesc"][1] : currentQuest["ratingDesc"][3]}</p>
                                                                            </div>
                                                                            <div className='flex w-32 gap-2 items-center'>
                                                                                <img src={rating3} alt=''
                                                                                    className={`${currentQuest?.resposne === 3 ? "bg-orange-600" : ""} w-10 cursor-pointer`}
                                                                                    onClick={() => handleFeedbackRating(3, 3)} />
                                                                                <p>{currentQuest["ratingDesc"][2]}</p>
                                                                            </div>
                                                                            <div className='flex w-32 gap-2 items-center'>
                                                                                <img src={currentQuest["scoringDirection"] === "Ascending" ? rating4 : rating2} alt=''
                                                                                    className={`${(currentQuest["scoringDirection"] === "Ascending" && currentQuest?.resposne === 4) || (currentQuest["scoringDirection"] === "Descending" && currentQuest?.resposne === 2) ? "bg-orange-600" : ""} w-10 cursor-pointer`}
                                                                                    onClick={() => handleFeedbackRating(4, 2)} />
                                                                                <p>{currentQuest["scoringDirection"] === "Ascending" ? currentQuest["ratingDesc"][3] : currentQuest["ratingDesc"][1]}</p>
                                                                            </div>
                                                                            <div className='flex w-32 gap-2 items-center'>
                                                                                <img src={currentQuest["scoringDirection"] === "Ascending" ? rating5 : rating1} alt=''
                                                                                    className={`${(currentQuest["scoringDirection"] === "Ascending" && currentQuest?.resposne === 5) || (currentQuest["scoringDirection"] === "Descending" && currentQuest?.resposne === 1) ? "bg-orange-600" : ""} w-10 cursor-pointer`}
                                                                                    onClick={() => handleFeedbackRating(5, 1)} />
                                                                                <p>{currentQuest["scoringDirection"] === "Ascending" ? currentQuest["ratingDesc"][4] : currentQuest["ratingDesc"][0]}</p>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                :
                                                                currentQuest && currentQuest.type === "textfield" ?
                                                                    <div key={currentPage - 1 + "textfield"} className='bg-white h-max py-8 rounded-xl mb-4'>
                                                                        <div className='border rounded-lg w-full flex flex-col gap-2'>
                                                                            <textarea
                                                                                className="p-2 min-h-[7rem] resize-none w-full focus:outline-none bg-white"
                                                                                placeholder={currentQuest["placeholder"] || "Let us know your thoughts"}
                                                                                onChange={(e) => {
                                                                                    let res = [...responses];
                                                                                    res[currentPage - 1][currentPage]["response"] = { "details": e.target.value }
                                                                                    setResponses(res)
                                                                                }}
                                                                                minLength={minLength}
                                                                                maxLength={maxLength}
                                                                                value={responses[currentPage - 1][currentPage]["response"]?.details}

                                                                            />
                                                                            <div className='flex justify-end p-2'>
                                                                                <p className='text-gray-400'>{currentQuest["wordLimit"]}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : currentQuest && currentQuest.type === "generic" ?
                                                                        <div key={currentPage - 1 + "textfield"} className='bg-white h-max rounded-xl mb-4'>
                                                                            <p className='mb-3 text-black text-lg'>Rate your overall Experience for this version</p>
                                                                            <div className="flex justify-center items-center gap-0.5 cursor-pointer pb-8">
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Tooltip id={`tooltip-top`}>
                                                                                            <div className='left-align'>
                                                                                                Fed Up!
                                                                                            </div>
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <div className={`flex flex-col items-center h-14 gap-1 ${genericRating === 1 ? 'opacity-100' : 'opacity-40'}`}>
                                                                                        <img src={genericRating === 1 ? orating1 : rating1} onClick={(e, i) => handleGenericRating(1)} className={`w-12 h-12 cursor-pointer`} />
                                                                                        <span className={`text-sm font-medium ${genericRating === 1 ? "" : "hidden"}`}>Fed Up!</span>
                                                                                    </div>
                                                                                </OverlayTrigger><br />
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Tooltip id={`tooltip-top`}>
                                                                                            <div className='left-align'>
                                                                                                Hate it!
                                                                                            </div>
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <div className={`flex flex-col items-center h-14 gap-1 ${genericRating === 2 ? 'opacity-100' : 'opacity-40'}`}>
                                                                                        <img src={genericRating === 2 ? orating2 : rating2} onClick={(e, i) => handleGenericRating(2)} className={`w-12 h-12 cursor-pointer`} />
                                                                                        <span className={`text-sm font-medium ${genericRating === 2 ? "" : "hidden"}`}>Hate it!</span>
                                                                                    </div>
                                                                                </OverlayTrigger><br />
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Tooltip id={`tooltip-top`}>
                                                                                            <div className='left-align'>
                                                                                                It's Ok!
                                                                                            </div>
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <div className={`flex flex-col items-center h-14 gap-1 ${genericRating === 3 ? 'opacity-100' : 'opacity-40'}`}>
                                                                                        <img src={genericRating === 3 ? orating3 : rating3} onClick={(e, i) => handleGenericRating(3)} className={`w-12 h-12 cursor-pointer`} />
                                                                                        <span className={`text-sm font-medium ${genericRating === 3 ? "" : "hidden"}`}>It's Ok!</span>
                                                                                    </div>
                                                                                </OverlayTrigger><br />
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Tooltip id={`tooltip-top`}>
                                                                                            <div className='left-align'>
                                                                                                Like it!
                                                                                            </div>
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <div className={`flex flex-col items-center h-14 gap-1 ${genericRating === 4 ? 'opacity-100' : 'opacity-40'}`}>
                                                                                        <img src={genericRating === 4 ? orating4 : rating4} onClick={(e, i) => handleGenericRating(4)} className={`w-12 h-12 cursor-pointer`} />
                                                                                        <span className={`text-sm font-medium ${genericRating === 4 ? "" : "hidden"}`}>Like it!</span>
                                                                                    </div>
                                                                                </OverlayTrigger><br />

                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Tooltip id={`tooltip-top`}>
                                                                                            <div className='left-align'>
                                                                                                Love it!
                                                                                            </div>
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <div className={`flex flex-col items-center h-14 gap-1 ${genericRating === 5 ? 'opacity-100' : 'opacity-40'}`}>
                                                                                        <img src={genericRating === 5 ? orating5 : rating5} onClick={(e, i) => handleGenericRating(5)} className={`w-12 h-12 cursor-pointe`} />
                                                                                        <span className={`text-sm font-medium  ${genericRating === 5 ? "" : "hidden"}`}>Love it!</span>
                                                                                    </div>
                                                                                </OverlayTrigger><br />

                                                                            </div>
                                                                            <textarea
                                                                                className="border border-gray-400 rounded p-2 min-h-[10rem] mb-2 resize-none w-full border-gray focus:outline-none placeholder:text-gray-500 focus:text-black text-black"
                                                                                placeholder="Additional Thoughts"
                                                                                value={responses[currentPage - 1][currentPage]["resposne"]?.["details"]}
                                                                                onChange={(e) => responses[currentPage - 1][currentPage]["resposne"] = { ...responses[currentPage - 1][currentPage]["resposne"], "details": e.target.value }}
                                                                            />
                                                                        </div> :

                                                                        <></>}
                                                </Col> : <></>}
                                    </Row>
                                    <div className="flex justify-between items-center gap-6 ">
                                        <div className="text-orange-600 text-lg p-2 cursor-pointer"
                                            onClick={cleanUp}
                                        >Cancel</div>
                                        <div className='flex items-center gap-4'>
                                            {currentQuest && currentQuest.type === "generic" ? <></> : <p className={`text-sm cursor-pointer text-[#E6570E] ${skipped ? "hidden" : ""}`}
                                                onClick={() => { setSkipped(true); responses[0][1] = { "question": "", "options": [], "type": "generic", "required": true } }}
                                            >Skip  & Provide overall feedback</p>}
                                            {/* <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                    <Tooltip id={`tooltip-top`}>
                                                       
                                                    </Tooltip>
                                                }
                                            > */}
                                            <div className='btn-outer group'>
                                                {(skipped ? !genericRating : currentPage === questions?.length ? !genericRating : currentQuest?.required && !currentQuest?.response) ?
                                                    <p className='hidden group-hover:inline-block bg-stone-100 py-1.5 px-4 rounded-lg absolute -top-12 right-0 min-w-max'>
                                                        Please provide response
                                                    </p>
                                                    : ''
                                                }
                                                <div className='btn-1' style={{ width: "120px", height: "30px" }}>
                                                </div>
                                                <Button
                                                    disabled={skipped ? !genericRating : currentPage === questions?.length ? (currentQuest?.resposne || currentQuest?.response ) ? false : !genericRating : currentQuest?.required && !currentQuest?.response}
                                                    variant="default"
                                                    className="btn-2" style={{ width: "120px", height: "30px", lineHeight: "12px", marginLeft: "auto" }}
                                                    onClick={() => {
                                                        if (currentPage === questions?.length || skipped)
                                                            postFeedback();
                                                        else
                                                            handleForwardClick();
                                                    }}
                                                >{currentPage === questions?.length || skipped ? "Submit" : "Next"}</Button>
                                            </div>
                                            {/* </OverlayTrigger> */}
                                        </div>
                                    </div>
                                </div>
                                : <>
                                </>
                    }
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default ProductRatingSection
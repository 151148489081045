import { React, Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Form, Button } from "react-bootstrap";
import logowhite from '../images/fedduplogo-white.png';
import './Footer.css';

export default class Footer extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="mt-3 footer_bg">
                <Row style={{ width: "100%" }}>
                    <Col xs={12} md={4}>
                        <img src={logowhite} className="footer-logo" /><br />
                        <div className='mt-2 footer-text'>
                        A threadality Technology Product.<br/> Helping customers and companies in <br/>easy accessible and quick feedbacks<br/> and rating
                        </div>
                        <div className='mt-4 footer-text'>
                            Copyright {new Date().getFullYear()} Feddup.<br />All right reserved.
                        </div><br/>
                    </Col>
                    <Col xs={12} md={5}>
                        <Row style={{ width: "100%" }}>
                            <Col xs={12} md={4}>
                                {/* <div className='links'><b>Community</b></div> */}
                                <a> <p className='mt-5 links-sm'>Pricing</p></a>
                                <a href="/faqs"> <p className='links-sm'>FAQs</p></a>
                            </Col>
                            <Col xs={12} md={4}>
                                <a> <p className='mt-5 links-sm'>User Agreement</p></a>
                                <a> <p className='links-sm'>Privacy Policy</p></a>
                                <a> <p className='links-sm'>Content Policy</p></a>
                                <a href="/help"> <p className='links-sm'>Moderator Guidelines</p></a>
                            </Col>
                            {/* <Col xs={12} md={4}>
                                <a> <p className='mt-5 links-sm'>Linkedin</p></a>
                                <a> <p className='links-sm'>Facebook</p></a>
                                <a> <p className='links-sm'>Instagram</p></a>
                                <a> <p className='links-sm'>Twitter</p></a>
                            </Col> */}
                        </Row>
                    </Col>
                    {/* <Col xs={12} md={3}>
                        <Form className='newsletter'>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Newsletter Subscription</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" />
                            </Form.Group>
                            <Button variant="default" className='nav-links-footerbtn'>Subscribe</Button>
                        </Form>
                    </Col> */}
                </Row>
                {/* <center className="footer-down-text"><span>User Agreement</span><span className="ml-4">Privacy Policy</span><Link to="/contentpolicy" className='link'><span className="ml-4 color">Content Policy</span></Link><Link to="/moderatorsguidelines" className='link'><span className="ml-4 color">Moderator Guidelines</span></Link></center><br /> */}
            </div>
        )
    }
}
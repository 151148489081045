import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, Card, Navbar, Form, Nav, FormControl, Modal } from "react-bootstrap";
import fedduplogo from '../images/fedduplogo.png';
import home from '../images/home.svg';
import fillnotification from '../images/fill-notification.svg';
import profile from '../images/profilepicture.png';
import profileicon from '../images/profileicon.svg';
import dashboard from '../images/dashboard.svg';
import usersettings from '../images/usersettings.svg';
import faq from '../images/faq.svg';
import helpcenter from '../images/helpcenter.svg';
import logout from '../images/logout.svg';
import notification from '../images/notifications.svg';
import './NavBar.css';
import axios from 'axios';
import { config } from '../Config/Config';
import PopupSuggestionComments from '../UsersFeed/PopupSuggestionComments';
import SuggestionComments from '../AdminFeed/SuggestionComments';
import PopupComments from '../UsersFeed/PopupComments';
import FeedbackComments from '../AdminFeed/FeedbackComments';
import { decrypt } from '../utils';

export default class NavBar1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clicked: false,
            loading: false,
            searchValue: "",
            close: false,
            searchModal: false,
            searchResults: [],
            redirect: false,
            productName: "",
            notifications: [],
            displayNotifications: false,
            notificationFlag: false,
            log: false,
            viewSuggestionImage: false,
            popupSuggestionImage: "",
            popupSuggestion: false,
            popupSuggestionData: [],
            suggestionPop: false,
            popup: false,
            popupFeedbackData: [],
            viewFeedbackImage: false,
            popupFeedbackImage: "",
            feedbackPop: false,
        }
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        this.getNotificationFlag();
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }
    getNotificationFlag = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getNotificationFlag', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    self.setState({ notificationFlag: response.data.flag })
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    getNotifications = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getNotifications', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success");
                    self.setState({
                        notifications: response.data.notifications
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    showNotifications = () => {
        this.setState({ displayNotifications: !this.state.displayNotifications })
        this.getNotifications()
    }
    openModal = () => {
        if (localStorage.getItem('junkStore')) {
            this.setState({ close: !this.state.close })
        } else {
            this.setState({ close: false })
        }
    }
    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }
    logoutFromApp = () => {
        localStorage.clear();
        this.setState(
            {
                log: true
            })
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef?.current?.contains(event.target)) {
            this.setState({ close: false, displayNotifications: false })
        }
    }
    openSuggestionImage = (data) => {
        this.setState({ viewSuggestionImage: true, popupSuggestionImage: data.suggestionImage })
    }
    togglePopupSuggestion = (data) => {
        if (localStorage.getItem('junkStore')) {
            this.setState({ popupSuggestion: !this.state.popupSuggestion, popupSuggestionData: data })
        } else {
            this.setState({ popupSuggestion: false })
        }
    }
    toggleAdminPopupSuggestion = (data) => {
        if (localStorage.getItem('junkStore')) {
            this.setState({ suggestionPop: !this.state.suggestionPop, popupSuggestionData: data })
        } else {
            this.setState({ suggestionPop: false })
        }
    }
    openFeedbackImage = (data) => {
        this.setState({ viewFeedbackImage: true, popupFeedbackImage: data.feedbackImage })
    }
    togglePopupFeedback = (data) => {
        if (localStorage.getItem('junkStore')) {
            this.setState({ popup: !this.state.popup, popupFeedbackData: data })
        } else {
            this.setState({ popup: false })
        }
    }
    toggleAdminPopupFeedback = (data) => {
        if (localStorage.getItem('junkStore')) {
            this.setState({ feedbackPopup: !this.state.feedbackPopup, popupFeedbackData: data })
        } else {
            this.setState({ feedbackPopup: false })
        }
    }
    openSuggestionPopup = (data) => {
        if (data.type == 1) {
            this.setState({ popup: true, popupFeedbackData: data })
        } else {
            this.setState({ popupSuggestion: true, popupSuggestionData: data })
        }
    }
    openAdminSuggestionPopup = (data) => {
        if (data.type == 1) {
            this.setState({ suggestionPop: true, popupSuggestionData: data })
        } else {
            this.setState({ suggestionPop: true, popupSuggestionData: data })
        }
    }
    render() {
        const { searchResults, popupSuggestion, suggestionPop, popup, feedbackPop, popupSuggestionData, popupFeedbackData, redirect, log } = this.state;
        if (log) {
            return <Redirect to="/" />
        }


        return (
            <div>
                {popupSuggestion && <PopupSuggestionComments openSuggestionImage={this.openSuggestionImage} closePopup={this.togglePopupSuggestion} data={popupSuggestionData} selectedValue={1} />}
                {suggestionPop && <SuggestionComments openSuggestionImage={this.openSuggestionImage} closePopup={this.toggleAdminPopupSuggestion} data={popupSuggestionData} selectedValue={1} />}
                {popup && <PopupComments openImage={this.openFeedbackImage} closePopup={this.togglePopupFeedback} data={popupFeedbackData} selectedValue={1} />}
                {feedbackPop && <FeedbackComments openImage={this.openFeedbackImage} closePopup={this.toggleAdminPopupFeedback} data={popupFeedbackData} selectedValue={1} />}

                <Navbar className="nav-height py-8" fixed='top' >
                    {
                        (localStorage.getItem('junkStore') && localStorage.getItem('role') == "individual") ?
                            <Navbar.Brand href="/userfeed" > <img className="navbar-logo" src={fedduplogo} /> </Navbar.Brand >
                            :
                            <Navbar.Brand href="/admindashboard" > <img className="navbar-logo" src={fedduplogo} /> </Navbar.Brand >
                    }
                    <Nav className="flex items-center gap-6 ml-auto mr-right" >
                        {localStorage.getItem('junkStore') ?
                            <>{this.state.notificationFlag ?
                                <Nav.Link href="#"><img src={fillnotification} onClick={this.showNotifications} className="mt-1 ml-3 notification-icon" /></Nav.Link>
                                :
                                <Nav.Link href="#"><img src={notification} onClick={this.showNotifications} className="mt-1 ml-3 notification-icon" /></Nav.Link>}
                                <Nav.Link href="#">
                                    <div onClick={this.openModal} className='flex flex-col items-center justify-center'>
                                        <img src={!localStorage.getItem("icon") ? profile : localStorage.getItem("icon")} className="icon" />
                                        <div className='flex items-center gap-1'>

                                            <span style={{ color: "black", fontSize: "13px", fontFamily: "poppins" }}>{localStorage.getItem('name')}</span>
                                            <i className="fa fa-angle-down" ></i>
                                        </div>
                                    </div>

                                </Nav.Link></>
                            : ""}
                        {!localStorage.getItem('junkStore') ?
                            "" : <>
                                {this.state.displayNotifications ?
                                    <div>
                                        {(localStorage.getItem('role') == "admin") || (localStorage.getItem('role') == "subAdmin") ?
                                            <Card id="notification-search" style={{ border: '1px solid #FFB633' }} ref={this.wrapperRef}>
                                                <div>
                                                    {this.state.notifications.length == 0 ? <div>
                                                        No Notifications</div> :
                                                        <div>

                                                            <div className="companyName">Notifications</div>
                                                            {/* <a class="closebtn" onClick={() => this.setState({ searchModal: false })}>&times;</a> */}
                                                            {this.state.notifications.map((data, i) =>
                                                            (
                                                                <div style={{ cursor: "pointer" }} onClick={() => this.setState({ displayNotifications: false })}>
                                                                    <div onClick={() => this.openAdminSuggestionPopup(data)}>
                                                                        <div className="mt-1 mb-1 row no-gutters">
                                                                            <div className="col-md-2">
                                                                                <img src={data.productIcon} className="notification-p-img_" />
                                                                            </div>
                                                                            <div className="col-md-10">
                                                                                <div className="companyName">{data.productName}<span style={{ float: 'right', fontWeight: "500", fontSize: "12px" }}>{Math.ceil((new Date().getTime() - new Date(data.notificationDate).getTime()) / (1000 * 60 * 60 * 24)) == 0 ? "Today" : Math.ceil((new Date().getTime() - new Date(data.notificationDate).getTime()) / (1000 * 60 * 60 * 24)) + " day(s)"}</span></div>
                                                                                <div className='NotificationName'>{data.description}</div>
                                                                            </div>
                                                                        </div> </div>
                                                                </div>

                                                            ))}
                                                        </div>
                                                    }
                                                </div>
                                            </Card>
                                            :
                                            <div >
                                                <Card id="notification-search" ref={this.wrapperRef}>
                                                    <div >
                                                        {this.state.notifications.length == 0 ? <div >No Results found</div> :
                                                            <div>
                                                                <div className="companyName">Notifications</div>
                                                                {this.state.notifications.map((data, i) =>
                                                                (
                                                                    <div style={{ cursor: "pointer" }} className="border-underline-n" onClick={() => this.setState({ displayNotifications: false })}>
                                                                        <div onClick={() => this.openSuggestionPopup(data)}>
                                                                            {/* <img src={data.productIcon} className="search-p-img_" />&nbsp;<div className="search-t-gap_">
                                                                            <span className="companyName">{data.productName}</span><br/><span>{data.description}</span></div> */}

                                                                            <div className="mt-1 mb-1 row no-gutters">
                                                                                <div className="col-md-2">
                                                                                    <img src={data.productIcon} className="notification-p-img_" />
                                                                                </div>
                                                                                <div className="col-md-10">
                                                                                    <div className="companyName">{data.productName}<span style={{ float: 'right', fontWeight: "500", fontSize: "12px" }}>{Math.ceil((new Date().getTime() - new Date(data.notificationDate).getTime()) / (1000 * 60 * 60 * 24)) == 0 ? "Today" : Math.ceil((new Date().getTime() - new Date(data.notificationDate).getTime()) / (1000 * 60 * 60 * 24)) + " day(s)"}</span></div>
                                                                                    <div className='NotificationName'>{data.description}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>}
                                                    </div>
                                                </Card>
                                            </div>
                                        }
                                    </div> : ""
                                }</>}

                        {this.state.close ?
                            <>
                                {(localStorage.getItem('role') == "admin") || (localStorage.getItem('role') == "subAdmin") ?
                                    <Card className="card-profile shadow-lg" ref={this.wrapperRef}>
                                        <div style={{ width: '150px' }} className="nav-profile-text-sm mt-2 ml-2 mr-2">My Stuff</div>
                                        <div style={{ width: '150px' }} className="forgot-text-sm m-1"><Link to="/adminprofile" className="link flex items-center gap-2"><img src={profileicon} style={{ width: "20px" }} /><span>Profile</span></Link></div>
                                        <div style={{ width: '150px' }} className="forgot-text-sm m-1"><Link to="/admindashboard" className="link flex items-center gap-2"><img src={dashboard} style={{ width: "20px" }} /><span>Dashboard</span></Link></div>
                                        <div style={{ width: '150px' }} className="forgot-text-sm m-1"><Link to="/usersettings/account" className="link flex items-center gap-2"><img src={usersettings} style={{ width: "20px" }} /><span>Admin Settings</span></Link></div>
                                        <hr className='bg-orange-300 my-2 h-[1px]' />
                                        <div style={{ width: '150px' }} className="nav-profile-text-sm ml-2 mr-2">More</div>
                                        <div style={{ width: '150px' }} className="forgot-text-sm m-1"><Link to="/faqs" className="link flex items-center gap-2"><img src={faq} style={{ width: "20px" }} /><span>FAQ's</span></Link></div>
                                        <hr className='bg-orange-300 my-2 h-[1px]' />
                                        <div style={{ width: '150px' }} className="forgot-text-sm ml-2 mr-2 cursor flex items-center gap-2 text-orange" onClick={this.logoutFromApp}><img src={logout} style={{ width: "20px" }} /><span>Logout</span></div>
                                    </Card> :
                                    <Card className="card-profile shadow-lg" ref={this.wrapperRef}>
                                        <div style={{ width: '150px' }} className="nav-profile-text-sm mt-2 ml-2 mr-2">My Stuff</div>
                                        <div style={{ width: '150px' }} className="forgot-text-sm m-1"><Link to="/profile" className="link flex items-center gap-2"><img src={profileicon} style={{ width: "20px" }} /><span>Profile</span></Link></div>
                                        <div style={{ width: '150px' }} className="forgot-text-sm m-1"><Link to="/usersettings/account" className="link flex items-center gap-2"><img src={usersettings} style={{ width: "20px" }} /><span>User Settings</span></Link></div>
                                        <hr className='bg-orange-300 my-2 h-[1px]' />
                                        <div style={{ width: '150px' }} className="nav-profile-text-sm ml-2 mr-2">More</div>
                                        <div style={{ width: '150px' }} className="forgot-text-sm m-1"><Link to="/faqs" className="link flex items-center gap-2"><img src={faq} style={{ width: "20px" }} /><span>FAQ's</span></Link></div>
                                        <hr className='bg-orange-300 my-2 h-[1px]' />
                                        <div style={{ width: '150px' }} className="forgot-text-sm ml-2 mr-2 cursor flex items-center gap-2 text-orange" onClick={this.logoutFromApp}><img src={logout} style={{ width: "20px" }} /><span>Logout</span></div>
                                    </Card>}
                            </> : null}
                    </Nav >
                </Navbar >
            </div>
        )
    }
}

import React from 'react';
import '../CompanyFeed/CompanyFeed.css';
import { Row, Col, Modal, Form, FormControl, Card, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import upvoteb from '../images/upvote-b.svg';
import downvoteb from '../images/downvote-b.svg';
import upvote from '../images/upvote.svg';
import downvote from '../images/downvote.svg';
import comment from '../images/comments.svg';
import share from '../images/share.svg';
import lock from '../images/lock.png';
import fillTag from '../images/fill-tag.svg';
import profilepicture from '../images/profilepicture.png';
import axios from 'axios';
import { config } from '../Config/Config';
import Tag from '../utils/Tag';
import ShareBox from '../UsersFeed/ShareBox';
import moment from 'moment';
import { decrypt } from '../utils';

const list = ["adventurer", "adventurer-neutral", "croodles", "croodles-neutral", "avataaars", "bottts", "big-ears", "micah", "open-peeps", "miniavs", "identicon"]
class AnnouncementComments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uImg: false,
            dImg: false,
            cuImg: false,
            cdImg: false,
            count: 999,
            announcement: [],
            announcementByUserId: "",
            allComments: [],
            comment: "",
            commentReply: "",
            click: false,
            commentByUserId: "",
            feedbackCommentId: "",
            isLoading: true,
            feedId: []
        }
    }
    componentDidMount() {
        this.getAnnouncementById();
        console.log("props", this.props)
    }
    changeUpvoteAnnouncementImage = (id, val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        console.log("dataup", id.id)
        const { uImg, dImg, count } = this.state
        this.setState({ uImg: !uImg })
        if (uImg) {
            this.setState({ count: count - 1, dImg: false })
        } else {
            this.setState({ count: count + 1, dImg: true })
        }
        var self = this;
        var payload = {
            "announcementId": id.id,
            "announcement": val,
            "userIdAnnouncementBelongTo": id.announcementByUserId
        }
        console.log("upvote", payload)
        var self = this
        axios.post(config.userUrl + 'admin/likeOrDislikeAnnouncementByAdmin', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    self.getAnnouncementById();
                    self.props.getAnnouncements(self.props.selectedValue);
                    console.log("upvote", payload, response.data)
                }
            })
            .catch(function (error) {
                console.log(error, payload)
            })
    }
    changeDownvoteAnnouncementImage = (id, val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        console.log("dataup", id.id)
        const { uImg, dImg, count } = this.state
        this.setState({ dImg: !dImg })
        if (!dImg) {
            this.setState({ count: count - 1, uImg: false })
        } else {
            this.setState({ dImg: false, uImg: false })
        }
        var payload = {
            "announcementId": id.id,
            "announcement": val,
            "userIdAnnouncementBelongTo": id.announcementByUserId
        }
        console.log("upvote", payload)
        var self = this;
        axios.post(config.userUrl + 'admin/likeOrDislikeAnnouncementByAdmin', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    self.getAnnouncementById();
                    self.props.getAnnouncements(self.props.selectedValue);
                    console.log("upvote", payload, response.data)
                }
            })
            .catch(function (error) {
                console.log(error, payload)
            })
    }

    openAdminReplyComment = (data) => {
        const { allComments, feedback } = this.state
        const feedId = allComments.map(c => c.id == data.id ?
            { ...data, isClicked: !data.isClicked } : data)
        console.log("reply", data.id, feedId)
        this.setState({ feedId: feedId, click: !this.state.click, feedbackCommentId: data.id, commentByUserId: data.commentByUserId })
    }
    getAnnouncementById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "announcementId": this.props.data.id
        }
        console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'admin/getAnnouncementByAnnouncementIdByAdmin', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    self.setState({ announcement: response.data.announcement, announcementByUserId: response.data.announcement[0].announcementByUserId, allComments: response.data.allComments, isLoading: false })
                    self.props.getAnnouncements(self.props.selectedValue);
                    console.log("feedback by id retrieved successfully", response.data, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    shareArticle = (data) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        if (navigator.share) {
            navigator.share({
                url: `https://www.feddup.com/${data.title}`,
            }).then(() => {
                console.log('Thanks for sharing!', data.title);
                var payload = {
                    "announcementId": data.id,
                    "userIdAnnouncementBelongTo": data.announcementByUserId
                }
                console.log("payload", payload)
                var self = this;
                axios.post(config.userUrl + 'admin/shareAnnouncementByAdmin', payload, { headers: headers })
                    .then(function (response) {
                        console.log(response);
                        if (response.data.status == true) {
                            console.log("feedback shared successfully", response.data.message, payload);
                            self.getAnnouncementById();
                            self.props.getAnnouncements(self.props.selectedValue);
                        } else {
                            console.log("Error", payload);
                        }
                    })
            }).catch(err => {
                console.log(
                    "Error while using Web share API:");
                console.log(err);
                console.log("error", err);
            });
        } else {
            console.log("Sorry! Your browser does not support Web Share API");
        }
    };
    render() {
        const { uImg, dImg, count, allComments, announcement, feedId, isLoading } = this.state
        function checkReply(checkItem) {
            return console.log("feedqwer", feedId.filter(item => item.id == checkItem.id).length != 0)
        }
        // if (isLoading) {
        //     allComments.forEach(item => false ? item.isAdded = true : item.isAdded = false)
        //     console.log("allComments", allComments)
        // }
        return (
            // <div className='popup-box'>
            //     <div className='_box'>
            //         <span className="close-icon-new" onClick={this.props.closePopup}> &times;</span>

            <Modal
                size="lg"
                show={true}
                onHide={this.props.closePopup}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            // backdrop={true}
            // backdropClassName={"modal-backdrop"}
            >
                <Modal.Header closeButton style={{ zIndex: "100" }}>
                </Modal.Header>
                <Modal.Body>
                    {announcement.map((data, i) => (
                        <div>
                            <Card className="border-none">
                                <div className="row" style={{ marginTop: "-3.5rem" }}>
                                    <Col md={2}>
                                        <Card className="ml-2 mt-2 mb-2 border-none card-padding-sm rounded-xl" style={{ background: "#FFEBD0" }}>
                                            <center className='cursor'>{data.vote == 1 ? <img src={upvote} onClick={(e, i) => this.changeUpvoteAnnouncementImage(data, 2)} style={{ width: "55px" }} /> : <img src={upvoteb} onClick={(e, i) => this.changeUpvoteAnnouncementImage(data, 1)} style={{ width: "45px" }} />}</center><br />
                                            <center className="likes">{data.totallike == null ? 0 : data.totallike}</center><br />
                                            <center className='cursor'>{data.vote == -1 ? <img src={downvote} onClick={(e, i) => this.changeDownvoteAnnouncementImage(data, 2)} style={{ width: "55px" }} /> : <img src={downvoteb} onClick={(e, i) => this.changeDownvoteAnnouncementImage(data, 0)} style={{ width: "45px" }} />}</center>
                                        </Card>
                                    </Col>
                                    <Col md={10}>
                                        <div className="mt-2 mb-2">
                                            <div className="card-padding">
                                                <h6 className="t-today text-lg">{data.title}</h6>
                                                <div className="posted pt-2">Posted by {data.userName == null ? "anonymous" : data.userName} &nbsp;&nbsp;{data.updatedAt.split("T")[0].split("-").reverse().join("-")} {moment(data.updatedAt).diff(moment(data.createdAt), 'minutes') > 1 ? <span>(Edited)</span> : ''}</div>
                                                <div className="mt-1" dangerouslySetInnerHTML={{
                                                    __html: data.announcement
                                                }} />
                                                {
                                                    data?.tag?.length
                                                        ?
                                                        <div className='flex flex-wrap gap-x-3 gap-y-3 py-3'>
                                                            {data?.tag?.map(tag => <Tag text={tag} />
                                                            )}
                                                        </div>
                                                        : ''
                                                }
                                                {
                                                    this.props.data?.images?.length > 0 ?
                                                        <div className='flex items-start gap-3 pb-4'>
                                                            {this.props.data?.images?.map((img, i) => <img onClick={() => { this.props.openAnnouncementImage({ image: img }); }} key={i} src={img} style={{ width: "100px", height: '75px', objectFit: 'contain', objectPosition: 'top', cursor: 'pointer' }} />)}
                                                        </div>
                                                        :
                                                        ''
                                                }

                                                <ShareBox boxClass='!ml-0' shareCount={data.shareCount} path={`/${data.companyName}/${data.productName}/${data.productTypeString}`} type={'Announcement'} postId={data.id} />

                                                <br />
                                                <br />
                                            </div>
                                        </div>
                                    </Col>

                                </div>
                            </Card>
                        </div>
                    ))}
                </Modal.Body>
            </Modal>
        );
    }
}

export default AnnouncementComments;


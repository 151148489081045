import React, { useEffect, useState, version } from 'react'
import axios from 'axios'
import { config } from '../Config/Config';
import cancelWhiteIcon from "../images/cancel-x-white.svg";
import orating1 from "../images/fill-rating1.png";
import orating2 from "../images/fill-rating2.png";
import orating3 from "../images/fill-rating3.png";
import orating4 from "../images/fill-rating4.png";
import orating5 from "../images/fill-rating5.png";
import rating1 from '../images/rating1.png';
import rating2 from '../images/rating2.png';
import rating3 from '../images/rating3.png';
import rating4 from '../images/rating4.png';
import rating5 from '../images/rating5.png';
import pinkHeart from "../images/pink-heart.svg";
import grayHeart from "../images/gray-heart.svg";
import moment from 'moment';
import { Button, Col, Dropdown, Form, FormControl, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { decrypt, loaderProps } from '../utils';
import { CircleFadeLoader } from 'react-loaders-kit';
import DetailedReview from './DetailedReview';

function ProductReviews({ productid, membershipType, selectedVersionId, versionIds, handleFlag, deletePost, openReasonPopUp, userRole }) {
    const [section, setSection] = useState("Reviews");
    const [openStates, setOpenStates] = useState([]);
    const [highlightedReviews, setHighlightedReviews] = useState([])
    const [highlightedReviewsCount, setHighlightedReviewsCount] = useState(0)
    const [show, setShow] = useState(false)
    const [sortedData, setSortedData] = useState([])
    const [sortOption, setSortOption] = useState('Latest to Oldest')
    const [sortOption2, setSortOption2] = useState('ALl')
    const [ratingSortOption, setRatingSortOption] = useState(0)
    const [selectedRating, setSelectedRating] = useState(0);
    const [searchInput, setSearchInput] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const [ratings, setRatings] = useState([])
    const [showToast, setShowToast] = useState(false)
    const [loading, setLoading] = useState(false)
    const [versions, setVersions] = useState([]);
    const [versionRatings, setVersionRatings] = useState([]);
    const [detailedReview, setDetailedReview] = useState()
    const [ratingFilter, setRatingFilter] = useState([])

    useEffect(() => {
        setSortedData([]);
        getRatingComments()
    }, [selectedVersionId])

    useEffect(() => {
        if (section === "Insights") {
            getInsights();
        }
    }, [section])

    const getInsights = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "productId": productid
        }
        axios.post(config.userUrl + "admin/getRatingsByVersion", payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                }
            })
            .catch(function (error) {
                console.log("Error", error);
                setLoading(false);
            });
    };

    const getRatingComments = () => {
        if (selectedVersionId === "") return;
        setLoading(true);
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "productId": productid,
            "versionId": selectedVersionId
        }
        axios.post(config.userUrl + "admin/getProductRatings", payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    setRatings(response.data.ratingDetails?.filter(item => item?.comment?.trim()))
                    setSortedData(response.data.ratingDetails?.filter(item => item?.comment?.trim()))
                    setOpenStates(Array(response.data.ratingDetails.length).fill(false))
                    setHighlightedReviews(response.data.highlightedReviews)
                    setHighlightedReviewsCount(response.data.highlightedReviewsCount)
                }
            })
            .then(() => {
                setSortOption('Latest to Oldest')
                setSortOption2("All")
                setLoading(false);
            })
            .catch(function (error) {
                console.log("Error", error);
                setLoading(false);
            });
    };
    const highlightRating = (rating) => {
        if (highlightedReviewsCount === 10) {
            setShowToast(true);
            setTimeout(() => {
                setShowToast(false)
            }, 3000)
            return;
        }
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }

        var payload = {
            "id": rating.id || rating?.ratingId
        }
        axios.post(config.userUrl + "admin/highlightProductRating", payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    rating.highlighted = true;
                    setHighlightedReviews([...highlightedReviews, rating])
                    setHighlightedReviewsCount(highlightedReviewsCount + 1);
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const deHighlightRating = (rating) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "id": rating.ratingId,
        }
        axios.post(config.userUrl + "admin/deHighlightProductRating", payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    let newHighlightedReviewsArray = highlightedReviews.filter(item => item.id !== rating.id)
                    rating.highlighted = false;
                    setHighlightedReviews(newHighlightedReviewsArray)
                    setHighlightedReviewsCount(highlightedReviewsCount - 1);
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        const currentData = sortedData;
        if (searchValue !== '') {
            const filteredData = currentData.filter((item) => {
                return item.comment.toLowerCase().includes(searchValue.toLowerCase())
            })
            setFilteredResults(filteredData)
        }
        else {
            setFilteredResults(currentData)
        }
    }
    const handleRatingClear = () => {
        setSelectedRating(0);
        setRatingSortOption(0);
        setRatingFilter([])
    }
    const handleButtonClick = (rowIndex) => {
        setOpenStates(openStates.map((isOpen, index) => index === rowIndex ? !isOpen : isOpen));
    }

    useEffect(() => {
        filter()
    }, [sortOption, sortOption2, ratingFilter])

    const filter = () => {
        let ratingsArry = [...ratings]
        let sortedRatings = []
        if (sortOption2.length > 0) {
            if (sortOption2 === "Anonymous") {
                sortedRatings = ratingsArry.filter(rating => rating.userName === null)
            }
            else if (sortOption2 === "Registered User") {
                sortedRatings = ratingsArry.filter(rating => rating.userName !== null)
            }
            else {
                sortedRatings = [...ratings];
            }
            ratingsArry = [...sortedRatings];
        }
        if (sortOption.length > 0) {
            if (sortOption === "Latest to Oldest") {
                sortedRatings = ratingsArry.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            }
            else {
                sortedRatings = ratingsArry.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
            }
            ratingsArry = [...sortedRatings];
        }
        // if (ratingSortOption > 0 && ratingSortOption < 6) {
        //     sortedRatings = ratingsArry.filter(rating => rating.rating === ratingSortOption)
        // }
        if (ratingFilter.length) {
            sortedRatings = ratingsArry.filter(item => ratingFilter.find(rat => item?.rating ? Math.round(Number(item?.rating)) == rat : false))
        }
        setSortedData(sortedRatings);
    }

    const handleRating = (rating) => {
        let rats = ratingFilter;
        let idx = rats.findIndex(item => item == rating)
        if (idx > -1) {
            rats.splice(idx, 1);
        }
        else {
            rats.push(Number(rating));
        }
        setRatingFilter([...rats])
    }
    console.log(ratingFilter)


    return (
        <div>
            {detailedReview ? <DetailedReview review={detailedReview} userRole={userRole} closePopUp={() => setDetailedReview()} handleReport={() => openReasonPopUp({ type: 'reporting', noPopUp: userRole != 'moderator', action: () => handleFlag({ id: detailedReview?.ratingId, type: 'review', report: true, callback: () => { getRatingComments(); setDetailedReview() } }) })} handleHighlight={() => detailedReview.highlighted ? deHighlightRating(detailedReview) : highlightRating(detailedReview)} /> : <></>}
            <div className='flex gap-6 mt-8 ml-10 '>
                <p className={`text-md font-semibold cursor-pointer ${section === "Reviews" ? "underline text-orange-400 underline-offset-8" : ""}`} onClick={() => setSection("Reviews")}><span className='text-black'>Reviews</span></p>
                {/* <p className={`text-md font-semibold cursor-pointer ${section === "Insights" ? "underline text-orange-400 underline-offset-8" : ""}`} onClick={() => setSection("Insights")}><span className='text-black'>Insights</span></p> */}
            </div>
            <Row className="ml-1 mt-6">
                <Col md={3} className='flex flex-col bg-white mb-10 p-3 rounded-xl h-max sticky top-[80px]'>
                    <div className='flex justify-between items-center'>
                        <p className='text-2xl font-semibold  underline text-orange-400 underline-offset-8'><span className='text-black'>Sort&Filter</span></p>
                        <p className='bg-orange-600 rounded-xl text-white text-xs px-2 py-0.5 cursor-pointer flex items-center justify-between gap-2' onClick={() => { setSortOption("Latest to Oldest"); setSortOption2("All"); handleRatingClear(); setSortedData(ratings) }}><span>Clear All</span><img src={cancelWhiteIcon} alt='' className='float-right w-5' /></p>
                    </div>
                    <br />
                    <div className="flex flex-col gap-2 text-balck font-medium" onChange={(e) => setSortOption(e.target.value)}>
                        <div className='flex items-center gap-2'>
                            <button
                                className={`rounded-full w-4 h-4 ${sortOption === "Latest to Oldest" ? 'bg-black text-white' : 'bg-gray-200'
                                    }`}
                                onClick={() => setSortOption("Latest to Oldest")}
                            >
                            </button>
                            <span>Latest to Oldest</span>
                        </div>
                        <div className='flex items-center gap-2'>
                            <button
                                className={`rounded-full w-4 h-4 ${sortOption === "Oldest to Latest" ? 'bg-black text-white' : 'bg-gray-200'
                                    }`}
                                onClick={() => setSortOption("Oldest to Latest")}
                            >
                            </button>
                            <span>Oldest to Latest</span>
                        </div>
                    </div>
                    <br />
                    <div>
                        <p className='text-xl font-medium mt-4 flex items-center justify-between'>Users </p>
                        <div className="flex flex-col gap-2 text-balck font-medium" onChange={(e) => setSortOption2(e.target.value)}>
                            <div className='flex items-center gap-2'>
                                <button
                                    className={`rounded-full w-4 h-4 ${sortOption2 === "Anonymous" ? 'bg-black text-white' : 'bg-gray-200'
                                        }`}
                                    onClick={() => setSortOption2("Anonymous")}
                                >
                                </button>
                                <span>Anonymous</span>
                            </div>
                            <div className='flex items-center gap-2'>
                                <button
                                    className={`rounded-full w-4 h-4 ${sortOption2 === "Registered User" ? 'bg-black text-white' : 'bg-gray-200'
                                        }`}
                                    onClick={() => setSortOption2("Registered User")}
                                >
                                </button>
                                <span>Registered User</span>
                            </div>
                            <div className='flex items-center gap-2'>
                                <button
                                    className={`rounded-full w-4 h-4 ${sortOption2 === "All" ? 'bg-black text-white' : 'bg-gray-200'
                                        }`}
                                    onClick={() => setSortOption2("All")}
                                >
                                </button>
                                <span>All</span>
                            </div>
                        </div>
                    </div>
                    <p className='text-xl font-medium mt-4 flex items-center justify-between'>By Reaction <span className='bg-orange-600 rounded-xl text-white text-sm w-20 pl-3 cursor-pointer' onClick={handleRatingClear} >Clear <img src={cancelWhiteIcon} alt='' className='float-right w-5 cursor-pointer' /></span></p>
                    <div className="mt-4 mx-auto mb-40">
                        <div className='flex items-center gap-0.5'>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id={`tooltip-top`}>
                                        <div className='left-align'>
                                            1
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <span className="highlight-container" onClick={() => handleRating(1)}><span className="highlight"> {ratingFilter?.includes(1) ? <img src={orating1} style={{ width: "40px", cursor: "pointer" }} /> : <img src={rating1} className="" style={{ width: "40px", cursor: "pointer" }} />}&nbsp;</span></span>
                            </OverlayTrigger><br />
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id={`tooltip-top`}>
                                        <div className='left-align'>
                                            2
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <span className="highlight-container" onClick={() => handleRating(2)}><span className="highlight">{ratingFilter?.includes(2) ? <img src={orating2} style={{ width: "40px", cursor: "pointer" }} /> : <img src={rating2} className="" style={{ width: "40px", cursor: "pointer" }} />}&nbsp;</span></span>
                            </OverlayTrigger><br />
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id={`tooltip-top`}>
                                        <div className='left-align'>
                                            3
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <span className="highlight-container"><span className="highlight" onClick={() => handleRating(3)}>{ratingFilter?.includes(3) ? <img src={orating3} style={{ width: "40px", cursor: "pointer" }} /> : <img src={rating3} className="" style={{ width: "40px", cursor: "pointer" }} />}&nbsp;</span></span>
                            </OverlayTrigger><br />
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id={`tooltip-top`}>
                                        <div className='left-align'>
                                            4
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <span className="highlight-container"><span className="highlight" onClick={() => handleRating(4)}>{ratingFilter?.includes(4) ? <img src={orating4} style={{ width: "40px", cursor: "pointer" }} /> : <img src={rating4} className="" style={{ width: "40px", cursor: "pointer" }} />}&nbsp;</span></span>
                            </OverlayTrigger><br />

                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id={`tooltip-top`}>
                                        <div className='left-align'>
                                            5
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <span className="highlight-container"><span className="highlight" onClick={() => handleRating(5)}>{ratingFilter?.includes(5) ? <img src={orating5} style={{ width: "40px", cursor: "pointer" }} /> : <img src={rating5} className="" style={{ width: "40px", cursor: "pointer" }} />}&nbsp;</span></span>
                            </OverlayTrigger><br />

                        </div>
                    </div>

                </Col>
                <Col md={9} className='flex flex-col gap-4'>
                    <div className='flex justify-between'>
                        <div className='flex flex-col gap-4'>
                            <p className='text-2xl font-semibold text-black'>Reviews</p>
                            <div className={`${membershipType === "Free" ? "hidden" : ""}`}>
                                <Form className=''>
                                    <FormControl
                                        type="text"
                                        placeholder="Search for ratings"
                                        value={searchInput}
                                        onChange={(e) => searchItems(e.target.value)}
                                        id="search"
                                        className="abc form-control ml-0 bg-white rounded-full"
                                        style={{ width: "480px", height: "45px", borderRadius: "25px", paddingLeft: "3rem" }}
                                        aria-label="Search keyword"
                                    />
                                </Form>
                            </div>
                        </div>
                        <div className='flex flex-col bg-white relative w-72 h-32 -mt-8 rounded-xl'>
                            <div className='absolute -top-4 -left-5'>
                                <img src={pinkHeart} alt='' className='w-10' />
                            </div>
                            <div className='pl-4'>
                                <p className='text-lg font-medium'>Highlighted Reviews</p>
                                <p className='text-lg ml-20 mt-3 font-medium'>{highlightedReviewsCount < 0 ? 0 : highlightedReviewsCount}/10</p>
                                <Button
                                    variant="default"
                                    type="submit"
                                    className={`bg-pink-400 text-white float-right h-8 w-24 m-3 leading-tight rounded-lg ${highlightedReviewsCount === 0 ? "disabled" : ""}`}
                                    onClick={() => setShow(true)}
                                >View All</Button>
                            </div>
                        </div>
                    </div>
                    {loading ? <div className='mx-auto flex flex-col'><CircleFadeLoader {...loaderProps} /><span className='text-2xl font-semibold'>Loading...</span></div> : null}
                    {loading === false && searchInput.length > 0 ?
                        filteredResults.length === 0 ? <div className='mx-auto flex text-2xl font-semibold'>No reviews found</div>
                            : filteredResults.map((rating, i) => {
                                return (
                                    <div key={i}>
                                        <Row className='mt-4 ml-2'>
                                            <Col md={3} className='text-black font-medium'>
                                                <p>{rating.userName ? rating.userName : "Anonymous User"}</p>
                                                <p>{moment(rating.updatedAt, "YYYY-MM-DD HH:mm:ss.SSSZ").format("DD/MM/YY")}</p>
                                            </Col>
                                            <Col md={1}><img src={rating.rating === 1 ? orating1 : rating.rating === 2 ? orating2 : rating.rating === 3 ? orating3 : rating.rating === 4 ? orating4 : orating5} alt='' className='w-10' /></Col>
                                            <Col md={4}>
                                                <p className='text-xs font-semibold'>{rating.comment}</p>
                                            </Col>
                                            <Col md={2}>
                                                <Button
                                                    variant="default"
                                                    type="submit"
                                                    className="bg-orange-400 text-white text-[0.7rem] h-8 w-28 leading-tight rounded-lg"
                                                    onClick={() => rating.feedbackId ? setDetailedReview(rating.feedbackId) : setDetailedReview(rating)}
                                                >View Full Review</Button>
                                            </Col>
                                            <Col md={1} className={`flex items-center relative ${userRole != 'moderator' ? 'group cursor-pointer' : ''}`}>
                                                {rating.highlighted && <svg onClick={() => deHighlightRating(rating)} className='hidden group-hover:inline-block' width="36" height="38" viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M31.5763 7.3458C31.8072 10.7275 30.4222 17.4908 20.9584 25.0996C8.49372 23.6201 3.41551 18.5476 1.5689 15.5886C0.876422 14.5319 0.645609 13.8978 0.645609 13.6864C-0.508524 9.88206 1.79975 5.65496 5.95462 4.59818C10.1095 3.54141 14.726 5.65496 15.8801 9.45935C14.726 5.65496 17.0343 1.42786 21.1892 0.371086C25.344 -0.685688 29.9606 1.42786 31.1147 5.23225C31.3455 5.23225 31.5763 6.07767 31.5763 7.3458Z" fill="#E6570E" />
                                                    <path d="M14.8292 8.23651L20.6243 12.2606L18.3705 16.2239L21.6346 18.0891L19.7999 25.099" stroke="white" stroke-width="3" />
                                                </svg>}

                                                <img src={rating.highlighted ? pinkHeart : grayHeart} alt="" className={`cursor-pointer ${rating.highlighted ? 'inline-block group-hover:hidden' : ''}`} onClick={() => userRole != 'moderator' && (rating.highlighted ? deHighlightRating(rating) : highlightRating(rating))} />
                                            </Col>
                                            <Col md={1} className='flex items-center relative'>
                                                {/* <button id={`dropdownMenuIconButton-${i}`} data-dropdown-toggle={`dropdownMenuIconButton-${i}`} className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 rounded-lg  focus:outline-none dark:text-white  dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button" onClick={() => handleButtonClick(i)}>
                                                    <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path></svg>
                                                </button>
                                                <br />
                                                <div id={`dropdownMenuIconButton-${i}`} className={`z-10 bg-white divide-y border divide-gray-100 rounded-lg shadow w-28 dark:bg-gray-700 dark:divide-gray-600  ${openStates[i] ? '' : 'hidden'} absolute top-10 left-10`}>
                                                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby={"dropdownMenuIconButton" + i}>
                                                        <li onClick={() => handleButtonClick(i)} className='cursor-pointer'>
                                                            Report Review
                                                        </li>
                                                    </ul>
                                                </div> */}
                                                <Dropdown align="end" show={openStates[i]} onToggle={() => handleButtonClick(i)}>
                                                    <Dropdown.Toggle variant="default" className="text-white focus:outline-none focus:ring-0" >
                                                        <svg className="w-6 h-6" aria-hidden="true" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z">
                                                            </path>
                                                        </svg>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className="border-black rounded-lg min-w-max px-2 z-20">
                                                        <p className="hover:bg-orange-400 hover:font-medium w-40 rounded-md p-1 cursor-pointer" onClick={() => openReasonPopUp({ type: 'reporting', noPopUp: userRole != 'moderator', action: () => handleFlag({ id: rating.ratingId, type: 'review', report: true, callback: () => getRatingComments() }) })}
                                                        >Report this review</p>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Col>
                                        </Row>
                                        <hr className="bg-orange-600 w-full mx-auto rounded-sm h-[1px]" />
                                    </div>
                                )
                            }) : loading === false && sortedData.length === 0 ? <div className='mx-auto flex text-2xl font-semibold'>Change your filter to load more reviews</div>
                            : sortedData.map((rating, i) => {
                                return (
                                    <div key={i}>
                                        <Row className='mt-4 ml-2'>
                                            <Col md={3} className='text-black font-medium'>
                                                <p>{rating.userName ? rating.userName : "Anonymous User"}</p>
                                                <p>{moment(rating.updatedAt, "YYYY-MM-DD HH:mm:ss.SSSZ").format("DD/MM/YY")}</p>
                                            </Col>
                                            <Col md={1}><img src={rating.rating === 1 ? orating1 : rating.rating === 2 ? orating2 : rating.rating === 3 ? orating3 : rating.rating === 4 ? orating4 : orating5} alt='' className='w-10' /></Col>
                                            <Col md={4}>
                                                <p className='text-xs font-semibold'>{rating.comment}</p>
                                            </Col>
                                            <Col md={2}>
                                                <Button
                                                    variant="default"
                                                    type="submit"
                                                    className="bg-orange-400 text-white text-[0.7rem] h-8 w-28 leading-tight rounded-lg whitespace-nowrap"
                                                    onClick={() => rating.feedbackId ? setDetailedReview(rating) : setDetailedReview(rating)}
                                                >View Full Review</Button>
                                            </Col>
                                            <Col md={1} className={`flex items-center relative ${userRole != 'moderator' ? 'group cursor-pointer' : ''}`}>
                                                {rating.highlighted && <svg onClick={() => deHighlightRating(rating)} className='hidden group-hover:inline-block' width="36" height="38" viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M31.5763 7.3458C31.8072 10.7275 30.4222 17.4908 20.9584 25.0996C8.49372 23.6201 3.41551 18.5476 1.5689 15.5886C0.876422 14.5319 0.645609 13.8978 0.645609 13.6864C-0.508524 9.88206 1.79975 5.65496 5.95462 4.59818C10.1095 3.54141 14.726 5.65496 15.8801 9.45935C14.726 5.65496 17.0343 1.42786 21.1892 0.371086C25.344 -0.685688 29.9606 1.42786 31.1147 5.23225C31.3455 5.23225 31.5763 6.07767 31.5763 7.3458Z" fill="#E6570E" />
                                                    <path d="M14.8292 8.23651L20.6243 12.2606L18.3705 16.2239L21.6346 18.0891L19.7999 25.099" stroke="white" stroke-width="3" />
                                                </svg>}

                                                <img src={rating.highlighted ? pinkHeart : grayHeart} alt="" className={`${rating.highlighted ? 'inline-block group-hover:hidden' : ''}`} onClick={() => userRole != 'moderator' && (rating.highlighted ? deHighlightRating(rating) : highlightRating(rating))} />
                                            </Col>
                                            <Col md={1} className='flex items-end'>
                                                {/* <button id={`dropdownMenuIconButton-${i}`} data-dropdown-toggle={`dropdownMenuIconButton-${i}`} className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 rounded-lg  focus:outline-none dark:text-white  dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button" onClick={() => handleButtonClick(i)}>
                                                    <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path></svg>
                                                </button>
                                                <br />
                                                <div id={`dropdownMenuIconButton-${i}`} className={`z-10 bg-white divide-y border divide-gray-100 rounded-lg shadow w-28 dark:bg-gray-700 dark:divide-gray-600  ${openStates[i] ? '' : 'hidden'} absolute top-10 left-10`}>
                                                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby={"dropdownMenuIconButton" + i}>
                                                        <li onClick={() => handleButtonClick(i)} className='cursor-pointer'>
                                                            Report Review
                                                        </li>
                                                    </ul>
                                                </div> */}
                                                <Dropdown align="end" show={openStates[i]} onToggle={() => handleButtonClick(i)}>
                                                    <Dropdown.Toggle variant="default" className="text-white focus:outline-none focus:ring-0" >
                                                        <svg className="w-6 h-6" aria-hidden="true" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z">
                                                            </path>
                                                        </svg>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className="border-black rounded-lg min-w-max px-2 z-20">
                                                        <p className="hover:bg-orange-400 hover:font-medium w-40 rounded-md p-1 cursor-pointer"
                                                            // onClick={() => reportReview(rating)}
                                                            onClick={() => openReasonPopUp({ type: 'reporting', noPopUp: userRole != 'moderator', action: () => handleFlag({ id: rating.ratingId, type: 'review', report: true, callback: () => getRatingComments() }) })}
                                                        >Report this review</p>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Col>
                                        </Row>
                                        <hr className="bg-orange-600 w-full mx-auto rounded-sm h-[1px]" />
                                    </div>
                                )
                            })}
                </Col>
            </Row>
            <Modal show={show} onHide={() => setShow(false)} size="lg" dialogClassName='modal-highlighted-reviews'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className='flex underline text-pink-400 underline-offset-8'>
                            <img src={pinkHeart} alt='' className='w-10' />
                            <p className='text-2xl font-semibold my-4 text-black'>Highlighted Reviews</p>
                        </div>
                        <p className='text-xs font-medium'>Highlighted reviews showcase the emotion(star) and the description of each review. Form  data will not be shown.</p>
                        <p className='text-xs font-medium'> Use this to showcase in your webpages. You can find the connector in the feddup overview under “code”</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='mb-40'>
                    <div className='flex justify-between'>
                        <p className='text-lg font-medium'>Highlighted reviews</p>
                        <span>{highlightedReviewsCount}/10</span>
                    </div>
                    {highlightedReviews && highlightedReviews.map((rating, i) => {
                        return (
                            <div key={i}>
                                <Row className='mt-4 ml-2'>
                                    <Col md={3} className='text-black font-medium'>
                                        <p>{rating.userName ? rating.userName : "Annonymus User"}</p>
                                        <p>{moment(rating.updatedAt, "YYYY-MM-DD HH:mm:ss.SSSZ").format("DD/MM/YY")}</p>
                                    </Col>
                                    <Col md={1}><img src={rating.rating === 1 ? orating1 : rating.rating === 2 ? orating2 : rating.rating === 3 ? orating3 : rating.rating === 4 ? orating4 : orating5} alt='' className='w-10' /></Col>
                                    <Col md={4}>
                                        <p className='text-xs font-semibold'>{rating.comment}</p>
                                    </Col>
                                    <Col md={3}>
                                        <Button
                                            variant="default"
                                            type="submit"
                                            className="bg-orange-400 text-white h-8 m-3 leading-tight rounded-lg"
                                            onClick={() => rating.feedbackId ? setDetailedReview(rating) : setDetailedReview(rating)}
                                        >View Full Review</Button>
                                    </Col>
                                    <Col md={1} className={`flex items-center relative ${userRole != 'moderator' ? 'group cursor-pointer' : ''}`}>
                                        {rating.highlighted && <svg onClick={() => deHighlightRating(rating)} className='hidden group-hover:inline-block' width="36" height="38" viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M31.5763 7.3458C31.8072 10.7275 30.4222 17.4908 20.9584 25.0996C8.49372 23.6201 3.41551 18.5476 1.5689 15.5886C0.876422 14.5319 0.645609 13.8978 0.645609 13.6864C-0.508524 9.88206 1.79975 5.65496 5.95462 4.59818C10.1095 3.54141 14.726 5.65496 15.8801 9.45935C14.726 5.65496 17.0343 1.42786 21.1892 0.371086C25.344 -0.685688 29.9606 1.42786 31.1147 5.23225C31.3455 5.23225 31.5763 6.07767 31.5763 7.3458Z" fill="#E6570E" />
                                            <path d="M14.8292 8.23651L20.6243 12.2606L18.3705 16.2239L21.6346 18.0891L19.7999 25.099" stroke="white" stroke-width="3" />
                                        </svg>}

                                        <img src={rating.highlighted ? pinkHeart : grayHeart} alt="" className={`${rating.highlighted ? 'inline-block group-hover:hidden' : ''}`} onClick={() => userRole != 'moderator' && (rating.highlighted ? deHighlightRating(rating) : highlightRating(rating))} />

                                        {/* <img src={rating.highlighted ? pinkHeart : grayHeart} alt="" className='cursor-pointer' onClick={() => rating.highlighted ? deHighlightRating(rating) : highlightRating(rating)} /> */}
                                    </Col>
                                </Row>
                                <hr className="bg-orange-400 w-full mx-auto rounded-sm h-[1px]" />
                            </div>
                        )
                    })}
                </Modal.Body>
            </Modal>
            <Modal
                show={showToast}
                onHide={() => setShowToast(false)}
                dialogClassName="modal-3w"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <p className='text-lg'>You cannot highlight more than 10 reviews</p>
                    </Modal.Title>
                </Modal.Header>
            </Modal>
        </div>
    )
}

export default ProductReviews
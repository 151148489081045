import React, { useState, useEffect } from 'react';
import '../CompanyFeed/CompanyFeed.css';
import { Modal, Form, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import fillTag from "../images/fill-tag.svg";
import emptyTag from "../images/empty-tag.svg";
import imageicon from "../images/image-icon.svg";
import axios from 'axios';
import AddImage from '../CompanyFeed/AddImage';
import { config } from '../Config/Config';
import i from "../images/i.svg";
import AddImage2 from '../CompanyFeed/AddImage2';
import Tag from '../utils/Tag';
import { decrypt } from '../utils';


const EditFeedbackPopup = (props) => {
    const [edit, setEdit] = useState(true);
    const [feedId, setFeedId] = useState(props.feedId);
    const [feedbackTitle, setFeedbackTitle] = useState(props.title);
    const [feedbackDescription, setFeedbackDescription] = useState(props.postFeedback);
    const [image, setImage] = useState(props.image);
    const [tag, setTag] = useState(props.tag);
    const [defaultTags, setDefaultTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    useEffect(() => {
        if (props.tag && props.initialTags) {
            let tags = props.initialTags?.map(item => {
                if (props.tag?.includes(item.tag)) {
                    item.isSelected = true
                }
                return item
            })
            setDefaultTags(tags)
        }
        else if (props.productId) {
            getTagsByProductId(props.productId)
        }
    }, []);


    const onChangeImage = (data) => {
        setImage(data);
        console.log("s", data);
    };


    const handleTags = (e, tag) => {
        const updated = defaultTags.map(data => (data.tag === tag) ?
            { ...data, isSelected: !data.isSelected } : data);
        setDefaultTags(updated);
        setSelectedTags(updated);
    };

    const editFeedbackById = (img) => {
        console.log("img", img);
        const headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        };
        let tagsData = [];
        tagsData = defaultTags.filter(item => (item.isSelected === true) ? item.tag : null);
        const imgUrl = (img === "img") ? "" : image;
        const payload = {
            "feedbackId": feedId,
            "title": feedbackTitle,
            "feedback": feedbackDescription,
            "feedbackImage": imgUrl,
            "tag": tagsData.map(item => item.tag)
        };
        axios.post(config.userUrl + 'user/updateFeedbackByCreater', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    props.getAllFeedbacks(props.selectedValue);
                    props.closePopup();
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };

    const getTagsByProductId = (id) => {
        const headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        };

        const payload = {
            productId: id
        };
        axios.post(config.userUrl + 'admin/getTagsByProductId', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    let tags = response?.data?.tags?.map(item => {
                        return {
                            tag: item,
                            isSelected: Boolean(props.tag?.includes(item))
                        }
                    })
                    setDefaultTags(tags)
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };

    return (
        <Modal
            size="xl"
            show={edit}
            onHide={props.closePopup}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className='flex items-center gap-2'>
                        <span className='w-24'>
                            <svg viewBox="0 0 139 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M70.7812 34.2148V73.2644H84.1343H89.6179H95.5238H114.824L123.016 83.3073L137.547 34.2148L70.7812 34.2148Z" fill="#E6570E" stroke="#E6570E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.3435 47.6166C91.4671 49.5947 90.7254 53.551 85.6564 58.0018C78.9802 57.1363 76.2603 54.1692 75.2712 52.4383C74.9003 51.8201 74.7767 51.4492 74.7767 51.3256C74.1586 49.1002 75.3949 46.6276 77.6203 46.0094C79.8457 45.3912 82.3183 46.6276 82.9365 48.8529C82.3183 46.6276 83.5546 44.1549 85.78 43.5367C88.0054 42.9186 90.4781 44.1549 91.0963 46.3803C91.2199 46.3803 91.3435 46.8748 91.3435 47.6166Z" fill="#FFB858" />
                                <path d="M115.166 48.3779L121.842 55.0541" stroke="#FFB858" stroke-width="3.9997" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M121.842 48.3779L115.166 55.0541" stroke="#FFB858" stroke-width="3.9997" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M96.6179 51.6519C96.6179 54.5646 98.9791 56.9258 101.892 56.9258C104.805 56.9258 107.166 54.5646 107.166 51.6519C107.166 48.7391 104.805 46.3779 101.892 46.3779C98.9791 46.3779 96.6179 48.7391 96.6179 51.6519Z" fill="#FFB858" />
                                <path d="M67.5469 1.21484V40.2644H54.1938H48.7102H42.8044L23.504 40.2644L15.3126 50.3073L0.78125 1.21484L67.5469 1.21484Z" fill="#FFB858" stroke="#FFB858" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M46.9846 14.6166C46.861 16.5947 47.6028 20.551 52.6717 25.0018C59.3479 24.1363 62.0678 21.1692 63.0569 19.4383C63.4278 18.8201 63.5514 18.4492 63.5514 18.3256C64.1696 16.1002 62.9332 13.6276 60.7078 13.0094C58.4825 12.3912 56.0098 13.6276 55.3916 15.8529C56.0098 13.6276 54.7735 11.1549 52.5481 10.5367C50.3227 9.91857 47.85 11.1549 47.2319 13.3803C47.1082 13.3803 46.9846 13.8748 46.9846 14.6166Z" fill="#E6570E" />
                                <path d="M23.1624 15.3779L16.4862 22.0541" stroke="#E6570E" stroke-width="3.9997" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M16.4862 15.3779L23.1624 22.0541" stroke="#E6570E" stroke-width="3.9997" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M41.7102 18.6519C41.7102 21.5646 39.349 23.9258 36.4363 23.9258C33.5236 23.9258 31.1624 21.5646 31.1624 18.6519C31.1624 15.7391 33.5236 13.3779 36.4363 13.3779C39.349 13.3779 41.7102 15.7391 41.7102 18.6519Z" fill="#E6570E" />
                            </svg>
                        </span>
                        <h5 style={{ fontFamily: "poppins" }} className='text-orange font-semibold'>Edit Feddback</h5>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-top`}>
                                    <div className='tool-text'>
                                        Public community post to shed light on generic bugs and features not working as intended<br />
                                    </div>
                                </Tooltip>
                            }
                        >
                            <span className="i-icon"><img src={i} style={{ width: "20px" }} /></span>
                        </OverlayTrigger>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="-mt-8 mb-2 px-5">
                    <Form>
                        <Form.Control style={{ fontFamily: "poppins" }} type="text"
                            name="feedbackTitle" value={feedbackTitle}
                            placeholder="Feddback Title"
                            onChange={(e) => setFeedbackTitle(e.target.value)} />
                        <textarea
                            className="mt-3 form-control"
                            id="exampleFormControlTextarea1"
                            style={{ fontFamily: "poppins", resize: "none" }}
                            rows="5"
                            maxLength={180}
                            value={feedbackDescription}
                            name="feedbackDescription"
                            onChange={(e) => setFeedbackDescription(e.target.value)}
                            placeholder="What is making you feddup?"
                        />
                        <div style={{ fontFamily: "poppins", fontSize: "12px" }}>{feedbackDescription.length}/180 characters</div>

                        <div className='flex items-start gap-6 w-full py-4'>
                            <div className="w-1/2"> <AddImage2 imageUrl={image} onChangeImage={onChangeImage} /></div>
                            <div className='w-1/2 h-[13rem] overflow-y-auto scrollbar-hide rounded-lg border-2 border-gray-500 p-2'>
                                <h1 className='text-orange font-medium'>Select Tags</h1>
                                <div className='flex flex-wrap gap-y-2 py-2'>
                                    {
                                        defaultTags.map((data, i) => (
                                            <span className='cursor-pointer' onClick={(e) => handleTags(e, data.tag)}>
                                                <Tag text={data.tag} color={data.isSelected ? '' : '#999'} />
                                            </span>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </Form>
                    <div className='w-full flex items-center justify-between'>
                        <div onClick={props.closePopup} className=" text-orange font-medium text-lg cursor-pointer">Cancel</div>

                        <OverlayTrigger style={(feedbackTitle.length != 0) && (feedbackDescription.length != 0) ? { display: "none" } : { cursor: "pointer" }}
                            placement="left"
                            overlay={
                                <Tooltip id={`tooltip-top`} style={(feedbackTitle.length != 0) && (feedbackDescription.length != 0) ? { display: "none" } : { cursor: "pointer" }}>
                                    {(feedbackTitle.length != 0) && (feedbackDescription.length != 0) ?
                                        <div style={{ display: "none" }}>

                                        </div>
                                        : <div className='tool-text'>
                                            Please add title and description
                                        </div>
                                    }
                                </Tooltip>
                            }
                        >
                            {(feedbackTitle.length != 0) && (feedbackDescription.length != 0) ?
                                <div className='btn-outer transform translate-x-6'>
                                    <div className='btn-1' style={{ width: "120px", height: "30px" }}>
                                    </div>
                                    <Button
                                        variant="default"
                                        className="btn-2" style={{ width: "120px", height: "30px", lineHeight: "12px" }}
                                        onClick={editFeedbackById}
                                    >Submit</Button>
                                </div>
                                :
                                <div className='btn-outer transform translate-x-6'>
                                    <div className='btn-1' style={{ width: "120px", height: "30px" }}>
                                    </div>
                                    <Button
                                        disabled
                                        variant="default"
                                        className="btn-2" style={{ width: "120px", height: "30px", lineHeight: "12px", color: '#222' }}
                                    >Submit</Button>
                                </div>
                            }
                        </OverlayTrigger>
                    </div>
                </div>

                {/* <div className="mt-1 mb-2">
            <Form>
              <Form.Control
                style={{ fontFamily: "poppins" }}
                type="text"
                value={title}
                name="title"
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Title"
              />
              <textarea
                className="mt-3 form-control"
                id="exampleFormControlTextarea1"
                style={{ fontFamily: "poppins", resize: "none" }}
                rows="5"
                name="postFeedback"
                value={postFeedback}
                onChange={(e) => setPostFeedback(e.target.value)}
                placeholder="What is making you feddup?"
              />
              <div style={{ fontFamily: "poppins", fontSize: "12px" }}>{postFeedback.length}/280 characters</div>
              <div className="mt-3">
                <AddImage imageUrl={image} editById={editFeedbackById} onChangeImage={onChangeImage} />
              </div><br />
              <div className="tag-card">
                {defaultTags.map((data, i) => (
                  <Button
                    key={i}
                    className="abc family"
                    variant="default"
                    size="sm"
                    value={data.tag}
                    onClick={(e) => handleTags(e, data.tag)}
                  >
                    <div>
                      {data.isSelected ?
                        <div>
                          <img src={fillTag} style={data.tag.length >= 15 ? { width: "200px", height: "35px" } : { width: "150px", height: "35px" }} />
                          <div className={data.tag.length >= 15 ? "tag-data-fill" : "tag-data-fill_"} >{data.tag}</div>
                        </div>
                        :
                        <div>
                          <img src={emptyTag} style={data.tag.length >= 15 ? { width: "200px", height: "35px" } : { width: "150px", height: "35px" }} />
                          <div className={data.tag.length >= 15 ? "tag-data-empty" : "tag-data-empty_"} >{data.tag}</div>
                        </div>
                      }
                    </div>
                  </Button>
                ))}
              </div>
            </Form>
            <div className="mt-3 suggestion-box-text" style={{ float: "left" }}>Post guidelines <span style={{ color: "#E6570E" }}>read here.</span></div>
            <br />
            <OverlayTrigger
              style={(title.length !== 0) && (postFeedback.length !== 0) ? { display: "none" } : { cursor: "pointer" }}
              placement="left"
              overlay={
                <Tooltip id={`tooltip-top`} style={(title.length !== 0) && (postFeedback.length !== 0) ? { display: "none" } : { cursor: "pointer" }}>
                  {(title.length !== 0) && (postFeedback.length !== 0) ?
                    <div style={{ display: "none" }}></div>
                    :
                    <div className='tool-text'>
                      Please add title and description
                    </div>
                  }
                </Tooltip>
              }
            >
              {(title.length !== 0) && (postFeedback.length !== 0) ?
                <Button
                  variant="default"
                  size="md"
                  type="submit"
                  style={{ float: "right" }}
                  className="abc submit-button"
                  onClick={editFeedbackById}
                >
                  submit
                </Button>
                :
                <Button
                  variant="default"
                  size="md"
                  type="submit"
                  style={{ float: "right" }}
                  className="abc submit-button-grey"
                >
                  submit
                </Button>
              }
            </OverlayTrigger>
          </div> */}
            </Modal.Body>
        </Modal>
    );
};

export default EditFeedbackPopup;


import React, { useEffect, useState } from 'react'
import share from '../images/share.svg';
import axios from 'axios';
import { config } from '../Config/Config';
import { decrypt } from '../utils';

function ShareBox({ shareCount, boxClass = '', path, type, postId, userId, callback }) {
    const [isCopied, setIsCopied] = useState(false)


    useEffect(() => {
        if (isCopied) {
            setTimeout(() => {
                setIsCopied(false);
                if (type == "Suggestion") {
                    shareSuggestion();
                }
                else if (type == "Feedback") {
                    shareFeedback();
                }
                else if (type == "Announcement") {
                    shareAnnouncement();
                }
            }, 1000);
        }
    }, [isCopied])

    const copyUrl = () => {
        navigator.clipboard.writeText(window.location.origin + "/by" + path + `?type=${type}&id=${postId}`);
        setIsCopied(true)
    }

    const shareSuggestion = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + (localStorage.getItem('junkStore') ? decrypt(localStorage.getItem('junkStore')) : ''),
            "applicationType": "web"
        }
        var payload = {
            "suggestionId": postId,
        }
        axios.post(config.userUrl + 'user/shareSuggestionByUser', payload, { headers: headers })

    }

    const shareAnnouncement = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + (localStorage.getItem('junkStore') ? decrypt(localStorage.getItem('junkStore')) : ''),
            "applicationType": "web"
        }
        var payload = {
            "announcementId": postId,
        }
        axios.post(config.userUrl + 'user/shareAnnouncement', payload, { headers: headers })
    }

    const shareFeedback = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + (localStorage.getItem('junkStore') ? decrypt(localStorage.getItem('junkStore')) : ''),
            "applicationType": "web"
        }
        var payload = {
            "feedbackIdd": postId,
        }
        axios.post(config.userUrl + 'user/shareFeedbackByUser', payload, { headers: headers })
    }

    return (
        <div className={`flex items-center cursor-pointer gap-x-2.5 ml-5 relative ${boxClass}`} onClick={() => copyUrl()}>
            {isCopied ? <div className='absolute -top-10 left-0 bg-orange px-4 py-1.5 text-white font-medium text-sm rounded-full z-50'>
                <h1>Copied</h1>
            </div> : ''}
            <img src={share} style={{ width: "20px" }} className={localStorage.getItem('junkStore') ? "cursor" : ""} />
            <span className="comment">{shareCount || 0} Shares</span>
        </div>
    )
}

export default ShareBox


// edit company ui update
// learn more to pricing with underline in faq
// add animation to faq list

// no review put empty box in product details
// update company details cut out
// feedback form hover for free company update 
import React, { Component } from "react";
import { Button, Card, Form, Accordion, FormControl, Col, Row, Image } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Header from "../Header/Header";
import fedduplogo from "../images/fedduplogo.png";
import background from '../images/background-img.png';
import fillrating2 from '../images/fill-rating2.png';
import fillrating5 from '../images/fill-rating5.png';
import { help } from './HelpData';
import { FaCheckCircle } from "react-icons/fa";
import { decrypt } from "../utils";

export default class PricingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [
                {
                    id: 1,
                    ques: "What is Feddup?",
                    ans: "Feddup is a free to use feedback tool for web and desktop application"
                },
                {
                    id: 2,
                    ques: "Is feddup paid?",
                    ans: " Feddup is free for all users. Feddup has 2 payment tiers for companies. Free and Premium. Learm more from here"
                },
                {
                    id: 3,
                    ques: "Can I post without signing in?",
                    ans: "Yes, but only through the pop up. For better interaction and follow ups we suggest to use the sig up "
                },
                {
                    id: 4,
                    ques: "what is a feddback?",
                    ans: "a public community section for all feddup pages, where u can post about bugs or errors you have encountered"
                },
                {
                    id: 5,
                    ques: "What if you want to share confidential data?",
                    ans: "feddup does not store any sensitive user information or capture any information from the application being used.If you want to report an error or bug only with the application admin, the company must be on a paid tier and you must use the “reach support” button"
                },
                {
                    id: 6,
                    ques: "What are tags?",
                    ans: "Tags are an easy way to sort posts in feddup pages, or an easy way to let the product admin know what your suggestion or feddback is on"
                },
                {
                    id: 7,
                    ques: "can i add custom tags",
                    ans: "No, a user cannot add custom tags. Tags are set by the page’s admin and can be changed at anytime"
                }
            ]
        }
    }
    render() {
        return (
            <div style={{ background: "#FFB858", height: "100%" }}>
            {
                (!localStorage.getItem('junkStore')) ?
                    <Link to="/"><img alt="" src={fedduplogo} className="logo-login" /></Link> :
                    ((decrypt(localStorage.getItem('junkStore')) && (localStorage.getItem('role') == "admin") || (localStorage.getItem('role') == "subAdmin")) ?
                        <Link to="/admindashboard"><img alt="" src={fedduplogo} className="logo-login" /></Link> :
                        <Link to="/userfeed"><img alt="" src={fedduplogo} className="logo-login" /></Link>)
            }
                {/* <Header />
                <div className="bg-img">
                    <Form className="container-help">
                        <FormControl
                            type="text"
                            placeholder="Search here"
                            id="search_help" className="form-control search_box_help"
                            aria-label="Search keyword"
                        />
                    </Form>
                </div> */}
                <center>
                    <div style={{ color: "#092C4C", fontFamily: "Poppins", fontSize: "28px", fontWeight: "600" }}>Choose the plan that’s right for you.</div>
                    <div style={{ color: "#092C4C", fontFamily: "Poppins", fontSize: "16px", fontWeight: "500" }}><i>Plans below are for companies not for users</i></div><br />
                </center>

                <Row style={{ marginLeft: "25%", marginRight: "25%", width: "50%" }}>
                    <Col md={6}>
                        <Card className="pricing-p">
                            <div className="ml-2">
                                <div className="free">Free </div>
                                <div style={{position:"absolute",right:"10%",top:"8%"}}><img src={fillrating2}/></div>
                                <div className="pricing-ul"></div>
                                <div className="mt-5"><span className="verticalAlign">$ &nbsp;</span><span style={{ color: "#062C51", fontFamily: "Poppins", fontSize: "28px", fontWeight: "600" }}>0</span></div>
                                <div style={{ color: "grey", fontFamily: "Poppins", fontSize: "12px", fontWeight: "600" }}>per month</div>
                            </div>
                            <div>
                                <div className="mt-4"><FaCheckCircle className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">2 feddup pages</span></div>
                                <div className="mt-2"><FaCheckCircle className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">Overall rating</span></div>
                                <div className="mt-2"><FaCheckCircle className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">Standard feedback form</span></div>
                                <div className="mt-2"><FaCheckCircle className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">Only admin access</span></div>
                                <div className="mt-2"><FaCheckCircle className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">Basic admin dashboard</span></div>
                                <div className="mt-2"><FaCheckCircle className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">Advertise for 6 times in an year</span></div>
                                <div className="mt-2"><FaCheckCircle className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">Share contact with selected</span></div>
                            </div>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card className="pricing-p">
                            <div className="ml-2">
                                <div className="free">Premium<br /></div>
                                <div style={{position:"absolute",right:"10%",top:"8%"}}><img src={fillrating5}/></div>
                                <div className="pricing-ul"></div>
                                <div className="mt-5"><span className="verticalAlign">$ &nbsp;</span><span style={{ color: "#062C51", fontFamily: "Poppins", fontSize: "28px", fontWeight: "600" }}>37</span></div>
                                <div style={{ color: "grey", fontFamily: "Poppins", fontSize: "12px", fontWeight: "600" }}>per month</div>
                                <div style={{ color: "grey", fontFamily: "Poppins", fontSize: "11px", fontWeight: "400" }}>First 2 months free</div>
                            </div>
                            <div>
                                <div className="mt-4"><FaCheckCircle className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">Unlimited feddup pages</span></div>
                                <div className="mt-2"><FaCheckCircle className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">Version based rating</span></div>
                                <div className="mt-2"><FaCheckCircle className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">Custom feedback form</span></div>
                                <div className="mt-2"><FaCheckCircle className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">Featurewise feddback</span></div>
                                <div className="mt-2"><FaCheckCircle className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">Sub-admin & moderator capabilities</span></div>
                                <div className="mt-2"><FaCheckCircle className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">Detailed admin dashboards</span></div>
                                <div className="mt-2"><FaCheckCircle className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">Customer can share privately share bug reports</span></div>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <br/><br/>
            </div >
        );
    }
}

import moment from 'moment'
import React, { useEffect } from 'react'
import usePayment from '../utils/usePayment'
import PaymentSuccess from '../SignUp/PaymentSuccess';

function ExpiringSoon({ subscription }) {
    const { createOrder, success, setSuccess } = usePayment();
    useEffect(() => {
        if (success) {
            setTimeout(() => {
                closeSuccess()
            }, 3000)
        }
    }, [success])

    const closeSuccess = () => {
        setSuccess(false);
        localStorage.removeItem('ssSeen')
        window.location.replace('/admindashboard');
    }
    if (success) return <PaymentSuccess closeSuccess={closeSuccess} />

    return (
        <div className='fixed inset-0 grid place-items-center' style={{ zIndex: 10000 }}>
            <div className='bg-black bg-opacity-70 fixed inset-0'></div>

            <div className='relative bg-[#E6900E] text-white w-full max-w-2xl rounded-xl flex flex-col justify-between gap-20 p-4'>
                <div>
                    <h1 className='text-2xl font-bold'>Oh No! its almost time...</h1>
                    <p className='italic font-light text-lg'>Subscription ending soon</p>
                </div>
                <h1 className='text-center text-lg'>Your feddup Premium subscription ends in <span className='text-4xl font-semibold'>{moment(subscription?.expiryDate).diff(moment(), 'days')} days</span></h1>

                <div className='flex items-center justify-between'>
                    <button className='py-1.5 px-6 text-lg rounded-lg bg-[#E6570E]' onClick={() => { localStorage.removeItem("ssSeen"); window.location.reload() }}>Continue to portal</button>
                    <button className='py-1.5 px-6 text-lg rounded-lg bg-[#649E36]' onClick={() => createOrder({ planId: subscription?.metadata?.planId })}>Renew Now</button>
                </div>
            </div>
        </div>
    )
}

export default ExpiringSoon
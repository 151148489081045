import React, { useEffect, useState, version } from 'react'
import axios from 'axios';
import { config } from '../Config/Config';
import moment from 'moment';
import { Col, Row, Modal, Button } from 'react-bootstrap'
import addFileIcon from "../images/add-file.svg";
import rating1 from '../images/rating1.png';
import rating2 from '../images/rating2.png';
import rating3 from '../images/rating3.png';
import rating4 from '../images/rating4.png';
import rating5 from '../images/rating5.png';
import arrowLeft from "../images/arrow-left-black.svg"
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import NavBar1 from '../NavBar/NavBar1';
import MultiSelect from '../utils/MultiSelect';
import { decrypt } from '../utils';

const sortVersionList = (arr) => {

    const sortedVersions = arr.sort((a, b) => {
        const versionA = a.label.split('.').map(Number);
        const versionB = b.label.split('.').map(Number);

        for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
            const numA = versionA[i] || 0;
            const numB = versionB[i] || 0;

            if (numA < numB) {
                return 1;
            } else if (numA > numB) {
                return -1;
            }
        }

        return 0;
    });

    return sortedVersions

}

function ViewForm({ togglePreview, createFeedbackForm }) {
    const location = useLocation();
    const history = useHistory();
    const { name, id, productid } = location.state;
    const [feedbackForm, setFeedbackForm] = useState(null);
    const [versionDetails, setVersionDetails] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [showVersion, setShowVersion] = useState(false);
    const [versions, setVersions] = useState([]);
    const [currentVersion, setCurrentVersion] = useState();
    const [selectedVersionId, setSelectedVersionId] = useState([]);
    const [selectOptions, setSelectOptions] = useState([]);
    const [productIcon, setProductIcon] = useState("");
    const [productType, setProductType] = useState("");
    const [productName, setProductName] = useState("");
    const [versionOptions, setVersionOptions] = useState([]);


    useEffect(() => {
        getFeedbackForm();
        window.scrollTo(0, 0);
    }, [])
    const getFeedbackForm = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "formId": id,
            "productId": productid
        }
        axios.post(config.userUrl + "admin/getFeedbackForm", payload, { headers: headers })
            .then((response) => {
                console.log(response.data);
                setFeedbackForm(response.data.feedbackForms);
                setQuestions(response.data.feedbackForms.questions);
                setVersionDetails(response.data.feedbackForms.versionDetails)
                getProductVersions(response.data.feedbackForms.versionDetails);
            })
            .catch((err) => {
                console.log(err)
            });
    }
    const getProductVersions = (formCurrentVersion) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "formId": id,
            "productId": productid
        }
        axios.post(config.userUrl + "admin/getProductVersionDetail", payload, { headers: headers })
            .then((response) => {
                setProductIcon(response.data.productDetail[0].productIcon);
                console.log("response.data.versions ", response.data.versions)
                setProductName(response.data.productDetail[0].productName);
                setProductType(response.data.productDetail[0].productType);
                setVersions(response.data.versions)
                setCurrentVersion(response.data.currentVersion);
                let versionOptionsArray = [...response.data.versions];
                let modifiedArray = versionOptionsArray.filter(form => form.reviewEnabled === true);
                console.log("version options arry", modifiedArray);
                // if (formCurrentVersion.length > 0) {
                //     let existingFormVersions = formCurrentVersion.map(form => form.id)
                //     modifiedArray = modifiedArray.filter(form => !existingFormVersions.includes(form.id))
                // }
                setSelectOptions(modifiedArray)
                updateVersionOptions(modifiedArray)
            })
            .catch((err) => {
                console.log(err)
            });
    }
    const useCurrentForm = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "formId": id,
            "productId": productid,
            "versionId": selectedVersionId
        }
        axios.post(config.userUrl + "admin/useCurrentForm", payload, { headers: headers })
            .then((response) => {
                console.log(response.data);
                setShowVersion(false);
                history.goBack();
            })
            .catch((err) => {
                console.log(err)
            });
    }
    const handleEditClick = () => {
        history.push("/admin/newfeedbackform", { name: feedbackForm.formName, id, productid, edit: true });
    }
    const updateVersionOptions = (versions) => {
        let currentOptions = versions.map((item) => {
            let obj = {
                value: item.id,
                label: item.version
            }
            return obj;
        })
        setVersionOptions(currentOptions)
    }

    const handleOptionChange = (option, action) => {
        if (action.action === "clear") {
            setSelectedVersionId([]);
        } else if (action.action === "remove-value") {
            setSelectedVersionId(selectedVersionId.filter(version => version !== option.value))
        } else
            setSelectedVersionId(option.map(item => item.value))
    }
    const getDefaultVersion = () => {
        console.log("default version", versionOptions, versionDetails);
        let defaultOptions = versionOptions.filter(option => versionDetails.some((detail) => detail.version === option.label && detail.beingUsed))
        console.log(defaultOptions);
        return defaultOptions;
    }
    const getBackGroundColor = (formStatus, details) => {
        let color = "bg-orange-600"
        if (formStatus === "Saved as Draft" || !details) {
            return color;
        }
        for (const element of details) {
            if (element.beingUsed && element.latestVersion) {
                color = "bg-green-700"
                break;
            }
            if (element.beingUsed && !element.latestVersion) {
                color = "bg-purple-800"
            }
            else if (!element.beingUsed && element.latestVersion) {
                color = "bg-orange-400"
            }
        }
        return color;
    }

    return (
        <>
            <NavBar1 />
            <div className='bg-gray-100 min-h-[100dvh] px-20 mt-16 py-6'>
                <Row>
                    <Col md={2}>
                        <div className='flex justify-end w-full mt-8'>
                            <img src={arrowLeft} alt='' onClick={() => history.goBack()} className='cursor-pointer' />
                        </div>
                    </Col>
                    <Col md={7}>
                        <div className='flex gap-2 items-center mb-2'>
                            <div className='bg-white rounded-full' style={{ width: "100px", height: "100px" }}>
                                <img src={productIcon} alt='' />
                            </div>
                            <div className='flex flex-col'>
                                <span className='text-2xl font-semibold'>{productName}</span>
                                <span className='text-xl font-medium'>{productType === 1 ? "Desktop Application" : "Web Application"}</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='flex mb-20'>
                    <Col md={2}>
                    </Col>
                    <Col md={7}>
                        <div className='flex flex-col'>
                            <div className='flex gap-2 items-center'>
                                <div className={`w-5 h-5 rounded-full ${getBackGroundColor(feedbackForm?.status, feedbackForm?.versionDetails)}`}></div>
                                <p className='text-2xl text-black font-semibold mb-2'>{feedbackForm && feedbackForm.formName}</p>
                            </div>
                            <div className='flex'>
                                <p className={`text-lg text-black font-semibold mb-2 ${feedbackForm ? "" : "hidden"}`}>{moment(feedbackForm?.createdAt, "YYYY-MM-DD HH:mm:ss.SSSZ").format("DD/MM/YY")}-</p>
                                <p className={`text-lg text-black font-semibold mb-2 ${feedbackForm ? "" : "hidden"}`}>{feedbackForm?.status === "In Use" ? "present" : moment(feedbackForm?.updatedAt, "YYYY-MM-DD HH:mm:ss.SSSZ").format("DD/MM/YY")}</p>
                            </div>
                            {questions && questions.map((ques, i) => {
                                if (ques[i + 1]["type"] === "mcq") {
                                    return (
                                        <div key={i + "mcq"} className='bg-white h-max px-4 py-8 rounded-xl mb-4'>
                                            <p className='text-base text-black font-semibold'>1. {ques[i + 1]["question"]}</p>

                                            <form className='flex flex-col gap-3 mt-2'>
                                                {ques[i + 1].options.map((option, index) => {
                                                    return (
                                                        <label className='flex gap-3' key={index + "mcqoptions"}>
                                                            <input type="checkbox" name={ques[i + 1]["question"]} value={option} disabled />
                                                            {option}
                                                        </label>
                                                    )
                                                })}
                                            </form>
                                            <div className={`${ques[i + 1]["required"] ? "mt-3 flex gap-8 items-center" : "hidden"}`}>
                                                <div className='w-2 h-2 bg-orange-600 border'></div>
                                                <p className='text-sm text-black font-medium'>Respondents must answer this question</p>
                                            </div>
                                        </div>
                                    )
                                }
                                else if (ques[i + 1]["type"] === "singlechoice") {
                                    return (
                                        <div key={i + "singlechoice"} className='bg-white h-max px-4 py-8 rounded-xl mb-4'>
                                            <p className='text-base text-black font-semibold'>{i + 1}. {ques[i + 1]["question"]}</p>

                                            <form className='flex flex-col gap-3 mt-2'>
                                                {ques[i + 1].options.map((option, index) => {
                                                    return (
                                                        <label className='flex gap-3' key={index + "singleoptions"}>
                                                            <input type="radio" name={ques[i + 1]["question"]} value={option} disabled />
                                                            {option}
                                                        </label>
                                                    )
                                                })}
                                            </form>
                                            <div className={`${ques[i + 1]["required"] ? "mt-3 flex gap-8 items-center" : "hidden"}`}>
                                                <div className='w-2 h-2 bg-orange-600 border'></div>
                                                <p className='text-sm text-black font-medium'>Respondents must answer this question</p>
                                            </div>
                                        </div>
                                    )
                                }
                                else if (ques[i + 1]["type"] === "rating") {
                                    return (
                                        <div key={i + "rating"} className='bg-white h-max px-4 py-8 rounded-xl mb-4'>
                                            <p className='text-base text-black font-semibold'>{i + 1}. {ques[i + 1]["question"]}</p>
                                            {ques[i + 1]["scaleType"] === "Yes-No" ?
                                                <div className='flex w-full justify-center gap-8'>
                                                    <div className='flex items-center gap-2'>
                                                        <span className='w-4 h-4 rounded-full border'></span>
                                                        <p>Yes</p>
                                                    </div>
                                                    <div className='flex items-center gap-2'>
                                                        <span className='w-4 h-4 rounded-full border'></span>
                                                        <p>Uncertain</p>
                                                    </div>
                                                    <div className='flex items-center gap-2'>
                                                        <span className='w-4 h-4 rounded-full border'></span>
                                                        <p>No</p>
                                                    </div>

                                                </div> :
                                                <div className='flex my-4'>
                                                    <div className='flex w-32 gap-2 items-center'>
                                                        <img src={ques[i + 1]["scoringDirection"] === "Ascending" ? rating1 : rating5} alt='' className='w-10' />
                                                        <p>{ques[i + 1]["scoringDirection"] === "Ascending" ? ques[i + 1]["ratingDesc"][0] : ques[i + 1]["ratingDesc"][4]}</p>
                                                    </div>
                                                    <div className='flex w-32 gap-2 items-center'>
                                                        <img src={ques[i + 1]["scoringDirection"] === "Ascending" ? rating2 : rating4} alt='' className='w-10' />
                                                        <p>{ques[i + 1]["scoringDirection"] === "Ascending" ? ques[i + 1]["ratingDesc"][1] : ques[i + 1]["ratingDesc"][3]}</p>
                                                    </div>
                                                    <div className='flex w-32 gap-2 items-center'>
                                                        <img src={rating3} alt='' className='w-10' />
                                                        <p>{ques[i + 1]["ratingDesc"][2]}</p>
                                                    </div>
                                                    <div className='flex w-32 gap-2 items-center'>
                                                        <img src={ques[i + 1]["scoringDirection"] === "Ascending" ? rating4 : rating2} alt='' className='w-10' />
                                                        <p>{ques[i + 1]["scoringDirection"] === "Ascending" ? ques[i + 1]["ratingDesc"][3] : ques[i + 1]["ratingDesc"][1]}</p>
                                                    </div>
                                                    <div className='flex w-32 gap-2 items-center'>
                                                        <img src={ques[i + 1]["scoringDirection"] === "Ascending" ? rating5 : rating1} alt='' className='w-10' />
                                                        <p>{ques[i + 1]["scoringDirection"] === "Ascending" ? ques[i + 1]["ratingDesc"][4] : ques[i + 1]["ratingDesc"][0]}</p>
                                                    </div>
                                                </div>
                                            }
                                            <div className={`${ques[i + 1]["required"] ? "mt-3 flex gap-8 items-center" : "hidden"}`}>
                                                <div className='w-2 h-2 bg-orange-600 border'></div>
                                                <p className='text-sm text-black font-medium'>Respondents must answer this question</p>
                                            </div>
                                        </div>
                                    )
                                }
                                else if (ques[i + 1]["type"] === "textfield") {
                                    return (
                                        <div key={i + "textfield"} className='bg-white h-max px-4 py-8 rounded-xl mb-4'>
                                            <p className='text-base text-black font-semibold'>{i + 1}. {ques[i + 1]["question"]}</p>
                                            <div className='border rounded-lg w-full flex flex-col gap-2 my-2'>
                                                <textarea
                                                    className="p-2 min-h-[7rem] resize-none w-full focus:outline-none bg-white"
                                                    placeholder="Enter additional feedback here"
                                                    disabled
                                                />
                                                <div className='flex justify-between p-2'>
                                                    {/* <img src={addFileIcon} alt='' className='w-6' /> */}
                                                    <p className='text-gray-400'>{ques[i + 1]["wordLimit"]}</p>
                                                </div>
                                            </div>
                                            <div className={`${ques[i + 1]["required"] ? "mt-3 flex gap-8 items-center" : "hidden"}`}>
                                                <div className='w-2 h-2 bg-orange-600 border'></div>
                                                <p className='text-sm text-black font-medium'>Respondents must answer this question</p>
                                            </div>
                                        </div>
                                    )
                                }
                                else if (ques[i + 1]["type"] === "generic") {
                                    return (
                                        <div key={i + "textfield"} className='bg-white h-max px-4 py-8 rounded-xl mb-4'>
                                            <p className="text-2xl">Generic Question</p>
                                            <p className='my-3 text-black text-lg '>Rate your overall Experience for this version</p>
                                            <div className='flex w-full mt-2 my-4'>
                                                <div className='flex w-48 gap-2 items-center'>
                                                    <img src={rating5} alt='' className='w-10' />
                                                    <p>Love it!</p>
                                                </div>
                                                <div className='flex w-48 gap-2 items-center'>
                                                    <img src={rating4} alt='' className='w-10' />
                                                    <p>Like it!</p>
                                                </div>
                                                <div className='flex w-48 gap-2 items-center'>
                                                    <img src={rating3} alt='' className='w-10' />
                                                    <p>It's OK!</p>
                                                </div>
                                                <div className='flex w-48 gap-2 items-center'>
                                                    <img src={rating2} alt='' className='w-10' />
                                                    <p>Hate it!</p>
                                                </div>
                                                <div className='flex w-48 gap-2 items-center'>
                                                    <img src={rating1} alt='' className='w-10' />
                                                    <p>Fedup!</p>
                                                </div>
                                            </div>
                                            <textarea
                                                className="border border-gray-400 rounded p-2 min-h-[12rem] mb-2 resize-none w-full border-gray focus:outline-none"
                                                placeholder="Additional Thoughts"
                                                disabled
                                            />
                                            <div className={`${ques[i + 1]["required"] ? "mt-3 flex gap-8 items-center" : "hidden"}`}>
                                                <div className='w-2 h-2 bg-orange-600 border'></div>
                                                <p className='text-sm text-black font-medium'>Respondents must answer this question</p>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </Col>
                    <Col md={2} >
                        <div className='flex flex-col justify-end items-end mt-8 text-white'>
                            <div className={`bg-orange-400  w-32 h-auto p-2 mb-4 flex justify-center items-center rounded-lg cursor-pointer ${versionDetails.length > 0 ? "invisible" : ""}`} onClick={handleEditClick}>Edit Form</div>
                            {/* <div className='bg-orange-600  h-auto p-2 mb-4 flex justify-center items-center rounded-lg cursor-pointer' onClick={handlePublish}>Use Another Form</div> */}
                            <div className={`bg-green-700 w-32 h-auto p-2 flex justify-center items-center rounded-lg cursor-pointer`} onClick={() => setShowVersion(true)}>Use this Form</div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal show={showVersion} onHide={() => setShowVersion(false)} size="md" centered dialogClassName='modal-dialog-add'>
                <Modal.Title>
                    <p className='text-2xl my-8 mx-12 font-semibold  underline text-orange-400 underline-offset-8'><span className='text-black'>Choose Version</span></p>
                </Modal.Title>
                <Modal.Body>
                    <div className='mt-6 ml-16 w-full'>
                        <p className='text-xl font-semibold'>Select from created versions</p>
                        <div className="flex border px-2 py-1 w-8/12 h-20 rounded-md bg-white mt-2 text-black">
                            <MultiSelect options={sortVersionList(versionOptions)?.slice(0, 3)} handleChange={handleOptionChange} placeholder={"--Choose a version--"} defaultValue={getDefaultVersion} />
                        </div>
                        <div className='flex justify-end items-end mb-8 mr-12 mt-28 relative z-0'>
                            <div className='btn-outer'>
                                <div className='btn-1' style={{ width: "120px" }}>
                                </div>
                                <Button
                                    variant="default" size="md"
                                    disabled={selectedVersionId.length === 0 && versionDetails.length === 0 ? true : false}
                                    className={`btn-2 text-white ${selectedVersionId.length === 0 ? "disabled" : "enabled"}`}
                                    onClick={useCurrentForm}
                                    style={{ width: "120px", backgroundColor: "#E6570E" }}

                                >
                                    Update
                                </Button>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ViewForm
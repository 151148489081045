import React, { Component } from "react";
import { Button, Form, Col, Row, Image, Modal } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import fedduplogo from "../images/fedduplogo.png";
import loginimg from "../images/login-img.png";
import facebook from "../images/facebook.png";
import google from "../images/google.png";
import './Login.css';
import axios from 'axios';
import { config } from '../Config/Config';
import forgotImg from '../images/forgot-screen-img.png';
import toast from "react-hot-toast";

export default class VerifyOtp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: this.props.location.state.email,
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            otp5: "",
            otp6: "",
            sort: false,
            try: false,
            registerFlow: this.props.location.state.registerFlow,
            newPassword: false,
            redirect: false,
            authKey: "",
            modal: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(value1, event) {
        this.setState({ [value1]: event.target.value });
    }
    handleSubmit(event) {
        const data = new FormData(event.target);
        event.preventDefault()
        if (this.props.location.state.name) {
            this.verifyOtp();
        } else if (this.props.location.state.name || this.props.location.state.registerFlow === true) {
            this.verifyOtp();
        }
        else {
            this.verifyForgotPasswordEmail();
        }
    }
    componentDidMount() {

    }
    verifyForgotPasswordEmail = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var self = this;
        var payload = {
            "email": this.state.email,
            "otp": this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4 + this.state.otp5 + this.state.otp6
        }
        axios.post(config.apiUrl + 'auth/forgotPassword/verifyEmail', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1);
                if (response.data.status === true) {
                    console.log("Forgot Password Email verified successfully!!")
                    self.setState({
                        newPassword: true,
                        authKey: response.data.authkey
                    })
                } else {
                    toast.error(response.data.message, { id: 'err' })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    verifyOtp = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var self = this;
        var payload = {
            "email": this.state.email,
            "otp": this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4 + this.state.otp5 + this.state.otp6
        }
        axios.post(config.apiUrl + 'auth/verifyEmail', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1);
                if (response.data.status === true) {
                    console.log("Email verified successfully!!")
                    self.setState({
                        redirect: true
                    })
                } else {
                    toast(msg, { id: 'err' })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    resendOtp = (e) => {
        this.setState({ modal: true });
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var payload = {
            "email": this.state.email,
            "registerFlow": this.state.registerFlow
        }
        axios.post(config.apiUrl + 'auth/sendOtp', payload, { headers: headers })
            .then(function (response) {
                const msg = "Otp sent successfully!!";
                if (response.data.status === true) {
                    console.log("Otp sent successfully!!")
                    document.getElementById('otp').innerText = msg
                } else {
                    console.log("err", payload)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        setTimeout(() => {
            this.setState({ modal: false });
        }, 4000)
    }
    inputfocus = (elmnt) => {
        if (elmnt.key === "Backspace" || elmnt.key === "Delete") {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {
                elmnt.target.form.elements[next].focus()
            }
        }
        else {
            const next = elmnt.target.tabIndex;
            if (next < 6) {
                elmnt.target.form.elements[next].focus()
            }
        }

    }
    render() {
        const { redirect, newPassword } = this.state;
        if (newPassword) {
            return <Redirect
                to={{
                    pathname: "/resetpassword",
                    state: { email: this.state.email, authKey: this.state.authKey }
                }} />
        }
        if (redirect) {
            return <Redirect
                to={{
                    pathname: "/welcomefeddup",
                    state: { email: this.state.email }
                }}
            />
        }
        return (
            <div>
                <span> <Link to="/login"> <img src={fedduplogo} className="logo-login mt-1 mb-4" /></Link><span className="help"></span></span>
                <Row style={{ width: "100%" }} className="h-[80dvh] flex items-center justify-center pb-32" >
                    <Col sm={7} md={7}>
                        <Image src={loginimg} className="img-login object-contain" />
                    </Col>
                    <Col sm={5} md={5}>
                        <div className="login">
                            <h3 className="forgot-top font_family font-bold text-3xl">Verify your Identity</h3>
                            <div className="mt-3 font_family text-[18px]">Please enter the otp we sent to your registered email id.</div>
                            <Form onSubmit={this.handleSubmit} className="mt-2 font_family">
                                <div className="form-group verify ml-4">
                                    <input
                                        name="otp1"
                                        type="text"
                                        autoComplete="off"
                                        className="form-control digit-width"
                                        value={this.state.otp1}
                                        onKeyPress={this.keyPressed}
                                        onChange={e => this.handleChange("otp1", e)}
                                        tabIndex="1" maxLength={1} onKeyUp={e => this.inputfocus(e)}

                                    />
                                    <input
                                        name="otp2"
                                        type="text"
                                        autoComplete="off"
                                        className="form-control digit-width"
                                        value={this.state.otp2}
                                        onChange={e => this.handleChange("otp2", e)}
                                        tabIndex="2" maxLength={1} onKeyUp={e => this.inputfocus(e)}

                                    />
                                    <input
                                        name="otp3"
                                        type="text"
                                        autoComplete="off"
                                        className="form-control digit-width"
                                        value={this.state.otp3}
                                        onChange={e => this.handleChange("otp3", e)}
                                        tabIndex="3" maxLength="1" onKeyUp={e => this.inputfocus(e)}

                                    />
                                    <input
                                        name="otp4"
                                        type="text"
                                        autoComplete="off"
                                        className="form-control digit-width"
                                        value={this.state.otp4}
                                        onChange={e => this.handleChange("otp4", e)}
                                        tabIndex="4" maxLength={1} onKeyUp={e => this.inputfocus(e)}
                                    />

                                    <input
                                        name="otp5"
                                        type="text"
                                        autoComplete="off"
                                        className="form-control digit-width"
                                        value={this.state.otp5}
                                        onChange={e => this.handleChange("otp5", e)}
                                        tabIndex="5" maxLength={1} onKeyUp={e => this.inputfocus(e)}
                                    />
                                    <input
                                        name="otp6"
                                        type="text"
                                        autoComplete="off"
                                        className="form-control digit-width"
                                        value={this.state.otp6}
                                        onChange={e => this.handleChange("otp6", e)}
                                        tabIndex="6" maxLength={1} onKeyUp={e => this.inputfocus(e)}
                                    />
                                </div>
                                <div className='btn-outer mx-auto mt-10'>
                                    <div className='btn-1 '></div>
                                    <Button
                                        variant="default" size="md" block
                                        type="submit"
                                        className="login_button btn-2"
                                        style={{ fontSize: "22px" }}
                                    >
                                        Verify
                                    </Button>
                                </div>
                                <center>
                                    {/* <div id="err-msg" className="errormsg"></div>
                                    <div id="otp" className="errormsg"></div> */}
                                    <p className="mt-4">Something wrong? did not recive the OTP?<span style={{ color: "#E6570E", cursor: "pointer" }} onClick={this.resendOtp}> Send again</span></p>
                                </center>
                            </Form>
                        </div>
                    </Col>
                </Row>
                <Modal
                    size="md"
                    show={this.state.modal}
                    onHide={() => this.setState({ modal: false })}
                    dialogClassName="modal-60w"
                    aria-labelledby="example-modal-sizes-title-lg"
                    className="mt-32"
                >
                    <Modal.Header>
                        <Modal.Title >
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <center>
                            <p className="forgot-text font-normal text-3xl mt-4" style={{ marginTop: "-2rem" }}>We sent you a new OTP.</p>
                            <img alt="" src={forgotImg} className="forgotImg" /><br />
                            <p className="forgot-text-sm ml-2 mr-2 text-2xl">Please check your email.</p>
                            <p className="forgot-text-sm ml-2 mr-2 text-2xl">OTP is valid for 1 hr</p>
                        </center><br /><br />

                    </Modal.Body>
                </Modal>
                <footer>
                    <p className="copyright" style={{ bottom: "0" }}>Copyright {new Date().getFullYear()} Feddup. All right reserved</p>
                </footer>
            </div >
        );
    }
}

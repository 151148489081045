import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col, Image, Container } from "react-bootstrap";
import { Redirect } from 'react-router-dom';
import fedduplogo from "../images/fedduplogo.png";
import thanks from "../images/thanksforregistering.png";
import '../Login/Login.css';
import axios from 'axios';
import { config } from '../Config/Config';
import camera from "../images/camera.svg";
import { decrypt, uploadImage } from "../utils";
import toast from "react-hot-toast";

export default function CompanyInfo() {
    const [token, setToken] = useState(decrypt(localStorage.getItem('junkStore')));
    const [companyId, setCompanyId] = useState("");
    const [companyIcon, setCompanyIcon] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companyWebsite, setCompanyWebsite] = useState("");
    const [adminName, setAdminName] = useState("");
    const [headquarter, setHeadquarter] = useState("");
    const [adminNameError, setAdminNameError] = useState("");
    const [companyWebsiteError, setCompanyWebsiteError] = useState("");
    const [headquarterError, setHeadquarterError] = useState("");
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        getCompanyDetails();
    }, []);
    let handleBlur = (event) => {
        const { name } = event.target;
        return;
    };
    let handleSubmit = (event) => {
        event.preventDefault();
        let formFiledsNew = [
            "companyWebsite",
            "adminName",
            "headquarter"
        ];
        let isValidNew = true;
        formFiledsNew.forEach(field => {
            isValidNew = validateField(field) && isValidNew;
        });
        if (!isValidNew) {
            toast("Please enter the details", { id: 'info' })
        } else {
            updateCompanyDetails();
        }
    };
    let validateField = (name) => {
        let isValid = false;
        if (name === "companyWebsite") isValid = validateCompanyWebsite();
        else if (name === "adminName") isValid = validateAdminName();
        else if (name === "headquarter") isValid = validateHeadquarter();
        return isValid;
    }
    let getCompanyDetails = () => {
        console.log("company info ")
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'admin/getCompanyProfileDetailsByAdmin', { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                console.log(response)
                if (response.data.status === true) {
                    console.log("Company details retrieved successfully!!", response.data.companyDetail);
                    setCompanyName(response.data.companyDetail.companyName);
                    response.data.companyDetail.companyWebsite && setCompanyWebsite(response.data.companyDetail.companyWebsite);
                    response.data.companyDetail.userName && setAdminName(response.data.companyDetail.userName);
                    response.data.companyDetail.companyIcon && setCompanyIcon(response.data.companyDetail.companyIcon);
                    response.data.companyDetail.companyheadquarter && setHeadquarter(response.data.companyDetail.companyheadquarter);
                } else {
                    console.log(response.data.message)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    let addImage = async (e) => {
        // setCompanyIcon(e.target.files[0]);
        let img_url = await uploadImage(e.target.files[0]);
        console.log(img_url);
        if (img_url) setCompanyIcon(img_url);
    }
    let validateCompanyWebsite = () => {
        let companyWebsiteError = "";
        const value = companyWebsite;
        if (value.trim() === "") companyWebsiteError = "Please add Company Website";
        else if (value.length < 5)
            companyWebsiteError = 'Please add a valid Company Website';
        setCompanyWebsiteError(companyWebsiteError)
        return companyWebsiteError === "";
    }
    let validateAdminName = () => {
        let adminNameError = "";
        const value = adminName;
        if (value.trim() === "") adminNameError = "Admin Name must be at least 3 characters long!";
        else if (value.length < 3)
            adminNameError = 'Admin Name must be at least 3 characters long!';
        setAdminNameError(adminNameError);
        return adminNameError === "";
    }
    let validateHeadquarter = () => {
        let headquarterError = "";
        const value = headquarter;
        if (value.trim() === "") headquarterError = "Please enter the country name";
        else if (value.length < 5)
            headquarterError = "Please enter the country name";
        setHeadquarterError(headquarterError);
        return headquarterError === "";
    }
    let updateCompanyDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "companyIcon": companyIcon,
            "companyWebsite": companyWebsite,
            "adminName": adminName,
            "companyheadquarter": headquarter
        }
        axios.post(config.userUrl + 'admin/updateCompanyDetails', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("Company details updated successfully!!");
                    setRedirect(true);

                } else {
                    console.log(response.data.message)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    if (redirect) {
        return (<Redirect
            to={{
                pathname: `/buildprofile/addProduct`
            }}
        />)
    }
    return (
        <div>
            <Row className="w-full">
                <Col md={4}><a href="/"><img src={fedduplogo} className="logo-login" style={{ width: "15%" }} alt="feddup-logo" /></a></Col>
                <Col md={4} className="ml-16">
                    <div className="text-4xl text-orange-600 font-bold mt-4">Let's get you all set up!</div>
                    <div className="font-normal text-3xl text-orange-400 ml-4">3 quick and easy steps</div>
                </Col>
            </Row>
            <Container style={{ marginTop: "2rem" }}>
                <Row>
                    <Col sm={4} md={4} className="mt-16">
                        <center>
                            <Image src={thanks} style={{ width: "70%" }} /></center>
                    </Col>
                    <Col sm={8} md={8}>
                        <div className="max-w-3xl">
                            <div className="container">
                                <ul className="progressbar">
                                    <li className="current" style={{ color: "black" }}>Company Info</li>
                                    <li style={{ color: "black" }}>First Feddup Page</li>
                                    <li style={{ color: "black" }}>Choose Plan</li>
                                </ul>
                            </div>
                            <Row className="ml-8">
                                <Col sm={3} md={3}>
                                    {companyIcon ? <div className="flex flex-col items-center mt-8 h-40 w-40 rounded-[10px] relative overflow-hidden border-2 border-orange-500 group">
                                        <img src={companyIcon} alt="" className="w-full h-full object-cover" />
                                        <label className="text-center cursor-pointer z-10 absolute inset-0 -bottom-2 bg-black bg-opacity-40 hidden group-hover:flex flex-col items-center justify-center">
                                            <img src={camera} alt="" className="mt-4 filter invert" />
                                            <span className="text-sm text-white py-1 px-2">Upload New</span>
                                            <input type='file' accept="image/*" className="hidden" onChange={addImage} />
                                        </label>
                                    </div> :
                                        <div className="flex flex-col gap-4 items-center mt-8 w-40 h-40 bg-gray-100 rounded-[10px] overflow-hidden border-2 border-orange-500">
                                            <img src={camera} alt="" className="mt-4" />
                                            <label className="items-center px-4 text-center cursor-pointer ">
                                                <span className="mt-2 text-base leading-normal">Upload your company logo</span>
                                                <input type='file' accept="image/*" className="hidden" onChange={addImage} />
                                            </label>
                                        </div>
                                    }
                                </Col>
                                <Col sm={9} md={9} className="max-w-md">
                                    <Form onSubmit={handleSubmit} className="font_family">
                                        <div className="mt-3 form-group">
                                            <label className="font-medium text-lg mb-0">Company Name</label>
                                            <input type="text" className="form-control border placeholder-gray-500 text-black focus:text-black" aria-describedby="text"
                                                value={companyName}
                                                name="companyName"
                                                placeholder="Company Name"
                                                onBlur={handleBlur}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="font-medium text-lg mb-0">Company Website</label>
                                            {companyWebsiteError !== "" ?
                                                <input type="text" className="form-control error-border placeholder-gray-500 text-black focus:text-black" aria-describedby="text"
                                                    value={companyWebsite}
                                                    name="companyWebsite"
                                                    placeholder="Company Website"
                                                    onChange={(e) => setCompanyWebsite(e.target.value)}
                                                    onBlur={handleBlur}
                                                /> :
                                                <input type="text" className="form-control border placeholder-gray-500 text-black focus:text-black" aria-describedby="text"
                                                    value={companyWebsite}
                                                    name="companyWebsite"
                                                    placeholder="Company Website"
                                                    onChange={(e) => setCompanyWebsite(e.target.value)}
                                                    onBlur={handleBlur}
                                                />}
                                            <div>{companyWebsiteError && (
                                                <div className="error">{companyWebsiteError}</div>
                                            )}</div>
                                        </div>
                                        <div className="form-group">
                                            <label className="font-medium text-lg mb-0">Company Admin Name</label>
                                            {adminNameError !== "" ?
                                                <input type="text" className="form-control error-border placeholder-gray-500 text-black focus:text-black" aria-describedby="text"
                                                    value={adminName}
                                                    name="adminName"
                                                    placeholder="Compnay Admin"
                                                    onChange={(e) => setAdminName(e.target.value)}
                                                    onBlur={handleBlur}
                                                /> :
                                                <input type="text" className="form-control border placeholder-gray-500 text-black focus:text-black" aria-describedby="text"
                                                    value={adminName}
                                                    name="adminName"
                                                    placeholder="Company Admin"
                                                    onChange={(e) => setAdminName(e.target.value)}
                                                    onBlur={handleBlur}
                                                />}
                                            <div>{adminNameError && (
                                                <div className="error">{adminNameError}</div>
                                            )}</div>
                                        </div>
                                        <div className="form-group mb-2">
                                            <label className="font-medium text-lg mb-0">Company Headquarter</label>
                                            {headquarterError !== "" ?
                                                <input type="text" className="form-control error-border placeholder-gray-500 text-black focus:text-black" aria-describedby="text"
                                                    value={headquarter}
                                                    name="headquarter"
                                                    placeholder="Headquarter"
                                                    onChange={(e) => setHeadquarter(e.target.value)}
                                                    onBlur={handleBlur}
                                                /> :
                                                <input type="text" className="form-control border placeholder-gray-500 text-black focus:text-black" aria-describedby="text"
                                                    value={headquarter}
                                                    name="headquarter"
                                                    placeholder="Headquarter"
                                                    onChange={(e) => setHeadquarter(e.target.value)}
                                                    onBlur={handleBlur}
                                                />}
                                            <div className="div">{headquarterError && (
                                                <div className="error mb-4">{headquarterError}</div>
                                            )}</div>
                                        </div>
                                        <div className='btn-outer mt-4 ml-auto' style={{ width: "100px", height: "30px" }}>
                                            <div className='btn-1' style={{ width: "100px", height: "30px" }}></div>
                                            <Button
                                                variant="default" size="md" block
                                                type="submit"
                                                className="login_button btn-2"
                                                style={{ fontSize: "16px", width: "100px", height: "30px", lineHeight: "20px" }}
                                            >
                                                Next
                                            </Button>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>

        </div>
    );
}

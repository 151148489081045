import { React, Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Navbar } from "react-bootstrap";
import fedduplogo from '../images/fedduplogo.png';
import './Header.css';

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = { clicked: false }
    }
    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }
    render() {
        return (
            <Navbar bg="white" style={{ boxShadow: "0px 1px 15px #999 " }} className="navbar-height" fixed="top">
                {/* <nav>

                    <div className="menu-icon" onClick={this.handleClick}>
                        <i className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
                    </div>
                    <ul className={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>
                        <li className='bg-red-100 flex-grow'>
                            <Link to="/faqs" className='nav-links'>FAQs</Link>
                            <Link to="/pricingplans" className='nav-links '>Pricing</Link>
                            <Link to="/login">
                                <Button variant="default" className='nav-links-feddupbtn btn-feddup' >Login/Register</Button>
                            </Link>

                        </li>
                    </ul>
                </nav> */}
                <nav className='flex items-center justify-between w-full 2xl:px-5'>
                    <div className='w-36'>
                        <Link to="/">
                            <img className="w-12" src={fedduplogo} />
                        </Link>
                    </div>
                    <ul className='flex items-center gap-6 text-lg font-medium'>
                        <li>
                            <Link to="/" className={`hover:text-orange-500 pb-2 px-3 border-b-2 ${window.location.pathname == '/' ? 'border-orange-500 text-orange' : 'border-transparent'} !no-underline`}>Home</Link>
                        </li>
                        <li>
                            <Link to="/faqs" className={`hover:text-orange-500 pb-2 px-3 border-b-2 ${window.location.pathname == '/faqs' ? 'border-orange-500 text-orange' : 'border-transparent'} !no-underline`}>FAQs</Link>
                        </li>
                        <li>
                            <Link to="/pricing" className={`hover:text-orange-500 pb-2 px-3 border-b-2 ${window.location.pathname == '/pricing' ? 'border-orange-500 text-orange' : 'border-transparent'} !no-underline`}>Pricing</Link>
                        </li>
                    </ul>
                    <div className='w-36'>
                        <Link to="/login">
                            <div className='btn-outer mt-1'>
                                <div className='btn-1' style={{ width: "150px", height: '38px' }}>
                                </div>
                                <Button
                                    variant="default" size="md"
                                    type="submit"
                                    className={`btn-2`}
                                    style={{ width: "150px", height: '38px', lineHeight: 0.7 }}
                                >
                                    Login
                                </Button>
                            </div>
                        </Link>
                    </div>
                </nav>

            </Navbar>
        )
    }
}
import React, { Component } from "react";
import { Button, Form, Navbar, Dropdown, Card, Row, Col, OverlayTrigger, Tooltip, Popover } from "react-bootstrap";
import { Link, NavLink, Redirect } from "react-router-dom";
import fedduplogo from "../images/fedduplogo.png";
import './Profile.css';
import safety from '../images/safety.svg';
import profileicon from '../images/profileicon.svg';
import feedsettings from '../images/feedsettings.svg';
import usersettings from '../images/usersettings.svg';
import deactivate from '../images/deactivate.png';
import { Tags } from "../UsersFeed/TrendingTags";
import { Moderators } from "../CompanyFeed/Moderators";
import axios from 'axios';
import { config } from '../Config/Config';
import { decrypt } from "../utils";


export default class ActivateAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: localStorage.getItem('value'),
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            otp5: "",
            otp6: "",
            activate: false,
            redirect: false
        }
    }
    handleChange(value1, event) {
        this.setState({ [value1]: event.target.value });
    }
    clickActivateAccount = () => {
        this.setState({ activate: true })
        document.getElementById('activate').style.display = 'none'
        this.sendOtp();
    }
    activateAccount = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "flag": 1
        }
        console.log("payload", payload)
        axios.post(config.userUrl + `user/activeAndDeActiveAccount `, payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    console.log("account activated successfully")
                    localStorage.clear();
                    window.location.href = '/'
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    showActivateNone() {
        document.getElementById('activate').style.display = 'none'
    }
    showActivate() {
        document.getElementById('activate').style.display = 'flex'
    }
    verifyOtp = (e) => {
        e.preventDefault();
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var self = this;
        var payload = {
            "email": this.state.email,
            "otp": this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4 + this.state.otp5 + this.state.otp6
        }
        axios.post(config.apiUrl + 'auth/verifyEmail', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1);
                if (response.data.status === true) {
                    console.log("Email verified successfully!!")
                    console.log("payload", payload)
                    self.activateAccount();
                    localStorage.clear();
                    self.setState({
                        redirect: true
                    })
                } else {
                    console.log(response.data.message)
                    document.getElementById('activateOtp').innerText = msg
                }
            })
            .catch(function (error) {
                console.log("payload", payload)
                console.log(error);
                document.getElementById('activateOtp').innerText = error
            });
    }
    inputfocus = (elmnt) => {
        if (elmnt.key === "Backspace" || elmnt.key === "Delete") {
            const next = elmnt.target.tabIndex - 2;
            console.log("ext", elmnt.target.tabIndex, next)
            if (next > -1) {
                elmnt.target.form.elements[next].focus()
            }
        }
        else {
            const next = elmnt.target.tabIndex;
            console.log("next", elmnt.target.tabIndex, next);
            if (next < 6) {
                elmnt.target.form.elements[next].focus()
            }
        }

    }
    sendOtp = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var payload = {
            "email": this.state.email,
        }
        var self = this;
        axios.post(config.apiUrl + 'auth/sendOtp', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("Otp sent successfully!!")
                    console.log("payload", payload)
                    document.getElementById('activateOtp').innerText = "Otp sent successfully!!"
                } else {
                    console.log(response.data.message)
                    document.getElementById('activateOtp').innerText = msg
                }
            })
            .catch(function (error) {
                console.log("payload", payload)
                console.log("err", error);
            });
    }
    render() {
        if (this.state.redirect) {
            console.log("redirect1", this.state.redirect);
            return <Redirect
                to={{
                    pathname: "/"
                }}
            />
        }
        return (
            <div>
                <Navbar className="nav-height" fixed='top'>
                    <Navbar.Brand > <img className="navbar-logo" src={fedduplogo} /> </Navbar.Brand >
                </Navbar>

                <div id="background" style={{ minHeight: "93vh" }}>
                    <div className="usersettings-l-r"><br /><br />
                        <Row className="row-width">
                            <Col md={3}></Col>
                            <Col md={6}>
                                <Card>
                                    <div className="card-user-padding">
                                        <h1 className="py-2 border-b text-gray-500 font-medium mb-3">Account Activate</h1>

                                        <Row style={{ width: "100%" }}>
                                            <Col md={7}>
                                                <div className="change-username">Activate Account</div>

                                                <div className="mt-1 username_">Click on activate account button to activate your account.</div>
                                            </Col>
                                            <Col md={5}>
                                                {this.state.activate ?
                                                    <Button variant="default" size="md" disabled="true" className="english-btn flex items-center gap-2" onClick={this.showActivate}><img src={deactivate} style={{ width: "20px" }} />&nbsp;&nbsp;&nbsp; Activate Account</Button>
                                                    : <Button variant="default" size="md" className="english-btn flex items-center gap-2" onClick={this.showActivate}><img src={deactivate} style={{ width: "20px" }} />&nbsp;&nbsp;&nbsp; Activate Account</Button>}
                                            </Col>
                                        </Row>
                                        <div id="activate" className="modal-box">
                                            <form className="modal-content-log">
                                                    <div className="md-container" style={{ height: 200, width: 300, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                                        <p className="mb-6 text-lg">Do you really want to Activate your account?</p>
                                                        <center><Button variant="default" onClick={this.clickActivateAccount} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                            <Button variant="default" onClick={this.showActivateNone} className="deletebutton">No</Button>
                                                            <div className="mt-4 desc">If you click on yes, please verify the otp which will be send to your gmail.</div></center>
                                                    </div>

                                            </form>
                                        </div>
                                        <hr className="my-3" />
                                        {this.state.activate ?
                                            <Row style={{ width: "100%" }}>
                                                <Col md={12}>
                                                    <h3 className="font_family">Verify</h3>
                                                    <div className="mt-3 font_family">Please enter the otp we sent to email.</div>
                                                    <Form onSubmit={this.verifyOtp} className="mt-5 font_family">
                                                        <div className="form-group verify">
                                                            <input
                                                                name="otp1"
                                                                type="text"
                                                                autoComplete="off"
                                                                className="form-control digit-width"
                                                                value={this.state.otp1}
                                                                onKeyPress={this.keyPressed}
                                                                onChange={e => this.handleChange("otp1", e)}
                                                                tabIndex="1" maxLength={1} onKeyUp={e => this.inputfocus(e)}

                                                            />
                                                            <input
                                                                name="otp2"
                                                                type="text"
                                                                autoComplete="off"
                                                                className="form-control digit-width"
                                                                value={this.state.otp2}
                                                                onChange={e => this.handleChange("otp2", e)}
                                                                tabIndex="2" maxLength={1} onKeyUp={e => this.inputfocus(e)}

                                                            />
                                                            <input
                                                                name="otp3"
                                                                type="text"
                                                                autoComplete="off"
                                                                className="form-control digit-width"
                                                                value={this.state.otp3}
                                                                onChange={e => this.handleChange("otp3", e)}
                                                                tabIndex="3" maxLength="1" onKeyUp={e => this.inputfocus(e)}

                                                            />
                                                            <input
                                                                name="otp4"
                                                                type="text"
                                                                autoComplete="off"
                                                                className="form-control digit-width"
                                                                value={this.state.otp4}
                                                                onChange={e => this.handleChange("otp4", e)}
                                                                tabIndex="4" maxLength={1} onKeyUp={e => this.inputfocus(e)}
                                                            />

                                                            <input
                                                                name="otp5"
                                                                type="text"
                                                                autoComplete="off"
                                                                className="form-control digit-width"
                                                                value={this.state.otp5}
                                                                onChange={e => this.handleChange("otp5", e)}
                                                                tabIndex="5" maxLength={1} onKeyUp={e => this.inputfocus(e)}
                                                            />
                                                            <input
                                                                name="otp6"
                                                                type="text"
                                                                autoComplete="off"
                                                                className="form-control digit-width"
                                                                value={this.state.otp6}
                                                                onChange={e => this.handleChange("otp6", e)}
                                                                tabIndex="6" maxLength={1} onKeyUp={e => this.inputfocus(e)}
                                                            />
                                                        </div>
                                                        <Button
                                                            variant="default" size="md" block
                                                            type="submit"
                                                            className="mt-5 login_button"
                                                        >
                                                            Verify
                                                        </Button>
                                                        <center id="activateOtp" className="mt-2 errormsg"></center>
                                                        <br />
                                                    </Form>
                                                </Col>
                                            </Row>
                                            : null}
                                    </div>
                                </Card>
                            </Col>
                            <Col md={3}></Col>
                        </Row >

                    </div > <br />
                </div >
            </div >
        );
    }
}


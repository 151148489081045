import React, { useEffect, useState } from 'react'
import NavBar1 from '../NavBar/NavBar1'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import { config } from '../Config/Config';
import ReactSelect2 from '../utils/ReSelect2';
import { Button, Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import selectBlack from "../images/select-black.svg";
import selectGray from "../images/select-gray.svg";
import checkBlack from "../images/check-black.svg";
import checkGray from "../images/check-gray.svg";
import ratingBlack from "../images/rating-scale-black.svg"
import ratingGray from "../images/rating-scale-gray.svg"
import textFieldGray from "../images/text-field-gray.svg"
import textFieldBlack from "../images/text-field-black.svg"
import custTextBlack from "../images/custom-text-black.svg"
import custTextGray from "../images/custom-text-gray.svg"
import pageBreakGray from "../images/page-break-gray.svg"
import pageBreakBlack from "../images/page-break-black.svg"
import deleteBin from "../images/delete-bin.svg"
import menu from "../images/hamburgermenu.svg"
import arrowRightGray from "../images/arrow-right-gray.svg";
import arrowLeftBlack from "../images/arrow-left-black.svg";
import editIcon from "../images/edit-pen.svg";
import addFileIcon from "../images/add-file.svg";
import copyIcon from "../images/copy-icon-gray.svg"
import rating1 from '../images/rating1.png';
import rating2 from '../images/rating2.png';
import rating3 from '../images/rating3.png';
import rating4 from '../images/rating4.png';
import rating5 from '../images/rating5.png';
import arrowRightBlack from "../images/arrow-right-black.svg";
import arrowLeftGray from "../images/arrow-left-gray.svg";
import arrowRightGray2 from "../images/arrow-right-gray2.svg";
import uploaded from "../images/uploaded-tick.svg";
import { decrypt, ratingDescAgreeDisagree, ratingDescHappyUnhappy, ratingDescLikelyUnlikely, ratingDescOftenRarely, ratingDescSatisfiedUndsatisfied, scaleTypeOptions, wordLimitOptions } from '../utils';
import PreviewAndPublish from './PreviewAndPublish';


function NewFeedbackFrom() {
    const location = useLocation();
    const history = useHistory();
    const { name, id, productid, edit } = location.state;
    const [section, setSection] = useState('');
    const [questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState("");
    const [options, setOptions] = useState(['', '', '']);
    const [required, setRequired] = useState(false);
    const [scaleType, setScaleType] = useState('')
    const [scoringDirection, setScoringDirection] = useState('')
    const [ratingDesc, setRatingDesc] = useState([]);
    const [placeholder, setPlaceholder] = useState('');
    const [wordLimit, setWordLimit] = useState('');
    const [editButtonClicked, setEditButtonClicked] = useState(false)
    const [currentQuesNo, setCurrentQuesNo] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [currentQuest, setCurrentQuest] = useState(null);
    const [preview, setPreview] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [formStatus, setFormStatus] = useState(false)

    useEffect(() => {
        getFeedbackForm()
        setCurrentPage(1);
    }, [id])
    const getFeedbackForm = () => {
        if (id === '') return;
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "formId": id,
            "productId": productid
        }
        axios.post(config.userUrl + "admin/getFeedbackForm", payload, { headers: headers })
            .then((response) => {
                console.log(response.data);
                let currentQuestions = response.data.feedbackForms.questions;
                console.log("current questions", currentQuestions);
                setQuestions(currentQuestions?.slice(0, currentQuestion.length - 1));
                console.log(response.data.feedbackForms.questions[0]);
                setCurrentQuest(response.data.feedbackForms.questions[0][1])
            })
            .catch((err) => {
                console.log(err)
            });
    }

    const handleOptionChange = (index, event) => {
        const newOptions = [...options];
        newOptions[index] = event.target.value;
        setOptions(newOptions);
    };
    const addOption = (otherFlag) => {
        let newOptions = []
        if (otherFlag) {
            newOptions = [...options, 'Other']
        } else {
            newOptions = [...options, ''];
        }
        setOptions(newOptions)
    }
    const removeOption = (index) => {
        let newOptions = options.filter((option, i) => i !== index)
        setOptions(newOptions)
    }
    const removeQuestion = (index) => {
        console.log("Current Index", index, questions);
        if (editButtonClicked) {
            setSection('');
            clearQuestion();
            setEditButtonClicked(false)
            return;
        }
        let newQuestions = questions.map((ques, i) => {
            console.log(i, ques);
            if (i < index) return ques;
            else if (i > index) {
                let newques = ques[i + 1];
                console.log({ [i]: newques });
                return { [i]: newques }
            }
        });
        console.log(newQuestions);
        let newQuestionsArray = newQuestions.filter(ques => ques !== undefined)
        console.log(newQuestionsArray);
        setQuestions(newQuestionsArray);
        setCurrentQuestion("");
        setOptions(['', '', '']);
        setRequired(false);
        setScaleType('')
        setScoringDirection('')
        setRatingDesc([]);
        setSection("");
        setWordLimit('')
        newQuestionsArray.hasOwnProperty(currentPage - 1) ? setCurrentQuest(newQuestionsArray[currentPage - 1][currentPage]) : setCurrentQuest('');
    }
    const clearQuestion = () => {
        setCurrentQuestion("");
        setOptions(['', '', '']);
        setRequired(false);
        setScaleType('')
        setScoringDirection('')
        setRatingDesc([]);
        setWordLimit('')
    }
    const addQUestion = (type) => {
        console.log(currentQuestion, options, required, questions?.length);
        let key = currentPage;
        let value;
        if (type === "rating") {
            value = {
                "question": currentQuestion,
                "scaleType": scaleType,
                "scoringDirection": scoringDirection,
                "ratingDesc": ratingDesc,
                "required": required,
                "type": type
            }
        }
        else if (type === "textfield") {
            value = {
                "question": currentQuestion,
                "placeholder": placeholder,
                "wordLimit": wordLimit,
                "required": required,
                "type": type
            }
        }
        else {
            value = {
                "question": currentQuestion,
                "options": options,
                "required": required,
                "type": type
            }
        }
        if (questions.hasOwnProperty(currentPage - 1)) {
            let newQuestions = [...questions]
            newQuestions[currentPage - 1][currentPage] = value;
            setQuestions(newQuestions);
        } else {
            console.log("Adding Question", type, value);
            let newQuestions = [...questions]
            newQuestions[currentPage - 1] = { [key]: value }
            console.log("New Questions", newQuestions);
            if (currentPage < 3) {
                setCurrentPage(currentPage + 1)
                if (questions.hasOwnProperty(currentPage))
                    setCurrentQuest(questions[currentPage][currentPage + 1])
                else
                    setCurrentQuest(null)
            }
            else {
                setCurrentPage(1);
                if (questions.hasOwnProperty(0))
                    setCurrentQuest(questions[0][1]);
                else
                    setCurrentQuest(null)
            }
            setQuestions(newQuestions);
        }
        setSection("");
        setCurrentQuestion("");
        setOptions(['', '', '']);
        setRequired(false);
        setScaleType("");
        setScoringDirection("");
        setRatingDesc([]);
        setWordLimit('')
        setEditButtonClicked(false);
        setCurrentQuesNo(0)
    }
    const updateQUestion = (type) => {
        console.log(currentQuestion, options, required, questions?.length);
        let key = questions.length + 1
        let value;
        if (type === "rating") {
            value = {
                "question": currentQuestion,
                "scaleType": scaleType,
                "scoringDirection": scoringDirection,
                "ratingDesc": ratingDesc,
                "required": required,
                "type": type
            }
        }
        else if (type === "textfield") {
            value = {
                "question": currentQuestion,
                "placeholder": placeholder,
                "wordLimit": wordLimit,
                "required": required,
                "type": type
            }
        }
        else {
            value = {
                "question": currentQuestion,
                "options": options,
                "required": required,
                "type": type
            }
        }
        questions[currentPage - 1] = { [currentPage]: {} };
        questions[currentPage - 1][currentPage] = value;
        console.log("Update questions", questions);
        setSection("");
        setCurrentQuest(questions[currentPage - 1][currentPage])
        setOptions(['', '', '']);
        setRequired(false);
        setScaleType("");
        setScoringDirection("");
        setRatingDesc([]);
        setWordLimit('')
        setEditButtonClicked(false);
        setCurrentQuesNo(0)

    }
    const handleMCQEditClick = (i, question) => {
        console.log("Edit click", question);
        setCurrentQuestion(question["question"])
        setOptions(question["options"])
        setRequired(question["required"])
        setEditButtonClicked(true);
        setCurrentQuesNo(i)
    }
    const handleRatingEditClick = (i, question) => {
        console.log("Rating Edit click", question);
        setCurrentQuestion(question["question"])
        setRequired(question["required"])
        setScaleType(question["scaleType"])
        setScoringDirection(question["scoringDirection"])
        setRatingDesc(question["ratingDesc"])
        setEditButtonClicked(true);
        setCurrentQuesNo(i)
    }
    const handleTextfieldEditClick = (i, question) => {
        console.log("Textfield Edit click", question);
        setCurrentQuestion(question["question"])
        setRequired(question["required"])
        setWordLimit(question["wordLimit"])
        setEditButtonClicked(true);
        setCurrentQuesNo(i)
    }
    const createFeedbackForm = (formStatus) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "formName": name,
            "questions": JSON.stringify(questions),
            "productId": productid,
            "formId": id
        }
        if (formStatus) payload["status"] = "Saved as Draft"
        axios.post(config.userUrl + "admin/createFeedbackForms", payload, { headers: headers })
            .then(function (response) {
                console.log("getRatigetAllUsersRegionBase", response);
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                setShowSuccess(true);
                setTimeout(() => {
                    setShowSuccess(false);
                    if (formStatus) {
                        setPreview(true);
                    } else {
                        history.goBack();
                    }
                }, 3000)

            })
            .catch(function (error) {
                console.log("Error", error);
            });
    }
    const updateFeedbackForm = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "formName": name,
            "questions": JSON.stringify(questions),
            "formId": id
        }
        if (formStatus) payload["status"] = "Saved as Draft"
        axios.post(config.userUrl + "admin/updateFeedbackForms", payload, { headers: headers })
            .then(function (response) {
                console.log("getRatigetAllUsersRegionBase", response);
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                setShowSuccess(true);
                setTimeout(() => {
                    setShowSuccess(false);
                    history.push(`/admin/feddbackforms/${productid}`);
                }, 3000)

            })
            .catch(function (error) {
                console.log("Error", error);
            });
    }
    const handleScaleChange = (option) => {
        let cureentratingDesc = [];
        if (option.value === "Agree-Disagree") {
            cureentratingDesc = ratingDescAgreeDisagree();
        }
        else if (option.value === "Happy-Unhappy") {
            cureentratingDesc = ratingDescHappyUnhappy();
        }
        else if (option.value === "Likely-Unlikely") {
            cureentratingDesc = ratingDescLikelyUnlikely();
        }
        else if (option.value === "Often-Rarely") {
            cureentratingDesc = ratingDescOftenRarely();
        }
        else if (option.value === "Satisfied-Unsatisfied") {
            cureentratingDesc = ratingDescSatisfiedUndsatisfied();
        }
        setScaleType(option.value)
        setRatingDesc(cureentratingDesc)
    }
    const handleWordLimitChange = (option) => {
        setWordLimit(option.value)
    }
    const handleBackClick = () => {
        if (currentPage === 1 || section) return;
        setCurrentPage(currentPage - 1)
        if (questions.hasOwnProperty(currentPage - 2) && questions[currentPage - 2]) {
            setCurrentQuest(questions[currentPage - 2][currentPage - 1])
        }
        else {
            setCurrentQuest(null)
        }
        setSection('');
        clearQuestion();
    }
    const handleForwardClick = () => {
        if (questions.length == currentPage) {
            if (currentPage === 3 || section) return;
            setCurrentPage(currentPage + 1);
            if (questions.hasOwnProperty(currentPage)) {
                setCurrentQuest(questions[currentPage][currentPage + 1])
            }
            else {
                setCurrentQuest(null)
            }
            setSection('');
            clearQuestion();
        }
    }
    const togglePreview = () => {
        setPreview(!preview)
    }
    const getDefaultScaleOption = () => {
        if (scaleType) {
            let defaultValue = scaleTypeOptions.filter(option => option.value === scaleType)
            return defaultValue;
        }
        return []
    }
    const getDefaultOrderOption = () => {
        let options = [
            { value: "Ascending", label: "Ascending" },
            { value: "Descending", label: "Descending" }
        ];
        if (scoringDirection) {
            let defaultValue = options.filter(option => option.value === scoringDirection)
            return defaultValue;
        }
        return []
    }
    const handlePreviewClick = () => {
        let k = 0;
        let temp = [];
        let newQuestionsArray = questions.filter((question, index) => {
            if (question !== undefined) {
                temp[k] = {
                    [k + 1]: question[index + 1]
                };
                k++;
            }

        })
        let value = {
            "type": 'generic'
        }
        let newQuestions = [...temp, { [temp.length + 1]: value }]
        setQuestions(newQuestions)
        setFormStatus(false);
        setPreview(true);
    }

    return (
        <div className='bg-gray-100 h-screen pb-12'>
            <NavBar1 />
            {preview ? <PreviewAndPublish questions={questions} setQuestions={setQuestions} togglePreview={togglePreview} createFeedbackForm={createFeedbackForm} updateFeedbackform={updateFeedbackForm} edit={edit} formStatus={formStatus} productId={productid} />
                :
                <div className='py-4 px-32 my-20'>
                    <div className='flex justify-center items-center gap-4 mb-4'>
                        <img src={currentPage > 1 ? arrowLeftBlack : arrowLeftGray} alt='' className='w-12 cursor-pointer' onClick={handleBackClick} />
                        <p className=' text-black text-lg '>Page {currentPage}/3</p>
                        <img src={(currentPage !== 3 && questions.length == currentPage) ? arrowRightBlack : arrowRightGray2} alt='' className='w-16 cursor-pointer' onClick={handleForwardClick} />
                    </div>
                    <Row className='flex justify-between px-4 rounded-xl'>
                        {section === '' ?
                            !questions.hasOwnProperty(currentPage - 1) || questions[currentPage - 1] === undefined ?
                                <Col md={9} >
                                    <div className='h-96 dashed-border flex justify-center items-center gap-4 rounded-xl'>
                                        <p className='text-gray-400 text-lg'>Select Question from the list on the right</p>
                                        <img src={arrowRightGray} alt='' className='w-8' />
                                    </div>
                                </Col> :
                                <Col md={9} >
                                    {currentQuest && currentQuest.type === "mcq" ?
                                        <div key={currentPage - 1 + "mcq"} className='bg-white h-max px-4 py-8 rounded-xl mb-4'>
                                            <p className='text-base text-black font-semibold'>1. {currentQuest["question"]}</p>
                                            <form className='flex flex-col gap-3 mt-2'>
                                                {currentQuest.options.map((option, index) => {
                                                    return (
                                                        <label className='flex gap-3' key={index + "mcqoptions"}>
                                                            <input type="checkbox" name={currentQuest["question"]} value={option} disabled />
                                                            {option}
                                                        </label>
                                                    )
                                                })}
                                            </form>
                                            <div className='flex justify-end gap-8'>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={
                                                        <Tooltip id={`tooltip-top`}>
                                                            <div className='tool-text'>
                                                                Edit<br />
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                >
                                                    {/* <img src={editIcon} alt='' className='w-5 cursor-pointer hover:bg-gray-700' onClick={() => { handleMCQEditClick(currentPage, currentQuest); setSection("multiplechoice") }} /> */}
                                                    <svg className='edit w-5 cursor-pointer' onClick={() => { handleMCQEditClick(currentPage, currentQuest); setSection("multiplechoice") }} width="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M3.04719 16.7549L1.67496 22.6771C1.62763 22.8936 1.62925 23.1179 1.67971 23.3337C1.73018 23.5495 1.82821 23.7513 1.96664 23.9243C2.10508 24.0973 2.28042 24.2373 2.47986 24.3339C2.67931 24.4305 2.89781 24.4813 3.11941 24.4826C3.22267 24.4931 3.32671 24.4931 3.42996 24.4826L9.3883 23.1104L20.8283 11.7137L14.4439 5.34375L3.04719 16.7549Z" fill="#DADADA" />
                                                        <path d="M24.4264 6.00881L20.1653 1.7477C19.8851 1.46897 19.506 1.3125 19.1108 1.3125C18.7156 1.3125 18.3365 1.46897 18.0564 1.7477L15.6875 4.11659L22.0647 10.4938L24.4336 8.12492C24.5722 7.98559 24.682 7.82029 24.7567 7.63848C24.8314 7.45667 24.8695 7.26192 24.8688 7.06537C24.8681 6.86882 24.8287 6.67434 24.7528 6.49304C24.6769 6.31174 24.566 6.1472 24.4264 6.00881Z" fill="#DADADA" />
                                                    </svg>

                                                </OverlayTrigger>
                                                <img src={copyIcon} alt='' className='w-5 cursor-pointer' />
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={
                                                        <Tooltip id={`tooltip-top`}>
                                                            <div className='tool-text'>
                                                                Delete<br />
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                >
                                                    {/* <img src={deleteBin} alt='' className='w-5 cursor-pointer hover:bg-red-700' onClick={() => removeQuestion(currentPage - 1)} /> */}
                                                    <svg className='delete w-5 cursor-pointer' onClick={() => removeQuestion(currentPage - 1)} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.5 33.25C10.5 35.175 12.075 36.75 14 36.75H28C29.925 36.75 31.5 35.175 31.5 33.25V12.25H10.5V33.25ZM33.25 7H27.125L25.375 5.25H16.625L14.875 7H8.75V10.5H33.25V7Z" fill="#DADADA" />
                                                    </svg>

                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                        :
                                        currentQuest && currentQuest.type === "singlechoice"
                                            ?
                                            <div key={currentPage - 1 + "singlechoice"} className='bg-white h-max px-4 py-8 rounded-xl mb-4'>
                                                <p className='text-base text-black font-semibold'>{currentPage}. {currentQuest["question"]}</p>

                                                <form className='flex flex-col gap-3 mt-2'>
                                                    {currentQuest.options.map((option, index) => {
                                                        return (
                                                            <label className='flex gap-3' key={index + "singleoptions"}>
                                                                <input type="radio" name={currentQuest["question"]} value={option} disabled />
                                                                {option}
                                                            </label>
                                                        )
                                                    })}
                                                </form>
                                                <div className='flex justify-end gap-8'>
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={
                                                            <Tooltip id={`tooltip-top`}>
                                                                <div className='tool-text'>
                                                                    Edit<br />
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        {/* <img src={editIcon} alt='' className='w-5 cursor-pointer hover:bg-gray-700' onClick={() => { handleMCQEditClick(currentPage, currentQuest); setSection("singlechoice") }} /> */}
                                                        <svg className='edit w-5 cursor-pointer' onClick={() => { handleMCQEditClick(currentPage, currentQuest); setSection("singlechoice") }} width="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M3.04719 16.7549L1.67496 22.6771C1.62763 22.8936 1.62925 23.1179 1.67971 23.3337C1.73018 23.5495 1.82821 23.7513 1.96664 23.9243C2.10508 24.0973 2.28042 24.2373 2.47986 24.3339C2.67931 24.4305 2.89781 24.4813 3.11941 24.4826C3.22267 24.4931 3.32671 24.4931 3.42996 24.4826L9.3883 23.1104L20.8283 11.7137L14.4439 5.34375L3.04719 16.7549Z" fill="#DADADA" />
                                                            <path d="M24.4264 6.00881L20.1653 1.7477C19.8851 1.46897 19.506 1.3125 19.1108 1.3125C18.7156 1.3125 18.3365 1.46897 18.0564 1.7477L15.6875 4.11659L22.0647 10.4938L24.4336 8.12492C24.5722 7.98559 24.682 7.82029 24.7567 7.63848C24.8314 7.45667 24.8695 7.26192 24.8688 7.06537C24.8681 6.86882 24.8287 6.67434 24.7528 6.49304C24.6769 6.31174 24.566 6.1472 24.4264 6.00881Z" fill="#DADADA" />
                                                        </svg>
                                                    </OverlayTrigger>
                                                    <img src={copyIcon} alt='' className='w-5 cursor-pointer' />
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={
                                                            <Tooltip id={`tooltip-top`}>
                                                                <div className='tool-text'>
                                                                    Delete<br />
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <svg className='delete w-5 cursor-pointer' onClick={() => removeQuestion(currentPage - 1)} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.5 33.25C10.5 35.175 12.075 36.75 14 36.75H28C29.925 36.75 31.5 35.175 31.5 33.25V12.25H10.5V33.25ZM33.25 7H27.125L25.375 5.25H16.625L14.875 7H8.75V10.5H33.25V7Z" fill="#DADADA" />
                                                        </svg>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                            :
                                            currentQuest && currentQuest.type === "rating" ?
                                                <div key={currentPage - 1 + "rating"} className='bg-white h-max px-4 py-8 rounded-xl mb-4'>
                                                    <p className='text-base text-black font-semibold'>{currentPage}. {currentQuest["question"]}</p>
                                                    {currentQuest["scaleType"] === "Yes-No" ?
                                                        <div className='flex w-full justify-center gap-8 mt-4'>
                                                            <div className='flex items-center gap-2'>
                                                                <span className='w-4 h-4 rounded-full border'></span>
                                                                <p>Yes</p>
                                                            </div>
                                                            <div className='flex items-center gap-2'>
                                                                <span className='w-4 h-4 rounded-full border'></span>
                                                                <p>Uncertain</p>
                                                            </div>
                                                            <div className='flex items-center gap-2'>
                                                                <span className='w-4 h-4 rounded-full border'></span>
                                                                <p>No</p>
                                                            </div>

                                                        </div> :
                                                        <div className='flex my-4'>
                                                            <div className='flex w-32 gap-2 items-center'>
                                                                <img src={currentQuest["scoringDirection"] === "Ascending" ? rating1 : rating5} alt='' className='w-10' />
                                                                <p>{currentQuest["scoringDirection"] === "Ascending" ? currentQuest["ratingDesc"][0] : currentQuest["ratingDesc"][4]}</p>
                                                            </div>
                                                            <div className='flex w-32 gap-2 items-center'>
                                                                <img src={currentQuest["scoringDirection"] === "Ascending" ? rating2 : rating4} alt='' className='w-10' />
                                                                <p>{currentQuest["scoringDirection"] === "Ascending" ? currentQuest["ratingDesc"][1] : currentQuest["ratingDesc"][3]}</p>
                                                            </div>
                                                            <div className='flex w-32 gap-2 items-center'>
                                                                <img src={rating3} alt='' className='w-10' />
                                                                <p>{currentQuest["ratingDesc"][2]}</p>
                                                            </div>
                                                            <div className='flex w-32 gap-2 items-center'>
                                                                <img src={currentQuest["scoringDirection"] === "Ascending" ? rating4 : rating2} alt='' className='w-10' />
                                                                <p>{currentQuest["scoringDirection"] === "Ascending" ? currentQuest["ratingDesc"][3] : currentQuest["ratingDesc"][1]}</p>
                                                            </div>
                                                            <div className='flex w-32 gap-2 items-center'>
                                                                <img src={currentQuest["scoringDirection"] === "Ascending" ? rating5 : rating1} alt='' className='w-10' />
                                                                <p>{currentQuest["scoringDirection"] === "Ascending" ? currentQuest["ratingDesc"][4] : currentQuest["ratingDesc"][0]}</p>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className='flex justify-end gap-8'>
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            overlay={
                                                                <Tooltip id={`tooltip-top`}>
                                                                    <div className='tool-text'>
                                                                        Edit<br />
                                                                    </div>
                                                                </Tooltip>
                                                            }
                                                        >
                                                            {/* <img src={editIcon} alt='' className='w-5 cursor-pointer hover:bg-gray-700' onClick={() => { handleRatingEditClick(currentPage, currentQuest); setSection("rating") }} /> */}
                                                            <svg className='edit w-5 cursor-pointer' onClick={() => { handleRatingEditClick(currentPage, currentQuest); setSection("rating") }} width="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.04719 16.7549L1.67496 22.6771C1.62763 22.8936 1.62925 23.1179 1.67971 23.3337C1.73018 23.5495 1.82821 23.7513 1.96664 23.9243C2.10508 24.0973 2.28042 24.2373 2.47986 24.3339C2.67931 24.4305 2.89781 24.4813 3.11941 24.4826C3.22267 24.4931 3.32671 24.4931 3.42996 24.4826L9.3883 23.1104L20.8283 11.7137L14.4439 5.34375L3.04719 16.7549Z" fill="#DADADA" />
                                                                <path d="M24.4264 6.00881L20.1653 1.7477C19.8851 1.46897 19.506 1.3125 19.1108 1.3125C18.7156 1.3125 18.3365 1.46897 18.0564 1.7477L15.6875 4.11659L22.0647 10.4938L24.4336 8.12492C24.5722 7.98559 24.682 7.82029 24.7567 7.63848C24.8314 7.45667 24.8695 7.26192 24.8688 7.06537C24.8681 6.86882 24.8287 6.67434 24.7528 6.49304C24.6769 6.31174 24.566 6.1472 24.4264 6.00881Z" fill="#DADADA" />
                                                            </svg>
                                                        </OverlayTrigger>
                                                        <img src={copyIcon} alt='' className='w-5 cursor-pointer' />
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            overlay={
                                                                <Tooltip id={`tooltip-top`}>
                                                                    <div className='tool-text'>
                                                                        Delete<br />
                                                                    </div>
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <svg className='delete w-5 cursor-pointer' onClick={() => removeQuestion(currentPage - 1)} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10.5 33.25C10.5 35.175 12.075 36.75 14 36.75H28C29.925 36.75 31.5 35.175 31.5 33.25V12.25H10.5V33.25ZM33.25 7H27.125L25.375 5.25H16.625L14.875 7H8.75V10.5H33.25V7Z" fill="#DADADA" />
                                                            </svg>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                :
                                                currentQuest && currentQuest.type === "textfield" ?
                                                    <div key={currentPage - 1 + "textfield"} className='bg-white h-max px-4 py-8 rounded-xl mb-4'>
                                                        <p className='text-base text-black font-semibold'>{currentPage}. {currentQuest["question"]}</p>
                                                        <div className='border rounded-lg w-full flex flex-col gap-2 my-2'>
                                                            <textarea
                                                                className="p-2 min-h-[7rem] resize-none w-full focus:outline-none bg-white"
                                                                placeholder={currentQuest["placeholder"]}
                                                                disabled
                                                            />
                                                            <div className='flex justify-end p-2'>
                                                                <p className='text-gray-400'>{currentQuest["wordLimit"]}</p>
                                                            </div>
                                                        </div>
                                                        <div className='flex justify-end gap-8'>
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                overlay={
                                                                    <Tooltip id={`tooltip-top`}>
                                                                        <div className='tool-text'>
                                                                            Edit<br />
                                                                        </div>
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                {/* <img src={editIcon} alt='' className='w-5 cursor-pointer hover:bg-gray-700' onClick={() => { handleTextfieldEditClick(currentPage, currentQuest); setSection("textfield") }} /> */}
                                                                <svg className='edit w-5 cursor-pointer' onClick={() => { handleTextfieldEditClick(currentPage, currentQuest); setSection("textfield") }} width="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M3.04719 16.7549L1.67496 22.6771C1.62763 22.8936 1.62925 23.1179 1.67971 23.3337C1.73018 23.5495 1.82821 23.7513 1.96664 23.9243C2.10508 24.0973 2.28042 24.2373 2.47986 24.3339C2.67931 24.4305 2.89781 24.4813 3.11941 24.4826C3.22267 24.4931 3.32671 24.4931 3.42996 24.4826L9.3883 23.1104L20.8283 11.7137L14.4439 5.34375L3.04719 16.7549Z" fill="#DADADA" />
                                                                    <path d="M24.4264 6.00881L20.1653 1.7477C19.8851 1.46897 19.506 1.3125 19.1108 1.3125C18.7156 1.3125 18.3365 1.46897 18.0564 1.7477L15.6875 4.11659L22.0647 10.4938L24.4336 8.12492C24.5722 7.98559 24.682 7.82029 24.7567 7.63848C24.8314 7.45667 24.8695 7.26192 24.8688 7.06537C24.8681 6.86882 24.8287 6.67434 24.7528 6.49304C24.6769 6.31174 24.566 6.1472 24.4264 6.00881Z" fill="#DADADA" />
                                                                </svg>
                                                            </OverlayTrigger>
                                                            <img src={copyIcon} alt='' className='w-5 cursor-pointer' />
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                overlay={
                                                                    <Tooltip id={`tooltip-top`}>
                                                                        <div className='tool-text'>
                                                                            Delete<br />
                                                                        </div>
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <svg className='delete w-5 cursor-pointer' onClick={() => removeQuestion(currentPage - 1)} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M10.5 33.25C10.5 35.175 12.075 36.75 14 36.75H28C29.925 36.75 31.5 35.175 31.5 33.25V12.25H10.5V33.25ZM33.25 7H27.125L25.375 5.25H16.625L14.875 7H8.75V10.5H33.25V7Z" fill="#DADADA" />
                                                                </svg>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div>
                                                    : currentQuest && currentQuest.type === "generic" ?
                                                        <div key={currentPage - 1 + "generic"} className='bg-white h-max px-4 py-8 rounded-xl mb-4'>
                                                            <p className="text-2xl">Generic Question</p>
                                                            <p className='my-3 text-black text-lg '>Rate your overall Experience for this version</p>
                                                            <div className='flex w-full mt-2 my-4'>
                                                                <div className='flex w-48 gap-2 items-center'>
                                                                    <img src={rating5} alt='' className='w-10' />
                                                                    <p>Love it!</p>
                                                                </div>
                                                                <div className='flex w-48 gap-2 items-center'>
                                                                    <img src={rating4} alt='' className='w-10' />
                                                                    <p>Like it!</p>
                                                                </div>
                                                                <div className='flex w-48 gap-2 items-center'>
                                                                    <img src={rating3} alt='' className='w-10' />
                                                                    <p>It's OK!</p>
                                                                </div>
                                                                <div className='flex w-48 gap-2 items-center'>
                                                                    <img src={rating2} alt='' className='w-10' />
                                                                    <p>Hate it!</p>
                                                                </div>
                                                                <div className='flex w-48 gap-2 items-center'>
                                                                    <img src={rating1} alt='' className='w-10' />
                                                                    <p>Fedup!</p>
                                                                </div>
                                                            </div>
                                                            <textarea
                                                                className="border border-gray-400 rounded p-2 min-h-[12rem] mb-2 resize-none w-full border-gray focus:outline-none"
                                                                placeholder="Additional Thoughts"
                                                                disabled
                                                            />
                                                            <div className='flex justify-end gap-8'>
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-top`}>
                                                                            <div className='tool-text'>
                                                                                Edit<br />
                                                                            </div>
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    {/* <img src={editIcon} alt='' className='w-5 cursor-pointer hover:bg-gray-700' onClick={() => { setSection("generic") }} /> */}
                                                                    <svg className='edit w-5 cursor-pointer' onClick={() => { setSection("generic") }} width="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M3.04719 16.7549L1.67496 22.6771C1.62763 22.8936 1.62925 23.1179 1.67971 23.3337C1.73018 23.5495 1.82821 23.7513 1.96664 23.9243C2.10508 24.0973 2.28042 24.2373 2.47986 24.3339C2.67931 24.4305 2.89781 24.4813 3.11941 24.4826C3.22267 24.4931 3.32671 24.4931 3.42996 24.4826L9.3883 23.1104L20.8283 11.7137L14.4439 5.34375L3.04719 16.7549Z" fill="#DADADA" />
                                                                        <path d="M24.4264 6.00881L20.1653 1.7477C19.8851 1.46897 19.506 1.3125 19.1108 1.3125C18.7156 1.3125 18.3365 1.46897 18.0564 1.7477L15.6875 4.11659L22.0647 10.4938L24.4336 8.12492C24.5722 7.98559 24.682 7.82029 24.7567 7.63848C24.8314 7.45667 24.8695 7.26192 24.8688 7.06537C24.8681 6.86882 24.8287 6.67434 24.7528 6.49304C24.6769 6.31174 24.566 6.1472 24.4264 6.00881Z" fill="#DADADA" />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                                <img src={copyIcon} alt='' className='w-5 cursor-pointer' />
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-top`}>
                                                                            <div className='tool-text'>
                                                                                Delete<br />
                                                                            </div>
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <svg className='delete w-5 cursor-pointer' onClick={() => removeQuestion(currentPage - 1)} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M10.5 33.25C10.5 35.175 12.075 36.75 14 36.75H28C29.925 36.75 31.5 35.175 31.5 33.25V12.25H10.5V33.25ZM33.25 7H27.125L25.375 5.25H16.625L14.875 7H8.75V10.5H33.25V7Z" fill="#DADADA" />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </div> :

                                                        <></>}
                                </Col>

                            :
                            <Col md={9} className='bg-white h-max px-4 py-8 rounded-xl'>
                                <div className={`${section === "multiplechoice" ? "" : "hidden"}`}>
                                    <div className='flex justify-between items-center'>
                                        <p className="text-2xl">Multiple Choice</p>
                                        <img src={deleteBin} alt='' className='w-10 cursor-pointer' onClick={() => removeQuestion(currentPage - 1)} />
                                    </div>
                                    <p className='my-3 text-gray-400 text-lg '>Question</p>
                                    <textarea
                                        className="border border-gray-400 rounded p-2 min-h-[7rem] resize-none w-full border-gray focus:outline-none"
                                        placeholder="Enter question here"
                                        value={currentQuestion}
                                        onChange={(e) => setCurrentQuestion(e.target.value)}
                                    />
                                    <p className='my-1 text-gray-400 text-lg '>Choices</p>
                                    {options.map((option, index) => {
                                        return (
                                            <div className='flex justify-between items-center gap-4 mb-3' key={index + "multiple"}>
                                                <div className="flex justify-between items-center w-full border border-gray rounded-lg p-2">
                                                    <input
                                                        className="rounded w-8/12 focus:outline-none"
                                                        type="text"
                                                        placeholder={`Option ${index + 1}`}
                                                        value={option}
                                                        onChange={(event) => handleOptionChange(index, event)}
                                                    />
                                                    <img src={deleteBin} alt='' className='w-7 cursor-pointer' onClick={() => removeOption(index)} />
                                                </div>
                                                <img src={menu} alt='' className='w-10 cursor-pointer' />
                                            </div>
                                        )
                                    })}
                                    {options.length < 4 ?
                                        <div className='flex gap-8 text-gray-400 text-sm'>
                                            <p className='cursor-pointer' onClick={() => addOption(false)}>+ Add Choice</p>
                                            <p className='cursor-pointer' onClick={() => addOption(true)}>+ Add 'Other' Choice</p>
                                        </div> : <></>}
                                    <p className='my-4 text-gray-400 text-md '>Options</p>
                                    <div className='flex gap-32 text-md'>
                                        <p>Required</p>
                                        <label className="flex items-center">
                                            <input type="checkbox" className="form-singlechoice rounded text-red-400 checkbox-color" onClick={() => setRequired(!required)} value={required} />
                                            <span className="ml-2">Respondents must answer this question.</span>
                                        </label>
                                    </div>
                                    <div className='flex justify-end items-center gap-4'>
                                        <Button
                                            variant="default" size="lg"
                                            type="submit"
                                            style={{ width: "100px", height: "40px", lineHeight: "10px", fontSize: "18px" }}
                                            className='rounded-lg text-orange-400 '
                                            onClick={() => removeQuestion(currentPage - 1)}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="default" size="lg"
                                            type="submit"
                                            style={{ width: "100px", height: "40px", lineHeight: "10px", fontSize: "18px" }}
                                            className='bg-red-400 rounded-lg text-white'
                                            onClick={() => {
                                                if (!questions.hasOwnProperty(currentPage - 1)) addQUestion("mcq")
                                                else updateQUestion("mcq")
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>

                                <div className={`${section === "singlechoice" ? "" : "hidden"}`}>
                                    <div className='flex justify-between items-center'>
                                        <p className="text-2xl">Single Choice</p>
                                        <img src={deleteBin} alt='' className='w-10 cursor-pointer' onClick={() => removeQuestion(currentPage - 1)} />
                                    </div>
                                    <p className='my-3 text-gray-400 text-lg '>Question</p>
                                    <textarea
                                        className="border border-gray-400 rounded p-2 min-h-[7rem] resize-none w-full border-gray focus:outline-none"
                                        placeholder="Enter question here"
                                        value={currentQuestion}
                                        onChange={(e) => setCurrentQuestion(e.target.value)}
                                    />
                                    <p className='my-1 text-gray-400 text-lg '>Choices</p>
                                    {options.map((option, index) => {
                                        return (
                                            <div className='flex justify-between items-center gap-4 mb-3' key={index + "single"}>
                                                <div className="flex justify-between items-center w-full border border-gray rounded-lg p-2">
                                                    <input
                                                        className="rounded w-8/12 focus:outline-none"
                                                        type="text"
                                                        placeholder={`Option ${index + 1}`}
                                                        value={option}
                                                        onChange={(event) => handleOptionChange(index, event)}
                                                    />
                                                    <img src={deleteBin} alt='' className='w-7 cursor-pointer' onClick={() => removeOption(index)} />
                                                </div>
                                                <img src={menu} alt='' className='w-10 cursor-pointer' />
                                            </div>
                                        )
                                    })}
                                    {options.length < 4 ?
                                        <div className='flex gap-8 text-gray-400 text-sm'>
                                            <p className='cursor-pointer' onClick={() => addOption(false)}>+ Add Choice</p>
                                            <p className='cursor-pointer' onClick={() => addOption(true)}>+ Add 'Other' Choice</p>
                                        </div> : <></>}
                                    <p className='my-4 text-gray-400 text-md '>Options</p>
                                    <div className='flex gap-32 text-md'>
                                        <p>Required</p>
                                        <label className="flex items-center">
                                            <input type="checkbox" className="form-singlechoice rounded text-red-400" onClick={() => setRequired(!required)} value={required} />
                                            <span className="ml-2">Respondents must answer this question.</span>
                                        </label>
                                    </div>
                                    <div className='flex justify-end items-center gap-4'>
                                        <Button
                                            variant="default" size="lg"
                                            type="submit"
                                            style={{ width: "100px", height: "40px", lineHeight: "10px", fontSize: "18px" }}
                                            className='rounded-lg text-orange-400 '
                                            onClick={() => removeQuestion(currentPage - 1)}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="default" size="lg"
                                            type="submit"
                                            style={{ width: "100px", height: "40px", lineHeight: "10px", fontSize: "18px" }}
                                            className='bg-red-400 rounded-lg text-white'
                                            onClick={() => {
                                                if (!questions.hasOwnProperty(currentPage - 1)) addQUestion("singlechoice")
                                                else updateQUestion("singlechoice")
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>
                                <div className={`${section === "rating" ? "" : "hidden"}`}>
                                    <div className='flex justify-between items-center'>
                                        <p className="text-2xl">Rating Scale</p>
                                        <img src={deleteBin} alt='' className='w-10 cursor-pointer' onClick={() => removeQuestion(currentPage - 1)} />
                                    </div>
                                    <p className='my-3 text-gray-400 text-lg '>Question</p>
                                    <textarea
                                        className="border border-gray-400 rounded p-2 min-h-[4rem] resize-none w-full border-gray focus:outline-none"
                                        placeholder="Enter question here"
                                        value={currentQuestion}
                                        onChange={(e) => setCurrentQuestion(e.target.value)}
                                    />
                                    <p className='mt-4 mb-2 text-gray-400 text-md '>Scale</p>
                                    <div className='flex gap-32 text-md items-center'>
                                        <p className='w-32'>Scale Type</p>
                                        <ReactSelect2 options={scaleTypeOptions} handleChange={handleScaleChange} placeholder={"--Choose a scale type--"} defaultValue={getDefaultScaleOption} />
                                    </div>
                                    <div className='flex gap-32 text-md mt-4 items-center'>
                                        <p className='w-32'>Scoring Direction</p>
                                        <ReactSelect2 options={[
                                            { value: "Ascending", label: "Ascending" },
                                            { value: "Descending", label: "Descending" }
                                        ]}
                                            isDisabled={scaleType === "Yes-No" ? true : false}
                                            placeholder={"--Choose a direction--"}
                                            handleChange={(option) => setScoringDirection(option.value)} defaultValue={getDefaultOrderOption} />

                                        {scaleType === "Yes-No" ? <p className='-ml-28 text-gray-400 text-md '>-NA- for this type</p> : <></>}
                                    </div>
                                    <div className='flex mt-4 justify-between w-full'>
                                        {(scaleType !== '' && scoringDirection !== "") || scaleType === "Yes-No" ?
                                            scaleType === "Yes-No" ?
                                                <div className='flex w-full justify-center gap-8'>
                                                    <div className='flex items-center gap-2'>
                                                        <span className='w-4 h-4 rounded-full border'></span>
                                                        <p>Yes</p>
                                                    </div>
                                                    <div className='flex items-center gap-2'>
                                                        <span className='w-4 h-4 rounded-full border'></span>
                                                        <p>Uncertain</p>
                                                    </div>
                                                    <div className='flex items-center gap-2'>
                                                        <span className='w-4 h-4 rounded-full border'></span>
                                                        <p>No</p>
                                                    </div>

                                                </div> :
                                                <div className='flex w-full'>
                                                    <div className='flex w-48 gap-2 items-center'>
                                                        <img src={scoringDirection === "Ascending" ? rating1 : rating5} alt='' className='w-10' />
                                                        <p>{scoringDirection === "Ascending" ? ratingDesc[0] : ratingDesc[4]}</p>
                                                    </div>
                                                    <div className='flex w-48 gap-2 items-center'>
                                                        <img src={scoringDirection === "Ascending" ? rating2 : rating4} alt='' className='w-10' />
                                                        <p>{scoringDirection === "Ascending" ? ratingDesc[1] : ratingDesc[3]}</p>
                                                    </div>
                                                    <div className='flex w-48 gap-2 items-center'>
                                                        <img src={rating3} alt='' className='w-10' />
                                                        <p>{ratingDesc[2]}</p>
                                                    </div>
                                                    <div className='flex w-48 gap-2 items-center'>
                                                        <img src={scoringDirection === "Ascending" ? rating4 : rating2} alt='' className='w-10' />
                                                        <p>{scoringDirection === "Ascending" ? ratingDesc[3] : ratingDesc[1]}</p>
                                                    </div>
                                                    <div className='flex w-48 gap-2 items-center'>
                                                        <img src={scoringDirection === "Ascending" ? rating5 : rating1} alt='' className='w-10' />
                                                        <p>{scoringDirection === "Ascending" ? ratingDesc[4] : ratingDesc[0]}</p>
                                                    </div>
                                                </div> :
                                            <p className='mt-4 mb-2 text-gray-400 text-md mx-auto'>Preview appears here</p>

                                        }
                                    </div>
                                    <p className='mt-4 mb-2 text-gray-400 text-md '>Options</p>
                                    <div className='flex gap-32 text-md'>
                                        <p>Required</p>
                                        <label className="flex items-center">
                                            <input type="checkbox" className="form-singlechoice rounded text-red-400" onClick={() => setRequired(!required)} value={required} />
                                            <span className="ml-2">Respondents must answer this question.</span>
                                        </label>
                                    </div>
                                    <div className='flex justify-end items-center gap-4'>
                                        <Button
                                            variant="default" size="lg"
                                            type="submit"
                                            style={{ width: "100px", height: "40px", lineHeight: "10px", fontSize: "18px" }}
                                            className='rounded-lg text-orange-400 '
                                            onClick={() => removeQuestion(currentPage - 1)}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="default" size="lg"
                                            type="submit"
                                            style={{ width: "100px", height: "40px", lineHeight: "10px", fontSize: "18px" }}
                                            className='bg-red-400 rounded-lg text-white'
                                            onClick={() => {
                                                if (!questions.hasOwnProperty(currentPage - 1)) addQUestion("rating")
                                                else updateQUestion("rating")
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>
                                <div className={`${section === "textfield" ? "" : "hidden"}`}>
                                    <div className='flex justify-between items-center'>
                                        <p className="text-2xl">Text Field</p>
                                        <img src={deleteBin} alt='' className='w-10 cursor-pointer' onClick={() => removeQuestion(currentPage - 1)} />
                                    </div>
                                    <p className='my-3 text-gray-400 text-lg '>Text</p>
                                    <textarea
                                        className="border border-gray-400 rounded p-2 min-h-[4rem] resize-none w-full border-gray focus:outline-none"
                                        placeholder="Enter text here"
                                        value={currentQuestion}
                                        onChange={(e) => setCurrentQuestion(e.target.value)}
                                    />
                                    <p className='mt-6 mb-3 text-gray-400 text-md '>Options</p>
                                    <div className='flex gap-28 text-md w-full mb-2'>
                                        <p className='w-36'>Required</p>
                                        <div className="flex flex-col items-start w-full">
                                            <div className="flex items-center">
                                                <input type="checkbox" className="form-singlechoice rounded text-red-400" onClick={() => setRequired(!required)} value="mandatory" defaultChecked={required} />
                                                <span className="ml-2">Respondents must answer this question.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex gap-28 text-md w-full mb-2'>
                                        <p className='w-36'>Placeholder</p>
                                        <div className="flex items-start w-full">
                                            <textarea
                                                className="border border-gray-400 rounded mt-2 min-h-[1rem] resize-none w-64 border-gray focus:outline-none text-gray-500"
                                                placeholder="Enter placeholder here"
                                                value={placeholder}
                                                onChange={(e) => setPlaceholder(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex gap-28 text-md w-full my-4'>
                                        <p className='w-[7vw]'>Word Limit</p>
                                        <ReactSelect2 options={wordLimitOptions} handleChange={handleWordLimitChange} placeholder={wordLimit ? wordLimit : "--Word Limit--"} defaultValue={wordLimit} />
                                    </div>
                                    <div className='flex justify-end items-center gap-4'>
                                        <Button
                                            variant="default" size="lg"
                                            type="submit"
                                            style={{ width: "100px", height: "40px", lineHeight: "10px", fontSize: "18px" }}
                                            className='rounded-lg text-orange-400 '
                                            onClick={() => removeQuestion(currentPage - 1)}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="default" size="lg"
                                            type="submit"
                                            style={{ width: "100px", height: "40px", lineHeight: "10px", fontSize: "18px" }}
                                            className='bg-red-400 rounded-lg text-white'
                                            onClick={() => {
                                                if (!questions.hasOwnProperty(currentPage - 1)) addQUestion("textfield")
                                                else updateQUestion("textfield")
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>
                                <div className={`${section === "generic" ? "" : "hidden"}`}>
                                    <div className='flex justify-between items-center'>
                                        <p className="text-2xl">Generic Question</p>
                                        <div className='flex gap-2'>
                                            <img src={copyIcon} alt='' className='w-8 cursor-pointer' />
                                            <img src={deleteBin} alt='' className='w-10 cursor-pointer' onClick={() => removeQuestion(currentPage - 1)} />
                                        </div>
                                    </div>
                                    <p className='my-3 text-black text-lg '>Rate your overall Experience for this version</p>
                                    <div className='flex w-full mt-2 my-4'>
                                        <div className='flex w-48 gap-2 items-center'>
                                            <img src={rating5} alt='' className='w-10' />
                                            <p>Love it!</p>
                                        </div>
                                        <div className='flex w-48 gap-2 items-center'>
                                            <img src={rating4} alt='' className='w-10' />
                                            <p>Like it!</p>
                                        </div>
                                        <div className='flex w-48 gap-2 items-center'>
                                            <img src={rating3} alt='' className='w-10' />
                                            <p>It's OK!</p>
                                        </div>
                                        <div className='flex w-48 gap-2 items-center'>
                                            <img src={rating2} alt='' className='w-10' />
                                            <p>Hate it!</p>
                                        </div>
                                        <div className='flex w-48 gap-2 items-center'>
                                            <img src={rating1} alt='' className='w-10' />
                                            <p>Fedup!</p>
                                        </div>
                                    </div>
                                    <textarea
                                        className="border border-gray-400 rounded p-2 min-h-[12rem] mb-2 resize-none w-full border-gray focus:outline-none"
                                        placeholder="Additional Thoughts"
                                        disabled
                                    />
                                    <div className='flex justify-end items-center gap-4 mt-2'>
                                        <Button
                                            variant="default" size="lg"
                                            type="submit"
                                            style={{ width: "100px", height: "40px", lineHeight: "10px", fontSize: "18px" }}
                                            className='rounded-lg text-orange-400 '
                                            onClick={() => removeQuestion(currentPage - 1)}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="default" size="lg"
                                            type="submit"
                                            style={{ width: "100px", height: "40px", lineHeight: "10px", fontSize: "18px" }}
                                            className='bg-red-400 rounded-lg text-white'
                                            onClick={() => {
                                                if (!questions.hasOwnProperty(currentPage - 1)) addQUestion("generic")
                                                else updateQUestion("generic")
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        }


                        <Col md={3}>
                            <div className='flex flex-col gap-6'>
                                <div
                                    onClick={() => { setSection("multiplechoice"); clearQuestion() }}
                                    className={`flex gap-6 items-center justify-center h-14 rounded-xl bg-white cursor-pointer ${section === "multiplechoice" ? "text-black" : "text-gray-400"}`}>
                                    <img src={section === "multiplechoice" ? checkBlack : checkGray} alt='' className='w-5' />
                                    <p className={`text-lg min-w-[8rem] ${section === "multiplechoice" ? "text-black font-bold" : ""}`}>Multiple Choice</p>
                                </div>
                                <div
                                    onClick={() => { setSection("singlechoice"); clearQuestion() }}
                                    className={`flex gap-6 items-center justify-center h-14 rounded-xl bg-white cursor-pointer ${section === "singlechoice" ? "text-black" : "text-gray-400"}`}>
                                    <img src={section === "singlechoice" ? selectBlack : selectGray} alt='' className='w-5' />
                                    <p className={`text-lg min-w-[8rem] ${section === "singlechoice" ? "text-black font-bold" : ""}`}>Single Choice</p>
                                </div>
                                <div
                                    onClick={() => { setSection("rating"); clearQuestion() }}
                                    className={`flex gap-6 items-center justify-center h-14 rounded-xl bg-white cursor-pointer ${section === "rating" ? "text-black" : "text-gray-400"}`}>
                                    <img src={section === "rating" ? ratingBlack : ratingGray} alt='' className='w-5' />
                                    <p className={`text-lg min-w-[8rem] ${section === "rating" ? "text-black font-bold" : ""}`}>Rating Scale</p>
                                </div>
                                <div
                                    onClick={() => { setSection("textfield"); clearQuestion() }}
                                    className={`flex gap-6 items-center justify-center h-14 rounded-xl bg-white cursor-pointer ${section === "textfield" ? "text-black" : "text-gray-400"}`}>
                                    <img src={section === "textfield" ? textFieldBlack : textFieldGray} alt='' className='w-5' />
                                    <p className={`text-lg min-w-[8rem] ${section === "textfield" ? "text-black font-bold" : ""}`}>Text Field</p>
                                </div>
                                <div
                                    onClick={() => { setSection("generic"); clearQuestion() }}
                                    className={`flex gap-6 items-center justify-center h-14 rounded-xl bg-white cursor-pointer hidden ${section === "generic" ? "text-black" : "text-gray-400"}`}>
                                    <img src={section === "generic" ? custTextBlack : custTextGray} alt='' className='w-5' />
                                    <p className={`text-lg min-w-[8rem] ${section === "generic" ? "text-black font-bold" : ""}`}>Generic Question</p>
                                </div>
                                <div className='flex flex-col items-center gap-2'>
                                    <Button
                                        variant="default" size="lg"
                                        type="submit"
                                        style={{ width: "100%", height: "40px", lineHeight: "10px", fontSize: "18px" }}
                                        className='bg-red-400 rounded-lg text-white'
                                        disabled={questions?.length > 0 ? false : true}
                                        onClick={handlePreviewClick}
                                    >
                                        Preview & Publish
                                    </Button>
                                    <Button
                                        variant="default" size="lg"
                                        type="submit"
                                        style={{ width: "80%", height: "40px", lineHeight: "10px", fontSize: "18px" }}
                                        className='bg-orange-400 rounded-lg text-white'
                                        disabled={formStatus || questions?.length > 0 ? false : true}
                                        onClick={() => { setFormStatus("true"); createFeedbackForm(true) }}
                                    >
                                        Save as Draft
                                    </Button>
                                    <Button
                                        variant="default" size="lg"
                                        type="submit"
                                        style={{ height: "40px", lineHeight: "10px", fontSize: "18px" }}
                                        className='rounded-lg text-orange-400 '
                                        onClick={() => history.goBack()}

                                    >
                                        Cancel Form
                                    </Button>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            }
            <Modal show={showSuccess} onHide={() => setShowSuccess(false)} size="md" centered >
                <Modal.Body>
                    <div className='flex flex-col gap-4 justify-center items-center'>
                        <img src={uploaded} alt='' />
                        <p className='text-3xl font-semibold text-orange-600'>Added Successfully</p>
                        <p className='text-lg text-orange-400'>{formStatus ? "Feedback Form has been Saved Successfully" : "Feedback Form has been Added Successfully"}</p>
                    </div>
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default NewFeedbackFrom

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import fedduplogo from "../images/fedduplogo.png";
import faqImage from '../images/faqImage.svg';
import faqSearch from '../images/faqSearch.svg';
import NavBar from '../NavBar/NavBar';
import Header from '../Header/Header';


export default function FAQs() {

    const [data, setData] = useState([
        {
            id: 1,
            ques: "What is Feddup?",
            ans: "Feddup is a free to use feedback tool for web and desktop application."
        },
        {
            id: 2,
            ques: "Is feddup paid?",
            ans: `Feddup is free for all users. Feddup has 2 payment tiers for companies. Free and Premium. <a class="underline" href="${window.location.origin}/pricing" target="_blank">Learn more from here</a>.`
        },
        {
            id: 3,
            ques: "Can I post without signing in?",
            ans: "Yes, but only through the pop up. For better interaction and follow ups we suggest to use the sign up."
        },
        {
            id: 4,
            ques: "What is a feddback?",
            ans: "A public community section for all feddup pages, where u can post about bugs or errors you have encountered."
        },
        {
            id: 5,
            ques: "What if you want to share confidential data?",
            ans: "Feddup does not store any sensitive user information or capture any information from the application being used.If you want to report an error or bug only with the application admin, the company must be on a paid tier and you must use the “reach support” button."
        },
        {
            id: 6,
            ques: "What are tags?",
            ans: "Tags are an easy way to sort posts in feddup pages, or an easy way to let the product admin know what your suggestion or feddback is on."
        },
        {
            id: 7,
            ques: "Can i add custom tags?",
            ans: "No, a user cannot add custom tags. Tags are set by the page’s admin and can be changed at anytime."
        }
    ])

    const handleAddIcon = (index) => {
        let newData = [...data];
        newData = newData.map((obj, id) => {
            return {
                ...obj,
                open: id === index
            }
        })
        setData(newData)
    }

    const handleCloseIcon = () => {
        let newData = [...data];
        newData = newData.map((obj, id) => {
            return {
                ...obj,
                open: false
            }
        })
        setData(newData)
    }

    return (
        <div style={{ background: "#FFAA37", height: "100dvh" }} className=''>
            {
                localStorage.getItem('role')
                    ?
                    <NavBar />
                    :
                    <Header />
            }

            <div className='flex gap-20 items-start pt-40 justify-center px-5'>

                <img src={faqImage} alt='' className='max-w-xs' />
                <div className='w-full max-w-3xl'>
                    <span style={{ color: "#000000", fontWeight: 600, fontSize: "50px", fontFamily: "Poppins" }}>Have a </span><span style={{ color: "#ffffff", fontWeight: 600, fontSize: "50px", fontFamily: "Poppins" }}>Question?</span><br />

                    {data.map((obj, id) =>
                        <div className='my-3' key={id}>
                            <div className='flex items-center justify-between cursor-pointer' onClick={() => obj.open ? handleCloseIcon(id) : handleAddIcon(id)} >
                                <p className="cursor-pointer font-bold text-xl flex-grow" style={{ textDecoration: "none", color: "#ffffff" }} >{obj.ques}</p>
                                <i className={`fa ${!obj.open ? 'fa-plus' : 'fa-minus'} cursor-pointer`} style={{ color: "white" }} aria-hidden="true"></i>
                            </div>
                            {obj.open ? <p className='text-white text-lg opacAnimation' dangerouslySetInnerHTML={{ __html: `${obj.ans}` }}></p> : <></>}
                            <hr className='mt-2' />
                        </div>
                    )}
                    <img src={faqSearch} style={{ width: "18%", position: "fixed", bottom: "9%", left: "60%" }} />
                </div>
            </div>
        </div>
    )
}

import React from 'react';
import '../CompanyFeed/CompanyFeed.css';
import { Row, Col, Modal, Form, FormControl, Card, Button } from "react-bootstrap";
import axios from 'axios';
import { config } from '../Config/Config';
import { decrypt } from '../utils';

class EditFeddup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            version: "",
            helpLink: "",
            faqLink: "",
            productId: this.props.data
        }


    }
    componentDidMount() {
        console.log("props", this.props)
    }
    editFeddup = (e) => {
        e.preventDefault();
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "productId": this.props.data,
            "productVersion": parseFloat(this.state.version),
            "productHelp": this.state.helpLink,
            "productFaq": this.state.faqLink
        }
        var self = this;
        console.log("payload", payload)
        axios.post(config.userUrl + "admin/editProductDetails", payload, { headers: headers })
            .then(function (response) {
                console.log("product details added successfully", response);
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    self.props.closePopup();
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    render() {
        const { productId } = this.state
        return (
            <Modal
                size="lg"
                show={true}
                onHide={this.props.closePopup}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <h5 style={{ marginTop: "5px", fontFamily: "poppins" }}>&nbsp;Code Snippet</h5>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Card className="border-none">
                            <div className="edit-feddup">
                                <div style={{ width: "90%" }}>
                                    <div>&lt;script id="#feddup12345"&gt; var nodeId = "{productId}";&lt;/script&gt;<br />
                                        &lt;script&gt; src="{config.popupUrl}/popup.js&lt;/script&gt;
                                    </div>
                                </div>
                                <span className="showPassword" onClick={() => navigator.clipboard.writeText(`<script id="#feddup12345"> var nodeId = "${productId}";</script><script src="${config.popupUrl}/popup.js"></script>`)} >
                                    <div style={{ marginTop: "15px", fontSize: "10px", color: "#FF942C" }}>Copy Script</div>
                                </span>
                            </div>
                        </Card>
                        <hr />
                        <Card className="border-none">
                            <h5 style={{ marginTop: "5px", fontFamily: "poppins" }}>Edit FeddUp Popup</h5>
                            <Form style={{ fontFamily: "Poppins" }}>
                                <label>Version</label>
                                <input
                                    className="mb-2 form-control"
                                    name="version"
                                    value={this.state.version}
                                    placeholder="Version"
                                    onChange={(e) => this.setState({ version: e.target.value })}
                                />
                                <label>Faq Link</label>
                                <input
                                    className="mb-2 form-control"
                                    name="version"
                                    value={this.state.faqLink}
                                    placeholder="Faq Link"
                                    onChange={(e) => this.setState({ faqLink: e.target.value })}
                                />
                                <label>Help Link</label>
                                <input
                                    className="mb-2 form-control"
                                    name="helpLink"
                                    value={this.state.helpLink}
                                    placeholder="Help Link"
                                    onChange={(e) => this.setState({ helpLink: e.target.value })}
                                />
                                <Button style={{ float: "right", width: "5rem" }}
                                    variant="default" size="sm"
                                    type="submit"
                                    className="submit-button"
                                    onClick={this.editFeddup}
                                >
                                    Submit
                                </Button>
                            </Form>
                        </Card>
                    </div >
                </Modal.Body >
            </Modal >
        );
    }
}

export default EditFeddup;


import React from 'react';
import '../CompanyFeed/CompanyFeed.css';
import { Row, Col, Modal, Form, FormControl, OverlayTrigger, Tooltip, Card, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import upvoteb from '../images/upvote-b.svg';
import downvoteb from '../images/downvote-b.svg';
import upvote from '../images/upvote.svg';
import downvote from '../images/downvote.svg';
import comments from '../images/comments.svg';
import share from '../images/share.svg';
import fillTag from '../images/fill-tag.svg';
import lock from '../images/lock.png';
import profilepicture from '../images/profilepicture.png';
import axios from 'axios';
import { config } from '../Config/Config';
import Tag from '../utils/Tag';
import ShareBox from '../UsersFeed/ShareBox';
import moment from 'moment';
import { decrypt } from '../utils';

// const list = ["adventurer", "adventurer-neutral", "croodles", "croodles-neutral", "avataaars", "bottts", "big-ears", "micah", "open-peeps", "miniavs", "identicon"]
class SuggestionComments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uImg: false,
            dImg: false,
            cuImg: false,
            cdImg: false,
            count: 999,
            suggestion: [],
            suggestionByUserId: "",
            allComments: [],
            comment: "",
            commentReply: "",
            click: false,
            commentByUserId: "",
            suggestionCommentId: "",
            suggId: false
        }

    }
    componentDidMount() {
        console.log("props", this.props)
        this.getSuggestionById();
    }
    openAdminReplyComment = (data) => {
        var suggId = this.state.allComments.map(c => c.id == data.id ? true : false)
        console.log("reply", data.id, suggId)
        // var feedVal = feedId.map((item, i) =>
        //     <div></div>)
        this.setState({ suggId: suggId, click: !this.state.click, suggestionCommentId: data.id, commentByUserId: data.commentByUserId })
    }
    likeOrDislikeSuggestionReplyCommentUpvote = (id, val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        console.log("dataup", id.id)
        this.setState({ cuImg: !this.state.cuImg })
        if (this.state.cuImg) {
            this.setState({ cdImg: false })

        } else {
            this.setState({ cdImg: false })
        }
        var payload = {
            "suggestionReplyCommentId": id.replyId,
            "suggestionReplyComment": val,
            "userIdSuggestionReplyCommentBelongTo": id.replyByUserId
        }
        console.log("payload", payload)
        var self = this;
        axios.post(config.userUrl + "admin/likeOrDislikeSuggestionReplyCommentByAdmin", payload, { headers: headers })
            .then(function (response) {
                console.log("comment created successfully", response);
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    self.getSuggestionById();
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    likeOrDislikeSuggestionReplyCommentDownvote = (id, val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        this.setState({ cdImg: !this.state.cdImg })
        if (!this.state.cdImg) {
            this.setState({ cuImg: false })
        } else {
            this.setState({ cuImg: false })
            this.setState({ cdImg: false })
        }
        var payload = {
            "suggestionReplyCommentId": id.replyId,
            "suggestionReplyComment": val,
            "userIdSuggestionReplyCommentBelongTo": id.replyByUserId
        }
        console.log("payload", payload)
        var self = this;
        axios.post(config.userUrl + "admin/likeOrDislikeSuggestionReplyCommentByAdmin", payload, { headers: headers })
            .then(function (response) {
                console.log("comment created successfully", response);
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    self.getSuggestionById();
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    replyForComment = (e) => {
        e.preventDefault();
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "suggestionCommentId": this.state.suggestionCommentId,
            "userIdFeedbackCommentBelongTo": this.state.commentByUserId,
            "comment": this.state.commentReply,
        }
        var self = this;
        console.log("payload", payload)
        axios.post(config.userUrl + "admin/suggestionCommentReplyByAdmin", payload, { headers: headers })
            .then(function (response) {
                console.log("comment created successfully", response);
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    self.setState({ commentReply: "" });
                    self.getSuggestionById();
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    getSuggestionById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "suggestionId": this.props.data.id
        }
        console.log("payload", payload)
        var self = this;
        axios.post(config.userUrl + 'admin/getSuggestionBySuggestionIdByAdmin', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    self.setState({
                        suggestion: response.data.suggestion,
                        suggestionByUserId: response.data.suggestion[0].suggestionByUserId,
                        allComments: response.data.allComments
                    })
                    self.props.getSuggestions(self.props.selectedValue);
                    console.log("suggestion by id retrieved successfully", response.data, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    shareArticle = (data) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        if (navigator.share) {
            navigator.share({
                url: `https://www.feddup.com/${data.title}`,
            }).then(() => {
                console.log('Thanks for sharing!', data.title);
                var payload = {
                    "suggestionId": data.id,
                    "userIdSuggestionBelongTo": data.suggestionByUserId
                }
                console.log("payload", payload)
                var self = this;
                axios.post(config.userUrl + 'admin/shareSuggestionByAdmin', payload, { headers: headers })
                    .then(function (response) {
                        console.log(response);
                        if (response.data.status == true) {
                            console.log("suggetsion shared successfully", response.data.message);
                            self.getSuggestionById();
                            self.props.getSuggestions(self.props.selectedValue);
                        } else {
                            console.log("Error", payload);
                        }
                    })
            }).catch(err => {
                console.log(
                    "Error while using Web share API:");
                console.log(err);
                console.log("error", err);
            });
        } else {
            console.log("Sorry! Your browser does not support Web Share API");
        }
    }
    // changeUpvoteImage = () => {
    //     const { uImg, dImg, count } = this.state
    //     this.setState({ uImg: !uImg })
    //     if (uImg) {
    //         this.setState({ count: count - 1, dImg: false })
    //     } else {
    //         this.setState({ count: count + 1, dImg: true })
    //     }
    // }
    likeDislikeUpvoteComment = (id, val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        console.log("dataup", id.id)
        this.setState({ cuImg: !this.state.cuImg })
        if (this.state.cuImg) {
            this.setState({ cdImg: false })

        } else {
            this.setState({ cdImg: false })
        }
        var payload = {
            "suggestionCommentId": id.id,
            "suggestionComment": val,
            "userIdSuggestionCommentBelongTo": id.commentByUserId
        }
        console.log("payload", payload)
        var self = this;
        axios.post(config.userUrl + "admin/likeOrDislikeSuggestionCommentByAdmin", payload, { headers: headers })
            .then(function (response) {
                console.log("comment created successfully", response);
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    self.getSuggestionById();
                }
            })
    };
    likeDislikeDownvoteComment = (id, val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        this.setState({ cdImg: !this.state.cdImg })
        if (!this.state.cdImg) {
            this.setState({ cuImg: false })
        } else {
            this.setState({ cuImg: false })
            this.setState({ cdImg: false })
        }
        var payload = {
            "suggestionCommentId": id.id,
            "suggestionComment": val,
            "userIdSuggestionCommentBelongTo": id.commentByUserId
        }
        console.log("payload", payload)
        var self = this;
        axios.post(config.userUrl + "admin/likeOrDislikeSuggestionCommentByAdmin", payload, { headers: headers })
            .then(function (response) {
                console.log("comment created successfully", response);
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    self.getSuggestionById();
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    changeUpvoteSuggestionImage = (id, val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        console.log("dataup", id.id)
        const { uImg, dImg, count } = this.state
        this.setState({ uImg: !uImg })
        if (uImg) {
            this.setState({ count: count - 1, dImg: false })
        } else {
            this.setState({ count: count + 1, dImg: true })
        }
        var self = this;
        var payload = {
            "suggestionId": id.id,
            "suggestion": val,
            "userIdSuggestionBelongTo": id.suggestionByUserId
        }
        console.log("upvote", payload)
        var self = this;
        axios.post(config.userUrl + 'admin/likeOrDislikeSuggestionByAdmin', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    self.getSuggestionById();
                    self.props.getSuggestions(self.props.selectedValue);
                    console.log("upvote", payload, response.data)
                }
            })
            .catch(function (error) {
                console.log(error, payload)
            })
    }
    changeDownvoteSuggestionImage = (id, val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        console.log("dataup", id.id)
        const { uImg, dImg, count } = this.state
        this.setState({ dImg: !dImg })
        if (!dImg) {
            this.setState({ count: count - 1, uImg: false })
        } else {
            this.setState({ dImg: false, uImg: false })
        }
        var payload = {
            "suggestionId": id.id,
            "suggestion": val,
            "userIdSuggestionBelongTo": id.suggestionByUserId
        }
        console.log("upvote", payload)
        var self = this;
        axios.post(config.userUrl + 'admin/likeOrDislikeSuggestionByAdmin', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    self.getSuggestionById();
                    self.props.getSuggestions(self.props.selectedValue);
                    console.log("upvote", payload, response.data)
                }
            })
            .catch(function (error) {
                console.log(error, payload)
            })
    }
    createComment = (e) => {
        e.preventDefault();
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "suggestionId": this.props.data.id,
            "userIdSuggestionBelongTo": this.state.suggestionByUserId,
            "comment": this.state.comment,
        }
        var self = this;
        console.log("payload", payload)
        var self = this;
        axios.post(config.userUrl + "admin/suggestionCommentByAdmin", payload, { headers: headers })
            .then(function (response) {
                console.log("comment created successfully", response);
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    self.setState({ comment: "" });
                    self.getSuggestionById();
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    render() {
        const { uImg, dImg, count, comment, commentReply, suggestion } = this.state
        return (
            // <div className='popup-box'>
            //     <div className='_box'>
            //         <span className="close-icon-new" onClick={this.props.closePopup}> &times;</span>
            <div>
                <Modal
                    show={true}
                    onHide={this.props.closePopup}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >    <Modal.Header closeButton style={{ zIndex: "100" }}>
                    </Modal.Header>
                    {suggestion.map((data, i) => (
                        <div key={i}>
                            <Card className="border-none mb-3">
                                <div className="row" style={{ marginTop: "-2.5rem" }}>
                                    <Col md={2}>
                                        <Card className="ml-2 mt-2 mb-2 border-none card-padding-sm rounded-xl" style={{ background: "#FFEBD0" }}>
                                            <center className='cursor'>{data.vote == 1 ? <img src={upvote} onClick={(e, i) => this.changeUpvoteSuggestionImage(data, 2)} style={{ width: "55px" }} /> : <img src={upvoteb} onClick={(e, i) => this.changeUpvoteSuggestionImage(data, 1)} style={{ width: "45px" }} />}</center><br />
                                            <center className="likes">{data.totallike == null ? 0 : data.totallike}</center><br />
                                            <center className='cursor'>{data.vote == -1 ? <img src={downvote} onClick={(e, i) => this.changeDownvoteSuggestionImage(data, 2)} style={{ width: "55px" }} /> : <img src={downvoteb} onClick={(e, i) => this.changeDownvoteSuggestionImage(data, 0)} style={{ width: "45px" }} />}</center>
                                        </Card>
                                    </Col>
                                    <Col md={10}>
                                        <div className="mt-2 mb-2">
                                            <div className="card-padding">
                                                <h6 className="t-today text-lg">{data.title}</h6>
                                                <div className="posted pt-2">Posted by {data.userName == null ? "anonymous" : data.userName} &nbsp;&nbsp;{data.updatedAt.split("T")[0].split("-").reverse().join("-")} {moment(data.updatedAt).diff(moment(data.createdAt), 'minutes') > 1 ? <span>(Edited)</span> : ''}</div>
                                                {/* <div className="font-medium">{data.title}</div> */}
                                                <div className="mt-1" dangerouslySetInnerHTML={{
                                                    __html: data.suggestion
                                                }} />
                                                {
                                                    data?.tag?.length
                                                        ?
                                                        <div className='flex flex-wrap gap-x-3 gap-y-3 py-3'>
                                                            {data?.tag?.map(tag => <Tag text={tag} />
                                                            )}
                                                        </div>
                                                        : ''
                                                }
                                                <div className='my-2'><img src={data.suggestionImage} className="cursor" style={{ width: "150px" }} onClick={() => { this.props.openSuggestionImage(data); }} /></div>

                                                <div className='flex items-center'>
                                                    <div className='flex gap-1 items-center'>
                                                        <img src={comments} style={{ width: "25px" }} /> <span className="comment">{data.commentCount == null ? 0 : data.commentCount} Comments</span>
                                                    </div>
                                                    <ShareBox boxClass="" shareCount={data.shareCount} path={`/${data.companyName}/${data.productName}/${data.productTypeString}`} type={"Suggestion"} postId={data.id} callback={() => { }} />

                                                </div>
                                                <div className="mt-3">
                                                    <Form><span style={{ fontFamily: "Poppins" }}>Comment Here</span>
                                                        <textarea
                                                            className="mt-1 mb-2 form-control"
                                                            id="exampleFormControlTextarea1"
                                                            rows="5"
                                                            name="comment"
                                                            style={{ resize: "none", fontFamily: "poppins" }}
                                                            value={this.state.comment}
                                                            placeholder="Comment"
                                                            onChange={(e) => this.setState({ comment: e.target.value })}
                                                        />
                                                        <OverlayTrigger style={(comment.length != 0) ? { display: "none" } : { cursor: "pointer" }}
                                                            placement="left"
                                                            overlay={
                                                                <Tooltip id={`tooltip-top`} style={(comment.length != 0) ? { display: "none" } : { cursor: "pointer" }}>
                                                                    {(comment.length != 0) ?
                                                                        <div style={{ display: "none" }}>

                                                                        </div>
                                                                        : <div className='tool-text'>
                                                                            Please add comment
                                                                        </div>
                                                                    }
                                                                </Tooltip>
                                                            }
                                                        >
                                                            {(comment.length != 0) ?
                                                                <Button style={{ float: "right", width: "5rem" }}
                                                                    variant="default" size="sm"
                                                                    type="submit"
                                                                    className="abc submit-button"
                                                                    onClick={this.createComment}
                                                                >
                                                                    Submit
                                                                </Button> :
                                                                <Button
                                                                    variant="default" size="md"
                                                                    style={{ float: "right" }}
                                                                    className="abc submit-button-grey"
                                                                >
                                                                    submit
                                                                </Button>
                                                            }
                                                        </OverlayTrigger>
                                                    </Form></div> <br />
                                                <div style={{ fontFamily: "Poppins" }}>Sort by<span style={{ color: "#E6570E" }}> Top </span></div>
                                                <hr style={{ marginTop: "5px" }} />
                                                <div className="mt-3">
                                                    {this.state.allComments.length == 0 ? <center className="no-details-text">No Comments to View </center> :
                                                        <div>
                                                            <div style={{ fontSize: "16px", fontFamily: "Poppins" }} className="mt-2">All Comments</div><br />
                                                            {this.state.allComments.map((data, i) => (
                                                                <div key={i}>
                                                                    <Row style={{ width: "100%" }}>
                                                                        <Col md={2}>
                                                                            <img src={data.profilePicture || `https://api.dicebear.com/7.x/avataaars/svg?seed=${data.userName}`} style={{ width: "50px" }} />
                                                                        </Col>
                                                                        <Col md={10}>
                                                                            <div className='c-comments'>
                                                                                <span style={{ display: "flex" }}><span style={{ fontSize: "14px" }}>{data.userName} </span>&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ marginTop: "5px", color: "#AAAAAA", fontSize: "10px" }}>{data.updatedAt.split("T")[0].split("-").reverse().join("-")}</span></span>
                                                                                <div style={{ fontSize: "14px" }}>{data.comment}</div>
                                                                                <div className='flex items-center gap-3 mt-2 cursor-pointer'>
                                                                                    <center> {data.vote == 1 ? <img src={upvote} onClick={(e, i) => this.likeDislikeUpvoteComment(data, 2)} style={{ width: "55px" }} /> : <img src={upvoteb} onClick={(e) => this.likeDislikeUpvoteComment(data, 1)} style={{ width: "45px" }} />}</center>
                                                                                    <center className="text-lg font-medium"> &nbsp;{data.totalLike == null ? 0 : data.totalLike}&nbsp;</center>
                                                                                    <center> {data.vote == -1 ? <img src={downvote} onClick={(e, i) => this.likeDislikeDownvoteComment(data, 2)} style={{ width: "55px" }} /> : <img src={downvoteb} onClick={(e) => this.likeDislikeDownvoteComment(data, 0)} style={{ width: "45px" }} />}</center>
                                                                                </div>
                                                                            </div>

                                                                            <div className='row'>
                                                                                <Col md={12}>
                                                                                    {this.state.click ?
                                                                                        <Form>
                                                                                            <textarea
                                                                                                className="mt-1 mb-2 form-control"
                                                                                                id="exampleFormControlTextarea1"
                                                                                                rows="3"
                                                                                                name="commentReply"
                                                                                                value={this.state.commentReply}
                                                                                                placeholder="Comment"
                                                                                                onChange={(e) => this.setState({ commentReply: e.target.value })}
                                                                                            />
                                                                                            <OverlayTrigger style={(commentReply.length != 0) ? { display: "none" } : { cursor: "pointer" }}
                                                                                                placement="left"
                                                                                                overlay={
                                                                                                    <Tooltip id={`tooltip-top`} style={(commentReply.length != 0) ? { display: "none" } : { cursor: "pointer" }}>
                                                                                                        {(commentReply.length != 0) ?
                                                                                                            <div style={{ display: "none" }}>

                                                                                                            </div>
                                                                                                            : <div className='tool-text'>
                                                                                                                Please add comment
                                                                                                            </div>
                                                                                                        }
                                                                                                    </Tooltip>
                                                                                                }
                                                                                            >
                                                                                                {(commentReply.length != 0) ?
                                                                                                    <Button style={{ float: "right", width: "5rem" }}
                                                                                                        variant="default" size="sm"
                                                                                                        type="submit"
                                                                                                        className="abc submit-button"
                                                                                                        onClick={this.replyForComment}
                                                                                                    >
                                                                                                        Submit
                                                                                                    </Button> :
                                                                                                    <Button
                                                                                                        variant="default" size="md"
                                                                                                        style={{ float: "right" }}
                                                                                                        className="abc submit-button-grey"
                                                                                                    >
                                                                                                        submit
                                                                                                    </Button>
                                                                                                }
                                                                                            </OverlayTrigger>
                                                                                        </Form>
                                                                                        : null}
                                                                                </Col>
                                                                            </div>
                                                                            {data.replyComment.length == 0 ? null :
                                                                                <div>
                                                                                    {data.replyComment.map((item, idx) => (
                                                                                        <Row style={{ width: "100%" }} className="ml-1 mt-2" key={idx}>
                                                                                            <Col md={1}>
                                                                                                <img src={data.userName == localStorage.getItem('name') ? localStorage.getItem('icon') : `https://api.dicebear.com/7.x/avataaars/svg?seed=${data.userName}`} style={{ width: "30px" }} />
                                                                                            </Col>
                                                                                            <Col md={11}>
                                                                                                <div className='c-comments'>
                                                                                                    {/* {item.replyUpdatedAt.split("T")[0].split("-").reverse().join("-")} */}
                                                                                                    <span style={{ display: "flex" }}><span style={{ fontSize: "14px" }}>{item.replyUserName} </span>&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ marginTop: "5px", color: "#AAAAAA", fontSize: "10px" }}></span></span>
                                                                                                    <div style={{ fontSize: "14px" }}>{item.replyComment}</div>
                                                                                                    <div style={{ display: "flex", marginTop: "10px" }} className='gap-3 items-center cursor-pointer'>
                                                                                                        <center> {item.replyVote == 1 ? <img src={upvote} onClick={(e, i) => this.likeOrDislikeSuggestionReplyCommentUpvote(item, 2)} style={{ width: "55px" }} /> : <img src={upvoteb} onClick={(e) => this.likeOrDislikeSuggestionReplyCommentUpvote(item, 1)} style={{ width: "45px" }} />}</center>
                                                                                                        <center className="text-lg font-medium"> &nbsp;{item.ReplyLikeCount == null ? 0 : item.ReplyLikeCount}&nbsp;</center>
                                                                                                        <center> {item.replyVote == -1 ? <img src={downvote} onClick={(e, i) => this.likeOrDislikeSuggestionReplyCommentDownvote(item, 2)} style={{ width: "55px" }} /> : <img src={downvoteb} onClick={(e) => this.likeOrDislikeSuggestionReplyCommentDownvote(item, 0)} style={{ width: "45px" }} />}</center>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    ))}
                                                                                </div>
                                                                            }
                                                                        </Col>
                                                                    </Row><br />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    }
                                                    {/* <div className='c-comments'>
                                                                <span style={{ display: "flex" }}><span style={{ fontSize: "18px" }}>John Doe </span>&nbsp;&nbsp;&nbsp;&nbsp;<li style={{ marginTop: "5px", color: "#AAAAAA", fontSize: "10px" }}>1 hour</li></span>
                                                                <div style={{ fontSize: "14px" }}>Suscipit egestas dolor massa magna nullam enim, dignissim. Sed est morbi auctor nibh morbi. Quisque tortor potenti fusce scelerisque adipiscing. Lectus mauris consectetur ut urna sit pellentesque blandit arcu in.</div>
                                                                <div style={{ display: "flex", marginTop: "10px" }}>
                                                                    <center>{uImg ? <img src={upvote} onClick={(e, i) => this.changeUpvoteImage()} style={{ width: "55px" }} /> : <img src={upvoteb} onClick={(e, i) => this.changeUpvoteImage()} style={{ width: "45px" }} />}</center>
                                                                    <center className="mt-2 likes"> &nbsp;&nbsp;{count}&nbsp;&nbsp;</center>
                                                                    <center>{dImg ? <img src={downvote} onClick={(e, i) => this.changeDownvoteImage()} style={{ width: "55px" }} /> : <img src={downvoteb} onClick={(e, i) => this.changeDownvoteImage()} style={{ width: "45px" }} />}</center>
                                                                    <span className="ml-5 mt-1"><img src={share} style={{ width: "22px" }} /> <span className="ml-2 comment">Share</span></span></div>
                                                            </div><br />
                                                            <div className="row">
                                                                <Col md={2}>
                                                                    <img src={profilepicture} style={{ width: "50px" }} />
                                                                </Col>
                                                                <Col md={10}>
                                                                    <div className='c-comments'>
                                                                        <span style={{ display: "flex" }}><span style={{ fontSize: "18px" }}>John Doe </span>&nbsp;&nbsp;&nbsp;&nbsp;<li style={{ marginTop: "5px", color: "#AAAAAA", fontSize: "10px" }}>1 hour</li></span>
                                                                        <div style={{ fontSize: "14px" }}>Suscipit egestas dolor massa magna nullam enim, dignissim. Sed est morbi auctor nibh morbi. Quisque tortor potenti fusce scelerisque adipiscing. Lectus mauris consectetur ut urna sit pellentesque blandit arcu in.</div>
                                                                        <div style={{ display: "flex", marginTop: "10px" }}><center> {uImg ? <img src={upvote} onClick={this.changeUpvoteImage} style={{ width: "55px" }} /> : <img src={upvoteb} onClick={this.changeUpvoteImage} style={{ width: "45px" }} />}</center>
                                                                            <center className="mt-2 likes"> &nbsp;&nbsp;{count}&nbsp;&nbsp;</center>
                                                                            <center> {dImg ? <img src={downvote} onClick={this.changeDownvoteImage} style={{ width: "55px" }} /> : <img src={downvoteb} onClick={this.changeDownvoteImage} style={{ width: "45px" }} />}</center>
                                                                            <span className="ml-5 mt-1"><img src={share} style={{ width: "22px" }} /> <span className="ml-2 comment">Share</span></span></div>
                                                                    </div><br />
                                                                </Col>
                                                            </div> */}

                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </Col>

                                </div>
                            </Card>
                        </div>
                    ))}
                </Modal>
            </div>
        );
    }
}

export default SuggestionComments;


import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { config } from '../Config/Config';
import { Button, Card, Col, Dropdown, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import AddImage from "./AddImage";
import PopupSuggestionComments from "../UsersFeed/PopupSuggestionComments";
import suggestionImage from "../images/suggestion-img.svg";
import suggestionAccepted from '../images/suggestion-accepted.svg';
import suggestionRejected from '../images/suggestion-rejected.svg';
import suggestionInprogress from '../images/suggestion-inprogress.svg'
import imageicon from "../images/image-icon.svg";
import linkicon from "../images/link-icon.svg";
import i from "../images/i.svg";
import fillTag from "../images/fill-tag.svg";
import emptyTag from "../images/empty-tag.svg";
import downvoteb from '../images/downvote-b.svg';
import upvote from '../images/upvote.svg';
import upvoteb from '../images/upvote-b.svg';
import downvote from '../images/downvote.svg';
import dots from "../images/dots.svg";
import ImgEdit from "../images/edit.svg";
import imgDel from "../images/delete.svg";
import comment from '../images/comments.svg';
import share from '../images/share.svg';
import ViewSuggestionImage from '../UsersFeed/ViewSuggestionImage';
import DeleteSuggestionPopup from '../UsersFeed/DeleteSuggestionPopup';
import EditSuggestionPopup from '../UsersFeed/EditSuggestionPopup';
import OpenShareSuggestionPopup from '../UsersFeed/OpenShareSuggestionPopup';
import AddImage2 from './AddImage2';
import moment from 'moment';
import Tag from '../utils/Tag';
import AccessPopUp from './AccessPopUp';
import ShareBox from '../UsersFeed/ShareBox';
import { decrypt, encrypt } from '../utils';

function ProductSuggestionSection(props) {
    const { companyId, productId, selectedVersion, selectedValue, initialTags, canReview, guidelines, productType, sharedId, setState } = props;
    const [addSuggestion, setAddSuggestion] = useState(false);
    const [suggestionTitle, setSuggestionTitle] = useState("");
    const [suggestionDescription, setSuggestionDescription] = useState("");
    const [image, setImage] = useState("");
    const [defaultTags, setDefaultTags] = useState(initialTags || [])
    const [selectedTags, setSelectedTags] = useState([])
    const [productSuggestions, setProductSuggestions] = useState([]);
    const [uImg, setUImg] = useState(false);
    const [count, setCount] = useState(999);
    const [dImg, setDImg] = useState(true);
    const [editSuggestionPost, setEditSuggestionPost] = useState(false);
    const [tag, setTag] = useState([]);
    const [suggId, setSuggId] = useState("");
    const [deleteSuggestionPost, setDeleteSuggestionPost] = useState(false);
    const [popupSuggestion, setPopupSuggestion] = useState(false);
    const [popupSuggestionData, setPopupSuggestionData] = useState([]);
    const [openShareSuggestion, setOpenShareSuggestion] = useState(false);
    const [sortOption, setSortOption] = useState('');
    const [sortOption2, setSortOption2] = useState('');
    const [sortedData, setSortedData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [reviewEnabled, setReviewEnabled] = useState(false);
    const [viewSuggestionImage, setViewSuggestionImage] = useState(false);
    const [popupSuggestionImage, setPopupSuggestionImage] = useState("");
    const [isAccess, setIsAccess] = useState(true);
    const [accessPopUp, setAccessPopUp] = useState(false)


    useEffect(() => {
        setSortedData([]);
        getAllSuggestionsByProductId(0);
        // getDefaultTags()
    }, [selectedVersion])

    const getAllSuggestionsByProductId = async (eventKey) => {
        let headers;
        if (localStorage.getItem('junkStore')) {
            headers = {
                'Accept': 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                'Content-Type': 'application/json'
            }
        } else {
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        var payload = {
            "productId": productId,
            "type": parseInt(eventKey),
            "versionId": selectedVersion.id
        }
        axios.post(config.userUrl + "user/getSuggestionByProductId", payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("getSuggestionProductById successfull");
                    setProductSuggestions(response.data.suggestion?.sort((a, b) => b.pin - a.pin))
                    setSortedData(response.data.suggestion?.sort((a, b) => b.pin - a.pin))
                    setReviewEnabled(response.data.reviewEnabled.reviewEnabled);
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    useEffect(() => {
        if (productSuggestions?.length && sharedId) {
            let idx = productSuggestions.findIndex(item => item?.id == sharedId);
            if (idx > -1)
                togglePopupSuggestion(productSuggestions[idx])
        }
    }, [productSuggestions.length, sharedId])


    const addSuggestionPopup = () => {
        if (localStorage.getItem('junkStore')) {
            if (isAccess) {
                setAddSuggestion(true)
            }
            else {
                setAccessPopUp(true)
            }
        } else {
            setAddSuggestion(false)
        }
    }
    const onChangeImage = (data) => {
        setImage(data)
        console.log("s", data)
    }
    const handleTags = (e, tag) => {
        const updated = defaultTags.map(data => (data.tag === tag) ?
            { ...data, isSelected: !data.isSelected } : data)
        setDefaultTags(updated)
        setSelectedTags(updated)
    }
    const postSuggestion = () => {
        if (localStorage.getItem('junkStore')) {
            var headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                "applicationType": "web"
            }
            let tagsData = [];
            tagsData = defaultTags.filter(item => (item.isSelected === true) ? item.tag : null)
            var tokenId = encrypt({ "companyId": companyId, "productId": productId })
            var payload = {
                "tokenId": tokenId,
                "title": suggestionTitle,
                "suggestion": suggestionDescription,
                "suggestionImage": image,
                "tag": tagsData.map(item => item.tag),
                "versionId": selectedVersion.id
            }
            console.log("payload", payload);
            axios.post(config.userUrl + 'user/createProductSuggestionByUser', payload, { headers: headers })
                .then(function (response) {
                    // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                    if (response.data.status === true) {
                        console.log("success");
                        setAddSuggestion(false)
                        setSuggestionTitle("")
                        setImage("")
                        setSuggestionDescription("")
                        getDefaultTags()
                        getAllSuggestionsByProductId(0)
                    } else {
                        console.log("error", payload);
                    }
                })
                .catch(function (error) {
                    console.log("Error", error);
                });
        } else {
            console.log("No Suggestion token")
        }
    };

    const validateReviewAdded = () => {
        if (localStorage.getItem('junkStore')) {
            var headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                "applicationType": "web"
            }
            var tokenId = encrypt({ "companyId": companyId, "productId": productId })
            var payload = {
                "tokenId": tokenId,
                "versionId": selectedVersion.id,
                type: 'suggestion'
            }
            console.log("payload", payload);
            if (selectedVersion.id) {

                axios.post(config.userUrl + 'user/validateReviewAdded', payload, { headers: headers })
                    .then(function (response) {
                        if (response.data.status === true) {
                            setIsAccess(response.data?.access)
                        } else {
                            console.log("error", payload);
                        }
                    })
                    .catch(function (error) {
                        console.log("Error", error);
                    });
            }
        } else {
            console.log("No Suggestion token")
        }
    };

    // useEffect(() => {
    //     if (companyId && productId && selectedVersion?.id) {
    //         validateReviewAdded()
    //     }
    // }, [companyId, productId, selectedVersion?.id])

    const getDefaultTags = () => {
        var headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getDefaultTag', { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    for (let i = 0; i < response.data.getDefaultTag.length; i++) {
                        response.data.getDefaultTag[i].isSelected = false
                    }
                    setDefaultTags(response.data.getDefaultTag)
                } else {
                    console.log("error");
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    function changeUpvoteImageSuggestion(id, val) {
        if (localStorage.getItem('junkStore')) {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                "applicationType": "web"
            }
            console.log("dataup", id.id)

            setUImg(!uImg)
            if (uImg) {
                setCount(count - 1)
                setDImg(false)

            } else {
                setCount(count + 1)
                // setDImg(false)
                setDImg(false)
            }
            var payload = {
                "suggestionId": id.id,
                "suggestion": val,
                "userIdSuggestionBelongTo": id.suggestionByUserId
            }
            if (val == 1) {
                console.log("upvote", payload)
                let idx = sortedData?.findIndex(item => item.id == id.id);
                let arr = [...sortedData];
                arr[idx].vote = 1;
                arr[idx].totallike = Number(arr[idx].totallike) + 1;
                setSortedData(arr);

                axios.post(config.userUrl + 'user/likeOrDislikeSuggestion', payload, { headers: headers })
                    .then(function (response) {
                        if (response.data.status == true) {
                            // getAllSuggestionsByProductId(selectedValue);
                        }
                    })
                    .catch(function (error) {
                        arr[idx].vote = 0;
                        arr[idx].totallike = Number(arr[idx].totallike) - 1;
                        setSortedData(arr);
                        console.log(error, payload)
                    })
            }
        } else {
            console.log('No upvote')
        }
    }
    function changeDownvoteImageSuggestion(id, val) {
        if (localStorage.getItem('junkStore')) {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                "applicationType": "web"
            }
            setDImg(!dImg)
            if (!dImg) {
                setUImg(false)
                setCount(count - 1)
            } else {
                // setDImg(!dImg)
                setUImg(false)
                setDImg(false)
            }
            var payload = {
                "suggestionId": id.id,
                "suggestion": val,
                "userIdSuggestionBelongTo": id.suggestionByUserId
            }
            if (val == 0) {
                let idx = sortedData?.findIndex(item => item.id == id.id);
                let arr = [...sortedData];
                arr[idx].vote = -1;
                arr[idx].totallike = Number(arr[idx].totallike) - 1;
                setSortedData(arr);

                console.log("upvote", payload)
                axios.post(config.userUrl + 'user/likeOrDislikeSuggestion', payload, { headers: headers })
                    .then(function (response) {
                        if (response.data.status == true) {
                            console.log("upvote")
                            // getAllSuggestionsByProductId(selectedValue);
                        }
                    })
                    .catch(function (error) {
                        arr[idx].vote = 0;
                        arr[idx].totallike = Number(arr[idx].totallike) + 1;
                        setSortedData(arr);
                        console.log(error, payload)
                    })
            }
        } else {
            console.log('No downvote')
        }
    }
    function editSuggestion(id) {
        setEditSuggestionPost(!editSuggestionPost)
        setSuggestionTitle(id.title)
        setSuggestionDescription(id.suggestion)
        setImage(id.suggestionImage)
        setTag(id.tag)
        setSuggId(id.id)
    }
    function deleteSuggestion(id) {
        setDeleteSuggestionPost(!deleteSuggestionPost)
        setSuggId(id.id)
    }
    const togglePopupSuggestion = (data) => {
        setPopupSuggestion(!popupSuggestion)
        setPopupSuggestionData({ ...data, productTypeString: productType })
        // if (localStorage.getItem('junkStore')) {
        // } else {
        //     setPopupSuggestion(false)
        // }
    }
    const toggleShareSuggestionPopup = (data) => {
        if (localStorage.getItem('junkStore')) {
            setOpenShareSuggestion(!openShareSuggestion)
            setPopupSuggestionData(data)
        } else {
            setOpenShareSuggestion(false);
        }
    }
    const filter = () => {
        let suggestionsArray = [...productSuggestions]
        let sortedSuggestions = []
        if (sortOption2.length > 0) {
            if (sortOption2 === "Anonymous") {
                sortedSuggestions = suggestionsArray.filter(suggestion => suggestion.userName === null)
            }
            else if (sortOption2 === "Registered") {
                sortedSuggestions = suggestionsArray.filter(suggestion => suggestion.userName !== null)
            }
            else {
                sortedSuggestions = [...productSuggestions];
            }
            suggestionsArray = [...sortedSuggestions];
        }
        if (sortOption.length > 0) {
            if (sortOption === "Latest to Oldest") {
                sortedSuggestions = suggestionsArray.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            }
            else {
                sortedSuggestions = suggestionsArray.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
            }
            suggestionsArray = [...sortedSuggestions];
        }
        setSortedData(sortedSuggestions);
    }
    const handleToggle = () => {
        if (sortOption || sortOption2)
            filter()
        setIsOpen(!isOpen)
    }
    const openSuggestionImage = (data) => {
        setViewSuggestionImage(true)
        setPopupSuggestionImage(data.suggestionImage)
    }
    const closeSuggestionImage = () => {
        setViewSuggestionImage(false)
    }
    const closeDeleteSuggestion = () => {
        setDeleteSuggestionPost(false)
        setSuggId("")
    }
    const closeEditSuggestion = () => {
        setEditSuggestionPost(false)
        setSuggestionTitle("")
        setSuggestionDescription("")
        setTag("")
        setImage("")
        setSuggId("");
        setDefaultTags(defaultTags?.map((item) => {
            item.isSelected = false;
            return item
        }))
    }

    return (
        <div>
            {accessPopUp && <AccessPopUp active={accessPopUp} closePopUp={() => {
                setAccessPopUp(false);
                setDefaultTags(defaultTags?.map((item) => {
                    item.isSelected = false;
                    return item
                }))
            }} type={"Suggestion"} />}
            {popupSuggestion && <PopupSuggestionComments openSuggestionImage={openSuggestionImage} getSuggestions={getAllSuggestionsByProductId} closePopup={togglePopupSuggestion} data={popupSuggestionData} selectedValue={selectedValue} />}
            {viewSuggestionImage && <ViewSuggestionImage close={closeSuggestionImage} image={popupSuggestionImage} />}
            {deleteSuggestionPost && <DeleteSuggestionPopup closePopup={closeDeleteSuggestion} getSuggestions={getAllSuggestionsByProductId} data={suggId} selectedValue={selectedValue} />}
            {editSuggestionPost && <EditSuggestionPopup productId={productId} initialTags={defaultTags} closePopup={closeEditSuggestion} image={image} getSuggestions={getAllSuggestionsByProductId} suggId={suggId} title={suggestionTitle} postSuggestion={suggestionDescription} tag={tag} selectedValue={selectedValue} />}
            {openShareSuggestion && <OpenShareSuggestionPopup closePopup={toggleShareSuggestionPopup} getSuggestions={getAllSuggestionsByProductId} data={popupSuggestionData} selectedValue={selectedValue} />}
            <div className="mt-2">
                {localStorage.getItem('junkStore') && canReview ?
                    <div className={`feedback-inp ${reviewEnabled ? "flex items-center" : "hidden"}`} style={{ cursor: "pointer" }} onClick={addSuggestionPopup}>
                        <img src={suggestionImage} style={{ width: "100px", margin: "0 30px 0 40px" }} />
                        <div className="feedback-details">
                            <p className="text-2xl">Have an idea?</p>
                            <div className="create-post">
                                <span className="ml-4">Suggest a feature now!</span>
                                <div style={{ padding: "0 2px" }}>
                                    <img src={imageicon} style={{ width: "20px", float: "right", margin: "0 1rem" }} />
                                    <img src={linkicon} style={{ width: "20px", float: "right" }} />
                                </div>
                            </div>
                        </div>
                    </div> : ""}
                <Modal
                    size="xl"
                    show={addSuggestion}
                    onHide={() => setAddSuggestion(false)}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title >
                            <h1 className='cursor-pointer absolute top-4 right-10 text-lg font-normal' style={{ color: 'rgba(255, 160, 32, 1)' }} onClick={guidelines}>View Post Guidelines</h1>
                            <div className='flex items-baseline gap-2'>
                                <span className='h-12 w-20'>
                                    <svg viewBox="0 0 123 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M92.0658 60.8291L113.51 82.3076" stroke="#E6570E" stroke-width="9" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M113.559 60.8772L92.0176 82.2594" stroke="#E6570E" stroke-width="9" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M0.984108 17.8875C0.984108 27.6462 8.8951 35.5572 18.6538 35.5572C28.4125 35.5572 36.3235 27.6462 36.3235 17.8875C36.3235 8.12876 28.4125 0.217773 18.6538 0.217773C8.8951 0.217773 0.984108 8.12876 0.984108 17.8875Z" fill="#FFB858" />
                                        <path d="M31.1702 40.9355C34.893 42.9316 33.1965 46.0774 36.9194 48.0734C40.6422 50.0694 42.3387 46.9236 46.0615 48.9196C49.7844 50.9156 48.0879 54.0614 51.8108 56.0574C55.5336 58.0534 57.2301 54.9077 60.9529 56.9037C64.6758 58.8997 62.9793 62.0455 66.7021 64.0415" stroke="#E6570E" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M37.6181 29.5059C41.3409 31.5019 39.6445 34.6477 43.3673 36.6437C47.0902 38.6397 48.7866 35.4939 52.5095 37.4899C56.2323 39.4859 54.5359 42.6317 58.2587 44.6277C61.9815 46.6238 63.678 43.478 67.4009 45.474C71.1237 47.47 69.4272 50.6158 73.1501 52.6118" stroke="#E6570E" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M44.0659 18.0762C47.7887 20.0722 46.0922 23.218 49.8151 25.214C53.5379 27.21 55.2344 24.0642 58.9572 26.0602C62.6801 28.0562 60.9836 31.202 64.7065 33.1981C68.4293 35.1941 70.1258 32.0483 73.8486 34.0443C77.5715 36.0403 75.875 39.1861 79.5978 41.1821" stroke="#E6570E" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M120.872 56.3545C120.458 60.2882 117.543 67.7957 105.335 74.7004C91.5517 70.5692 86.8285 63.7463 85.3335 59.9837C84.7623 58.6335 84.6281 57.8594 84.6703 57.6163C84.1262 53.0165 87.5772 48.6056 92.481 48.2004C97.3849 47.7951 102.177 51.1254 102.721 55.7252C102.177 51.1254 105.628 46.7145 110.532 46.3093C115.436 45.904 120.228 49.2344 120.773 53.8341C121.033 53.8791 121.125 54.8963 120.872 56.3545Z" fill="#04C700" />
                                    </svg>

                                </span>
                                <h5 style={{ fontFamily: "poppins" }} className='text-orange font-semibold'>Create Suggestion</h5>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id={`tooltip-top`}>
                                            <div className='tool-text'>
                                                Public community post where you can suggest new features and updates<br />
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <span className="ml-2 i-icon"><img src={i} style={{ width: "20px" }} /></span>
                                </OverlayTrigger>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="-mt-8 mb-2 px-5">
                            <Form>
                                <Form.Control style={{ fontFamily: "poppins" }} type="text" name="suggestionTitle"
                                    value={suggestionTitle} placeholder="Suggestion Title"
                                    onChange={(e) => setSuggestionTitle(e.target.value)} />
                                <textarea
                                    className="mt-3 form-control"
                                    id="exampleFormControlTextarea1"
                                    style={{ fontFamily: "poppins", resize: "none" }}
                                    rows="5"
                                    maxLength={180}
                                    name="suggestionDescription"
                                    value={suggestionDescription}
                                    placeholder="Let us know your ideas"
                                    onChange={(e) => setSuggestionDescription(e.target.value)}
                                />
                                <div style={{ fontFamily: "poppins", fontSize: "12px" }}>{suggestionDescription.length}/180 characters</div>
                                <div className='flex items-start w-full py-4 gap-8'>
                                    <div className="w-1/2"> <AddImage2 onChangeImage={onChangeImage} /></div>
                                    <div className='w-1/2 h-[13rem] overflow-y-auto scrollbar-hide rounded-lg border-2 border-gray-500 p-2'>
                                        <h1 className='text-orange font-medium'>Select Tags</h1>
                                        <div className='flex flex-wrap gap-y-2 py-2'>
                                            {
                                                defaultTags.map((data, i) => (
                                                    <span className='cursor-pointer' onClick={(e) => handleTags(e, data.tag)}>
                                                        <Tag text={data.tag} color={data.isSelected ? '' : '#999'} />
                                                    </span>
                                                ))}
                                        </div>
                                    </div>
                                </div>

                            </Form>
                            <div className='w-full flex items-center justify-between'>
                                <div onClick={() => setAddSuggestion(false)} className=" text-orange font-medium text-lg cursor-pointer">Cancel</div>


                                <OverlayTrigger style={(suggestionTitle.length != 0) && (suggestionDescription.length != 0) ? { display: "none" } : { cursor: "pointer" }}
                                    placement="left"
                                    overlay={
                                        <Tooltip id={`tooltip-top`} style={(suggestionTitle.length != 0) && (suggestionDescription.length != 0) ? { display: "none" } : { cursor: "pointer" }}>
                                            {(suggestionTitle.length != 0) && (suggestionDescription.length != 0) ?
                                                <div style={{ display: "none" }}>

                                                </div>
                                                : <div className='tool-text'>
                                                    Please add title and description
                                                </div>
                                            }
                                        </Tooltip>
                                    }
                                >
                                    {(suggestionTitle.length != 0) && (suggestionDescription.length != 0) ?
                                        <div className='btn-outer transform translate-x-6'>
                                            <div className='btn-1' style={{ width: "120px", height: "30px" }}>
                                            </div>
                                            <Button
                                                variant="default"
                                                className="btn-2" style={{ width: "120px", height: "30px", lineHeight: "12px" }}
                                                onClick={postSuggestion}
                                            >Submit</Button>
                                        </div>
                                        :
                                        <div className='btn-outer transform translate-x-6'>
                                            <div className='btn-1' style={{ width: "120px", height: "30px" }}>
                                            </div>
                                            <Button
                                                disabled={true}
                                                variant="default"
                                                className="btn-2" style={{ width: "120px", height: "30px", lineHeight: "12px", color: '#222' }}
                                            >Submit</Button>
                                        </div>
                                    }
                                </OverlayTrigger>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <div className="feddback-company">
                    <div className="mt-1 mb-32">
                        <div className="rating-title">
                            <p className="rating-text">User Suggestions</p>
                            <div className='btn-outer'>
                                <div className='btn-1'>
                                </div>
                                <Dropdown className="btn-2 flex justify-end items-end" align="end" show={isOpen} onToggle={handleToggle}>
                                    <Dropdown.Toggle variant="default" className="text-white focus:outline-none focus:ring-0" >
                                        <span className="text-center text-lg">Sort & Filter <b><i className="fa fa-angle-down"></i></b></span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="border-orange-400 rounded-lg min-w-[15rem] z-20">
                                        <span className='text-black px-2 text-lg'>Sort</span>
                                        <div className="flex flex-col gap-1 text-balck font-medium px-2 mb-2">
                                            <div className='flex items-center gap-2'>
                                                <button
                                                    className={`rounded-sm w-3 h-3 focus:outline-none ${sortOption === "" ? 'bg-orange-600 text-white' : 'bg-gray-200'
                                                        }`}
                                                    onClick={() => setSortOption("")}
                                                >
                                                </button>
                                                <span>Default</span>
                                            </div>
                                            <div className='flex items-center gap-2'>
                                                <button
                                                    className={`rounded-sm w-3 h-3 focus:outline-none ${sortOption === "Latest to Oldest" ? 'bg-orange-600 text-white' : 'bg-gray-200'
                                                        }`}
                                                    onClick={() => setSortOption("Latest to Oldest")}
                                                >
                                                </button>
                                                <span>Latest to Oldest</span>
                                            </div>
                                            <div className='flex items-center gap-2'>
                                                <button
                                                    className={`rounded-sm w-3 h-3 focus:outline-none ${sortOption === "Oldest to Latest" ? 'bg-orange-600 text-white' : 'bg-gray-200'
                                                        }`}
                                                    onClick={() => setSortOption("Oldest to Latest")}
                                                >
                                                </button>
                                                <span>Oldest to Latest</span>
                                            </div>
                                        </div>
                                        <span className='text-black px-2 mt-4 text-lg'>Review Type</span>
                                        <div className="flex flex-col gap-1 text-black font-medium px-2 mb-4">
                                            <div className='flex items-center gap-2'>
                                                <button
                                                    className={`rounded-sm w-3 h-3 focus:outline-none ${sortOption2 === "Registered" ? 'bg-orange-600 text-white' : 'bg-gray-200'
                                                        }`}
                                                    onClick={() => setSortOption2("Registered")}
                                                >
                                                </button>
                                                <span>Registered Users</span>
                                            </div>
                                            <div className='flex items-center gap-2'>
                                                <button
                                                    className={`rounded-sm w-3 h-3 focus:outline-none ${sortOption2 === "Anonymous" ? 'bg-orange-600 text-white' : 'bg-gray-200'
                                                        }`}
                                                    onClick={() => setSortOption2("Anonymous")}
                                                >
                                                </button>
                                                <span>Anonymous Users</span>
                                            </div>
                                        </div>
                                        {/* <div className="flex justify-end mt-4 px-2">
                                        <span className="bg-orange-600 text-white rounded-lg text-sm p-1 cursor-pointer" onClick={filter}>Apply Filters</span>
                                    </div> */}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        {sortedData.length == 0 ?
                            <Card className="mt-2 border-none no_pages"><center className="no-details-text"><img src={downvoteb} className="no-pages-img" /><br />No Suggestions</center></Card>
                            :
                            sortedData.map((data, i) => (
                                <div className="my-8" key={i}>
                                    <Card className={data.status == 4 ? "suggestion-border-accepted mt-4" : (data.status == 2 ? "suggestion-border-pending mt-4" : (data.status == 3 ? "suggestion-border-rejected mt-4" : "suggestion-border mt-4"))}>
                                        <div className="suggestion-img-container">
                                            <img src={data.status == 4 ? suggestionAccepted : (data.status == 2 ? suggestionInprogress : (data.status == 3 ? suggestionRejected : null))} alt="" style={{ width: "40px" }} />
                                        </div>
                                        <div className="row py-2">
                                            <Col md={2}>
                                                <Card className="ml-2 h-full vote-bg rounded-[10px] min-h-[12rem] py-2 border-none">
                                                    <center className={localStorage.getItem('junkStore') ? "cursor" : ""}> {data.vote == 1 ? <img src={upvote} onClick={(e, i) => changeUpvoteImageSuggestion(data, 2)} style={{ width: "55px" }} /> : <img src={upvoteb} onClick={(e, i) => changeUpvoteImageSuggestion(data, 1)} style={{ width: "45px" }} />}</center><br />
                                                    <center className="likes">{data.totallike == null ? 0 : data.totallike}</center><br />
                                                    <center className={localStorage.getItem('junkStore') ? "cursor" : ""}> {data.vote == -1 ? <img src={downvote} onClick={(e, i) => changeDownvoteImageSuggestion(data, 2)} style={{ width: "55px" }} /> : <img src={downvoteb} onClick={(e, i) => changeDownvoteImageSuggestion(data, 0)} style={{ width: "45px" }} />}</center>
                                                </Card>
                                            </Col>
                                            <Col md={10}>
                                                <div className="card-padding flex flex-col w-full h-[90%]">
                                                    {data.pin ? <div className='flex items-center gap-2 -mt-2'>
                                                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M3.94073 13.7296L9.08947 6.21469L6.38797 4.95496L3.94073 13.7296Z" fill="black" />
                                                            <path d="M6.20129 8.88249C8.18696 9.80842 10.4574 9.14206 11.2725 7.39412C12.0876 5.64619 11.1386 3.47859 9.15294 2.55265C7.16726 1.62672 4.89681 2.29308 4.08173 4.04102C3.26666 5.78895 4.21561 7.95655 6.20129 8.88249Z" fill="#FFB858" />
                                                        </svg>
                                                        <p className='text-orange-300 text-sm'>Pinned post</p>
                                                    </div> : <></>}
                                                    <div className='mb-1' ><h6 className="t-today">{data.title}</h6>
                                                        {(data.userCreate) ?
                                                            <Dropdown className='z-40'>
                                                                <Dropdown.Toggle variant="default" style={{ float: "right" }} className="abc feed-card-a -mt-5 h-7">
                                                                    <img src={dots} style={{ width: "3px" }} />
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu className="border-orange-400 bg-white" align="end">
                                                                    <Dropdown.Item className="d-text" onClick={(e, i) => editSuggestion(data)} ><span className='flex gap-2'><img src={ImgEdit} style={{ width: "20px" }} /> &nbsp;Edit</span></Dropdown.Item>
                                                                    <Dropdown.Item className="d-text" onClick={(e, i) => deleteSuggestion(data)} style={{ color: "red" }}><span className='flex gap-2'><img src={imgDel} style={{ width: "20px" }} /> &nbsp;Delete</span></Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown> : null}
                                                    </div>
                                                    <div onClick={() => localStorage.getItem('junkStore') ? togglePopupSuggestion(data) : setState(true)} className={`max-w-[50vw] 2xl:max-w-[40vw] flex-grow py-2 ${localStorage.getItem('junkStore') ? "cursor" : ""}`}>
                                                        <div className="posted">Posted by {data.userName == null ? "anonymous" : data.userName} &nbsp;&nbsp;{moment(data.updatedAt).fromNow()} {moment(data.updatedAt).diff(moment(data.createdAt), 'minutes') > 1 ? <span>(Edited)</span> : ''}</div>
                                                        <div className="text-gray-800 py-2 font-medium" dangerouslySetInnerHTML={{
                                                            __html: data.suggestion
                                                        }} />
                                                        {
                                                            data?.tag?.length
                                                                ?
                                                                <div className='flex flex-wrap gap-x-3 gap-y-3 py-3'>
                                                                    {data?.tag?.map(tag => <Tag text={tag} />
                                                                    )}
                                                                </div>
                                                                : ''
                                                        }
                                                        {data.suggestionImage ? <div className={`${data.suggestionImage ? "my-2" : ""}`}><img src={data.suggestionImage} style={{ width: "200px", height: '150px', objectFit: 'contain', objectPosition: 'top' }} /></div> : ''}
                                                    </div>

                                                    <div className={`flex items-center mt-6 ${localStorage.getItem('junkStore') ? "cursor" : ""}`}>
                                                        <div onClick={() => localStorage.getItem('junkStore') ? togglePopupSuggestion(data) : setState(true)} className="flex items-center gap-x-2.5">
                                                            <img src={comment} style={{ width: "20px" }} className={localStorage.getItem('junkStore') ? "cursor" : ""} />
                                                            <span className="text-gray-400 font-medium" >{data.commentCount == null ? 0 : data.commentCount} Comments</span>
                                                        </div>
                                                        <ShareBox shareCount={data.shareCount} path={`/${data.companyName}/${data.productName}/${productType}`} type={"Suggestion"} postId={data.id} callback={() => { }} />
                                                    </div>
                                                </div>
                                            </Col>
                                        </div>
                                    </Card>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductSuggestionSection
import React from 'react';
import '../CompanyFeed/CompanyFeed.css';
import { Row, Col, Modal, Form, OverlayTrigger, Tooltip, FormControl, Card, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import upvoteb from '../images/upvote-b.svg';
import downvoteb from '../images/downvote-b.svg';
import upvote from '../images/upvote.svg';
import downvote from '../images/downvote.svg';
import comments from '../images/comments.svg';
import share from '../images/share.svg';
import fillTag from "../images/fill-tag.svg";
import lock from '../images/lock.png';
import profilepicture from '../images/profilepicture.png';
import axios from 'axios';
import { config } from '../Config/Config';
import { decrypt } from '../utils';

const list = ["adventurer", "adventurer-neutral", "croodles", "croodles-neutral", "avataaars", "bottts", "big-ears", "micah", "open-peeps", "miniavs", "identicon"]
class OpenShareFeedbackByAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            feedback: [],
            feedbackByUserId: "",
            allComments: [],
            comment: "",
            image: "",
            viewImage: false
        }


    }
    componentDidMount() {
        console.log("props", this.props)
    }
    shareArticle = () => {
        navigator.clipboard.writeText(`${window.location.origin}/company/productpage/${this.props.data.productName}?productId=${this.props.data.productId}`)
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "feedbackIdd": this.props.data.id,
            "userIdFeedbackBelongTo": this.props.data.feedbackByUserId
        }
        console.log("payload", payload)
        var self = this;
        axios.post(config.userUrl + 'admin/shareFeedbackByAdmin', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("feedback shared successfully", response.data.message, payload);
                    self.props.getAllFeedbacks(self.props.selectedValue);
                    self.props.closePopup()
                } else {
                    console.log("Error", payload);
                }
            }).catch(err => {
                console.log("error", err);
            });
    }

    render() {
        const { viewImage, uImg, dImg, count, comment, feedback } = this.state
        return (
            <div>
                <Modal
                    size="lg"
                    show={true}
                    onHide={this.props.closePopup}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    keyboard={false}
                >
                    <Modal.Header closeButton style={{ zIndex: "100", outline: "none" }}>
                    </Modal.Header>
                    <Modal.Body>
                    <div  style={{ marginTop: "-3.5rem",fontSize:"22px",fontWeight:"600" }}>Copy link to Share</div>
                        <Card className="border-none ">
                            <div style={{ display: 'flex' }}>
                                <div className="share_">{`${window.location.origin}/company/productpage/${this.props.data.productName}?productId=${this.props.data.productId}`}</div>
                                <span className="ml-3 mt-3" >
                                    <i onClick={this.shareArticle} class="fa fa-clipboard fa-2x cursor" style={{color:"#E6570E"}} aria-hidden="true"></i>
                                </span>
                            </div>
                        </Card>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default OpenShareFeedbackByAdmin;


import React, { Component } from "react";
import { Button, Form, Card, Row, Col } from "react-bootstrap";
import fedduplogo from "../images/fedduplogo.png";
import thanksforregistering from '../images/thanksforregistering.png';
import './Login.css';

export default class ForgotEmail extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="ml-5 mr-5 mt-5">
                <Row style={{ width: "100%" }}>
                    <Col md={4}>
                        <h4>Hello xxxx,</h4>
                        <p style={{ fontSize: "15px", fontFamily: "Poppins" }}><b>Forgot your password?</b><br /> No worries</p>
                        <p style={{ fontSize: "14px", fontFamily: "Poppins" }}>We’ve made it easy for you to access FeddUp again.<br />You can reset your password immediately by clicking here.</p>
                        <p style={{ fontSize: "14px", fontFamily: "Poppins" }}>Regards,<br /> Team</p>
                        <img src={fedduplogo} style={{ width: "20%" }} />
                    </Col>
                    <Col md={4}>
                        <img src={thanksforregistering} style={{ width: "80%" }} />
                    </Col>
                </Row><br />
            </div>
        )
    }
}
import React, { useEffect, useState } from 'react'
import NavBar1 from '../NavBar/NavBar1'
import arrowLeft from "../images/arrow-left-black.svg"
import axios from 'axios'
import { config } from '../Config/Config';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import uploaded from "../images/uploaded-tick.svg";
import deleteConfirmtion from "../images/delete-confirmation.svg"
import cancel from "../images/cancel-x-black.svg"
import ReactSelect from '../utils/ReSelect';
import useDebounce, { decrypt } from '../utils';

function ManageUsers() {
    const [companyId, setCompanyId] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [companyWebsite, setCompanyWebsite] = useState("")
    const [companyIcon, setCompanyIcon] = useState("")
    const [companyHeadquarter, setCompanyHeadquarter] = useState("")
    const [companyDescription, setCompanyDescription] = useState("")
    const [membershipType, setMembershipType] = useState("")
    const [adminName, setAdminName] = useState("")
    const [adminEmail, setAdminEmail] = useState("")
    const [item, setItem] = useState("Portal")
    const [searchInput, setSearchInput] = useState("")
    const [show, setShow] = useState(false);
    const [subAdminId, setSubAdminId] = useState("")
    const [subAdminName, setSubAdminName] = useState("")
    const [subAdminEmail, setSubAdminEmail] = useState("")
    const [moderatorId, setModeratorId] = useState("")
    const [moderatorName, setModeratorName] = useState("")
    const [moderatorEmail, setModeratorEmail] = useState("")
    const [feddupPages, setFeddupPages] = useState([])
    const [subAdmins, setSubAdmins] = useState([])
    const [moderators, setModerators] = useState([])
    const [showSuccess, setShowSuccess] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [confirmed, setConfirmed] = useState(false)
    const [filteredResults, setFilteredResults] = useState([])
    const [companyProducts, setCompanyProducts] = useState([])
    const [options, setOptions] = useState([])
    const [productIds, setProductIds] = useState([])
    const [nameError, setNameError] = useState("")
    const [emailError, setEmailError] = useState("")
    const [pagesError, setPagesError] = useState("");
    const [userRole, setUserRole] = useState('');
    const [isUpdated, setIsUpdated] = useState(false);

    const history = useHistory();

    useEffect(() => {
        let role = localStorage.getItem('role')?.toLowerCase();
        if (role)
            setUserRole(role)
        try {
            getCompanyDetails();
        } catch (error) {
            console.log(error);
        }
    }, [])

    useEffect(() => {
        if (isUpdated) {
            setTimeout(() => setIsUpdated(false), 2000)
        }
    }, [isUpdated])

    const verifyEmail = (email, role) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        axios.get(config.userUrl + `admin/validateEmail?search=${email}&role=${role}`, { headers: headers })
            .then((response) => {
                if (response?.data?.sameCompany) {
                    if (response.data?.valid) {
                        setEmailError(item == 'Portal' ? "This user is already a moderator in your company, cannot add as sub admin." : "This user is already a sub admin in your company, cannot add as moderator")
                    }
                }
                else {
                    setEmailError("User is already a part of an organization, cannot add it in yours now.")
                }
            }).catch((err) => {
                console.log(err)
            });
    };

    let debounced = useDebounce(item == 'Portal' ? subAdminEmail : moderatorEmail, 400)

    useEffect(() => {
        if (debounced && debounced?.includes('@')) {
            verifyEmail(debounced, item == 'Portal' ? 4 : 2)
        }
    }, [debounced])


    const handleClose = () => { setShow(false); setEmailError("") };
    const handleShow = () => setShow(true);
    let handleRemovePage = (pageToRemove) => {
        setOptions([...options, { label: pageToRemove.name, value: pageToRemove.id }])
        let filteredPages = feddupPages.filter((page) => page.id !== pageToRemove.id)
        let newProductIds = productIds.filter(product => product !== pageToRemove.id)
        setProductIds(newProductIds)
        setFeddupPages(filteredPages)
    };
    let handleChange = (option) => {
        const newPage = option.label;
        setPagesError('');
        let newOptions = options.filter((item) => item.value !== option.value)
        setOptions(newOptions);
        setProductIds([...productIds, option.value])
        setFeddupPages([...feddupPages, { name: option.label, id: option.value }])
    }
    const getCompanyDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'admin/getCompanyProfileDetailsByAdmin', { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    setCompanyName(response.data.companyDetail.companyName);
                    setCompanyWebsite(response.data.companyDetail.companyWebsite);
                    setCompanyIcon(response.data.companyDetail.companyIcon);
                    setCompanyHeadquarter(response.data.companyDetail.companyheadquarter);
                    setCompanyId(response.data.companyDetail.companyId);
                    setCompanyDescription(response.data.companyDetail.companyDescription);
                    setMembershipType(response.data.companyDetail.membershipType);
                    setAdminName(response.data.companyDetail.userName);
                    setAdminEmail(response.data.companyDetail.email);
                    getAllProducts(response.data.companyDetail.companyId)
                    getAllSubAdmins(response.data.companyDetail.companyId);
                    getAllModerators(response.data.companyDetail.companyId);
                } else {
                    console.log(response.data.message)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const getAllProducts = (companyId) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "companyId": companyId
        }
        axios.post(config.userUrl + 'admin/getProductIdsFromCompanyId', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("Product Ids retrieved successfully!!", response.data);
                    updateOptions(response.data.productIds)
                    setCompanyProducts(response.data.productIds)
                } else {
                    console.log(response.data.message)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const updateOptions = (products) => {

        let currentOptions = products.map((item) => {
            let obj = {
                value: item.id,
                label: item.productName
            }
            return obj;
        })
        setOptions(currentOptions)
    }
    const getAllSubAdmins = (companyId) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "companyId": companyId
        }
        axios.post(config.userUrl + "admin/getAllSubAdmins", payload, { headers: headers })
            .then((response) => {
                setSubAdmins(response.data.subadmins);
            }).catch((err) => {
                console.log(err)
            });
    }
    const getAllModerators = (companyId) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "companyId": companyId
        }
        axios.post(config.userUrl + "admin/getAllModerators", payload, { headers: headers })
            .then((response) => {
                setModerators(response.data.moderators);
            }).catch((err) => {
                console.log(err)
            });
    }
    const addSubAdmin = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "subAdminName": subAdminName,
            "email": subAdminEmail,
            "pages": feddupPages,
            "productIds": productIds
        }
        axios.post(config.userUrl + "admin/addSubAdmin", payload, { headers: headers })
            .then((response) => {
                handleClose()
                setSubAdmins([...subAdmins, response.data.newSubadmin])
                setProductIds([])
                setShowSuccess(true);
                setTimeout(() => {
                    setShowSuccess(false)
                }, 5000);
            }).catch((err) => {
                console.log(err)
            });
    };
    const editSubAdmin = (id) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "id": id,
            "subAdminName": subAdminName,
            "email": subAdminEmail,
            "pages": feddupPages,
            "productIds": productIds
        }
        axios.post(config.userUrl + "admin/editSubAdmin", payload, { headers: headers })
            .then((response) => {
                setShowEdit(false)
                setProductIds([])
                editSubAdminsArray(id);
                setIsUpdated(true)
            }).catch((err) => {
                console.log(err)
            });
    };
    const deleteSubAdmin = (id) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "id": id
        }
        axios.post(config.userUrl + "admin/deleteSubAdmin", payload, { headers: headers })
            .then((response) => {
                updateSubAdmins(id);
            }).catch((err) => {
                console.log(err)
            });
    };
    const updateSubAdmins = (id) => {
        let newSubAdminsArray = subAdmins.filter((subadmin) => subadmin.uuid !== id)
        setSubAdmins(newSubAdminsArray);
    }
    const editSubAdminsArray = (id) => {
        let index = 0;
        let newSubAdminArray = [...subAdmins];
        let editedItem = newSubAdminArray.find((subadmin, i) => {
            if (subadmin.uuid === id) {
                index = i; return subadmin;
            }
        });
        editedItem["pages"] = feddupPages?.map(page => page.name).toString();
        editedItem["userName"] = subAdminName;
        editedItem["email"] = subAdminEmail;
        editedItem["productIds"] = productIds
        newSubAdminArray[index] = editedItem;
        setSubAdmins(newSubAdminArray);
    }
    const updateModerators = (id) => {
        let newModeratorsArray = moderators.filter((moderator) => moderator.uuid !== id)
        setModerators(newModeratorsArray);
    }
    const editModeratorsArray = (id) => {
        let index = 0;
        let newModeratorsArray = [...moderators];
        let editedItem = newModeratorsArray.find((moderator, i) => {
            if (moderator.uuid === id) {
                index = i;
                return moderator;
            }
        });
        editedItem["pages"] = feddupPages?.map(page => page.name).toString();
        editedItem["userName"] = moderatorName;
        editedItem["email"] = moderatorEmail;
        editedItem["productIds"] = productIds
        newModeratorsArray[index] = editedItem;
        setModerators(newModeratorsArray);
    }
    const addModerator = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "moderatorName": moderatorName,
            "email": moderatorEmail,
            "pages": feddupPages,
            "productIds": productIds

        }
        axios.post(config.userUrl + "admin/addModerator", payload, { headers: headers })
            .then((response) => {
                handleClose();
                setModerators([...moderators, response.data.newModerator])
                setShowSuccess(true);
                setProductIds([]);
                setTimeout(() => {
                    setShowSuccess(false)
                }, 5000);
            }).catch((err) => {
                console.log(err)
            });
    }
    const editModerator = (id) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "id": id,
            "moderatorName": moderatorName,
            "email": moderatorEmail,
            "pages": feddupPages,
            "productIds": productIds
        }
        axios.post(config.userUrl + "admin/editModerator", payload, { headers: headers })
            .then((response) => {
                setShowEdit(false)
                setProductIds([]);
                editModeratorsArray(id);
                setIsUpdated(true)
            }).catch((err) => {
                console.log(err)
            });
    };
    const deleteModerator = (id) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "id": id
        }
        axios.post(config.userUrl + "admin/deleteModerator", payload, { headers: headers })
            .then((response) => {
                updateModerators(id);
            }).catch((err) => {
                console.log(err)
            });
    };

    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        const currentData = item === "Portal" ? subAdmins : moderators;
        if (searchValue !== '') {
            const filteredData = currentData.filter((item) => {
                return item["userName"].toLowerCase().includes(searchValue.toLowerCase())
            })
            setFilteredResults(filteredData)
        }
        else {
            setFilteredResults(currentData)
        }
    }
    let validateField = () => {
        let result = true;
        result = result && validateSubAdminName()
        result = result && validateEmail();
        result = result && validatePages();
        return result
    }
    let validateSubAdminName = () => {
        let nameErr = "";
        const value = item === "Portal" ? subAdminName : moderatorName;
        if (value.trim() === "") nameErr = `${item === "Portal" ? "Sub Admin" : "Moderator"} Name must be at least 3 characters long!`;
        else if (value.length < 3)
            nameErr = `${item === "Portal" ? "Sub Admin" : "Moderator"}  Name must be at least 3 characters long!`;
        setNameError(nameErr);
        return nameErr === "";
    }
    let validateEmail = () => {
        let emailErr = "";
        const value = item === "Portal" ? subAdminEmail : moderatorEmail;
        const regex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!regex.test(value)) emailErr = "Please Enter a valid email address"
        setEmailError(emailErr)
        return emailErr === '';
    }
    let validatePages = () => {
        let pageErr = "";
        if (feddupPages.length < 1) pageErr = "Please Enter atleast one feddup page"
        else if (feddupPages.length > 5) pageErr = "You can choose at max 5 pages"
        setPagesError(pageErr)
        return pageErr === '';
    }

    console.log(feddupPages, options, productIds)

    return (
        <div className='bg-gray-100 min-h-screen pb-16'>
            <NavBar1 />
            <div className='px-24 mt-20'>
                <div className='flex gap-4 items-center pt-4'>
                    <img src={arrowLeft} alt='' onClick={() => history.goBack()} className='cursor-pointer' />
                    <div className='bg-white flex items-center justify-center rounded-full w-32 h-32' style={{ width: "100px", height: "100px" }}>
                        <img src={companyIcon} alt='' className='object-fill w-auto h-auto' />
                    </div>
                    <span className='text-xl font-bold'>{companyName}</span>
                </div>
                <div className='max-w-5xl mx-28 mt-8'>
                    <div className='flex gap-6'>
                        <div className='btn-outer-section w-40'>
                            <div className={item == "Portal" ? "feed-card f-color btn-inner-section" : "feed-card-a f-color-g btn-inner-section"} onClick={() => setItem("Portal")}>Subadmin</div>
                        </div>
                        <div className='btn-outer-section w-40'>
                            <div className={item == "Moderators" ? "feed-card f-color btn-inner-section" : "feed-card-a f-color-g btn-inner-section"} onClick={() => setItem("Moderators")}>Moderators</div>
                        </div>
                    </div>
                    <div className='bg-white w-full mt-4 rounded-xl'>
                        <Row className='items-center px-6 py-4'>
                            <Col md={4} className='font-semibold'>
                                {adminName.charAt(0).toUpperCase() + adminName.slice(1)}
                            </Col>
                            <Col md={2} className='font-semibold'>
                                Admin
                            </Col>
                            <Col md={4} className='font-semibold'>
                                {adminEmail}
                            </Col>
                            <Col md={2}>
                                {/* {userRole == 'admin' && <Button
                                    variant="default" size="sm"
                                    type="submit"
                                    style={{ width: "100px", height: "30px", lineHeight: "10px", fontSize: "14px" }}
                                    className='bg-orange-400 rounded-lg text-white'
                                >
                                    Edit admin
                                </Button>} */}
                            </Col>
                        </Row>
                    </div>
                    <div className="mt-8 flex justify-between">
                        <input
                            type="text"
                            placeholder="Search"
                            value={searchInput}
                            onChange={(e) => searchItems(e.target.value)}
                            id="search"
                            className="abc form-control ml-0 bg-white rounded-full"
                            style={{ width: "460px", height: "45px", borderRadius: "25px", paddingLeft: "3rem" }}
                            aria-label="Search keyword"
                        />
                        {userRole != 'moderator' ?
                            userRole == 'admin'
                                ?
                                <div className='btn-outer'>
                                    <div className='btn-1' style={{ width: "150px", height: "35px" }}>
                                    </div>
                                    <Button
                                        variant="default" size="md"
                                        type="submit"
                                        className="btn-2 "
                                        style={{ width: "150px", height: "35px", lineHeight: "14px", fontSize: "14px" }}
                                        onClick={() => {
                                            if (item === "Portal") {
                                                setSubAdminName("");
                                                setSubAdminEmail("");

                                            } else {
                                                setModeratorEmail("");
                                                setModeratorName("");
                                            }
                                            setFeddupPages([]);
                                            handleShow()
                                        }}
                                    >
                                        {`Add ${item === "Portal" ? "sub admin" : "moderator"}`}
                                    </Button>
                                </div>
                                :
                                item != "Portal" && userRole == 'subadmin' ?
                                    <div className='btn-outer'>
                                        <div className='btn-1' style={{ width: "150px", height: "35px" }}>
                                        </div>
                                        <Button
                                            variant="default" size="md"
                                            type="submit"
                                            className="btn-2 "
                                            style={{ width: "150px", height: "35px", lineHeight: "14px", fontSize: "14px" }}
                                            onClick={() => {
                                                if (item === "Portal") {
                                                    setSubAdminName("");
                                                    setSubAdminEmail("");

                                                } else {
                                                    setModeratorEmail("");
                                                    setModeratorName("");
                                                }
                                                setFeddupPages([]);
                                                handleShow()
                                            }}
                                        >
                                            {`Add moderator`}
                                        </Button>
                                    </div>
                                    :
                                    ''
                            : ''}
                    </div>
                    <div className='bg-white mt-4 rounded-xl px-4'>
                        <Table responsive bordered={false}>
                            <thead>
                                <tr className=''>
                                    <th className='text-orange-600 underline underline-offset-4 pl-4'><span className="text-black">{item === "Portal" ? 'Sub Admin' : 'Moderator'}</span></th>
                                    <th className='text-orange-600 underline underline-offset-4 pl-4'><span className="text-black">Email</span></th>
                                    <th className='text-orange-600 underline underline-offset-4 pl-4'><span className="text-black">Pages</span></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {item === "Portal" ?
                                    searchInput.length > 1 ? filteredResults.map((subadmin, index) => {
                                        return (
                                            <tr className='font-semibold' key={index}>
                                                <td>{subadmin.userName}</td>
                                                <td>{subadmin.email}</td>
                                                <td>{subadmin.pages}</td>
                                                <td>
                                                    {userRole != 'moderator' && <Button
                                                        variant="default" size="sm"
                                                        type="submit"
                                                        style={{ width: "100px", height: "30px", lineHeight: "10px", fontSize: "14px" }}
                                                        className='bg-orange-400 rounded-lg text-white'
                                                        onClick={() => {
                                                            setSubAdminId(subadmin.uuid);
                                                            setAdminName(subadmin.userName);
                                                            setAdminEmail(subadmin.email);
                                                            setProductIds(subadmin.productIds);
                                                            setFeddupPages(() => {
                                                                let newfeddups = [];
                                                                subadmin.productIds.forEach(id => {
                                                                    var result = "";
                                                                    companyProducts.forEach(product => {
                                                                        if (product.id === id)
                                                                            result = { id: product.id, name: product.productName }
                                                                    })
                                                                    newfeddups.push(result);
                                                                })
                                                                let newOptions = options.filter(function (obj) {
                                                                    return !newfeddups.some(function (obj2) {
                                                                        return obj.value === obj2.id;
                                                                    });
                                                                });
                                                                setOptions(newOptions)
                                                                return newfeddups;
                                                            });
                                                            setShowEdit(true)
                                                        }}
                                                    >
                                                        Edit User
                                                    </Button>}
                                                </td>
                                            </tr>
                                        )
                                    })
                                        : subAdmins && subAdmins.map((subadmin, index) => {
                                            return (
                                                <tr className='font-semibold' key={index}>
                                                    <td>{subadmin.userName}</td>
                                                    <td>{subadmin.email}</td>
                                                    <td>{subadmin.pages}</td>
                                                    <td>
                                                        {userRole != 'moderator' && <Button
                                                            variant="default" size="sm"
                                                            type="submit"
                                                            style={{ width: "100px", height: "30px", lineHeight: "10px", fontSize: "14px" }}
                                                            className='bg-orange-400 rounded-lg text-white'
                                                            onClick={() => {
                                                                setSubAdminId(subadmin.uuid);
                                                                setSubAdminName(subadmin.userName);
                                                                setSubAdminEmail(subadmin.email);
                                                                setProductIds(subadmin.productIds);
                                                                setFeddupPages(() => {
                                                                    let newfeddups = [];
                                                                    subadmin.productIds.forEach(id => {
                                                                        var result = "";
                                                                        companyProducts.forEach(product => {
                                                                            if (product.id === id)
                                                                                result = { id: product.id, name: product.productName }
                                                                        })
                                                                        newfeddups.push(result);
                                                                    })
                                                                    let newOptions = options.filter(function (obj) {
                                                                        return !newfeddups.some(function (obj2) {
                                                                            return obj.value === obj2.id;
                                                                        });
                                                                    });
                                                                    setOptions(newOptions)
                                                                    return newfeddups;
                                                                });
                                                                setShowEdit(true)
                                                            }}
                                                        >
                                                            Edit User
                                                        </Button>}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    :
                                    searchInput.length > 1 ? filteredResults.map((moderator, index) => {
                                        return (
                                            <tr className='font-semibold' key={index}>
                                                <td>{moderator.userName}</td>
                                                <td>{moderator.email}</td>
                                                <td>{moderator.pages}</td>
                                                <td>
                                                    {userRole != 'moderator' && <Button
                                                        variant="default" size="sm"
                                                        type="submit"
                                                        style={{ width: "100px", height: "30px", lineHeight: "10px", fontSize: "14px" }}
                                                        className='bg-orange-400 rounded-lg text-white'
                                                        onClick={() => {
                                                            setModeratorId(moderator.uuid);
                                                            setModeratorName(moderator.userName);
                                                            setModeratorEmail(moderator.email);
                                                            setProductIds(moderator.productIds);
                                                            setFeddupPages(() => {
                                                                let newfeddups = [];
                                                                moderator.productIds.forEach(id => {
                                                                    var result = "";
                                                                    companyProducts.forEach(product => {
                                                                        if (product.id === id)
                                                                            result = { id: product.id, name: product.productName }
                                                                    })
                                                                    newfeddups.push(result);
                                                                })
                                                                let newOptions = options.filter(function (obj) {
                                                                    return !newfeddups.some(function (obj2) {
                                                                        return obj.value === obj2.id;
                                                                    });
                                                                });
                                                                setOptions(newOptions)
                                                                return newfeddups;
                                                            });
                                                            setShowEdit(true)
                                                        }}
                                                    >
                                                        Edit User
                                                    </Button>}
                                                </td>
                                            </tr>
                                        )
                                    }) :
                                        moderators && moderators.map((moderator, index) => {
                                            return (
                                                <tr className='font-semibold' key={index}>
                                                    <td>{moderator.userName}</td>
                                                    <td>{moderator.email}</td>
                                                    <td>{moderator.pages}</td>
                                                    <td>
                                                        {userRole != 'moderator' && <Button
                                                            variant="default" size="sm"
                                                            type="submit"
                                                            style={{ width: "100px", height: "30px", lineHeight: "10px", fontSize: "14px" }}
                                                            className='bg-orange-400 rounded-lg text-white'
                                                            onClick={() => {
                                                                setModeratorId(moderator.uuid);
                                                                setModeratorName(moderator.userName);
                                                                setModeratorEmail(moderator.email);
                                                                setProductIds(moderator.productIds);
                                                                setFeddupPages(() => {
                                                                    let newfeddups = [];
                                                                    let arr = [...new Set(moderator.productIds)]
                                                                   arr.forEach(id => {
                                                                        var result = "";
                                                                        companyProducts.forEach(product => {
                                                                            if (product.id === id)
                                                                                result = { id: product.id, name: product.productName?.trim() }
                                                                        })
                                                                        newfeddups.push(result);
                                                                    })
                                                                    let newOptions = options.filter(function (obj) {
                                                                        return !newfeddups.some(function (obj2) {
                                                                            return obj.value === obj2.id;
                                                                        });
                                                                    });
                                                                    setOptions(newOptions)
                                                                    return newfeddups;
                                                                });
                                                                setShowEdit(true)
                                                            }}
                                                        >
                                                            Edit User
                                                        </Button>}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>

            {/* Adding SubAdmins and Moderators */}
            <Modal show={show} onHide={() => { handleClose(); updateOptions(companyProducts); setProductIds([]) }} size="md" dialogClassName='modal-dialog-add' centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className='flex flex-col'>
                            <span className='text-orange-600 text-3xl'>{`Add ${item === "Portal" ? "Sub-admin" : "Moderators"}`}</span>
                            <span className='text-orange-400 text-base'>An email will be sent to the email address entered this can be changed after logging in the first time</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='flex flex-col -mt-8 form-group'>
                        <span className='text-lg font-semibold'>Name</span>
                        {nameError === "" ? <input type="text"
                            placeholder={`Enter ${item === "Portal" ? "Sub admin" : "Moderator"} name`}
                            value={item === "Portal" ? subAdminName : moderatorName}
                            onChange={(e) => { item === "Portal" ? setSubAdminName(e.target.value) : setModeratorName(e.target.value); setNameError("") }}
                            className="bg-white rounded-xl border-2 border-gray-300 px-4 mt-2"
                            style={{ width: "460px", height: "45px" }} />
                            : <input type="text"
                                placeholder={`Enter ${item === "Portal" ? "Sub admin" : "Moderator"} name`}
                                value={item === "Portal" ? subAdminName : moderatorName}
                                onChange={(e) => { item === "Portal" ? setSubAdminName(e.target.value) : setModeratorName(e.target.value); setNameError("") }}
                                className="bg-white rounded-xl border-2 border-red-600 px-4 mt-2"
                                style={{ width: "460px", height: "45px" }} />
                        }
                        <div>{nameError && (
                            <div className="error">{nameError}</div>
                        )}</div>
                    </div>
                    <div className='flex flex-col mt-2 form-group'>
                        <span className='text-lg font-semibold'>Email</span>
                        {emailError === "" ?
                            <input type="email"
                                required
                                placeholder={`Enter ${item === "Portal" ? "Sub admin" : "Moderator"} email address`}
                                value={item === "Portal" ? subAdminEmail : moderatorEmail}
                                onChange={(e) => { item === "Portal" ? setSubAdminEmail(e.target.value) : setModeratorEmail(e.target.value); setEmailError("") }}
                                className="bg-white rounded-xl border-2 border-gray-300 px-4 mt-2"
                                style={{ width: "460px", height: "45px" }} />
                            : <input type="email"
                                required
                                placeholder={`Enter ${item === "Portal" ? "Sub admin" : "Moderator"} email address`}
                                value={item === "Portal" ? subAdminEmail : moderatorEmail}
                                onChange={(e) => { item === "Portal" ? setSubAdminEmail(e.target.value) : setModeratorEmail(e.target.value); setEmailError("") }}
                                className="bg-white rounded-xl border-2 border-red-600 px-4 mt-2"
                                style={{ width: "460px", height: "45px" }} />}

                        <div>{emailError && (
                            <div className="error">{emailError}</div>
                        )}</div>
                    </div>
                    <div className="mt-3 max-w-xl form-group">
                        <label className="font-medium text-lg mb-0">Feddup Pages</label>
                        <p className="font-normal text-sm mb-2">{`Add ${item === "Portal" ? "sub admin" : "moderator"} to upto 5 feddup pages`}<span className='float-right font-medium text-base'>{feddupPages.length}/5</span></p>
                        {pagesError === "" ?
                            <div className="flex flex-wrap items-start p-2 rounded-lg border-2 border-gray-300 min-h-[120px] bg-white">
                                {feddupPages && feddupPages.map((page, i) => {
                                    return (
                                        <span key={i} className="mr-2 rounded-xl min-w-[80px] text-center px-2 text-black font-medium" style={{ backgroundColor: "#FFB858" }}>{page.name}<span className="float-right cursor-pointer pl-2" onClick={() => handleRemovePage(page)}><img src={cancel} alt='' /></span></span>
                                    )
                                })
                                }
                                {/* <input type="text" className="focus:outline-none" onKeyDown={handleKeyDown} placeholder="Enter Page" maxLength="20" /> */}
                                <ReactSelect options={options} handleChange={handleChange} />
                            </div> :
                            <div className="flex flex-wrap items-start p-2 rounded-lg border-2 border-red-600 min-h-[120px] bg-white">
                                {feddupPages && feddupPages.map((page, i) => {
                                    return (
                                        <span key={i} className="mr-2 rounded-xl min-w-[80px] text-center px-2 text-black font-medium" style={{ backgroundColor: "#FFB858" }}>{page.name}<span className="float-right cursor-pointer pl-2" onClick={() => handleRemovePage(page)}><img src={cancel} alt='' /></span></span>
                                    )
                                })
                                }
                                {/* <input type="text" className="focus:outline-none" onKeyDown={handleKeyDown} placeholder="Enter Page" maxLength="20" /> */}
                                <ReactSelect options={options} handleChange={handleChange} />
                            </div>}
                        <div>{pagesError && (
                            <div className="error">{pagesError}</div>
                        )}</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='btn-outer ml-auto'>
                        <div className='btn-1' style={{ width: "150px", height: "35px" }}>
                        </div>
                        <Button
                            variant="default" size="md"
                            type="submit"
                            className="btn-2 "
                            style={{ width: "150px", height: "35px", lineHeight: "14px", fontSize: "14px" }}
                            onClick={() => {
                                if (!!validateField()) {
                                    if (item === "Portal") addSubAdmin();
                                    else addModerator();
                                    updateOptions(companyProducts);
                                }
                            }
                            }
                        >
                            {`Add ${item === "Portal" ? "sub admin" : "moderator"}`}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

            {/* Editing SubAdmins && Moderators */}
            <Modal show={showEdit} onHide={() => { setShowEdit(false); updateOptions(companyProducts); setProductIds([]) }} size="md" dialogClassName='modal-dialog-add' centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className='flex flex-col'>
                            <span className='text-orange-600 text-3xl'>{`Edit ${item === "Portal" ? "Sub - admin" : "Moderators"}`}</span>
                            <span className='text-orange-400 text-base'>An email will be sent to the email address entered this can be changed after loggin in the first time</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='flex flex-col -mt-8'>
                        <span className='text-lg font-semibold'>Name</span>
                        {nameError === "" ? <input type="text"
                            placeholder={`Enter ${item === "Portal" ? "Sub admin" : "Moderator"} name`}
                            value={item === "Portal" ? subAdminName : moderatorName}
                            onChange={(e) => { item === "Portal" ? setSubAdminName(e.target.value) : setModeratorName(e.target.value); setNameError("") }}
                            className="bg-white rounded-xl border-2 border-gray-300 px-4 mt-2"
                            style={{ width: "460px", height: "45px" }} />
                            : <input type="text"
                                placeholder={`Enter ${item === "Portal" ? "Sub admin" : "Moderator"} name`}
                                value={item === "Portal" ? subAdminName : moderatorName}
                                onChange={(e) => { item === "Portal" ? setSubAdminName(e.target.value) : setModeratorName(e.target.value); setNameError("") }}
                                className="bg-white rounded-xl border-2 border-red-600 px-4 mt-2"
                                style={{ width: "460px", height: "45px" }} />
                        }
                        <div>{nameError && (
                            <div className="error">{nameError}</div>
                        )}</div>
                    </div>
                    <div className='flex flex-col mt-2'>
                        <span className='text-lg font-semibold'>Email</span>
                        {emailError === "" ?
                            <input type="email"
                                required
                                placeholder={`Enter ${item === "Portal" ? "Sub admin" : "Moderator"} email address`}
                                value={item === "Portal" ? subAdminEmail : moderatorEmail}
                                onChange={(e) => { item === "Portal" ? setSubAdminEmail(e.target.value) : setModeratorEmail(e.target.value); setEmailError("") }}
                                className="bg-white rounded-xl border-2 border-gray-300 px-4 mt-2"
                                style={{ width: "460px", height: "45px" }} />
                            : <input type="email"
                                required
                                placeholder={`Enter ${item === "Portal" ? "Sub admin" : "Moderator"} email address`}
                                value={item === "Portal" ? subAdminEmail : moderatorEmail}
                                onChange={(e) => { item === "Portal" ? setSubAdminEmail(e.target.value) : setModeratorEmail(e.target.value); setEmailError("") }}
                                className="bg-white rounded-xl border-2 border-red-600 px-4 mt-2"
                                style={{ width: "460px", height: "45px" }} />}

                        <div>{emailError && (
                            <div className="error">{emailError}</div>
                        )}</div>
                    </div>
                    <div className="mt-3 max-w-xl">
                        <label className="font-medium text-lg mb-0">Feddup Pages</label>
                        <p className="font-normal text-sm mb-2">Add sub admin to upto 5 feddup pages<span className='float-right font-medium text-base'>{feddupPages.length}/5</span></p>
                        {pagesError === "" ?
                            <div className="flex flex-wrap items-start p-2 rounded-lg border-2 border-gray-300 min-h-[120px] bg-white">
                                {feddupPages && feddupPages.map((page, i) => {
                                    return (
                                        <span key={i} className="mr-2 rounded-xl min-w-[80px] text-center px-2 text-black font-medium" style={{ backgroundColor: "#FFB858" }}>{page.name}<span className="float-right cursor-pointer pl-2" onClick={() => handleRemovePage(page)}><img src={cancel} alt='' /></span></span>
                                    )
                                })
                                }
                                {/* <input type="text" className="focus:outline-none" onKeyDown={handleKeyDown} placeholder="Enter Page" maxLength="20" /> */}
                                <ReactSelect options={options} handleChange={handleChange} />
                            </div> :
                            <div className="flex flex-wrap items-start p-2 rounded-lg border-2 border-red-600 min-h-[120px] bg-white">
                                {feddupPages && feddupPages.map((page, i) => {
                                    return (
                                        <span key={i} className="mr-2 rounded-xl min-w-[80px] text-center px-2 text-black font-medium" style={{ backgroundColor: "#FFB858" }}>{page.name}<span className="float-right cursor-pointer pl-2" onClick={() => handleRemovePage(page)}><img src={cancel} alt='' /></span></span>
                                    )
                                })
                                }
                                {/* <input type="text" className="focus:outline-none" onKeyDown={handleKeyDown} placeholder="Enter Page" maxLength="20" /> */}
                                <ReactSelect options={options} handleChange={handleChange} />
                            </div>}
                        <div>{pagesError && (
                            <div className="error">{pagesError}</div>
                        )}</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='flex justify-between w-full'>

                        <Button
                            variant="default" size="md"
                            type="submit"
                            className="btn-2 "
                            style={{ width: "150px", height: "35px", lineHeight: "14px", fontSize: "14px", color: "black" }}
                            onClick={() => {
                                setShowEdit(false)
                                setShowDeleteModal(true);
                            }
                            }
                        >
                            {`Delete ${item === "Portal" ? "Sub Admin" : "Mod"}`}
                        </Button>
                        <div className='btn-outer'>
                            <div className='btn-1' style={{ width: "150px", height: "35px" }}>
                            </div>
                            <Button
                                variant="default" size="md"
                                type="submit"
                                className="btn-2 "
                                style={{ width: "150px", height: "35px", lineHeight: "14px", fontSize: "14px" }}
                                onClick={() => {
                                    if (validateField()) {
                                        if (item === "Portal") editSubAdmin(subAdminId);
                                        else editModerator(moderatorId);
                                        updateOptions(companyProducts)
                                    }
                                }
                                }
                            >
                                Update
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>

            {/* Success Modal */}
            <Modal show={showSuccess} onHide={() => setShowSuccess(false)} size="md" centered >
                <Modal.Body>
                    <div className='flex flex-col gap-4 justify-center items-center'>
                        <img src={uploaded} alt='' />
                        <p className='text-3xl font-semibold text-orange-600'>Added Successfully</p>
                        <p className='text-lg text-orange-400'>Email has been sent with the temporary password</p>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title className='mx-auto'><img src={deleteConfirmtion} alt='' /></Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-auto'><div className="text-orange-600 text-xl font-semibold">{`Are you sure you want to delete ${item === "Portal" ? "Subadmin" : "Moderator"}`}</div></Modal.Body>
                <Modal.Footer className='flex justify-between'>
                    <Button
                        variant="default" size="sm"
                        type="submit"
                        style={{ width: "100px", height: "30px", lineHeight: "10px", fontSize: "14px" }}
                        className='bg-orange-400 rounded-lg text-white'
                        onClick={() => {
                            setShowDeleteModal(false);

                        }}>
                        No, Cancel
                    </Button>
                    <Button
                        variant="default" size="sm"
                        type="submit"
                        style={{ width: "100px", height: "30px", lineHeight: "10px", fontSize: "14px" }}
                        className='bg-orange-600 rounded-lg text-white'
                        onClick={() => {
                            setShowDeleteModal(false);
                            if (item === "Portal") deleteSubAdmin(subAdminId);
                            else deleteModerator(moderatorId);
                        }}>
                        Yes, Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                size='lg'
                show={isUpdated}
                onHide={() => setIsUpdated(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className='w-full flex flex-col items-center justify-center'>
                    <svg className='mt-10' width="176" height="176" viewBox="0 0 176 176" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_725_2659)">
                            <path d="M88.1145 175.859C136.439 175.859 175.615 136.684 175.615 88.3594C175.615 40.0345 136.439 0.859375 88.1145 0.859375C39.7896 0.859375 0.614502 40.0345 0.614502 88.3594C0.614502 136.684 39.7896 175.859 88.1145 175.859Z" fill="#E6570E" />
                            <path d="M41.2393 93.0469L78.7393 130.547L134.989 46.1719" stroke="#FFB858" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_725_2659">
                                <rect width="176" height="175" fill="white" transform="translate(0 0.859375)" />
                            </clipPath>
                        </defs>
                    </svg>
                    <h1 className='text-3xl font-medium text-orange mb-10 mt-4'>Updated Successfully</h1>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ManageUsers
export const help = [
    {
        id: 1,
        topic: "Topic 1",
        desc:"Posuere imperdiet at amet blandit dolor egestas enim. In maecenas eleifend lacus aliquet et?"
    },
    {
        id: 2,
        topic: "Topic 2",
        desc:"Posuere imperdiet at amet blandit dolor egestas enim. In maecenas eleifend lacus aliquet et?"
    },
    {
        id: 3,
        topic: "Topic 3",
        desc:"Posuere imperdiet at amet blandit dolor egestas enim. In maecenas eleifend lacus aliquet et?"
    },
    {
        id: 4,
        topic: "Topic 4",
        desc:"Posuere imperdiet at amet blandit dolor egestas enim. In maecenas eleifend lacus aliquet et?"
    },
    {
        id: 5,
        topic: "Topic 5",
        desc:"Posuere imperdiet at amet blandit dolor egestas enim. In maecenas eleifend lacus aliquet et?"
    },
    {
        id: 6,
        topic: "Topic 6",
        desc:"Posuere imperdiet at amet blandit dolor egestas enim. In maecenas eleifend lacus aliquet et?"
    }
]
import React, { useEffect, useState } from 'react'
import NavBar from '../NavBar/NavBar'
import axios from 'axios';
import { config } from '../Config/Config';
import background from '../images/background-img.svg'
import arrowLeft from "../images/arrow-left-black.svg"
import feddupRoundLogo from "../images/feddup-logo-round.svg"
import premiumRotated from "../images/premium-rotated.svg"
import camera from "../images/camera.svg";
import editIcon from "../images/edit-icon.svg"
import cancelWhite from "../images/cancel-x-white.svg";
import cancelBlack from "../images/cancel-x-black.svg";
import uploaded from "../images/uploaded-tick.svg";
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { decrypt, uploadImage } from '../utils';
import { useQuery } from 'react-query';

const getCompanyDetails = () => {
    let headers = {
        "Content-Type": 'application/json',
        "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
        "applicationType": "web"
    }
    return axios.get(config.userUrl + 'admin/getCompanyProfileDetailsByAdmin', { headers: headers })
}

function EditProduct() {
    const { productname, membershiptype, productid, tags } = useParams();
    const [productDescription, setProductDescription] = useState("")
    const [productIcon, setProductIcon] = useState("")
    const [productSearchTags, setProductSearchTags] = useState([])
    const [productTags, setProductTags] = useState([])
    const [productType, setProductType] = useState("")
    const [productWebsite, setProductWebsite] = useState("")
    const [productFaq, setProductFaq] = useState("")
    const [productSupport, setProductSupport] = useState("")
    const [pageType, setPageType] = useState("")
    const [tagError, setTagError] = useState("")
    const [searchTagError, setSearchTagError] = useState("")
    const [showSuccess, setShowSuccess] = useState(false)
    const [image, setImage] = useState("");
    const history = useHistory();
    const [deletePage, setDeletePage] = useState(false);
    const [guidelines, setGuideLines] = useState([]);
    const [isGuideline, setIsGuideline] = useState(false)
    const [isUpdated, setIsUpdated] = useState(false);
    const [initialGuides, setInitialGuides] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0);
        getProductDetails()
    }, [])
    useEffect(() => {
        if (tags) {
            const section = document.getElementById('tags-section');
            section.scrollIntoView({ behavior: 'smooth' });
        }
    }, [tags])


    useEffect(() => {
        if (isUpdated) {
            setTimeout(() => setIsUpdated(false), 3000)
        }
    }, [isUpdated])

    const { data: companyData } = useQuery("company-details", getCompanyDetails, { refetchOnWindowFocus: false, });


    const getProductDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            productId: productid
        }
        axios.post(config.userUrl + 'admin/getProductProfileDetailsByAdmin', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("Product details retrieved successfully!!", response.data)
                    setProductIcon(response.data.productDetail.productIcon)
                    setProductWebsite(response.data.productDetail.productWebsite)
                    setProductType(response.data.productDetail.productType)
                    setProductDescription(response.data.productDetail.productDescription)
                    setProductWebsite(response.data.productDetail.productWebsite)
                    setProductSearchTags(response.data.productDetail.searchTags?.split(","));
                    setProductFaq(response.data.productDetail.productFaq)
                    setProductSupport(response.data.productDetail.productHelp)
                    setPageType(response.data.productDetail.pageType);
                    setGuideLines(response.data.productDetail?.guidelines || [])
                    setInitialGuides(response.data.productDetail?.guidelines || [])
                    return axios.post(config.userUrl + "admin/getProductTags", payload, { headers: headers })

                } else {
                    console.log(response.data.message)
                }
            })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull product tags", response.data);
                    if (response.data.productTags?.tag)
                        setProductTags(response.data.productTags?.tag?.split(","))
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    }
    const deleteProductByAdmin = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            productId: productid
        }
        axios.post(config.userUrl + 'admin/deleteProductByAdmin', payload, { headers: headers })
            .then(function (response) {
                console.log(response.data)
                if (response.data.status === true) {
                    window.location.replace('/admindashboard')
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    }

    let handleRemoveTag = (tagToRemove) => {
        let filteredTags = productSearchTags.filter((tag) => tag !== tagToRemove)
        setProductSearchTags(filteredTags);
    };
    let handleKeyDown = (event) => {
        if (event.key === "Enter") {
            const newTag = event.target.value.trim();
            if (!newTag) {
                setSearchTagError("Seacrh tag should not be empty");
                event.preventDefault();
                return;
            }
            if (productSearchTags?.length === 15) {
                setSearchTagError("Cannot enter more than 15 search tags")
                event.preventDefault();
                return;
            }
            setProductSearchTags([...productSearchTags, newTag])
            event.preventDefault();
            event.target.value = "";
        }
    }
    let handleRemoveTag2 = (tagToRemove) => {
        let filteredTags = productTags.filter((tag) => tag !== tagToRemove)
        setProductTags(filteredTags);
    };
    let handleKeyDown2 = (event) => {
        if (event.key === "Enter") {
            const newTag = event.target.value.trim();
            if (!newTag) {
                setTagError("Product tag should not be empty")
                event.preventDefault();
                return;
            }
            if (productTags?.length === 7) {
                setTagError("Cannot enter more than 7 tags")
                event.preventDefault();
                return;
            }
            setProductTags([...productTags, newTag])
            event.preventDefault();
            event.target.value = "";
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let icon;
        if (image) {
            icon = await getImageUrl(image)
        } else {
            icon = productIcon;
        }
        let payload = {
            "productIcon": icon,
            "productname": productname,
            "productId": productid,
            "productDescription": productDescription,
            "productType": productType,
            "productWebsite": productWebsite,
            "productFaq": productFaq,
            "productSupport": productSupport,
            "productSearchTags": productSearchTags?.toString() || '',
            "productTags": productTags?.toString() || ''
        };
        axios.post(config.userUrl + 'admin/updateProductProfileDetails', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("Product details updated successfully", response.data)
                    setShowSuccess(true);
                    setTimeout(() => {
                        setShowSuccess(false);
                    }, 3000)
                } else {
                    console.log(response.data.message)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    }
    const updatePageType = (e) => {
        e.preventDefault();
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "productId": productid,
            "pageType": pageType
        };
        axios.post(config.userUrl + 'admin/updatePageType', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("Product details updated successfully", response.data)
                    setShowSuccess(true);
                    setTimeout(() => {
                        setShowSuccess(false);
                    }, 3000)
                } else {
                    console.log(response.data.message)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    }
    const updateProductGuidelines = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let payload = {
            "productId": productid,
            guidelines: guidelines?.filter((item) => item?.length > 0)
        };
        axios.post(config.userUrl + 'admin/updateProductGuidelines', payload, { headers: headers })
            .then(function (response) {
                setIsGuideline(false);
                setInitialGuides(guidelines)
                setIsUpdated(true)
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    }
    const getImageUrl = async () => {
        let img_url = await uploadImage(image);
        return img_url;
    }

    const onGuidelineChange = (i, value) => {
        let arr = [...guidelines]
        arr[i] = value
        setGuideLines(arr)
    }

    const removeGuideline = (i) => {
        let arr = [...guidelines]
        arr.splice(i, 1)
        setGuideLines(arr)
    }
    const addGuideline = () => {
        if (guidelines?.length < 10) {
            let arr = [...guidelines]
            arr.push('')
            setGuideLines(arr)
        }
    }

    return (
        <div>
            <NavBar />
            <div className="bg-gray-100 relative h-auto pb-9" >
                <img src={background} style={{ marginTop: "3rem", width: "100%" }} />
                <img src={arrowLeft} alt='' className='absolute top-[5vh] left-[10vw] cursor-pointer z-30' onClick={() => history.goBack()} />
                {/* <img src={editIcon} alt='' className='absolute top-[8vh] right-10' /> */}

                <Row className='relative min-w-full -top-28 flex items-start justify-center'>
                    <Col md={5} className='relative'>
                        <div className='absolute top-0 -left-40 w-36 h-36 flex flex-col items-center justify-center bg-white rounded-full group overflow-hidden cursor-pointer border-4 border-white shadow-md'>
                            {productIcon ? <>
                                <label htmlFor="fileInput" className='w-full h-full'>
                                    <div className='flex flex-col items-center justify-center w-36 h-36 productImageUpload'>
                                        <img src={image ? URL.createObjectURL(image) : productIcon} alt="" className="w-36 h-36 object-cover" />
                                        <div className='absolute inset-0 bg-black bg-opacity-40  cursor-pointer flex flex-col items-center justify-center'>
                                            <h1 className='text-lg text-white px-3 text-center'>Upload new logo</h1>
                                            <input id='fileInput' type='file' className="hidden" onChange={(e) => {
                                                setImage(e.target.files[0])
                                            }} />
                                        </div>
                                    </div>
                                </label>
                            </> :
                                <>
                                    <label htmlFor='fileInput' className="items-center px-4 text-center cursor-pointer ">
                                        <div className='flex flex-col items-center justify-center w-36 h-36'>
                                            <img src={image ? URL.createObjectURL(image) : camera} alt="" />
                                            <span className="text-xs text-center">Upload your<br />product logo</span>
                                            <input id='fileInput' type='file' className="hidden" onChange={(e) => {
                                                setImage(e.target.files[0])
                                            }} />
                                        </div>
                                    </label>
                                </>}
                        </div>
                        <Form onSubmit={handleSubmit}>
                            <Form.Control disabled placeholder='Product Name' className='border rounded bg-gray-300 text-gray-500 text-lg' value={productname} />
                            <Form.Group>
                                <div className="flex border px-2 py-1 w-full text-center rounded-md bg-white mt-2 text-black">
                                    <select
                                        className="focus:outline-none cursor-pointer w-full"
                                        value={productType === 1 ? "Desktop Application" : "Web Application"}
                                        id="productType"
                                        onChange={(e) => setProductType(e.target.value === "Desktop Application" ? 1 : 2)}
                                    >
                                        <option value="Desktop Application" >Desktop Application</option>
                                        <option value="Web Application" >Web Application</option>
                                    </select>
                                </div>
                            </Form.Group>
                            <Card className="border mt-2">
                                <textarea
                                    className="form-control text-black"
                                    id="exampleFormControlTextarea1"
                                    style={{ fontFamily: "poppins", resize: "none" }}
                                    rows="5"
                                    maxLength={280}
                                    value={productDescription}
                                    name="productDescription"
                                    onChange={(e) => setProductDescription(e.target.value)}
                                    placeholder="Product Description"
                                />
                            </Card>
                            <Form.Control placeholder='Enter Product Website' className='border rounded bg-white mt-2 placeholder-gray-500 text-black focus:text-black' value={productWebsite} onChange={(e) => setProductWebsite(e.target.value)} />
                            <Form.Label className='mb-0 mt-2 font-medium text-lg'>Product Id</Form.Label>
                            <Form.Control disabled placeholder='Product Id' className='border rounded bg-gray-300 text-gray-500 text-lg' value={productid} />
                            <Form.Label id="tags-section" className='mb-0 mt-2 font-medium text-lg'>FAQ Link</Form.Label>
                            <Form.Control placeholder='FAQ link' className='border rounded bg-white mt-2 placeholder-gray-500 text-black focus:text-black' value={productFaq} onChange={(e) => setProductFaq(e.target.value)} />
                            <Form.Label className='mb-0 mt-2 font-medium text-lg'>Support Link</Form.Label>
                            <Form.Control id="tags-section" placeholder='support link' className='border rounded bg-white mt-2 placeholder-gray-500 text-black focus:text-black' value={productSupport} onChange={(e) => setProductSupport(e.target.value)} />
                            <div className="mt-2">
                                <label className="font-medium text-lg mb-0">Product Search Tags</label>
                                <p className="font-normal text-sm mb-2">Tags will help out for searching and finding your product better. Type the tag and hit enter <span className='float-right font-medium text-base'>{productSearchTags?.length}/15</span></p>
                                <div className="flex flex-wrap items-start p-2 rounded border border-black min-h-[120px] bg-white w-full">
                                    {productSearchTags && productSearchTags.map((tag, i) => {
                                        return (
                                            <div key={i} className="flex justify-between items-center mr-2 rounded-xl min-w-[80px] text-center px-2" style={{ backgroundColor: "#FFB858" }}>{tag}<img className="float-right cursor-pointer w-5" src={cancelBlack} alt="" onClick={() => handleRemoveTag(tag)} /></div>
                                        )
                                    })
                                    }
                                    {productSearchTags?.length < 15 ? <input type="text" className="focus:outline-none" onKeyDown={handleKeyDown} placeholder="Enter Search Tag" maxLength="20" onChange={() => setSearchTagError("")} /> : ''}
                                </div>
                                <div>{searchTagError && (
                                    <div className="error">{searchTagError}</div>
                                )}</div>
                            </div>
                            <div className="mt-2">
                                <label className="font-medium text-lg mb-0">Product Tags</label>
                                <p className="font-normal text-xs mb-2">These tags will help users and admin while share post,filtering and sorting, and to give an overall overview of the post<span className='float-right font-medium text-base'>{productTags?.length || 0}/7</span></p>
                                <div className="flex flex-wrap items-start p-2 rounded border border-black min-h-[120px] bg-white w-full">
                                    {productTags && productTags.map((tag, i) => {
                                        return (
                                            <div key={i} className="flex justify-between items-center mr-2 rounded-xl min-w-[80px] text-center text-white px-2" style={{ backgroundColor: "#E6570E" }}>{tag}<img src={cancelWhite} alt="" className="float-right cursor-pointer w-5" onClick={() => handleRemoveTag2(tag)} /></div>
                                        )
                                    })
                                    }
                                    {(productTags?.length || 0) < 7 ? <input type="text" className="focus:outline-none" onKeyDown={handleKeyDown2} placeholder="Enter Tag" maxLength="20" onChange={() => setTagError("")} /> : ''}
                                </div>
                                <div>{tagError && (
                                    <div className="error">{tagError}</div>
                                )}</div>
                            </div>
                            <div className='btn-outer float-right my-4'>
                                <div className='btn-1 ml-auto' style={{ width: "120px", height: "35px" }}>
                                </div>
                                <Button
                                    variant="default" size="md"
                                    type="submit"
                                    style={{ float: "right", width: "120px", height: "35px", lineHeight: "15px", fontSize: "14px" }}
                                    className="btn-2 mr-[1px]"
                                >
                                    Update Page
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <hr className='bg-orange-600 -mt-20 w-[50rem] mx-auto rounded-sm h-[1px]' />
                {companyData?.data?.companyDetail?.membershipType == "Premium" ?
                    <div className='flex max-w-[50rem] gap-2' style={{ marginLeft: "20rem" }}>
                        <img src={premiumRotated} alt='' />
                        <div className='flex flex-col mt-8'>
                            <p className='text-xl font-medium'>Upgrade Page</p>
                            <p className='text-lg font-normal'>You are on a free Organization account. Upgrade to premium and use Feddup to the fullest...</p>
                            <div className='flex justify-around'>
                                <div className='btn-outer float-right my-4'>
                                    <div className='btn-1 ml-auto' style={{ width: "200px", height: "35px" }}>
                                    </div>
                                    <Button
                                        variant="default" size="lg"
                                        type="submit"
                                        style={{ float: "right", width: "200px", height: "35px", lineHeight: "15px", fontSize: "14px" }}
                                        className="btn-2 mr-[1px]"
                                    >
                                        View feature & pricing
                                    </Button>
                                </div>
                                <div className='btn-outer float-right my-4'>
                                    <div className='btn-1 ml-auto' style={{ width: "200px", height: "35px" }}>
                                    </div>
                                    <Button
                                        variant="default" size="lg"
                                        type="submit"
                                        style={{ float: "right", width: "200px", height: "35px", lineHeight: "14px", fontSize: "12px" }}
                                        className="btn-2 mr-[1px]"
                                    >
                                        Update Your Organization
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div> : ""}

                {<hr className="bg-orange-600 w-[50rem] mx-auto rounded-sm h-[1px]" />}
                <div className='flex flex-col max-w-max mx-auto my-10 justify-center'>
                    <p className='text-2xl font-semibold'>Community guidelines</p>

                    <button onClick={() => setIsGuideline(true)} className='bg-orange text-white text-lg py-2 text-center w-full max-w-lg font-medium rounded-xl my-3 focus:outline-orange-400'>
                        Edit Community guidelines
                    </button>
                    <p className='w-[34rem] text-left'>User will have to adhere to the rules you set for posting on your pages.
                        <br />You can set up to 10 rules.<br />
                        If not followed admin or moderators can remove the post from the page.</p>

                </div>

                {companyData?.data?.companyDetail?.membershipType === "Premium" && <hr className="bg-orange-600  w-[50rem] mx-auto rounded-sm h-[1px]" />}


                {companyData?.data?.companyDetail?.membershipType == "Premium" && <div className='flex flex-col max-w-[50rem] mt-4 justify-center' style={{ marginLeft: "30rem" }}>
                    <p className='text-2xl font-semibold mb-4'>Change Page Type</p>
                    <div className="flex border p-2 w-[40rem] text-xl text-center rounded-xl bg-white">
                        <select
                            className="font-medium focus:outline-none w-[38rem] cursor-pointer"
                            id="type"
                            value={pageType}
                            onChange={(e) => setPageType(e.target.value)}
                        >
                            <option value="Public">Public</option>
                            <option value="Private">Private</option>
                        </select>
                    </div>
                    <p className='w-[40rem] text-center'>Public pages: Listed page, Users can view contents with out following the page and post anonymously.
                        Private pages: These pages cannot be searched and posting anonymously is not allowed.
                        Converting a page to private from public will remove all followers</p>
                    <div className='btn-outer ml-auto my-4 mr-28'>
                        <div className='btn-1 ml-auto' style={{ width: "150px", height: "35px" }}>
                        </div>
                        <Button
                            variant="default" size="lg"
                            type="submit"
                            style={{ float: "right", width: "150px", height: "35px", lineHeight: "14px", fontSize: "18px" }}
                            className="btn-2 mr-[1px]"
                            onClick={updatePageType}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>}
                <hr className='bg-orange-600  w-[50rem] mx-auto rounded-sm h-[1px]' />
                <div className='flex flex-col max-w-[50rem] mt-4 justify-center' style={{ marginLeft: "30rem" }}>
                    <p className='text-2xl font-semibold mb-4'>Change Page status</p>
                    <div className='flex flex-col'>
                        <p className='text-lg font-semibold'>Deactivate Page</p>
                        <div className='flex items-center gap-20'>
                            <p className='text-md font-medium w-[35rem] text-gray-700'>The page with all its posts and followers remain, page will become unlisted until reactivated, users will not be able to post on page.
                                Admin will be able to reactivate page by revisiting from dashboard.</p>
                            <Button
                                variant="default" size="md"
                                type="submit"
                                style={{ float: "right", width: "120px", height: "35px", lineHeight: "14px", fontSize: "18px" }}
                                className='bg-orange-400 rounded-xl text-white'
                            >
                                Deactivate
                            </Button>
                        </div>
                    </div>
                    <div className='flex flex-col mt-4'>
                        <p className='text-lg font-semibold'>Delete Page</p>
                        <div className='flex items-center gap-20'>
                            <p className='text-md font-medium w-[35rem] text-gray-700'>All of the page’s content will be deleted.
                                You will be able to use the name again but the connect your application must be made again</p>
                            <Button
                                onClick={() => setDeletePage(true)}
                                variant="default" size="md"
                                type="submit"
                                style={{ float: "right", width: "120px", height: "35px", lineHeight: "14px", fontSize: "18px" }}
                                className='bg-orange-600 rounded-xl text-white'
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showSuccess} onHide={() => setShowSuccess(false)} size="md" centered >
                <Modal.Body>
                    <div className='flex flex-col gap-4 justify-center items-center'>
                        <img src={uploaded} alt='' />
                        <p className='text-2xl font-semibold text-orange-600'>Product details updated successfully</p>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                size='lg'
                show={deletePage}
                onHide={() => setDeletePage(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body className='w-full'>

                    <div className='flex flex-col'>
                        <div className='flex flex-col items-center justify-center flex-grow pt-10 pb-16'>
                            <svg width="177" height="175" viewBox="0 0 177 175" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_2081_3068)">
                                    <path d="M89.0059 175C137.331 175 176.506 135.825 176.506 87.5C176.506 39.1751 137.331 0 89.0059 0C40.6809 0 1.50586 39.1751 1.50586 87.5C1.50586 135.825 40.6809 175 89.0059 175Z" fill="#FFB858" />
                                    <path d="M34.7532 87.5L143.258 87.5" stroke="#E6570E" stroke-width="15" stroke-linecap="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2081_3068">
                                        <rect width="176" height="175" fill="white" transform="translate(0.891357)" />
                                    </clipPath>
                                </defs>
                            </svg>


                            <div className="mt-3 text-3xl font-medium text-orange">Are you sure you want to delete page?</div>
                            <p className='text-lg text-orange-300 mt-3'>Page date will be lost. You can create page again</p>
                        </div>

                        <div className='flex items-center justify-between w-full '>
                            <button className='py-1.5 px-4 rounded-lg text-white font-medium text-lg bg-orange-300' onClick={() => setDeletePage(false)}>No, Cancel</button>
                            <button className='py-1.5 px-4 rounded-lg text-white font-medium text-lg bg-orange' onClick={deleteProductByAdmin}>Yes, Delete Page</button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>

            <Modal
                size='lg'
                show={isUpdated}
                onHide={() => setIsUpdated(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className='w-full flex flex-col items-center justify-center'>
                    <svg className='mt-10' width="176" height="176" viewBox="0 0 176 176" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_725_2659)">
                            <path d="M88.1145 175.859C136.439 175.859 175.615 136.684 175.615 88.3594C175.615 40.0345 136.439 0.859375 88.1145 0.859375C39.7896 0.859375 0.614502 40.0345 0.614502 88.3594C0.614502 136.684 39.7896 175.859 88.1145 175.859Z" fill="#E6570E" />
                            <path d="M41.2393 93.0469L78.7393 130.547L134.989 46.1719" stroke="#FFB858" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_725_2659">
                                <rect width="176" height="175" fill="white" transform="translate(0 0.859375)" />
                            </clipPath>
                        </defs>
                    </svg>
                    <h1 className='text-3xl font-medium text-orange mb-10 mt-4'>Guidelines Updated Successfully</h1>
                </Modal.Body>
            </Modal>

            {isGuideline && <div className='fixed inset-0 grid place-items-center' style={{ zIndex: 13000 }}>
                <div className='fixed inset-0 bg-black bg-opacity-50' onClick={() => { setIsGuideline(false); setGuideLines(initialGuides) }}>
                </div>
                <div className='px-5 py-4 bg-white rounded-lg w-full max-w-5xl min-h-[650px] z-10 flex flex-col opacTranslate'>
                    <div className='flex items-center justify-between'>
                        <h1 className='text-2xl font-medium'>Community guidelines</h1>
                        {guidelines?.length ? <Button
                            variant="default" size="lg"
                            type="submit"
                            style={{ float: "right", width: "150px", height: "35px", lineHeight: "14px", fontSize: "18px" }}
                            className="text-white bg-orange"
                            onClick={() => setGuideLines([])}
                        >
                            Clear All
                        </Button> : ''}
                    </div>
                    <div className='flex-grow space-y-2 pt-3 pb-4'>
                        {
                            guidelines.map((item, i) => {
                                return (
                                    <div className='flex items-center gap-2 w-full'>
                                        <h1 className='text-xl font-medium w-5 text-right'>{i + 1}</h1>
                                        <div className='flex items-center w-full gap-2 rounded-lg px-2' style={{ background: 'rgba(230, 87, 14, 0.12)' }}>
                                            <input type="text" value={item} onChange={(e) => onGuidelineChange(i, e.target.value)} className='flex-grow p-2 bg-transparent focus:outline-none' />
                                            <span className='cursor-pointer' onClick={() => removeGuideline(i)}>
                                                <svg width="35" height="32" viewBox="0 0 35 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M32.8965 1.80029H1.64648" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {
                            guidelines?.length < 10
                                ?
                                <div className='flex justify-center pt-3'>
                                    <button className='bg-orange text-white py-2 px-6 text-sm focus:outline-orange-400 rounded-lg' onClick={() => addGuideline()}>Add Guideline</button>
                                </div>
                                :
                                <></>
                        }
                    </div>
                    <div className='flex items-center justify-between'>
                        <Button
                            variant="default" size="lg"
                            type="submit"
                            style={{ float: "right", width: "150px", height: "35px", lineHeight: "14px", fontSize: "18px" }}
                            className="bg-orange-300 font-medium text-orange"
                            onClick={() => { setIsGuideline(false); setGuideLines(initialGuides) }}
                        >
                            Cancel
                        </Button>
                        <div className='btn-outer'>
                            <div className='btn-1 ml-auto' style={{ width: "150px", height: "35px" }}>
                            </div>
                            <Button
                                variant="default" size="lg"
                                type="submit"
                                style={{ float: "right", width: "150px", height: "35px", lineHeight: "14px", fontSize: "18px" }}
                                className="btn-2 mr-[1px]"
                                onClick={() => updateProductGuidelines()}
                            >
                                Update
                            </Button>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default EditProduct



import React from 'react'
import { Modal } from 'react-bootstrap'

function AccessPopUp({ active, type, closePopUp }) {
    return (
        <Modal
            size="lg"
            show={active}
            onHide={() => closePopUp()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName='add-rating-modal'
            
        >
            <Modal.Body style={{background: '#FFC373'}}>
                <section className='min-h-[450px] flex flex-col items-center justify-center bg-[#FFC373]'>
                    <svg width="215" height="127" viewBox="0 0 215 127" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M95.3918 1H32.8918V63.5H95.3918V1Z" fill="#E6570E" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M80.2481 51.5881C80.2481 42.9555 73.1694 35.8768 64.5368 35.8768C55.9042 35.8768 48.8254 42.9555 48.8254 51.5881C58.8393 46.7538 70.4069 46.7538 80.2481 51.5881Z" fill="black" />
                        <path d="M52.4511 28.7978C54.9303 28.7978 56.9401 26.788 56.9401 24.3088C56.9401 21.8297 54.9303 19.8199 52.4511 19.8199C49.9719 19.8199 47.9622 21.8297 47.9622 24.3088C47.9622 26.788 49.9719 28.7978 52.4511 28.7978Z" fill="black" />
                        <path d="M46.408 17.0569C46.408 17.0569 54.5226 15.6757 59.5295 25.3442" stroke="black" stroke-width="2.8346" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M78.0031 28.7978C80.4823 28.7978 82.4921 26.788 82.4921 24.3088C82.4921 21.8297 80.4823 19.8199 78.0031 19.8199C75.5239 19.8199 73.5142 21.8297 73.5142 24.3088C73.5142 26.788 75.5239 28.7978 78.0031 28.7978Z" fill="black" />
                        <path d="M84.0459 17.0569C84.0459 17.0569 75.9312 15.6757 70.9243 25.3442" stroke="black" stroke-width="2.8346" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M64.1416 63.5H1.6416V126H64.1416V63.5Z" fill="#E6570E" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M48.6028 114.088C48.6028 105.456 41.5241 98.3768 32.8915 98.3768C24.2589 98.3768 17.1802 105.456 17.1802 114.088H48.6028Z" fill="black" />
                        <path d="M13.5547 76.1039L22.7052 85.4271" stroke="black" stroke-width="3.9997" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M22.7052 76.1039L13.5547 85.4271" stroke="black" stroke-width="3.9997" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M42.9055 76.1039L52.2287 85.4271" stroke="black" stroke-width="3.9997" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M52.2287 76.1039L42.9055 85.4271" stroke="black" stroke-width="3.9997" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M126.642 63.5H64.1416V126H126.642V63.5Z" fill="#E6570E" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M84.1691 85.0818C86.6483 85.0818 88.6581 83.072 88.6581 80.5928C88.6581 78.1137 86.6483 76.1039 84.1691 76.1039C81.6899 76.1039 79.6802 78.1137 79.6802 80.5928C79.6802 83.072 81.6899 85.0818 84.1691 85.0818Z" fill="black" />
                        <path d="M106.442 85.0818C108.921 85.0818 110.931 83.072 110.931 80.5928C110.931 78.1137 108.921 76.1039 106.442 76.1039C103.962 76.1039 101.953 78.1137 101.953 80.5928C101.953 83.072 103.962 85.0818 106.442 85.0818Z" fill="black" />
                        <path d="M110.24 99.4105C108.168 105.626 102.298 110.115 95.3915 110.115C88.4854 110.115 82.6153 105.626 80.5435 99.4105" stroke="black" stroke-width="2.8346" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M213.289 63.5H150.789V126H213.289V63.5Z" fill="#E6570E" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M197.751 98.3768C197.751 100.103 197.406 101.83 196.888 103.384C194.816 109.599 188.946 114.088 182.04 114.088C175.134 114.088 169.264 109.599 167.192 103.384C166.674 101.83 166.329 100.103 166.329 98.3768H197.751Z" fill="black" stroke="black" stroke-width="5.6693" stroke-miterlimit="10" stroke-linecap="round" />
                        <path d="M170.818 85.0818C173.297 85.0818 175.307 83.072 175.307 80.5928C175.307 78.1137 173.297 76.1039 170.818 76.1039C168.338 76.1039 166.329 78.1137 166.329 80.5928C166.329 83.072 168.338 85.0818 170.818 85.0818Z" fill="black" />
                        <path d="M193.261 85.0818C195.741 85.0818 197.75 83.072 197.75 80.5928C197.75 78.1137 195.741 76.1039 193.261 76.1039C190.782 76.1039 188.772 78.1137 188.772 80.5928C188.772 83.072 190.782 85.0818 193.261 85.0818Z" fill="black" />
                        <path d="M213.29 1H150.79V63.5H213.29V1Z" fill="#E6570E" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M197.751 35.8768C197.751 37.6033 197.406 39.3298 196.888 40.8837C194.816 47.0991 188.946 51.5881 182.04 51.5881C175.134 51.5881 169.264 47.0991 167.192 40.8837C166.674 39.3298 166.329 37.6033 166.329 35.8768H197.751Z" fill="black" stroke="black" stroke-width="5.6693" stroke-miterlimit="10" stroke-linecap="round" />
                        <path d="M179.968 10.3724C180.14 13.1348 179.104 18.6597 172.026 24.8752C162.703 23.6666 158.904 19.523 157.523 17.1058C157.005 16.2426 156.832 15.7246 156.832 15.552C155.969 12.4442 157.696 8.99119 160.803 8.12793C163.911 7.26467 167.364 8.99119 168.227 12.0989C167.364 8.99119 169.091 5.53815 172.198 4.67489C175.306 3.81163 178.759 5.53815 179.622 8.64588C179.795 8.64588 179.968 9.33649 179.968 10.3724Z" fill="black" />
                        <path d="M184.111 10.3723C183.939 13.1347 184.975 18.6596 192.053 24.8751C201.376 23.6665 205.175 19.5229 206.556 17.1057C207.074 16.2425 207.247 15.7245 207.247 15.5519C208.11 12.4441 206.383 8.9911 203.276 8.12784C200.168 7.26458 196.715 8.9911 195.852 12.0988C196.715 8.9911 194.988 5.53806 191.881 4.6748C188.773 3.81154 185.32 5.53806 184.457 8.64579C184.284 8.64579 184.111 9.3364 184.111 10.3723Z" fill="black" />
                        <path d="M197.751 35.8768C197.751 37.6033 197.406 39.3298 196.888 40.8837H167.192C166.674 39.3298 166.329 37.6033 166.329 35.8768H197.751Z" fill="white" />
                    </svg>


                    <h1 className='text-gray-900 text-3xl font-bold mt-4'>You have  already given  your review</h1>
                    <h1 className='text-xl font-medium my-1'>You can provide your rating and review in the next version...</h1>
                    <p>You can still post in feddbacks and suggestions!</p>
                </section>
            </Modal.Body>
        </Modal >
    )
}

export default AccessPopUp
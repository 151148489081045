import React, { Component } from "react";
import { Button, Form, Card } from "react-bootstrap";
import { Link,Redirect } from "react-router-dom";
import fedduplogo from "../images/fedduplogo.png";
import forgotImg from '../images/forgot-screen-img.png';
import './Login.css';

export default class CheckEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            registerFlow: false,
            redirect: false
        }
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    render() {
        const { redirect } = this.state
        if (redirect) {
            return <Redirect
                to={{
                    pathname: "/verify",
                    state: { email: this.state.email, registerFlow: this.state.registerFlow }
                }} />
        }
        return (
            <div>
                <a href="/"><img alt="" src={fedduplogo} className="logo-login" /></a>
                <center><Card style={{ width: "30%" }}> <center>
                    <p className="forgot-text">Check Your Email</p>
                    <p className="forgot-text-sm">we've sent an email to reset the password</p><img alt="" src={forgotImg} className="forgotImg" /></center><br /><br />
                    <Form className="forgot-email">
                        <center>
                            <div style={{ width: "80%" }}>
                                <Link to="/login" className="link">
                                    <Button
                                        variant="default" size="md" block
                                        type="submit"
                                        className="login_button"
                                    >
                                        Go Back To Login
                                    </Button></Link><br />
                            </div><br />
                            <div className="forgot-text-sm">Did not receive the mail? Check your spam folder<br /><Link to="/forgotpassword"><span className="login-here">or submit email again</span></Link></div>

                        </center>
                    </Form><br />
                </Card>
                </center>
            </div>
        );
    }
}

import React, { useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { Route, Switch, Redirect } from 'react-router-dom';
import Login from '../src/Login/Login';
import ProductDetail from './AdminFeed/ProductDetail';
import AdminFeed from './AdminFeed/AdminFeed';
import CreateAnnouncement from './AdminFeed/CreateAnnouncement';
import CompanyFeed from './CompanyFeed/CompanyFeed';
import SearchResults from './CompanyFeed/SearchResults';
import ContentPolicy from './LandingPage/ContentPolicy';
import LandingPage from "./LandingPage/LandingPage"
import AdminLandingPage from './AdminFeed/AdminLandingPage';
import ModeratorsGuidelines from './LandingPage/ModeratorsGuidelines';
import ChangePassword from './Login/ChangePassword';
import CheckEmail from './Login/CheckEmail';
import EmailInvitation from './Login/EmailInvitation';
import EmailNotVerified from './Login/EmailNotVerified';
import EmailSent from './Login/EmailSent';
import ForgotEmail from './Login/ForgotEmail';
import ForgotPassword from './Login/ForgotPassword';
import SuccessResetPassword from './Login/SuccessResetPassword';
import ThanksForRegistering from './Login/ThanksForRegistering';
import VerifyOtp from './Login/VerifyOtp';
import WelcomeFeddup from './Login/WelcomeFeddup';
import ActivateAccount from './Profile/ActivateAccount';
import AdminProfile from './Profile/AdminProfile';
import AdminSettings from './Profile/AdminSettings';
import FeedSettings from './Profile/FeedSettings';
import ProductProfile from './Profile/ProductProfile';
import Profile from './Profile/Profile';
import SafetyPrivacy from './Profile/SafetyPrivacy';
import UserSettings from './Profile/UserSettings';
import AddModerators from './SignUp/AddModerators';
import AddProduct from './SignUp/AddProduct';
import Pricing from './SignUp/Pricing';
import SignUp from './SignUp/SignUp';
import Faq from './Terms/Faq';
import Help from './Terms/Help';
import PricingPage from './Terms/PricingPage';
import PrivacyPolicy from './Terms/PrivacyPolicy';
import Terms from './Terms/Terms';
import PageNotFound from './UsersFeed/PageNotFound';
import PostFeedback from './UsersFeed/PostFeedback';
import UsersFeed from './UsersFeed/UsersFeed';
import ViewAllCommentsForProduct from './UsersFeed/ViewAllCommentForAProduct';
import CompanyInfo from './SignUp/CompanyInfo';
import EditProduct from './AdminFeed/EditProduct';
import EditCompanyDetails from './AdminFeed/EditCompanyDetails';
import ManageUsers from './AdminFeed/ManageUsers';
import CretaeFeddup from './AdminFeed/CreateFeddup';
import Members from './AdminFeed/Members';
import AllReviews from './AdminFeed/AllReviews';
import FeddbackForms from './AdminFeed/FeddbackForms';
import NewFeedbackFrom from './AdminFeed/NewFeedbackFrom';
import ViewForm from './AdminFeed/ViewForm';
import ProductInsight from './AdminFeed/ProductInsight';
import axios from 'axios';
import { config } from './Config/Config';
import PricingNew from './Terms/Pricing';
import ManagePageUsers from './AdminFeed/ManagePageUsers';
import ResetPassword from './Login/ResetPassword';
import Billing from './AdminFeed/Billing';
import { decrypt } from './utils';

export default function AppRoutes() {
    if (!localStorage.getItem('junkStore') && window.location.pathname != "/" && window.location.pathname != "/login" && !window.location.pathname.includes("/by/") && !window.location.pathname.includes("/admin/") && !window.location.pathname.includes("/pric")) {
        window.location.pathname = "/login"
    }

    const getUserType = () => {
        let token = localStorage.getItem('junkStore')
        if (token) {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
                "applicationType": "web"
            }

            axios.get(config.userUrl + 'user/getUserType', { headers: headers })
                .then(function (response) {
                    if (response.data.user) {
                        localStorage.setItem("role", response.data.user.role)
                        if (response.data.user.productIds)
                            localStorage.setItem("productIds", JSON.stringify(response.data.user.productIds))
                    }
                })
                .catch(function (error) {
                    console.log("Error", error);
                });
        }

    }

    useEffect(() => { getUserType() }, [])

    return (
        <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route path="/login" component={Login} />
            <Route path="/forgotpassword" component={ForgotPassword} />
            <Route path="/changepassword" component={ChangePassword} />
            <Route path="/verifyemail" component={VerifyOtp} />
            <Route path="/emailnotverified" component={EmailNotVerified} />
            <Route path="/checkemail" component={CheckEmail} />
            <Route exact path="/signup/:id" component={SignUp} />
            <Route path="/signup" component={SignUp} />
            <Route path="/addmoderators" component={AddModerators} />
            <Route path="/buildprofile/pricing" component={Pricing} />
            <Route path="/pricing" component={PricingNew} />
            <Route path="/pricingplans" component={PricingPage} />
            <Route path="/thanksforregistering" component={ThanksForRegistering} />
            <Route path="/emailsent" component={EmailSent} />
            <Route path="/emailinvitation" component={EmailInvitation} />
            <Route path="/resetpassword" component={ResetPassword} />
            <Route path="/successresetpassword" component={SuccessResetPassword} />
            <Route path="/terms" component={Terms} />
            <Route path="/privacypolicy" component={PrivacyPolicy} />
            <Route path="/welcomefeddup" component={WelcomeFeddup} />
            <Route path="/forgotemail" component={ForgotEmail} />
            <Route exact path="/userfeed" component={UsersFeed} />
            <Route path="/contentpolicy" component={ContentPolicy} />
            <Route path="/moderatorsguidelines" component={ModeratorsGuidelines} />
            <Route path="/company/productpage/:id" component={CompanyFeed} />
            <Route path="/by/:companyName/:productName/:productType" component={CompanyFeed} />
            {/* <Route path="/postfeed" component={PostFeedback } /> */}
            <Route path="/buildprofile/addproduct" component={AddProduct} />
            <Route path="/editor" component={Editor} />
            <Route path="/profile" component={Profile} />
            <Route path="/activateordeactivate" component={ActivateAccount} />
            <Route path="/adminprofile" component={AdminProfile} />
            <Route exact path="/admin" component={AdminFeed} />
            <Route exact path="/admin/productdetails/:productname/:productid" component={ProductDetail} />
            <Route path="/faqs" component={Faq} />
            <Route path="/help" component={Help} />
            <Route path="/createannouncement" component={CreateAnnouncement} />
            <Route path="/admindashboard" component={AdminLandingPage} />
            <Route exact path="/userfeed/:id" component={ViewAllCommentsForProduct} />
            <Route exact path="/usersettings/account" component={UserSettings} />
            {/* <Route exact path="/usersettings/companyprofile" component={AdminSettings} />
            <Route exact path="/usersettings/productprofile" component={ProductProfile} />
            <Route exact path="/usersettings/safety&privacy" component={SafetyPrivacy} />
            <Route exact path="/usersettings/feedsettings" component={FeedSettings} /> */}
            <Route exact path="/company/products/" component={SearchResults} />
            <Route path="/buildprofile/details" component={CompanyInfo} />
            <Route path="/editdetails/:cname/:cid" component={EditCompanyDetails} />
            <Route exact path="/admin/editproduct/:membershiptype/:productname/:productid" component={EditProduct} />
            <Route exact path="/admin/editproduct/:membershiptype/:productname/:productid/:tags" component={EditProduct} />
            <Route path="/admin/manageusers" component={ManageUsers} />
            <Route path="/:productname/users" component={ManagePageUsers} />
            <Route exact path="/admin/createnewfeddup/:companyId" component={CretaeFeddup} />
            <Route exact path='/admin/allreviews/:productname/:productid/:section' component={AllReviews} />
            <Route path="/admin/feddbackforms/:productid" component={FeddbackForms} />
            <Route path="/admin/insights/:productId/:versionId" component={ProductInsight} />
            <Route path="/admin/:productname/:productid/members" component={Members} />
            <Route path="/admin/newfeedbackform" component={NewFeedbackFrom} />
            <Route path="/admin/viewform/:id" component={ViewForm} />
            <Route path="/admin/billing" component={Billing} />
            <Route exact path="*" component={PageNotFound} />
        </Switch >
    )
}

export const config = {
    "apiUrl": "https://auth.feddup.me/",
    "userUrl": "https://api.feddup.me/",
    // "apiUrl": "http://localhost:8080/",
    // "userUrl": "http://localhost:8082/",
    "PASSWORD_ENCRYPTION_KEY": "zxcvbnmasdfghjkl",
    "webUrl": "https://www.feddup.me/",
    "popupUrl": "https://popup.feddup.me"
}
// export const config = {
//     "apiUrl": "http://localhost:8080/",
//     "userUrl": "http://localhost:8082/",
//     "PASSWORD_ENCRYPTION_KEY": "zxcvbnmasdfghjkl",
//     "webUrl": "https://www.feddup.me/"
// }



import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { config } from '../Config/Config';
import cancelWhiteIcon from "../images/cancel-x-white.svg";
import { Button, Col, Dropdown, Form, FormControl, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import { decrypt, loaderProps, suggestionsOptions } from '../utils';
import { CircleFadeLoader } from 'react-loaders-kit';
import fillTag from "../images/fill-tag.svg";
import emptyTag from "../images/empty-tag-gray.svg";
import pinOrange from "../images/pinned-orange.svg";
import pinGray from "../images/pinned-gray.svg"
import pinkDot from "../images/pink-dot.svg";
import yellowDot from "../images/yellow-dot.svg";
import redDot from "../images/red-dot.svg";
import greenDot from "../images/green-dot.svg";
import SuggestionComments from './SuggestionComments';
import Tag from '../utils/Tag';
import ViewImage from '../UsersFeed/ViewImage';

function ProductSuggestions({ productid, membershipType, selectedVersionId, item, productTags, handleFlag, deletePost, openReasonPopUp, userRole }) {
    const [section, setSection] = useState("Suggestions");
    const [openStates, setOpenStates] = useState([]);
    const [selectedTags, setSelectedTags] = useState(Array(productTags?.length).fill(false));
    const [show, setShow] = useState(false)
    const [sortedData, setSortedData] = useState([])
    const [sortOption, setSortOption] = useState('')
    const [sortOption2, setSortOption2] = useState('')
    const [sortOption3, setSortOption3] = useState([])
    const [sortOption4, setSortOption4] = useState(-1)
    const [searchInput, setSearchInput] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const [suggestions, setSuggestions] = useState([])
    const [loading, setLoading] = useState(false)
    const [pinnedSuggestionsCount, setPinnedSuggestionsCount] = useState(0)
    const [showToast, setShowToast] = useState(false)
    const [suggestionPop, setSuggestionPop] = useState(false);
    const [viewImage, setViewImage] = useState(false);
    const [suggestionPopData, setSuggestionPopData] = useState([]);
    const [suggestionData, setSuggestionData] = useState([]);
    const [selectedValue, setSelectedValue] = useState(0);

    useEffect(() => {
        setSortedData([]);
        getAllSuggestions();
    }, [selectedVersionId])

    const getAllSuggestions = () => {
        if (selectedVersionId === "") return;
        setLoading(true);
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "productId": productid,
            "versionId": selectedVersionId
        }
        axios.post(config.userUrl + "admin/getProductSuggestions", payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    setSuggestions(response.data.suggestionDetails)
                    setSortedData(response.data.suggestionDetails)
                    setOpenStates(Array(response.data.suggestionDetails.length).fill(false))
                    setPinnedSuggestionsCount(response.data.pinnedSuggestionsCount)
                }
            })
            .then(() => {
                setSortOption("Latest to Oldest")
                setSortOption2("All")
                setSortOption3([])
                setSortOption4(-1)
                setLoading(false);
            })
            .catch(function (error) {
                console.log("Error", error);
                setLoading(false);
            });
    };
    const pinSuggestion = (suggestion) => {
        if (pinnedSuggestionsCount === 5) {
            setShowToast(true);
            setTimeout(() => {
                setShowToast(false)
            }, 3000)
            return;
        }
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "id": suggestion.id
        }
        axios.post(config.userUrl + "admin/pinSuggestion", payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    suggestion.pin = true;
                    setPinnedSuggestionsCount(pinnedSuggestionsCount + 1)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const unpinSuggestion = (suggestion) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "id": suggestion.id,
        }
        axios.post(config.userUrl + "admin/unpinSuggestion", payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    suggestion.pin = false;
                    setPinnedSuggestionsCount(pinnedSuggestionsCount - 1)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const handleChange = (value, suggestion) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "id": suggestion.id,
            "status": value
        }
        axios.post(config.userUrl + "admin/updateSuggestionStatus", payload, { headers: headers })
            .then(function (response) {
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("successfull", response.data);
                    let updatedSuggestions = suggestions.filter(element => {
                        if (element.id === suggestion.id) {
                            element.status = parseInt(value);
                        }
                        return element;
                    })
                    setSuggestions(updatedSuggestions);
                    filter()
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });

    }
    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        const currentData = suggestions;
        if (searchValue !== '') {
            const filteredData = currentData.filter((item) => {
                return item.suggestion.toLowerCase().includes(searchValue.toLowerCase())
            })
            setFilteredResults(filteredData)
        }
        else {
            setFilteredResults(currentData)
        }
    }
    const handleButtonClick = (rowIndex) => {
        setOpenStates(openStates.map((isOpen, index) => index === rowIndex ? !isOpen : isOpen));
    }
    const handleTagSelectButtonClick = (rowIndex) => {
        let newSelectedTagsArray = selectedTags.map((isOpen, index) => rowIndex === index ? !isOpen : isOpen);
        setSelectedTags(newSelectedTagsArray);
        let tagsSelected = [];
        productTags.forEach((tag, i) => {
            if (newSelectedTagsArray[i] === true)
                tagsSelected.push(tag);
        })
        setSortOption3(tagsSelected);
    }
    useEffect(() => {
        filter()
    }, [sortOption, sortOption2, sortOption3, sortOption4])
    const filter = () => {
        let suggestionsArray = [...suggestions]
        let sortedSuggestion = []
        if (sortOption2.length > 0) {
            if (sortOption2 === "Pinned Only") {
                sortedSuggestion = suggestionsArray.filter(suggestion => suggestion.pin === true)
            }
            else if (sortOption2 === "Except Pinned") {
                sortedSuggestion = suggestionsArray.filter(suggestion => suggestion.pin === false)
            }
            else {
                sortedSuggestion = [...suggestions];
            }
            suggestionsArray = [...sortedSuggestion];
        }
        if (sortOption4 > 0 && sortOption4 < 5) {
            if (sortOption4 === 1) {
                sortedSuggestion = suggestionsArray.filter(suggestion => suggestion.status === 1)
            }
            else if (sortOption4 === 2) {
                sortedSuggestion = suggestionsArray.filter(suggestion => suggestion.status === 2)
            }
            else if (sortOption4 === 3) {
                sortedSuggestion = suggestionsArray.filter(suggestion => suggestion.status === 3)
            }
            else {
                sortedSuggestion = suggestionsArray.filter(suggestion => suggestion.status === 4)
            }
            suggestionsArray = [...sortedSuggestion];
        }
        if (sortOption.length > 0) {
            if (sortOption === "Latest to Oldest") {
                sortedSuggestion = suggestionsArray.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            }
            else {
                sortedSuggestion = suggestionsArray.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
            }
            suggestionsArray = [...sortedSuggestion];
        }
        if (sortOption3.length > 0) {
            sortedSuggestion = suggestionsArray.filter(suggestion => {
                let currentTagArray = suggestion.tag;
                const filteredArray = currentTagArray.filter(element => sortOption3.includes(element));
                if (filteredArray.length > 0)
                    return suggestion;
            })
        }
        setSortedData(sortedSuggestion);
    }
    const openSuggestionImage = (data) => {
        setViewImage(data.suggestionImage)
    }
    const toggleSuggestionPopup = (data) => {
        setSuggestionPop(!suggestionPop)
        setSuggestionPopData(data)
    }
    const getSuggestions = (eventKey) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        let product = decrypt(localStorage.getItem('junk'))
        var payload = {
            "productId": product,
            "type": parseInt(eventKey)
        }
        axios.post(config.userUrl + 'admin/getSuggestionByProductIdByAdmin', payload, { headers: headers })
            .then(function (response) {
                console.log("getSuggestion");
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("getSuggestion successfull");
                    setSuggestionData(response.data.suggestion)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    return (
        <div>
            {viewImage && <ViewImage close={() => setViewImage(false)} image={viewImage} />}

            {suggestionPop && <SuggestionComments openSuggestionImage={openSuggestionImage} closePopup={toggleSuggestionPopup} getSuggestions={getSuggestions} data={suggestionPopData} selectedValue={selectedValue} />}
            <div>
                <div className='flex gap-6 mt-8 ml-10 '>
                    <p className={`text-md font-semibold cursor-pointer ${section === "Suggestions" ? "underline text-orange-400 underline-offset-8" : ""}`} onClick={() => setSection("Suggestions")}><span className='text-black'>Suggestions</span></p>
                    <p className={`text-md font-semibold cursor-pointer ${section === "Replied" ? "underline text-orange-400 underline-offset-8" : ""}`} onClick={() => setSection("Replied")}><span className='text-black'>Replied</span></p>

                    {/* <p className={`text-md font-semibold cursor-pointer ${section === "Insights" ? "underline text-orange-400 underline-offset-8" : ""}`} onClick={() => setSection("Insights")}><span className='text-black'>Insights</span></p> */}
                </div>
                <Row className="ml-1 mt-6">
                    <Col md={3} className='flex flex-col bg-white mb-10 p-3 rounded-xl h-max sticky top-[80px]'>
                        <div className='flex justify-between items-center'>
                            <p className='text-2xl font-semibold  underline text-orange-400 underline-offset-8'><span className='text-black'>Sort&Filter</span></p>
                            <p className='bg-orange-600 rounded-xl text-white text-xs px-2 py-0.5 flex items-center gap-2 justify-between cursor-pointer' onClick={() => { setSortOption("Latest to Oldest"); setSortOption2("All"); setSortOption3([]); setSortOption4(-1); setSortedData(suggestions) }}><span>Clear All</span><img src={cancelWhiteIcon} alt='' className='float-right w-5' /></p>
                        </div>
                        <br />
                        <div className="flex flex-col gap-2 text-balck font-medium" onChange={(e) => setSortOption(e.target.value)}>
                            <div className='flex items-center gap-2'>
                                <button
                                    className={`rounded-full w-4 h-4 ${sortOption === "Latest to Oldest" ? 'bg-black text-white' : 'bg-gray-200'
                                        }`}
                                    onClick={() => setSortOption("Latest to Oldest")}
                                >
                                </button>
                                <span>Latest to Oldest</span>
                            </div>
                            <div className='flex items-center gap-2'>
                                <button
                                    className={`rounded-full w-4 h-4 ${sortOption === "Oldest to Latest" ? 'bg-black text-white' : 'bg-gray-200'
                                        }`}
                                    onClick={() => setSortOption("Oldest to Latest")}
                                >
                                </button>
                                <span>Oldest to Latest</span>
                            </div>
                        </div>
                        <br />
                        <div>
                            <p className='text-xl font-medium mt-4 flex items-center justify-between'>Pinned </p>
                            <div className="flex flex-col gap-2 text-balck font-medium" onChange={(e) => setSortOption2(e.target.value)}>
                                <div className='flex items-center gap-2'>
                                    <button
                                        className={`rounded-full w-4 h-4 ${sortOption2 === "Pinned Only" ? 'bg-black text-white' : 'bg-gray-200'
                                            }`}
                                        onClick={() => setSortOption2("Pinned Only")}
                                    >
                                    </button>
                                    <span>Pinned Only</span>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <button
                                        className={`rounded-full w-4 h-4 ${sortOption2 === "Except Pinned" ? 'bg-black text-white' : 'bg-gray-200'
                                            }`}
                                        onClick={() => setSortOption2("Except Pinned")}
                                    >
                                    </button>
                                    <span>Except Pinned</span>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <button
                                        className={`rounded-full w-4 h-4 ${sortOption2 === "All" ? 'bg-black text-white' : 'bg-gray-200'
                                            }`}
                                        onClick={() => setSortOption2("All")}
                                    >
                                    </button>
                                    <span>All</span>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div>
                            <p className='text-xl font-medium mt-4 flex items-center justify-between'>Status </p>
                            <div className="flex flex-col gap-2 text-balck font-medium">
                                <div className='flex items-center gap-2'>
                                    <button
                                        className={`rounded-full w-4 h-4 ${sortOption4 === -1 ? 'bg-black text-white' : 'bg-gray-200'
                                            }`}
                                        onClick={() => setSortOption4(-1)}
                                    >
                                    </button>
                                    <span>All</span>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <button
                                        className={`rounded-full w-4 h-4 ${sortOption4 === 1 ? 'bg-black text-white' : 'bg-gray-200'
                                            }`}
                                        onClick={() => setSortOption4(1)}
                                    >
                                    </button>
                                    <span>No action</span>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <button
                                        className={`rounded-full w-4 h-4 ${sortOption4 === 4 ? 'bg-black text-white' : 'bg-gray-200'
                                            }`}
                                        onClick={() => setSortOption4(4)}
                                    >
                                    </button>
                                    <span>Accepted</span>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <button
                                        className={`rounded-full w-4 h-4 ${sortOption4 === 2 ? 'bg-black text-white' : 'bg-gray-200'
                                            }`}
                                        onClick={() => setSortOption4(2)}
                                    >
                                    </button>
                                    <span>In discussion</span>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <button
                                        className={`rounded-full w-4 h-4 ${sortOption4 === 3 ? 'bg-black text-white' : 'bg-gray-200'
                                            }`}
                                        onClick={() => setSortOption4(3)}
                                    >
                                    </button>
                                    <span>Rejected</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p className='text-xl font-medium mt-4 flex items-center justify-between'>Filter By Tags</p>
                            <div className='flex items-center flex-wrap gap-3 py-3'>
                                {productTags && productTags.map((data, i) => {
                                    return (
                                        <span className='cursor-pointer' onClick={() => handleTagSelectButtonClick(i)}>
                                            <Tag text={data} color={selectedTags[i] ? '#E6570E' : '#999'} />
                                        </span>
                                    )
                                })}
                            </div>
                        </div>
                    </Col>
                    <Col md={9} className='flex flex-col gap-4'>
                        <div className='flex justify-between'>
                            <div className='flex flex-col gap-4'>
                                <p className='text-2xl font-semibold text-black'>Suggestions</p>
                                <div className={`${membershipType === "Free" ? "hidden" : ""}`}>
                                    <Form className=''>
                                        <FormControl
                                            type="text"
                                            placeholder="Search for user"
                                            value={searchInput}
                                            onChange={(e) => searchItems(e.target.value)}
                                            id="search"
                                            className="abc form-control ml-0 bg-white rounded-full"
                                            style={{ width: "480px", height: "45px", borderRadius: "25px", paddingLeft: "3rem" }}
                                            aria-label="Search keyword"
                                        />
                                    </Form>
                                </div>
                            </div>
                            <div className='flex rounded-xl items-center'>
                                <img src={pinOrange} alt='' className='w-10' />
                                <span className='text-lg font-medium'>Pinned {pinnedSuggestionsCount < 0 ? 0 : pinnedSuggestionsCount}/5</span>
                            </div>
                        </div>
                        {loading ? <div className='mx-auto flex flex-col'><CircleFadeLoader {...loaderProps} /><span className='text-2xl font-semibold'>Loading...</span></div> : null}
                        {loading === false && searchInput.length > 0 ?
                            filteredResults.filter(item => section == 'Replied' ? item?.reply : true).length === 0 ? <div className='mx-auto flex text-2xl font-semibold'>No Suggestions found</div>
                                : filteredResults.filter(item => section == 'Replied' ? item?.reply : true).map((suggestion, i) => {
                                    return (
                                        <div key={i}>
                                            <Row className='mt-4 ml-2 items-center'>
                                                <Col md={2} className='text-black font-medium'>
                                                    <p>{suggestion.userName ? suggestion.userName : "Anonymous User"}</p>
                                                    <p>{moment(suggestion.updatedAt, "YYYY-MM-DD HH:mm:ss.SSSZ").format("DD/MM/YY")}</p>
                                                </Col>
                                                <Col md={4}>
                                                    <p className='text-base font-semibold'>{suggestion.title}</p>
                                                </Col>
                                                {
                                                    section === "Replied"
                                                        ?
                                                        <>
                                                            <Col md={3}>
                                                                <div>
                                                                    <p className='text-right'>{suggestion.reply?.comment?.length > 30 ? suggestion.reply?.comment?.slice(0, 30) + '...' : suggestion.reply?.comment}</p>
                                                                    <p className='text-right text-sm italic'>-{suggestion.reply?.role == 'individual' ? 'user' : suggestion.reply?.role}</p>
                                                                </div>
                                                            </Col>
                                                        </>
                                                        :
                                                        <>
                                                            <Col md={1}>
                                                                <span className='font-medium'>{`${suggestion.tag?.length > 1 ? suggestion.tag?.length + " Tags" : suggestion.tag?.length === 1 ? "1 Tag" : "0 Tag"}`}</span>
                                                            </Col>
                                                            <Col md={2}>
                                                                <select className='focus:border-none focus:outline-none' onChange={(e) => handleChange(e.target.value, suggestion)} value={suggestion.status}>
                                                                    <option value={1}>No action</option>
                                                                    <option value={2}>In discussion</option>
                                                                    <option value={3}>Reject</option>
                                                                    <option value={4}>Accept</option>
                                                                </select>
                                                            </Col>

                                                        </>
                                                }
                                                <Col md={1}>
                                                    <img className='cursor-pointer' src={suggestion.pin ? pinOrange : pinGray} alt="" onClick={() => suggestion.pin ? unpinSuggestion(suggestion) : pinSuggestion(suggestion)} />
                                                </Col>
                                                <Col md={1}>
                                                    <Button
                                                        size='sm'
                                                        variant="default"
                                                        type="submit"
                                                        className="bg-orange-400 text-white h-8 w-20 leading-tight rounded-lg"
                                                        onClick={() => toggleSuggestionPopup(suggestion)}
                                                    >Comment</Button>
                                                </Col>
                                                <Col md={1} className='flex items-center relative'>
                                                    <Dropdown align="end" show={openStates[i]} onToggle={() => handleButtonClick(i)}>
                                                        <Dropdown.Toggle variant="default" className="text-white focus:outline-none focus:ring-0" >
                                                            <svg className="w-6 h-6" aria-hidden="true" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z">
                                                                </path>
                                                            </svg>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu className="border-black rounded-lg min-w-max w-44 px-2 z-20">
                                                            {userRole != 'moderator' && <p className="hover:bg-orange-200 hover:font-medium rounded-md py-1 px-2 w-full cursor-pointer" onClick={() => openReasonPopUp({ type: 'deleting', action: () => deletePost({ id: suggestion.id, type: 'suggestion', remove: true, callback: () => getAllSuggestions() }) })}>Delete this post</p>}
                                                            {
                                                                suggestion.flag
                                                                    ?
                                                                    <p className="hover:bg-orange-200 hover:font-medium rounded-md py-1 px-2 w-full cursor-pointer" onClick={() => openReasonPopUp({ noPopUp: true, type: 'unflagging', action: () => handleFlag({ id: suggestion.id, type: 'suggestion', flag: false, callback: () => getAllSuggestions() }) })}>UnFlag this post</p>
                                                                    : <p className="hover:bg-orange-200 hover:font-medium rounded-md py-1 px-2 w-full cursor-pointer" onClick={() => openReasonPopUp({ type: 'flagging', action: () => handleFlag({ id: suggestion.id, type: 'suggestion', flag: true, callback: () => getAllSuggestions() }) })}>Flag this post</p>
                                                            }
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </Col>
                                            </Row>
                                            <hr className="bg-orange-600 w-full mx-auto rounded-sm h-[1px]" />
                                        </div>
                                    )
                                })
                            : loading === false && sortedData.filter(item => section == 'Replied' ? item?.reply : true).length === 0 ? <div className='mx-auto flex text-2xl font-semibold'>Change your filter to load more suggestions</div>
                                : sortedData && sortedData.filter(item => section == 'Replied' ? item?.reply : true).map((suggestion, i) => {
                                    return (
                                        <div key={i}>
                                            <Row className='mt-4 ml-2 items-center'>
                                                <Col md={2} className='text-black font-medium'>
                                                    <p>{suggestion.userName ? suggestion.userName : "Anonymous User"}</p>
                                                    <p>{moment(suggestion.updatedAt, "YYYY-MM-DD HH:mm:ss.SSSZ").format("DD/MM/YY")}</p>
                                                </Col>
                                                <Col md={4}>
                                                    <p className='text-base font-semibold'>{suggestion.title}</p>
                                                </Col>
                                                {
                                                    section === "Replied"
                                                        ?
                                                        <>
                                                            <Col md={3}>
                                                                <div>
                                                                    <p className='text-right'>{suggestion.reply?.comment?.length > 30 ? suggestion.reply?.comment?.slice(0, 30) + '...' : suggestion.reply?.comment}</p>
                                                                    <p className='text-right text-sm italic'>-{suggestion.reply?.role == 'individual' ? 'user' : suggestion.reply?.role}</p>
                                                                </div>
                                                            </Col>
                                                        </>
                                                        :
                                                        <>
                                                            <Col md={1}>
                                                                <span className='font-medium'>{`${suggestion.tag?.length > 1 ? suggestion.tag?.length + " Tags" : suggestion.tag?.length === 1 ? "1 Tag" : "0 Tag"}`}</span>
                                                            </Col>
                                                            <Col md={2}>
                                                                <div className="sel flex min-w-max">
                                                                    <span className="flex gap-2 text-sm mr-2"><img src={suggestion.status === 1 ? pinkDot : suggestion.status === 2 ? yellowDot : suggestion.status === 3 ? redDot : greenDot} alt="dot" className="w-3" /></span>
                                                                    {userRole == 'moderator'
                                                                        ?
                                                                        <h1>{suggestion.status === 1 ? 'No action' : suggestion.status === 2 ? 'In discussion' : suggestion.status === 3 ? 'Rejected' : 'Accepted'}</h1>
                                                                        :
                                                                        <select className='focus:border-none focus:outline-none' onChange={(e) => handleChange(e.target.value, suggestion)} value={suggestion.status}>
                                                                            <option value={1}>No action</option>
                                                                            <option value={2}>In discussion</option>
                                                                            <option value={3}>Reject</option>
                                                                            <option value={4}>Accept</option>
                                                                        </select>}
                                                                </div>
                                                            </Col>
                                                        </>
                                                }
                                                <Col md={1}>
                                                    <img className='cursor-pointer' src={suggestion.pin ? pinOrange : pinGray} alt="" onClick={() => suggestion.pin ? unpinSuggestion(suggestion) : pinSuggestion(suggestion)} />
                                                </Col>
                                                <Col md={1}>
                                                    <Button
                                                        size='sm'
                                                        variant="default"
                                                        type="submit"
                                                        className="bg-orange-400 text-white h-8 w-20 leading-tight rounded-lg"
                                                        onClick={() => toggleSuggestionPopup(suggestion)}
                                                    >Comment</Button>
                                                </Col>
                                                <Col md={1} className='flex items-center relative'>
                                                    <Dropdown align="end" show={openStates[i]} onToggle={() => handleButtonClick(i)}>
                                                        <Dropdown.Toggle variant="default" className="text-white focus:outline-none focus:ring-0" >
                                                            <svg className="w-6 h-6" aria-hidden="true" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z">
                                                                </path>
                                                            </svg>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu className="border-black rounded-lg min-w-max w-44 px-2 z-20">
                                                            {userRole != 'moderator' && <p className="hover:bg-orange-200 hover:font-medium rounded-md py-1 px-2 w-full cursor-pointer" onClick={() => openReasonPopUp({ type: 'deleting', action: () => deletePost({ id: suggestion.id, type: 'suggestion', remove: true, callback: () => getAllSuggestions() }) })}>Delete this post</p>}
                                                            {
                                                                suggestion.flag
                                                                    ?
                                                                    <p className="hover:bg-orange-200 hover:font-medium rounded-md py-1 px-2 w-full cursor-pointer" onClick={() => openReasonPopUp({ type: 'unflagging', noPopUp: true, action: () => handleFlag({ id: suggestion.id, type: 'suggestion', flag: false, callback: () => getAllSuggestions() }) })}>UnFlag this post</p>
                                                                    : <p className="hover:bg-orange-200 hover:font-medium rounded-md py-1 px-2 w-full cursor-pointer" onClick={() => openReasonPopUp({ type: 'flagging', action: () => handleFlag({ id: suggestion.id, type: 'suggestion', flag: true, callback: () => getAllSuggestions() }) })}>Flag this post</p>
                                                            }
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </Col>
                                                {section == 'Replied' ? <div className='w-full pb-2 flex items-center justify-end pr-10'>
                                                <span className="flex gap-2 text-sm mr-2"><img src={suggestion.status === 1 ? pinkDot : suggestion.status === 2 ? yellowDot : suggestion.status === 3 ? redDot : greenDot} alt="dot" className="w-3" /></span>
                                                    <select className='focus:border-none focus:outline-none' onChange={(e) => handleChange(e.target.value, suggestion)} value={suggestion.status}>
                                                        <option value={1}>No action</option>
                                                        <option value={2}>In discussion</option>
                                                        <option value={3}>Reject</option>
                                                        <option value={4}>Accept</option>
                                                    </select>
                                                </div> : <></>}
                                            </Row>
                                            <hr className="bg-orange-600 w-full mx-auto rounded-sm h-[1px]" />
                                        </div>
                                    )
                                })}
                    </Col>
                </Row>
            </div>
            <Modal
                show={showToast}
                onHide={() => setShowToast(false)}
                dialogClassName="modal-3w"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <p className='text-lg'>You cannot pin more than 5 suggestions</p>
                    </Modal.Title>
                </Modal.Header>
            </Modal>
        </div>
    )
}

export default ProductSuggestions
import React from 'react';
import '../CompanyFeed/CompanyFeed.css';
import { Row, Col, Modal, Form, FormControl, Card, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import upvoteb from '../images/upvote-b.svg';
import downvoteb from '../images/downvote-b.svg';
import upvote from '../images/upvote.svg';
import downvote from '../images/downvote.svg';
import comment from '../images/comments.svg';
import share from '../images/share.svg';
import lock from '../images/lock.png';
import profilepicture from '../images/profilepicture.png';
import deletei from "../images/delete-i.svg";
import axios from 'axios';
import { config } from '../Config/Config';
import { decrypt } from '../utils';

class DeleteSuggestionByAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deletePost: true
        }
    }
    componentDidMount() {
        console.log("props", this.props.data)
    }
    deleteSuggestionById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "suggestionId": this.props.data
        }
        console.log("payload", payload)
        var self = this;
        axios.post(config.userUrl + 'admin/productAdminDeleteUserSuggestion', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, payload);
                    self.props.getSuggestions(self.props.selectedValue)
                    self.props.closePopup();

                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    render() {
        const { deletePost } = this.state
        return (
            <Modal
                size="md"
                show={deletePost}
                onHide={this.props.closePopup}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title >
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <center>    <img src={deletei} style={{ width: "80px" }} /></center>
                    <center className="mt-1 mb-2">
                        <div className="mt-3 suggestion-box-text">Are you sure to delete this suggestion post?</div>
                        <br /><Button
                            variant="default" size="md"
                            type="submit"
                            onClick={this.deleteSuggestionById}
                            className="deleteyes-button"

                        >
                            Yes, delete it
                        </Button><br />
                        <Button
                            variant="default" size="md"
                            type="submit"
                            className="mt-2 deleteno-button"
                            onClick={this.props.closePopup}
                        >
                            No
                        </Button></center>
                </Modal.Body>
            </Modal>
        );
    }
}

export default DeleteSuggestionByAdmin;


import React from 'react'

function Tag({ text, extraClass="bg-[#E6570E]", color="#E6570E", width }) {
    return (
        <>
            <div className={`px-3 ml-3 rounded-lg min-w-24 max-w-max h-8 relative flex flex-shrink-0 items-center justify-end ${extraClass} ${width}`} style={{ backgroundColor: color }}>
                <div className={`rounded-lg w-6 h-6 transform rotate-45 absolute top-1 -left-2 grid place-items-center z-10 ${extraClass}`} style={{ backgroundColor: color }}>
                    <div className='w-1.5 h-1.5 rounded-full bg-white' />
                </div>
                <h1 className='text-right text-white text-sm pl-1 tracking-wide z-20'>{text}</h1>
            </div>
        </>
    )
}

export default Tag
import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import './CompanyFeed.css';
import imageIcon from '../images/image-icon.svg';

const fileTypes = ["JPG", "PNG"];

function AddImage2({ editById, imageUrl, onChangeImage }) {
    const [file, setFile] = useState(null);
    const [image, setImage] = useState(imageUrl || "");
    const [loading, setLoading] = useState(false);

    const handleChange = async (file) => {
        setFile(file);
        console.log("file", file)
        if (file) {
            setLoading(true);
            const res = await uploadImage(file);
            if (res) {
                console.log("res", res)
                setImage(res)
            }
            onChangeImage(res);
            setLoading(false);
        }
    };
    const deleteImage = () => {
        setFile(null);
        setImage("")
        onChangeImage("")
    }
    const uploadImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "feddUp")
        data.append("cloud_name", "feddup")
        const resp = await fetch(`https://api.cloudinary.com/v1_1/feddup/image/upload`, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        return res.secure_url
    }


    return (
        <>
            <div className="relative w-full imageUploadBox">
                {image ? <svg onClick={deleteImage} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer text-red-500 absolute -top-2 -right-2 bg-white rounded-full z-10">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg> : ''}
                <FileUploader
                    multiple={false}
                    handleChange={handleChange} // function to get uploaded files
                    name="file"
                    className="w-full"
                    children={
                        <div className="w-full h-[13rem] rounded-lg border-2 border-gray-500 flex flex-col items-center justify-center cursor-pointer">
                            {
                                loading
                                ?
                                <div className="w-12 h-12 border-4 rounded-full border-orange-500 border-b-transparent animate-spin"></div>
                                :
                                image ?
                                    <>
                                        <img className="w-full h-full object-contain" src={image} alt="" />
                                    </>
                                    :
                                    <>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-gray-700">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                        </svg>
                                        <h1 className="text-base">Choose a file or drag and drop here</h1>
                                    </>
                            }
                        </div>
                        // <div className="drag-drop">
                        //     <div className="i-flex">
                        //         <img src={imageIcon} style={{ marginLeft: "5px", marginTop: "-5px", width: "20px" }} />
                        //         <div className="ml-2 b-image"><span className="ml-2">Choose a file or drag and drop here</span></div>
                        //     </div>
                        //     <div style={{ fontSize: "12px", color: "grey", float: "right" }}>Only JPG's,PNG's are allowed</div>
                        // </div>
                    }
                    types={["JPG", "PNG", 'JPEG']}
                />
            </div>

        </>

    );
}

export default AddImage2;

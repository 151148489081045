import React, { Component } from "react";
import { Button, Form, Row, Col, Card, Image, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import fedduplogo from "../images/fedduplogo.png";
import thanks from "../images/thanksforregistering.png";
import '../Login/Login.css'; import axios from 'axios';
import { config } from '../Config/Config';
import camera from "../images/camera.svg";
import cancelWhite from "../images/cancel-x-white.svg";
import cancelBlack from "../images/cancel-x-black.svg";
import { decrypt, uploadImage } from "../utils";
import toast from "react-hot-toast";


export default class AddProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: decrypt(localStorage.getItem('junkStore')),
            companyId: "",
            productId: "",
            productTypeArr: [],
            productIcon: "",
            productName: "",
            productWebsite: "",
            productType: "",
            productNameError: "",
            productWebsiteError: "",
            productTypeError: "",
            tags: [],
            redirect1: false,
            redirect2: false,
        }
        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.validateField = this.validateField.bind(this);
        this.validateProductName = this.validateProductName.bind(this);
        this.validateProductWebsite = this.validateProductWebsite.bind(this);
        this.validateProductType = this.validateProductType.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleRemoveTag = this.handleRemoveTag.bind(this);
        this.goBack = this.goBack.bind(this);
        this.addImage = this.addImage.bind(this);
        this.getProductDetails = this.getProductDetails.bind(this);
    }
    onChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
        return;
    }
    handleKeyDown(event) {
        if (event.key === "Enter") {
            const newTag = event.target.value.trim();
            this.setState(prevState => ({
                tags: [...prevState.tags, newTag]
            }))
            event.preventDefault();
            event.target.value = "";
        }
    };
    handleRemoveTag(tagToRemove) {
        let filteredTags = this.state.tags.filter((tag) => tag !== tagToRemove)
        this.setState({ tags: filteredTags });
    };

    handleBlur(event) {
        const { name } = event.target;
        this.validateField(name);
        return;
    }
    handleSubmit(event) {
        event.preventDefault();
        let formFiledsNew = [
            "productName",
            "productWebsite",
            "productType"

        ];
        let isValidNew = true;
        formFiledsNew.forEach(field => {
            isValidNew = this.validateField(field) && isValidNew;
        });
        if (!isValidNew) {
            toast("Please enter the details", { id: 'info' })
        } else {
            if (this.state.productId?.length > 0)
                this.updateProductDetails();
            else
                this.addProduct()
        }
    }
    validateField(name) {
        let isValid = false;
        if (name === "productName") isValid = this.validateProductName();
        else if (name === "productWebsite") isValid = this.validateProductWebsite();
        else if (name === "productType") isValid = this.validateProductType();
        return isValid;
    }
    componentDidMount() {
        this.getProductType();
        this.getProductDetails();
    }
    validateProductName() {
        let productNameError = "";
        const value = this.state.productName;
        if (value.trim() === "") productNameError = "Please enter the Product Name";
        else if (value.length < 3)
            productNameError = "Please enter the Product Name";
        this.setState({
            productNameError
        });
        return productNameError === "";
    }
    validateProductWebsite() {
        let productWebsiteError = "";
        const value = this.state.productWebsite;
        if (value.trim() === "") productWebsiteError = "Please add Product Website";
        else if (value.length < 5)
            productWebsiteError = 'Please add Product Website';
        this.setState({
            productWebsiteError
        });
        return productWebsiteError === "";
    }
    validateProductType() {
        let productTypeError = "";
        const value = this.state.productType;
        if (value.length == 0) productTypeError = "Select Product Type";
        this.setState({
            productTypeError
        });
        return productTypeError === "";
    }
    goBack() {
        this.setState({ redirect2: true })
    }
    async addImage(e) {
        let img_url = await uploadImage(e.target.files[0]);
        if (img_url) this.setState({ productIcon: img_url });
    }
    addProduct = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var payload = {
            "productIcon": this.state.productIcon,
            "productName": this.state.productName,
            "productWebsite": this.state.productWebsite,
            "productType": this.state.productType,
            "productTags": this.state.tags,
            "pageType": 'Public'
        }
        var self = this
        axios.post(config.userUrl + 'admin/createProductByAdmin', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    self.setState({
                        companyId: response.data.productDetails.companyId,
                        redirect1: true
                    })
                } else {
                    console.log("error")
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    updateProductDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "productId": this.state.productId,
            "productIcon": this.state.productIcon,
            "productName": this.state.productName,
            "productWebsite": this.state.productWebsite,
            "productType": this.state.productType,
            "productTags": this.state.tags,
        }
        console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'admin/updateProductDetails', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    self.setState({
                        redirect1: true
                    })
                } else {
                    console.log(response.data.message)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    getProductType = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getProductType', { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    self.setState({
                        productTypeArr: response.data.productType,
                    })
                } else {
                    console.log("error")
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    getProductDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'admin/getProductDetail', { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    self.setState({
                        productId: response.data.productProfile.id,
                        productIcon: response.data.productProfile.productIcon,
                        productName: response.data.productProfile.productName,
                        productWebsite: response.data.productProfile.productWebsite,
                        productType: response.data.productProfile.productType,
                        tags: response.data.productProfile.searchTags?.split(","),
                        pageType: 'Public'
                    })

                } else {
                    console.log(response.data.message)
                }
            })
            .catch(function (error) {
                console.log("First Product", error);
            });
    }
    onProductTypeChange = (e) => {
        this.setState({ productType: (parseInt(e.target.value)) })
    }
    onProductSectorChange = (e) => {
        this.setState({ productSector: (parseInt(e.target.value)) })
    }
    render() {
        if (this.state.redirect1) {
            return <Redirect
                to={{
                    pathname: `/buildprofile/pricing`
                }}
            />
        }
        if (this.state.redirect2) {
            return <Redirect
                to={{
                    pathname: `/buildprofile/details`
                }}
            />
        }
        return (
            <div>
                <Row className="w-full">
                    <Col md={4}> <a href="/"><img src={fedduplogo} className="logo-login" style={{ width: "15%" }} /></a></Col>
                    <Col md={4} className="ml-16">
                        <div className="text-4xl text-orange-600 font-bold mt-4">Let's get you all set up!</div>
                        <div className="font-normal text-3xl text-orange-400 ml-4">3 quick and easy steps</div>
                    </Col>
                </Row>
                <Container style={{ marginTop: "2rem" }}>
                    <Row>
                        <Col sm={4} md={4} className="mt-16">
                            <center>    <Image src={thanks} style={{ width: "70%" }} /></center>
                        </Col>
                        <Col sm={8} md={8}>
                            <div className="max-w-3xl">
                                <div className="container">
                                    <ul className="progressbar">
                                        <li className="active" style={{ color: "black" }}>Company Info</li>
                                        <li className="current" style={{ color: "black" }}>First Feddup Page</li>
                                        <li style={{ color: "black" }}>Choose Plan</li>
                                    </ul>
                                </div>
                                <Row className="ml-8">
                                    <Col sm={3} md={3}>
                                        {this.state.productIcon ?
                                            <div className="flex flex-col items-center mt-8 h-40 w40 rounded-[10px] bg-gray-100 overflow-hidden relative border-2 border-orange-500 group">
                                                <img src={this.state.productIcon} alt="" className="w-full h-full object-cover" />
                                                <label className="text-center cursor-pointer z-10 absolute inset-0 -bottom-2 bg-black bg-opacity-40 flex-col items-center justify-center hidden group-hover:flex">
                                                    <img src={camera} alt="" className="mt-4 filter invert" />
                                                    <span className="text-sm text-white py-1 px-2">Upload New</span>
                                                    <input type='file' accept="image/*" className="hidden" onChange={this.addImage} />
                                                </label>
                                            </div>
                                            :
                                            <div className="flex flex-col gap-4 items-center mt-8 w-40 h-40 bg-gray-100 rounded-[10px] overflow-hidden border-2 border-orange-500">
                                                <img src={camera} alt="" className="mt-4" />
                                                <label className="items-center px-4 text-center cursor-pointer ">
                                                    <span className="mt-2 text-base leading-normal">Upload product logo</span>
                                                    <input type='file' accept="image/*" className="hidden" onChange={this.addImage} />
                                                </label>
                                            </div>
                                        }
                                    </Col>
                                    <Col sm={9} md={9} className="max-w-md">
                                        <Form onSubmit={this.handleSubmit} className="font_family">
                                            <div className="mt-3 mb-2">
                                                <label className="font-medium text-lg mb-0">Product Name</label>
                                                {this.state.productNameError !== "" ?
                                                    <input type="text" className="form-control error-border placeholder-gray-500 text-black focus:text-black" aria-describedby="text"
                                                        value={this.state.country}
                                                        name="productName"
                                                        placeholder="Product Name"
                                                        onChange={this.onChange}
                                                        onBlur={this.handleBlur}
                                                    /> :
                                                    <input type="text" className="form-control border placeholder-gray-500 text-black focus:text-black" aria-describedby="text"
                                                        value={this.state.productName}
                                                        name="productName"
                                                        placeholder="Product Name"
                                                        onChange={this.onChange}
                                                        onBlur={this.handleBlur}
                                                    />}
                                                <div>{this.state.productNameError && (
                                                    <div className="error">{this.state.productNameError}</div>
                                                )}</div>
                                            </div>
                                            <div className="mb-2">
                                                <label className="font-medium text-lg mb-0">Product Website</label>
                                                {this.state.productWebsiteError !== "" ?
                                                    <input type="text" className="form-control error-border placeholder-gray-500 text-black focus:text-black" aria-describedby="text"
                                                        value={this.state.productWebsite}
                                                        name="productWebsite"
                                                        placeholder="Product Website"
                                                        onChange={this.onChange}
                                                        onBlur={this.handleBlur}
                                                    /> :
                                                    <input type="text" className="form-control border placeholder-gray-500 text-black focus:text-black" aria-describedby="text"
                                                        value={this.state.productWebsite}
                                                        name="productWebsite"
                                                        placeholder="Product Website"
                                                        onChange={this.onChange}
                                                        onBlur={this.handleBlur}
                                                    />}
                                                <div>{this.state.productWebsiteError && (
                                                    <div className="error">{this.state.productWebsiteError}</div>
                                                )}</div>
                                            </div>
                                            <div className="mb-2">
                                                <label className="font-medium text-lg mb-0">Product Type</label>
                                                <div>
                                                    {this.state.productTypeError !== "" ?
                                                        <select name="productType" value={this.state.productType} onBlur={this.handleBlur} onChange={this.onProductTypeChange} className="form-control error-border placeholder-gray-500 text-black focus:text-black">
                                                            <option value="" selected disabled>Select Product Type</option>
                                                            {this.state.productTypeArr.map((data, i) => (
                                                                <option value={data.productTypeId} key={i}>{data.productType}</option>
                                                            ))}
                                                        </select> :
                                                        <select name="productType" value={this.state.productType} onBlur={this.handleBlur} onChange={this.onProductTypeChange} className="form-control borde placeholder-gray-500 text-black focus:text-black">
                                                            <option value="" selected disabled>Select Product Type</option>
                                                            {this.state.productTypeArr.map((data, i) => (
                                                                <option value={data.productTypeId} key={i}>{data.productType}</option>
                                                            ))}
                                                        </select>
                                                    }  <div>{this.state.productTypeError && (
                                                        <div className="error">{this.state.productTypeError}</div>
                                                    )}</div>
                                                </div>
                                            </div>
                                            <div className="mb-2">
                                                <label className="font-medium text-lg mb-0">Product Search Tags</label>
                                                <p className="font-normal text-xs mb-2">Tags will help out for searching and finding your product better. Type the tag and hit enter</p>
                                                <div className="flex flex-wrap items-start p-2 rounded border border-black min-h-[140px] relative w-full">
                                                    <div className="flex flex-wrap items-start w-full gap-2">
                                                        {this.state.tags && this.state.tags.map((tag, i) => {
                                                            return (
                                                                <span key={i} className="rounded-xl min-w-max text-center px-2" style={{ backgroundColor: "#FFB858" }}>{tag}<img src={cancelBlack} alt="" className="float-right cursor-pointer" onClick={() => this.handleRemoveTag(tag)} /></span>
                                                            )
                                                        })
                                                        }
                                                        {this.state.tags?.length < 15 ? <input type="text" className="focus:outline-none" onKeyDown={this.handleKeyDown} placeholder="Enter Tag" maxlength="20" /> : ''}
                                                    </div>
                                                    <span className="text-sm absolute bottom-2 right-2">{this.state.tags?.length || 0}/15</span>
                                                </div>
                                            </div>
                                            <div className="flex justify-between mt-4">
                                                <Button
                                                    variant="default" size="md"
                                                    style={{ float: "right", width: "100px", height: "30px", borderRadius: "10px", backgroundColor: "#FFB858", color: "black", fontSize: "16px", lineHeight: "20px" }}
                                                    className="login_button border-0"
                                                    onClick={this.goBack}
                                                >
                                                    Back
                                                </Button>
                                                <div className='relative'>
                                                    <div className='btn-1 ' style={{ width: "100px", height: "30px" }}></div>
                                                    <Button
                                                        variant="default" size="md" block
                                                        type="submit"
                                                        className="login_button btn-2"
                                                        style={{ fontSize: "16px", width: "100px", height: "30px", lineHeight: "16px" }}
                                                    >
                                                        Next
                                                    </Button>
                                                </div>
                                            </div>

                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    }
}

import React, { useEffect, useState } from 'react'
import { CircleFadeLoader } from 'react-loaders-kit';
import { decrypt, loaderProps } from '../utils';
import axios from 'axios';
import { config } from '../Config/Config';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import DetailedReview from './DetailedReview';
import ViewImage from '../UsersFeed/ViewImage';
import FeedbackComments from './FeedbackComments';
import SuggestionComments from './SuggestionComments';


function FlaggedPost({ selectedVersionId, category, productId, handleFlag, deletePost, openReasonPopUp, removeReviewReport, userRole }) {

    const [loading, setLoading] = useState(true);
    const [feddbacks, setFeddbacks] = useState([]);
    const [suggestions, setSuggestions] = useState([])
    const [reviews, setReviews] = useState([])
    const [search, setSearch] = useState('');
    const [flaggedBy, setFlaggedBy] = useState('');
    const [detailedReview, setDetailedReview] = useState()
    const [viewImage, setViewImage] = useState(false);
    const [popup, setPopup] = useState(false)
    const [popupData, setPopupData] = useState([]);
    const [suggestionPop, setSuggestionPop] = useState(false);
    const [suggestionPopData, setSuggestionPopData] = useState([]);

    useEffect(() => {
        // getAllFeddbacks();
        // getAllSuggestions();
        getFlaggedPosts()
    }, [selectedVersionId])


    const getFlaggedPosts = () => {
        if (selectedVersionId === "") return;
        setLoading(true);
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + decrypt(localStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "productId": productId,
            "versionId": selectedVersionId,
            "role": userRole
        }
        axios.post(config.userUrl + "admin/getFlaggedPosts", payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setFeddbacks(response.data?.feedbacks)
                    setSuggestions(response.data?.suggestions);
                    setReviews(response.data?.reviews);
                }
            })
            .then(() => {
                setLoading(false);
            })
            .catch(function (error) {
                console.log("Error", error);
                setLoading(false);
            });
    };

    const openImage = (data) => {
        setViewImage(data.feedbackImage || data.suggestionImage)
    }
    const togglePopup = (data) => {
        setPopup(!popup)
        setPopupData(data)
    }

    const toggleSuggestionPopup = (data) => {
        setSuggestionPop(!suggestionPop)
        setSuggestionPopData(data)
    }

    return (
        <>
            {viewImage && <ViewImage close={() => setViewImage(false)} image={viewImage} />}
            {suggestionPop && <SuggestionComments openSuggestionImage={openImage} closePopup={toggleSuggestionPopup} getSuggestions={() => { }} data={suggestionPopData} selectedValue={0} />}
            {popup && <FeedbackComments openImage={openImage} closePopup={togglePopup} getAllFeedbacks={() => { }} data={popupData} selectedValue={0} />}

            {detailedReview ? <DetailedReview review={detailedReview} userRole={userRole} closePopUp={() => setDetailedReview()} /> : <></>}

            <div className='w-full flex items-center justify-between'>
                <div className='flex-grow'>
                    <h1 className='text-2xl font-medium capitalize my-1'>{category}</h1>
                    <div className='flex items-center w-full max-w-lg rounded-full border text-gray-600 px-2 bg-white'>
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.5508 21.8154L17.0648 17.3214L21.5508 21.8154ZM19.5508 11.3154C19.5508 13.5698 18.6552 15.7318 17.0612 17.3258C15.4671 18.9199 13.3051 19.8154 11.0508 19.8154C8.79644 19.8154 6.63443 18.9199 5.04037 17.3258C3.44631 15.7318 2.55078 13.5698 2.55078 11.3154C2.55078 9.06109 3.44631 6.89908 5.04037 5.30502C6.63443 3.71096 8.79644 2.81543 11.0508 2.81543C13.3051 2.81543 15.4671 3.71096 17.0612 5.30502C18.6552 6.89908 19.5508 9.06109 19.5508 11.3154V11.3154Z" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" />
                        </svg>
                        <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='search user' className='p-2 flex-grow bg-transparent outline-none' />
                    </div>
                </div>
                {userRole == 'moderator' ? '' : <div>
                    <h1 className='text-lg mb-1'>Show flagged post by:</h1>
                    <div className='text-sm flex items-center gap-1'>
                        <button className={`${flaggedBy == '' ? 'bg-yellow-400' : ''} py-0.5 px-3 rounded-full outline-none border-none focus:outline-none`} onClick={() => setFlaggedBy('')}>All</button>
                        <button className={`${flaggedBy == 'admin' ? 'bg-yellow-400' : ''}  py-0.5 px-3 rounded-full outline-none border-none focus:outline-none`} onClick={() => setFlaggedBy('admin')}>Admin</button>
                        <button className={`${flaggedBy == 'subadmin' ? 'bg-yellow-400' : ''}  py-0.5 px-3 rounded-full outline-none border-none focus:outline-none`} onClick={() => setFlaggedBy('subadmin')}>Sub Admin</button>
                        <button className={`${flaggedBy == 'moderator' ? 'bg-yellow-400' : ''}  py-0.5 px-3 rounded-full outline-none border-none focus:outline-none`} onClick={() => setFlaggedBy('moderator')}>Moderators</button>
                    </div>
                </div>}
            </div>
            <div className='py-4'>
                {loading ?
                    <div className='mx-auto flex flex-col'><CircleFadeLoader {...loaderProps} /><span className='text-2xl font-semibold'>Loading...</span></div>
                    :
                    category == 'feddbacks'
                        ?
                        !feddbacks?.filter(item => item?.userName?.toLowerCase()?.includes(search?.toLowerCase()) && (userRole == 'moderator' ? item?.flaggedBy == 'moderator' : true))?.length
                            ?
                            <div className='mx-auto flex text-2xl font-semibold'>No feedbacks found</div>
                            :
                            feddbacks?.filter(item => item?.userName?.toLowerCase()?.includes(search?.toLowerCase()) && (userRole == 'moderator' ? item?.flaggedBy == 'moderator' : true))?.map((item, i) => (
                                <PostRow userRole={userRole} flaggedBy={flaggedBy} category={category} key={i} item={item} getFlaggedPosts={getFlaggedPosts} openReasonPopUp={openReasonPopUp} deletePost={deletePost} handleFlag={handleFlag} onClick={() => togglePopup({ ...item, feedbackId: item.id })} />
                            ))
                        :
                        category == 'suggestions' ?
                            !suggestions?.filter(item => item?.userName?.toLowerCase()?.includes(search?.toLowerCase()) && (userRole == 'moderator' ? item?.flaggedBy == 'moderator' : true))?.length
                                ?
                                <div className='mx-auto flex text-2xl font-semibold'>No Suggestion found</div>
                                :
                                suggestions?.filter(item => item?.userName?.toLowerCase()?.includes(search?.toLowerCase()) && (userRole == 'moderator' ? item?.flaggedBy == 'moderator' : true))?.map((item, i) => (
                                    <PostRow userRole={userRole} flaggedBy={flaggedBy} category={category} key={i} item={item} getFlaggedPosts={getFlaggedPosts} openReasonPopUp={openReasonPopUp} deletePost={deletePost} handleFlag={handleFlag} onClick={() => toggleSuggestionPopup(item)} />
                                ))
                            :
                            category == 'reviews' ?
                                !reviews?.filter(item => (!item?.userName || item?.userName?.toLowerCase()?.includes(search?.toLowerCase())) && (userRole == 'moderator' ? item?.flaggedBy == 'moderator' : true))?.length
                                    ?
                                    <div className='mx-auto flex text-2xl font-semibold'>No Review found</div>
                                    :
                                    reviews?.filter(item => (!item?.userName || item?.userName?.toLowerCase()?.includes(search?.toLowerCase())) && (userRole == 'moderator' ? item?.flaggedBy == 'moderator' : true))?.map((item, i) => (
                                        <PostRow userRole={userRole} flaggedBy={flaggedBy} category={category} key={i} item={item} getFlaggedPosts={getFlaggedPosts} openReasonPopUp={openReasonPopUp} deletePost={deletePost} handleFlag={removeReviewReport} onClick={() => setDetailedReview(item)} />
                                    ))
                                :
                                <></>
                }


            </div>
        </>
    )
}

export default FlaggedPost


const PostRow = ({ item, getFlaggedPosts, openReasonPopUp, deletePost, handleFlag, category, onClick, userRole, flaggedBy }) => {
    if (flaggedBy && flaggedBy !== item.flaggedBy?.toLowerCase())
        return <></>;

    return (
        <div>
            <Row className='items-center'>
                <Col md={1} className='text-black font-medium'>
                    <h1>{item?.userName || 'Anonymous'}</h1>
                    <p className='text-sm'>{moment(item?.updatedAt).format("DD/MM/YY")}</p>
                    {userRole !== 'moderator' && <p className='text-xs text-gray-500 capitalize'>{item.flaggedBy || 'subAdmin'}</p>}
                </Col>
                <Col md={4} onClick={item?.deletedOn ? () => { } : onClick} className={`${item?.deletedOn ? '' : 'cursor-pointer'}`}>
                    <p className='max-w-md break-words'>{item?.title}</p>
                </Col>

                <Col md={4}>
                    <p className='italic max-w-md break-words'>Note: {item?.note || item?.reason || 'None'}</p>
                </Col>
                <Col md={3}>

                    <div className='flex items-center gap-3 text-sm'>
                        {
                            userRole == 'moderator'
                            &&
                            <div className='mr-4 max-w-max'>
                                <h1 className='text-sm'>Admin Action:</h1>
                                <h1 className='italic text-sm text-center'>{item.action || 'no action'}</h1>
                            </div>
                        }

                        {
                            item?.deletedOn
                                ?
                                userRole !== 'moderator' ?
                                    <div>
                                        <h1 className='italic text-center'>Post will be deleted</h1>
                                        {item.deletedOn && <p className="text-center">in {moment(moment(item.deletedOn).add(2, 'days')).fromNow(true)}</p>}
                                    </div>
                                    :
                                    <></>
                                :
                                <>
                                    {
                                        item.flaggedBy
                                            ?
                                            <button className='flex items-center text-white gap-2 rounded-xl py-1 px-3 bg-orange-400 focus:outline-none'
                                                onClick={() => openReasonPopUp({ noPopUp: true, type: 'unflagging', action: () => handleFlag({ id: item.id, type: category, flag: false, callback: () => getFlaggedPosts() }) })}
                                            >
                                                <span><svg width="16" height="23" viewBox="0 0 18 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_1900_11143)">
                                                        <path d="M11.7121 6.56879C11.8868 6.38756 11.7791 6.08466 11.5291 6.05451L0.92379 4.77524C0.698056 4.74801 0.52302 4.96854 0.600786 5.1822L3.84558 14.0972C3.92269 14.309 4.19511 14.3669 4.35157 14.2045L11.7121 6.56879Z" fill="#97FF58" />
                                                        <path d="M1.26707 4.99675C1.20201 4.81799 1.00436 4.72583 0.825611 4.79089C0.64686 4.85595 0.554695 5.0536 0.619755 5.23235L7.77195 24.8828C7.83701 25.0616 8.03466 25.1538 8.21341 25.0887C8.39216 25.0236 8.48433 24.826 8.41927 24.6472L1.26707 4.99675Z" fill="black" />
                                                        <path d="M10.9374 25.2069C10.9374 24.9267 10.8586 24.6491 10.7053 24.3902C10.5521 24.1313 10.3275 23.896 10.0444 23.6978C9.76136 23.4996 9.42526 23.3425 9.05533 23.2352C8.68545 23.1279 8.28898 23.0728 7.88862 23.0728C7.48825 23.0728 7.09179 23.1279 6.72191 23.2352C6.35203 23.3425 6.01592 23.4996 5.73281 23.6978C5.44971 23.896 5.22514 24.1313 5.07192 24.3902C4.9187 24.6491 4.83984 24.9267 4.83984 25.2069H7.88862H10.9374Z" fill="black" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1900_11143">
                                                            <rect width="17.6829" height="25" fill="white" transform="translate(0.185547 0.674316)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                </span>
                                                <span>
                                                    Unflag Post
                                                </span>
                                            </button>
                                            :
                                            ''
                                    }

                                    {userRole !== 'moderator' && <button className='flex items-center text-white gap-2 rounded-xl py-1 px-3 bg-rose-500 focus:outline-none'
                                        onClick={() => openReasonPopUp({ type: 'deleting', noPopUp: category == 'reviews', action: () => deletePost({ id: item.id, type: category, remove: true, callback: () => getFlaggedPosts() }) })}
                                    >
                                        <span>
                                            <svg width="17" height="20" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.2244 8.17484L11.8784 17.1748M7.09037 17.1748L6.74438 8.17484M16.7124 4.96484C17.0544 5.01684 17.3944 5.07184 17.7344 5.13084M16.7124 4.96484L15.6444 18.8478C15.6008 19.4131 15.3454 19.941 14.9294 20.3261C14.5134 20.7112 13.9673 20.925 13.4004 20.9248H5.56837C5.00147 20.925 4.4554 20.7112 4.03935 20.3261C3.62331 19.941 3.36796 19.4131 3.32438 18.8478L2.25637 4.96484M16.7124 4.96484C15.5582 4.79035 14.3981 4.65793 13.2344 4.56784M2.25637 4.96484C1.91437 5.01584 1.57438 5.07084 1.23438 5.12984M2.25637 4.96484C3.41051 4.79036 4.57061 4.65794 5.73438 4.56784M13.2344 4.56784V3.65184C13.2344 2.47184 12.3244 1.48784 11.1444 1.45084C10.038 1.41548 8.93076 1.41548 7.82438 1.45084C6.64438 1.48784 5.73438 2.47284 5.73438 3.65184V4.56784M13.2344 4.56784C10.7381 4.37492 8.23065 4.37492 5.73438 4.56784" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </span>
                                        <span>Delete Post</span>
                                    </button>}
                                </>
                        }
                    </div>
                </Col>
            </Row>

            <hr className="bg-orange-400 w-full mx-auto rounded-sm h-[1px] my-3" />
        </div>
    )
}


import React, { Component } from "react";
import { Button, Form, Dropdown, Col, Row, Image, Toast } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import fedduplogo from "../images/fedduplogo.png";
import loginimg from "../images/login-img.png";
import facebook from "../images/facebook.png";
import google from "../images/google.png";
import '../Login/Login.css';
import axios from 'axios';
import { config } from '../Config/Config';
import signUpBottom from "../images/signup-bottom.svg";
import registerNow from "../images/register-now.svg"
import getTimeZoneDetails from "../utils/getTimeZone";
import { encrypt } from "../utils";

const validEmailRegex =
    /^[a-z0-9+_.-]+@[a-z0-9.-]+$/
const validPasswordRegex =
    /^.*(?=.{10,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*]).*$/
const NavigateContext = React.createContext();

export default class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: "",
            showPassword: false,
            showConfirmPassword: false,
            nav: false,
            firstNameError: "",
            emailError: "",
            passwordError: "",
            confirmPasswordError: "",
            adminName: "",
            companyName: "",
            website: "",
            country: "",
            productId: "",
            countryError: "",
            userRoleId: "",
            thanksForRegistering: false,
            addModerators: false,
            verify: false,
            registerFlow: true,
            companyNameError: "",
            adminNameError: "",
            showToast: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeRole = this.onChangeRole.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleCompanyBlur = this.handleCompanyBlur.bind(this);
        this.validateFirstName = this.validateFirstName.bind(this);
        this.validateAdminName = this.validateAdminName.bind(this);
        // this.validateCountry = this.validateCountry.bind(this);
        this.validateEmailAddress = this.validateEmailAddress.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
        this.validateConfirmPassword = this.validateConfirmPassword.bind(this);
        this.validateField = this.validateField.bind(this);
        this.validateCompanyField = this.validateCompanyField.bind(this);
        this.validateCompanyName = this.validateCompanyName.bind(this);
        this.handleCompanySubmit = this.handleCompanySubmit.bind(this);
    }
    componentDidMount() {
        if (this.props.match?.params.id) {
            console.log("id", this.props.match.params.id)
            this.setState({ userRoleId: 3 })
            this.getSubAdminDetails();
        } else {
            console.log("There is no id")
            this.setState({ userRoleId: 1 })
        }
    }
    onChange(event) {
        this.setState({
            adminNameError: "",
            firstNameError: "",
            emailError: "",
            passwordError: "",
            confirmPasswordError: "",
            countryError: "",
            companyNameError: ""
        });
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
        return;
    }
    onChangeRole(e, value) {
        this.setState({
            userRoleId: parseInt(value)
        })
        console.log("role", value)
    }
    handleBlur(event) {
        const { name } = event.target;
        this.validateField(name);
        return;
    }
    handleCompanyBlur(event) {
        const { name } = event.target;
        this.validateCompanyField(name);
        return;
    }
    handleSubmit(event) {
        event.preventDefault();
        console.log(this.state);
        let formFileds = [
            "firstName",
            "email",
            // "country",
            "password",
            "confirmPassword"

        ];
        let isValid = true;
        formFileds.forEach(field => {
            isValid = this.validateField(field) && isValid;
        });
        if (!isValid) {
            console.log("Please enter the details")
        } else {
            this.postDetails()
        }
    }
    handleCompanySubmit(event) {
        event.preventDefault();
        let formFiledsNew = [
            "adminName",
            "companyName",
            "email",
            // "country",
            "password",
            "confirmPassword"

        ];
        let isValidNew = true;
        formFiledsNew.forEach(field => {
            isValidNew = this.validateCompanyField(field) && isValidNew;
        });
        if (!isValidNew) {
            console.log("Please enter the details")
        } else {
            this.postCompanyDetails()
        }
    }
    validateField(name) {
        let isValid = false;
        if (name === "firstName") isValid = this.validateFirstName();
        else if (name === "email") isValid = this.validateEmailAddress();
        // else if (name === "country") isValid = this.validateCountry();
        else if (name === "password") isValid = this.validatePassword();
        else if (name === "confirmPassword") isValid = this.validateConfirmPassword();
        return isValid;
    }
    validateCompanyField(name) {
        let isValidNew = false;
        if (name === "companyName") isValidNew = this.validateCompanyName();
        else if (name === "adminName") isValidNew = this.validateAdminName();
        else if (name === "email") isValidNew = this.validateEmailAddress();
        // else if (name === "country") isValidNew = this.validateCountry();
        else if (name === "password") isValidNew = this.validatePassword();
        else if (name === "confirmPassword") isValidNew = this.validateConfirmPassword();
        return isValidNew;
    }
    validateAdminName() {
        let adminNameError = "";
        const value = this.state.adminName;
        if (value.trim() === "") adminNameError = "Admin Name must be at least 3 characters long!";
        else if (value.length < 3)
            adminNameError = 'Admin Name must be at least 3 characters long!';
        this.setState({
            adminNameError
        });
        return adminNameError === "";
    }
    validateCompanyName() {
        let companyNameError = "";
        const value = this.state.companyName;
        if (value.trim() === "") companyNameError = "Company Name must be at least 3 characters long!";
        else if (value.length < 3)
            companyNameError = 'Company Name must be at least 3 characters long!';
        this.setState({
            companyNameError
        });
        return companyNameError === "";
    }
    validateCountry() {
        let countryError = "";
        const value = this.state.country;
        if (value.trim() === "") countryError = "Please enter the country name";
        else if (value.length < 5)
            countryError = "Please enter the country name";
        this.setState({
            countryError
        });
        return countryError === "";
    }
    validateFirstName() {
        let firstNameError = "";
        const value = this.state.firstName;
        if (value.trim() === "") firstNameError = "FirstName must be at least 5 characters long!";
        else if (value.length < 5)
            firstNameError = 'FirstName must be at least 5 characters long!';
        else if (value.length > 50)
            firstNameError = 'FirstName should not be more than 50 characters long!';
        this.setState({
            firstNameError
        });
        return firstNameError === "";
    }

    validateEmailAddress() {
        let emailError = "";
        const value = this.state.email;
        if (value.trim === "") emailError = "Email is required";
        else if (!validEmailRegex.test(value))
            emailError = "Please Enter Email";
        this.setState({
            emailError
        });
        return emailError === "";
    }
    validatePassword() {
        let passwordError = "";
        const value = this.state.password;
        if (value.trim === "") passwordError = "Password is required";
        else if (!validPasswordRegex.test(value))
            passwordError = "Min 10 with 1 capital, 1 numeric and 1 character (!@#$%^&*)";
        this.setState({
            passwordError
        });
        return passwordError === "";
    }

    validateConfirmPassword() {
        let confirmPasswordError = "";
        if (this.state.password !== this.state.confirmPassword)
            confirmPasswordError = "Password does not match";
        this.setState({
            confirmPasswordError
        });
        return confirmPasswordError === "";
    }


    showPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };
    showConfirmPassword = () => {
        this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
    };
    postDetails = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var encryptedPassword = encrypt(this.state.password);
        var self = this;
        var payload = {
            "firstName": this.state.firstName,
            "lastName": this.state.lastName,
            "email": this.state.email,
            "country": getTimeZoneDetails(Intl.DateTimeFormat().resolvedOptions().timeZone).userCountry,
            "userRoleId": 1,
            "password": encryptedPassword
        }

        axios.post(config.apiUrl + 'auth/register', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    console.log("User Registered successfully!!")
                    console.log("payload", payload)
                    self.setState({
                        thanksForRegistering: true
                    })
                }
                else {
                    console.log("error")
                    document.getElementById('status').innerText = response.data.message
                }
            })
            .catch(function (error) {
                console.log("payload", payload)
                console.log(error);
            });
    }
    postCompanyDetails = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var encryptedPassword = encrypt(this.state.password);
        var self = this;

        if (this.props.match?.params.id) {
            var payload = {
                "password": encryptedPassword
            }
            axios.post(config.userUrl + `user/subAdminActivation/${this.props.match.params.id}`, payload, { headers: headers })
                .then(function (response) {
                    if (response.data.status === true) {
                        console.log("Sub-Admin Registered successfully!!")
                        console.log("payload", payload)
                        self.setState({
                            thanksForRegistering: true
                        })
                    } else {
                        console.log("error")
                    }
                })
                .catch(function (error) {
                    console.log("payload", payload)
                    console.log(error);
                });
        } else {
            var payload = {
                "firstName": this.state.adminName,
                "companyName": this.state.companyName,
                "email": this.state.email,
                "country": getTimeZoneDetails(Intl.DateTimeFormat().resolvedOptions().timeZone).userCountry,
                "companyWebsite": this.state.website,
                "password": encryptedPassword
            }
            axios.post(config.apiUrl + 'auth/adminRegister', payload, { headers: headers })
                .then(function (response) {
                    const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                    if (response.data.status === true) {
                        console.log("Admin Registered successfully!!")
                        console.log("payload", payload)
                        self.setState({
                            thanksForRegistering: true
                        })
                    } else {
                        console.log("error", response.data.message)
                        if (response.data.message == 'This company name already registered') {
                            self.setState({
                                companyNameError: 'This company name already registered'
                            });
                        }
                        else {
                            document.getElementById('errormessage').innerText = response.data.message
                        }
                    }
                })
                .catch(function (error) {
                    console.log("payload", payload)
                    console.log(error);
                    self.setState({ showToast: true })
                });
        }
    }

    getSubAdminDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var payload = {
            "activationId": this.props.match?.params.id
        }
        var self = this;
        axios.post(config.userUrl + 'user/getSubAdminData', payload, { headers: headers })
            .then(function (response) {
                console.log("getSubAdminData", response);
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("getSubAdminDetails successfull", response.data.subAdmin);
                    self.setState({
                        adminName: response.data.subAdmin[0].firstName,
                        companyName: response.data.subAdmin[0].companyName,
                        website: response.data.subAdmin[0].companyWebsite,
                        country: getTimeZoneDetails(Intl.DateTimeFormat().resolvedOptions().timeZone).userCountry,
                        email: response.data.subAdmin[0].email,
                        productId: response.data.subAdmin[0].id,
                    })
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    render() {
        const { thanksForRegistering, addModerators, userRoleId } = this.state;
        // console.log(getTimeZoneDetails(Intl.DateTimeFormat().resolvedOptions().timeZone).userCountry)
        if (thanksForRegistering) {
            console.log("redirect1", this.state.thanksForRegistering);
            return <Redirect
                to={{
                    pathname: "/thanksforregistering",
                    state: { email: this.state.email, registerFlow: this.state.registerFlow, firstName: this.state.firstName }
                }}
            />
        }
        if (addModerators) {
            console.log("redirect1", this.state.addModerators);
            return <Redirect
                to={{
                    pathname: "/addmoderators",
                    state: { email: this.state.email, password: this.state.password }
                }}
            />
        }
        return (
            <div className="flex flex-col h-[100vh] justify-between">
                <span>  <Link to="/"><img src={fedduplogo} className="logo-login mt-1" /></Link></span>
                <Row className="flex w-full justify-center items-center">
                    <Col sm={7} md={7}>
                        <Image src={loginimg} className="img-login" />
                        {userRoleId == 1 ?
                            <div>
                                <center className="forgot-text-sm font-bold mt-4">Have a product?<span className="underline" style={{ color: "#3480eb", cursor: "pointer" }} onClick={(e) => this.onChangeRole(e, 3)}> Register as a Company</span></center>
                            </div> :
                            <div>
                                <center className="forgot-text-sm font-bold mt-4"><span className="underline" style={{ color: "#3480eb", cursor: "pointer" }} onClick={(e) => this.onChangeRole(e, 1)}> Register as an User</span></center>
                                <center className="forgot-text-sm font-bold mt-2">Alreay have an account ?<Link to="/login"><span className="underline" style={{ color: "#3480eb" }}> Login here</span></Link></center>
                            </div>}

                    </Col>
                    <Col sm={5} md={5}>
                        <div className="login">

                            {userRoleId == 1 ?
                                <Form onSubmit={this.handleSubmit} className="font_family">
                                    <img src={signUpBottom} alt="" className="mt-[-1rem] mb-4" />
                                    <div>
                                        <div className="mt-3 form-group">
                                            {this.state.firstNameError !== "" ?
                                                <input type="text" className="form-control error-border  placeholder-gray-500 text-black focus:text-black" aria-describedby="text"
                                                    value={this.state.firstName}
                                                    name="firstName"
                                                    placeholder="First Name"
                                                    onChange={this.onChange}
                                                    onBlur={this.handleBlur}
                                                /> :
                                                <input type="text" className="form-control border placeholder-gray-500 text-black focus:text-black" aria-describedby="text"
                                                    value={this.state.firstName}
                                                    name="firstName"
                                                    placeholder="First Name"
                                                    onChange={this.onChange}
                                                    onBlur={this.handleBlur}
                                                />}
                                            <div>{this.state.firstNameError && (
                                                <div className="error">{this.state.firstNameError}</div>
                                            )}</div>
                                        </div>
                                        <div className="form-group">
                                            <input type="text" className="form-control border placeholder-gray-500 text-black focus:text-black" aria-describedby="text"
                                                value={this.state.lastName}
                                                name="lastName"
                                                placeholder="Last Name"
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            {this.state.emailError !== "" ?
                                                <input type="email" className="form-control error-border placeholder-gray-500 text-black focus:text-black" aria-describedby="text"
                                                    value={this.state.email}
                                                    name="email"
                                                    placeholder="Email"
                                                    onChange={this.onChange}
                                                    onBlur={this.handleBlur}
                                                /> :
                                                <input type="email" className="form-control border placeholder-gray-500 text-black focus:text-black" aria-describedby="text"
                                                    value={this.state.email}
                                                    name="email"
                                                    placeholder="Email"
                                                    onChange={this.onChange}
                                                    onBlur={this.handleBlur}
                                                />}
                                            <div className="div">{this.state.emailError && (
                                                <div className="error">{this.state.emailError}</div>
                                            )}</div>
                                        </div>
                                        
                                        <div className="mt-2 form-group">
                                            {this.state.passwordError !== "" ?
                                                <input type={this.state.showPassword ? "text" : "password"}
                                                    name="password"
                                                    className="form-control error-border placeholder-gray-500 text-black focus:text-black"
                                                    value={this.state.password}
                                                    placeholder="Password"
                                                    onChange={this.onChange}
                                                    onBlur={this.handleBlur}
                                                /> :
                                                <input type={this.state.showPassword ? "text" : "password"}
                                                    name="password"
                                                    className="form-control border placeholder-gray-500 text-black focus:text-black"
                                                    value={this.state.password}
                                                    placeholder="Password"
                                                    onChange={this.onChange}
                                                    onBlur={this.handleBlur}
                                                />}
                                            <span className="showPassword"
                                                onClick={this.showPassword}
                                            >
                                                {this.state.showPassword ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                                            </span>
                                            <div className="div">{this.state.passwordError && (
                                                <div className="error">{this.state.passwordError}</div>
                                            )}</div>
                                        </div>
                                        <div className="mt-2 form-group">
                                            {this.state.confirmPasswordError !== "" ?
                                                <input type={this.state.showConfirmPassword ? "text" : "password"}
                                                    name="confirmPassword"
                                                    className="form-control error-border placeholder-gray-500 text-black focus:text-black"
                                                    value={this.state.confirmPassword}
                                                    placeholder="Re-enter Password"
                                                    onChange={this.onChange}
                                                    onBlur={this.handleBlur}
                                                /> :
                                                <input type={this.state.showConfirmPassword ? "text" : "password"}
                                                    name="confirmPassword"
                                                    className="form-control border placeholder-gray-500 text-black focus:text-black"
                                                    value={this.state.confirmPassword}
                                                    placeholder="Re-enter Password"
                                                    onChange={this.onChange}
                                                    onBlur={this.handleBlur}
                                                />}
                                            <span className="showPassword"
                                                onClick={this.showConfirmPassword}
                                            >
                                                {this.state.showConfirmPassword ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                                            </span>
                                            <div className="div">{this.state.confirmPasswordError && (
                                                <div className="error">{this.state.confirmPasswordError}</div>
                                            )}</div>
                                        </div>
                                    </div>

                                    <div className="forgot-text-sm">
                                        By signing up you automatically accept <Link to="/privacypolicy"><span style={{ color: "#3480eb" }}>Privacy policies</span></Link> and <Link to="/terms"><span style={{ color: "#3480eb" }}>Terms & conditions</span></Link>
                                    </div><br />
                                    <div id="status" className="error" style={{ marginTop: "-15px" }}></div>
                                    <div className='btn-outer mx-auto'>
                                        <div className='btn-1 login-btn-1'></div>
                                        <Button
                                            variant="default" size="md"
                                            type="submit"
                                            className="login_button btn-2 login-btn-2"
                                        >
                                            Signup
                                        </Button>
                                    </div>
                                    <center className="forgot-text-sm font-bold mt-2">Alreay have an account ?<Link to="/login"><span className="underline" style={{ color: "#3480eb" }}> Login here</span></Link></center>
                                </Form>
                                :
                                <Form className="font_family">
                                    <div>
                                        <img src={registerNow} alt="" className="mt-[-1rem] mb-4" />
                                        <div className="mt-0 form-group mb-2">
                                            {this.state.adminNameError !== "" ?
                                                <input type="text" className="form-control error-border placeholder-gray-500 text-black focus:text-black" aria-describedby="text"
                                                    value={this.state.adminName}
                                                    name="adminName"
                                                    placeholder="Admin Name"
                                                    onChange={this.onChange}
                                                    onBlur={this.handleCompanyBlur}
                                                /> :
                                                <input type="text" className="form-control border mb-2 placeholder-gray-500 text-black focus:text-black" aria-describedby="text"
                                                    value={this.state.adminName}
                                                    name="adminName"
                                                    placeholder="Admin Name"
                                                    onChange={this.onChange}
                                                    onBlur={this.handleCompanyBlur}
                                                />}
                                            <div>{this.state.adminNameError && (
                                                <div className="error mb-4">{this.state.adminNameError}</div>
                                            )}</div>
                                        </div>
                                        <div className="mt-0 form-group mb-2">
                                            {this.state.companyNameError !== "" ?
                                                <input type="text" className="form-control error-border placeholder-gray-500 text-black focus:text-black" aria-describedby="text"
                                                    value={this.state.companyName}
                                                    name="companyName"
                                                    placeholder="Company Name"
                                                    onChange={this.onChange}
                                                    onBlur={this.handleCompanyBlur}
                                                /> :
                                                <input type="text" className="form-control border mb-2 placeholder-gray-500 text-black focus:text-black" aria-describedby="text"
                                                    value={this.state.companyName}
                                                    name="companyName"
                                                    placeholder="Company Name"
                                                    onChange={this.onChange}
                                                    onBlur={this.handleCompanyBlur}
                                                />}
                                            <div>{this.state.companyNameError && (
                                                <div className="error mb-4">{this.state.companyNameError}</div>
                                            )}</div>
                                        </div>
                                        <div className="form-group mb-2">
                                            <input type="text" className="form-control border placeholder-gray-500 text-black focus:text-black" aria-describedby="text"
                                                value={this.state.website}
                                                name="website"
                                                placeholder="Website"
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        <div className="form-group mb-2">
                                            {this.state.emailError !== "" ?
                                                <input type="email" className="form-control error-border placeholder-gray-500 text-black focus:text-black" aria-describedby="text"
                                                    value={this.state.email}
                                                    name="email"
                                                    placeholder="Email"
                                                    onChange={this.onChange}
                                                    onBlur={this.handleCompanyBlur}
                                                /> :
                                                <input type="email" className="form-control border placeholder-gray-500 text-black focus:text-black" aria-describedby="text"
                                                    value={this.state.email}
                                                    name="email"
                                                    placeholder="Company Email"
                                                    onChange={this.onChange}
                                                    onBlur={this.handleCompanyBlur}
                                                />}
                                            <div className="div">{this.state.emailError && (
                                                <div className="error mb-4">{this.state.emailError}</div>
                                            )}</div>
                                        </div>
                                        <div className="mt-0 form-group mb-2">
                                            {this.state.passwordError !== "" ?
                                                <input type={this.state.showPassword ? "text" : "password"}
                                                    name="password"
                                                    className="form-control error-border placeholder-gray-500 text-black focus:text-black"
                                                    value={this.state.password}
                                                    placeholder="Password"
                                                    onChange={this.onChange}
                                                    onBlur={this.handleCompanyBlur}
                                                /> :
                                                <input type={this.state.showPassword ? "text" : "password"}
                                                    name="password"
                                                    className="form-control border placeholder-gray-500 text-black focus:text-black"
                                                    value={this.state.password}
                                                    placeholder="Password"
                                                    onChange={this.onChange}
                                                    onBlur={this.handleCompanyBlur}
                                                />}
                                            <span className="showPassword"
                                                onClick={this.showPassword}
                                            >
                                                {this.state.showPassword ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                                            </span>
                                            <div className="div">{this.state.passwordError && (
                                                <div className="error mb-4">{this.state.passwordError}</div>
                                            )}</div>
                                        </div>
                                        <div className="mt-0 mb-2 form-group">
                                            {this.state.confirmPasswordError !== "" ?
                                                <input type={this.state.showConfirmPassword ? "text" : "password"}
                                                    name="confirmPassword"
                                                    className="form-control error-border placeholder-gray-500 text-black focus:text-black"
                                                    value={this.state.confirmPassword}
                                                    placeholder="Re-enter Password"
                                                    onChange={this.onChange}
                                                    onBlur={this.handleCompanyBlur}
                                                /> :
                                                <input type={this.state.showConfirmPassword ? "text" : "password"}
                                                    name="confirmPassword"
                                                    className="form-control border placeholder-gray-500 text-black focus:text-black "
                                                    value={this.state.confirmPassword}
                                                    placeholder="Re-enter Password"
                                                    onChange={this.onChange}
                                                    onBlur={this.handleCompanyBlur}
                                                />}
                                            <span className="showPassword"
                                                onClick={this.showConfirmPassword}
                                            >
                                                {this.state.showConfirmPassword ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                                            </span>
                                            <div className="div">{this.state.confirmPasswordError && (
                                                <div className="error mb-4">{this.state.confirmPasswordError}</div>
                                            )}</div>
                                        </div>
                                    </div>
                                    <div className="forgot-text-sm">
                                        By signing up you automatically accept <Link to="/privacypolicy"><span style={{ color: "#3480eb" }}>Privacy policies</span></Link> and <Link to="/terms"><span style={{ color: "#3480eb" }}>Terms & conditions</span></Link>
                                    </div><br />
                                    <div id="errormessage" className="error" style={{ marginTop: "-15px" }}></div>
                                    <div className='btn-outer mx-auto'>
                                        <div className='btn-1 login-btn-1'></div>
                                        <Button
                                            variant="default" size="md"
                                            type="submit"
                                            className="login_button btn-2 login-btn-2"
                                            onClick={this.handleCompanySubmit}
                                        >
                                            Signup
                                        </Button>
                                    </div>
                                </Form>}
                        </div>
                    </Col >
                </Row >
                <footer>
                    <p className="text-center py-6">Copyright {new Date().getFullYear()} Feddup. All right reserved</p>
                </footer>

                <Toast
                    onClose={() => this.setState({ showToast: false })}
                    autohide
                    show={this.state.showToast}
                    delay={2200}
                    position="bottom-center"
                    bg="warning"
                >
                    <Toast.Header>
                        <strong className="">Company Website URl is not valid</strong>
                    </Toast.Header>
                </Toast>
            </div >
        );
    }
}

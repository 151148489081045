import React, { Component } from "react";
import { Button, Form, FormControl, Card, Col, Row, Image } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Header from "../Header/Header";
import './Terms.css';
import { help } from './HelpData';
import fedduplogo from "../images/fedduplogo.png";
import background from '../images/background-img.png';

export default class Help extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div>
                <Header />
                <div className="bg-img">
                    <Form className="container-help">
                        <FormControl
                            type="text"
                            placeholder="Search here"
                            id="search_help" className="form-control search_box_help"
                            aria-label="Search keyword"
                        />
                    </Form>
                </div>
                <br />
                <center><span style={{ color: "#000000", fontWeight: 600, fontSize: "20px", fontFamily: "Poppins" }}>Help Resources</span></center><br />
                <div style={{
                    marginLeft: "20%", marginRight: "20%", fontFamily: "Poppins"
                }}>
                    <Row style={{ width: "100%" }}>
                        {help.map((data, i) => (
                            <Col md={4}>
                                <Card className="help-card">
                                    <div className="help-topic">{data.topic}</div>
                                    <div className="mt-2 help-text">{data.desc}</div>
                                </Card><br/>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div >
        );
    }
}
